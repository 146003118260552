import { Fragment, FunctionComponent, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useApi } from '../../providers/ApiProvider';
import { useUser } from '../../providers/UserProvider';
import { Helmet } from 'react-helmet';
import { Config } from '../../config/config';
import toast from 'react-hot-toast';

/**
 * Ce composant désauthentifie l'utilisateur et affiche une page qui l'invite à se réauthentifier.
 * C'est le contexte UserProvider qui contrôle l'activité de l'utilisateur et qui redirige vers cette page
 */
export const InactiviteScreen: FunctionComponent = () => {
  /**
   * On a besoin des informations de l'utilisateur et du profil courant de celui-ci ainsi que du moyen de modifier ces informations.
   */
  const { user, changeUser, profil, changeProfil: setCurrentProfil } = useUser();

  /**
   * Permet au composant de changer de page.
   */
  const navigate = useNavigate();

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  /**
   * Au chargement de la page on desauthentifie l'utilisateur, tout en gardant l'affichage tel qu'il est.
   */
  useEffect(() => {
    if (!user || !user.token) {
      toast.error('Accès refusé.');
      navigate('/connexion');
    } else {
      client.setToken(user.token);
      client.post('/logout'); // On dit à l'API de se déconnecter mais on ne veut pas que se soit bloquant
      client.resetProfil();
      client.resetToken();
      if (profil !== null) {
        setCurrentProfil(null);
      }
      changeUser(null);
      localStorage.clear();
    }
  }, [client]);

  return (
    <Fragment>
      <Helmet>
        <title>Inactivité - {Config.app_label}</title>
      </Helmet>

      <div className='p-6 bg-[#F7FBFE] w-full rounded-md shadow-md relative'>
        <h2 className='text-3xl text-center mb-1 space-x-3 poppins-bold text-secondary-2'>Inactivité détectée</h2>
        <p className='my-8 text-sm text-gray-700 text-center leading-tight'>Votre session a expiré. Pour continuer merci de vous reconnecter.</p>
        <div className='mt-3'>
          <Link
            to='/connexion'
            className='block poppins-semibold w-full px-2 py-1 rounded-md bg-primary text-white  disabled:bg-primary/50 uppercase text-center'>
            Je me reconnecte
          </Link>
        </div>
      </div>
    </Fragment>
  );
};

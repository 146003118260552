import { CheckIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { FunctionComponent } from 'react';

/**
 * Composant ReadOnlyBoolean.
 *
 * @param label - Libellé du champ de saisie en lecture seule.
 * @param value - Valeur du champ de saisie en lecture seule.
 * @param error - Indique si le champ de saisie  en lecture seule a une erreur.
 * @return Composant ReadOnlyBoolean.
 */
export const ReadOnlyBoolean: FunctionComponent<{
  error?: boolean;
  label?: string;
  value: boolean;
}> = ({ label, value, error = false }) => {
  return (
    <div className='flex flex-col'>
      {label && <label className={`text-xs text-gray-600 ${error ? 'text-red-500' : ''}`}>{label}</label>}
      <p className={`text-secondary-1 text-sm font-normal ${error ? 'text-red-500' : ''}`}>
        {value ? (
          <div className='flex flex-row items-center gap-2'>
            <CheckIcon className='w-4 h-4 text-white bg-light-green-300 rounded-full p-0.5' />
            Oui
          </div>
        ) : (
          <div className='flex flex-row items-center gap-2'>
            <XMarkIcon className='w-4 h-4 text-white bg-red-300 rounded-full p-0.5' />
            Non
          </div>
        )}
      </p>
    </div>
  );
};

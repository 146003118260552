import { Fragment, FunctionComponent } from 'react';
import { DashboardStatsPopulaires } from '../../types/AdminRessources';

/**
 * Ce composant est un encart du tableau de bord administrateur concernant les visites de sites.
 *
 * @param sites - Liste des visites de sites.
 */
export const DashboardAdminSitesPopulaires: FunctionComponent<{
  sites: DashboardStatsPopulaires[];
}> = ({ sites }) => {
  return (
    <Fragment>
      {sites.map((site) => (
        <div key={site.id} className='text-secondary-1 flex flex-row items-center justify-between gap-2 border-b last:border-none py-2'>
          <h6 className='montserrat-semibold'>{site.nom}</h6>
          <span className='text-sm'>{site.compte} visites</span>
        </div>
      ))}
    </Fragment>
  );
};

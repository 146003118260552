import { FunctionComponent, useState } from 'react';
import { DashboardFormation } from '../../types/SalariesRessources';
import { AcademicCapIcon, CalendarDaysIcon, ChevronRightIcon, MapPinIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import { Progress } from '@material-tailwind/react';
import { ModalAjoutAvis } from '../Modales/Salarie/ModalAjoutAvis';
import { DatesDisplay } from '../DatesDisplay';

/**
 * Ce composant est un encart du tableau de bord salariés concernant les formations.
 *
 * @param formations - Liste des formations dans lesquelle le salarié à au moin emis le souhait de participer.
 */
export const DashboardSalariesSessions: FunctionComponent<{ formations: DashboardFormation[] }> = ({ formations }) => {
  /**
   * Permet d'ouvrir une modale lorsque l'on souhaite ajouter un avis.
   */
  const [modalAvisEstVisible, setModalAvisEstVisible] = useState<boolean>(false);

  const [formationToken, setFormationToken] = useState<string | null>(null);

  const handleModalAjoutAvis = (token: string) => {
    setFormationToken(token);
    setModalAvisEstVisible(true);
  };

  return (
    <div className='mx-auto mt-2 shadow-md rounded-md bg-white overflow-hidden'>
      <div className='flex flex-col sm:flex-row items-center justify-between p-5'>
        <h3 className='text-secondary-2 poppins-semibold text-center md:text-left'>Progression de mes formations</h3>
        <Link to='/mes-formations' className='text-sm text-secondary-1/90 flex items-center hover:text-primary'>
          Tout voir
          <ChevronRightIcon className='w-3 h-3' />
        </Link>
      </div>
      {formations && formations.length > 0 ? (
        formations.slice(0, 4).map((session, index) => (
          <div
            key={index}
            className={
              session.etape_en_cours_texte === 'Souhait refusé'
                ? 'border-b border-secondary-1/10 mx-auto p-5 opacity-60'
                : 'border-b border-secondary-1/10 mx-auto p-5'
            }>
            <div className='sm:flex justify-between items-start gap-2 text-secondary-1'>
              <div className='flex justify-center md:justify-start gap-2 mb-5'>
                {session.formation.image_couverture ? (
                  <img
                    alt={session.formation.nom}
                    src={session.formation.image_couverture}
                    tabIndex={0}
                    className='rounded-lg shadow-md object-cover w-10 h-10 mt-1'
                    loading='lazy'
                  />
                ) : (
                  <div className={`rounded-lg shadow-md object-cover w-10 h-10 mt-1 bg-gray-700 flex items-center justify-center`}>
                    <AcademicCapIcon className='h-7 w-7 text-white/50' />
                  </div>
                )}
                <div>
                  <h3 className='text-lg poppins-medium text-left'>{session.formation.nom}</h3>
                  <div className='text-secondary-1/80 flex flex-row items-center gap-1 text-xs'>
                    <span>
                      <CalendarDaysIcon className='w-5 h-5' />
                    </span>
                    {session.groupe && session.groupe.date_debut !== null && session.groupe.date_fin !== null ? (
                      <DatesDisplay date_start={session.groupe.date_debut} date_end={session.groupe.date_fin} />
                    ) : (
                      <span className='text-xs font-medium pl-0'>Dates non définies</span>
                    )}
                  </div>
                </div>
              </div>
              <div className='flex items-center justify-center md:justify-start'>
                <div>
                  <MapPinIcon className='w-5 h-5 flex-none' />
                </div>
                {session.site ? <span className='text-sm'>{session.site.nom}</span> : <span className='text-sm'>Lieu non défini</span>}
              </div>
            </div>
            <p className='text-sm text-secondary-1 font-normal text-center sm:text-right mt-2 md:mt-0 mb-2'>{session.etape_en_cours_texte}</p>
            <Progress
              value={session.pourcentage}
              color={session.souhait_refuse || session.demande_refusee ? 'gray' : session.pourcentage === 100 ? 'green' : 'orange'}
              size='lg'
            />
            {session.peut_rediger_avis === true && (
              <div className='mt-4 md:flex items-center gap-3'>
                <p className='text-sm poppins-medium text-secondary-1 text-center md:text-left'>
                  Vous pouvez désormais déposer un avis si vous le souhaitez&nbsp;:
                </p>
                <div className='w-full text-center md:text-right'>
                  <button
                    className='bg-primary px-3 py-1 text-white text-sm rounded-md hover:shadow-lg cursor-pointer'
                    onClick={() => handleModalAjoutAvis(session.formation.slug)}>
                    Rédiger un avis
                  </button>
                </div>
              </div>
            )}
          </div>
        ))
      ) : (
        <p className='p-5 text-center text-sm font-medium text-secondary-1/70'>Aucune session de formation pour le moment.</p>
      )}
      <ModalAjoutAvis visible={modalAvisEstVisible} setVisible={setModalAvisEstVisible} formationToken={formationToken} />
    </div>
  );
};

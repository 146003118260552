import { FunctionComponent } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Demande } from '../../types/AdminRessources';
import { dateEstCompriseDansIntervalle, ajouterJour } from '../../utils/Temps';
import { FormationSessionDisplay } from '../FormationSessionDisplay';
import { EntrepriseDisplay } from '../EntrepriseDisplay';
import { UserDisplay } from '../UserDisplay';

/**
 * Ce composant est un encart du tableau de bord administrateur qui permet de valider ou non une entreprise.
 *
 * @param demandes - Tableau de demandes en attente de réponse de la part de l'admin.
 * @param handleIndex - Callback pour effectuer un appel de rechargement.
 */
export const DashboardAdminDemandesATraiter: FunctionComponent<{
  demandes: Demande[];
}> = ({ demandes }) => {
  /**
   * Date du jour.
   *
   * @constant
   */
  const currentDate = new Date();

  /**
   * Permet au composant de changer de page.
   */
  const navigate = useNavigate();

  return (
    <div>
      {demandes?.length > 0 ? (
        <table className='table-fixed min-w-full bg-white rounded-b-lg'>
          <thead>
            <tr className='w-full h-12 border-gray-300  border-b py-8'>
              <th className='py-4 text-secondary-1 pr-4 text-left text-sm tracking-normal leading-4'>Formation</th>
              <th className='py-4 text-secondary-1 pr-4 text-left text-sm tracking-normal leading-4'>Entreprise</th>
              <th className='py-4 text-secondary-1 pr-4 text-left text-sm tracking-normal leading-4'>Salarié</th>
              <th className='py-4 text-secondary-1 text-left text-sm tracking-normal leading-4'>Décision</th>
            </tr>
          </thead>
          <tbody>
            {demandes &&
              demandes.map((demande) => (
                <tr
                  key={demande.id}
                  className={`border-gray-100 border-b hover:bg-gray-100 ${demande.est_archive && 'bg-gray-200'}`}
                  onDoubleClick={() => navigate(`/admin/demandes/${demande.id}`)}>
                  <td className='pr-4 py-3'>
                    <FormationSessionDisplay formation={demande.formation} groupe={demande.groupe} />
                  </td>
                  <td className='pr-4 py-3'>
                    {demande.entreprise && (
                      <Link to={'/admin/entreprises/editer/' + demande.entreprise.id} target='blank'>
                        <EntrepriseDisplay entreprise={demande.entreprise} />
                      </Link>
                    )}
                  </td>
                  <td className='pr-4 py-3'>{demande.user && <UserDisplay user={demande.user} />}</td>
                  <td className='py-3 text-gray-800 text-xs'>
                    <div>
                      {`Émis le ${new Intl.DateTimeFormat('default', {
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        hour12: false,
                      }).format(new Date(demande.created_at as string))}`}
                    </div>
                    {demande.date_acceptation === null && demande.date_refus === null ? (
                      <span
                        className={
                          demande.created_at &&
                          demande.date_limite_admin_reponse_demande &&
                          dateEstCompriseDansIntervalle(
                            new Date(demande.created_at),
                            ajouterJour(new Date(demande.date_limite_admin_reponse_demande), -1),
                            currentDate,
                          )
                            ? currentDate.getDate() === ajouterJour(new Date(demande.date_limite_admin_reponse_demande), -1).getDate()
                              ? 'text-orange-200'
                              : ''
                            : 'text-red-700'
                        }>
                        En attente
                        {demande.date_limite_admin_reponse_demande
                          ? `, vous avez jusqu'au  ${new Intl.DateTimeFormat('default', {
                              year: 'numeric',
                              month: 'numeric',
                              day: 'numeric',
                              hour12: false,
                            }).format(new Date(demande.date_limite_admin_reponse_demande as string))} pour répondre`
                          : ''}
                      </span>
                    ) : demande.date_acceptation ? (
                      `Acceptée le ${new Intl.DateTimeFormat('default', {
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        hour12: false,
                      }).format(new Date(demande.date_acceptation as string))}`
                    ) : demande.date_refus ? (
                      `Refusée le : ${new Intl.DateTimeFormat('default', {
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        hour12: false,
                      }).format(new Date(demande.date_refus as string))}`
                    ) : (
                      ''
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      ) : (
        <p className='p-5 text-center text-sm font-medium text-secondary-1/70'>Aucune demande à traiter pour le moment.</p>
      )}
    </div>
  );
};

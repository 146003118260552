import { Fragment, FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';
import toast from 'react-hot-toast';
import { Config } from '../../config/config';
import { ToastInfo } from '../../components/toasts/ToastInfo';
import { useUser } from '../../providers/UserProvider';
import { Profil } from '../../types/auth';
import { useNavigate } from 'react-router-dom';
import { UserLocal } from '../../types/auth';
import { InputSwitch } from '../../components/Inputs/InputSwitch';
import { Tooltip } from '@material-tailwind/react';
import { CheckIcon, EyeSlashIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Notification } from '../../types/AdminRessources';

/**
 * Ce composant permet d'afficher la page des notifications de l'utilisateur.
 */
export const NotificationsScreen: FunctionComponent = () => {
  /**
   * On a besoin des information de l'utilisateur courant ainsi que du profil. On a également besoin d'intéragir avec les notifications. On rappelle que le fait de cliquer sur certaines notification peut faire changer de profil.
   */
  const {
    user,
    profil,
    changeProfil,
    notifications,
    viewNotification,
    maskNotification,
    filtreEstVue,
    tooggleFiltreEstVue,
    filtreEstMasquee,
    tooggleFiltreEstMasquee,
  } = useUser();

  /**
   * Permet au composant de changer de page.
   */
  const navigate = useNavigate();

  if (notifications === null) {
    return <Fragment>Chargement...</Fragment>;
  }

  return (
    <div className='shadow-xl rounded-lg mt-7 pb-2 bg-white'>
      <Helmet>
        <title>Notifications - {Config.app_label}</title>
      </Helmet>

      <div className='px-4 md:px-5 py-4 md:py-5 bg-gray-100 rounded-tl-lg rounded-tr-lg mb-7'>
        <div className='flex flex-col sm:flex-row items-center justify-between'>
          <div className='flex flex-row gap-3 basis-full  w-full sm:basis-1/2 lg:basis-3/5'>
            <h1 className='text-lg md:text-xl lg:text-2xl poppins-bold leading-normal text-secondary-1'>Notifications</h1>
          </div>
          <div className='flex flex-col md:flex-row gap-4 items-center'>
            <div>
              <InputSwitch checked={filtreEstVue} onChange={() => tooggleFiltreEstVue(!filtreEstVue)} label={'Voir notifications vues'} />
            </div>
            <div>
              <InputSwitch checked={filtreEstMasquee} onChange={() => tooggleFiltreEstMasquee(!filtreEstMasquee)} label={'Voir notifications masquées'} />
            </div>
          </div>
        </div>
      </div>

      {notifications && notifications.length > 0 ? (
        notifications.map((notification) => (
          <div
            key={notification.id}
            className={`sm:flex justify-between gap-2 w-full max-w-lg mx-auto mb-4 rounded-lg shadow-lg p-5 ${
              notification.est_vu
                ? notification.est_masque
                  ? 'shadow-none opacity-50 border-gray-500 border-dashed border'
                  : 'shadow-none border border-gray-300'
                : ''
            }`}>
            <div className='w-full flex flex-row justify-between gap-2'>
              <div className='w-[35px]'>
                {notification.categorie === 'info' && (
                  <div className='rounded-full w-full aspect-1 bg-[#0E65D0] text-white flex items-center justify-center poppins-semibold text-lg'>i</div>
                )}
                {notification.categorie === 'success' && (
                  <div className='rounded-full w-full aspect-1 bg-[#16AE26] text-white flex items-center justify-center'>
                    <CheckIcon className='w-5 h-5' strokeWidth={2.2} />
                  </div>
                )}
                {notification.categorie === 'warning' && (
                  <div className='rounded-full w-full aspect-1 bg-[#f9a534] text-white flex items-center justify-center poppins-semibold text-lg'>!</div>
                )}
                {notification.categorie === 'error' && (
                  <div className='rounded-full w-full aspect-1 bg-[#D00E0E] text-white flex items-center justify-center'>
                    <XMarkIcon className='w-5 h-5' strokeWidth={2.3} />
                  </div>
                )}
              </div>
              <div className='basis-4/5'>
                <h3
                  className='text-lg poppins-semibold text-secondary-1 cursor-pointer'
                  onClick={() => {
                    viewNotification(notification.id).then(() => {
                      if (profil && notification.profil_id && profil.id !== notification.profil_id) {
                        changeProfil(
                          (user as UserLocal).profils.find((profil) => profil.id === (notification.profil_id as Notification['profil_id'] as number)) as Profil,
                        );
                        toast.custom(<ToastInfo>Vous avez changé de profil !</ToastInfo>);
                      }
                    });
                    navigate(notification.url as string);
                  }}>
                  {notification.titre}
                </h3>

                <p className='text-secondary-1/70 text-xs'>{notification.description}</p>
                <p className='text-gray-600 text-xs poppins-semibold'>
                  Créée le :{' '}
                  {notification.created_at
                    ? new Intl.DateTimeFormat('default', {
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: false,
                      }).format(new Date(notification.created_at as string))
                    : ''}
                </p>
              </div>
              <div
                className='basis-1/5 flex flex-col gap-y-4
                   items-end text-center sm:text-right pt-4 sm:mt-0'>
                {!notification.est_masque && (
                  <Tooltip content='Masquer'>
                    <button
                      className='text-secondary-1 block'
                      onClick={() => {
                        maskNotification(notification.id).then(() => {
                          toast.custom(<ToastInfo>La notification a été masquée</ToastInfo>);
                        });
                      }}>
                      <EyeSlashIcon strokeWidth={2.2} className='w-5 h-5' />
                    </button>
                  </Tooltip>
                )}
                {!notification.est_vu && (
                  <Tooltip content='Marquer comme vu'>
                    <button
                      className='text-secondary-1 block'
                      onClick={() => {
                        viewNotification(notification.id).then(() => {
                          toast.custom(<ToastInfo>La notification a été vue</ToastInfo>);
                        });
                      }}>
                      <CheckIcon strokeWidth={2.2} className='w-5 h-5' />
                    </button>
                  </Tooltip>
                )}
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className='p-5 pt-0 text-center'>Aucune notification pour le moment.</div>
      )}
    </div>
  );
};

import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { CheckIcon, EllipsisVerticalIcon, ExclamationTriangleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Helmet } from 'react-helmet';
import { Menu, MenuHandler, Button, MenuList, MenuItem } from '@material-tailwind/react';
import { useNavigate } from 'react-router-dom';
import { LoadingAbsolute } from '../../../components/LoadingAbsolute';
import { useApi } from '../../../providers/ApiProvider';
import { Demande } from '../../../types/EntreprisesRessources';
import { GoodApiResponse, ErrorApiResponse } from '../../../types/api';
import { Config } from '../../../config/config';
import { handleError } from '../../../utils/ErrorHandler';
import { UserDisplay } from '../../../components/UserDisplay';
import { FormationSessionDisplay } from '../../../components/FormationSessionDisplay';

/**
 * Ce composant affiche la liste des demandes de l'entreprise
 */
export const EntrepriseDemandesScreen: FunctionComponent = () => {
  /**
   * On stocke si on est en train d'utiliser l'API ou non.
   */
  const [apiIsLoading, setApiIsLoading] = useState(false);

  /**
   * On stocke les Souhait possédés par le profil.
   */
  const [demandes, setDemandes] = useState<Demande[] | null>(null);

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  /**
   * Permet au composant de changer de page.
   */
  const navigate = useNavigate();

  /**
   * Permet de recharger les données au chargement de page
   */
  useEffect(() => {
    handleIndex();
  }, [client]);

  /**
   * Cette méthode va chercher les informations dans l'API pour afficher les demandes.
   */
  const handleIndex = () => {
    setApiIsLoading(true);

    client
      .get<GoodApiResponse<Demande[]>>(`/entreprise/demandes`)
      .then((response) => {
        setDemandes(response.data.data);
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  if (demandes === null) {
    return <Fragment>Chargement...</Fragment>;
  }

  return (
    <Fragment>
      <Helmet>
        <title>Demandes de formation - {Config.app_label}</title>
      </Helmet>
      <div className='px-4 py-4 md:py-3 bg-gray-100 rounded-t-lg flex flex-col sm:flex-row gap-7 items-center'>
        <div className='sm:flex items-center justify-between'>
          <h1 className='text-base sm:text-lg md:text-xl poppins-bold leading-normal text-secondary-1'>Demandes de formation</h1>
        </div>
      </div>
      <div>
        <div className='bg-white rounded-b-lg w-full overflow-x-auto'>
          {demandes.length > 0 ? (
            <table className='w-full min-w-[1200px] bg-white rounded-b-lg'>
              <thead className='text-sm text-gray-500 text-left border-b'>
                <tr>
                  <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Numéro</th>
                  <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Formation et session</th>
                  <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Salarié</th>
                  <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Avancement</th>
                  <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Décision</th>
                  <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'></th>
                </tr>
              </thead>
              <tbody>
                {demandes.map((demande) => (
                  <tr
                    key={demande.formation.nom}
                    className={`border-gray-100 border-b hover:bg-gray-100 `}
                    onDoubleClick={() => navigate(`/entreprise/demandes/${demande.token}`)}>
                    <td className='pl-4 py-3 text-secondary-1 text-xs'>{demande.id?.toString().padStart(6, '0')}</td>
                    <td className='px-4 py-3'>
                      <FormationSessionDisplay formation={demande.formation} groupe={demande.groupe} />
                    </td>
                    <td className='px-4 py-3'>
                      <UserDisplay user={demande.user} />
                    </td>
                    <td className='px-4 py-3 text-gray-800 poppins--medium text-xs min-w-[220px]'>
                      <div>
                        {/*<div>
                        {demande.est_en_attente_informations ? (
                          <ExclamationTriangleIcon className='w-4 h-4 inline-block mx-auto text-red-300' />
                        ) : (
                          <XMarkIcon className='w-4 h-4 inline-block mx-auto text-gray-900/20' />
                        )}{' '}
                        En attente d'informations
                      </div>*/}
                        <div>
                          {demande.est_en_attente_documents ? (
                            <ExclamationTriangleIcon className='w-4 h-4 inline-block mx-auto text-red-300' />
                          ) : (
                            <XMarkIcon className='w-4 h-4 inline-block mx-auto text-gray-900/20' />
                          )}{' '}
                          Documents en attente
                        </div>
                        <div>
                          {demande.est_complete ? (
                            <CheckIcon className='w-4 h-4 text-white inline-block mx-auto bg-light-green-300 rounded-full p-0.5' />
                          ) : (
                            <XMarkIcon className='w-4 h-4 inline-block mx-auto text-gray-900/20' />
                          )}{' '}
                          Demande complète
                        </div>
                        <div>
                          {demande.est_paye ? (
                            <CheckIcon className='w-4 h-4 text-white inline-block mx-auto bg-light-green-300 rounded-full p-0.5' />
                          ) : (
                            <XMarkIcon className='w-4 h-4 inline-block mx-auto text-gray-900/20' />
                          )}{' '}
                          Paiement effectué
                        </div>
                        <div>
                          {demande.est_finalise ? (
                            <CheckIcon className='w-4 h-4 text-white inline-block mx-auto bg-light-green-300 rounded-full p-0.5' />
                          ) : (
                            <XMarkIcon className='w-4 h-4 inline-block mx-auto text-gray-900/20' />
                          )}{' '}
                          Finalisée
                        </div>
                      </div>
                    </td>
                    <td className='px-4 py-3 text-gray-800 text-xs min-w-[100px]'>
                      <div>
                        {`Émise le ${new Intl.DateTimeFormat('default', {
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                          hour12: false,
                        }).format(new Date(demande.created_at as string))}`}
                      </div>
                      {demande.date_acceptation === null && demande.date_refus === null
                        ? 'En attente'
                        : demande.date_acceptation
                          ? `Acceptée le ${new Intl.DateTimeFormat('default', {
                              year: 'numeric',
                              month: 'numeric',
                              day: 'numeric',
                              hour12: false,
                            }).format(new Date(demande.date_acceptation as string))}`
                          : demande.date_refus
                            ? `Refusée le : ${new Intl.DateTimeFormat('default', {
                                year: 'numeric',
                                month: 'numeric',
                                day: 'numeric',
                                hour12: false,
                              }).format(new Date(demande.date_refus as string))}`
                            : ''}
                    </td>
                    <td className='px-4 py-3 text-gray-800 text-sm'>
                      <Menu placement='right-start'>
                        <MenuHandler>
                          <Button
                            variant='text'
                            className='w-9 h-9 p-1.5 rounded-full text-gray-500 block mx-auto text-center mr-0 hover:bg-white/100 active:bg-transparent'>
                            <EllipsisVerticalIcon className='h-6 w-6' />
                          </Button>
                        </MenuHandler>
                        <MenuList>
                          <MenuItem onClick={() => navigate(`/entreprise/demandes/${demande.token}`)}>Voir la demande</MenuItem>
                          <MenuItem onClick={() => window.open(`/formations/${demande.formation.slug}`, '_blank')}>Voir la page de la formation</MenuItem>
                        </MenuList>
                      </Menu>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className='text-center px-4 py-7'>Vous n'avez pas encore de demande.</div>
          )}
        </div>
        {apiIsLoading ? <LoadingAbsolute /> : ''}
      </div>
    </Fragment>
  );
};

import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingAbsolute } from '../../../components/LoadingAbsolute';
import { useApi } from '../../../providers/ApiProvider';
import { HeaderForm } from '../../../components/HeaderForm';
import { DropdownRessource } from '../../../types';
import { GoodApiResponse, ErrorApiResponse, BaseIndexParams, PaginatedApiResponse } from '../../../types/api';
import { Helmet } from 'react-helmet';
import { ReadOnlyNumber } from '../../../components/Inputs/ReadOnlyNumber';
import { Demande, DemandeDocument, /*DemandeInformation,*/ Document } from '../../../types/AdminRessources';
import { ReadOnlyText } from '../../../components/Inputs/ReadOnlyText';
import { ConventionCollective, Entreprise, FormeJuridique, Octa, TypeEmployeur } from '../../../types/schematics';
import { ReadOnlyDateTime } from '../../../components/Inputs/ReadOnlyDateTime';
import { ReadOnlySelect } from '../../../components/Inputs/ReadOnlySelect';
import { ReadOnlyBoolean } from '../../../components/Inputs/ReadOnlyBoolean';
import { Logo } from '../../../components/Logo';
import { ReadOnlyTrilean } from '../../../components/Inputs/ReadOnlyTrilean';
import { AcademicCapIcon, BookOpenIcon, CalendarDaysIcon, CheckIcon, ExclamationTriangleIcon, MapPinIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Avatar } from '../../../components/Avatar';
import nl2br from 'react-nl2br';
import { handleError } from '../../../utils/ErrorHandler';
import { Tab, TabPanel, Tabs, TabsBody, TabsHeader } from '../../../components/Onglets';
import { FormationSessionDisplay } from '../../../components/FormationSessionDisplay';
import { UserWithMailDisplay } from '../../../components/UserWithMailDisplay';
import { DownloadLink } from '../../../components/DownloadLink';

/**
 * Ce composant doit afficher un formulaire qui à 3 modes de fonctionnement. Les 2 principaux sont la création et l'édition.
 * On a également un mode lecture seule. L'idée c'est de gérer une partie du CRUD.
 * C'est dans ce composant que vont se faire les appels API pour créer, éditer et voir une ressource en particulier.
 *
 * @param modeFormulaire - Mode du formulaire (creer, editer et voir).
 * @returns JSX du composant
 */
export const AdmingcDemandeScreen: FunctionComponent = () => {
  // On initialise les données dont on va avoir besoin sur cette page

  /**
   * On stocke si on est en train d'utiliser l'API ou non.
   */
  const [apiIsLoading, setApiIsLoading] = useState(false);

  /**
   * On stocke l'état des formes juridiques.
   */
  const [formeJuridiques, setFormeJuridiques] = useState<DropdownRessource[] | null>(null);

  /**
   * On stocke l'état des types d'employeurs.
   */
  const [typeEmployeurs, setTypeEmployeurs] = useState<DropdownRessource[] | null>(null);

  /**
   * On stocke l'état des convention collectives.
   */
  const [conventionCollectives, setConventionCollectives] = useState<DropdownRessource[] | null>(null);

  /**
   * On stocke l'état des octas.
   */
  const [octas, setOctas] = useState<DropdownRessource[] | null>(null);

  /**
   * On stocke l'état des document liés à la demande.
   */
  const [documents, setDocuments] = useState<Document[] | null>(null);

  /**
   * Permet de stocker les informations relatives à la demande.
   */
  const [demande, setDemande] = useState<Demande | null>(null);

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  const navigate = useNavigate();

  const params = useParams();

  useEffect(() => {
    handleIndex();
    handleShow();
  }, [client]);

  /**
   * Retrouve Les ressources nécessaires pour afficher les dropdown et les stockent dans des .
   *
   * @param indexParams - Paramètres de la recherche.
   */
  const handleIndex = () => {
    setApiIsLoading(true);
    const baseParams: BaseIndexParams = { as_foreign: true, no_pagination: true };

    const promises = [
      client.get<PaginatedApiResponse<FormeJuridique>, BaseIndexParams>('/admingc/formes-juridiques', { params: baseParams }),
      client.get<PaginatedApiResponse<TypeEmployeur>, BaseIndexParams>('/admingc/types-employeurs', { params: baseParams }),
      client.get<PaginatedApiResponse<ConventionCollective>, BaseIndexParams>('/admingc/conventions-collectives', { params: baseParams }),
      client.get<PaginatedApiResponse<Octa>, BaseIndexParams>('/admingc/octas', { params: baseParams }),
      client.get<GoodApiResponse<Document[]>, { params: { demande_id: Demande['id'] } }>('/admingc/documents', {
        params: {
          demande_id: parseInt(params.id as string),
        },
      }),
    ];

    Promise.all(promises)
      .then((response) => {
        setFormeJuridiques(response[0].data.data as unknown as DropdownRessource[]);
        setTypeEmployeurs(response[1].data.data as unknown as DropdownRessource[]);
        setConventionCollectives(response[2].data.data as unknown as DropdownRessource[]);
        setOctas(response[3].data.data as unknown as DropdownRessource[]);
        setDocuments(response[4].data.data as unknown as Document[]);
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Retrouve par le réseau la ressource liée à l'ID foruni et la met dans un état local.
   * Ne doit être utilisé qu'en mode édition et lecture seule.
   *
   * @param ressourceId - ID de la ressource dont on veut retrouver les informations.
   */
  const handleShow = () => {
    if (apiIsLoading === true) {
      return;
    }

    setApiIsLoading(true);
    client
      .get<GoodApiResponse<Demande>>(`/admingc/demandes/${params.id}`)
      .then((response) => {
        setDemande(response.data.data as unknown as Demande);
      })
      .catch((error: ErrorApiResponse<Demande & { id: number; entreprise: Entreprise }>) => {
        handleError(error, () => {
          navigate('/admingc/entreprises');
        });
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Paramètres tabs
   * @constant
   */
  const data = [
    {
      label: 'Général',
      value: 'general',
    },
    {
      label: 'Demandes de documents',
      value: 'demandeDocuments',
    },
    {
      label: 'Documents salariés',
      value: 'documents',
    },
  ] as const;

  if (demande === null || documents === null) {
    return <Fragment>Chargement...</Fragment>;
  }

  return (
    <Fragment>
      <Helmet>
        <title>Demande</title>
      </Helmet>
      <HeaderForm text={`Demande`} returnUrl='/admingc/demandes' />
      <Tabs value='general'>
        <TabsHeader>
          {data.map(({ label, value }) => (
            <Tab key={value} value={value}>
              {label}
            </Tab>
          ))}
        </TabsHeader>
        <TabsBody>
          <TabPanel value='general'>
            <form className='relative'>
              <div className='bg-white rounded-b-lg p-5'>
                <div className='container mx-auto bg-white rounded-none'>
                  <fieldset className='flex flex-col md:flex-row gap-3 pb-7 mb-7'>
                    <div className='basis-3/5 md:border-r border-secondary-1/30'>
                      <legend className='text-secondary-1 poppins-semibold text-lg mb-3'>Formation</legend>
                      <FormationSessionDisplay formation={demande.formation} groupe={demande.groupe} />
                    </div>
                    <div className='basis-2/5'>
                      <legend className='text-secondary-1 poppins-semibold text-lg mb-3'>Souhait</legend>
                      <div>
                        {demande.souhait.date_acceptation === null && demande.souhait.date_refus === null
                          ? 'En attente'
                          : demande.souhait.date_acceptation
                            ? `Acceptée le ${new Intl.DateTimeFormat('default', {
                                year: 'numeric',
                                month: 'numeric',
                                day: 'numeric',
                                hour12: false,
                              }).format(new Date(demande.souhait.date_acceptation as string))}`
                            : demande.souhait.date_refus
                              ? `Refusée le : ${new Intl.DateTimeFormat('default', {
                                  year: 'numeric',
                                  month: 'numeric',
                                  day: 'numeric',
                                  hour12: false,
                                }).format(new Date(demande.souhait.date_refus as string))}`
                              : ''}
                      </div>
                      {demande.created_at && (
                        <div>{`Demande envoyée le ${new Intl.DateTimeFormat('default', {
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                          hour: 'numeric',
                          minute: 'numeric',
                          hour12: false,
                        }).format(new Date(demande.created_at as string))}`}</div>
                      )}
                    </div>
                  </fieldset>

                  <fieldset className='pb-7 mb-7 border-b'>
                    <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-2'>
                      <div>
                        <legend className='text-secondary-1 poppins-semibold text-lg mb-3'>Avancement</legend>
                        <div>
                          <ReadOnlyBoolean label='Est archivé' value={demande.est_archive} />
                        </div>
                        <div>
                          <ReadOnlyDateTime label='Date acceptation' value={demande.date_acceptation} />
                        </div>
                        <div>
                          <div>
                            <ReadOnlyDateTime label='Date refus' value={demande.date_refus} />
                          </div>
                          {demande.motif_refus && <div>Motif de refus : {demande.motif_refus}</div>}
                        </div>
                      </div>
                      <div className='sm:border-l xl:border-r border-secondary-1/30 md:px-3'>
                        <div className='text-secondary-1 poppins-semibold text-lg mb-3'>Statut</div>
                        {/*<div>
                          {demande.est_en_attente_informations ? (
                            <ExclamationTriangleIcon className='w-4 h-4 inline-block mx-auto text-red-300' />
                          ) : (
                            <XMarkIcon className='w-4 h-4 inline-block mx-auto text-gray-900/20' />
                          )}{' '}
                          En attente d'informations
                          </div>*/}
                        <div>
                          {demande.est_en_attente_documents ? (
                            <ExclamationTriangleIcon className='w-4 h-4 inline-block mx-auto text-red-300' />
                          ) : (
                            <XMarkIcon className='w-4 h-4 inline-block mx-auto text-gray-900/20' />
                          )}{' '}
                          Documents en attente
                        </div>
                        <div>
                          {demande.est_complete ? (
                            <CheckIcon className='w-4 h-4 text-white inline-block mx-auto bg-light-green-300 rounded-full p-0.5' />
                          ) : (
                            <XMarkIcon className='w-4 h-4 inline-block mx-auto text-gray-900/20' />
                          )}{' '}
                          Demande complète
                        </div>
                        <div>
                          {demande.est_paye ? (
                            <CheckIcon className='w-4 h-4 text-white inline-block mx-auto bg-light-green-300 rounded-full p-0.5' />
                          ) : (
                            <XMarkIcon className='w-4 h-4 inline-block mx-auto text-gray-900/20' />
                          )}{' '}
                          Paiement effectué
                        </div>
                        {demande.date_paiement && (
                          <div>
                            Date paiement :{' '}
                            {new Intl.DateTimeFormat('default', {
                              year: 'numeric',
                              month: 'numeric',
                              day: 'numeric',
                            }).format(new Date(demande.date_paiement))}
                          </div>
                        )}
                        {/*demande.informations_paiement && <div>Informations du paiement : {demande.informations_paiement}</div>*/}
                        <div>
                          {demande.est_finalise ? (
                            <CheckIcon className='w-4 h-4 text-white inline-block mx-auto bg-light-green-300 rounded-full p-0.5' />
                          ) : (
                            <XMarkIcon className='w-4 h-4 inline-block mx-auto text-gray-900/20' />
                          )}{' '}
                          Finalisée
                        </div>
                        <div>
                          {demande.est_archive && (
                            <Fragment>
                              <BookOpenIcon className='w-4 h-4 inline-block mx-auto text-gray-900/20' /> Archivée
                            </Fragment>
                          )}
                        </div>
                      </div>
                      {demande.user && (
                        <div className='md:px-2'>
                          <legend className='text-secondary-1 poppins-semibold text-lg mb-3'>Salarié</legend>
                          <UserWithMailDisplay user={demande.user} />
                        </div>
                      )}
                    </div>
                  </fieldset>

                  {demande.entreprise && (
                    <fieldset>
                      <legend className='text-secondary-1 poppins-semibold text-lg mt-7 mb-3'>Entreprise</legend>
                      <h3 className='text-secondary-2 mt-7 mb-3'>Identité</h3>
                      <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-2 items-center'>
                        <a href={'/admingc/entreprises/editer/' + demande.entreprise.id} target='_blank' rel='noreferrer'>
                          <Logo className='rounded h-10 w-10 object-cover' src={demande.entreprise.image_logo} />
                        </a>
                        <div>
                          <ReadOnlyText label='Raison sociale' value={demande.entreprise.raison_sociale} />
                        </div>
                        <div>
                          <ReadOnlyText label='Enseigne' value={demande.entreprise.enseigne} />
                        </div>
                        <div>
                          <ReadOnlyText label='Numéro de SIRET' value={demande.entreprise.siret} />
                        </div>
                        <div>
                          <ReadOnlyText label='Code naf' value={demande.entreprise.code_naf} />
                        </div>
                        <div>
                          <ReadOnlySelect
                            label='Forme juridique'
                            value={demande.entreprise.forme_juridique_id as number}
                            options={formeJuridiques as DropdownRessource[]}
                          />
                        </div>
                        <div>
                          <ReadOnlySelect
                            label={"Type d'employeur"}
                            value={demande.entreprise.type_employeur_id as number}
                            options={typeEmployeurs as DropdownRessource[]}
                          />
                        </div>
                        <div>
                          <ReadOnlySelect
                            label={'Convention collective'}
                            value={demande.entreprise.convention_collective_id as number}
                            options={conventionCollectives as DropdownRessource[]}
                          />
                        </div>
                        <div>
                          <ReadOnlySelect label={'Octa'} value={demande.entreprise.octa_id as number} options={octas as DropdownRessource[]} />
                        </div>
                        <div>
                          <ReadOnlyDateTime label='Date création' value={demande.entreprise.date_creation} />
                        </div>
                        <div>
                          <ReadOnlyDateTime label='Date modification' value={demande.entreprise.date_modification} />
                        </div>
                        <div>
                          <ReadOnlyDateTime label='Date radiation' value={demande.entreprise.date_radiation} />
                        </div>
                        <div>
                          <ReadOnlyNumber label='Effectif' value={demande.entreprise.effectif} />
                        </div>
                        <div>
                          <ReadOnlyText label='Numéro repertoire métiers' value={demande.entreprise.num_rep_metier} />
                        </div>
                        <div>
                          <ReadOnlyText label='Numéro de TVA intracommunautaire' value={demande.entreprise.num_tva_intracom} />
                        </div>
                        <div>
                          <ReadOnlyBoolean label='Assujetti taxe' value={demande.entreprise.est_assujetti_taxe as boolean} />
                        </div>
                      </div>

                      <h3 className='text-secondary-2 mt-7 mb-3'>Adresse domiciliation</h3>
                      <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-2'>
                        <div>
                          <ReadOnlyText label='Adresse 1' value={demande.entreprise.adr1} />
                        </div>
                        <div>
                          <ReadOnlyText label='Adresse 2' value={demande.entreprise.adr2} />
                        </div>
                        <div>
                          <ReadOnlyText label='Adresse 3' value={demande.entreprise.adr3} />
                        </div>
                        <div>
                          <ReadOnlyText label='Adresse 4' value={demande.entreprise.adr4} />
                        </div>
                        <div>
                          <ReadOnlyText label='Code postal' value={demande.entreprise.code_postal} />
                        </div>
                        <div>
                          <ReadOnlyText label='Ville' value={demande.entreprise.ville} />
                        </div>
                        <div>
                          <ReadOnlyText label='Pays' value={demande.entreprise.pays} />
                        </div>
                      </div>

                      <h3 className='text-secondary-2 mt-7 mb-3'>Adresse facturation</h3>
                      <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-2'>
                        <div>
                          <ReadOnlyText label='Adresse 1' value={demande.entreprise.fact_adr1} />
                        </div>
                        <div>
                          <ReadOnlyText label='Adresse 2' value={demande.entreprise.fact_adr2} />
                        </div>
                        <div>
                          <ReadOnlyText label='Adresse 3' value={demande.entreprise.fact_adr3} />
                        </div>
                        <div>
                          <ReadOnlyText label='Adresse 4' value={demande.entreprise.fact_adr4} />
                        </div>
                        <div>
                          <ReadOnlyText label='Code postal' value={demande.entreprise.fact_code_postal} />
                        </div>
                        <div>
                          <ReadOnlyText label='Ville' value={demande.entreprise.fact_ville} />
                        </div>
                        <div>
                          <ReadOnlyText label='Pays' value={demande.entreprise.fact_pays} />
                        </div>
                      </div>
                      <h3 className='text-secondary-2 mt-7 mb-3'>Contact</h3>
                      <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-2'>
                        <div>
                          <ReadOnlyText label='Site web' value={demande.entreprise.site_web} />
                        </div>
                      </div>
                      <h3 className='text-secondary-2 mt-7 mb-3'>Administration</h3>
                      <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-2'>
                        <div>
                          <ReadOnlyTrilean label='Approuvée' value={demande.entreprise.est_approuvee} />
                        </div>
                        <div className='w-1/2'>
                          <ReadOnlyNumber label='YParéo CodeEntreprise' value={demande.entreprise.yp_code} />
                        </div>
                      </div>
                    </fieldset>
                  )}
                </div>
              </div>
            </form>
          </TabPanel>
          <TabPanel value='demandeDocuments'>
            <div className='bg-white w-full overflow-x-auto'>
              <table className='table-fixed min-w-full bg-white rounded-b-lg'>
                <thead className='text-sm text-gray-500 text-left border-b'>
                  <tr>
                    <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Question</th>
                    <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Fichier</th>
                    <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Est traité</th>
                    <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Créée le</th>
                  </tr>
                </thead>
                <tbody>
                  {(demande.documents as DemandeDocument[]).map((document) => (
                    <tr key={document.id} className=' border-gray-100 border-b hover:bg-gray-100'>
                      <td className='px-4 py-3 text-gray-800 text-sm'>{document.question}</td>
                      <td className='px-4 py-3 text-gray-800 text-sm'>
                        {document.fichier && <DownloadLink fichier={document.fichier} titre={document.titre} />}
                      </td>
                      <td className='px-4 py-3 text-gray-800 text-sm'>{document.est_traite ? 'Oui' : 'Non'}</td>
                      <td className='px-4 py-3 text-gray-800 text-sm'>
                        {document.created_at
                          ? new Intl.DateTimeFormat('default', {
                              year: 'numeric',
                              month: 'numeric',
                              day: 'numeric',
                              hour: 'numeric',
                              minute: 'numeric',
                              second: 'numeric',
                              hour12: false,
                            }).format(new Date(document.created_at as string))
                          : ''}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </TabPanel>
          <TabPanel value='documents'>
            <div className='bg-white w-full overflow-x-auto'>
              <div className='flex flex-row justify-end p-4'></div>
              <table className='table-fixed min-w-full bg-white rounded-b-lg'>
                <thead className='text-sm text-gray-500 text-left border-b'>
                  <tr>
                    <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Formation</th>
                    <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Session</th>
                    <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Entreprise</th>
                    <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Salarié</th>
                    <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Fichier</th>
                    <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Description</th>
                    <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Créée le</th>
                  </tr>
                </thead>
                <tbody>
                  {documents &&
                    documents.map((document) => (
                      <tr key={document.id} className=' border-gray-100 border-b hover:bg-gray-100'>
                        <td className='px-4 py-3 text-gray-800 text-sm'>
                          {document.formation && (
                            <div className='flex flex-row gap-2 items-center'>
                              {document.formation.image_couverture ? (
                                <img
                                  alt={document.formation.nom as string}
                                  src={document.formation.image_couverture}
                                  tabIndex={0}
                                  className='rounded-lg shadow-md object-cover w-10 h-10'
                                  loading='lazy'
                                />
                              ) : (
                                <div className={`rounded-lg shadow-md object-cover w-10 h-10 bg-gray-700 flex items-center justify-center`}>
                                  <AcademicCapIcon className='h-7 w-7 text-white/50' />
                                </div>
                              )}
                              <div>
                                <div className='poppins-bold'>{document.formation.nom}</div>
                              </div>
                            </div>
                          )}
                        </td>
                        <td className='pr-4 py-3 text-gray-800 text-sm'>
                          {document.groupe && document.groupe.date_debut && document.groupe.date_fin && (
                            <Fragment>
                              <div className='text-sm text-secondary-1 flex flex-row items-center'>
                                <CalendarDaysIcon className='w-5 h-5 mr-1 flex-none' />
                                <span>
                                  {document.groupe.date_debut
                                    ? `${new Intl.DateTimeFormat('default', {
                                        year: 'numeric',
                                        month: 'numeric',
                                        day: 'numeric',
                                        hour12: false,
                                      }).format(new Date(document.groupe.date_debut))}`
                                    : ''}
                                  {' - '}
                                  {document.groupe.date_fin
                                    ? `${new Intl.DateTimeFormat('default', {
                                        year: 'numeric',
                                        month: 'numeric',
                                        day: 'numeric',
                                        hour12: false,
                                      }).format(new Date(document.groupe.date_fin))}`
                                    : ''}
                                </span>
                              </div>

                              <div className='text-xs text-secondary-1 flex flex-row items-center'>
                                <div className='mr-1 flex-none'>
                                  <MapPinIcon className='w-5 h-5' />
                                </div>
                                <span>{document.groupe.site && <span>{document.groupe.site.nom}</span>}</span>
                              </div>
                            </Fragment>
                          )}
                        </td>
                        <td className='px-4 py-3 text-gray-800 text-sm'>
                          {document.entreprise && (
                            <div className='flex flex-row gap-2 items-center'>
                              <div>
                                <Logo className='rounded h-10 w-10 object-cover' src={document.entreprise.image_logo} />
                              </div>
                              <div>{`${document.entreprise.enseigne}`}</div>
                            </div>
                          )}
                        </td>
                        <td className='px-4 py-3 text-gray-800 text-sm flex flex-row gap-2 items-center'>
                          {document.user && (
                            <Fragment>
                              <div>
                                <Avatar src={document.user.image_avatar} />
                              </div>
                              <div>{`${document.user.prenom} ${document.user.nom}`}</div>
                            </Fragment>
                          )}
                        </td>
                        <td className='px-4 py-3 text-gray-800 text-sm'>
                          {document.fichier && <DownloadLink fichier={document.fichier} titre={document.titre} />}
                        </td>
                        <td className='px-4 py-3 text-gray-800 text-sm'>{document.description ? nl2br(document.description) : ''}</td>
                        <td className='px-4 py-3 text-gray-800 text-sm'>
                          {document.created_at
                            ? new Intl.DateTimeFormat('default', {
                                year: 'numeric',
                                month: 'numeric',
                                day: 'numeric',
                                hour: 'numeric',
                                minute: 'numeric',
                                hour12: false,
                              }).format(new Date(document.created_at as string))
                            : ''}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </TabPanel>
        </TabsBody>
      </Tabs>
      {apiIsLoading ? <LoadingAbsolute /> : ''}
    </Fragment>
  );
};

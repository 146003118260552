import { FunctionComponent, useState, useEffect, lazy, Suspense } from 'react';
import { DashboardAdminAvisAValider } from '../../../components/Dashboard/DashboardAdminAvisAValider';
const DashboardAdminCAEstime = lazy(() => import('../../../components/Dashboard/DashboardAdminCAEstime'));
const DashboardAdminDemandes = lazy(() => import('../../../components/Dashboard/DashboardAdminDemandes'));
import { DashboardAdminEntrepriseAValider } from '../../../components/Dashboard/DashboardAdminEntrepriseAValider';
import { DashboardAdminFormationsPopulaires } from '../../../components/Dashboard/DashboardAdminFormationsPopulaires';
const DashboardAdminInscriptions = lazy(() => import('../../../components/Dashboard/DashboardAdminInscriptions'));
import { DashboardAdminSitesPopulaires } from '../../../components/Dashboard/DashboardAdminSitesPopulaires';
import { LoadingAbsolute } from '../../../components/LoadingAbsolute';
import { useApi } from '../../../providers/ApiProvider';
import { AdminDashboard, ErrorApiResponse } from '../../../types/api';
import { Link } from 'react-router-dom';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { DashboardAdminSynthese } from '../../../components/Dashboard/DashboardAdminSynthese';
import { DashboardSynthese } from '../../../types/AdminRessources';
import { DashboardAdminRecherches } from '../../../components/Dashboard/DashboardAdminRecherches';
import { DashboardAdminVisitesFormations } from '../../../components/Dashboard/DashboardAdminVisitesFormations';
import { DashboardAdminVisitesFilieres } from '../../../components/Dashboard/DashboardAdminVisitesFilieres';
import { DashboardAdminVisitesMetiers } from '../../../components/Dashboard/DashboardAdminVisitesMetiers';
import { handleError } from '../../../utils/ErrorHandler';
import { DashboardAdminDemandesATraiter } from '../../../components/Dashboard/DashboardAdminDemandesATraiter';
import { DashboardAdminDevis } from '../../../components/Dashboard/DashboardAdminDevis';
import { Tab, TabPanel, Tabs, TabsBody, TabsHeader } from '../../../components/Onglets';

/**
 * Ce composant affiche le tableau de bord côté administrateur.
 */
export const AdminDashboardScreen: FunctionComponent = () => {
  /**
   * On stocke si on est en train d'utiliser l'API ou non.
   */
  const [apiIsLoading, setApiIsLoading] = useState(false);

  /**
   * Stocke les informations affichées dans un état local dans un état local.
   */
  const [adminDashboard, setAdminDashboard] = useState<AdminDashboard | null>(null);

  /**
   * On stocke le nombre de facture par jours pour le graphique.
   */
  const [compteParJour, setCompteParJour] = useState<{ [key: string]: number }>({});

  /**
   * Chiffre d'affaire par jours pour le graphique.
   */
  const [montantParJour, setMontantParJour] = useState<{ [key: string]: number }>({});

  /**
   * Nombre d'apprenants inscript par jours pour le graphique.
   */
  const [apprenantsParJour, setApprenantsParJour] = useState<{ [key: string]: number }>({});

  /**
   * Nombre d'entreprise inscrites par jours pour le graphique.
   */
  const [entreprisesParJour, setEntreprisesParJour] = useState<{ [key: string]: number }>({});

  /**
   * Données du tableau de synthèse
   */
  const [adminDashboardSynthese, setAdminDashboardSynthese] = useState<DashboardSynthese>({
    caestime: 0,
    nbFormations: 0,
    nbInscriptionApprenants: 0,
    nbInscriptionEntreprises: 0,
    nbAvis: 0,
    nbEntrepriseAValider: 0,
  });

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  useEffect(() => {
    handleIndex();
  }, []);

  /**
   * Retrouve des données de L'API et les stocke dans un état local.
   */
  const handleIndex = () => {
    setApiIsLoading(true);

    client
      .get<AdminDashboard>('/admin/dashboard')
      .then((response) => {
        setAdminDashboard(response.data as AdminDashboard);

        // Partie Chiffre d'affaire.
        const compte: { [key: string]: number } = {};

        const montant: { [key: string]: number } = {};

        let nbFormations = 0;

        let caestime = 0;

        // Partie Inscriptions.
        const apprenants: { [key: string]: number } = {};

        const entreprises: { [key: string]: number } = {};

        let nbInscriptionApprenants = 0;

        let nbInscriptionEntreprises = 0;

        /**
         * Giga boucle qui fait la ventilation de toutes les données à la fois.
         */
        Object.keys(response.data.data.stats_ca_mois_glissant).forEach((jour) => {
          compte[jour] = response.data.data.stats_ca_mois_glissant[jour].compte;
          montant[jour] = response.data.data.stats_ca_mois_glissant[jour].montant / 100;
          nbFormations += compte[jour];
          caestime += response.data.data.stats_ca_mois_glissant[jour].montant;

          apprenants[jour] = response.data.data.stats_inscriptions_mois_glissant[jour].apprenants;
          entreprises[jour] = response.data.data.stats_inscriptions_mois_glissant[jour].entreprises;
          nbInscriptionApprenants += apprenants[jour];
          nbInscriptionEntreprises += entreprises[jour];
        });

        setCompteParJour({ ...compte });
        setMontantParJour({ ...montant });

        setApprenantsParJour({ ...apprenants });
        setEntreprisesParJour({ ...entreprises });

        setAdminDashboardSynthese({
          ...adminDashboardSynthese,
          caestime: caestime / 100,
          nbFormations: nbFormations,
          nbInscriptionApprenants,
          nbInscriptionEntreprises,
          nbAvis: response.data.data.avis_a_valider?.length,
          nbEntrepriseAValider: response.data.data.entreprises_a_valider?.length,
        });
      })
      .catch((error: ErrorApiResponse<AdminDashboard>) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  return (
    <div>
      <Suspense fallback={<div>Chargement...</div>}>
        <div>
          <h2 className='text-sm poppins-medium text-secondary-1 mb-3'>Ces 30 derniers jours</h2>
          <DashboardAdminFormationsPopulaires
            formationsPopulaires={adminDashboard && adminDashboard.data ? adminDashboard.data.formations_populaires_mois_glissant : []}
          />
        </div>
        <div className='flex flex-col md:flex-row items-stretch gap-5 pb-11'>
          <div className='basis-4/6'>
            <h2 className='text-sm poppins-medium text-secondary-1 mb-3'>Modération</h2>
            <div className='h-full bg-white rounded-md shadow-md overflow-hidden'>
              <Tabs value='avis'>
                <TabsHeader>
                  <Tab value='avis'>Avis</Tab>
                  <Tab value='entreprises'>Entreprises</Tab>
                  <Tab value='demandes'>Demandes</Tab>
                  <Tab value='devis'>Devis</Tab>
                </TabsHeader>
                <TabsBody>
                  <TabPanel value='avis'>
                    <div className='px-5 p-b5'>
                      <DashboardAdminAvisAValider
                        avis={adminDashboard && adminDashboard.data ? adminDashboard.data.avis_a_valider : []}
                        handleIndex={handleIndex}
                      />
                    </div>
                  </TabPanel>
                  <TabPanel value='entreprises'>
                    <div className='px-5 p-b5'>
                      <DashboardAdminEntrepriseAValider
                        entreprises={adminDashboard && adminDashboard.data ? adminDashboard.data.entreprises_a_valider : []}
                        handleIndex={handleIndex}
                      />
                    </div>
                  </TabPanel>
                  <TabPanel value='demandes'>
                    <div className='px-5 p-b5'>
                      <DashboardAdminDemandesATraiter demandes={adminDashboard && adminDashboard.data ? adminDashboard.data.demandes_a_traiter : []} />
                    </div>
                  </TabPanel>
                  <TabPanel value='devis'>
                    <div className='px-5 p-b5'>
                      <DashboardAdminDevis
                        devisatraiter={adminDashboard && adminDashboard.data ? adminDashboard.data.devis_a_traiter : []}
                        devissansreponse={adminDashboard && adminDashboard.data ? adminDashboard.data.devis_sans_reponse : []}
                      />
                    </div>
                  </TabPanel>
                </TabsBody>
              </Tabs>
            </div>
          </div>
          <div className='basis-2/6'>
            <h2 className='text-sm poppins-medium text-secondary-1 mb-3'>Synthèse</h2>
            <DashboardAdminSynthese synthese={adminDashboardSynthese} />
          </div>
        </div>
        <h2 className='text-sm poppins-medium text-secondary-1 mb-3 mt-7'>Flux e-commerce</h2>
        <div className='flex flex-col md:flex-row gap-5'>
          <div className='basis-1/2'>
            <div className='bg-white rounded-md shadow-md p-5'>
              <div className='flex flex-col sm:flex-row items-center justify-between mb-7'>
                <h3 className='text-secondary-2 poppins-semibold text-center md:text-left'>Chiffre d'affaires estimé</h3>
                <Link to='/admin/statistiques/demandes-paiements' className='text-sm text-secondary-1/90 flex items-center hover:text-primary'>
                  Tout voir
                  <ChevronRightIcon className='w-3 h-3' />
                </Link>
              </div>
              <DashboardAdminCAEstime compte={compteParJour} montant={montantParJour} />
            </div>
          </div>
          <div className='basis-1/2'>
            <div className='bg-white rounded-md shadow-md p-5'>
              <div className='flex flex-col sm:flex-row items-center justify-between mb-7'>
                <h3 className='text-secondary-2 poppins-semibold text-center md:text-left'>Inscriptions</h3>
                <Link to='/admin/statistiques/inscriptions' className='text-sm text-secondary-1/90 flex items-center hover:text-primary'>
                  Tout voir
                  <ChevronRightIcon className='w-3 h-3' />
                </Link>
              </div>
              <DashboardAdminInscriptions apprenants={apprenantsParJour} entreprises={entreprisesParJour} />
            </div>
          </div>
        </div>
        <h2 className='text-sm poppins-medium text-secondary-1 mb-3 mt-11'>A propos des formations</h2>
        <div className='flex flex-col md:flex-row gap-5'>
          <div className='basis-2/6'>
            <div className='h-full bg-white rounded-md shadow-md p-5'>
              <div className='flex flex-col sm:flex-row items-center justify-between mb-7'>
                <h3 className='text-secondary-2 poppins-semibold text-center md:text-left'>Top sites</h3>
                <Link to='/admin/statistiques/visites-sites' className='text-sm text-secondary-1/90 flex items-center hover:text-primary'>
                  Tout voir
                  <ChevronRightIcon className='w-3 h-3' />
                </Link>
              </div>
              <DashboardAdminSitesPopulaires sites={adminDashboard && adminDashboard.data ? adminDashboard.data.stats_sites_mois_glissant : []} />
            </div>
          </div>
          <div className='basis-4/6'>
            <div className='bg-white rounded-md shadow-md p-5'>
              <div className='flex flex-col sm:flex-row items-center justify-between mb-7'>
                <h3 className='text-secondary-2 poppins-semibold text-center md:text-left'>Flux de demandes</h3>
                <Link to='/admin/statistiques/demandes-formations' className='text-sm text-secondary-1/90 flex items-center hover:text-primary'>
                  Tout voir
                  <ChevronRightIcon className='w-3 h-3' />
                </Link>
              </div>
              <DashboardAdminDemandes demandes={adminDashboard && adminDashboard.data ? adminDashboard.data.stats_demandes_mois_glissant : {}} />
            </div>
          </div>
        </div>
        <h2 className='text-sm poppins-medium text-secondary-1 mb-3 mt-11'>Popularité</h2>
        <div className='flex flex-col md:flex-row gap-5'>
          <div className='basis-1/2'>
            <div className='h-full bg-white rounded-md shadow-md p-5'>
              <div className='flex flex-col sm:flex-row items-center justify-between mb-7'>
                <h3 className='text-secondary-2 poppins-semibold text-center md:text-left'>Top recherches</h3>
                <Link to='/admin/statistiques/recherches' className='text-sm text-secondary-1/90 flex items-center hover:text-primary'>
                  Tout voir
                  <ChevronRightIcon className='w-3 h-3' />
                </Link>
              </div>
              <DashboardAdminRecherches recherches={adminDashboard && adminDashboard.data ? adminDashboard.data.stats_recherches_mois_glissant : []} />
            </div>
          </div>
          <div className='basis-1/2'>
            <div className='h-full bg-white rounded-md shadow-md p-5'>
              <div className='flex flex-col sm:flex-row items-center justify-between mb-7'>
                <h3 className='text-secondary-2 poppins-semibold text-center md:text-left'>Top formations</h3>
                <Link to='/admin/statistiques/visites-formations' className='text-sm text-secondary-1/90 flex items-center hover:text-primary'>
                  Tout voir
                  <ChevronRightIcon className='w-3 h-3' />
                </Link>
              </div>
              <DashboardAdminVisitesFormations
                visitesFormations={adminDashboard && adminDashboard.data ? adminDashboard.data.stats_formations_mois_glissant : []}
              />
            </div>
          </div>
        </div>
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 mt-7'>
          <div className='h-full bg-white rounded-md shadow-md p-5'>
            <div className='flex flex-col sm:flex-row items-center justify-between mb-7'>
              <h3 className='text-secondary-2 poppins-semibold text-center md:text-left'>Top filières</h3>
              <Link to='/admin/statistiques/visites-filieres' className='text-sm text-secondary-1/90 flex items-center hover:text-primary'>
                Tout voir
                <ChevronRightIcon className='w-3 h-3' />
              </Link>
            </div>
            <DashboardAdminVisitesFilieres visitesFilieres={adminDashboard && adminDashboard.data ? adminDashboard.data.stats_filieres_mois_glissant : []} />
          </div>
          <div className='h-full bg-white rounded-md shadow-md p-5'>
            <div className='flex flex-col sm:flex-row items-center justify-between mb-7'>
              <h3 className='text-secondary-2 poppins-semibold text-center md:text-left'>Top métiers</h3>
              <Link to='/admin/statistiques/visites-metiers' className='text-sm text-secondary-1/90 flex items-center hover:text-primary'>
                Tout voir
                <ChevronRightIcon className='w-3 h-3' />
              </Link>
            </div>
            <DashboardAdminVisitesMetiers visitesMetiers={adminDashboard && adminDashboard.data ? adminDashboard.data.stats_metiers_mois_glissant : []} />
          </div>
          <div className='h-full bg-white rounded-md shadow-md p-5'>
            <div className='flex flex-col sm:flex-row items-center justify-between mb-7'>
              <h3 className='text-secondary-2 poppins-semibold text-center md:text-left'>Top sites</h3>
              <Link to='/admin/statistiques/visites-sites' className='text-sm text-secondary-1/90 flex items-center hover:text-primary'>
                Tout voir
                <ChevronRightIcon className='w-3 h-3' />
              </Link>
            </div>
            <DashboardAdminSitesPopulaires sites={adminDashboard && adminDashboard.data ? adminDashboard.data.stats_sites_mois_glissant : []} />
          </div>
        </div>
        {apiIsLoading ? <LoadingAbsolute /> : ''}
      </Suspense>
    </div>
  );
};

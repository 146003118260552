import { Fragment, FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';
import { Config } from '../../config/config';
import { Link } from 'react-router-dom';

/**
 * Ce composant affiche la page d'e-mail vérifié.
 */
export const MerciInscriptionScreen: FunctionComponent = () => {
  // Vue

  return (
    <Fragment>
      <Helmet>
        <title>Vérification E-mail - {Config.app_label}</title>
      </Helmet>
      <div className='p-6 bg-[#F7FBFE] w-full rounded-md shadow-md relative'>
        <h2 className='text-3xl text-center mb-1 space-x-3 poppins-bold text-secondary-2'>Confirmation de votre inscription</h2>
        <p className='my-8 text-sm text-gray-700 text-center leading-tight'>
          Merci pour votre inscription ! Vous pouvez désormais accéder à votre espace client.
        </p>
        <div className='mt-3'>
          <Link
            to='/connexion'
            className='poppins-semibold block w-full px-2 py-1 rounded-md bg-primary text-white disabled:bg-primary/50 text-center uppercase'>
            Accéder à l'espace client
          </Link>
        </div>
      </div>
    </Fragment>
  );
};

import { Fragment, FunctionComponent, useState } from 'react';
import { Input } from '@material-tailwind/react';
import { Navigate } from 'react-router-dom';
import { Renderable, Toast, toast, ValueFunction } from 'react-hot-toast';
import { Helmet } from 'react-helmet';
import { Config } from '../../config/config';
import { LoadingAbsolute } from '../../components/LoadingAbsolute';
import { useApi } from '../../providers/ApiProvider';
import { useUser } from '../../providers/UserProvider';

/**
 * Ce composant affiche la page pour renvoyer le code de vérification à l'utilisateur
 */
export const RenvoiVerificationEmail: FunctionComponent = () => {
  /**
   * On stocke si on est en train d'utiliser l'API ou non.
   */
  const [apiIsLoading, setApiIsLoading] = useState(false);

  /**
   * État local pour gérer le champ `Adresse e-mail` du formulaire de mot de passe oublé
   */
  const [email, setEmail] = useState({ value: '', error: false });

  /**
   * Cet état local determine si on a déjà envoyé le mail pour réinitialiser son mot de passe ou non.
   */
  const [emailIsSent, setEmailIsSent] = useState(false);

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  /**
   * On a besoin des informations de l'utilisateur.
   */
  const { user } = useUser();

  /**
   * Cette méthode gère le changement de valeur du champ `Adresse e-mail`
   *
   * @param event - Évènement de changement de valeur.
   */
  const handleEmailChange = (event: { target: { value: string } }) => {
    setEmail({ value: event.target.value, error: false });
  };

  /**
   * Cette méthode effectue l'appel API pour envoyer le mail de réinitialisation de mot de passe.
   *
   * @param event - Évènement d'envoi du formulaire.
   */
  const handleRenvoiVerification = (event: { preventDefault: () => void }) => {
    event.preventDefault();

    if (apiIsLoading) {
      return;
    }

    setApiIsLoading(true);

    // Appel effectif
    client
      .post('/renvoi-verification-email', {
        email: email.value,
      })
      .then(() => {
        setApiIsLoading(false);
        setEmailIsSent(true);
        toast.success('Les instructions de vérification vous ont été renvoyées par e-mail.');
      })
      .catch(
        (error: {
          response: {
            status: number;
            data: { message: Renderable | ValueFunction<Renderable, Toast> };
          };
          message: Renderable | ValueFunction<Renderable, Toast>;
        }) => {
          setApiIsLoading(false);
          if (error.response && [400, 401, 422].includes(error.response.status) && error.response.data.message) {
            setEmail({ value: email.value, error: true });
            toast.error(error.response.data.message);
          } else {
            toast.error(error.message);
          }
        },
      );
  };

  // Vue
  if (!user || !user.token) {
    if (emailIsSent === false) {
      return (
        <Fragment>
          <Helmet>
            <title>Renvoi de l'e-mail de vérification - {Config.app_label}</title>
          </Helmet>

          <form className='p-6 bg-[#F7FBFE] w-full rounded-md shadow-md relative' onSubmit={handleRenvoiVerification}>
            <h2 className='text-3xl text-center mb-1 space-x-3 poppins-bold text-secondary-2'>Je n'ai pas reçu l'e-mail de vérification</h2>

            <p className='my-8 text-sm text-gray-700 text-center leading-tight'>
              Veuillez renseigner votre adresse e-mail. Un nouvel e-mail de vérification sera envoyé si un compte y est rattaché.
            </p>

            <div className='space-y-1'>
              <Input type='email' label='Adresse email' onChange={handleEmailChange} error={email.error} />
            </div>

            <div className='mt-8'>
              <button type='submit' className='poppins-semibold w-full px-2 py-1 rounded-md bg-primary text-white  disabled:bg-primary/50 uppercase'>
                Envoyer
              </button>
            </div>
            {apiIsLoading ? <LoadingAbsolute /> : ''}
          </form>
        </Fragment>
      );
    } else {
      return <Navigate to='/' />;
    }
  } else {
    return <Navigate to='/mon-espace' />;
  }
};

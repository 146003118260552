import { useState } from 'react';
import type { FunctionComponent } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { Checkbox, Input } from '@material-tailwind/react';

import { LoadingAbsolute } from '../components/LoadingAbsolute';
import { PasswordHints } from '../components/PasswordHints';
import { PasswordStrength } from '../components/PasswordStrength';
import { useApi } from '../providers/ApiProvider';
import { RegisterApprenant } from '../types/auth';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/20/solid';
import { handleError } from '../utils/ErrorHandler';

/**
 * Formulaire Pour l'inscription de l'apprenant.
 */
export const InscriptionApprenant: FunctionComponent = () => {
  /**
   * État local de chargement de l'API.
   */
  const [apiIsLoading, setApiIsLoading] = useState(false);

  /**
   * On crée une objet en lecture seule qui contient les valeurs initiales des champs.
   *
   * @constant
   */
  const initialState = {
    nom: { value: '', error: false },
    prenom: { value: '', error: false },
    date_naissance: { value: '', error: false },
    email: { value: '', error: false },
    password: { value: '', error: false },
  } as const;

  /**
   * Contient un état général des valeurs des champs du formulaire ainsi que les champs qui ont reçu une erreur lors de l'envoi du formulaire.
   *
   * @defaultValue `initialState`
   */
  const [formInputs, setFormInputs] = useState<{ [K in keyof RegisterApprenant]: { value: RegisterApprenant[K]; error: boolean } }>(initialState);

  /**
   * Permet de gérer l'acceptation des condition générales de d'utilisation.
   */
  const [acceptationCgu, setAcceptationCgu] = useState<boolean>(false);

  /**
   * Permet de gérer l'acceptation de l'utilisation des données personnelles.
   */
  const [acceptationRgpd, setAcceptationRgpd] = useState<boolean>(false);

  /**
   * Gère l'appui sur un bouton pour voir le mot de passe.
   */
  const [showedPassword, setShowedPassword] = useState<boolean>(false);

  /**
   * Cette méthode permet de gérer les changements à l'interieur des champs texte du formulaire et de gérer les valeurs des champs via un état local.
   *
   * @param field - Champs du formulaire dont on doit stocker la valeur et s'il est en erreur.
   */
  const handleInputChange = (field: keyof RegisterApprenant) => {
    return (event: { target: { value: string } }) => {
      setFormInputs((prev) => ({
        ...prev,
        [field]: { value: event.target.value, error: false },
      }));
    };
  };

  /**
   * État local pour gérer le moment où l'enregistrement est effectuer.
   */
  const [registerIsDone, setRegisterIsDone] = useState(false);

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  /**
   * C'est cette méthode qui va faire l'appel pour enregistrer l'utilisateur salarié.
   *
   * @param event
   */
  const handleRegister = (event: { preventDefault: () => void }) => {
    event.preventDefault();

    if (apiIsLoading === true) {
      return;
    }

    setApiIsLoading(true);

    let ressource: Partial<RegisterApprenant> = {};
    Object.keys(formInputs).forEach((key) => {
      if (formInputs[key as keyof RegisterApprenant].value !== null) {
        ressource = { ...ressource, [key]: formInputs[key as keyof RegisterApprenant].value };
      }
    });

    // Appel effectif
    client
      .post<void, Partial<RegisterApprenant>>('/apprenant/inscription', ressource)
      .then(() => {
        setApiIsLoading(false);
        setRegisterIsDone(true);
        toast.success('Votre compte a bien été créé. Vous allez recevoir un e-mail afin de finaliser votre inscription.');
      })
      .catch((error) => {
        setApiIsLoading(false);
        handleError(error, () => {
          let object = formInputs;
          Object.keys(error.response.data.errors).forEach((key) => {
            if (error.response.data.errors[key as keyof RegisterApprenant]) {
              object = { ...object, [key]: { value: formInputs[key as keyof RegisterApprenant].value, error: true } };
            }
          }, formInputs);
          setFormInputs(object);
        });
      });
  };

  if (registerIsDone === false) {
    return (
      <form className='relative mt-6' onSubmit={handleRegister}>
        <h2 className='text-3xl text-center mb-1 space-x-3 poppins-bold text-secondary-2'>Créez votre compte salarié(e)</h2>
        <div className='mt-6'>
          <Input
            //className='block border border-gray-200 rounded px-5 py-3 leading-6 w-full focus:border-primary focus:ring focus:ring-primary focus:ring-opacity-50'
            type='text'
            label='Prénom'
            onChange={handleInputChange('prenom')}
            error={formInputs.prenom.error}
          />
        </div>
        <div className='mt-6'>
          <Input
            //className='block border border-gray-200 rounded px-5 py-3 leading-6 w-full focus:border-primary focus:ring focus:ring-primary focus:ring-opacity-50'
            type='text'
            label='Nom'
            onChange={handleInputChange('nom')}
            error={formInputs.nom.error}
          />
        </div>
        <div className='mt-6'>
          <Input
            //className='block border border-gray-200 rounded px-5 py-3 leading-6 w-full focus:border-primary focus:ring focus:ring-primary focus:ring-opacity-50'
            type='date'
            label='Date de naissance'
            onChange={handleInputChange('date_naissance')}
            error={formInputs.date_naissance.error}
          />
        </div>
        <div className='mt-6'>
          <Input
            //className='block border border-gray-200 rounded px-5 py-3 leading-6 w-full focus:border-primary focus:ring focus:ring-primary focus:ring-opacity-50'
            type='email'
            label='Adresse e-mail'
            onChange={handleInputChange('email')}
            error={formInputs.email.error}
          />
        </div>
        <div className='mt-6'>
          <Input
            //className='block border border-gray-200 rounded px-5 py-3 leading-6 w-full focus:border-primary focus:ring focus:ring-primary focus:ring-opacity-50'
            type={showedPassword ? 'text' : 'password'}
            label='Mot de passe'
            onChange={handleInputChange('password')}
            error={formInputs.password.error}
            icon={
              <div onClick={() => setShowedPassword(!showedPassword)}>
                {showedPassword ? <EyeSlashIcon className='w-5 h-5' /> : <EyeIcon className='w-5 h-5' />}
              </div>
            }
          />

          <div className='mt-1'>
            <PasswordStrength password={formInputs.password.value} />
          </div>
          <div className='mt-3'>
            <PasswordHints password={formInputs.password.value} />
          </div>
        </div>
        <div className='-ml-3'>
          <div className='flex flex-row items-center mt-6'>
            <Checkbox
              checked={acceptationCgu}
              onChange={() => {
                setAcceptationCgu(!acceptationCgu);
              }}
            />
            <span className='text-sm'>
              J'accepte les{' '}
              <Link className='text-primary hover:text-secondary-1' to='/conditions-generales' target='_blank'>
                conditions générales d'utilisation
              </Link>
              .
            </span>
          </div>
          <div className='flex flex-row items-start'>
            <Checkbox
              checked={acceptationRgpd}
              onChange={() => {
                setAcceptationRgpd(!acceptationRgpd);
              }}
            />
            <p className='text-sm pt-3'>
              J'accepte le traitement de mes données personnelles par les Compagnons du Devoir et du Tour de France.
              <br />
              <Link className='text-primary hover:text-secondary-1' to='/conditions-generales' target='_blank'>
                En savoir plus
              </Link>
              .
            </p>
          </div>
        </div>
        <div className='mt-6'>
          <button
            type='submit'
            className='poppins-semibold w-full px-2 py-1 rounded-md bg-primary text-white  disabled:bg-primary/50 uppercase'
            disabled={!acceptationCgu || !acceptationRgpd}>
            S'inscrire
          </button>
        </div>
        {apiIsLoading ? <LoadingAbsolute /> : ''}
      </form>
    );
  } else {
    return <Navigate to='/connexion' />;
  }
};

import { Fragment, FunctionComponent } from 'react';
import { DashboardStatsPopulaires } from '../../types/AdminRessources';

/**
 * Ce composant est un encart du tableau de bord administrateur concernant les visites de métiers.
 *
 * @param visitesMetiers - Liste des visites de métiers.
 */
export const DashboardAdminVisitesMetiers: FunctionComponent<{
  visitesMetiers: DashboardStatsPopulaires[];
}> = ({ visitesMetiers }) => {
  return (
    <Fragment>
      {visitesMetiers.map((visiteMetier) => (
        <div key={visiteMetier.id} className='text-secondary-1 flex flex-row items-center justify-between gap-2 border-b last:border-none py-2'>
          <h6 className='montserrat-semibold'>{visiteMetier.nom}</h6>
          <span className='text-sm'>{visiteMetier.compte} visites</span>
        </div>
      ))}
    </Fragment>
  );
};

import { FunctionComponent } from 'react';
import { useUser } from '../providers/UserProvider';
import { Profil, StatutAffiliationEnum } from '../types/auth';
import { ConstTypeProfil } from './ConstTypeProfil';
import { Logo } from './Logo';

/**
 * Ce composant est celui qui affiche la liste des profils avec la possibilité d'en changer.
 * @param profils - Liste des profils de l'utilisateur.
 * @param handleOpen - Callback qui précise ce qui doit être fait lorsque l'on ouvre le sélecteur de profil.
 */
export const ProfileSelector: FunctionComponent<{
  profils: Profil[];
  handleOpen: () => void;
}> = ({ profils, handleOpen }) => {
  /**
   * On a besoin du profil courant de l'utilisateur et de la possibilité de modifier ce profil.
   */
  const { profil: currentProfil, changeProfil: setCurrentProfil } = useUser();

  /**
   * @constant Stocke les profils révoqués.
   */
  //const profilsRevoques = profils.filter((profil) => profil.statut_affiliation == StatutAffiliationEnum.revoque);

  /**
   * Cette méthode change le profil courant
   *
   * @param profil - Profil sur lequel on clique.
   */
  const handleProfilClick = (profil: Profil) => {
    setCurrentProfil(profil);
    handleOpen();
  };

  return (
    <div>
      <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-7 justify-center px-4'>
        {profils.map((profil) =>
          profil.statut_affiliation !== StatutAffiliationEnum.revoque ? (
            <div
              key={profil.id}
              className={
                currentProfil && currentProfil.id && currentProfil.id === profil.id
                  ? 'outline outline-offset-3 outline-primary bg-deep-orange-50 bg-cursor-not-allowed block w-full h-full cursor-pointer rounded-md'
                  : 'block w-full h-full bg-white hover:scale-105 transition transform hover:shadow-lg cursor-pointer rounded-md'
              }
              onClick={() => {
                handleProfilClick(profil);
              }}>
              <div className='w-full flex justify-center h-full relative'>
                <div className='bg-white/30 backdrop-blur-xl backdrop-saturate-200 rounded-md w-full px-5 py-12 shadow-lg flex flex-col justify-between gap-3'>
                  <Logo className='rounded-md h-24 w-24 object-cover mx-auto flex-none' src={profil.entreprise.image_logo} />
                  <h3 className='mt-2 text-xl text-secondary-2 text-center poppins-medium'>
                    {profil.statut_affiliation == StatutAffiliationEnum.affilie
                      ? profil.entreprise.enseigne
                        ? profil.entreprise.enseigne
                        : profil.entreprise.raison_sociale
                      : "Pas d'entreprise"}
                  </h3>
                  <div className='text-center mt-4 w-full'>
                    <p className='text-center text-secondary-1'>
                      <ConstTypeProfil text={profil.type_profil} />
                    </p>
                  </div>

                  {currentProfil && currentProfil.id && currentProfil.id === profil.id && (
                    <div className='absolute bottom-0 left-0 right-0 text-primary poppins text-xs text-center pb-4 poppins-semibold'>
                      Profil actuellement sélectionné
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : (
            ''
          ),
        )}
      </div>

      {/*profilsRevoques && profilsRevoques.length > 0 && (
        <Fragment>
          <h2 className='text-xl poppins-semibold text-center mt-20'>Profils Archivés</h2>
          <div className='grid grid-cols-1 md:grid-cols-3 place-content-center xl:grid-cols-4 gap-7 justify-center mx-auto px-5'>
            {profils.map((profil) =>
              profil.statut_affiliation == StatutAffiliationEnum.revoque ? (
                <div key={profil.id} className={'block w-full h-full rounded-lg cursor-not-allowed'}>
                  <div className='w-full flex justify-center h-full'>
                    <div className='bg-gray-100  rounded-lg w-full p-5 md:py-12'>
                      <div className='w-full flex items-center justify-center'>
                        <div className='flex flex-col items-center'>
                          <Logo className='rounded h-20 w-20 object-cover' src={profil.entreprise.image_logo} />
                          <h3 className='mt-2 text-base sm:text-lg md:text-xl 2xl:text-2xl text-secondary-1 text-center'>
                            {profil.entreprise.enseigne ? profil.entreprise.enseigne : profil.entreprise.raison_sociale}
                          </h3>
                        </div>
                      </div>
                      <div className='text-center mt-7 w-full'>
                        <div>
                          <p className='mt-2 text-xs sm:text-sm md:text-base poppins-semibold text-center text-secondary-1'>
                            <ConstTypeProfil text={profil.type_profil} />
                          </p>
                          <p className='text-xs text-gray-400 '>
                            <ConstStatutAffiliation text={profil.statut_affiliation} />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ''
              ),
            )}
          </div>
        </Fragment>
              )*/}
    </div>
  );
};

import { CurrencyEuroIcon } from '@heroicons/react/24/outline';
import { FunctionComponent } from 'react';
import { DashboardSynthese } from '../../types/GestionnaireCentreRessources';

/**
 * Ce composant est un encart du tableau de bord gestionnaire de centre qui présente une synthèse de plusieurs données.
 *
 * @param synthese - Objet qui rassemble plusieurs données à présenter.
 */
export const DashboardGestionnaireCentreSynthese: FunctionComponent<{ synthese: DashboardSynthese }> = ({ synthese }) => {
  return (
    <div className='h-full bg-white rounded-lg shadow-md p-5'>
      <h3 className='text-secondary-2 poppins-semibold text-center md:text-left mb-7'>Ces 30 derniers jours</h3>
      <p className='text-xs poppins-medium text-gray-600 tracking-normal font-normal leading-5 mt-5 mb-2'>Chiffre d'affaires estimé</p>
      <div className='flex flex-row items-center gap-3'>
        <div className='p-2 bg-gradient-to-r from-orange-900 to-primary/90 rounded-lg text-white'>
          <CurrencyEuroIcon className='w-9 h-9' />
        </div>
        <div className='text-secondary-1'>
          <h5 className='leading-5 poppins-bold text-4xl mt-1'>
            {new Intl.NumberFormat('default', { style: 'currency', currency: 'EUR' }).format(synthese.caestime)}
          </h5>
          <p className='text-sm poppins-medium tracking-normal font-normal mt-2'>
            Sur {synthese.nbFormations} formation{synthese.nbFormations > 1 ? 's' : ''}
          </p>
        </div>
      </div>
    </div>
  );
};

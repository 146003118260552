import { InformationCircleIcon } from '@heroicons/react/20/solid';
import { FunctionComponent, ReactNode } from 'react';

/**
 * Ce composant Affiche un Toast d'information.
 *
 * @param children - Texte d'information.
 */
export const ToastInfo: FunctionComponent<{ children?: ReactNode }> = ({ children }) => {
  return (
    <div className='shadow-lg rounded-lg pointer-events-auto border-l-[4px] border border-[#0E65D0]/50 border-l-[#0E65D0] bg-[#E6EFFA]'>
      <div className='flex p-[20px] pr-8 items-center'>
        <div>
          <InformationCircleIcon className='w-7 h-7 text-[#0E65D0]' />
        </div>
        <div className='ml-3 flex-1'>
          <p className='text-gray-900'>{children}</p>
        </div>
      </div>
    </div>
  );
};

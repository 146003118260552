import { FunctionComponent } from 'react';
import { FolderArrowDownIcon } from '@heroicons/react/24/outline';

export const DownloadLink: FunctionComponent<{
  fichier?: string | null;
  titre?: string | null;
}> = ({ fichier = null, titre = null }) => {
  if (fichier && titre) {
    return (
      <a
        href={fichier}
        target='_blank'
        title={titre}
        className='inline-flex flex-row gap-1 items-center text-black hover:text-white px-2 py-0.5 rounded border hover:border-primary hover:bg-primary'
        rel='noreferrer'>
        <FolderArrowDownIcon className='w-4 h-4 flex-none' />
        <span className='line-clamp-1'>Télécharger</span>
      </a>
    );
  } else {
    return <div></div>;
  }
};

import React, { useState, ReactNode, FunctionComponent, Fragment } from 'react';

type TabProps = {
  value: string;
  children: ReactNode;
  onClick?: React.MouseEventHandler<HTMLLIElement>;
};

const Tab: FunctionComponent<TabProps> = ({ children }) => {
  return <Fragment>{children}</Fragment>;
};

const TabPanel: FunctionComponent<TabProps> = ({ children }) => {
  return <div className='w-full h-max text-gray-700 antialiased text-base leading-relaxed p-0 font-normal'>{children}</div>;
};

type TabsHeaderProps = {
  children: ReactNode;
  classDiv?: string;
  classNav?: string;
  classTab?: string;
  classTabActive?: string;
  onClick?: React.MouseEventHandler<HTMLLIElement>;
  activeTab?: string;
  setActiveTab?: (value: string) => void;
};

const TabsHeader: FunctionComponent<TabsHeaderProps> = ({ children, classDiv, classNav, classTab, classTabActive, onClick, activeTab, setActiveTab }) => {
  return (
    <div className={`${classDiv ? classDiv : 'block'}`}>
      <nav>
        <ul className={` ${classNav ? classNav : 'bg-white rounded-sm p-4 flex flex-row gap-4 text-sm items-stretch'}`}>
          {React.Children.map(children, (child) => {
            if (child === null || typeof child === 'undefined') {
              return null;
            }

            const { value } = (child as React.ReactElement<TabProps>).props;

            return (
              <li
                key={value}
                className={`
                 ${
                   activeTab === value
                     ? classTabActive
                       ? classTabActive
                       : 'flex-1 text-center cursor-pointer py-2 rounded text-white bg-primary shadow-md montserrat-semibold flex flex-row gap-2 items-center justify-center'
                     : classTab
                       ? classTab
                       : 'flex-1 text-center cursor-pointer py-2 rounded text-gray-600 bg-[#FAFAFA] montserrat-semibold flex flex-row gap-2 items-center justify-center'
                 }`}
                onClick={() => {
                  onClick && onClick;
                  setActiveTab && setActiveTab(value);
                }}>
                {child}
              </li>
            );
          })}
        </ul>
      </nav>
    </div>
  );
};

type TabsBodyProps = {
  children: ReactNode;
  activeTab?: string;
};

const TabsBody: FunctionComponent<TabsBodyProps> = ({ children, activeTab }) => {
  return (
    <div className='block w-full relative bg-transparent overflow-hidden'>
      {React.Children.map(children, (child) => {
        if (child === null || typeof child === 'undefined') {
          return null;
        }

        if ((child as React.ReactElement<TabProps>).props.value === activeTab) {
          return <div>{child}</div>;
        }
        return null;
      })}
    </div>
  );
};

type TabsProps = {
  children: ReactNode;
  value?: string;
};

const Tabs: FunctionComponent<TabsProps> = ({ children, value }: React.PropsWithChildren<TabsProps>) => {
  const [activeTab, setActiveTab] = useState(value || (children as React.ReactElement[])[0]?.props.value || '');

  return (
    <div className='overflow-hidden block'>
      {React.Children.map(children, (child) => {
        if ((child as React.ReactElement).type === TabsHeader) {
          return React.cloneElement(child as React.ReactElement, { activeTab, setActiveTab });
        }
        if ((child as React.ReactElement).type === TabsBody) {
          return React.cloneElement(child as React.ReactElement, { activeTab });
        }
        return null;
      })}
    </div>
  );
};

export { Tabs, TabsHeader, TabsBody, Tab, TabPanel };

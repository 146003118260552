import { EyeIcon } from '@heroicons/react/24/outline';
import { Fragment, FunctionComponent } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Devis } from '../../types/AdminRessources';
import { dateEstCompriseDansIntervalle, ajouterJour } from '../../utils/Temps';

import { FormationSessionDisplay } from '../FormationSessionDisplay';
import { EntrepriseDisplay } from '../EntrepriseDisplay';
import { UserDisplay } from '../UserDisplay';

/**
 * Ce composant est un encart du tableau de bord administrateur qui permet de valider ou non une entreprise.
 *
 * @param devis - Tableau de devis à traiter .
 * @param handleIndex - Callback pour effectuer un appel de rechargement.
 */
export const DashboardAdminDevis: FunctionComponent<{
  devisatraiter: Devis[];
  devissansreponse: Devis[];
}> = ({ devisatraiter, devissansreponse }) => {
  /**
   * Permet au composant de changer de page.
   */
  const navigate = useNavigate();

  /**
   * Date du jour.
   * @constant
   */
  const currentDate = new Date();

  /**
   * Cette méthode sert à afficher un résumé de l'avancement pour chaque devis.
   *
   * @param devi - Devis dont il est question dans la boucle.
   * @returns Le résumé des étapes de l'avancement du devis.
   */
  const afficheAvancement = (devi: Devis) => {
    if (devi.date_acceptation === null && devi.date_refus === null) {
      if (currentDate < new Date(devi.date_limite_acceptation)) {
        // Devis en attente
        return (
          <div
            className={
              devi.date_limite_acceptation &&
              dateEstCompriseDansIntervalle(new Date(devi.created_at as string), ajouterJour(new Date(devi.date_limite_acceptation), -1), currentDate)
                ? currentDate.getDate() === ajouterJour(new Date(devi.date_limite_acceptation), -1).getDate()
                  ? 'text-orange-200'
                  : ''
                : 'text-red-700'
            }>
            En attente de décision
            {devi.date_limite_acceptation
              ? `, date limite de décision : ${new Intl.DateTimeFormat('default', {
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                  hour12: false,
                }).format(new Date(devi.date_limite_acceptation))}.`
              : '.'}
          </div>
        );
      } else {
        // Délais d'attente dépassé
        return (
          <div className='text-red-700'>
            {devi.date_limite_acceptation
              ? `Date limite de décision : ${new Intl.DateTimeFormat('default', {
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                  hour12: false,
                }).format(new Date(devi.date_limite_acceptation))}.`
              : ''}
          </div>
        );
      }
    }

    if (devi.date_acceptation) {
      /**
       * Une fois le devis accepté on sait que l'on va rendre ce bloc pour tous les cas où le devis est accepté.
       * @constant
       */
      const devisAccepte = (
        <div>{`Accepté le : ${new Intl.DateTimeFormat('default', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour12: false,
        }).format(new Date(devi.date_acceptation as string))}.`}</div>
      );

      if (devi.date_traitement === null && devi.date_limite_traitement) {
        if (currentDate < new Date(devi.date_limite_traitement)) {
          // En attente de traitement
          return (
            <Fragment>
              {devisAccepte}
              <div
                className={
                  devi.date_acceptation &&
                  devi.date_limite_retractation &&
                  dateEstCompriseDansIntervalle(
                    new Date(devi.date_acceptation as string),
                    ajouterJour(new Date(devi.date_limite_retractation as string), -1),
                    currentDate,
                  )
                    ? currentDate.getDate() === ajouterJour(new Date(devi.date_limite_retractation), -1).getDate()
                      ? 'text-orange-200'
                      : ''
                    : 'text-red-700'
                }>
                {devi.date_limite_retractation
                  ? `Date limite de rétractation : ${new Intl.DateTimeFormat('default', {
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                      hour12: false,
                    }).format(new Date(devi.date_limite_retractation))}.`
                  : ''}
              </div>
            </Fragment>
          );
        } else {
          // Délais de traitement dépassé.
          return (
            <Fragment>
              {devisAccepte}
              <div className='text-red-700'>
                {devi.date_limite_traitement
                  ? `Date limite de traitement : ${new Intl.DateTimeFormat('default', {
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                      hour12: false,
                    }).format(new Date(devi.date_limite_traitement))}.`
                  : ''}
              </div>
            </Fragment>
          );
        }
      }

      if (devi.date_retractation) {
        return (
          <Fragment>
            {devisAccepte}
            <div className='text-red-700'>
              {devi.date_retractation
                ? `Rétractation le : ${new Intl.DateTimeFormat('default', {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour12: false,
                  }).format(new Date(devi.date_retractation))}.`
                : ''}
            </div>
          </Fragment>
        );
      }

      if (devi.date_traitement) {
        return (
          <Fragment>
            {devisAccepte}
            <div>
              {devi.date_traitement
                ? `Traité le : ${new Intl.DateTimeFormat('default', {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour12: false,
                  }).format(new Date(devi.date_traitement))}.`
                : ''}
            </div>
          </Fragment>
        );
      }
    }

    if (devi.date_refus) {
      return (
        <div>{`Refusé le : ${new Intl.DateTimeFormat('default', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour12: false,
        }).format(new Date(devi.date_refus as string))}.`}</div>
      );
    }
  };

  return (
    <Fragment>
      <div>
        <h3 className='text-secondary-2 poppins-semibold text-center md:text-left'>Devis à traiter</h3>
        {devisatraiter.length > 0 ? (
          <table className='table-fixed min-w-full bg-white rounded-b-lg'>
            <thead>
              <tr className='w-full h-12 border-gray-300  border-b py-8'>
                <th className='pr-4 text-secondary-1 text-left text-sm tracking-normal leading-4'>Numéro</th>
                <th className='pr-4 text-secondary-1 text-left text-sm tracking-normal leading-4'>Formation</th>
                <th className='pr-4 text-secondary-1 text-left text-sm tracking-normal leading-4'>Entreprise</th>
                <th className='pr-4 text-secondary-1 text-left text-sm tracking-normal leading-4'>Salarié</th>
                <th className='pr-4 text-secondary-1 text-left text-sm tracking-normal leading-4'>Avancement</th>
                <th className='text-secondary-1 px-2 text-center text-sm tracking-normal leading-4'>Voir</th>
              </tr>
            </thead>
            <tbody>
              {devisatraiter &&
                devisatraiter.map((devi) => (
                  <tr key={devi.id} className=' border-gray-100 border-b hover:bg-gray-100' onDoubleClick={() => navigate(`/admin/devis/${devi.id}`)}>
                    <td className='pr-4 py-3 text-secondary-1/90 text-xs'>{devi.numero}</td>
                    <td className='pr-4 py-3'>
                      <FormationSessionDisplay formation={devi.formation} groupe={devi.groupe} />
                    </td>
                    <td className='pr-4 py-3'>
                      <EntrepriseDisplay entreprise={devi.entreprise} />
                    </td>
                    <td className='pr-4 py-3'>
                      <UserDisplay user={devi.user} />
                    </td>
                    <td className='pr-4 py-3 text-secondary-1/90 text-xs'>{<div>{afficheAvancement(devi)}</div>}</td>
                    <td className='py-3 text-secondary-1/90 text-xs'>
                      <div className='flex justify-center'>
                        <Link to={`/admin/devis/${devi.id}`}>{<EyeIcon className='w-5 h-5' />}</Link>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        ) : (
          <p className='p-5 text-center text-sm font-medium text-secondary-1/70'>Aucun devis à traiter pour le moment.</p>
        )}
      </div>
      <div className='pt-4'>
        <h3 className='text-secondary-2 poppins-semibold text-center md:text-left'>Devis sans réponse</h3>
        {devissansreponse.length > 0 ? (
          <table className='table-fixed min-w-full bg-white rounded-b-lg'>
            <thead>
              <tr className='w-full h-12 border-gray-300  border-b py-8'>
                <th className='pr-4 text-secondary-1 text-left text-sm tracking-normal leading-4'>Numéro</th>
                <th className='pr-4 text-secondary-1 text-left text-sm tracking-normal leading-4'>Formation</th>
                <th className='pr-4 text-secondary-1 text-left text-sm tracking-normal leading-4'>Entreprise</th>
                <th className='pr-4 text-secondary-1 text-left text-sm tracking-normal leading-4'>Salarié</th>
                <th className='pr-4 text-secondary-1 text-left text-sm tracking-normal leading-4'>Avancement</th>
                <th className=' text-secondary-1 px-2 text-center text-sm tracking-normal leading-4'>Voir</th>
              </tr>
            </thead>
            <tbody>
              {devissansreponse &&
                devissansreponse.map((devi) => (
                  <tr key={devi.id} className=' border-gray-100 border-b hover:bg-gray-100' onDoubleClick={() => navigate(`/admin/devis/${devi.id}`)}>
                    <td className='pr-4 py-3 text-secondary-1/90 text-xs'>{devi.numero}</td>
                    <td className='pr-4 py-3'>
                      <FormationSessionDisplay formation={devi.formation} groupe={devi.groupe} />
                    </td>
                    <td className='pr-4 py-3'>
                      <EntrepriseDisplay entreprise={devi.entreprise} />
                    </td>
                    <td className='pr-4 py-3'>
                      <UserDisplay user={devi.user} />
                    </td>
                    <td className='pr-4 py-3 text-secondary-1/90 text-xs'>{<div>{afficheAvancement(devi)}</div>}</td>
                    <td className='py-3 text-secondary-1/90 text-xs'>
                      <div className='flex justify-center'>
                        <Link to={`/admin/devis/${devi.id}`}>{<EyeIcon className='w-5 h-5' />}</Link>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        ) : (
          <p className='p-5 text-center text-sm font-medium text-secondary-1/70'>Aucun devis sans réponse pour le moment.</p>
        )}
      </div>
    </Fragment>
  );
};

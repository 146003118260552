/**
 * Permet de décrire le type de donnée d'une colonne. Cela peut conditionner la mannière dont elle seront filtrées.
 */
export enum ColumnTypes {
  array = 'array',
  boolean = 'boolean',
  color = 'color',
  currency = 'currency',
  date = 'date',
  datetime = 'datetime',
  decimal = 'decimal',
  foreign = 'foreign',
  id = 'id',
  image = 'image',
  int = 'int',
  trilean = 'trilean',
  varchar = 'varchar',
}

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

/**
 * Permet de typer les colonnes du composant ressouce liste.
 */
export type RessourceListColumn<T = unknown> = {
  /**
   * Composant que l'on s'attend à utiliser que l'on s'attend à afficher.
   *
   * @defaultValue ``
   */
  component?: string;

  /**
   * Nom qui sera afficher en en-tête de colonne.
   */
  label: string;

  /**
   * Nom de l'atribu dans la ressource.
   */
  name: keyof T;

  /**
   * Permet de savoir si on peut faire un recherche au sein des valeurs de la colonne.
   *
   * @defaultValue `false`
   */
  searchable?: boolean;

  /**
   * Permet de savoir si on peut faire un tri sur la colonne.
   *
   * @defaultValue `true`
   */
  sortable?: boolean;

  /**
   * type de données que l'on s'attend à afficher.
   *
   * @defaultValue `varchar`
   */
  type: ColumnTypes;
};

import { BuildingStorefrontIcon } from '@heroicons/react/24/outline';
import { Fragment, FunctionComponent } from 'react';

/**
 * Ce composant affiche un avatar pour l'utilisateur.
 *
 * @param src - Source de l'image. Par défault `null`.
 * @param className - Classes tailwind afin d'ajuster et styliser le groupe d'étoiles. Par défaut `'rounded h-full w-full object-cover aspect-1'`.
 * @param title - Titre que l'on souhaite donner à l'avatar, ça apparait comme un titre d'image.
 */
export const Logo: FunctionComponent<{ src?: string | null; className?: string; title?: string }> = ({
  src = null,
  className = 'rounded h-full w-full object-cover aspect-1',
  title = '',
}) => (
  <Fragment>
    {src ? (
      <img className={className} src={src} alt={title} title={title} />
    ) : (
      <div className={`${className} bg-secondary-2 flex items-center justify-center`} title={title}>
        <BuildingStorefrontIcon className='h-1/2 w-1/2 text-white' />
      </div>
    )}
  </Fragment>
);

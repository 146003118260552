import { InboxArrowDownIcon, PaperAirplaneIcon, PlusIcon } from '@heroicons/react/20/solid';
import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { LoadingAbsolute } from '../../components/LoadingAbsolute';
import { ModalInvitation } from '../../components/Modales/Salarie/ModalInvitation';
import { Config } from '../../config/config';
import { useApi } from '../../providers/ApiProvider';
import { GoodApiResponse, ErrorApiResponse } from '../../types/api';
import { Invitation } from '../../types/SalariesRessources';
import { Logo } from '../../components/Logo';
import { handleError } from '../../utils/ErrorHandler';
import { ModalAnnulationInvitation } from '../../components/Modales/Salarie/ModalAnnulationInvitation';
import { ModalAcceptationInvitation } from '../../components/Modales/Salarie/ModalAcceptationInvitation';
import { ModalRefusInvitation } from '../../components/Modales/Salarie/ModalRefusInvitation';
import { Tab, TabPanel, Tabs, TabsBody, TabsHeader } from '../../components/Onglets';
import { InputSwitch } from '../../components/Inputs/InputSwitch';

/**
 * Ce composant affiche la page ou l'utilisateur peut gérer ses invitations envoyées et recues.
 */
export const InvitationsScreen: FunctionComponent = () => {
  /**
   * On stocke si on est en train d'utiliser l'API ou non.
   */
  const [apiIsLoading, setApiIsLoading] = useState(false);

  /**
   * État local qui gère la liste des invitations envoyées
   */
  const [invitationsEnvoyees, setInvitationsEnvoyees] = useState<Invitation[] | null>(null);

  /**
   * État local qui gère la liste des invitations reçues
   */
  const [invitationsRecues, setInvitationsRecues] = useState<Invitation[] | null>(null);

  /**
   * État local qui gère la liste des anciennes invitations envoyées
   */
  const [invitationsAnciennesEnvoyees, setInvitationsAnciennesEnvoyees] = useState<Invitation[] | null>(null);

  /**
   * État local qui gère la liste des anciennes invitations reçues
   */
  const [invitationsAnciennesRecues, setInvitationsAnciennesRecues] = useState<Invitation[] | null>(null);

  /**
   * Permet de stocker le jetion de l'invitation sur laquelle on souhaite effectuer une action.
   */
  const [invitationTokenSelectionnee, setInvitationTokenSelectionnee] = useState<Invitation['token'] | null>();

  /**
   * Permet d'ouvrir une modale lorsque l'on souhaite lancer une invitation pour rejoindre une entreprise.
   */
  const [modalEstVisible, setModalEstVisible] = useState<boolean>(false);

  /**
   * Permet d'ouvrir une modale lorsque l'on souhaite annuler une invitation envoyée à une entreprise.
   */
  const [modalAnnulationEstVisible, setModalAnnulationEstVisible] = useState<boolean>(false);

  /**
   * Permet d'ouvrir une modale lorsque l'on souhaite accepter une invitation reçue par une entreprise.
   */
  const [modalAcceptationEstVisible, setModalAcceptationEstVisible] = useState<boolean>(false);

  /**
   * Permet d'ouvrir une modale lorsque l'on souhaite refuser une invitation reçue par une entreprise.
   */
  const [modalRefusEstVisible, setModalRefusEstVisible] = useState<boolean>(false);

  /**
   * Permet de filtrer par provenance.
   */
  const [filtreAnciennes, setFiltreAnciennes] = useState<boolean>(localStorage.getItem(`InvitationScreen-filtreAnciennes`) === 'true' || false);

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  /**
   * Permet de recharger les données au chargement de page
   */
  useEffect(() => {
    handleIndex();
  }, [client]);

  /**
   * Retrouve des données paginées de L'API et les stocke dans un état local.
   */
  const handleIndex = () => {
    setApiIsLoading(true);
    client
      .get<GoodApiResponse<Invitation[]>>('/invitations')
      .then((reponse) => {
        //setInvitations(reponse.data.data as unknown as Invitation[]);
        setInvitationsEnvoyees(
          (reponse.data.data as unknown as Invitation[]).filter(
            (invitation) => invitation.date_acceptation === null && invitation.date_refus === null && invitation.par_salarie,
          ),
        );
        setInvitationsRecues(
          (reponse.data.data as unknown as Invitation[]).filter(
            (invitation) => invitation.date_acceptation === null && invitation.date_refus === null && invitation.par_entreprise,
          ),
        );

        setInvitationsAnciennesEnvoyees(
          (reponse.data.data as unknown as Invitation[]).filter(
            (invitation) => (invitation.date_acceptation !== null || invitation.date_refus !== null) && invitation.par_salarie,
          ),
        );

        setInvitationsAnciennesRecues(
          (reponse.data.data as unknown as Invitation[]).filter(
            (invitation) => (invitation.date_acceptation !== null || invitation.date_refus !== null) && invitation.par_entreprise,
          ),
        );
      })
      .catch((error: ErrorApiResponse<Invitation>) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  const handleModal = () => {
    setModalEstVisible(true);
  };

  const handleModalAnnulation = (invitationToken: Invitation['token']) => {
    setInvitationTokenSelectionnee(invitationToken);
    setModalAnnulationEstVisible(true);
  };

  const handleModalAcceptation = (invitationToken: Invitation['token']) => {
    setInvitationTokenSelectionnee(invitationToken);
    setModalAcceptationEstVisible(true);
  };

  const handleModalRefus = (invitationToken: Invitation['token']) => {
    setInvitationTokenSelectionnee(invitationToken);
    setModalRefusEstVisible(true);
  };

  /**
   * Contient les informations pour les onglets.
   * @constant
   */
  const datainvits = [
    {
      label: 'Reçues',
      value: 'recues',
      icone: <InboxArrowDownIcon className='w-5 h-5 flex-none' />,
    },
    {
      label: 'Envoyées',
      value: 'envoyees',
      icone: <PaperAirplaneIcon className='w-5 h-5 flex-none' />,
    },
  ] as const;

  if (invitationsEnvoyees === null || invitationsRecues === null) {
    return <Fragment>Chargement...</Fragment>;
  }

  return (
    <div className='shadow-xl rounded-lg'>
      <Helmet>
        <title>Invitations - {Config.app_label}</title>
      </Helmet>
      <div className='px-4 py-4 md:py-3 bg-gray-100 rounded-tl rounded-tr'>
        <div className='flex flex-col sm:flex-row items-center justify-between'>
          <div className='flex flex-row gap-3 basis-full items-center w-full sm:basis-1/2 lg:basis-3/5'>
            <h1 className='text-base sm:text-lg md:text-xl poppins-bold leading-normal text-secondary-1'>Mes invitations</h1>
            <button className='mr-1 py-1 px-6 flex flex-row items-center rounded-full bg-primary text-white' onClick={() => handleModal()}>
              <PlusIcon className='h-4 w-4 inline mr-2 flex-none' />
              <span>Inviter</span>
            </button>
          </div>
          <InputSwitch
            label='Voir les anciennes invitations'
            checked={filtreAnciennes}
            onChange={() => {
              setFiltreAnciennes(!filtreAnciennes);
              localStorage.setItem(`InvitationScreen-filtreAnciennes`, (!filtreAnciennes).toString());
            }}
          />
        </div>
      </div>
      <Tabs value='recues'>
        <TabsHeader>
          {datainvits.map(({ label, value, icone }) => (
            <Tab key={value} value={value}>
              {icone}
              <span>{label}</span>
            </Tab>
          ))}
        </TabsHeader>

        <TabsBody>
          <TabPanel value='recues'>
            <div className='bg-white p-5 rounded-b-lg'>
              {invitationsRecues && invitationsRecues.length > 0 ? (
                invitationsRecues.map((invitation) => (
                  <div key={invitation.token} className='sm:flex justify-between gap-2 w-full max-w-2xl mx-auto mb-4 rounded-lg bg-white shadow-lg p-5'>
                    <div className='w-28'>
                      <Logo className='rounded h-20 w-20 object-cover' src={invitation.entreprise && invitation.entreprise.image_logo} />
                    </div>
                    <div className='w-full'>
                      <div>
                        <h3 className='text-lg poppins-semibold text-secondary-1'>
                          {invitation.entreprise ? `Entreprise : ${invitation.entreprise.raison_sociale}` : 'Entreprise inexistante'}
                        </h3>
                        <p className='text-gray-600 text-sm poppins'>
                          Reçue le :{' '}
                          {invitation.created_at
                            ? new Intl.DateTimeFormat('default', {
                                year: 'numeric',
                                month: 'numeric',
                                day: 'numeric',
                                hour: 'numeric',
                                minute: 'numeric',
                                hour12: false,
                              }).format(new Date(invitation.created_at as string))
                            : ''}
                        </p>
                      </div>
                      <div className='text-center sm:text-left pt-4 sm:mt-0 flex flex-row justify-center md:justify-end'>
                        <button
                          className='bg-secondary-1 uppercase text-xs poppins-bold hover:bg-gray-300 text-white hover:text-secondary-1 py-2 px-5 rounded-full shadow-sm mr-3'
                          onClick={() => handleModalRefus(invitation.token)}>
                          Refuser
                        </button>
                        <button
                          className='bg-primary uppercase text-xs poppins-bold text-white py-2 px-5 rounded-full shadow-sm hover:bg-secondary-1'
                          onClick={() => handleModalAcceptation(invitation.token)}>
                          Accepter
                        </button>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className='text-center'>Il n'y a pas d'invitations reçues</div>
              )}
              {filtreAnciennes && (
                <div className='pt-4'>
                  <div className='text-center mb-4'>
                    <h3>Anciennes invitations</h3>
                  </div>
                  {invitationsAnciennesRecues && invitationsAnciennesRecues.length > 0 ? (
                    invitationsAnciennesRecues.map((invitation) => (
                      <div
                        key={invitation.token}
                        className='sm:flex justify-between gap-2 w-full max-w-2xl mx-auto mb-4 rounded-lg bg-gray-100 p-5 shadow-none opacity-50 border-gray-500 border-dashed border'>
                        <div className='w-28'>
                          <Logo className='rounded h-20 w-20 object-cover' src={invitation.entreprise && invitation.entreprise.image_logo} />
                        </div>
                        <div className='w-full'>
                          <div>
                            <h3 className='text-lg poppins-semibold text-secondary-1'>
                              {invitation.entreprise ? `Entreprise : ${invitation.entreprise.raison_sociale}` : 'Entreprise inexistante'}
                            </h3>
                            <p className='text-gray-600 text-sm poppins'>
                              Reçue le :{' '}
                              {invitation.created_at
                                ? new Intl.DateTimeFormat('default', {
                                    year: 'numeric',
                                    month: 'numeric',
                                    day: 'numeric',
                                    hour: 'numeric',
                                    minute: 'numeric',
                                    hour12: false,
                                  }).format(new Date(invitation.created_at as string))
                                : ''}
                            </p>
                            {invitation.date_acceptation && (
                              <p className='text-gray-600 poppins text-sm'>
                                Acceptée le :{' '}
                                {new Intl.DateTimeFormat('default', {
                                  year: 'numeric',
                                  month: 'numeric',
                                  day: 'numeric',
                                  hour: 'numeric',
                                  minute: 'numeric',
                                  hour12: false,
                                }).format(new Date(invitation.date_acceptation as string))}
                              </p>
                            )}
                            {invitation.date_refus && (
                              <p className='text-gray-600 poppins text-sm'>
                                Refusée le :{' '}
                                {new Intl.DateTimeFormat('default', {
                                  year: 'numeric',
                                  month: 'numeric',
                                  day: 'numeric',
                                  hour: 'numeric',
                                  minute: 'numeric',
                                  hour12: false,
                                }).format(new Date(invitation.date_refus as string))}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className='text-center'>Il n'y a pas d'anciennes invitations reçues</div>
                  )}
                </div>
              )}
            </div>
          </TabPanel>
          <TabPanel value='envoyees'>
            <div className='bg-white p-5 rounded-b-lg'>
              {invitationsEnvoyees && invitationsEnvoyees.length > 0 ? (
                invitationsEnvoyees.map((invitation) => (
                  <div key={invitation.token} className='sm:flex justify-between gap-2 w-full max-w-2xl mx-auto mb-4 rounded-lg bg-white shadow-lg p-5'>
                    <div className='w-28'>
                      <Logo className='rounded h-20 w-20 object-cover' src={invitation.entreprise && invitation.entreprise.image_logo} />
                    </div>
                    <div className='w-full'>
                      <div>
                        <h3 className='text-lg poppins-semibold'>{invitation.email}</h3>
                        <p className='text-lg poppins-semibold text-secondary-1'>
                          {invitation.entreprise ? `Entreprise : ${invitation.entreprise.raison_sociale}` : 'Entreprise inexistante'}
                        </p>
                        <p className='text-gray-600 text-sm'>
                          Envoyée le :{' '}
                          {invitation.created_at
                            ? new Intl.DateTimeFormat('default', {
                                year: 'numeric',
                                month: 'numeric',
                                day: 'numeric',
                                hour: 'numeric',
                                minute: 'numeric',
                                hour12: false,
                              }).format(new Date(invitation.created_at as string))
                            : ''}
                        </p>
                      </div>
                      <div className='text-center sm:text-left pt-4 sm:mt-0 flex flex-row justify-center md:justify-end'>
                        <button
                          className='bg-secondary-1 uppercase text-xs poppins-bold hover:bg-gray-300 text-white hover:text-secondary-1 py-2 px-5 rounded-full shadow-sm mr-3'
                          onClick={() => handleModalAnnulation(invitation.token)}>
                          Annuler
                        </button>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className='text-center'>Il n'y a pas d'invitations envoyées</div>
              )}
              {filtreAnciennes && (
                <div className='pt-4'>
                  <div className='text-center mb-4'>
                    <h3>Anciennes invitations</h3>
                  </div>
                  {invitationsAnciennesEnvoyees && invitationsAnciennesEnvoyees.length > 0 ? (
                    invitationsAnciennesEnvoyees.map((invitation) => (
                      <div
                        key={invitation.token}
                        className='sm:flex justify-between gap-2 w-full max-w-2xl mx-auto mb-4 rounded-lg bg-gray-100 p-5 shadow-none opacity-50 border-gray-500 border-dashed border'>
                        <div className='w-28'>
                          <Logo className='rounded h-20 w-20 object-cover' src={invitation.entreprise && invitation.entreprise.image_logo} />
                        </div>
                        <div className='w-full'>
                          <div>
                            <h3 className='text-lg poppins-semibold'>{invitation.email}</h3>
                            <p className='text-lg poppins-semibold text-secondary-1'>
                              {invitation.entreprise ? `Entreprise : ${invitation.entreprise.raison_sociale}` : 'Entreprise inexistante'}
                            </p>
                            <p className='text-gray-600 text-sm'>
                              Envoyée le :{' '}
                              {invitation.created_at
                                ? new Intl.DateTimeFormat('default', {
                                    year: 'numeric',
                                    month: 'numeric',
                                    day: 'numeric',
                                    hour: 'numeric',
                                    minute: 'numeric',
                                    hour12: false,
                                  }).format(new Date(invitation.created_at as string))
                                : ''}
                            </p>
                            {invitation.date_acceptation && (
                              <p className='text-gray-600 text-sm'>
                                Acceptée le :{' '}
                                {new Intl.DateTimeFormat('default', {
                                  year: 'numeric',
                                  month: 'numeric',
                                  day: 'numeric',
                                  hour: 'numeric',
                                  minute: 'numeric',
                                  hour12: false,
                                }).format(new Date(invitation.date_acceptation as string))}
                              </p>
                            )}
                            {invitation.date_refus && (
                              <p className='text-gray-600 text-sm'>
                                Refusée le :{' '}
                                {new Intl.DateTimeFormat('default', {
                                  year: 'numeric',
                                  month: 'numeric',
                                  day: 'numeric',
                                  hour: 'numeric',
                                  minute: 'numeric',
                                  hour12: false,
                                }).format(new Date(invitation.date_refus as string))}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className='text-center'>Il n'y a pas d'anciennes invitations envoyées</div>
                  )}
                </div>
              )}
            </div>
          </TabPanel>
        </TabsBody>
      </Tabs>
      {apiIsLoading ? <LoadingAbsolute /> : ''}
      <ModalInvitation visible={modalEstVisible} setVisible={setModalEstVisible} handleIndex={handleIndex} />
      {invitationTokenSelectionnee && (
        <Fragment>
          <ModalAnnulationInvitation
            visible={modalAnnulationEstVisible}
            setVisible={setModalAnnulationEstVisible}
            invitationToken={invitationTokenSelectionnee}
            handleIndex={handleIndex}
          />
          <ModalAcceptationInvitation
            visible={modalAcceptationEstVisible}
            setVisible={setModalAcceptationEstVisible}
            invitationToken={invitationTokenSelectionnee}
            handleIndex={handleIndex}
          />
          <ModalRefusInvitation
            visible={modalRefusEstVisible}
            setVisible={setModalRefusEstVisible}
            invitationToken={invitationTokenSelectionnee}
            handleIndex={handleIndex}
          />
        </Fragment>
      )}
    </div>
  );
};

import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingAbsolute } from '../../components/LoadingAbsolute';
import { useApi } from '../../providers/ApiProvider';
import { HeaderForm } from '../../components/HeaderForm';
import { GoodApiResponse, ErrorApiResponse } from '../../types/api';
import { Helmet } from 'react-helmet';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { Config } from '../../config/config';
import { handleError } from '../../utils/ErrorHandler';
import nl2br from 'react-nl2br';
import { Document } from '../../types/SalariesRessources';
import { Tab, TabPanel, Tabs, TabsBody, TabsHeader } from '../../components/Onglets';
import { FormationSessionDisplay } from '../../components/FormationSessionDisplay';
import { DownloadLink } from '../../components/DownloadLink';

/**
 * Ce composant doit afficher le docoment identifié par son jeton.
 */
export const DocumentScreen: FunctionComponent = () => {
  // On initialise les données dont on va avoir besoin sur cette page

  /**
   * On stocke si on est en train d'utiliser l'API ou non.
   */
  const [apiIsLoading, setApiIsLoading] = useState(false);

  /**
   * Permet de stocker les informations relatives au document.
   */
  const [document, setDocument] = useState<Document | null>(null);

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  /**
   * Permet au composant de changer de page.
   */
  const navigate = useNavigate();

  /**
   * On a besoin des paramètres pour avoir l'id du devis.
   */
  const params = useParams();

  /**
   * Permet de recharger les données au chargement de la page.
   */
  useEffect(() => {
    handleShow();
  }, [client]);

  /**
   * Retrouve par le réseau la convocation et la met dans un état local.
   */
  const handleShow = () => {
    if (apiIsLoading === true) {
      return;
    }

    setApiIsLoading(true);
    client
      .get<GoodApiResponse<Document>>(`/salarie/documents/${params.token}`)
      .then((response) => {
        setDocument(response.data.data as unknown as Document);
      })
      .catch((error: ErrorApiResponse<Document>) => {
        handleError(error, () => {
          navigate('/mes-documents');
        });
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Contenu des onglets.
   * @constant
   */
  const data = [
    {
      label: 'Général',
      value: 'general',
      icone: <CheckCircleIcon className='w-5 h-5 flex-none' />,
    },
  ] as const;

  if (document === null) {
    return <Fragment>Chargement...</Fragment>;
  }

  return (
    <Fragment>
      <Helmet>
        <title>Document - {Config.app_label}</title>
      </Helmet>
      <HeaderForm text={`Document`} returnUrl='/mes-documents' />
      <Tabs value='general'>
        <TabsHeader>
          {data.map(({ label, value, icone }) => (
            <Tab key={value} value={value}>
              {icone}
              <span>{label}</span>
            </Tab>
          ))}
        </TabsHeader>
        <TabsBody>
          <TabPanel value='general'>
            <form className='relative'>
              <div className='bg-white p-5 rounded-b-lg'>
                <div className='container mx-auto bg-white rounded-none'>
                  <fieldset className='flex flex-col md:flex-row gap-3 pb-7'>
                    <div className='basis-3/5 md:border-r border-secondary-1/30'>
                      <div className='mb-6'>
                        <legend className='text-secondary-1 poppins-semibold text-lg mb-3'>Informations</legend>
                        <div className='text-secondary-1'>
                          {`Ajouté le ${new Intl.DateTimeFormat('default', {
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                            hour12: false,
                          }).format(new Date(document.created_at as string))}`}
                        </div>
                      </div>
                      <legend className='text-secondary-1 poppins-semibold text-lg mb-3'>Formation</legend>
                      <FormationSessionDisplay formation={document.formation} groupe={document.groupe} />
                    </div>
                    <div className='text-sm'>
                      <div className='pb-2'>{document.fichier && <DownloadLink fichier={document.fichier} titre={document.titre} />}</div>
                      <div>{nl2br(document.description)}</div>
                    </div>
                  </fieldset>
                </div>
              </div>
            </form>
          </TabPanel>
        </TabsBody>
      </Tabs>
      {apiIsLoading ? <LoadingAbsolute /> : ''}
    </Fragment>
  );
};

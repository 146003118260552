import { Dialog, DialogHeader, DialogBody, DialogFooter, Button } from '@material-tailwind/react';
import { FunctionComponent, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useApi } from '../../../providers/ApiProvider';
import { Demande, DemandeEtatAvancement } from '../../../types/AdminRessources';
import { ErrorApiResponse, GoodApiResponse } from '../../../types/api';
import { InputTextarea } from '../../Inputs/InputTextarea';
import { LoadingAbsolute } from '../../LoadingAbsolute';

/**
 * Ce composant permet d'afficher une Modale pour gérer l'avancement de la demande.
 *
 * @param visible - Précise si la modale et ouverte ou non. Provient du parent.
 * @param setVisible - Accesseur qui modifie la visibilité de la modale. Provient du parent.
 * @param etatAvancement - État d'avancement de la demande. Provient du parent.
 * @param handleShow - Callback qui affiche les informations de la demande dans le composant parent. Provient du parent.
 * @param demandeId - ID de la demande. Provient du parent.
 */
export const ModalAvancementDemande: FunctionComponent<{
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  etatAvancement: DemandeEtatAvancement;
  handleShow: () => void;
  demandeId: Demande['id'];
}> = ({ visible, setVisible, etatAvancement, handleShow, demandeId }) => {
  /**
   * On stocke si on est en train d'utiliser l'API ou non.
   */
  const [apiIsLoading, setApiIsLoading] = useState(false);

  /**
   * Titre de la modale
   */
  const [title, setTitle] = useState<string>('');

  /**
   * Contenu de la modale
   */
  const [content, setContent] = useState<string>('');

  /**
   * On stocke les éventuelles informations de paiement.
   */
  const [informationsPaiement, setInformationsPaiement] = useState<string>('');

  /**
   * On stocke le motif de refus éventuel
   */
  const [motifRefus, setMotifRefus] = useState<string>('');

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  /**
   * Effectue un appel en fonction de l'état d'avancement de la demande
   */
  const handleAction = async () => {
    setApiIsLoading(true);

    try {
      if (etatAvancement === DemandeEtatAvancement.refuse) {
        await client.patch<GoodApiResponse>(`/admin/demandes/${demandeId}/${DemandeEtatAvancement.refuse}`, { motif_refus: motifRefus });
      } else if (etatAvancement === DemandeEtatAvancement.paye) {
        await client.patch<GoodApiResponse>(`/admin/demandes/${demandeId}/${DemandeEtatAvancement.paye}`, { informations_paiement: informationsPaiement });
      } else {
        await client.patch<GoodApiResponse>(`/admin/demandes/${demandeId}/${etatAvancement}`);
      }
      setApiIsLoading(false);
      toast.success('Demande mise à jour.');
      handleShow();
    } catch (error) {
      if (
        (error as ErrorApiResponse).response &&
        parseInt((error as ErrorApiResponse).response.status) >= 400 &&
        (error as ErrorApiResponse).response.data.message
      ) {
        toast.error((error as ErrorApiResponse).response.data.message);
      } else {
        toast.error((error as ErrorApiResponse).message);
      }
      setApiIsLoading(false);
    }
  };

  /**
   * Cette effet perment de remplir les état locaux de la modale avec les informations correspondandant à son état d'avancement à Chaque ouverture ou fermeture de la modale.
   */
  useEffect(() => {
    switch (etatAvancement) {
      case DemandeEtatAvancement.accepte:
        setTitle('Accepter la demande ?');
        setContent('Souhaitez-vous vraiment accepter la demande ?');
        break;
      case DemandeEtatAvancement.refuse:
        setTitle('Refuser la demande ?');
        setContent('Motif du refus de la demande ?');
        break;
      case DemandeEtatAvancement.complete:
        setTitle('Demande complète ?');
        setContent('Souhaitez-vous vraiment marquer la demande comme complétée ?');
        break;

      case DemandeEtatAvancement.paye:
        setTitle('Demande payée ?');
        setContent('Informations sur le paiement.');
        break;

      case DemandeEtatAvancement.finalise:
        setTitle('Demande finalisée ?');
        setContent('Souhaitez-vous vraiment marquer la demande comme finalisée ?');
        break;

      case DemandeEtatAvancement.archive:
        setTitle('Demande archivée ?');
        setContent('Souhaitez-vous vraiment marquer la demande comme archivée ?');
        break;

      default:
        break;
    }
  }, [visible]);

  return (
    <Dialog open={visible} size='xs' handler={setVisible}>
      <DialogHeader>{title}</DialogHeader>
      <DialogBody divider>
        {content}
        {etatAvancement === DemandeEtatAvancement.refuse && <InputTextarea value={motifRefus} onChange={(e) => setMotifRefus(e.target.value)}></InputTextarea>}
        {etatAvancement === DemandeEtatAvancement.paye && (
          <InputTextarea value={informationsPaiement} onChange={(e) => setInformationsPaiement(e.target.value)}></InputTextarea>
        )}
      </DialogBody>
      <DialogFooter>
        <Button
          variant='text'
          color='gray'
          onClick={() => {
            setVisible(false);
          }}
          className='mr-1 !rounded-full'>
          Annuler
        </Button>
        <Button
          variant='filled'
          color='orange'
          onClick={() => {
            handleAction();
            setVisible(false);
          }}>
          Confirmer
        </Button>
      </DialogFooter>
      {apiIsLoading ? <LoadingAbsolute /> : ''}
    </Dialog>
  );
};

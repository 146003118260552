import {
  AcademicCapIcon,
  ArrowUpTrayIcon,
  BookOpenIcon,
  BriefcaseIcon,
  BuildingLibraryIcon,
  BuildingOffice2Icon,
  BuildingStorefrontIcon,
  CalculatorIcon,
  CalendarDaysIcon,
  ChartBarSquareIcon,
  ChatBubbleLeftEllipsisIcon,
  ChatBubbleOvalLeftEllipsisIcon,
  CheckBadgeIcon,
  ChevronDownIcon,
  ClipboardDocumentListIcon,
  ClockIcon,
  CodeBracketSquareIcon,
  Cog6ToothIcon,
  CubeTransparentIcon,
  CurrencyEuroIcon,
  DocumentIcon,
  DocumentTextIcon,
  EnvelopeIcon,
  FaceSmileIcon,
  FlagIcon,
  FolderIcon,
  GlobeEuropeAfricaIcon,
  HandRaisedIcon,
  HeartIcon,
  IdentificationIcon,
  LightBulbIcon,
  MagnifyingGlassIcon,
  MapIcon,
  MapPinIcon,
  NewspaperIcon,
  PencilIcon,
  PresentationChartBarIcon,
  Squares2X2Icon,
  SwatchIcon,
  TruckIcon,
  UserCircleIcon,
  UserGroupIcon,
  UserIcon,
  UserPlusIcon,
  UsersIcon,
  WrenchIcon,
} from '@heroicons/react/24/outline';
import { Accordion, AccordionBody, List } from '@material-tailwind/react';
import { Fragment, FunctionComponent, SetStateAction, useState } from 'react';
import { Link } from 'react-router-dom';
import { useUser } from '../../providers/UserProvider';
import { RoleEnum, TypeProfilEnum } from '../../types/auth';
import { DashboardSidebarLink } from './DashboardSidebarLink';
import { DashboardSidebarCategory } from './DashboardSidebarCategory';

/**
 * Ce composant affiche le menu de gauche pour le Back-Offiche.
 *
 * @param handleMenuItemOnClick
 */
export const DashboardSidebar: FunctionComponent<{ handleMenuItemOnClick: () => void }> = ({ handleMenuItemOnClick }) => {
  /**
   * On a besoin des information de l'utilisateur ainsi que du profil.
   */
  const { user, profil: currentProfil } = useUser();

  /**
   * On en besoin de gérer l'état des recherches textuelles pour un accés rapide aux formations.
   */
  const [recherche, setRecherche] = useState<string>('');

  /**
   * Permet d'effectuer une recherche de formation directement depuis ce menu de gauche.
   */
  const handleSearch = () => {
    window.open(`/resultats-recherche?recherche=${recherche}&sites=`);
  };

  /*---------menus déroulants sidebar---------*/

  const [open, setOpen] = useState(0);

  /**
   * Gère l'overture des liste déroulante du menu.
   *
   * @param value - Valeur du menu à dérouler.
   */
  const handleOpen = (value: SetStateAction<number>) => {
    setOpen(open === value ? 0 : value);
  };

  return (
    <div
      className={
        'fixed top-0 bottom-0 left-0 h-[calc(100vh)] xl:min-h-fit w-full max-w-[19rem] p-4 pt-14 shadow-xl shadow-blue-gray-900/5 z-[60] bg-secondary-1 text-white overflow-y-auto no-scrollbar flex flex-col'
      }>
      <div className='flex flex-col h-full justify-between'>
        <div className=''>
          <Link to='/' className='w-full items-center mb-7' target='_blank'>
            <img src='/assets/aocdtf-logo-white-orange.svg' className='m-8' />
          </Link>

          <DashboardSidebarLink label='Tableau de bord' link='/mon-espace' handleMenuItemOnClick={handleMenuItemOnClick}>
            <Squares2X2Icon className='h-5 w-5' />
          </DashboardSidebarLink>

          {user !== null && user.roles.includes(RoleEnum.standard) && (
            <div className='mb-4'>
              {currentProfil !== null && currentProfil.type_profil === TypeProfilEnum.salarie && (
                <Fragment>
                  <DashboardSidebarLink label='Mon agenda' link='/mon-agenda' handleMenuItemOnClick={handleMenuItemOnClick}>
                    <BookOpenIcon className='h-5 w-5' />
                  </DashboardSidebarLink>
                  <Accordion
                    open={open === 1}
                    icon={<ChevronDownIcon strokeWidth={2.5} className={`mx-auto h-4 w-4 transition-transform ${open === 1 ? 'rotate-180' : ''}`} />}>
                    <DashboardSidebarCategory
                      icon={<PresentationChartBarIcon className='h-5 w-5' />}
                      text='Formations'
                      open={open}
                      index={1}
                      handleClick={() => handleOpen(1)}
                    />
                    <AccordionBody className='py-1 pl-4'>
                      <DashboardSidebarLink label='Mes formations' link='/mes-formations' handleMenuItemOnClick={handleMenuItemOnClick}>
                        <AcademicCapIcon className='h-5 w-5' />
                      </DashboardSidebarLink>
                      <DashboardSidebarLink label='Mes favorites' link='/mes-favoris' handleMenuItemOnClick={handleMenuItemOnClick}>
                        <HeartIcon className='h-5 w-5' />
                      </DashboardSidebarLink>
                      <DashboardSidebarLink label='Souhaits de formation' link='/souhaits-de-formation' handleMenuItemOnClick={handleMenuItemOnClick}>
                        <ChatBubbleOvalLeftEllipsisIcon className='h-5 w-5' />
                      </DashboardSidebarLink>
                      <DashboardSidebarLink label='Mes avis' link='/avis' handleMenuItemOnClick={handleMenuItemOnClick}>
                        <PencilIcon className='h-5 w-5' />
                      </DashboardSidebarLink>

                      <DashboardSidebarLink label='Mes convocations' link='/mes-convocations' handleMenuItemOnClick={handleMenuItemOnClick}>
                        <EnvelopeIcon className='h-5 w-5' />
                      </DashboardSidebarLink>
                      <DashboardSidebarLink label='Mes documents' link='/mes-documents' handleMenuItemOnClick={handleMenuItemOnClick}>
                        <FolderIcon className='h-5 w-5' />
                      </DashboardSidebarLink>
                    </AccordionBody>
                  </Accordion>
                </Fragment>
              )}
              {currentProfil !== null && (currentProfil.type_profil === TypeProfilEnum.dirigeant || currentProfil.type_profil === TypeProfilEnum.delegue) && (
                <Fragment>
                  <Accordion
                    open={open === 1}
                    icon={<ChevronDownIcon strokeWidth={2.5} className={`mx-auto h-4 w-4 transition-transform ${open === 1 ? 'rotate-180' : ''}`} />}>
                    <DashboardSidebarCategory
                      icon={<PresentationChartBarIcon className='h-5 w-5' />}
                      text='Formations'
                      open={open}
                      index={1}
                      handleClick={() => handleOpen(1)}
                    />
                    <AccordionBody className='py-1 pl-4'>
                      <DashboardSidebarLink label='Mes favorites' link='/mes-favoris' handleMenuItemOnClick={handleMenuItemOnClick}>
                        <AcademicCapIcon className='h-5 w-5' />
                      </DashboardSidebarLink>
                      <DashboardSidebarLink
                        label='Souhaits de formation'
                        link='/entreprise/souhaits-de-formation'
                        handleMenuItemOnClick={handleMenuItemOnClick}>
                        <ChatBubbleOvalLeftEllipsisIcon className='h-5 w-5' />
                      </DashboardSidebarLink>
                      <DashboardSidebarLink label='Demandes' link='/entreprise/demandes' handleMenuItemOnClick={handleMenuItemOnClick}>
                        <ChatBubbleOvalLeftEllipsisIcon className='h-5 w-5' />
                      </DashboardSidebarLink>
                      <DashboardSidebarLink label='Devis' link='/entreprise/devis' handleMenuItemOnClick={handleMenuItemOnClick}>
                        <DocumentIcon className='h-5 w-5' />
                      </DashboardSidebarLink>
                      <DashboardSidebarLink label='Convocations' link='/entreprise/convocations' handleMenuItemOnClick={handleMenuItemOnClick}>
                        <EnvelopeIcon className='h-5 w-5' />
                      </DashboardSidebarLink>
                      <DashboardSidebarLink label='Factures' link='/entreprise/factures' handleMenuItemOnClick={handleMenuItemOnClick}>
                        <CurrencyEuroIcon className='h-5 w-5' />
                      </DashboardSidebarLink>
                    </AccordionBody>
                  </Accordion>
                  <Accordion
                    open={open === 2}
                    icon={<ChevronDownIcon strokeWidth={2.5} className={`mx-auto h-4 w-4 transition-transform ${open === 2 ? 'rotate-180' : ''}`} />}>
                    <DashboardSidebarCategory
                      icon={<AcademicCapIcon className='h-5 w-5' />}
                      text='Salariés'
                      open={open}
                      index={2}
                      handleClick={() => handleOpen(2)}
                    />
                    <AccordionBody className='py-1 pl-4'>
                      <DashboardSidebarLink label='Agenda des salariés' link='/entreprise/agenda' handleMenuItemOnClick={handleMenuItemOnClick}>
                        <BookOpenIcon className='h-5 w-5' />
                      </DashboardSidebarLink>
                      <DashboardSidebarLink label='Formations des salariés' link='/entreprise/groupes' handleMenuItemOnClick={handleMenuItemOnClick}>
                        <AcademicCapIcon className='h-5 w-5' />
                      </DashboardSidebarLink>

                      <DashboardSidebarLink label='Documents des salariés' link='/entreprise/documents' handleMenuItemOnClick={handleMenuItemOnClick}>
                        <FolderIcon className='h-5 w-5' />
                      </DashboardSidebarLink>
                    </AccordionBody>
                  </Accordion>
                  {currentProfil.type_profil === TypeProfilEnum.dirigeant && (
                    <Fragment>
                      <DashboardSidebarLink label='Mon entreprise' link='/entreprise/monEntreprise' handleMenuItemOnClick={handleMenuItemOnClick}>
                        <BuildingStorefrontIcon className='h-5 w-5' />
                      </DashboardSidebarLink>
                      <DashboardSidebarLink label='Membres' link='/entreprise/comptes' handleMenuItemOnClick={handleMenuItemOnClick}>
                        <IdentificationIcon className='h-5 w-5' />
                      </DashboardSidebarLink>
                    </Fragment>
                  )}
                  <DashboardSidebarLink label='Invitations' link='/entreprise/invitations' handleMenuItemOnClick={handleMenuItemOnClick}>
                    <UserPlusIcon className='h-5 w-5' />
                  </DashboardSidebarLink>
                </Fragment>
              )}
            </div>
          )}

          {user !== null && user.roles.includes(RoleEnum.admin) && (
            <Fragment>
              <Accordion
                open={open === 1}
                icon={<ChevronDownIcon strokeWidth={2.5} className={`mx-auto h-4 w-4 transition-transform ${open === 1 ? 'rotate-180' : ''}`} />}>
                <DashboardSidebarCategory
                  icon={<PresentationChartBarIcon className='h-5 w-5' />}
                  text='Formation'
                  open={open}
                  index={1}
                  handleClick={() => handleOpen(1)}
                />
                <AccordionBody className='py-1 pl-4'>
                  <DashboardSidebarLink label='Demandes' link='/admin/demandes' handleMenuItemOnClick={handleMenuItemOnClick}>
                    <ChatBubbleLeftEllipsisIcon className='h-5 w-5' />
                  </DashboardSidebarLink>
                  <DashboardSidebarLink label='Devis' link='/admin/devis' handleMenuItemOnClick={handleMenuItemOnClick}>
                    <DocumentIcon className='h-5 w-5' />
                  </DashboardSidebarLink>
                  <DashboardSidebarLink label='Factures' link='/admin/factures' handleMenuItemOnClick={handleMenuItemOnClick}>
                    <CurrencyEuroIcon className='h-5 w-5' />
                  </DashboardSidebarLink>
                  <DashboardSidebarLink label='Convocations' link='/admin/convocations' handleMenuItemOnClick={handleMenuItemOnClick}>
                    <EnvelopeIcon className='h-5 w-5' />
                  </DashboardSidebarLink>
                  <DashboardSidebarLink label='Documents' link='/admin/documents' handleMenuItemOnClick={handleMenuItemOnClick}>
                    <FolderIcon className='h-5 w-5' />
                  </DashboardSidebarLink>
                  <DashboardSidebarLink label='Avis' link='/admin/avis' handleMenuItemOnClick={handleMenuItemOnClick}>
                    <PencilIcon className='h-5 w-5' />
                  </DashboardSidebarLink>
                </AccordionBody>
              </Accordion>

              <Accordion
                open={open === 2}
                icon={<ChevronDownIcon strokeWidth={2.5} className={`mx-auto h-4 w-4 transition-transform ${open === 2 ? 'rotate-180' : ''}`} />}>
                <DashboardSidebarCategory
                  icon={<PresentationChartBarIcon className='h-5 w-5' />}
                  text='Catalogue'
                  open={open}
                  index={2}
                  handleClick={() => handleOpen(2)}
                />
                <AccordionBody className='py-1 pl-4'>
                  <DashboardSidebarLink label='Formations' link='/admin/formations' handleMenuItemOnClick={handleMenuItemOnClick}>
                    <PresentationChartBarIcon className='h-5 w-5' />
                  </DashboardSidebarLink>
                  <DashboardSidebarLink label='Sessions' link='/admin/groupes' handleMenuItemOnClick={handleMenuItemOnClick}>
                    <UserGroupIcon className='h-5 w-5' />
                  </DashboardSidebarLink>
                  <DashboardSidebarLink label='Filières' link='/admin/filieres' handleMenuItemOnClick={handleMenuItemOnClick}>
                    <CubeTransparentIcon className='h-5 w-5' />
                  </DashboardSidebarLink>
                  <DashboardSidebarLink label='Métiers' link='/admin/metiers' handleMenuItemOnClick={handleMenuItemOnClick}>
                    <ClipboardDocumentListIcon className='h-5 w-5' />
                  </DashboardSidebarLink>
                </AccordionBody>
              </Accordion>
              <Accordion
                open={open === 3}
                icon={<ChevronDownIcon strokeWidth={2.5} className={`mx-auto h-4 w-4 transition-transform ${open === 3 ? 'rotate-180' : ''}`} />}>
                <DashboardSidebarCategory
                  icon={<PresentationChartBarIcon className='h-5 w-5' />}
                  text='Utilisateurs & entreprises'
                  open={open}
                  index={3}
                  handleClick={() => handleOpen(3)}
                />
                <AccordionBody className='py-1 pl-4'>
                  <DashboardSidebarLink label='Utilisateurs' link='/admin/users' handleMenuItemOnClick={handleMenuItemOnClick}>
                    <IdentificationIcon className='h-5 w-5' />
                  </DashboardSidebarLink>
                  <DashboardSidebarLink label='Entreprises' link='/admin/entreprises' handleMenuItemOnClick={handleMenuItemOnClick}>
                    <BuildingStorefrontIcon className='h-5 w-5' />
                  </DashboardSidebarLink>
                  <DashboardSidebarLink label='Entreprises à valider' link='/admin/e-en-attente' handleMenuItemOnClick={handleMenuItemOnClick}>
                    <CheckBadgeIcon className='h-5 w-5' />
                  </DashboardSidebarLink>
                </AccordionBody>
              </Accordion>
              <Accordion
                open={open === 4}
                icon={<ChevronDownIcon strokeWidth={2.5} className={`mx-auto h-4 w-4 transition-transform ${open === 4 ? 'rotate-180' : ''}`} />}>
                <DashboardSidebarCategory
                  icon={<PresentationChartBarIcon className='h-5 w-5' />}
                  text='Données YParéo'
                  open={open}
                  index={4}
                  handleClick={() => handleOpen(4)}
                />
                <AccordionBody className='py-1 pl-4'>
                  <h4 className='pl-5 text-sm poppins-semibold text-primary mt-5 pb-1'>Général</h4>
                  <div className='p-0 pl-4'>
                    <DashboardSidebarLink label='Apprenants' link='/admin/apprenants' handleMenuItemOnClick={handleMenuItemOnClick}>
                      <UserIcon className='h-5 w-5' />
                    </DashboardSidebarLink>
                    <DashboardSidebarLink label='Civilités' link='/admin/civilites' handleMenuItemOnClick={handleMenuItemOnClick}>
                      <FaceSmileIcon className='h-5 w-5' />
                    </DashboardSidebarLink>
                    <DashboardSidebarLink label='Communes' link='/admin/communes' handleMenuItemOnClick={handleMenuItemOnClick}>
                      <BuildingOffice2Icon className='h-5 w-5' />
                    </DashboardSidebarLink>
                    <DashboardSidebarLink label='Nationalités' link='/admin/nationalites' handleMenuItemOnClick={handleMenuItemOnClick}>
                      <FlagIcon className='h-5 w-5' />
                    </DashboardSidebarLink>
                    <DashboardSidebarLink label='Pays' link='/admin/pays' handleMenuItemOnClick={handleMenuItemOnClick}>
                      <GlobeEuropeAfricaIcon className='h-5 w-5' />
                    </DashboardSidebarLink>
                  </div>
                  <h4 className='pl-5 text-sm poppins-semibold text-primary pt-2 mt-2 pb-1'>Formations</h4>
                  <div className='p-0 pl-4'>
                    <DashboardSidebarLink label='Années' link='/admin/annees' handleMenuItemOnClick={handleMenuItemOnClick}>
                      <CalendarDaysIcon className='h-5 w-5' />
                    </DashboardSidebarLink>
                    <DashboardSidebarLink label='Centres de formation' link='/admin/sites' handleMenuItemOnClick={handleMenuItemOnClick}>
                      <MapPinIcon className='h-5 w-5' />
                    </DashboardSidebarLink>
                    <DashboardSidebarLink label='Diplômes obtenus' link='/admin/diplomes-obtenus' handleMenuItemOnClick={handleMenuItemOnClick}>
                      <DocumentIcon className='h-5 w-5' />
                    </DashboardSidebarLink>
                    <DashboardSidebarLink label='Diplômes préparés' link='/admin/diplomes-prepares' handleMenuItemOnClick={handleMenuItemOnClick}>
                      <LightBulbIcon className='h-5 w-5' />
                    </DashboardSidebarLink>

                    <DashboardSidebarLink label='Matières' link='/admin/matieres' handleMenuItemOnClick={handleMenuItemOnClick}>
                      <BriefcaseIcon className='h-5 w-5' />
                    </DashboardSidebarLink>

                    <DashboardSidebarLink label='Opcos' link='/admin/opcos' handleMenuItemOnClick={handleMenuItemOnClick}>
                      <CalculatorIcon className='h-5 w-5' />
                    </DashboardSidebarLink>
                    <DashboardSidebarLink label='Périodes' link='/admin/periodes' handleMenuItemOnClick={handleMenuItemOnClick}>
                      <CodeBracketSquareIcon className='h-5 w-5' />
                    </DashboardSidebarLink>
                    <DashboardSidebarLink label='Personnel' link='/admin/personnels' handleMenuItemOnClick={handleMenuItemOnClick}>
                      <UsersIcon className='h-5 w-5' />
                    </DashboardSidebarLink>
                    <DashboardSidebarLink label='Régions' link='/admin/regions' handleMenuItemOnClick={handleMenuItemOnClick}>
                      <MapIcon className='h-5 w-5' />
                    </DashboardSidebarLink>
                    <DashboardSidebarLink label="Secteurs d'activité" link='/admin/secteurs-activite' handleMenuItemOnClick={handleMenuItemOnClick}>
                      <TruckIcon className='h-5 w-5' />
                    </DashboardSidebarLink>
                  </div>
                  <h4 className='pl-5 text-sm poppins-semibold text-primary pt-2 mt-2 pb-1'>Juridique</h4>
                  <div className='p-0 pl-4'>
                    <DashboardSidebarLink label='Conventions collectives' link='/admin/conventions-collectives' handleMenuItemOnClick={handleMenuItemOnClick}>
                      <NewspaperIcon className='h-5 w-5' />
                    </DashboardSidebarLink>
                    <DashboardSidebarLink label='Fonctions Compagnons' link='/admin/fonctions' handleMenuItemOnClick={handleMenuItemOnClick}>
                      <WrenchIcon className='h-5 w-5' />
                    </DashboardSidebarLink>
                    <DashboardSidebarLink label='Formes juridiques' link='/admin/formes-juridiques' handleMenuItemOnClick={handleMenuItemOnClick}>
                      <BuildingLibraryIcon className='h-5 w-5' />
                    </DashboardSidebarLink>
                    <DashboardSidebarLink label='Types employeurs' link='/admin/types-employeurs' handleMenuItemOnClick={handleMenuItemOnClick}>
                      <UserCircleIcon className='h-5 w-5' />
                    </DashboardSidebarLink>
                  </div>
                </AccordionBody>
              </Accordion>

              <Accordion
                open={open === 5}
                icon={<ChevronDownIcon strokeWidth={2.5} className={`mx-auto h-4 w-4 transition-transform ${open === 5 ? 'rotate-180' : ''}`} />}>
                <DashboardSidebarCategory
                  icon={<PresentationChartBarIcon className='h-5 w-5' />}
                  text='Statistiques'
                  open={open}
                  index={5}
                  handleClick={() => handleOpen(5)}
                />
                <AccordionBody className='py-1 pl-4'>
                  <List className='p-0'>
                    <DashboardSidebarLink label='Inscriptions' link='/admin/statistiques/inscriptions' handleMenuItemOnClick={handleMenuItemOnClick}>
                      <ChartBarSquareIcon className='h-5 w-5' />
                    </DashboardSidebarLink>
                    <DashboardSidebarLink label='Recherches' link='/admin/statistiques/recherches' handleMenuItemOnClick={handleMenuItemOnClick}>
                      <ChartBarSquareIcon className='h-5 w-5' />
                    </DashboardSidebarLink>
                    <DashboardSidebarLink label='Visites filières' link='/admin/statistiques/visites-filieres' handleMenuItemOnClick={handleMenuItemOnClick}>
                      <ChartBarSquareIcon className='h-5 w-5' />
                    </DashboardSidebarLink>
                    <DashboardSidebarLink
                      label='Visites formations'
                      link='/admin/statistiques/visites-formations'
                      handleMenuItemOnClick={handleMenuItemOnClick}>
                      <ChartBarSquareIcon className='h-5 w-5' />
                    </DashboardSidebarLink>
                    <DashboardSidebarLink label='Visites metiers' link='/admin/statistiques/visites-metiers' handleMenuItemOnClick={handleMenuItemOnClick}>
                      <ChartBarSquareIcon className='h-5 w-5' />
                    </DashboardSidebarLink>
                    <DashboardSidebarLink label='Visites sites' link='/admin/statistiques/visites-sites' handleMenuItemOnClick={handleMenuItemOnClick}>
                      <ChartBarSquareIcon className='h-5 w-5' />
                    </DashboardSidebarLink>
                    <DashboardSidebarLink
                      label='Favoris de formation'
                      link='/admin/statistiques/favoris-formations'
                      handleMenuItemOnClick={handleMenuItemOnClick}>
                      <ChartBarSquareIcon className='h-5 w-5' />
                    </DashboardSidebarLink>
                    <DashboardSidebarLink
                      label='Souhaits de formation'
                      link='/admin/statistiques/souhaits-formations'
                      handleMenuItemOnClick={handleMenuItemOnClick}>
                      <ChartBarSquareIcon className='h-5 w-5' />
                    </DashboardSidebarLink>
                    <DashboardSidebarLink
                      label='Demandes de formation'
                      link='/admin/statistiques/demandes-formations'
                      handleMenuItemOnClick={handleMenuItemOnClick}>
                      <ChartBarSquareIcon className='h-5 w-5' />
                    </DashboardSidebarLink>
                    <DashboardSidebarLink
                      label="Chiffre d'affaire estimé"
                      link='/admin/statistiques/demandes-paiements'
                      handleMenuItemOnClick={handleMenuItemOnClick}>
                      <ChartBarSquareIcon className='h-5 w-5' />
                    </DashboardSidebarLink>
                  </List>
                </AccordionBody>
              </Accordion>
              <Accordion
                open={open === 6}
                icon={<ChevronDownIcon strokeWidth={2.5} className={`mx-auto h-4 w-4 transition-transform ${open === 6 ? 'rotate-180' : ''}`} />}>
                <DashboardSidebarCategory
                  icon={<Cog6ToothIcon className='h-5 w-5' />}
                  text='Configuration'
                  open={open}
                  index={6}
                  handleClick={() => handleOpen(6)}
                />
                <AccordionBody className='py-1 pl-4'>
                  <DashboardSidebarLink label='Paramètres de délais' link='/admin/paramdelais' handleMenuItemOnClick={handleMenuItemOnClick}>
                    <ClockIcon className='h-5 w-5' />
                  </DashboardSidebarLink>
                </AccordionBody>
              </Accordion>
            </Fragment>
          )}

          {user !== null && user.roles.includes(RoleEnum.dev) && (
            <Fragment>
              <Accordion
                open={open === 7}
                icon={<ChevronDownIcon strokeWidth={2.5} className={`mx-auto h-4 w-4 transition-transform ${open === 7 ? 'rotate-180' : ''}`} />}>
                <DashboardSidebarCategory
                  icon={<PresentationChartBarIcon className='h-5 w-5' />}
                  text='Paramètres avancés'
                  open={open}
                  index={7}
                  handleClick={() => handleOpen(7)}
                />
                <AccordionBody className='py-1 pl-4'>
                  <h4 className='pl-5 text-sm poppins-semibold text-primary pt-3 pb-1'>Général</h4>
                  <div className='p-0 pl-4'>
                    <DashboardSidebarLink label='Rôles' link='/admin/roles' handleMenuItemOnClick={handleMenuItemOnClick}>
                      <HandRaisedIcon className='h-5 w-5' />
                    </DashboardSidebarLink>
                    <DashboardSidebarLink label="Statuts d'affiliation" link='/admin/statuts-affiliation' handleMenuItemOnClick={handleMenuItemOnClick}>
                      <DocumentTextIcon className='h-5 w-5' />
                    </DashboardSidebarLink>
                    <DashboardSidebarLink label='Types profils' link='/admin/types-profils' handleMenuItemOnClick={handleMenuItemOnClick}>
                      <SwatchIcon className='h-5 w-5' />
                    </DashboardSidebarLink>
                    <DashboardSidebarLink label='Export des données' link='/admin/export' handleMenuItemOnClick={handleMenuItemOnClick}>
                      <ArrowUpTrayIcon className='h-5 w-5' />
                    </DashboardSidebarLink>
                    <DashboardSidebarLink label='Logs' link='/admin/logs' handleMenuItemOnClick={handleMenuItemOnClick}>
                      <ChartBarSquareIcon className='h-5 w-5' />
                    </DashboardSidebarLink>
                  </div>
                  <h4 className='pl-5 text-sm poppins-semibold text-primary pt-3 pb-1'>YParéo</h4>
                  <div className='p-0 pl-4'>
                    <DashboardSidebarLink label='Logs synchro' link='/admin/ypareo/logs-synchronisation' handleMenuItemOnClick={handleMenuItemOnClick}>
                      <ChartBarSquareIcon className='h-5 w-5' />
                    </DashboardSidebarLink>
                  </div>
                </AccordionBody>
              </Accordion>
            </Fragment>
          )}

          {user !== null && user.roles.includes(RoleEnum.gestionnaireCentre) && (
            <Fragment>
              <DashboardSidebarLink label='Demandes' link='/admingc/demandes' handleMenuItemOnClick={handleMenuItemOnClick}>
                <ChatBubbleLeftEllipsisIcon className='h-5 w-5' />
              </DashboardSidebarLink>
              <DashboardSidebarLink label='Formations' link='/admingc/formations' handleMenuItemOnClick={handleMenuItemOnClick}>
                <PresentationChartBarIcon className='h-5 w-5' />
              </DashboardSidebarLink>

              <Accordion
                open={open === 1}
                icon={<ChevronDownIcon strokeWidth={2.5} className={`mx-auto h-4 w-4 transition-transform ${open === 1 ? 'rotate-180' : ''}`} />}>
                <DashboardSidebarCategory
                  icon={<PresentationChartBarIcon className='h-5 w-5' />}
                  text='Statistiques'
                  open={open}
                  index={1}
                  handleClick={() => handleOpen(1)}
                />
                <AccordionBody className='py-1 pl-4'>
                  <List className='p-0'>
                    <DashboardSidebarLink label='Recherches' link='/admingc/statistiques/recherches' handleMenuItemOnClick={handleMenuItemOnClick}>
                      <ChartBarSquareIcon className='h-5 w-5' />
                    </DashboardSidebarLink>
                    <DashboardSidebarLink label='Visites filières' link='/admingc/statistiques/visites-filieres' handleMenuItemOnClick={handleMenuItemOnClick}>
                      <ChartBarSquareIcon className='h-5 w-5' />
                    </DashboardSidebarLink>
                    <DashboardSidebarLink
                      label='Visites formations'
                      link='/admingc/statistiques/visites-formations'
                      handleMenuItemOnClick={handleMenuItemOnClick}>
                      <ChartBarSquareIcon className='h-5 w-5' />
                    </DashboardSidebarLink>
                    <DashboardSidebarLink label='Visites metiers' link='/admingc/statistiques/visites-metiers' handleMenuItemOnClick={handleMenuItemOnClick}>
                      <ChartBarSquareIcon className='h-5 w-5' />
                    </DashboardSidebarLink>
                    <DashboardSidebarLink label='Visites sites' link='/admingc/statistiques/visites-sites' handleMenuItemOnClick={handleMenuItemOnClick}>
                      <ChartBarSquareIcon className='h-5 w-5' />
                    </DashboardSidebarLink>
                    <DashboardSidebarLink
                      label='Souhaits de formation'
                      link='/admingc/statistiques/souhaits-formations'
                      handleMenuItemOnClick={handleMenuItemOnClick}>
                      <ChartBarSquareIcon className='h-5 w-5' />
                    </DashboardSidebarLink>
                    <DashboardSidebarLink
                      label='Demandes de formation'
                      link='/admingc/statistiques/demandes-formations'
                      handleMenuItemOnClick={handleMenuItemOnClick}>
                      <ChartBarSquareIcon className='h-5 w-5' />
                    </DashboardSidebarLink>
                    <DashboardSidebarLink
                      label="Chiffre d'affaire estimé"
                      link='/admingc/statistiques/demandes-paiements'
                      handleMenuItemOnClick={handleMenuItemOnClick}>
                      <ChartBarSquareIcon className='h-5 w-5' />
                    </DashboardSidebarLink>
                  </List>
                </AccordionBody>
              </Accordion>
            </Fragment>
          )}
          <div className='relative w-full mb-3'>
            <div className='text-gray-500 absolute ml-3 right-[14px] top-[8px] m-auto w-4 h-4'>
              <MagnifyingGlassIcon className='h-5 w-5' />
            </div>
            <input
              className='border border-gray-300 focus:outline-none focus:border-primary rounded-md w-full text-sm text-gray-500 pl-4 pr-10 py-2'
              type='search'
              placeholder='Chercher une formation'
              value={recherche}
              onChange={(e) => setRecherche(e.target.value)}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  handleSearch();
                }
              }}
            />
          </div>
        </div>

        <div className='py-4 px-4 text-gray-200'>
          <Link to='/conditions-generales' className='block text-xs hover:text-primary poppins'>
            Conditions générales
          </Link>
          <Link to='/mentions-legales' className='block text-xs pt-2 hover:text-primary poppins'>
            Mentions légales
          </Link>
          <p className='text-xs pt-3 poppins'>©{new Date().getFullYear()} Compagnons du Devoir et du Tour De France. Tous droits réservés.</p>
        </div>
      </div>
    </div>
  );
};

import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import toast from 'react-hot-toast';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { BanniereVitrine } from '../components/BanniereVitrine';
import { Container } from '../components/Container';
import { FilDariane } from '../components/FilDariane';
import { LoadingAbsolute } from '../components/LoadingAbsolute';
import { MainMenu } from '../components/MainMenu';
import { SearchResultCard } from '../components/SearchResultCard';
import { Config } from '../config/config';
import { useApi } from '../providers/ApiProvider';
import { GoodApiResponse, ErrorApiResponse } from '../types/api';
import { Formation, Metier } from '../types/visitorRessources';
import nl2br from 'react-nl2br';
import { ArrowRightIcon, ClipboardDocumentListIcon } from '@heroicons/react/24/outline';
import { TypeProfilEnum } from '../types/auth';
import { useUser } from '../providers/UserProvider';
import { handleError } from '../utils/ErrorHandler';

/**
 * Affiche la fiche métier.
 * Permet d'ajouter des formations en favoris.
 */
export const FicheMetierScreen: FunctionComponent = () => {
  /**
   * On a besoin des informations de l'utilisateur et du profil courant de celui-ci.
   */
  const { user, profil } = useUser();

  /**
   * On a besoin des paramètres pour avoir le slug.
   */
  const params = useParams();

  /**
   * On stocke si on est en train d'utiliser l'API ou non.
   */
  const [apiIsLoading, setApiIsLoading] = useState(false);

  /**
   * État local qui stocke le métier.
   */
  const [metier, setMetier] = useState<Metier | null>(null);

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  /**
   * Permet au composant de changer de page.
   */
  const navigate = useNavigate();

  /**
   * Permet de recharger les données au chargement de page ou au changement de paramètres.
   */
  useEffect(() => {
    handleShow();
  }, [client, params]);

  /**
   * Cette méthode va chercher les données du métier désigné dans l'URL.
   */
  const handleShow = () => {
    setApiIsLoading(true);
    client
      .get<GoodApiResponse<Metier>>(`/metiers/${params.slug}`)
      .then((response) => {
        setMetier(response.data.data);
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Cette méthode permet d'ajouter une formation en favoris.
   *
   * @param formation - Formation à mettre en favoris.
   */
  const handleFavorite = async (formation: Formation) => {
    if (user === null || !user.roles.includes('standard')) {
      navigate('/connexion');
      return;
    }

    const url = `/${
      profil !== null && (profil.type_profil === TypeProfilEnum.dirigeant || profil.type_profil === TypeProfilEnum.delegue) ? 'entreprise' : 'salarie'
    }/favoris/${formation.slug}`;

    try {
      if (formation.est_favori) {
        await client.delete(url);
        setMetier({
          ...(metier as Metier),
          formations: [
            ...(metier?.formations as Formation[]).map((format) => {
              if (format.slug === formation.slug) {
                return { ...format, est_favori: false }; // mettre à jour l'âge de l'objet avec id = 2
              }
              return format;
            }),
          ],
        });
        toast.success(`Formation retirée des favoris`);
      } else {
        await client.post(url);
        setMetier({
          ...(metier as Metier),
          formations: [
            ...(metier?.formations as Formation[]).map((format) => {
              if (format.slug === formation.slug) {
                return { ...format, est_favori: true }; // mettre à jour l'âge de l'objet avec id = 2
              }
              return format;
            }),
          ],
        });
        toast.success(`Formation ajoutée aux favoris`);
      }
    } catch (error: any) {
      handleError(error);
    }
  };

  if (metier === null) {
    return <Fragment>Chargement...{apiIsLoading ? <LoadingAbsolute /> : ''}</Fragment>;
  }

  return (
    <Fragment>
      <Helmet>
        <title>Fiche métier - {Config.app_label}</title>
      </Helmet>
      <BanniereVitrine>
        <div className='absolute top-0 right-0 left-0 bottom-0 w-full h-full bg-secondary-2/70'></div>
        <div className='relative flex flex-col items-center h-full mx-auto'>
          <MainMenu className='relative pb-20' />
        </div>
        <div className='lg:max-w-xl xl:max-w-7xl w-full relative h-full 2xl:px-8 mt-20 lg:mt-[120px] text-center md:text-left mr-auto xl:mx-auto'>
          <h1 className='text-white text-3xl w-full uppercase poppins-semibold xl:pr-96'>{metier.nom}</h1>
          <FilDariane
            className='bg-transparent w-full text-white pl-0 !mb-2 mt-2 xl:pr-96'
            items={[
              { label: metier.filieres[0]?.nom, link: `/filieres/${metier.filieres[0]?.slug}` },
              { label: metier.nom, link: `/metiers/${metier.slug}` },
            ]}
          />
        </div>
      </BanniereVitrine>
      <Container className='pb-11 bg-gray-100 h-full'>
        <div className='md:flex md:flex-row w-full'>
          <div className='basis-1/1 md:basis-3/5 lg:basis-2/3 h-full'>
            <div className='md:mr-4 mb-11'>
              <p className='text-gray-700 poppins'>{nl2br(metier.description)}</p>
              <h2 className='text-2xl text-secondary-1 text-center md:text-left pt-11 poppins-semibold'>Découvrez les formations liées à ce métier&nbsp;:</h2>
              <div className='w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-4 mt-11'>
                <SearchResultCard formations={metier.formations} handleFavorite={handleFavorite} />
              </div>
            </div>
          </div>
          <div className='relative basis-1/1 md:basis-2/5 lg:basis-1/3 md:pl-11 md:-mt-11'>
            <div className='bg-white rounded-lg shadow-md py-7 px-7 mb-7 lg:ml-4'>
              <div className='h-44 my-7 overflow-hidden shadow-md rounded-lg'>
                {metier.image_couverture ? (
                  <img alt={metier.nom} src={metier.image_couverture as string} tabIndex={0} className='object-cover w-full h-full' loading='lazy' />
                ) : (
                  <div className={`rounded-lg shadow-md object-cover w-full h-full bg-gray-700 flex items-center justify-center`}>
                    <ClipboardDocumentListIcon className='h-11 w-11 text-white/50' />
                  </div>
                )}
              </div>
              <p className='my-7 text-sm poppins'>
                Pour consulter les sessions disponibles dans le centre de votre choix, veuillez effectuer une recherche en cliquant sur le bouton ci-dessous.
              </p>
              <Link
                to='/'
                className='flex flex-row items-center text-center text-white py-1 px-5 bg-primary hover:bg-secondary-1 rounded-full shadow-primary/50 hover:shadow-secondary-1/50 uppercase text-sm poppins-semibold'>
                Rechercher une formation
                <ArrowRightIcon className=' pl-3 w-9 h-9' />
              </Link>
            </div>
          </div>
        </div>
      </Container>
    </Fragment>
  );
};

import { Fragment, FunctionComponent, useState } from 'react';
import { useUser } from '../providers/UserProvider';
import { ConstTypeProfil } from './ConstTypeProfil';
import { Avatar } from './Avatar';

import { RoleEnum, StatutAffiliationEnum } from '../types/auth';

import { ConstRoles } from './ConstRoles';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { Profil } from '../types/auth';
import { ChevronRightIcon, PlusIcon } from '@heroicons/react/20/solid';

/**
 * Ce composant affiche l'en-tête de page de la partie Back-Office.
 */
export const ProfileSelectorMini: FunctionComponent<{
  setAjoutVisible?: React.Dispatch<React.SetStateAction<boolean>> | null | undefined;
}> = ({ setAjoutVisible }) => {
  /**
   * On a besoin des informations de l'utilisateur et du profil courant de celui-ci.
   */
  /**
   * On a besoin du profil courant de l'utilisateur et de la possibilité de modifier ce profil.
   */
  const { user, profil: currentProfil, changeProfil: setCurrentProfil } = useUser();

  /**
   * @constant Stocke les profils révoqués.
   */
  //const profilsRevoques = profils.filter((profil) => profil.statut_affiliation == StatutAffiliationEnum.revoque);

  /**
   * Cette méthode change le profil courant
   *
   * @param profil - Profil sur lequel on clique.
   */
  const handleProfilClick = (profil: Profil) => {
    setCurrentProfil(profil);
    setSelecteurProfilDeplie(false);
  };

  const [selecteurProfilDeplie, setSelecteurProfilDeplie] = useState<boolean>(false);

  const toggleSelecteurProfil = () => {
    if (selecteurProfilDeplie === true) {
      setSelecteurProfilDeplie(false);
    } else {
      setSelecteurProfilDeplie(true);
    }
  };

  return (
    <Fragment>
      {user && (
        <div className='bg-white w-16 h-16 aspect-1 rounded-md border-2 shadow border-white'>
          <Avatar className='w-full h-full overflow-hidden object-cover rounded-md' src={user.image_avatar} />
        </div>
      )}
      {user !== null && (
        <div className='flex flex-col grow text-left'>
          <h2 className='text-xl text-secondary-1 poppins-medium'>
            {user.prenom} {user.nom}
          </h2>
          <div className='inline-block mt-2'>
            <Fragment>
              {(user.roles.includes(RoleEnum.admin) || user.roles.includes(RoleEnum.gestionnaireCentre) || user.roles.includes(RoleEnum.dev)) && (
                <div className='rounded-md bg-primary p-5 text-white px-3 py-1 normal-case font-normal md:truncate text-sm cursor-default'>
                  {user.roles.map((role) => (
                    <span key={role} className='px-2 first:pl-0 last:pr-0 border-r last:border-none'>
                      <ConstRoles text={role as RoleEnum} />
                    </span>
                  ))}
                </div>
              )}
              {user.roles.includes(RoleEnum.standard) && currentProfil && (
                <div className='relative z-[60]'>
                  <div
                    onClick={() => {
                      toggleSelecteurProfil();
                    }}
                    className={`md:truncate flex flex-row gap-3 items-center justify-between text-left text-sm bg-secondary-1 hover:shadow-secondary-1/20 text-white px-3 py-1 normal-case font-normal cursor-pointer ${
                      selecteurProfilDeplie ? 'rounded-t-md' : 'rounded-md'
                    }`}>
                    {currentProfil.statut_affiliation === StatutAffiliationEnum.affilie &&
                      `${
                        currentProfil.entreprise.enseigne
                          ? currentProfil.entreprise.enseigne
                          : currentProfil.entreprise.raison_sociale
                            ? currentProfil.entreprise.raison_sociale
                            : ' dans aucune entreprise'
                      } - `}
                    <ConstTypeProfil text={currentProfil.type_profil} />
                    <div className='w-3 h-3'>
                      {selecteurProfilDeplie ? <ChevronUpIcon className='h-3 w-3' strokeWidth={3} /> : <ChevronDownIcon className='h-3 w-3' strokeWidth={3} />}
                    </div>
                  </div>
                  <div className={`absolute rounded-b-md shadow-xl px-2 pb-2 top-full w-full bg-secondary-1 ${selecteurProfilDeplie ? '' : 'hidden'}`}>
                    {(user.profils as Profil[]).map((profil) =>
                      profil.statut_affiliation !== StatutAffiliationEnum.revoque && profil.id !== currentProfil.id ? (
                        <div
                          key={profil.id}
                          className='bg-white hover:bg-primary pl-2 py-1  text-secondary-2 hover:text-white text-sm flex flex-row gap-2 items-center cursor-pointer rounded mb-1'
                          onClick={() => {
                            handleProfilClick(profil);
                          }}>
                          <div className='truncate grow'>
                            {profil.statut_affiliation == StatutAffiliationEnum.affilie
                              ? profil.entreprise.enseigne
                                ? profil.entreprise.enseigne
                                : profil.entreprise.raison_sociale
                              : "Pas d'entreprise"}
                          </div>
                          <div className='text-xs'>
                            <ConstTypeProfil text={profil.type_profil} />
                          </div>
                          <div className='flex-none'>
                            <ChevronRightIcon className='h-5 w-5' strokeWidth={3} />
                          </div>
                        </div>
                      ) : (
                        ''
                      ),
                    )}
                    {setAjoutVisible && (
                      <Fragment>
                        <div className='border-t border-t-white/20 my-2'></div>
                        <div
                          className='bg-white hover:bg-primary group pl-2 py-1  text-secondary-2 hover:text-white text-sm flex flex-row gap-2 items-center cursor-pointer rounded'
                          onClick={() => {
                            setAjoutVisible(true);
                            setSelecteurProfilDeplie(false);
                          }}>
                          <div className='grow'>Ajouter une entreprise</div>
                          <div className='flex-none text-primary group-hover:text-white'>
                            <PlusIcon className='h-5 w-5' strokeWidth={3} />
                          </div>
                        </div>
                      </Fragment>
                    )}
                  </div>
                </div>
              )}
            </Fragment>
          </div>
        </div>
      )}
    </Fragment>
  );
};

import { Fragment, FunctionComponent, useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { Navigate, useNavigate } from 'react-router-dom';
import { useApi } from '../../providers/ApiProvider';
import { useUser } from '../../providers/UserProvider';
import { ToastInfo } from '../../components/toasts/ToastInfo';

/**
 * Ce composant sert à désauthentifier un utilisateur.
 */
export const LogoutScreen: FunctionComponent = () => {
  /**
   * On a besoin des informations de l'utilisateur et du profil courant de celui-ci ainsi que du moyen de modifier ces informations.
   */
  const { user, changeUser, profil, changeProfil: setCurrentProfil } = useUser();

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  /**
   * Permet au composant de changer de page.
   */
  const navigate = useNavigate();

  /**
   * Au chargement du composant on réalise plusieurs étapes pour désauthentifier l'utilisateur.
   */
  useEffect(() => {
    client.post('/logout'); // On dit à l'API de se déconnecter mais on ne veut pas que se soit bloquant
    client.resetProfil(); // On supprime l'en-tête `X-Profil` de l'instance de la classe `Api`
    if (profil !== null) {
      setCurrentProfil(null); // On supprime également le profil courant du contexte `UserProvider`
    }
    changeUser(null); // On supprime également l'utilisateur authentifié du contexte `UserProvider`
    localStorage.clear(); // On supprime toute les clé du localStorage lié à l'application
    toast.custom(<ToastInfo>Vous êtes maintenant déconnecté.</ToastInfo>);
    navigate('/'); // On renvoie l'utilisateur vers la page d'acceuil du Front-Office
  }, [client]);

  if (!user || !user.token) {
    return <Navigate to='/' />;
  }

  return <Fragment>Déconnexion en cours...</Fragment>;
};

import { ArrowRightIcon } from '@heroicons/react/24/outline';
import type { FunctionComponent } from 'react';

/**
 * Ce composant est une partie de la fiche formation.
 */
export const CtaHomeHandi: FunctionComponent = () => {
  return (
    <div className='px-11 isolate'>
      <div className='mx-auto max-w-7xl my-14 px-0 md:px-4 lg:px-8'>
        <div className='rounded-md bg-white p-4 shadow-md'>
          <h3 className='text-xl text-secondary-1 poppins-semibold tracking-tight'>Vous êtes en situation de handicap&nbsp;?</h3>
          <div className='mt-2 text-sm'>
            <p className='text-gray-600 mb-2 leading-loose'>
              Vous bénéficiez d'un accompagnement par un(e) référent(e) handicap pour la mise en oeuvre de votreprojet professionnel. Il/elle est à votre écoute
              et facilitera la relation avec les différents interlocuteurs pour favoriser la mise en place de votre projet de formation et d'insertion.
            </p>
            <a className='text-secondary-1 inline-flex items-center gap-2' href='https://compagnons-du-devoir.com/vous-etes-en-situation-de-handicap/'>
              <ArrowRightIcon className='w-5 h-5 flex-none' />
              <span className='montserrat-bold'>En savoir plus</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

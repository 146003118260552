import { Fragment, FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

/**
 * Ce composant est le breadcrumb de la partie front.
 *
 * @param className - stylise la partie principale.
 * @param items - Tableau d'objet qui contiennent à chaque fois un libellé et un lien.
 */
export const FilDariane: FunctionComponent<{
  className: string;
  items?: {
    label: string;
    link: string | null;
  }[];
}> = ({ className, items = [] }) => {
  return (
    <div className={className + ' text-center md:text-left mb-7 text-sm poppins'}>
      <Link to='/' className='transition-colors duration-300 text-white hover:text-primary opacity-60 hover:opacity-100 poppins'>
        Formations courtes
      </Link>
      {items.map((item, index) => (
        <Fragment key={index}>
          <span>&nbsp;/&nbsp;</span>
          {item.link === null ? (
            <span className='text-white opacity-60'>{item.label}</span>
          ) : (
            <Link
              to={item.link as string}
              className='text-left transition-colors duration-300 text-white hover:text-primary opacity-60 hover:opacity-100 poppins'>
              {item.label}
            </Link>
          )}
        </Fragment>
      ))}
    </div>
  );
};

import { Fragment, FunctionComponent } from 'react';

/**
 * Ce composant est un encart du tableau de bord administrateur concernant les recherches.
 *
 * @param recherches - Liste des recherches éffectuées.
 */
export const DashboardAdminRecherches: FunctionComponent<{
  recherches: {
    mot: string;
    compte: number;
  }[];
}> = ({ recherches }) => {
  return (
    <Fragment>
      {recherches.map((recherche) => (
        <div key={recherche.mot} className='text-secondary-1 flex flex-row items-center justify-between gap-2 border-b last:border-none py-2'>
          <h6 className='montserrat-semibold'>{recherche.mot}</h6>
          <span className='text-sm'>
            {recherche.compte} recherche{recherche.compte > 1 ? 's' : ''}
          </span>
        </div>
      ))}
    </Fragment>
  );
};

import { ArrowRightIcon } from '@heroicons/react/20/solid';
import { Dialog, DialogHeader, DialogBody, DialogFooter, Button } from '@material-tailwind/react';
import { FunctionComponent } from 'react';

/**
 * Ce composant permet d'afficher une Modale pour accepter un devis, côté dirigeant.
 *
 * @param visible - Précise si la modale et ouverte ou non. Provient du parent.
 * @param setVisible - Accesseur qui modifie la visibilité de la modale. Provient du parent.
 * @param handleAccepte - Callback qui réalise l'appel pour accepter le devis.
 */
export const ModalAcceptationDevisEntreprise: FunctionComponent<{
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  handleAccepte: () => void;
}> = ({ visible, setVisible, handleAccepte }) => {
  return (
    <Dialog open={visible} size='xs' handler={setVisible}>
      <DialogHeader>Acceptation du devis</DialogHeader>
      <DialogBody divider>
        <div className='text-secondary-1 flex items-start mb-3'>
          <ArrowRightIcon className='flex-none w-5 h-5 mr-3' strokeWidth={2} />
          <div className='grow text-sm'>En cliquant sur le bouton confirmer, vous allez nous indiquer que vous avez accepté le devis.</div>
        </div>
        <div className='text-secondary-1 flex items-start mb-3'>
          <ArrowRightIcon className='flex-none w-5 h-5 mr-3' strokeWidth={2} />
          <div className='grow text-sm'>Vous disposerez alors d'un délai de rétractation afin de vous laisser la possibilité de changer de décision.</div>
        </div>
        <div className='text-secondary-1 flex items-start mb-3'>
          <ArrowRightIcon className='flex-none w-5 h-5 mr-3' strokeWidth={2} />
          <div className='grow text-sm'>
            La date maximale de rétractation sera consultable à tout moment dans votre liste de devis et dans le détail de ceux-ci.
          </div>
        </div>
      </DialogBody>
      <DialogFooter>
        <Button variant='text' color='gray' onClick={() => setVisible(false)} className='mr-1 !rounded-full'>
          Annuler
        </Button>
        <Button
          variant='filled'
          color='orange'
          onClick={() => {
            handleAccepte();
            setVisible(false);
          }}>
          Confirmer
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

import { Fragment, FunctionComponent, useState } from 'react';
import { Input } from '@material-tailwind/react';
import { Navigate, useParams } from 'react-router-dom';
import toast, { Renderable, Toast, ValueFunction } from 'react-hot-toast';
import { Helmet } from 'react-helmet';
import { Config } from '../../config/config';

import { LoadingAbsolute } from '../../components/LoadingAbsolute';
import { PasswordStrength } from '../../components/PasswordStrength';
import { PasswordHints } from '../../components/PasswordHints';
import { useApi } from '../../providers/ApiProvider';
import { useUser } from '../../providers/UserProvider';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/20/solid';

/**
 * Ce composant affiche la page de mot de passe réinitialisé
 */
export const MotdepasseReinitialiseScreen: FunctionComponent = () => {
  /**
   * On stocke si on est en train d'utiliser l'API ou non.
   */
  const [apiIsLoading, setApiIsLoading] = useState(false);

  /**
   * État local pour gérer le champ `Mot de passe` du formulaire de mot de passe oublé
   */
  const [password, setPassword] = useState({ value: '', error: false });

  /**
   * Cet état local determine si on a déjà réinitialisé le mot de passe.
   */
  const [passwordIsReset, setPasswordIsReset] = useState(false);

  /**
   * Gère l'appui sur un bouton pour voir le mot de passe.
   */
  const [showedPassword, setShowedPassword] = useState<boolean>(false);

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  /**
   * On a besoin des informations de l'utilisateur.
   */
  const { user } = useUser();

  /**
   * On a besoin des paramètres pour avoir l'e-mail et le jeton.
   */
  const params = useParams();

  /**
   * Cette méthode gère le changement de valeur du champ `Mot de passe`
   *
   * @param event - Évènement de changement de valeur.
   */
  const handlePasswordChange = (event: { target: { value: string } }) => {
    setPassword({ value: event.target.value, error: false });
  };

  /**
   * Cette méthode effectue l'appel API pour réinitialiser le mot de passe.
   *
   * @param event - Évènement d'envoi du formulaire.
   */
  const handleResetPassword = (event: { preventDefault: () => void }) => {
    event.preventDefault();

    if (apiIsLoading === true) {
      return;
    }

    setApiIsLoading(true);

    // Appel effectif
    client
      .post('/reinitialise-motdepasse', {
        email: params.email,
        token: params.token,
        password: password.value,
      })
      .then(() => {
        setApiIsLoading(false);
        setPasswordIsReset(true);
        toast.success('Mot de passe réinitialisé. Vous pouvez maintenant vous connecter');
      })
      .catch(
        (error: {
          response: {
            status: number;
            data: { message: Renderable | ValueFunction<Renderable, Toast> };
          };
          message: Renderable | ValueFunction<Renderable, Toast>;
        }) => {
          setApiIsLoading(false);
          if (error.response && [400, 401, 422].includes(error.response.status) && error.response.data.message) {
            setPassword({ value: password.value, error: true });
            toast.error(error.response.data.message);
          } else {
            toast.error(error.message);
          }
        },
      );
  };

  // Vue
  if (user && user.token) {
    return <Navigate to='/mon-espace' />;
  }

  if (passwordIsReset === true) {
    return <Navigate to='/connexion' />;
  }

  return (
    <Fragment>
      <Helmet>
        <title>Réinitialiser mon mot de passe - {Config.app_label}</title>
      </Helmet>

      <form className='p-6 bg-[#F7FBFE] w-full rounded-md shadow-md relative' onSubmit={handleResetPassword}>
        <h2 className='text-3xl text-center mb-1 space-x-3 poppins-bold text-secondary-2'>
          Créez votre nouveau
          <br />
          mot de passe
        </h2>
        <div className='mt-6'>
          <Input
            //className='block border border-gray-200 rounded px-5 py-3 leading-6 w-full focus:border-primary focus:ring focus:ring-primary focus:ring-opacity-50'
            type={showedPassword ? 'text' : 'password'}
            label='Nouveau mot de passe'
            onChange={handlePasswordChange}
            error={password.error}
            icon={
              <div onClick={() => setShowedPassword(!showedPassword)}>
                {showedPassword ? <EyeSlashIcon className='w-5 h-5' /> : <EyeIcon className='w-5 h-5' />}
              </div>
            }
          />
        </div>
        <div className='mt-1'>
          <PasswordStrength password={password.value} />
        </div>
        <div className='mt-3'>
          <PasswordHints password={password.value} />
        </div>
        <div className='mt-8'>
          <button type='submit' className='poppins-semibold w-full px-2 py-1 rounded-md bg-primary text-white  disabled:bg-primary/50 uppercase'>
            Valider
          </button>
        </div>
        {apiIsLoading ? <LoadingAbsolute /> : ''}
      </form>
    </Fragment>
  );
};

import { FunctionComponent, useEffect, useState } from 'react';
import { ProgressBar } from './ProgressBar';

/**
 * Ce composant teste si le mot de passe a le schéma que l'on souhaite et affiche une barre de progression en fonction de la force du mot de passe.
 *
 * @param password - Mot de passe.
 */
export const PasswordStrength: FunctionComponent<{ password: string }> = ({ password }) => {
  /**
   * État local pour gérer l'affichage de la barre de progression de force.
   */
  const [passwordStrength, setPasswordStrength] = useState({
    value: 0,
    color: 'red',
    text: '',
  });

  /**
   * On effectue les tests à chaque changement de mot de passe et on met a jour l'affichage du mot de passe.
   */
  useEffect(() => {
    /**
     * @var score
     * @defaultValue `0`
     */
    let score = 0;

    /**
     * @var color
     * @defaultValue `''`
     */
    let color = '';

    /**
     * @var text
     * @defaultValue `''`
     */
    let text = '';

    // Validation
    if (password.length >= 6) {
      score += 25;
      if (/[A-Z]/.test(password)) {
        score += 25;
      }
      if (/[0-9]/.test(password)) {
        score += 25;
      }
    }
    if (password.length >= 8) {
      score += 25;
    }
    if (password.length >= 12) {
      score += 25;
    }

    // Par sécurité on limite à 100% max
    if (score > 100) {
      score = 100;
    }

    // Couleur
    if (score < 25) {
      color = 'red';
      text = 'Trop court';
    } else if (score <= 25) {
      color = 'red';
      text = 'Très faible';
    } else if (score <= 50) {
      color = 'amber';
      text = 'Faible';
    } else if (score <= 75) {
      color = 'indigo';
      text = 'Bon';
    } else {
      color = 'green';
      text = 'Excellent';
    }

    setPasswordStrength({ value: score, color: color, text: text });
  }, [password]);

  return (
    <div>
      <ProgressBar value={passwordStrength.value} color={passwordStrength.color} />
      <p className='text-right text-gray-500 text-xs mb-2'>{passwordStrength.text}</p>
    </div>
  );
};

import { FunctionComponent } from 'react';
import { Logo } from './Logo';

export const EntrepriseDisplay: FunctionComponent<{
  entreprise?: any | null;
}> = ({ entreprise = null }) => {
  if (entreprise) {
    return (
      <div className='flex flex-row gap-2 items-center text-xs'>
        <div className='h-10 w-10 object-cover aspect-1 flex-none'>
          <Logo className='rounded h-10 w-10 object-cover aspect-1' src={entreprise.image_logo} />
        </div>
        <div className='montserrat-semibold text-secondary-1'>{`${
          entreprise.enseigne ? entreprise.enseigne : entreprise.raison_sociale ? entreprise.raison_sociale : 'Non renseigné'
        }`}</div>
      </div>
    );
  } else {
    return <div></div>;
  }
};

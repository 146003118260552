import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { Button, Menu, MenuHandler, MenuItem, MenuList } from '@material-tailwind/react';
import { useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { InputTrilean } from '../../../components/Inputs/InputTrilean';
import { LoadingAbsolute } from '../../../components/LoadingAbsolute';
import { useApi } from '../../../providers/ApiProvider';
import { AxiosResponse } from 'axios';
import { ConstStatutAffiliation } from '../../../components/ConstStatutAffiliation';
import { ConstTypeProfil } from '../../../components/ConstTypeProfil';
import { HeaderForm } from '../../../components/HeaderForm';
import { ModeFormulaire, DropdownRessource, Ressource } from '../../../types';
import { BaseIndexParams, PaginatedApiResponse, GoodApiResponse, ErrorApiResponse } from '../../../types/api';
import { ProfilEntreprise, StatutAffiliationEnum, TypeProfilEnum } from '../../../types/auth';
import {
  Entreprise as EntrepriseDb,
  FormeJuridique,
  TypeEmployeur,
  ConventionCollective,
  Octa,
  StatutAffiliation,
  TypeProfil,
  Devis,
  Convocation,
  Facture,
} from '../../../types/schematics';
import { Config } from '../../../config/config';
import { Helmet } from 'react-helmet';
import { InputImage } from '../../../components/Inputs/InputImage';
import { ReadOnlyNumber } from '../../../components/Inputs/ReadOnlyNumber';
import { Avatar } from '../../../components/Avatar';
import { ModalDocumentsSalaries } from '../../../components/Modales/Admin/ModalDocumentsSalaries';
import { BookOpenIcon, CheckIcon, EllipsisVerticalIcon, ExclamationCircleIcon, ExclamationTriangleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { ModalUtilisateurs } from '../../../components/Modales/Admin/ModalUtilisateurs';
import { ModalAjouterDocumentEntreprise } from '../../../components/Modales/Admin/ModalAjouterDocumentEntreprise';
import { Demande, Entreprise } from '../../../types/AdminRessources';
import { ModalModificationYpareoCodeEntreprise } from '../../../components/Modales/Admin/ModalModificationYpareoCodeEntreprise';
import { handleError } from '../../../utils/ErrorHandler';
import { dateEstCompriseDansIntervalle, ajouterJour } from '../../../utils/Temps';
import { ModalAjouterDevisEntreprise } from '../../../components/Modales/Admin/ModalAjouterDevisEntreprise';
import nl2br from 'react-nl2br';
import { ModalAjouterFactureEntreprise } from '../../../components/Modales/Admin/ModalAjouterFactureEntreprise';
import { ModalAjouterConvocationSalariesAdmin } from '../../../components/Modales/Admin/ModalAjouterConvocationSalariesAdmin';
import { ModalConvocationsSalariesAdmin } from '../../../components/Modales/Admin/ModalConvocationsSalariesAdmin';
import { ModalSuppressionDevis } from '../../../components/Modales/Admin/ModalSuppressionDevis';
import { ModalSuppressionFacture } from '../../../components/Modales/Admin/ModalSuppressionFacture';
import { ModalSuppressionConvocation } from '../../../components/Modales/Admin/ModalSuppressionConvocation';
import { ModalAjouterConvocationEntreprise } from '../../../components/Modales/Admin/ModalAjouterConvocationEntreprise';
import { Tab, TabPanel, Tabs, TabsBody, TabsHeader } from '../../../components/Onglets';
import { ImageHints } from '../../../components/ImageHints';
import { ReadOnlyText } from '../../../components/Inputs/ReadOnlyText';
import { ReadOnlySelect } from '../../../components/Inputs/ReadOnlySelect';
import { ReadOnlyDate } from '../../../components/Inputs/ReadOnlyDate';
import { ReadOnlyBoolean } from '../../../components/Inputs/ReadOnlyBoolean';
import { SubmitSave } from '../../../components/SubmitSave';
import { AlertInfo } from '../../../components/Alertes';
import { UserDisplay } from '../../../components/UserDisplay';
import { FormationSessionDisplay } from '../../../components/FormationSessionDisplay';
import { DownloadLink } from '../../../components/DownloadLink';

type Props = { modeFormulaire: ModeFormulaire };

/**
 * Ce composant doit afficher un formulaire qui à 3 modes de fonctionnement. Les 2 principaux sont la création et l'édition.
 * On a également un mode lecture seule. L'idée c'est de gérer une partie du CRUD.
 * C'est dans ce composant que vont se faire les appels API pour créer, éditer et voir une ressource en particulier.
 *
 * @param modeFormulaire - Mode du formulaire (creer, editer et voir).
 * @returns JSX du composant
 */
export const AdminEntrepriseFormScreen: FunctionComponent<Props> = ({ modeFormulaire }) => {
  // On initialise les données dont on va avoir besoin sur cette page

  /**
   * On stocke si on est en train d'utiliser l'API ou non.
   */
  const [apiIsLoading, setApiIsLoading] = useState(false);

  /**
   * On stocke l'état des formes juridiques.
   */
  const [formeJuridiques, setFormeJuridiques] = useState<DropdownRessource[] | null>(null);

  /**
   * On stocke l'état des types d'employeurs.
   */
  const [typeEmployeurs, setTypeEmployeurs] = useState<DropdownRessource[] | null>(null);

  /**
   * On stocke l'état des convention collectives.
   */
  const [conventionCollectives, setConventionCollectives] = useState<DropdownRessource[] | null>(null);

  /**
   * On stocke l'état des octas.
   */
  const [octas, setOctas] = useState<DropdownRessource[] | null>(null);

  /**
   * On stocke les profils possédés par l'entreprise, les salariés.
   */
  const [ownedProfils, setOwnedProfils] = useState<ProfilEntreprise[] | null>(null);

  /**
   * On stocke les types de profils.
   */
  const [typeProfils, setTypeProfils] = useState<DropdownRessource[] | null>(null);

  /**
   * On stocke les statut d'affiliation.
   */
  const [statutAffiliations, setStatutAffiliations] = useState<DropdownRessource[] | null>(null);

  /**
   * On stocke les statut d'affiliation.
   */
  const [demandes, setDemandes] = useState<Demande[] | null>(null);

  /**
   * On stocke le profil sélectionné.
   */
  const [profilSelectionne, setProfilSelectionne] = useState<ProfilEntreprise | null>(null);

  /**
   * Permet d'ouvrir une modale lorsque l'on souhaite afficher les convocations d'un salariés.
   */
  const [modalConvocationsEstVisible, setModalConvocationsEstVisible] = useState<boolean>(false);

  /**
   * Permet d'ouvrir une modale lorsque l'on souhaite ajouter une convocation.
   */
  const [modalAjouterConvocationSalarieEstVisible, setModalAjouterConvocationSalarieEstVisible] = useState<boolean>(false);

  /**
   * Permet d'ouvrir une modale lorsque l'on souhaite afficher les document d'un salariés.
   */
  const [modalDocumentsSalariesEstVisible, setModalDocumentsSalariesEstVisible] = useState<boolean>(false);

  /**
   * Permet d'ouvrir une modale lorsque l'on souhaite ajouter un document.
   */
  const [modalAjouterDocumentEstVisible, setModalAjouterDocumentEstVisible] = useState<boolean>(false);

  /**
   * Permet d'ouvrir une modale lorsque l'on souhaite accepter un souhait en temps que deirigeant.
   */
  const [modalUtilisateursEstVisible, setModalUtilisateursEstVisible] = useState<boolean>(false);

  /**
   * Permet d'ouvrir une modale lorsque l'on souhaite supprimer un avis.
   */
  const [modalModificationYpareoCodeEstVisible, setModalModificationYpareoCodeEstVisible] = useState<boolean>(false);

  /**
   * Permet d'ouvrir une modale lorsque l'on souhaite ajouter un devis.
   */
  const [modalAjouterDevisEstVisible, setModalAjouterDevisEstVisible] = useState<boolean>(false);

  /**
   * Permet d'ouvrir une modale lorsque l'on souhaite ajouter une facture.
   */
  const [modalAjouterFactureEstVisible, setModalAjouterFactureEstVisible] = useState<boolean>(false);

  /**
   * État local pour gérer lle souhait selectionné en vue d'ouvrir une modale dessus.
   */
  const [devisSelectionne, setDevisSelectionne] = useState<{
    id: Devis['id'];
    numero: Devis['numero'];
    titre: Devis['titre'];
    fichier: Devis['fichier'];
    description: Devis['description'];
    telechargements: Devis['telechargements'];
    date_limite_acceptation: Devis['date_limite_acceptation'];
    date_acceptation: Devis['date_acceptation'];
    date_refus: Devis['date_refus'];
    date_limite_retractation: Devis['date_limite_retractation'];
    date_retractation: Devis['date_retractation'];
    date_limite_traitement: Devis['date_limite_traitement'];
    date_traitement: Devis['date_traitement'];
    created_at: Devis['created_at'];
    updated_at: string | null;
  } | null>(null);

  /**
   * Permet d'ouvrir une modale lorsque l'on souhaite accepter un souhait en temps que salarié.
   */
  const [modalSuppressionDevisEstVisible, setModalSuppressionDevisEstVisible] = useState<boolean>(false);

  /**
   * État local pour gérer la facture selectionné en vue d'ouvrir une modale dessus.
   */
  const [factureSelectionne, setFactureSelectionne] = useState<{
    id: Facture['id'];
    numero: Facture['numero'];
    titre: Facture['titre'];
    fichier: Facture['fichier'];
    description: Facture['description'];
    telechargements: Facture['telechargements'];
    date_limite_paiement: Facture['date_limite_paiement'];
    date_paiement: Facture['date_paiement'];
    date_relance1: Facture['date_relance1'];
    date_relance2: Facture['date_relance2'];
    date_relance3: Facture['date_relance3'];
    created_at: Facture['created_at'];
    updated_at: string | null;
  } | null>(null);

  /**
   * Permet d'ouvrir une modale lorsque l'on souhaite supprimer une facture.
   */
  const [modalSuppressionFactureEstVisible, setModalSuppressionFactureEstVisible] = useState<boolean>(false);

  /**
   * Permet d'ouvrir une modale lorsque l'on souhaite ajouter une facture.
   */
  const [modalAjouterConvocationEstVisible, setModalAjouterConvocationEstVisible] = useState<boolean>(false);

  /**
   * État local pour gérer la facture selectionné en vue d'ouvrir une modale dessus.
   */
  const [convocationSelectionne, setConvocationSelectionne] = useState<{
    id: Convocation['id'];
    titre: Convocation['titre'];
    fichier: Convocation['fichier'];
    description: Convocation['description'];
    telechargements: Convocation['telechargements'];
    date_convocation: Convocation['date_convocation'];
    created_at: Convocation['created_at'];
    updated_at: string | null;
  } | null>(null);

  /**
   * Permet d'ouvrir une modale lorsque l'on souhaite supprimer une facture.
   */
  const [modalSuppressionConvocationEstVisible, setModalSuppressionConvocationEstVisible] = useState<boolean>(false);

  /**
   * On stocke le nom afin de l'afficher en mode édition et voir.
   */
  const [raisonSociale, setRaisonSociale] = useState<Entreprise['raison_sociale']>('');

  /**
   * Permet de stocker la liste des convocations.
   */
  const [convocations, setConvocations] = useState<
    | {
        id: Convocation['id'];
        titre: Convocation['titre'];
        fichier: Convocation['fichier'];
        description: Convocation['description'];
        telechargements: Convocation['telechargements'];
        date_convocation: Convocation['date_convocation'];
        created_at: Convocation['created_at'];
        updated_at: string | null;
      }[]
    | null
  >(null);

  /**
   * Permet de stocker la liste des devis.
   */
  const [devis, setDevis] = useState<
    | {
        id: Devis['id'];
        numero: Devis['numero'];
        titre: Devis['titre'];
        fichier: Devis['fichier'];
        description: Devis['description'];
        telechargements: Devis['telechargements'];
        date_limite_acceptation: Devis['date_limite_acceptation'];
        date_acceptation: Devis['date_acceptation'];
        date_refus: Devis['date_refus'];
        date_limite_retractation: Devis['date_limite_retractation'];
        date_retractation: Devis['date_retractation'];
        date_limite_traitement: Devis['date_limite_traitement'];
        date_traitement: Devis['date_traitement'];
        created_at: Devis['created_at'];
        updated_at: string | null;
      }[]
    | null
  >(null);

  const [factures, setFactures] = useState<
    | {
        id: Facture['id'];
        numero: Facture['numero'];
        titre: Facture['titre'];
        fichier: Facture['fichier'];
        description: Facture['description'];
        telechargements: Facture['telechargements'];
        date_limite_paiement: Facture['date_limite_paiement'];
        date_paiement: Facture['date_paiement'];
        date_relance1: Facture['date_relance1'];
        date_relance2: Facture['date_relance2'];
        date_relance3: Facture['date_relance3'];
        created_at: Facture['created_at'];
        updated_at: string | null;
      }[]
    | null
  >(null);

  /**
   * Permet de selectionner un devis.
   */
  const [devisIdSelectionne, setDevisIdSelectionne] = useState<Devis['id'] | null>(null);

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  /**
   * Date du jour.
   *
   * @constant
   */
  const currentDate = new Date();

  /**
   * Contient un état général des valeurs des champs du formulaire ainsi que les champs qui ont reçu une erreur lors de l'envoi du formulaire.
   */
  const [formInputs, setFormInputs] = useState<{ [K in keyof EntrepriseDb]: { value: EntrepriseDb[K]; error: boolean } }>({
    forme_juridique_id: { value: null, error: false },
    type_employeur_id: { value: null, error: false },
    convention_collective_id: { value: null, error: false },
    octa_id: { value: null, error: false },
    raison_sociale: { value: '', error: false },
    enseigne: { value: null, error: false },
    image_logo: { value: null, error: false },
    code_naf: { value: null, error: false },
    date_creation: { value: null, error: false },
    date_modification: { value: null, error: false },
    date_radiation: { value: null, error: false },
    effectif: { value: null, error: false },
    est_assujetti_taxe: { value: null, error: false },
    num_rep_metier: { value: null, error: false },
    num_tva_intracom: { value: null, error: false },
    siret: { value: null, error: false },
    site_web: { value: null, error: false },
    adr1: { value: null, error: false },
    adr2: { value: null, error: false },
    adr3: { value: null, error: false },
    adr4: { value: null, error: false },
    code_postal: { value: null, error: false },
    ville: { value: null, error: false },
    pays: { value: null, error: false },
    fact_adr1: { value: null, error: false },
    fact_adr2: { value: null, error: false },
    fact_adr3: { value: null, error: false },
    fact_adr4: { value: null, error: false },
    fact_code_postal: { value: null, error: false },
    fact_ville: { value: null, error: false },
    fact_pays: { value: null, error: false },
    est_approuvee: { value: 0, error: false },
    yp_code: { value: null, error: false },
    created_at: { value: null, error: false },
  });

  const [imageLogoString, setImageLogoString] = useState<Entreprise['image_logo'] | null>(null);

  /**
   * Permet au composant de changer de page.
   */
  const navigate = useNavigate();

  const params = useParams();

  // On a besoin du useEffect lorsque l'on est en mode édition et lecture pour précharger le formulaire avec les données de la ressource.

  useEffect(() => {
    handleIndex();
    switch (modeFormulaire) {
      case ModeFormulaire.creer:
        break;
      case ModeFormulaire.voir:
      case ModeFormulaire.editer:
        if (params.id === undefined) {
          navigate('/admin/entreprises');
        }
        handleShow(parseInt(params.id as string));
        break;

      default:
        break;
    }
  }, [client]);

  /**
   * Retrouve Les ressources nécessaires pour afficher les dropdown et les stockent dans des .
   *
   * @param indexParams - Paramètres de la recherche.
   */
  const handleIndex = () => {
    setApiIsLoading(true);
    const baseParams: BaseIndexParams = { as_foreign: true, no_pagination: true };

    const promises = [
      client.get<PaginatedApiResponse<FormeJuridique>, BaseIndexParams>('/admin/formes-juridiques', { params: baseParams }),
      client.get<PaginatedApiResponse<TypeEmployeur>, BaseIndexParams>('/admin/types-employeurs', { params: baseParams }),
      client.get<PaginatedApiResponse<ConventionCollective>, BaseIndexParams>('/admin/conventions-collectives', { params: baseParams }),
      client.get<PaginatedApiResponse<Octa>, BaseIndexParams>('/admin/octas', { params: baseParams }),
    ];

    if (modeFormulaire !== ModeFormulaire.creer) {
      promises.push(
        client.get<GoodApiResponse<ProfilEntreprise>, BaseIndexParams>(`/admin/entreprises/${parseInt(params.id as string)}/profils`) as Promise<AxiosResponse>,
      );
      promises.push(client.get<GoodApiResponse<ProfilEntreprise>, BaseIndexParams>(`/admin/types-profils`, { params: baseParams }) as Promise<AxiosResponse>);
      promises.push(
        client.get<GoodApiResponse<ProfilEntreprise>, BaseIndexParams>(`/admin/statuts-affiliation`, { params: baseParams }) as Promise<AxiosResponse>,
      );
      promises.push(
        client.get<
          GoodApiResponse<ProfilEntreprise>,
          {
            params: { entreprise_id: Entreprise['id'] };
          }
        >(`/admin/demandes`, {
          params: { entreprise_id: parseInt(params.id as string) },
        }) as Promise<AxiosResponse>,
      );
    }

    Promise.all(promises)
      .then((response) => {
        setFormeJuridiques(response[0].data.data as unknown as DropdownRessource[]);
        setTypeEmployeurs(response[1].data.data as unknown as DropdownRessource[]);
        setConventionCollectives(response[2].data.data as unknown as DropdownRessource[]);
        setOctas(response[3].data.data as unknown as DropdownRessource[]);
        if (modeFormulaire !== ModeFormulaire.creer) {
          setOwnedProfils(response[4].data.data as unknown as ProfilEntreprise[]);
          setTypeProfils(response[5].data.data as unknown as DropdownRessource[]);
          setStatutAffiliations(response[6].data.data as unknown as DropdownRessource[]);
          setDemandes(response[7].data.data as unknown as Demande[]);
        }
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Retrouve par le réseau la ressource liée à l'ID foruni et la met dans un état local.
   * Ne doit être utilisé qu'en mode édition et lecture seule.
   *
   * @param ressourceId - ID de la ressource dont on veut retrouver les informations.
   */
  const handleShow = (ressourceId: Ressource['id']) => {
    if (apiIsLoading === true) {
      return;
    }

    setApiIsLoading(true);
    client
      .get<GoodApiResponse<Entreprise>>(`/admin/entreprises/${ressourceId}`)
      .then((reponse) => {
        const updatedInputs = Object.keys(reponse.data.data).reduce((acc, key) => {
          if (key === 'image_logo' && reponse.data.data.image_logo !== null) {
            setImageLogoString(reponse.data.data.image_logo);
          }
          return { ...acc, [key]: { value: reponse.data.data[key as keyof EntrepriseDb], error: false } };
        }, formInputs);
        setFormInputs(updatedInputs);
        setRaisonSociale(updatedInputs.raison_sociale.value);
        setConvocations(reponse.data.data.convocations);
        setDevis(reponse.data.data.devis);
        setFactures(reponse.data.data.factures);
      })
      .catch((error: ErrorApiResponse<Entreprise>) => {
        handleError(error, () => {
          navigate('/admin/entreprises');
        });
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Met à jour, par le réseau, une ressource à partir du formulaire via un état local.
   * Ne doit être utilisé qu'en mode édition.
   *
   * @param ressourceId - ID de la ressource dont on veut modifier les informations.
   */
  const handleUpdate = (ressourceId: Ressource['id']) => {
    setApiIsLoading(true);

    let ressource: Partial<EntrepriseDb> = {};
    Object.keys(formInputs).forEach((key) => {
      if (formInputs[key as keyof EntrepriseDb]?.value !== null && !(key === 'image_logo' && formInputs.image_logo.value === imageLogoString)) {
        ressource = { ...ressource, [key]: formInputs[key as keyof EntrepriseDb]?.value };
      }
    });

    client
      .patch<GoodApiResponse<EntrepriseDb>>(`/admin/entreprises/${ressourceId}`, ressource)
      .then(() => {
        toast.success(`Modification éffectuée`);
        navigate('/admin/entreprises');
      })
      .catch((error: ErrorApiResponse<EntrepriseDb>) => {
        handleError(error, () => {
          let object = formInputs;
          Object.keys(error.response.data.errors).forEach((key) => {
            if (error.response.data.errors[key as keyof EntrepriseDb]) {
              object = { ...object, [key]: { value: formInputs[key as keyof EntrepriseDb]?.value, error: true } };
            }
          }, formInputs);
          setFormInputs(object);
        });
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Cette méthode permet de gérer l'envoi du formulaire en fonction du mode dans lequel on se trouve.
   * L'envoi ne fait rien si on est en mode voir.
   *
   * @param event - Évenement de l'envoie.
   */
  const handleSubmit = (event: { preventDefault: () => void }): void => {
    event.preventDefault();

    if (apiIsLoading === true) {
      return;
    }

    if (modeFormulaire === ModeFormulaire.editer) {
      if (params.id !== undefined) {
        handleUpdate(parseInt(params.id as string));
      }
    }
  };

  const handleTrileanChange = (value: string, field: keyof EntrepriseDb) => {
    setFormInputs((prev) => ({
      ...prev,
      [field]: { value: value, error: false },
    }));
  };

  const handleImageChange = (field: keyof EntrepriseDb) => {
    return (imageString: string) => {
      setFormInputs((prev) => ({
        ...prev,
        [field]: { value: imageString, error: false },
      }));
    };
  };

  /**
   * Permet de demander à l'API faire le lien entre l'entreprise et YParéo.
   *
   * @return void
   */
  const handleLienYpareo = () => {
    setApiIsLoading(true);

    client
      .patch<GoodApiResponse>(`/admin/entreprises/${parseInt(params.id as string)}/ypareo-relie-entreprise-auto`)
      .then(() => {
        toast.success(`L'entreprise a été liée à un identifaint YParéo.`);
        window.location.reload();
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  const handleModaleConvocations = (profil: ProfilEntreprise | null) => {
    setProfilSelectionne(profil);
    setModalConvocationsEstVisible(true);
  };

  const handleModaleAjouterConvocationSalarie = (profil: ProfilEntreprise | null) => {
    setProfilSelectionne(profil);
    setModalAjouterConvocationSalarieEstVisible(true);
  };

  const handleModaleDocumentsSalaries = (profil: ProfilEntreprise | null) => {
    setProfilSelectionne(profil);
    setModalDocumentsSalariesEstVisible(true);
  };

  const handleModaleAjouterDocument = (profil: ProfilEntreprise | null) => {
    setProfilSelectionne(profil);
    setModalAjouterDocumentEstVisible(true);
  };

  const handleTypeProfilChange = (profilId: ProfilEntreprise['id'], typeProfilId: TypeProfil['id']) => {
    setApiIsLoading(true);

    client
      .patch(`/admin/profils/${profilId}/type-profil/${typeProfilId}`)
      .then(() => {
        toast.success(`Fonction modifiée`);
        handleIndex();
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  const handleStatutAffiliationChange = (profilId: ProfilEntreprise['id'], statutAffiliationId: StatutAffiliation['id']) => {
    setApiIsLoading(true);

    client
      .patch(`/admin/profils/${profilId}/statut-affiliation/${statutAffiliationId}`)
      .then(() => {
        toast.success(`Statut affiliation modifié`);
        handleIndex();
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Permet d'écrire le mot correspondant au mode de fomulaire. Ce mot s'affiche au début du formulaire juste à coté de la ressource.
   *
   * @param modeFormulaire - Mode du formulaire (creer, editer et voir).
   * @returns Mot à afficher correspondant au mode de formulaire.
   * @throws Lance une erreur si aucun mode formulaire n'est fourni.
   */
  const afficheModeFormulaire = (modeFormulaire: ModeFormulaire) => {
    switch (modeFormulaire) {
      case ModeFormulaire.creer:
        return 'Création';
      case ModeFormulaire.editer:
        return 'Édition';
      case ModeFormulaire.voir:
        return 'Voir';

      default:
        throw new Error("Vous n'avez pas choisi un mode de formulaire adéquat.");
    }
  };

  /**
   * Cette méthode sert à afficher un résumé de l'avancement pour chaque devis.
   *
   * @param devi - Devis dont il est question dans la boucle.
   * @returns Le résumé des étapes de l'avancement du devis.
   */
  const afficheAvancement = (devi: {
    id: Devis['id'];
    numero: Devis['numero'];
    titre: Devis['titre'];
    fichier: Devis['fichier'];
    description: Devis['description'];
    telechargements: Devis['telechargements'];
    date_limite_acceptation: Devis['date_limite_acceptation'];
    date_acceptation: Devis['date_acceptation'];
    date_refus: Devis['date_refus'];
    date_limite_retractation: Devis['date_limite_retractation'];
    date_retractation: Devis['date_retractation'];
    date_limite_traitement: Devis['date_limite_traitement'];
    date_traitement: Devis['date_traitement'];
    created_at: Devis['created_at'];
    updated_at: string | null;
  }) => {
    if (devi.date_acceptation === null && devi.date_refus === null) {
      if (currentDate < new Date(devi.date_limite_acceptation)) {
        // Devis en attente
        return (
          <div
            className={
              devi.date_limite_acceptation &&
              dateEstCompriseDansIntervalle(new Date(devi.created_at as string), ajouterJour(new Date(devi.date_limite_acceptation), -1), currentDate)
                ? currentDate.getDate() === ajouterJour(new Date(devi.date_limite_acceptation), -1).getDate()
                  ? 'text-orange-200'
                  : ''
                : 'text-red-700'
            }>
            En attente de décision
            {devi.date_limite_acceptation
              ? `, date limite de décision : ${new Intl.DateTimeFormat('default', {
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                  hour12: false,
                }).format(new Date(devi.date_limite_acceptation))}.`
              : '.'}
          </div>
        );
      } else {
        // Délais d'attente dépassé
        return (
          <div className='text-red-700'>
            {devi.date_limite_acceptation
              ? `Date limite de décision : ${new Intl.DateTimeFormat('default', {
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                  hour12: false,
                }).format(new Date(devi.date_limite_acceptation))}.`
              : ''}
          </div>
        );
      }
    }

    if (devi.date_acceptation) {
      /**
       * Une fois le devis accepté on sait que l'on va rendre ce bloc pour tous les cas où le devis est accepté.
       * @constant
       */
      const devisAccepte = (
        <div>{`Accepté le : ${new Intl.DateTimeFormat('default', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour12: false,
        }).format(new Date(devi.date_acceptation as string))}.`}</div>
      );

      if (devi.date_traitement === null && devi.date_limite_traitement) {
        if (currentDate < new Date(devi.date_limite_traitement)) {
          // En attente de traitement
          return (
            <Fragment>
              {devisAccepte}
              <div
                className={
                  devi.date_acceptation &&
                  devi.date_limite_retractation &&
                  dateEstCompriseDansIntervalle(
                    new Date(devi.date_acceptation as string),
                    ajouterJour(new Date(devi.date_limite_retractation as string), -1),
                    currentDate,
                  )
                    ? currentDate.getDate() === ajouterJour(new Date(devi.date_limite_retractation), -1).getDate()
                      ? 'text-orange-200'
                      : ''
                    : 'text-red-700'
                }>
                {devi.date_limite_retractation
                  ? `Date limite de rétractation : ${new Intl.DateTimeFormat('default', {
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                      hour12: false,
                    }).format(new Date(devi.date_limite_retractation))}.`
                  : ''}
              </div>
            </Fragment>
          );
        } else {
          // Délais de traitement dépassé.
          return (
            <Fragment>
              {devisAccepte}
              <div className='text-red-700'>
                {devi.date_limite_traitement
                  ? `Date limite de traitement : ${new Intl.DateTimeFormat('default', {
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                      hour12: false,
                    }).format(new Date(devi.date_limite_traitement))}.`
                  : ''}
              </div>
            </Fragment>
          );
        }
      }

      if (devi.date_retractation) {
        return (
          <Fragment>
            {devisAccepte}
            <div className='text-red-700'>
              {devi.date_retractation
                ? `Rétractation le : ${new Intl.DateTimeFormat('default', {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour12: false,
                  }).format(new Date(devi.date_retractation))}.`
                : ''}
            </div>
          </Fragment>
        );
      }

      if (devi.date_traitement) {
        return (
          <Fragment>
            {devisAccepte}
            <div>
              {devi.date_traitement
                ? `Traité le : ${new Intl.DateTimeFormat('default', {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour12: false,
                  }).format(new Date(devi.date_traitement))}.`
                : ''}
            </div>
          </Fragment>
        );
      }
    }

    if (devi.date_refus) {
      return (
        <div>{`Refusé le : ${new Intl.DateTimeFormat('default', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour12: false,
        }).format(new Date(devi.date_refus as string))}.`}</div>
      );
    }
  };

  /**
   * Ouvre une modale pour informer l'administrateur qu'il s'appreète à supprimer un devis.
   * @param devi - Devis selectionné.
   */
  const handleModaleSuppressionDevis = (devi: {
    id: Devis['id'];
    numero: Devis['numero'];
    titre: Devis['titre'];
    fichier: Devis['fichier'];
    description: Devis['description'];
    telechargements: Devis['telechargements'];
    date_limite_acceptation: Devis['date_limite_acceptation'];
    date_acceptation: Devis['date_acceptation'];
    date_refus: Devis['date_refus'];
    date_limite_retractation: Devis['date_limite_retractation'];
    date_retractation: Devis['date_retractation'];
    date_limite_traitement: Devis['date_limite_traitement'];
    date_traitement: Devis['date_traitement'];
    created_at: Devis['created_at'];
    updated_at: string | null;
  }) => {
    setDevisSelectionne(devi);
    setModalSuppressionDevisEstVisible(true);
  };

  /**
   * Cette méthode est passé en paramètre de la modale de suppression de devis.
   * Elle permet de refuser un souhait.
   */
  const handleSupprimeDevis = () => {
    setApiIsLoading(true);

    client
      .delete<GoodApiResponse>(
        `/admin/devis/${
          (
            devisSelectionne as {
              id: Devis['id'];
              numero: Devis['numero'];
              titre: Devis['titre'];
              fichier: Devis['fichier'];
              description: Devis['description'];
              telechargements: Devis['telechargements'];
              date_limite_acceptation: Devis['date_limite_acceptation'];
              date_acceptation: Devis['date_acceptation'];
              date_refus: Devis['date_refus'];
              date_limite_retractation: Devis['date_limite_retractation'];
              date_retractation: Devis['date_retractation'];
              date_limite_traitement: Devis['date_limite_traitement'];
              date_traitement: Devis['date_traitement'];
              created_at: Devis['created_at'];
              updated_at: string | null;
            }
          ).id
        }`,
      )
      .then(() => {
        handleShow(parseInt(params.id as string));
        toast.success(`Devis supprimé`);
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Ouvre une modale pour informer l'administrateur qu'il s'apprète à supprimer une facture.
   * @param facture - Facture selectionnée.
   */
  const handleModaleSuppressionFacture = (facture: {
    id: Facture['id'];
    numero: Facture['numero'];
    titre: Facture['titre'];
    fichier: Facture['fichier'];
    description: Facture['description'];
    telechargements: Facture['telechargements'];
    date_limite_paiement: Facture['date_limite_paiement'];
    date_paiement: Facture['date_paiement'];
    date_relance1: Facture['date_relance1'];
    date_relance2: Facture['date_relance2'];
    date_relance3: Facture['date_relance3'];
    created_at: Facture['created_at'];
    updated_at: string | null;
  }) => {
    setFactureSelectionne(facture);
    setModalSuppressionFactureEstVisible(true);
  };

  /**
   * Cette méthode est passé en paramètre de la modale de suppression de facture.
   */
  const handleSupprimeFacture = () => {
    setApiIsLoading(true);

    client
      .delete<GoodApiResponse>(
        `/admin/factures/${
          (
            factureSelectionne as {
              id: Facture['id'];
              numero: Facture['numero'];
              titre: Facture['titre'];
              fichier: Facture['fichier'];
              description: Facture['description'];
              telechargements: Facture['telechargements'];
              date_limite_paiement: Facture['date_limite_paiement'];
              date_paiement: Facture['date_paiement'];
              date_relance1: Facture['date_relance1'];
              date_relance2: Facture['date_relance2'];
              date_relance3: Facture['date_relance3'];
              created_at: Facture['created_at'];
              updated_at: string | null;
            }
          ).id
        }`,
      )
      .then(() => {
        handleShow(parseInt(params.id as string));
        toast.success(`Facture supprimée`);
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Ouvre une modale pour informer l'administrateur qu'il s'apprète à supprimer une convocation.
   * @param convocation - Convocation selectionnée.
   */
  const handleModaleSuppressionConvocation = (convocation: {
    id: Convocation['id'];
    titre: Convocation['titre'];
    fichier: Convocation['fichier'];
    description: Convocation['description'];
    telechargements: Convocation['telechargements'];
    date_convocation: Convocation['date_convocation'];
    created_at: Convocation['created_at'];
    updated_at: string | null;
  }) => {
    setConvocationSelectionne(convocation);
    setModalSuppressionConvocationEstVisible(true);
  };

  /**
   * Cette méthode est passé en paramètre de la modale de suppression de convocation.
   */
  const handleSupprimeConvocation = () => {
    setApiIsLoading(true);

    client
      .delete<GoodApiResponse>(
        `/admin/convocations/${
          (
            convocationSelectionne as {
              id: Convocation['id'];
              titre: Convocation['titre'];
              fichier: Convocation['fichier'];
              description: Convocation['description'];
              telechargements: Convocation['telechargements'];
              date_convocation: Convocation['date_convocation'];
              created_at: Convocation['created_at'];
              updated_at: string | null;
            }
          ).id
        }`,
      )
      .then(() => {
        handleShow(parseInt(params.id as string));
        toast.success(`Convocation supprimée`);
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Contenu des onglets.
   * @constant
   */
  const data = [
    {
      label: 'Membres',
      value: 'membres',
    },
    {
      label: 'Demandes',
      value: 'demandes',
    },
    {
      label: 'YParéo',
      value: 'ypareo',
    },
    {
      label: 'Convocations',
      value: 'convocations',
    },
    {
      label: 'Devis',
      value: 'devis',
    },
    {
      label: 'Factures',
      value: 'factures',
    },
  ] as const;

  if (
    formeJuridiques === null ||
    typeEmployeurs === null ||
    conventionCollectives === null ||
    octas === null ||
    (modeFormulaire !== ModeFormulaire.creer && ownedProfils === null)
  ) {
    return <Fragment>Chargement...</Fragment>;
  }

  return (
    <Fragment>
      <Helmet>
        <title>
          {afficheModeFormulaire(modeFormulaire)} entreprise - {Config.app_label}
        </title>
      </Helmet>
      {modeFormulaire !== ModeFormulaire.creer && formInputs.est_approuvee.value === 0 && (
        <p className='p-3 bg-amber-200 rounded-lg text-sm mb-7 poppins-semibold text-secondary-1 flex flex-row gap-2'>
          <ExclamationCircleIcon className='w-5 h-5 flex-none' strokeWidth={2.2} />
          <span className='leading-4'>
            <b>Important&nbsp;:</b> Veuillez valider l'entreprise.
          </span>
        </p>
      )}
      {modeFormulaire !== ModeFormulaire.creer && formInputs.yp_code.value === null && (
        <p className='p-3 bg-amber-200 rounded-lg text-sm mb-7 poppins-semibold text-secondary-1 flex flex-row gap-2'>
          <ExclamationCircleIcon className='w-5 h-5 flex-none' strokeWidth={2.2} />
          <span className='leading-4'>
            <b>Important&nbsp;:</b> Veuillez lier l'entreprise à YParéo dans l'onglet correspondant.
          </span>
        </p>
      )}
      <HeaderForm
        text={`${afficheModeFormulaire(modeFormulaire)} entreprise ${modeFormulaire !== ModeFormulaire.creer ? raisonSociale : ''}`}
        returnUrl='/admin/entreprises'
      />
      <Tabs value='general'>
        <TabsHeader>
          <Tab value='general'>Général</Tab>
          {modeFormulaire !== ModeFormulaire.creer &&
            data.map(({ label, value }) => (
              <Tab key={value} value={value}>
                {label}
              </Tab>
            ))}
        </TabsHeader>
        <TabsBody>
          <TabPanel value='general'>
            <form className='relative' onSubmit={handleSubmit}>
              <div className='bg-white p-5 rounded-b-lg'>
                <div className='container mx-auto bg-white rounded-none'>
                  <fieldset className='pb-7 mb-7 border-b'>
                    <legend className='block text-left mb-3 text-xl text-secondary-1'>Identité</legend>
                    <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-2 items-start'>
                      <div>
                        <ReadOnlyText label='Raison sociale' value={formInputs.raison_sociale.value as string} error={formInputs.raison_sociale.error} />
                      </div>
                      <div>
                        <ReadOnlyText label='Enseigne' value={formInputs.enseigne.value as string} error={formInputs.enseigne.error} />
                      </div>
                      <div>
                        <ReadOnlyText label='Numéro de SIRET' value={formInputs.siret.value as string} error={formInputs.siret.error} />
                        <a
                          className='text-primary underline text-xs'
                          href={`https://www.societe.com/cgi-bin/search?champs=${formInputs.siret.value}`}
                          target='_blank'
                          rel='noreferrer'>
                          Voir sur société.com
                        </a>
                      </div>
                      <div>
                        <ReadOnlyText label='Code naf' value={formInputs.code_naf.value as string} error={formInputs.code_naf.error} />
                      </div>
                      <div>
                        <ReadOnlySelect
                          label='Forme juridique'
                          value={
                            formInputs.forme_juridique_id.value !== null && formInputs.forme_juridique_id.value !== undefined
                              ? formInputs.forme_juridique_id.value.toString()
                              : ''
                          }
                          error={formInputs.forme_juridique_id.error}
                          options={formeJuridiques}
                        />
                      </div>
                      <div>
                        <ReadOnlySelect
                          label={"Type d'employeur"}
                          value={
                            formInputs.type_employeur_id.value !== null && formInputs.type_employeur_id.value !== undefined
                              ? formInputs.type_employeur_id.value.toString()
                              : ''
                          }
                          error={formInputs.type_employeur_id.error}
                          options={typeEmployeurs}
                        />
                      </div>
                      <div>
                        <ReadOnlySelect
                          label={'Convention collective'}
                          value={
                            formInputs.convention_collective_id.value !== null && formInputs.convention_collective_id.value !== undefined
                              ? formInputs.convention_collective_id.value.toString()
                              : ''
                          }
                          error={formInputs.convention_collective_id.error}
                          options={conventionCollectives}
                        />
                      </div>
                      <div>
                        <ReadOnlySelect
                          label={'Octa'}
                          value={formInputs.octa_id.value !== null && formInputs.octa_id.value !== undefined ? formInputs.octa_id.value.toString() : ''}
                          error={formInputs.octa_id.error}
                          options={octas}
                        />
                      </div>
                      <div>
                        <ReadOnlyDate label='Date création' value={formInputs.date_creation.value as string} error={formInputs.date_creation.error} />
                      </div>
                      <div>
                        <ReadOnlyDate
                          label='Date modification'
                          value={formInputs.date_modification.value as string}
                          error={formInputs.date_modification.error}
                        />
                      </div>
                      <div>
                        <ReadOnlyDate label='Date radiation' value={formInputs.date_radiation.value as string} error={formInputs.date_radiation.error} />
                      </div>
                      <div>
                        <ReadOnlyNumber label='Effectif' value={formInputs.effectif.value as number} error={formInputs.effectif.error} />
                      </div>
                      <div>
                        <ReadOnlyText
                          label='Numéro repertoire métiers'
                          value={formInputs.num_rep_metier.value as string}
                          error={formInputs.num_rep_metier.error}
                        />
                      </div>
                      <div>
                        <ReadOnlyText
                          label='Numéro de TVA intracommunautaire'
                          value={formInputs.num_tva_intracom.value as string}
                          error={formInputs.num_tva_intracom.error}
                        />
                      </div>
                      <div className='flex items-end h-full pb-1'>
                        <ReadOnlyBoolean label='Assujetti taxe' value={formInputs.est_assujetti_taxe.value as boolean} />
                      </div>
                    </div>
                  </fieldset>
                  <fieldset className='pb-7 mb-7 border-b'>
                    <legend className='block text-left mb-3 text-xl text-secondary-1'>Image</legend>
                    <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-2'>
                      <div>
                        <InputImage label='Logo' imageUrl={imageLogoString as string} onChange={handleImageChange('image_logo')} />
                      </div>
                      <div className='pt-6'>
                        <ImageHints largeur='512' />
                      </div>
                    </div>
                  </fieldset>
                  <fieldset className='pb-7 mb-7 border-b'>
                    <legend className='block text-left mb-3 text-xl text-secondary-1'>Adresse domiciliation</legend>
                    <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-2'>
                      <div>
                        <ReadOnlyText label='Adresse 1' value={formInputs.adr1.value as string} error={formInputs.adr1.error} />
                      </div>
                      <div>
                        <ReadOnlyText label='Adresse 2' value={formInputs.adr2.value as string} error={formInputs.adr2.error} />
                      </div>
                      <div>
                        <ReadOnlyText label='Adresse 3' value={formInputs.adr3.value as string} error={formInputs.adr3.error} />
                      </div>
                      <div>
                        <ReadOnlyText label='Adresse 4' value={formInputs.adr4.value as string} error={formInputs.adr4.error} />
                      </div>
                      <div>
                        <ReadOnlyText label='Code postal' value={formInputs.code_postal.value as string} error={formInputs.code_postal.error} />
                      </div>
                      <div>
                        <ReadOnlyText label='Ville' value={formInputs.ville.value as string} error={formInputs.ville.error} />
                      </div>
                      <div>
                        <ReadOnlyText label='Pays' value={formInputs.pays.value as string} error={formInputs.pays.error} />
                      </div>
                    </div>
                  </fieldset>
                  <fieldset className='pb-7 mb-5 border-b'>
                    <legend className='block text-left mb-3 text-xl text-secondary-1'>Adresse facturation</legend>
                    <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-2'>
                      <div>
                        <ReadOnlyText label='Adresse 1' value={formInputs.fact_adr1.value as string} error={formInputs.fact_adr1.error} />
                      </div>
                      <div>
                        <ReadOnlyText label='Adresse 2' value={formInputs.fact_adr2.value as string} error={formInputs.fact_adr2.error} />
                      </div>
                      <div>
                        <ReadOnlyText label='Adresse 3' value={formInputs.fact_adr3.value as string} error={formInputs.fact_adr3.error} />
                      </div>
                      <div>
                        <ReadOnlyText label='Adresse 4' value={formInputs.fact_adr4.value as string} error={formInputs.fact_adr4.error} />
                      </div>
                      <div>
                        <ReadOnlyText label='Code postal' value={formInputs.fact_code_postal.value as string} error={formInputs.code_postal.error} />
                      </div>
                      <div>
                        <ReadOnlyText label='Ville' value={formInputs.fact_ville.value as string} error={formInputs.fact_ville.error} />
                      </div>
                      <div>
                        <ReadOnlyText label='Pays' value={formInputs.fact_pays.value as string} error={formInputs.fact_pays.error} />
                      </div>
                    </div>
                  </fieldset>
                  <fieldset className='pb-7 mb-5 border-b'>
                    <legend className='block text-left mb-3 text-xl text-secondary-1'>Contact</legend>
                    <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-2'>
                      <div>
                        <ReadOnlyText label='Site web' value={formInputs.site_web.value as string} error={formInputs.site_web.error} />
                      </div>
                    </div>
                  </fieldset>
                  <fieldset className='pb-7 mb-5'>
                    <legend className='block text-left mb-3 text-xl text-secondary-1'>Administration</legend>
                    <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-2'>
                      <div>
                        <InputTrilean
                          label='Approuvée'
                          value={formInputs.est_approuvee.value}
                          onChange={(e) => handleTrileanChange(e.target.value, 'est_approuvee')}
                        />
                      </div>
                      <div>
                        <ReadOnlyNumber label='YParéo CodeEntreprise' value={formInputs.yp_code.value} error={formInputs.yp_code.error} />
                      </div>
                    </div>
                  </fieldset>
                </div>
                <div className='container mx-auto w-11/12 xl:w-full'>
                  <div className='w-full py-4 sm:px-0 bg-white flex justify-end'>{modeFormulaire !== ModeFormulaire.voir && <SubmitSave />}</div>
                </div>
              </div>
            </form>
          </TabPanel>
          {modeFormulaire !== ModeFormulaire.creer && (
            <TabPanel value='membres'>
              <div className='bg-white w-full overflow-x-auto'>
                <div className='mx-4'>
                  <AlertInfo text="Modifier la fonction ou le statut d'un membre lui enverra un mail pour l'informer du changement." />
                </div>
                {ownedProfils && ownedProfils.length > 0 ? (
                  <table className='table-fixed min-w-full bg-white rounded-b-lg'>
                    <thead className='text-sm text-gray-500 text-left border-b'>
                      <tr>
                        <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Utilisateur</th>
                        <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>E-mail</th>
                        <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Fonction</th>
                        <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Statut</th>
                        <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Date d'affiliation</th>
                        <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(ownedProfils as ProfilEntreprise[]).map((profil) => (
                        <tr key={profil.id} className='border-gray-100 border-b hover:bg-gray-100'>
                          <td className='px-4 py-3 text-gray-800 text-sm'>
                            <div className='flex flex-row gap-2 items-center'>
                              <div className='flex-none'>
                                <Avatar src={profil.user.image_avatar as string} />
                              </div>
                              <div>{`${profil.user.prenom} ${profil.user.nom}`}</div>
                            </div>
                          </td>
                          <td className='px-4 py-3 text-gray-800 text-sm'>{profil.user.email}</td>
                          <td className='px-4 py-3 text-gray-800 text-sm'>
                            {typeProfils && (
                              <select
                                className={`bg-white relative w-full px-4 py-2 text-sm leading-none text-left text-secondary-1 border border-gray-400 rounded outline-none focus:border-secondary-2`}
                                value={typeProfils.find((typeProfil) => profil.type_profil === typeProfil.label)?.id}
                                onChange={(e) => handleTypeProfilChange(profil.id, parseInt(e.target.value))}>
                                <option value={typeProfils.find((typeProfil) => typeProfil.label === TypeProfilEnum.delegue)?.id}>
                                  <ConstTypeProfil text={TypeProfilEnum.delegue} />
                                </option>
                                <option value={typeProfils.find((typeProfil) => typeProfil.label === TypeProfilEnum.dirigeant)?.id}>
                                  <ConstTypeProfil text={TypeProfilEnum.dirigeant} />
                                </option>
                                <option value={typeProfils.find((typeProfil) => typeProfil.label === TypeProfilEnum.salarie)?.id}>
                                  <ConstTypeProfil text={TypeProfilEnum.salarie} />
                                </option>
                              </select>
                            )}
                          </td>
                          <td className='px-4 py-3 text-gray-800 text-sm'>
                            {statutAffiliations && (
                              <select
                                className={`bg-white relative w-full px-4 py-2 text-sm leading-none text-left text-secondary-1 border border-gray-400 rounded outline-none focus:border-secondary-2`}
                                value={statutAffiliations.find((statutAffiliation) => profil.statut_affiliation === statutAffiliation.label)?.id}
                                onChange={(e) => handleStatutAffiliationChange(profil.id, parseInt(e.target.value))}>
                                <option value={statutAffiliations.find((statutAffiliation) => statutAffiliation.label === StatutAffiliationEnum.affilie)?.id}>
                                  <ConstStatutAffiliation text={StatutAffiliationEnum.affilie} />
                                </option>
                                <option value={statutAffiliations.find((statutAffiliation) => statutAffiliation.label === StatutAffiliationEnum.revoque)?.id}>
                                  <ConstStatutAffiliation text={StatutAffiliationEnum.revoque} />
                                </option>
                              </select>
                            )}
                          </td>
                          <td className='px-4 py-3 text-gray-800 text-sm'>
                            {profil.created_at
                              ? new Intl.DateTimeFormat('default', {
                                  year: 'numeric',
                                  month: 'numeric',
                                  day: 'numeric',
                                  hour: 'numeric',
                                  minute: 'numeric',
                                  hour12: false,
                                }).format(new Date(profil.created_at as string))
                              : ''}
                          </td>
                          <td className='px-4 py-3 text-gray-800 text-sm'>
                            {profil.type_profil === TypeProfilEnum.salarie && (
                              <Menu placement='right-start'>
                                <MenuHandler>
                                  <Button
                                    variant='text'
                                    className='w-9 h-9 p-1.5 rounded-full text-gray-500 block mx-auto text-center mr-0 hover:bg-white/100 active:bg-transparent'>
                                    <EllipsisVerticalIcon className='h-6 w-6' />
                                  </Button>
                                </MenuHandler>
                                <MenuList>
                                  <MenuItem onClick={() => handleModaleAjouterConvocationSalarie(profil)}>Ajouter une convocation</MenuItem>
                                  <MenuItem onClick={() => handleModaleConvocations(profil)}>Liste de convocations</MenuItem>
                                  <MenuItem onClick={() => handleModaleAjouterDocument(profil)}>Ajouter un document</MenuItem>
                                  <MenuItem onClick={() => handleModaleDocumentsSalaries(profil)}>Liste de documents</MenuItem>
                                </MenuList>
                              </Menu>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div className='text-center px-4 py-7'>Il n'y pas de membre pour cette entreprise.</div>
                )}
                <div className='p-4 text-center'>
                  <Button
                    variant='filled'
                    color='orange'
                    onClick={() => {
                      setModalUtilisateursEstVisible(true);
                    }}>
                    Ajouter un dirigeant
                  </Button>
                </div>
              </div>
            </TabPanel>
          )}
          {modeFormulaire !== ModeFormulaire.creer && (
            <TabPanel value='demandes'>
              <div className='container mx-auto bg-white rounded-b-lg'>
                <div className='w-full overflow-x-auto'>
                  {apiIsLoading ? <LoadingAbsolute /> : ''}
                  {demandes && demandes.length > 0 ? (
                    <table className='table-fixed min-w-full bg-white rounded-b-lg'>
                      <thead>
                        <tr className='w-full h-12 border-gray-300  border-b py-8'>
                          <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Formation et session</th>
                          <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Salarié</th>
                          <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Avancement</th>
                          <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Décision</th>
                          <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'></th>
                        </tr>
                      </thead>
                      <tbody>
                        {demandes.map((demande) => (
                          <tr
                            key={demande.id}
                            className={`border-gray-100 border-b hover:bg-gray-100 ${demande.est_archive && 'bg-gray-200'}`}
                            onDoubleClick={() => navigate(`/admin/demandes/${demande.id}`)}>
                            <td className='px-4 py-3'>
                              <FormationSessionDisplay formation={demande.formation} groupe={demande.groupe} />
                            </td>
                            <td className='pr-4 py-3'>{demande.user && <UserDisplay user={demande.user} />}</td>
                            <td className='pr-4 py-3 text-gray-800 text-xs'>
                              <div>
                                {/*<div>
                                  {demande.est_en_attente_informations ? (
                                    <ExclamationTriangleIcon className='w-4 h-4 inline-block mx-auto text-red-300' />
                                  ) : (
                                    <XMarkIcon className='w-4 h-4 inline-block mx-auto text-gray-900/20' />
                                  )}{' '}
                                  En attente d'informations
                                  </div> */}
                                <div>
                                  {demande.est_en_attente_documents ? (
                                    <ExclamationTriangleIcon className='w-4 h-4 inline-block mx-auto text-red-300' />
                                  ) : (
                                    <XMarkIcon className='w-4 h-4 inline-block mx-auto text-gray-900/20' />
                                  )}{' '}
                                  Documents en attente
                                </div>
                                <div>
                                  {demande.est_complete ? (
                                    <CheckIcon className='w-4 h-4 text-white inline-block mx-auto bg-light-green-300 rounded-full p-0.5' />
                                  ) : (
                                    <XMarkIcon className='w-4 h-4 inline-block mx-auto text-gray-900/20' />
                                  )}{' '}
                                  Demande complète
                                </div>
                                <div>
                                  {demande.est_paye ? (
                                    <CheckIcon className='w-4 h-4 text-white inline-block mx-auto bg-light-green-300 rounded-full p-0.5' />
                                  ) : (
                                    <XMarkIcon className='w-4 h-4 inline-block mx-auto text-gray-900/20' />
                                  )}{' '}
                                  Paiement effectué
                                </div>
                                <div>
                                  {demande.est_finalise ? (
                                    <CheckIcon className='w-4 h-4 text-white inline-block mx-auto bg-light-green-300 rounded-full p-0.5' />
                                  ) : (
                                    <XMarkIcon className='w-4 h-4 inline-block mx-auto text-gray-900/20' />
                                  )}{' '}
                                  Finalisée
                                </div>
                                <div>
                                  {demande.est_archive && (
                                    <Fragment>
                                      <BookOpenIcon className='w-4 h-4 inline-block mx-auto text-gray-900/20' /> Archivée
                                    </Fragment>
                                  )}
                                </div>
                              </div>
                            </td>
                            <td className='pr-4 py-3 text-gray-800 text-xs'>
                              {demande.date_acceptation === null && demande.date_refus === null ? (
                                <span
                                  className={
                                    demande.created_at &&
                                    demande.date_limite_admin_reponse_demande &&
                                    dateEstCompriseDansIntervalle(
                                      new Date(demande.created_at),
                                      ajouterJour(new Date(demande.date_limite_admin_reponse_demande), -1),
                                      currentDate,
                                    )
                                      ? currentDate.getDate() === ajouterJour(new Date(demande.date_limite_admin_reponse_demande), -1).getDate()
                                        ? 'text-orange-200'
                                        : ''
                                      : 'text-red-700'
                                  }>
                                  En attente
                                  {demande.date_limite_admin_reponse_demande
                                    ? `, vous avez jusqu'au  ${new Intl.DateTimeFormat('default', {
                                        year: 'numeric',
                                        month: 'numeric',
                                        day: 'numeric',
                                        hour12: false,
                                      }).format(new Date(demande.date_limite_admin_reponse_demande as string))} pour répondre`
                                    : ''}
                                </span>
                              ) : demande.date_acceptation ? (
                                `Acceptée le ${new Intl.DateTimeFormat('default', {
                                  year: 'numeric',
                                  month: 'numeric',
                                  day: 'numeric',
                                  hour12: false,
                                }).format(new Date(demande.date_acceptation as string))}`
                              ) : demande.date_refus ? (
                                `Refusée le ${new Intl.DateTimeFormat('default', {
                                  year: 'numeric',
                                  month: 'numeric',
                                  day: 'numeric',
                                  hour12: false,
                                }).format(new Date(demande.date_refus as string))}`
                              ) : (
                                ''
                              )}
                            </td>
                            <td className='px-4 py-3 text-gray-800 text-sm'>
                              <Menu placement='right-start'>
                                <MenuHandler>
                                  <Button
                                    variant='text'
                                    className='w-9 h-9 p-1.5 rounded-full text-gray-500 block mx-auto text-center mr-0 hover:bg-white/100 active:bg-transparent'>
                                    <EllipsisVerticalIcon className='h-6 w-6' />
                                  </Button>
                                </MenuHandler>
                                <MenuList>
                                  <MenuItem onClick={() => navigate(`/admin/demandes/${demande.id}`)}>Voir la demande</MenuItem>
                                  <MenuItem onClick={() => window.open(`/formations/${demande.formation.slug}`, '_blank')}>
                                    Voir la page de la formation
                                  </MenuItem>
                                </MenuList>
                              </Menu>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className='text-center px-4 py-7'>Il n'y pas de demande pour cette entreprise.</div>
                  )}
                </div>
              </div>
            </TabPanel>
          )}
          {modeFormulaire !== ModeFormulaire.creer && (
            <TabPanel value='convocations'>
              <div className='bg-white w-full overflow-x-auto'>
                {convocations && convocations.length > 0 ? (
                  <table className='table-fixed min-w-full bg-white rounded-b-lg'>
                    <thead className='text-sm text-gray-500 text-left border-b'>
                      <tr>
                        <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Fichier</th>
                        <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Description</th>
                        <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Date convocation</th>
                        <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Créée le</th>
                      </tr>
                    </thead>
                    <tbody>
                      {convocations.map((convocation) => (
                        <tr
                          key={convocation.id}
                          className=' border-gray-100 border-b hover:bg-gray-100'
                          onDoubleClick={() => navigate(`/admin/convocations/${convocation.id}`)}>
                          <td className='px-4 py-3 text-gray-800 text-sm'>
                            {convocation.fichier && <DownloadLink fichier={convocation.fichier} titre={convocation.titre} />}
                          </td>
                          <td className='px-4 py-3 text-gray-800 text-sm'>{convocation.description ? nl2br(convocation.description) : ''}</td>
                          <td className='px-4 py-3 text-gray-800 text-sm'>
                            {convocation.date_convocation
                              ? new Intl.DateTimeFormat('default', {
                                  year: 'numeric',
                                  month: 'numeric',
                                  day: 'numeric',
                                  hour12: false,
                                }).format(new Date(convocation.date_convocation as string))
                              : ''}
                          </td>
                          <td className='px-4 py-3 text-gray-800 text-sm'>
                            {convocation.created_at
                              ? new Intl.DateTimeFormat('default', {
                                  year: 'numeric',
                                  month: 'numeric',
                                  day: 'numeric',
                                  hour: 'numeric',
                                  minute: 'numeric',
                                  second: 'numeric',
                                  hour12: false,
                                }).format(new Date(convocation.created_at as string))
                              : ''}
                          </td>
                          <td className='px-4 py-3 text-gray-800 text-sm'>
                            <Menu placement='right-start'>
                              <MenuHandler>
                                <Button
                                  variant='text'
                                  className='w-9 h-9 p-1.5 rounded-full text-gray-500 block mx-auto text-center mr-0 hover:bg-white/100 active:bg-transparent'>
                                  <EllipsisVerticalIcon className='h-6 w-6' />
                                </Button>
                              </MenuHandler>
                              <MenuList>
                                <MenuItem onClick={() => navigate(`/admin/convocations/${convocation.id}`)}>Voir</MenuItem>
                                <MenuItem
                                  onClick={() => {
                                    handleModaleSuppressionConvocation(convocation);
                                  }}>
                                  Supprimer
                                </MenuItem>
                              </MenuList>
                            </Menu>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div className='text-center px-4 py-7'>Il n'y pas de convocation pour cette entreprise.</div>
                )}

                <div className='p-4 text-center'>
                  <Button
                    variant='filled'
                    color='orange'
                    onClick={() => {
                      setModalAjouterConvocationEstVisible(true);
                    }}>
                    Ajouter une convocation
                  </Button>
                </div>
              </div>
            </TabPanel>
          )}
          {modeFormulaire !== ModeFormulaire.creer && (
            <TabPanel value='devis'>
              <div className='bg-white w-full overflow-x-auto'>
                {devis && devis.length > 0 ? (
                  <table className='table-fixed w-full min-w-[1200px] bg-white rounded-b-lg'>
                    <thead className='text-sm text-gray-500 text-left border-b'>
                      <tr>
                        <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Numéro</th>
                        <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Fichier</th>
                        <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Description</th>
                        <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Avancement</th>
                        <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Créée le</th>
                        <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'></th>
                      </tr>
                    </thead>
                    <tbody>
                      {devis.map((devi) => (
                        <tr key={devi.id} className=' border-gray-100 border-b hover:bg-gray-100' onDoubleClick={() => navigate(`/admin/devis/${devi.id}`)}>
                          <td className='px-4 py-3 text-gray-800 text-sm'>{devi.numero}</td>
                          <td className='px-4 py-3 text-gray-800 text-sm'>{devi.fichier && <DownloadLink fichier={devi.fichier} titre={devi.titre} />}</td>
                          <td
                            className={`px-4 py-3 text-gray-800 text-xs ${devi.id !== devisIdSelectionne ? 'cursor-row-resize' : ''}`}
                            onClick={() => setDevisIdSelectionne(devi.id)}>
                            <div className={`${devi.id !== devisIdSelectionne ? 'line-clamp-6' : ''}`}>{devi.description ? nl2br(devi.description) : ''}</div>
                          </td>
                          <td className='px-4 py-3 text-gray-800 text-xs'>
                            <div>{afficheAvancement(devi)}</div>
                          </td>
                          <td className='px-4 py-3 text-gray-800 text-sm'>
                            {devi.created_at
                              ? new Intl.DateTimeFormat('default', {
                                  year: 'numeric',
                                  month: 'numeric',
                                  day: 'numeric',
                                  hour12: false,
                                }).format(new Date(devi.created_at as string))
                              : ''}
                          </td>
                          <td className='px-4 py-3 text-gray-800 text-sm'>
                            <Menu placement='right-start'>
                              <MenuHandler>
                                <Button
                                  variant='text'
                                  className='w-9 h-9 p-1.5 rounded-full text-gray-500 block mx-auto text-center mr-0 hover:bg-white/100 active:bg-transparent'>
                                  <EllipsisVerticalIcon className='h-6 w-6' />
                                </Button>
                              </MenuHandler>
                              <MenuList>
                                <MenuItem onClick={() => navigate(`/admin/devis/${devi.id}`)}>Voir</MenuItem>
                                <MenuItem
                                  onClick={() => {
                                    handleModaleSuppressionDevis(devi);
                                  }}>
                                  Supprimer
                                </MenuItem>
                              </MenuList>
                            </Menu>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div className='text-center px-4 py-7'>Il n'y pas de devis pour cette entreprise.</div>
                )}

                <div className='p-4 text-center'>
                  <Button
                    variant='filled'
                    color='orange'
                    onClick={() => {
                      setModalAjouterDevisEstVisible(true);
                    }}>
                    Ajouter un devis
                  </Button>
                </div>
              </div>
            </TabPanel>
          )}
          {modeFormulaire !== ModeFormulaire.creer && (
            <TabPanel value='factures'>
              <div className='bg-white w-full overflow-x-auto'>
                {factures && factures.length > 0 ? (
                  <table className='table-fixed min-w-full bg-white rounded-b-lg'>
                    <thead className='text-sm text-gray-500 text-left border-b'>
                      <tr>
                        <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Numéro</th>
                        <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Fichier</th>
                        <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Description</th>
                        <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Date limite de paiement</th>
                        <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Payée le</th>
                        <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Créée le</th>
                      </tr>
                    </thead>
                    <tbody>
                      {factures.map((facture) => (
                        <tr
                          key={facture.id}
                          className=' border-gray-100 border-b hover:bg-gray-100'
                          onDoubleClick={() => navigate(`/admin/factures/${facture.id}`)}>
                          <td className='px-4 py-3 text-gray-800 text-sm'>{facture.numero}</td>
                          <td className='px-4 py-3 text-gray-800 text-sm'>
                            {facture.fichier && <DownloadLink fichier={facture.fichier} titre={facture.titre} />}
                          </td>
                          <td className='px-4 py-3 text-gray-800 text-sm'>{facture.description ? nl2br(facture.description) : ''}</td>
                          <td className='px-4 py-3 text-gray-800 text-sm'>
                            {facture.date_limite_paiement
                              ? new Intl.DateTimeFormat('default', {
                                  year: 'numeric',
                                  month: 'numeric',
                                  day: 'numeric',
                                  hour12: false,
                                }).format(new Date(facture.date_limite_paiement as string))
                              : ''}
                          </td>
                          <td className='px-4 py-3 text-gray-800 text-sm'>
                            {facture.date_paiement
                              ? new Intl.DateTimeFormat('default', {
                                  year: 'numeric',
                                  month: 'numeric',
                                  day: 'numeric',
                                  hour12: false,
                                }).format(new Date(facture.date_paiement as string))
                              : ''}
                          </td>
                          <td className='px-4 py-3 text-gray-800 text-sm'>
                            {facture.created_at
                              ? new Intl.DateTimeFormat('default', {
                                  year: 'numeric',
                                  month: 'numeric',
                                  day: 'numeric',
                                  hour: 'numeric',
                                  minute: 'numeric',
                                  second: 'numeric',
                                  hour12: false,
                                }).format(new Date(facture.created_at as string))
                              : ''}
                          </td>
                          <td className='px-4 py-3 text-gray-800 text-sm'>
                            <Menu placement='right-start'>
                              <MenuHandler>
                                <Button
                                  variant='text'
                                  className='w-9 h-9 p-1.5 rounded-full text-gray-500 block mx-auto text-center mr-0 hover:bg-white/100 active:bg-transparent'>
                                  <EllipsisVerticalIcon className='h-6 w-6' />
                                </Button>
                              </MenuHandler>
                              <MenuList>
                                <MenuItem onClick={() => navigate(`/admin/factures/${facture.id}`)}>Voir</MenuItem>
                                <MenuItem
                                  onClick={() => {
                                    handleModaleSuppressionFacture(facture);
                                  }}>
                                  Supprimer
                                </MenuItem>
                              </MenuList>
                            </Menu>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div className='text-center px-4 py-7'>Il n'y pas de facture pour cette entreprise.</div>
                )}

                <div className='p-4 text-center'>
                  <Button
                    variant='filled'
                    color='orange'
                    onClick={() => {
                      setModalAjouterFactureEstVisible(true);
                    }}>
                    Ajouter une facture
                  </Button>
                </div>
              </div>
            </TabPanel>
          )}
          {modeFormulaire !== ModeFormulaire.creer && (
            <TabPanel value='ypareo'>
              <div className='bg-white p-5 rounded-b-lg'>
                <p className='pb-5'>{formInputs.yp_code.value ? `L'entreprise est déjà liée à YParéo.` : `L'entreprise n'est pas encore liée à YParéo.`}</p>
                {formInputs.yp_code.value === null && (
                  <Fragment>
                    <button className='bg-primary text-xs poppins-bold text-white py-2 px-5 rounded-full' onClick={() => handleLienYpareo()}>
                      Lier automatiquement à YParéo
                    </button>
                    <div className='px-2'>ou</div>
                  </Fragment>
                )}
                <button
                  className='bg-secondary-1 text-xs poppins-bold text-white py-2 px-5 rounded-full'
                  onClick={() => setModalModificationYpareoCodeEstVisible(true)}>
                  <span>Affecter manuellement un codeEntreprise YParéo...</span>
                </button>
                <ModalModificationYpareoCodeEntreprise
                  visible={modalModificationYpareoCodeEstVisible}
                  setVisible={setModalModificationYpareoCodeEstVisible}
                  entrepriseId={parseInt(params.id as string)}
                  ypareoCode={formInputs.yp_code.value ? (formInputs.yp_code.value as number).toString() : ''}
                  handleShow={handleShow}
                />
              </div>
            </TabPanel>
          )}
        </TabsBody>
      </Tabs>
      {apiIsLoading ? <LoadingAbsolute /> : ''}
      <ModalConvocationsSalariesAdmin
        visible={modalConvocationsEstVisible}
        setVisible={setModalConvocationsEstVisible}
        profil={profilSelectionne ? profilSelectionne : null}
      />
      <ModalAjouterConvocationSalariesAdmin
        visible={modalAjouterConvocationSalarieEstVisible}
        setVisible={setModalAjouterConvocationSalarieEstVisible}
        profil={profilSelectionne ? profilSelectionne : null}
        handleIndex={handleIndex}
      />
      <ModalDocumentsSalaries
        visible={modalDocumentsSalariesEstVisible}
        setVisible={setModalDocumentsSalariesEstVisible}
        profil={profilSelectionne ? profilSelectionne : null}
      />
      <ModalAjouterDocumentEntreprise
        visible={modalAjouterDocumentEstVisible}
        setVisible={setModalAjouterDocumentEstVisible}
        profil={profilSelectionne ? profilSelectionne : null}
        handleIndex={handleIndex}
      />
      <ModalUtilisateurs
        visible={modalUtilisateursEstVisible}
        setVisible={setModalUtilisateursEstVisible}
        entrepriseId={parseInt(params.id as string)}
        handleIndex={handleIndex}
      />
      <ModalAjouterDevisEntreprise
        visible={modalAjouterDevisEstVisible}
        setVisible={setModalAjouterDevisEstVisible}
        entrepriseId={params.id as Entreprise['id']}
        handleShow={handleShow}
      />
      <ModalAjouterFactureEntreprise
        visible={modalAjouterFactureEstVisible}
        setVisible={setModalAjouterFactureEstVisible}
        entrepriseId={params.id as Entreprise['id']}
        handleShow={handleShow}
      />
      <ModalSuppressionDevis visible={modalSuppressionDevisEstVisible} setVisible={setModalSuppressionDevisEstVisible} handleSupprime={handleSupprimeDevis} />
      <ModalSuppressionFacture
        visible={modalSuppressionFactureEstVisible}
        setVisible={setModalSuppressionFactureEstVisible}
        handleSupprime={handleSupprimeFacture}
      />
      <ModalAjouterConvocationEntreprise
        visible={modalAjouterConvocationEstVisible}
        setVisible={setModalAjouterConvocationEstVisible}
        entrepriseId={params.id as Entreprise['id']}
        handleShow={handleShow}
      />
      <ModalSuppressionConvocation
        visible={modalSuppressionConvocationEstVisible}
        setVisible={setModalSuppressionConvocationEstVisible}
        handleSupprime={handleSupprimeConvocation}
      />
    </Fragment>
  );
};

import { Dialog, DialogHeader, DialogBody, DialogFooter, Button } from '@material-tailwind/react';
import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useApi } from '../../../providers/ApiProvider';
import { GoodApiResponse, ErrorApiResponse, BaseIndexParams } from '../../../types/api';
import { LoadingAbsolute } from '../../LoadingAbsolute';
import { Avatar } from '../../Avatar';
import { User } from '../../../types/schematics';
import { DropdownRessource } from '../../../types';
import { handleError } from '../../../utils/ErrorHandler';
import { InputText } from '../../Inputs/InputText';

/**
 * Ce composant permet d'afficher une Modale pour qu'un admin puisse assigner un nouveau dirigeant à une entreprise'.
 *
 * @param visible - Précise si la modale et ouverte ou non. Provient du parent.
 * @param setVisible - Accesseur qui modifie la visibilité de la modale. Provient du parent.
 * @param entrepriseId - ID de l'entreprise. Provient du parent.
 * @param handleIndex - Callback qui permet de recharger les données du composant parent. Provient du parent.
 */
export const ModalUtilisateurs: FunctionComponent<{
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  entrepriseId: number;
  handleIndex: () => void;
}> = ({ visible, setVisible, entrepriseId, handleIndex }) => {
  /**
   * On stocke si on est en train d'utiliser l'API ou non.
   */
  const [apiIsLoading, setApiIsLoading] = useState(false);

  /**
   * On stocke les utilisateurs de l'application.
   */
  const [users, setUsers] = useState<(User & { roles: DropdownRessource[] })[] | null>(null);

  /**
   * On l'état de la valeur à filtrer.
   */
  const [filter, setFilter] = useState<string>('');

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  /**
   * Au chargement du composant on effectue l'appel qui va chercher les utilisateurs.
   */
  useEffect(() => {
    if (visible) {
      handleListeUtilisateurs();
    }
  }, [client, visible]);

  /**
   * Cette méthode va chercher les utilisateurs.
   */
  const handleListeUtilisateurs = () => {
    setApiIsLoading(true);

    client
      .get<GoodApiResponse<(User & { roles: DropdownRessource[] })[]>, BaseIndexParams>('/admin/users', {
        params: {
          no_pagination: true,
          est_rgpd_anonymise: false,
          sort: 'nom',
        },
      })
      .then((response) => {
        setUsers(response.data.data);
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Cette méthode permet d'ajouter un nouveau dirigeant d'entreprise au cas ou le premier cède sa place.
   *
   * @param userId
   */
  const handleAjoutDirigeant = (userId: User['id']) => {
    setApiIsLoading(true);

    client
      .post<GoodApiResponse>(`/admin/users/${userId}/assigne-dirigeant-entreprise/${entrepriseId}`)
      .then(() => {
        handleIndex();
        setVisible(false), toast.success('Dirigeant assigné');
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  return (
    <Fragment>
      <Dialog open={visible} size='sm' handler={setVisible}>
        <DialogHeader>Choisir le dirigeant</DialogHeader>
        <DialogBody divider className='p-0'>
          <div className='bg-white w-full overflow-x-auto overflow-y-auto h-96'>
            <table className='table-fixed min-w-full bg-white rounded-b-lg'>
              <tbody>
                <div className='px-4 py-2'>
                  <InputText label='Rechercher : ' value={filter} onChange={(e) => setFilter(e.target.value)} />
                </div>
                {users &&
                  users
                    .filter((user) => {
                      if (filter === '') {
                        return user.roles.filter((role) => role.label === 'standard').length > 0;
                      }

                      const regexp = new RegExp(
                        filter
                          .normalize('NFD')
                          .replace(/[\u0300-\u036f]/g, '')
                          .toLowerCase(),
                      );

                      return (
                        user.roles.filter((role) => role.label === 'standard').length > 0 &&
                        (regexp.test(
                          `${user.prenom
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')
                            .toLowerCase()} ${user.nom
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')
                            .toLowerCase()}`,
                        ) ||
                          regexp.test(
                            `${user.nom
                              .normalize('NFD')
                              .replace(/[\u0300-\u036f]/g, '')
                              .toLowerCase()} ${user.prenom
                              .normalize('NFD')
                              .replace(/[\u0300-\u036f]/g, '')
                              .toLowerCase()}`,
                          ) ||
                          regexp.test(user.email.toLowerCase()))
                      );
                    })
                    .map((user) => (
                      <tr key={user.id} className=' border-gray-100 border-b hover:bg-gray-100'>
                        <td className='px-4 py-3 text-gray-800 text-sm flex flex-row gap-2 items-center'>
                          <div className='flex-none'>
                            <Avatar src={user.image_avatar as string} />
                          </div>
                          <div>{`${user.prenom} ${user.nom}`}</div>
                        </td>
                        <td className='px-4 py-3 text-gray-800 text-sm'>{user.email as string}</td>
                        <td className='px-4 py-3 text-gray-800 text-sm text-right'>
                          <Button
                            variant='filled'
                            className='disabled:bg-primary/30 disabled:border-primary/30 px-2 py-1 rounded-full'
                            color='orange'
                            onClick={() => {
                              handleAjoutDirigeant(user.id);
                              handleIndex();
                            }}
                            disabled={apiIsLoading}>
                            Choisir
                          </Button>
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
        </DialogBody>
        <DialogFooter>
          <Button
            variant='text'
            color='gray'
            onClick={() => {
              setVisible(false);
            }}
            className='mr-1'
            disabled={apiIsLoading}>
            Fermer
          </Button>
        </DialogFooter>
        {apiIsLoading ? <LoadingAbsolute /> : ''}
      </Dialog>
    </Fragment>
  );
};

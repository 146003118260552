import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import { Helmet } from 'react-helmet';
import { LoadingAbsolute } from '../../../components/LoadingAbsolute';
import { useApi } from '../../../providers/ApiProvider';
import { Facture } from '../../../types/EntreprisesRessources';
import { GoodApiResponse, ErrorApiResponse } from '../../../types/api';
import { Config } from '../../../config/config';
import { handleError } from '../../../utils/ErrorHandler';
import { useNavigate } from 'react-router-dom';
import { Menu, MenuHandler, Button, MenuList, MenuItem } from '@material-tailwind/react';
import { UserDisplay } from '../../../components/UserDisplay';
import { FormationSessionDisplay } from '../../../components/FormationSessionDisplay';
import { DownloadLink } from '../../../components/DownloadLink';

/**
 * Cette page affiche les factures de l'entreprise.
 */
export const EntrepriseFacturesScreen: FunctionComponent = () => {
  /**
   * On stocke si on est en train d'utiliser l'API ou non.
   */
  const [apiIsLoading, setApiIsLoading] = useState(false);

  /**
   * Permet de stocker la liste des devis.
   */
  const [factures, setFactures] = useState<Facture[] | null>(null);

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  /**
   * Permet au composant de changer de page.
   */
  const navigate = useNavigate();

  /**
   * Permet de recharger les données au chargement de page
   */
  useEffect(() => {
    handleIndex();
  }, [client]);

  /**
   * Cette méthode va chercher les informations dans l'API pour afficher les factures.
   */
  const handleIndex = () => {
    setApiIsLoading(true);

    client
      .get<GoodApiResponse<Facture[]>>(`/entreprise/factures`)
      .then((response) => {
        setFactures(response.data.data);
      })
      .catch((error: ErrorApiResponse<Facture[]>) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  if (factures === null) {
    return <Fragment>Chargement...</Fragment>;
  }

  return (
    <Fragment>
      <Helmet>
        <title>Mes factures - {Config.app_label}</title>
      </Helmet>
      <div className='px-4 py-4 md:py-3 bg-gray-100 rounded-t-lg flex flex-col sm:flex-row gap-7 items-center'>
        <div className='sm:flex items-center justify-between'>
          <h1 className='text-base sm:text-lg md:text-xl poppins-bold leading-normal text-secondary-1'>Mes factures</h1>
        </div>
      </div>
      <div>
        <div className='bg-white rounded-b-lg w-full overflow-x-auto'>
          {factures.length > 0 ? (
            <table className='w-full min-w-[1200px] bg-white rounded-b-lg'>
              <thead className='text-sm text-gray-500 text-left border-b'>
                <tr>
                  <th className='py-4 text-secondary-1 px-2 text-left text-sm tracking-normal leading-4 pl-4'>Numéro</th>
                  <th className='py-4 text-secondary-1 px-2 text-left text-sm tracking-normal leading-4'>Numéro de devis</th>
                  <th className='py-4 text-secondary-1 px-2 text-left text-sm tracking-normal leading-4'>Formation et session</th>
                  <th className='py-4 text-secondary-1 px-2 text-left text-sm tracking-normal leading-4'>Salarié</th>
                  <th className='py-4 text-secondary-1 px-2 text-left text-sm tracking-normal leading-4'>Fichier</th>
                  <th className='py-4 text-secondary-1 px-2 text-left text-sm tracking-normal leading-4'>Date limite de paiement</th>
                  <th className='py-4 text-secondary-1 px-2 text-left text-sm tracking-normal leading-4'>Payée le</th>
                  <th className='py-4 text-secondary-1 px-2 text-left text-sm tracking-normal leading-4'>Créée le</th>
                  <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'></th>
                </tr>
              </thead>
              <tbody>
                {factures.map((facture) => (
                  <tr
                    key={facture.token}
                    className=' border-gray-100 border-b hover:bg-gray-100'
                    onDoubleClick={() => navigate(`/entreprise/factures/${facture.token}`)}>
                    <td className='pl-4 pr-2 py-3 text-secondary-1/90 text-xs'>{facture.numero}</td>
                    <td className='px-2 py-3 text-secondary-1 text-sm'>{facture.devis ? facture.devis.numero : ''}</td>
                    <td className='px-4 py-3'>
                      <FormationSessionDisplay formation={facture.formation} groupe={facture.groupe} />
                    </td>
                    <td className='px-2 py-3'>{facture.user && <UserDisplay user={facture.user} />}</td>
                    <td className='px-2 py-3 text-secondary-1 text-xs'>
                      {facture.fichier && <DownloadLink fichier={facture.fichier} titre={facture.titre} />}
                    </td>
                    <td className='px-2 py-3 text-secondary-1/90 text-xs'>
                      {facture.date_limite_paiement
                        ? new Intl.DateTimeFormat('default', {
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                            hour12: false,
                          }).format(new Date(facture.date_limite_paiement as string))
                        : ''}
                    </td>
                    <td className='px-2 py-3 text-secondary-1/90 text-xs'>
                      {facture.date_paiement
                        ? new Intl.DateTimeFormat('default', {
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                            hour12: false,
                          }).format(new Date(facture.date_paiement as string))
                        : ''}
                    </td>
                    <td className='px-2 py-3 text-secondary-1/90 text-xs'>
                      {facture.created_at
                        ? new Intl.DateTimeFormat('default', {
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                            hour12: false,
                          }).format(new Date(facture.created_at as string))
                        : ''}
                    </td>
                    <td className='px-4 py-3 text-secondary-1 text-sm'>
                      <Menu placement='right-start'>
                        <MenuHandler>
                          <Button
                            variant='text'
                            className='w-9 h-9 p-1.5 rounded-full text-gray-500 block mx-auto text-center mr-0 hover:bg-white/100 active:bg-transparent'>
                            <EllipsisVerticalIcon className='h-6 w-6' />
                          </Button>
                        </MenuHandler>
                        <MenuList>
                          <MenuItem onClick={() => navigate(`/entreprise/factures/${facture.token}`)}>Voir</MenuItem>
                        </MenuList>
                      </Menu>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className='text-center px-4 py-7'>Vous n'avez pas encore de facture.</div>
          )}
        </div>
        {apiIsLoading ? <LoadingAbsolute /> : ''}
      </div>
    </Fragment>
  );
};

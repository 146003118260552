import type { FunctionComponent, ReactNode } from 'react';

/**
 * Conteneur principal de la partie front.
 *
 * @param className - stylise la partie principale.
 * @param children - Reeste de la page.
 */
export const Container: FunctionComponent<{ className?: string; children?: ReactNode }> = ({ className, children }) => {
  return (
    <div className={'mx-auto w-full h-full ' + className}>
      <main className='max-w-7xl mx-auto'>
        <div className='justify-center relative max-w-10xl mx-auto px-4 lg:px-8 w-full'>{children}</div>
      </main>
    </div>
  );
};

import React, { FunctionComponent, useEffect, useState } from 'react';

/**
 * Ce composant teste si le mot de passe a le schéma que l'on souhaite.
 *
 * @param password - Mot de passe.
 */
export const PasswordHints: FunctionComponent<{ password: string }> = ({ password }) => {
  /**
   * État local pour stocker le résultat du test avec 8 caractères.
   */
  const [valid8char, setValid8Char] = useState(false);

  /**
   * État local pour stocker le résultat du test avec au moins un caractère majuscule.
   */
  const [validUpper, setValidUpper] = useState(false);

  /**
   * État local pour stocker le résultat du test avec au moins un nombre.
   */
  const [validNumber, setValidNumber] = useState(false);

  /**
   * On effectue les tests à chaque changement de mot de passe.
   */
  useEffect(() => {
    // Par défaut
    setValid8Char(false);
    setValidUpper(false);
    setValidNumber(false);

    // On test si le mot de passe possède au moins 8 caractères.
    if (password.length >= 8) {
      setValid8Char(true);
    }

    // On test si le mot de passe possède au moins une majuscule.
    if (/[A-Z]/.test(password)) {
      setValidUpper(true);
    }

    // On test si le mot de passe possède au moins un nombre.
    if (/[0-9]/.test(password)) {
      setValidNumber(true);
    }
  }, [password]);

  return (
    <div>
      <p className='text-sm'>Votre mot de passe doit contenir au moins :</p>
      <ul className='pl-4 text-sm list-disc'>
        <li className={valid8char ? 'line-through' : ''}>8 caractères</li>
        <li className={validUpper ? 'line-through' : ''}>Une majuscule</li>
        <li className={validNumber ? 'line-through' : ''}>Un chiffre</li>
      </ul>
    </div>
  );
};

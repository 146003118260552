import { FunctionComponent } from 'react';
import { MainMenu } from './MainMenu';
import { FilDariane } from './FilDariane';

/**
 * Encart qui affiche du texte sur la bannière.
 */
export const HeroSections: FunctionComponent = () => {
  return (
    <div
      className='px-0 lg:px-11 pt-3 isolate bg-secondary-1 bg-fixed bg-opacity-70 bg-blend-multiply bg-cover bg-center bg-no-repeat'
      style={{
        backgroundImage: `url("/assets/new-header-accueil-compagnons-du-devoir-formations-courtes.webp")`,
      }}>
      <MainMenu />

      <div className='relative px-4 lg:px-8'>
        <div className='mx-auto max-w-7xl pt-24 pb-20 px-4 lg:px-8'>
          <div>
            <h2 className='text-white text-3xl w-full uppercase mt-11 text-center md:text-left poppins-semibold'>Trouvez votre formation courte</h2>
          </div>
          <FilDariane className='bg-transparent w-full text-white pl-0 !mb-2 mt-2' items={[]} />
        </div>
      </div>
    </div>
  );
};

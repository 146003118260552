import { Link, Navigate } from 'react-router-dom';

import { FunctionComponent, useState } from 'react';
import { Checkbox, Input } from '@material-tailwind/react';
import toast from 'react-hot-toast';
import { useApi } from '../providers/ApiProvider';
import { PasswordHints } from './PasswordHints';
import { PasswordStrength } from './PasswordStrength';
import { RegisterEntreprise } from '../types/auth';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/20/solid';
import { handleError } from '../utils/ErrorHandler';
import { LoadingAbsolute } from './LoadingAbsolute';

/**
 * Formulaire Pour l'inscription de l'entreprise.
 */
export const InscriptionEntreprise: FunctionComponent = () => {
  /**
   * État local de chargement de l'API.
   */
  const [apiIsLoading, setApiIsLoading] = useState(false);

  /**
   * On crée une objet en lecture seule qui contient les valeurs initiales des champs.
   *
   * @constant
   */
  const initialState = {
    nom: { value: '', error: false },
    prenom: { value: '', error: false },
    date_naissance: { value: '', error: false },
    raison_sociale: { value: '', error: false },
    siret: { value: '', error: false },
    email: { value: '', error: false },
    password: { value: '', error: false },
  } as const;

  /**
   * Contient un état général des valeurs des champs du formulaire ainsi que les champs qui ont reçu une erreur lors de l'envoi du formulaire.
   *
   * @defaultValue `initialState`
   */
  const [formInputs, setFormInputs] = useState<{ [K in keyof RegisterEntreprise]: { value: RegisterEntreprise[K]; error: boolean } }>(initialState);

  /**
   * Permet de gérer l'acceptation des condition générales de d'utilisation.
   */
  const [acceptationCgu, setAcceptationCgu] = useState<boolean>(false);

  /**
   * Permet de gérer l'acceptation de l'utilisation des données personnelles.
   */
  const [acceptationRgpd, setAcceptationRgpd] = useState<boolean>(false);

  /**
   * Gère l'appui sur un bouton pour voir le mot de passe.
   */
  const [showedPassword, setShowedPassword] = useState<boolean>(false);

  /**
   * Cette méthode permet de gérer les changements à l'interieur des champs texte du formulaire et de gérer les valeurs des champs via un état local.
   *
   * @param field - Champs du formulaire dont on doit stocker la valeur et s'il est en erreur.
   */
  const handleInputChange = (field: keyof RegisterEntreprise) => {
    return (event: { target: { value: string } }) => {
      setFormInputs((prev) => ({
        ...prev,
        [field]: { value: event.target.value, error: false },
      }));
    };
  };

  /**
   * État local pour gérer le moment où l'enregistrement est effectuer.
   */
  const [registerIsDone, setRegisterIsDone] = useState(false);

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  /**
   * C'est cette méthode qui va faire l'appel pour enregistrer l'utilisateur entreprise.
   *
   * @param event
   */
  const handleRegister = (event: { preventDefault: () => void }) => {
    event.preventDefault();

    if (apiIsLoading === true) {
      return;
    }

    setApiIsLoading(true);

    let ressource: Partial<RegisterEntreprise> = {};
    Object.keys(formInputs).forEach((key) => {
      if (formInputs[key as keyof RegisterEntreprise].value !== null) {
        ressource = { ...ressource, [key]: formInputs[key as keyof RegisterEntreprise].value };
      }
    });

    // Appel effectif
    client
      .post<void, Partial<RegisterEntreprise>>('/entreprise/inscription', ressource)
      .then(() => {
        setApiIsLoading(false);
        setRegisterIsDone(true);
        toast.success('Votre compte a bien été créé. Vous allez recevoir un e-mail afin de finaliser votre inscription.');
      })
      .catch((error) => {
        setApiIsLoading(false);

        handleError(error, () => {
          if (error.response.data.errors) {
            let object = formInputs;
            Object.keys(error.response.data.errors).forEach((key) => {
              if (error.response.data.errors[key as keyof RegisterEntreprise]) {
                object = { ...object, [key]: { value: formInputs[key as keyof RegisterEntreprise].value, error: true } };
              }
            }, formInputs);
            setFormInputs(object);
          }
        });
      });
  };

  if (registerIsDone === false) {
    return (
      <form className='relative mt-6' onSubmit={handleRegister}>
        <h2 className='text-3xl text-center mb-1 space-x-3 poppins-bold text-secondary-2'>Créez votre compte Entreprise</h2>
        <h3 className='text-secondary-2 text-sm poppins-semibold mt-6'>Les informations sur votre entreprise</h3>
        <div className='mt-2'>
          <Input type='text' label='Raison sociale' onChange={handleInputChange('raison_sociale')} error={formInputs.raison_sociale.error} />
        </div>
        <div className='mt-6'>
          <Input type='text' label='N° de Siret' onChange={handleInputChange('siret')} error={formInputs.siret.error} />
        </div>

        <h3 className='text-secondary-2 text-sm poppins-semibold mt-6'>Vos informations générales</h3>
        <div className='mt-2'>
          <Input type='text' label='Prénom du responsable' onChange={handleInputChange('prenom')} error={formInputs.prenom.error} />
        </div>
        <div className='mt-6'>
          <Input type='text' label='Nom du responsable' onChange={handleInputChange('nom')} error={formInputs.nom.error} />
        </div>
        <div className='mt-6'>
          <Input type='date' label='Date de naissance du responsable' onChange={handleInputChange('date_naissance')} error={formInputs.date_naissance.error} />
        </div>

        <div className='mt-6'>
          <Input type='email' label='Adresse e-mail' onChange={handleInputChange('email')} error={formInputs.email.error} />
        </div>
        <div className='mt-6'>
          <Input
            type={showedPassword ? 'text' : 'password'}
            label='Mot de passe'
            onChange={handleInputChange('password')}
            error={formInputs.password.error}
            icon={
              <div onClick={() => setShowedPassword(!showedPassword)}>
                {showedPassword ? <EyeSlashIcon className='w-5 h-5' /> : <EyeIcon className='w-5 h-5' />}
              </div>
            }
          />
          <div className='mt-1'>
            <PasswordStrength password={formInputs.password.value} />
          </div>
          <div className='mt-3'>
            <PasswordHints password={formInputs.password.value} />
          </div>
        </div>
        <div className='-ml-3'>
          <div className='flex flex-row items-center text-sm mt-6'>
            <Checkbox
              checked={acceptationCgu}
              onChange={() => {
                setAcceptationCgu(!acceptationCgu);
              }}
            />
            <span>
              J'accepte les{' '}
              <Link className='text-primary hover:text-secondary-1' to='/conditions-generales' target='_blank'>
                conditions générales d'utilisation
              </Link>
              .
            </span>
          </div>
          <div className='flex flex-row items-start text-sm'>
            <Checkbox
              checked={acceptationRgpd}
              onChange={() => {
                setAcceptationRgpd(!acceptationRgpd);
              }}
            />
            <p className='text-sm pt-3'>
              J'accepte le traitement de mes données personnelles par les Compagnons du Devoir et du Tour de France.
              <br />
              <Link className='text-primary hover:text-secondary-1' to='/conditions-generales' target='_blank'>
                En savoir plus
              </Link>
              .
            </p>
          </div>
        </div>
        <div className='mt-6'>
          <button
            type='submit'
            className='poppins-semibold w-full px-2 py-1 rounded-md bg-primary text-white  disabled:bg-primary/50 uppercase'
            disabled={!acceptationCgu || !acceptationRgpd}>
            S'inscrire
          </button>
        </div>
        {apiIsLoading ? <LoadingAbsolute /> : ''}
      </form>
    );
  } else {
    return <Navigate to='/connexion' />;
  }
};

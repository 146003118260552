import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { Button } from '@material-tailwind/react';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingAbsolute } from '../../../components/LoadingAbsolute';
import { useApi } from '../../../providers/ApiProvider';
import { HeaderForm } from '../../../components/HeaderForm';
import { GoodApiResponse, ErrorApiResponse } from '../../../types/api';
import { Helmet } from 'react-helmet';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { Config } from '../../../config/config';
import { handleError } from '../../../utils/ErrorHandler';
import { Facture } from '../../../types/AdminRessources';
import nl2br from 'react-nl2br';
import { ModalMarquerFactureCommePayee } from '../../../components/Modales/Admin/ModalMarquerFactureCommePayee';
import toast from 'react-hot-toast';
import { ModalMarquerAussiDemandePayee } from '../../../components/Modales/Admin/ModalMarquerAussiDemandePayee';
import { Tab, TabPanel, Tabs, TabsBody, TabsHeader } from '../../../components/Onglets';

import { FormationSessionDisplay } from '../../../components/FormationSessionDisplay';
import { UserWithMailDisplay } from '../../../components/UserWithMailDisplay';
import { DownloadLink } from '../../../components/DownloadLink';

/**
 * Ce composant doit afficher la facture identifié par son id.
 */
export const AdminFactureScreen: FunctionComponent = () => {
  // On initialise les données dont on va avoir besoin sur cette page

  /**
   * On stocke si on est en train d'utiliser l'API ou non.
   */
  const [apiIsLoading, setApiIsLoading] = useState(false);

  /**
   * Permet de stocker les informations relatives à la facture.
   */
  const [facture, setFacture] = useState<Facture | null>(null);

  /**
   * Permet d'ouvrir pour mentionner la date de paiement de la facture.
   */
  const [modalMarquerCommePayeeEstVisible, setModalMarquerCommePayeeEstVisible] = useState<boolean>(false);

  /**
   * Permet d'ouvrir pour mentionner la date de paiement de la facture.
   */
  const [modalMarquerDemandeCommePayeeEstVisible, setModalMarquerDemandeCommePayeeEstVisible] = useState<boolean>(false);

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  /**
   * Permet au composant de changer de page.
   */
  const navigate = useNavigate();

  /**
   * On a besoin des paramètres pour avoir l'id du devis.
   */
  const params = useParams();

  /**
   * Permet de recharger les données au chargement de la page.
   */
  useEffect(() => {
    handleShow();
  }, [client]);

  /**
   * Retrouve par le réseau la facture et la met dans un état local.
   */
  const handleShow = () => {
    if (apiIsLoading === true) {
      return;
    }

    setApiIsLoading(true);
    client
      .get<GoodApiResponse<Facture>>(`/admin/factures/${params.id}`)
      .then((response) => {
        setFacture(response.data.data as unknown as Facture);
      })
      .catch((error: ErrorApiResponse<Facture>) => {
        handleError(error, () => {
          navigate('/admin/factures');
        });
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Envoie la date de paiement.
   */
  const handlePaiement = (datePaiement: Facture['date_paiement']) => {
    setApiIsLoading(true);

    client
      .patch<GoodApiResponse>(`/admin/factures/${params.id}/paye`, {
        date_paiement: datePaiement,
      })
      .then(() => {
        setModalMarquerCommePayeeEstVisible(false);
        toast.success(`La facture à bien été marquée comme réglée`);
        handleShow();
        if (facture && facture.demande) {
          setModalMarquerDemandeCommePayeeEstVisible(true);
        }
      })
      .catch((error) => handleError(error))
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Contenu des onglets.
   * @constant
   */
  const data = [
    {
      label: 'Général',
      value: 'general',
      icone: <CheckCircleIcon className='w-5 h-5 flex-none' />,
    },
  ] as const;

  if (facture === null) {
    return <Fragment>Chargement...</Fragment>;
  }

  return (
    <Fragment>
      <Helmet>
        <title>Facture - {Config.app_label}</title>
      </Helmet>
      <HeaderForm text={`Facture ` + facture.numero} returnUrl='/admin/factures' />
      <Tabs value='general'>
        <TabsHeader>
          {data.map(({ label, value, icone }) => (
            <Tab key={value} value={value}>
              {icone}
              <span>{label}</span>
            </Tab>
          ))}
        </TabsHeader>
        <TabsBody>
          <TabPanel value='general'>
            <form className='relative'>
              <div className='bg-white p-5 rounded-b-lg'>
                <div className='container mx-auto bg-white rounded-none'>
                  <fieldset className='flex flex-col md:flex-row gap-3 pb-7 border-b mb-7'>
                    <div className='basis-3/5 md:border-r border-secondary-1/30'>
                      <div className='mb-6'>
                        <legend className='text-secondary-1 poppins-semibold text-lg mb-3'>Informations</legend>
                        <div className='text-secondary-1'>N° {facture.numero}</div>
                        <div className='text-secondary-1'>
                          {`Émise le ${new Intl.DateTimeFormat('default', {
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                            hour12: false,
                          }).format(new Date(facture.created_at as string))}`}
                        </div>
                        {facture.date_limite_paiement && (
                          <div className='text-secondary-1'>
                            {`Date limite de paiement : ${new Intl.DateTimeFormat('default', {
                              year: 'numeric',
                              month: 'numeric',
                              day: 'numeric',
                              hour12: false,
                            }).format(new Date(facture.date_limite_paiement as string))}`}
                          </div>
                        )}
                        {facture.date_paiement && (
                          <div className='text-secondary-1'>
                            {`Date de paiement : ${new Intl.DateTimeFormat('default', {
                              year: 'numeric',
                              month: 'numeric',
                              day: 'numeric',
                              hour12: false,
                            }).format(new Date(facture.date_paiement as string))}`}
                          </div>
                        )}
                        {facture.date_relance1 && (
                          <div className='text-secondary-1'>
                            {`Date de première relance : ${new Intl.DateTimeFormat('default', {
                              year: 'numeric',
                              month: 'numeric',
                              day: 'numeric',
                              hour12: false,
                            }).format(new Date(facture.date_relance1 as string))}`}
                          </div>
                        )}
                        {facture.date_relance2 && (
                          <div className='text-secondary-1'>
                            {`Date de deuxième relance : ${new Intl.DateTimeFormat('default', {
                              year: 'numeric',
                              month: 'numeric',
                              day: 'numeric',
                              hour12: false,
                            }).format(new Date(facture.date_relance2 as string))}`}
                          </div>
                        )}
                        {facture.date_relance3 && (
                          <div className='text-secondary-1'>
                            {`Date de troisième relance : ${new Intl.DateTimeFormat('default', {
                              year: 'numeric',
                              month: 'numeric',
                              day: 'numeric',
                              hour12: false,
                            }).format(new Date(facture.date_relance3 as string))}`}
                          </div>
                        )}
                        {!facture.date_paiement && (
                          <Button
                            variant='filled'
                            className='flex flex-row items-center rounded-full'
                            size='sm'
                            onClick={() => setModalMarquerCommePayeeEstVisible(true)}>
                            Marquer comme payée
                          </Button>
                        )}
                      </div>
                      {facture.formation && (
                        <Fragment>
                          <legend className='text-secondary-1 poppins-semibold text-lg mb-3'>Formation</legend>
                          <FormationSessionDisplay formation={facture.formation} groupe={facture.groupe} />
                        </Fragment>
                      )}
                    </div>
                    <div className='text-sm'>
                      <div className='pb-2'>{facture.fichier && <DownloadLink fichier={facture.fichier} titre={facture.titre} />}</div>
                      <div>{nl2br(facture.description)}</div>
                    </div>
                  </fieldset>
                  <fieldset className='pb-2'>
                    <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2'>
                      {facture.user && (
                        <div className='mt-7 lg:mt-0'>
                          <legend className='text-secondary-1 poppins-semibold text-lg mb-3'>Salarié</legend>
                          <UserWithMailDisplay user={facture.user} isAdmin={true} />
                        </div>
                      )}
                    </div>
                  </fieldset>
                </div>
              </div>
            </form>
          </TabPanel>
        </TabsBody>
      </Tabs>
      {apiIsLoading ? <LoadingAbsolute /> : ''}
      <ModalMarquerFactureCommePayee
        visible={modalMarquerCommePayeeEstVisible}
        setVisible={setModalMarquerCommePayeeEstVisible}
        apiIsLoading={apiIsLoading}
        handlePaiement={handlePaiement}
      />
      {facture.demande && (
        <ModalMarquerAussiDemandePayee
          visible={modalMarquerDemandeCommePayeeEstVisible}
          setVisible={setModalMarquerDemandeCommePayeeEstVisible}
          handleShow={handleShow}
          demandeId={facture.demande.id}
        />
      )}
    </Fragment>
  );
};

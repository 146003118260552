import { type FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

/**
 * Ce composant affiche le pied de la page, côté Front.
 */
export const FooterLegal: FunctionComponent = () => {
  return (
    <div className='bg-secondary-2'>
      <div className='flex flex-col justify-center sm:flex-row px-4 py-7 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 text-center md:text-left'>
        <p className='text-sm text-white tracking-wider'>
          © {new Date().getFullYear()} - Les Compagnons du Devoir - Tous droits réservés |{' '}
          <Link to='/mentions-legales' className='montserrat-semibold'>
            Mentions légales
          </Link>{' '}
          |{' '}
          <Link to='/conditions-generales' className='montserrat-semibold'>
            Conditions générales
          </Link>
        </p>
      </div>
    </div>
  );
};

import { Dialog, DialogHeader, DialogBody, DialogFooter, Button } from '@material-tailwind/react';
import { FunctionComponent } from 'react';

/**
 * Ce composant permet d'afficher une Modale pour que le dirigeant puisse refuser un devis.
 *
 * @param visible - Précise si la modale et ouverte ou non. Provient du parent.
 * @param setVisible - Accesseur qui modifie la visibilité de la modale. Provient du parent.
 * @param handleRefuse - Callback qui permet de refuser un devis. Provient du parent.
 */
export const ModalRefusDevisAdmin: FunctionComponent<{
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  handleRefuse: () => void;
}> = ({ visible, setVisible, handleRefuse }) => {
  return (
    <Dialog open={visible} size='xs' handler={setVisible}>
      <DialogHeader>Refus du devis</DialogHeader>
      <DialogBody divider>
        Ceci refusera le devis en lieu et place du dirigeant de l'entreprise. Vous devez avoir son autorisation pour continuer. Confirmer ?
      </DialogBody>
      <DialogFooter>
        <Button variant='text' color='gray' onClick={() => setVisible(false)} className='mr-1 !rounded-full'>
          Annuler
        </Button>
        <Button
          variant='filled'
          color='orange'
          onClick={() => {
            handleRefuse();
            setVisible(false);
          }}>
          Confirmer
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

import { Dialog, DialogHeader, DialogBody, DialogFooter, Button } from '@material-tailwind/react';
import { FunctionComponent } from 'react';

/**
 * Ce composant permet d'afficher une Modale pour accepter un devis, côté Admin.
 *
 * @param visible - Précise si la modale et ouverte ou non. Provient du parent.
 * @param setVisible - Accesseur qui modifie la visibilité de la modale. Provient du parent.
 * @param handleAccepte - Callback qui réalise l'appel pour accepter le devis.
 */
export const ModalAcceptationDevisAdmin: FunctionComponent<{
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  handleAccepte: () => void;
}> = ({ visible, setVisible, handleAccepte }) => {
  return (
    <Dialog open={visible} size='xs' handler={setVisible}>
      <DialogHeader>Acceptation du devis</DialogHeader>
      <DialogBody divider>
        Ceci acceptera le devis en lieu et place du dirigeant de l'entreprise. Vous devez avoir son autorisation pour continuer. Confirmer ?
      </DialogBody>
      <DialogFooter>
        <Button variant='text' color='gray' onClick={() => setVisible(false)} className='mr-1 !rounded-full'>
          Annuler
        </Button>
        <Button
          variant='filled'
          color='orange'
          onClick={() => {
            handleAccepte();
            setVisible(false);
          }}>
          Confirmer
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

import { Fragment, FunctionComponent } from 'react';
import { StatutAffiliationEnum } from '../types/auth';

/**
 * Ce composant affiche les statuts d'affiliation de manière stylisé.
 *
 * @param text - Statut d'affiliation de l'utilisateur.
 */
export const ConstStatutAffiliation: FunctionComponent<{ text: StatutAffiliationEnum }> = ({ text }) => {
  /**
   * @var Texte transformé.
   * @defaultValue `''`.
   */
  let translatedText = '';

  switch (text) {
    case StatutAffiliationEnum.affilie:
      translatedText = 'Affilié';
      break;
    case StatutAffiliationEnum.suspendu:
      translatedText = 'En pause';
      break;
    case StatutAffiliationEnum.revoque:
      translatedText = 'Révoqué';
      break;
    default:
      translatedText = text;
  }

  return <Fragment>{translatedText}</Fragment>;
};

import { Fragment, FunctionComponent, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { LoadingAbsolute } from '../../../components/LoadingAbsolute';
import { RessourceList } from '../../../components/RessourceList';
import { Config } from '../../../config/config';
import { useApi } from '../../../providers/ApiProvider';
import { Ressource, ForeignRessources } from '../../../types';
import { PaginatedApiResponse, IndexParams, ErrorApiResponse } from '../../../types/api';
import { RessourceListColumn, SortDirection } from '../../../types/ressourceList';
import { DiplomePrepare } from '../../../types/schematics';
import { handleError } from '../../../utils/ErrorHandler';

/**
 * Ce composant contient la liste de ressource ainsi que les méthode pour gérer cette liste.
 */
export const AdminDiplomePrepareListScreen: FunctionComponent = () => {
  /**
   * On stocke si on est en train d'utiliser l'API ou non.
   */
  const [apiIsLoading, setApiIsLoading] = useState(false);

  /**
   * Contient une liste de ressources. Ces ressource arrivent par le réseau.
   */
  const [paginatedApiData, setPaginatedApiData] = useState<PaginatedApiResponse<DiplomePrepare> | null>(null);

  /**
   * Contient une liste de colonnes pour le tableau. Ces ressource arrivent par le réseau.
   */
  const [apiColumns, setApiColumns] = useState<RessourceListColumn<DiplomePrepare>[] | null>(null);

  /**
   * Contient une liste de clées étrangères avec le libellé à afficher. Ces ressource arrivent par le réseau.
   */
  const [apiForeigns, setApiForeigns] = useState<ForeignRessources | null>(null);

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  /**
   * On aura besoin que des méthode nous fasse voyager.
   */
  const navigate = useNavigate();

  /**
   * Retrouve des données paginées de L'API et les stocke dans un état local.
   *
   * @param indexParams - Paramètres de la recherche.
   */
  const handleIndex = (indexParams?: Partial<IndexParams>) => {
    setApiIsLoading(true);
    client
      .get<PaginatedApiResponse<DiplomePrepare>, IndexParams>('/admin/diplomes-prepares', { params: indexParams })
      .then((reponse) => {
        setPaginatedApiData(reponse.data);
        if (apiColumns === null) {
          setApiColumns(reponse.data.columns as RessourceListColumn<DiplomePrepare>[]);
        }
        if (apiForeigns === null) {
          setApiForeigns(reponse.data.foreigns as ForeignRessources);
        }
      })
      .catch((error: ErrorApiResponse<DiplomePrepare>) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Cette méthode envoie l'utilisateur sur un formulaire pour consulter la ressource ciblé avec son ID.
   * @param ressourceId - ID de la ressource à consulter.
   */
  const handleRead = (ressourceId: Ressource['id']) => {
    navigate(`/admin/diplomes-prepares/editer/${ressourceId}`);
  };

  return (
    <Fragment>
      <Helmet>
        <title>Diplômes préparés - {Config.app_label}</title>
      </Helmet>
      <div className='relative'>
        <RessourceList
          name={'diplome-prepare'}
          labels={'Diplômes préparés'}
          defaultSortBy={'id'}
          defaultSortDirection={SortDirection.ASC}
          paginatedApiData={paginatedApiData as PaginatedApiResponse<Ressource>}
          columns={apiColumns as RessourceListColumn[]}
          foreigns={apiForeigns as ForeignRessources}
          handleIndex={handleIndex}
          onRead={handleRead}
        />
        {apiIsLoading ? <LoadingAbsolute /> : ''}
      </div>
    </Fragment>
  );
};

import { AcademicCapIcon } from '@heroicons/react/24/outline';
import { Fragment, FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { DashboardStatsPopulaires } from '../../types/AdminRessources';

/**
 * Ce composant est un bandeau côté admin qui liste les formations les plus populaires sur les 30 derniers jours.
 *
 * @param formationsPopulaires - Les 5 formations les plus populaires.
 */
export const DashboardAdminFormationsPopulaires: FunctionComponent<{ formationsPopulaires: DashboardStatsPopulaires[] }> = ({ formationsPopulaires }) => {
  return (
    <Fragment>
      {formationsPopulaires && formationsPopulaires.length ? (
        <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5 mb-11'>
          {formationsPopulaires.map((formationPopulaire) => (
            <Link
              key={formationPopulaire.id}
              to={`/admin/formations/editer/${formationPopulaire.id}`}
              target='blank'
              className='bg-white rounded-md shadow-md hover:shadow-lg transition hover:scale-105 py-3 px-4'>
              <div className='flex flex-row gap-2'>
                <div className={`rounded-lg shadow-md object-cover w-10 h-10 bg-gray-700 flex items-center justify-center aspect-1`}>
                  <AcademicCapIcon className='h-7 w-7 text-white/50' />
                </div>
                <div className='overflow-hidden'>
                  <h6 className='montserrat-semibold text-secondary-1 truncate leading-4'>{formationPopulaire.nom}</h6>
                  <span className='text-xs text-secondary-1/90'>
                    {formationPopulaire.compte} demande{formationPopulaire.compte > 1 ? 's' : ''}
                  </span>
                </div>
              </div>
            </Link>
          ))}
        </div>
      ) : (
        <div className='text-center text-secondary-1/90'>
          <span>Retrouvez ici prochainement les formations populaires.</span>
        </div>
      )}
    </Fragment>
  );
};

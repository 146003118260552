import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { ArrowRightIcon } from '@heroicons/react/24/outline';
import { Fragment, type FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { useUser } from '../providers/UserProvider';
import { TypeProfilEnum } from '../types/auth';

/**
 * Ce composant est une partie de la fiche formation.
 */
export const CtaHomeEspaceClient: FunctionComponent = () => {
  /**
   * On a besoin des informations de l'utilisateur et du profil courant de celui-ci.
   */
  const { user, profil } = useUser();

  return (
    <div
      className='px-11 isolate bg-secondary-1 bg-fixed bg-opacity-80 bg-blend-multiply bg-cover bg-center bg-no-repeat'
      style={{
        backgroundImage: `url("/assets/new-espace-client.webp")`,
      }}>
      <div className='mx-auto max-w-7xl py-14 px-0 md:px-4 lg:px-8'>
        <h2 className='font text-2xl poppins-bold tracking-tight text-white mb-4'>Votre espace client</h2>
        <div className='rounded-md bg-white p-8'>
          {user === null && (
            <Fragment>
              <h3 className='text-2xl poppins-bold tracking-tight text-secondary-1'>Pourquoi créer un compte client&nbsp;?</h3>
              <div className='mt-2 flex flex-row justify-start gap-4'>
                <ul>
                  <li className='flex flex-row items-start gap-4'>
                    <div className='flex-none pt-[2px]'>
                      <div className='rounded-full bg-primary'>
                        <ChevronRightIcon className='w-5 h-5 text-white' />
                      </div>
                    </div>
                    <div className='grow text-gray-600'>
                      <span className='montserrat-semibold'>Pour consulter les formations</span>{' '}
                      <span>dans leur intégralité et connaître les sessions à venir.</span>
                    </div>
                  </li>
                  <li className='flex flex-row items-start gap-4 mt-3'>
                    <div className='flex-none pt-[2px]'>
                      <div className='rounded-full bg-primary'>
                        <ChevronRightIcon className='w-5 h-5 text-white' />
                      </div>
                    </div>
                    <div className='grow text-gray-600'>
                      <span className='montserrat-semibold'>Pour avoir un espace dédié</span>{' '}
                      <span>à votre projet professionnel, ou à votre entreprise, et tous les outils nécessaires à chaque étape de votre parcours.</span>
                    </div>
                  </li>
                  <li className='flex flex-row items-start gap-4 mt-3'>
                    <div className='flex-none pt-[2px]'>
                      <div className='rounded-full bg-primary'>
                        <ChevronRightIcon className='w-5 h-5 text-white' />
                      </div>
                    </div>
                    <div className='grow text-gray-600'>
                      <span className='montserrat-semibold'>Pour vous inscrire à une formation</span> <span>et suivre votre dossier en temps réel.</span>
                    </div>
                  </li>
                </ul>
              </div>
              <div className='mt-4 flex flex-col md:flex-row justify-start gap-4'>
                <Link
                  to='/inscription'
                  className='inline-flex items-center justify-center rounded-full border border-transparent bg-primary px-3 text-sm poppins-semibold text-white'>
                  Créer son compte
                  <ArrowRightIcon className=' pl-3 w-8 h-8' />
                </Link>
                <Link
                  to='/connexion'
                  className='inline-flex items-center justify-center rounded-full border border-primary bg-white px-3 text-sm poppins-semibold text-primary hover:bg-primary/10'>
                  Se connecter
                  <ArrowRightIcon className=' pl-3 w-8 h-8' />
                </Link>
              </div>
            </Fragment>
          )}
          {user !== null && (
            <Fragment>
              <div className='mt-2 flex flex-row justify-start gap-4'>
                <ul>
                  {profil !== null && (profil.type_profil === TypeProfilEnum.dirigeant || profil.type_profil === TypeProfilEnum.delegue) && (
                    <Fragment>
                      <li className='flex flex-row items-start gap-4'>
                        <div className='flex-none pt-[2px]'>
                          <div className='rounded-full bg-primary'>
                            <ChevronRightIcon className='w-5 h-5 text-white' />
                          </div>
                        </div>
                        <div className='grow text-gray-600'>
                          <span className='montserrat-semibold'>Vous disposez d'un espace dédié</span>{' '}
                          <span>à votre entreprise, et tous les outils nécessaires à chaque étape du parcours de vos salarié(e)s.</span>
                        </div>
                      </li>
                      <li className='flex flex-row items-start gap-4 mt-3'>
                        <div className='flex-none pt-[2px]'>
                          <div className='rounded-full bg-primary'>
                            <ChevronRightIcon className='w-5 h-5 text-white' />
                          </div>
                        </div>
                        <div className='grow text-gray-600'>
                          <span className='montserrat-semibold'>Inscrivez vos salarié(e)s à une formation</span>{' '}
                          <span>et suivez leurs dossiers en temps réel.</span>
                        </div>
                      </li>
                    </Fragment>
                  )}
                  {(profil === null || (profil !== null && profil.type_profil === TypeProfilEnum.salarie)) && (
                    <Fragment>
                      <li className='flex flex-row items-start gap-4'>
                        <div className='flex-none pt-[2px]'>
                          <div className='rounded-full bg-primary'>
                            <ChevronRightIcon className='w-5 h-5 text-white' />
                          </div>
                        </div>
                        <div className='grow text-gray-600'>
                          <span className='montserrat-semibold'>Vous disposez d'un espace dédié</span>{' '}
                          <span>à votre projet professionnel et tous les outils nécessaires à chaque étape de votre parcours.</span>
                        </div>
                      </li>
                      <li className='flex flex-row items-start gap-4 mt-3'>
                        <div className='flex-none pt-[2px]'>
                          <div className='rounded-full bg-primary'>
                            <ChevronRightIcon className='w-5 h-5 text-white' />
                          </div>
                        </div>
                        <div className='grow text-gray-600'>
                          <span className='montserrat-semibold'>Inscrivez-vous à une formation</span> <span>et suivez votre dossier en temps réel.</span>
                        </div>
                      </li>
                    </Fragment>
                  )}
                </ul>
              </div>
              <div className='mt-4 flex flex-col md:flex-row justify-start gap-4'>
                <Link
                  to='/mon-espace'
                  className='inline-flex items-center justify-center rounded-full border border-primary bg-white px-3 text-sm poppins-semibold text-primary hover:bg-primary/10'>
                  Mon espace
                  <ArrowRightIcon className=' pl-3 w-8 h-8' />
                </Link>
              </div>
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

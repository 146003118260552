import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import toast from 'react-hot-toast';
import { ConstStatutAffiliation } from '../../../components/ConstStatutAffiliation';
import { LoadingAbsolute } from '../../../components/LoadingAbsolute';
import { Config } from '../../../config/config';
import { useApi } from '../../../providers/ApiProvider';
import { GoodApiResponse, ErrorApiResponse } from '../../../types/api';
import { ProfilEntreprise, StatutAffiliationEnum, TypeProfilEnum } from '../../../types/auth';
import { ConstTypeProfil } from '../../../components/ConstTypeProfil';
import { ModalEntrepriseInvitation } from '../../../components/Modales/Entreprise/ModalEntrepriseInvitation';
import PlusIcon from '@heroicons/react/20/solid/PlusIcon';
import { Avatar } from '../../../components/Avatar';
import { StatutAffiliation, TypeProfil } from '../../../types/schematics';
import { InputColor } from '../../../components/Inputs/InputColor';
import { handleError } from '../../../utils/ErrorHandler';
import { AlertInfo } from '../../../components/Alertes';

/**
 * Ce commposant affiche la page des comptes de l'entreprise
 */
export const EntrepriseComptesScreen: FunctionComponent = () => {
  /**
   * On stocke si on est en train d'utiliser l'API ou non.
   */
  const [apiIsLoading, setApiIsLoading] = useState(false);

  /**
   * On stocke les profils possédés par l'entreprise, les salariés.
   */
  const [profils, setProfils] = useState<ProfilEntreprise[] | null>(null);

  /**
   * Permet d'ouvrir une modale lorsque l'on souhaite supprimer une ressource.
   */
  const [modalEstVisible, setModalEstVisible] = useState<boolean>(false);

  /**
   * Cet état local gère le système anti-rebond  du selecteur de couleur
   */
  const [apiTimeout, setApiTimeout] = useState<NodeJS.Timeout>();

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  /**
   * Charge les données des comptes liés à l'entreprise au chargenment du composant.
   */
  useEffect(() => {
    handleIndex();
  }, [client]);

  /**
   * Retrouve les comptes de liés à l'entreprise de L'API et les stocke dans un état local.
   */
  const handleIndex = () => {
    setApiIsLoading(true);

    client
      .get<GoodApiResponse<ProfilEntreprise[]>>('/entreprise/comptes')
      .then((response) => {
        setProfils(response.data.data);
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Cette méthode change le type de profil pour un profil donné.
   *
   * @param profilId - Profil dont on change le type.
   * @param value - Nouvelle valeur pour le type de profil.
   */
  const handleTypeProfilChange = (profilId: ProfilEntreprise['id'], value: TypeProfil['nom']) => {
    setApiIsLoading(true);

    client
      .patch(`/entreprise/comptes/${profilId}/type-profil/${value}`)
      .then(() => {
        toast.success(`Fonction modifiée`);
        handleIndex();
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Cette méthode change le statut d'affiliation pour un profil donné.
   *
   * @param profilId - Profil dont on change le type.
   * @param value - Nouvelle valeur pour le statut d'affiliation.
   */
  const handleStatutAffiliationChange = (profilId: ProfilEntreprise['id'], value: StatutAffiliation['nom']) => {
    setApiIsLoading(true);

    client
      .patch(`/entreprise/comptes/${profilId}/statut-affiliation/${value}`)
      .then(() => {
        toast.success(`Statut affiliation modifié`);
        handleIndex();
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Cette méthode change la couleur pour un profil donné.
   *
   * @param profilId - Profil dont on change le type.
   * @param value - Nouvelle valeur pour la couleur.
   */
  const handleColorChange = (profilId: ProfilEntreprise['id'], value: ProfilEntreprise['couleur']) => {
    clearTimeout(apiTimeout);

    setApiTimeout(
      setTimeout(() => {
        setApiIsLoading(true);

        client
          .patch(`/entreprise/comptes/${profilId}/couleur/${value.replace('#', '')}`)
          .then(() => {
            toast.success(`Couleur modifiée`);
            handleIndex();
          })
          .catch((error: ErrorApiResponse) => {
            handleError(error);
          })
          .finally(() => {
            setApiIsLoading(false);
          });
      }, 500),
    );
  };

  /**
   * Rend la modale visible
   */
  const handleModal = () => {
    setModalEstVisible(true);
  };

  if (profils === null) {
    return <Fragment>Chargement...</Fragment>;
  }

  return (
    <Fragment>
      <Helmet>
        <title>Salariés - {Config.app_label}</title>
      </Helmet>
      <div className='px-4 py-4 md:py-3 bg-gray-100 rounded-tl-lg rounded-tr-lg flex flex-col sm:flex-row gap-7 items-center'>
        <div className='sm:flex items-center gap-4 justify-between w-full'>
          <h1 className='text-base sm:text-lg md:text-xl poppins-bold leading-normal text-secondary-1'>Comptes</h1>
          <button
            className='mr-1 py-1 px-6 flex flex-row items-center rounded-full bg-primary text-white'
            onClick={() => {
              handleModal();
            }}>
            <PlusIcon className='h-4 w-4 inline mr-2 flex-none' />
            <span>Inviter</span>
          </button>
        </div>
      </div>
      <div>
        <div className='bg-white w-full overflow-x-auto rounded-b-lg'>
          <div className='m-4'>
            <AlertInfo text="Modifier la fonction ou le statut d'un membre lui enverra un mail pour l'informer du changement." />
          </div>
          {profils.length ? (
            <table className='table-fixed min-w-[1000px] w-full bg-white rounded-b-lg'>
              <thead className='text-sm text-gray-500 text-left border-b'>
                <tr>
                  <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Salarié</th>
                  <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>E-mail</th>
                  <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Fonction</th>
                  <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Statut</th>
                  <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4 w-[120px]'>Couleur</th>
                  <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4 w-[160px]'>Date d'affiliation</th>
                </tr>
              </thead>
              <tbody>
                {profils.map((profil) => (
                  <tr key={profil.id} className=' border-gray-100 border-b hover:bg-gray-100'>
                    <td className='px-4 py-3 text-gray-800 text-sm flex flex-row gap-2 items-center'>
                      <div className='flex-none'>
                        <Avatar src={profil.user.image_avatar} />
                      </div>
                      <span className='text-secondary-1 poppins'>{`${profil.user.prenom} ${profil.user.nom}`}</span>
                    </td>
                    <td className='px-4 py-3 text-gray-800 text-sm'>
                      <a href={'mailto:' + profil.user.email}>{profil.user.email}</a>
                    </td>
                    <td className='px-4 py-3 text-gray-800 text-sm'>
                      <select
                        className={`bg-white relative w-full px-4 py-2 text-xs leading-none text-left text-secondary-1 border border-gray-400 rounded outline-none focus:border-secondary-2`}
                        value={profil.type_profil}
                        onChange={(e) => handleTypeProfilChange(profil.id, e.target.value)}>
                        <option value={TypeProfilEnum.delegue}>
                          <ConstTypeProfil text={TypeProfilEnum.delegue} />
                        </option>
                        <option value={TypeProfilEnum.dirigeant}>
                          <ConstTypeProfil text={TypeProfilEnum.dirigeant} />
                        </option>
                        <option value={TypeProfilEnum.salarie}>
                          <ConstTypeProfil text={TypeProfilEnum.salarie} />
                        </option>
                      </select>
                    </td>
                    <td className='px-4 py-3 text-gray-800 text-sm'>
                      <select
                        className={`bg-white relative w-full px-4 py-2 text-xs leading-none text-left text-secondary-1 border border-gray-400 rounded outline-none focus:border-secondary-2`}
                        value={profil.statut_affiliation}
                        onChange={(e) => handleStatutAffiliationChange(profil.id, e.target.value)}>
                        <option value={StatutAffiliationEnum.affilie}>
                          <ConstStatutAffiliation text={StatutAffiliationEnum.affilie} />
                        </option>
                        <option value={StatutAffiliationEnum.revoque}>
                          <ConstStatutAffiliation text={StatutAffiliationEnum.revoque} />
                        </option>
                      </select>
                    </td>
                    <td className='px-4 py-3 text-gray-800 text-sm'>
                      {<InputColor value={profil.couleur} onChange={(e) => handleColorChange(profil.id, e.target.value)} />}
                    </td>
                    <td className='px-4 py-3 poppins text-gray-800 text-sm'>
                      {profil.created_at
                        ? new Intl.DateTimeFormat('default', {
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                            hour12: false,
                          }).format(new Date(profil.created_at as string))
                        : ''}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className='text-center px-4 py-7'>Vous n'avez pas encore de compte.</div>
          )}
        </div>
        <ModalEntrepriseInvitation visible={modalEstVisible} setVisible={setModalEstVisible} handleIndex={handleIndex} />
        {apiIsLoading ? <LoadingAbsolute /> : ''}
      </div>
    </Fragment>
  );
};

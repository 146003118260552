import { Dialog, DialogHeader, DialogBody, DialogFooter, Button } from '@material-tailwind/react';
import { FunctionComponent, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useApi } from '../../../providers/ApiProvider';
import { Convocation, Entreprise } from '../../../types/AdminRessources';
import { GoodApiResponse, ErrorApiResponse } from '../../../types/api';
import { EntrepriseInfo } from '../../../types/auth';
import { Demande } from '../../../types/EntreprisesRessources';
import { handleError } from '../../../utils/ErrorHandler';
import { AlertError } from '../../Alertes';
import { InputDate } from '../../Inputs/InputDate';
import { InputFile } from '../../Inputs/InputFile';
import { InputTextarea } from '../../Inputs/InputTextarea';
import { LoadingAbsolute } from '../../LoadingAbsolute';
import { FormationSessionDisplay } from '../../FormationSessionDisplay';
import { UserDisplay } from '../../UserDisplay';

/**
 * Ce composant permet d'afficher une Modale pour que les admins puissent ajouter une convocation.
 *
 * @param visible - Précise si la modale et ouverte ou non. Provient du parent.
 * @param setVisible - Accesseur qui modifie la visibilité de la modale. Provient du parent.
 * @param entrepriseId - Id de l'entreprise pour laquelle on veiut ajouter un devis. Provient du parent.
 * @param handleShow - Callback qui réalise l'appel pour recharger les données de la demande. Provient du parent.
 */
export const ModalAjouterConvocationEntreprise: FunctionComponent<{
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  entrepriseId: Entreprise['id'];
  handleShow: (entrepriseId: Entreprise['id']) => void;
}> = ({ visible, setVisible, entrepriseId, handleShow }) => {
  /**
   * On stocke si on est en train d'utiliser l'API ou non.
   */
  const [apiIsLoading, setApiIsLoading] = useState(false);

  /**
   * Permet de donner un titre au document.
   */
  const [titre, setTitre] = useState<Convocation['titre']>('');

  /**
   * Fichier à envoyer.
   */
  const [fichier, setFichier] = useState<Convocation['fichier']>('');

  /**
   * Description du fichier, optionnel.
   */
  const [description, setDescription] = useState<Convocation['description']>('');

  /**
   * Date de la convocation
   */
  const [dateConvocation, setDateConvocation] = useState<Convocation['date_convocation']>('');

  /**
   * Liste des demandes
   */
  const [demandes, setDemandes] = useState<Demande[] | null>(null);

  /**
   * Demande sélectionnée
   */
  const [demandeId, setDemandeId] = useState<Demande['id'] | null>(null);

  /**
   * On stocke l'eventuelle erreur.
   */
  const [error, setError] = useState<string | null>(null);

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  /**
   * Permet de réinitialiser les champs de la modale à chaque fois qu'on l'ouvre où qu'on la ferme.
   */
  useEffect(() => {
    setTitre('');
    setFichier('');
    setDescription('');
    setDateConvocation('');
    setError(null);
  }, [visible]);

  /**
   * Permet de rafraichir la liste des demandes, lorsque l'on change de profil.
   */
  useEffect(() => {
    if (visible) {
      handleListeDemandes();
    }
  }, [visible]);

  /**
   * Cette méthode permet d'obtenir la liste des demandes
   */
  const handleListeDemandes = () => {
    setApiIsLoading(true);
    client
      .get<GoodApiResponse<Demande[]>>('/admin/demandes', { params: { entreprise_id: entrepriseId } })
      .then((response) => {
        setDemandes(response.data.data as unknown as (Demande & { id: number; entreprise: EntrepriseInfo })[]);
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Envoie le fichier par l'API.
   */
  const handleEnvoie = () => {
    setApiIsLoading(true);

    client
      .post<GoodApiResponse>(`/admin/convocations`, {
        titre: titre,
        fichier: fichier,
        description: description,
        date_convocation: dateConvocation,
        demande_id: demandeId,
        entreprise_id: entrepriseId,
      })
      .then(() => {
        setVisible(false);
        handleShow(entrepriseId);
        toast.success(`Envoie de la convocation réussi`);
      })
      .catch((error: ErrorApiResponse<Convocation>) => {
        handleError(
          error,
          () => {
            setError(error.response.data.message);
          },
          () => {
            setError(error.message);
          },
        );
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  return (
    <Dialog open={visible} size='lg' handler={setVisible}>
      <DialogHeader>Ajouter une Convocation</DialogHeader>
      <DialogBody divider>
        {error && <AlertError text={error} />}
        <div>
          <p>Fichier :</p>
        </div>
        <InputFile
          onChange={(titre, base64) => {
            setTitre(titre);
            setFichier(base64);
            setError(null);
          }}
        />
        <div className='container mx-auto bg-white rounded-b-lg pt-4'>
          <div>
            <p>Demande liée (facultatif) :</p>
          </div>
          <div className='w-full overflow-x-auto overflow-y-auto border h-64'>
            <table className='table-fixed min-w-full bg-white rounded-b-lg'>
              <thead>
                <tr className='w-full h-12 border-gray-300 border-b py-8'>
                  <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Numéro</th>
                  <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Formation et session</th>
                  <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Salarié</th>
                  <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'></th>
                </tr>
              </thead>
              <tbody>
                <tr className='border-gray-100 border-b hover:bg-gray-100'>
                  <td colSpan={3} className='pl-4 py-3 text-gray-800 text-sm text-center'>
                    Aucune
                  </td>
                  <td className='px-4 py-3 text-gray-800 text-sm'>
                    <Button className='flex flex-row items-center rounded-full' size='sm' onClick={() => setDemandeId(null)} disabled={demandeId === null}>
                      Choisir
                    </Button>
                  </td>
                </tr>
                {demandes &&
                  demandes.map((demande) => (
                    <tr key={demande.id} className={`border-gray-100 border-b hover:bg-gray-100 ${demande.est_archive && 'bg-gray-200'}`}>
                      <td className='pl-4 py-3 text-gray-800 text-sm'>{demande.id?.toString().padStart(6, '0')}</td>
                      <td className='px-4 py-3'>
                        <FormationSessionDisplay formation={demande.formation} groupe={demande.groupe} />
                      </td>
                      <td className='px-4 py-3'>
                        <UserDisplay user={demande.user} />
                      </td>
                      <td className='px-4 py-3 text-gray-800 text-sm'>
                        <Button
                          className='flex flex-row items-center rounded-full'
                          size='sm'
                          onClick={() => setDemandeId(demande.id)}
                          disabled={demandeId === demande.id}>
                          Choisir
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className='pt-4'>
          <p>Description (facultatif)</p>
        </div>
        <InputTextarea value={description ? description : ''} onChange={(e) => setDescription(e.target.value)} />

        <div className='pt-4'>
          <p>Date de convocation</p>
        </div>
        <InputDate
          value={dateConvocation as string}
          onChange={(e) => {
            setDateConvocation(e.target.value);
          }}
        />
      </DialogBody>
      <DialogFooter>
        <Button
          variant='text'
          color='gray'
          onClick={() => {
            setTitre('');
            setFichier('');
            setDateConvocation('');
            setError(null);
            setVisible(false);
          }}
          className='mr-1 !rounded-full'
          disabled={apiIsLoading}>
          Annuler
        </Button>
        <Button
          variant='filled'
          color='orange'
          onClick={() => {
            handleEnvoie();
          }}
          disabled={apiIsLoading}>
          Confirmer
        </Button>
      </DialogFooter>
      {apiIsLoading ? <LoadingAbsolute /> : ''}
    </Dialog>
  );
};

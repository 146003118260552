import { createContext, FunctionComponent, useContext, ReactNode } from 'react';
import { Api } from '../api/Api';

/**
 * Crée un contexte qui va contenir une instance de la classe `Api`, afin que tous les appels vers l'API passent obligatoirement par elle.
 */
const ApiContext = createContext<Api | null>(null);

/**
 * Fournit l'instance de la classe `Api` créée dans le contexte qui va englober toute l'application.
 *
 * @param children - Reste de l'application entière. *
 * @returns Fournisseur du contxe qui contient l'instance de la classe `Api`.
 */
export const ApiProvider: FunctionComponent<{ children?: ReactNode }> = ({ children }) => {
  const api = Api.init();

  return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>;
};

/**
 * Hook personalisé qui permet un accès à l'instance de la classe `Api` créée dans le contexte.
 *
 * @returns Instance de la classe `Api` créée dans le contexte.
 */
export const useApi = () => {
  const api = useContext(ApiContext);

  if (api === null) {
    throw new Error('Component must be a child of ApiContainer.');
  }

  return api;
};

import { createContext, FunctionComponent, useContext, ReactNode, useState, useCallback, useEffect } from 'react';
import toast from 'react-hot-toast';
import { useIdleTimer } from 'react-idle-timer';
import { Notification } from '../types/AdminRessources';
import { GoodApiResponse, ErrorApiResponse } from '../types/api';
import { Profil, UserLocal } from '../types/auth';
import { handleError } from '../utils/ErrorHandler';
import { useApi } from './ApiProvider';

/**
 * Crée un contexte qui va permettre de gérer toute les intéractions avec l'utilisateur authentifié.
 */
const UserContext = createContext<{
  /**
   * @property Informations de l'utilisateur authentifié. Si `null` C'est qu'aucun utilisateur n'est authentifié.
   * @public
   */
  user: UserLocal | null;

  /**
   * @method Permet de changer d'utilisateur authentifié, de modifier les informations qui lui sont rattachées ou de déconnecter l'utilisateur authentifié.
   * @param updatedUser - Nouvelles informations concernant l'utilisateur, si `null`, on désauthentifie l'utilisateur.
   * @public
   */
  changeUser: (updatedUser: UserLocal | null) => void;

  /**
   * @property Information du profil courant.
   * @public
   */
  profil: Profil | null;

  /**
   * @method Permet de changer de profil courant, de modifier les informations qui lui sont rattachées ou de supprimer le profil courant.
   * @param updatedProfil - Nouvelles informations concernant le profil courant, si `null`, c'est qu'aucun profil n'a été choisi.
   * @public
   */
  changeProfil: (updatedProfil: Profil | null) => void;

  /**
   * @property Information de si on affiche le sélécteur de profil ou non.
   * @public
   */
  afficheSelecteurProfil: boolean;

  /**
   * @method Permet de changer si l'on affichage ou pas de la liste de profil lors de la connexion.
   * @param updatedAfficheSelecteurProfil - Nouvelle information de l'état de l'affichage du profil lors de la connection.
   * @public
   */
  changeAfficheSelecteurProfil: (updatedAfficheSelecteurProfil: boolean) => void;

  /**
   * @property Liste des notifications de l'utilisateur authentifié.
   * @public
   */
  notifications: Notification[] | null;

  /**
   * @method Permet de marquer une notification comme vue, suite à quoi on retourne chercher la liste des notifications.
   * @param notificationId - ID de la notification à marquer comme vue.
   * @public
   * @async
   */
  viewNotification: (notificationId: Notification['id']) => Promise<void>;

  /**
   * @method Permet de marquer une notification comme masquée, suite à quoi on retourne chercher la liste des notifications.
   * @param notificationId - ID de la notification à marquer comme vue.
   * @public
   * @async
   */
  maskNotification: (notificationId: Notification['id']) => Promise<void>;

  /**
   * @property valeur du filtre
   */
  filtreEstVue: boolean;

  /**
   * @method Permet de modifier la valeur du filtre pour trier les notifications sur le critère de vue.
   * @param updatedFiltreEstVue - Nouvelle valeur du filtre.
   * @public
   */
  tooggleFiltreEstVue: (updatedFiltreEstVue: boolean) => void;

  /**
   * @property valeur du filtre
   */
  filtreEstMasquee: boolean;

  /**
   * @method Permet de modifier la valeur du filtre pour trier les notifications sur le critère de maquée.
   * @param updatedFiltreEstMasquee - Nouvelle valeur du filtre.
   * @public
   */
  tooggleFiltreEstMasquee: (updatedFiltreEstMasquee: boolean) => void;
} | null>(null);

/**
 * Fournit les propriétés et les méthodes nécessaires aux intéractions avec l'utilisateur authentifié.
 *
 * @param children - Reste de l'application entière.
 * @returns Fournisseur du contexte qui contient les propriétés et les méthodes nécessaires aux intéractions avec l'utilisateur authentifié.
 */
export const UserProvider: FunctionComponent<{ children?: ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<UserLocal | null>(JSON.parse(localStorage.getItem('user') as string));
  const [profil, setProfil] = useState<Profil | null>(JSON.parse(localStorage.getItem('profil') as string));
  const [afficheSelecteurProfil, setAfficheSelecteurProfil] = useState<boolean>(
    JSON.parse(localStorage.getItem('affiche_selecteur_profil') as string) && false,
  );
  const [notifications, setNotifications] = useState<Notification[] | null>(null);
  const [filtreEstVue, setFiltreEstVue] = useState<boolean>(false);
  const [filtreEstMasquee, setFiltreEstMasquee] = useState<boolean>(false);

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  useEffect(() => {
    if (user) {
      reset();

      handleNotifications();
      const interval = setInterval(() => {
        handleNotifications();
      }, 60000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [user, filtreEstVue, filtreEstMasquee]);

  // Profil

  /**
   * Permet de changer d'utilisateur authentifié, de modifier les informations qui lui sont rattachées ou de déconnecter l'utilisateur authentifié.
   *
   * @param updatedUser - Nouvelles informations concernant l'utilisateur, si `null`, on désauthentifie l'utilisateur.
   */
  const changeUser = useCallback((updatedUser: UserLocal | null) => {
    setUser(updatedUser);
    if (updatedUser) {
      client.setToken(updatedUser.token);
    } else {
      client.resetToken();
    }
    localStorage.setItem('user', JSON.stringify(updatedUser));
  }, []);

  /**
   * Permet de changer de profil courant, de modifier les informations qui lui sont rattachées ou de supprimer le profil courant.
   *
   * @param updatedProfil - Nouvelles informations concernant le profil courant, si `null`, c'est qu'aucun profil n'a été choisi.
   */
  const changeProfil = (updatedProfil: Profil | null) => {
    setProfil(updatedProfil);
    if (updatedProfil) {
      client.setProfil(updatedProfil.id);
    } else {
      client.resetProfil();
    }
    localStorage.setItem('profil', JSON.stringify(updatedProfil));
  };

  // Affichage du selecteur de profil

  /**
   * Permet de changer si l'on affichage ou pas de la liste de profil lors de la connexion.
   * @param updatedAfficheSelecteurProfil - Nouvelle information de l'état de l'affichage du profil lors de la connection.
   */
  const changeAfficheSelecteurProfil = (updatedAfficheSelecteurProfil: boolean) => {
    setAfficheSelecteurProfil(updatedAfficheSelecteurProfil ? true : false);
    localStorage.setItem('affiche_selecteur_profil', JSON.stringify(updatedAfficheSelecteurProfil));
  };

  // Notifications

  /**
   * Cette méthode va chercher les notifications de l'utilisateur authentifié et les stocke dans un état local.
   */
  const handleNotifications = () => {
    let params = {};

    if (!filtreEstVue) {
      params = { ...params, est_vu: 0 };
    }

    if (!filtreEstMasquee) {
      params = { ...params, est_masque: 0 };
    }

    client
      .get<GoodApiResponse<Notification[]>>(`/notifications`, { params: params })
      .then((response) => {
        setNotifications(response.data.data);
      })
      .catch((error: ErrorApiResponse<Notification>) => {
        handleError(error);
      });
  };

  /**
   * Permet de marquer une notification comme vue, suite à quoi on retourne chercher la liste des notifications.
   *
   * @param notificationId - ID de la notification à marquer comme vue.
   * @async
   */
  const viewNotification = async (notificationId: Notification['id']) => {
    try {
      await client.patch<GoodApiResponse>(`/notifications/${notificationId}/vu`);
      handleNotifications();
    } catch (error) {
      if (
        (error as ErrorApiResponse).response &&
        parseInt((error as ErrorApiResponse).response.status) >= 400 &&
        (error as ErrorApiResponse).response.data.message
      ) {
        toast.error((error as ErrorApiResponse).response.data.message);
      } else {
        toast.error((error as ErrorApiResponse).message);
      }
    }
  };

  /**
   * Permet de marquer une notification comme masquée, suite à quoi on retourne chercher la liste des notifications.
   *
   * @param notificationId - ID de la notification à marquer comme vue.
   * @async
   */
  const maskNotification = async (notificationId: Notification['id']) => {
    try {
      await client.patch<GoodApiResponse>(`/notifications/${notificationId}/masque`);
      handleNotifications();
    } catch (error) {
      if (
        (error as ErrorApiResponse).response &&
        parseInt((error as ErrorApiResponse).response.status) >= 400 &&
        (error as ErrorApiResponse).response.data.message
      ) {
        toast.error((error as ErrorApiResponse).response.data.message);
      } else {
        toast.error((error as ErrorApiResponse).message);
      }
    }
  };

  /**
   * Permet de modifier la valeur du filtre pour trier les notifications sur le critère de vue.
   *
   * @param updatedFiltreEstVue - Nouvelle valeur du filtre.
   */
  const tooggleFiltreEstVue = (updatedFiltreEstVue: boolean) => {
    setFiltreEstVue(updatedFiltreEstVue);
  };

  /**
   * Permet de modifier la valeur du filtre pour trier les notifications sur le critère de maquée.
   *
   * @param updatedFiltreEstMasquee - Nouvelle valeur du filtre.
   */
  const tooggleFiltreEstMasquee = (updatedFiltreEstMasquee: boolean) => {
    setFiltreEstMasquee(updatedFiltreEstMasquee);
  };

  //Deconnexion automatique

  /**
   * @constant timeout - Definie la durée maximale au bout de laquelle on considère l'utilisateur comme inactif.
   * @default 1000 * 3600 * 24 millisecondes, soit 24 heures.
   */
  const timeout = 1000 * 3600 * 24;

  /**
   * @constant throttle - Trotteuse, c'est la durée entre 2 vérification d'inactivité
   */
  const throttle = 500;

  /**
   * C'est ce que l'on veut faire une fois la durée de `timeout` atteinte.
   *
   * Dans notre cas on veut rediriger l'utilisateur vers une page spéciale qui l'informe qu'il a été inactif et le desauthentifie.
   */
  const onIdle = () => {
    if (user !== null) {
      toast.success("Déconnecté pour cause d'inactivité.");
      window.location.href = '/inactivite';
    }
  };

  const { reset } = useIdleTimer({
    onIdle,
    timeout,
    throttle,
  });

  return (
    <UserContext.Provider
      value={{
        user,
        changeUser,
        profil,
        changeProfil,
        afficheSelecteurProfil,
        changeAfficheSelecteurProfil,
        notifications,
        viewNotification,
        maskNotification,
        filtreEstVue,
        tooggleFiltreEstVue,
        filtreEstMasquee,
        tooggleFiltreEstMasquee,
      }}>
      {children}
    </UserContext.Provider>
  );
};

/**
 * Hook personalisé qui permet un accès aux propriétés et aux méthodes nécessaires aux intéractions avec l'utilisateur authentifié.
 *
 * @returns propriétés et méthodes nécessaires aux intéractions avec l'utilisateur authentifié.
 */
export const useUser = () => {
  const user = useContext(UserContext);

  if (user === null) {
    throw new Error('Component must be a child of userContainer.');
  }

  return user;
};

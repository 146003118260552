import { Dialog, DialogHeader, DialogBody, DialogFooter, Button } from '@material-tailwind/react';
import { FunctionComponent, useState } from 'react';
import toast from 'react-hot-toast';
import { useApi } from '../../../providers/ApiProvider';
import { Demande, DemandeDocument } from '../../../types/AdminRessources';
import { GoodApiResponse, ErrorApiResponse } from '../../../types/api';
import { handleError } from '../../../utils/ErrorHandler';
import { AlertError } from '../../Alertes';
import { InputTextarea } from '../../Inputs/InputTextarea';
import { LoadingAbsolute } from '../../LoadingAbsolute';

/**
 * Ce composant permet d'afficher une Modale pour que les admins puissent demander un document au salarié.
 *
 * @param visible - Précise si la modale et ouverte ou non. Provient du parent.
 * @param setVisible - Accesseur qui modifie la visibilité de la modale. Provient du parent.
 * @param handleIndex - Callback qui réalise l'appel pour recharger les données annexes à la demande. Provient du parent.
 * @param handleShow - Callback qui réalise l'appel pour recharger les données de la demande. Provient du parent.
 * @param demandeId - ID de la demande. Provient du parent.
 */
export const ModalAdminDemandeDocument: FunctionComponent<{
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  handleIndex: () => void;
  handleShow: () => void;
  demandeId: Demande['id'];
}> = ({ visible, setVisible, handleIndex, handleShow, demandeId }) => {
  /**
   * On stocke si on est en train d'utiliser l'API ou non.
   */
  const [apiIsLoading, setApiIsLoading] = useState(false);

  /**
   * On stocke la valeur du champ question.
   */
  const [question, setQuestion] = useState<DemandeDocument['question']>('');

  /**
   * On stocke l'eventuelle erreur.
   */
  const [error, setError] = useState<string | null>(null);

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  /**
   * Cette méthode permet d'effectuer la demande de document. Si l'appel est réussie on réinitialise les état locaux.
   *
   * @param question - Question posé au salarié par exemple : `'Avez-vous votre carte d'identité ?'`.
   */
  const handleAjout = (question: DemandeDocument['question']) => {
    setApiIsLoading(true);

    client
      .post<GoodApiResponse>(`/admin/demandes/${demandeId}/documents`, { question: question })
      .then(() => {
        handleIndex();
        handleShow();
        setQuestion('');
        setError(null);
        setVisible(false);
        toast.success(`Demande de document adressée`);
      })
      .catch((error: ErrorApiResponse<DemandeDocument>) => {
        handleError(
          error,
          () => {
            setError(error.response.data.message);
          },
          () => {
            setError(error.message);
          },
        );
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  return (
    <Dialog open={visible} size='xs' handler={setVisible}>
      <DialogHeader>Demande de document</DialogHeader>
      <DialogBody divider>
        {error && <AlertError text={error} />}
        <p>Nom du document attendu (un seul par demande)&nbsp;:</p>
        <InputTextarea
          value={question as string}
          onChange={(e) => {
            setQuestion(e.target.value);
          }}
        />
      </DialogBody>
      <DialogFooter>
        <Button
          variant='text'
          color='gray'
          onClick={() => {
            setQuestion('');
            setError(null);
            setVisible(false);
          }}
          className='mr-1 !rounded-full'
          disabled={apiIsLoading}>
          Annuler
        </Button>
        <Button
          variant='filled'
          color='orange'
          onClick={() => {
            handleAjout(question);
          }}
          disabled={apiIsLoading}>
          Confirmer
        </Button>
      </DialogFooter>
      {apiIsLoading ? <LoadingAbsolute /> : ''}
    </Dialog>
  );
};

import { ChevronRightIcon, EyeIcon } from '@heroicons/react/24/outline';
import { Fragment, FunctionComponent } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Devis } from '../../types/EntreprisesRessources';
import { dateEstCompriseDansIntervalle, ajouterJour } from '../../utils/Temps';
import { FormationSessionDisplay } from '../FormationSessionDisplay';
import { UserDisplay } from '../UserDisplay';
import { DownloadLink } from '../DownloadLink';

/**
 * Ce composant est un encart du tableau de bord entreprises concernant les devis en attente.
 *
 * @param devis - Tableau de devis en attente.
 */
export const DashboardEntrepriseDevis: FunctionComponent<{ devis: Devis[] }> = ({ devis }) => {
  /**
   * Date du jour.
   * @constant
   */
  const currentDate = new Date();

  /**
   * Permet au composant de changer de page.
   */
  const navigate = useNavigate();

  /**
   * Cette méthode sert à afficher les un résumé de l'avancement pour chaque devis.
   *
   * @param devi - Devis dont il est question dans leboucle.
   * @returns Le résumé des étapes de l'avancement du devis.
   */
  const afficheAvancement = (devi: Devis) => {
    if (devi.date_acceptation === null && devi.date_refus === null) {
      if (currentDate < new Date(devi.date_limite_acceptation)) {
        // Devis en attente
        return (
          <div
            className={
              devi.date_limite_acceptation &&
              dateEstCompriseDansIntervalle(new Date(devi.created_at as string), ajouterJour(new Date(devi.date_limite_acceptation), -1), currentDate)
                ? currentDate.getDate() === ajouterJour(new Date(devi.date_limite_acceptation), -1).getDate()
                  ? 'text-orange-200'
                  : ''
                : 'text-red-700'
            }>
            En attente de décision
            {devi.date_limite_acceptation
              ? `, valable jusqu'au : ${new Intl.DateTimeFormat('default', {
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                  hour12: false,
                }).format(new Date(devi.date_limite_acceptation))}.`
              : '.'}
          </div>
        );
      } else {
        // Délais d'attente dépassé
        return (
          <div className='text-red-700'>
            {devi.date_limite_acceptation
              ? `Valable jusqu'au : ${new Intl.DateTimeFormat('default', {
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                  hour12: false,
                }).format(new Date(devi.date_limite_acceptation))}.`
              : ''}
          </div>
        );
      }
    }

    if (devi.date_acceptation) {
      /**
       * Une fois le devis accepté on sait que l'on va rendre ce bloc pour tous les cas où le devis est accepté.
       * @constant
       */
      const devisAccepte = (
        <div>{`Accepté le : ${new Intl.DateTimeFormat('default', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour12: false,
        }).format(new Date(devi.date_acceptation as string))}.`}</div>
      );

      if (devi.date_traitement === null && devi.date_limite_traitement) {
        if (currentDate < new Date(devi.date_limite_traitement)) {
          // En attente de traitement
          return (
            <Fragment>
              {devisAccepte}
              <div
                className={
                  devi.date_acceptation &&
                  devi.date_limite_retractation &&
                  dateEstCompriseDansIntervalle(
                    new Date(devi.date_acceptation as string),
                    ajouterJour(new Date(devi.date_limite_retractation as string), -1),
                    currentDate,
                  )
                    ? currentDate.getDate() === ajouterJour(new Date(devi.date_limite_retractation), -1).getDate()
                      ? 'text-orange-200'
                      : ''
                    : 'text-red-700'
                }>
                {devi.date_limite_retractation
                  ? `Rétractation possible jusqu'au : ${new Intl.DateTimeFormat('default', {
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                      hour12: false,
                    }).format(new Date(devi.date_limite_retractation))}.`
                  : ''}
              </div>
            </Fragment>
          );
        } else {
          // Délais de traitement dépassé.
          return (
            <Fragment>
              {devisAccepte}
              <div className='text-red-700'>
                {devi.date_limite_traitement
                  ? `Date limite de traitement le : ${new Intl.DateTimeFormat('default', {
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                      hour12: false,
                    }).format(new Date(devi.date_limite_traitement))}.`
                  : ''}
              </div>
            </Fragment>
          );
        }
      }

      if (devi.date_retractation) {
        return (
          <Fragment>
            {devisAccepte}
            <div className='text-red-700'>
              {devi.date_retractation
                ? `Rétracté le : ${new Intl.DateTimeFormat('default', {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour12: false,
                  }).format(new Date(devi.date_retractation))}.`
                : ''}
            </div>
          </Fragment>
        );
      }

      if (devi.date_traitement) {
        return (
          <Fragment>
            {devisAccepte}
            <div>
              {devi.date_traitement
                ? `Traité le : ${new Intl.DateTimeFormat('default', {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour12: false,
                  }).format(new Date(devi.date_traitement))}.`
                : ''}
            </div>
          </Fragment>
        );
      }
    }

    if (devi.date_refus) {
      return (
        <div>{`Refusé le : ${new Intl.DateTimeFormat('default', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour12: false,
        }).format(new Date(devi.date_refus as string))}.`}</div>
      );
    }
  };

  return (
    <div className='mx-auto mt-6 shadow-md rounded-md bg-white'>
      <div className='flex flex-col sm:flex-row items-center justify-between p-5'>
        <h3 className='text-secondary-2 poppins-semibold text-center md:text-left'>Devis en attente</h3>
        <Link to='/entreprise/devis' className='text-sm text-secondary-1/90 flex items-center hover:text-primary'>
          Tout voir
          <ChevronRightIcon className='w-3 h-3' />
        </Link>
      </div>
      {devis.length > 0 ? (
        <table className='table-auto w-full bg-white rounded-b-lg'>
          <thead className='text-sm text-gray-500 text-left border-b'>
            <tr className='w-full h-12 border-gray-300  border-b py-8'>
              <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Numéro</th>
              <th className='py-4 text-secondary-1 pl-2 text-left text-sm tracking-normal leading-4'>Formation</th>
              <th className='py-4 text-secondary-1 pl-2 text-left text-sm tracking-normal leading-4'>Salarié</th>
              <th className='py-4 text-secondary-1 pl-2 text-left text-sm tracking-normal leading-4'>Fichier</th>
              <th className='py-4 text-secondary-1 pl-2 text-left text-sm tracking-normal leading-4'>Avancement</th>
              <th className='py-4 text-secondary-1 px-2 text-center text-sm tracking-normal leading-4'>Voir</th>
            </tr>
          </thead>
          <tbody>
            {devis &&
              devis.map((devi) => (
                <tr key={devi.token} className=' border-gray-100 border-b hover:bg-gray-100' onDoubleClick={() => navigate(`/entreprise/devis/${devi.token}`)}>
                  <td className='pl-4 pr-2 py-3 text-secondary-1/90 text-xs'>{devi.numero}</td>
                  <td className='px-2 py-3'>
                    <FormationSessionDisplay formation={devi.formation} groupe={devi.groupe} />
                  </td>
                  <td className='px-2 py-3'>
                    <UserDisplay user={devi.user} />
                  </td>
                  <td className='px-2 py-3 text-secondary-1 text-xs'>{devi.fichier && <DownloadLink fichier={devi.fichier} titre={devi.titre} />}</td>
                  <td className='px-2 py-3 text-secondary-1 text-xs'>
                    Reçu le{' '}
                    {devi.created_at
                      ? new Intl.DateTimeFormat('default', {
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                          hour12: false,
                        }).format(new Date(devi.created_at as string))
                      : ''}
                    <div>{afficheAvancement(devi)}</div>
                  </td>
                  <td className='px-2 py-3 text-secondary-1/90 text-xs'>
                    <div className='flex justify-center'>
                      <Link to={`/entreprise/devis/${devi.token}`}>{<EyeIcon className='w-5 h-5' />}</Link>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      ) : (
        <p className='p-5 text-center text-sm font-medium text-secondary-1/70'>Aucun devis en attente pour le moment.</p>
      )}
    </div>
  );
};

import { ChangeEvent, ChangeEventHandler, Fragment, FunctionComponent, useCallback } from 'react';

/**
 * Composant InputNumber.
 *
 * @param label - Libellé du champ de saisie.
 * @param value - Valeur du champ de saisie.
 * @param onChange - Fonction de rappel appelée lorsque la valeur du champ de saisie change.
 * @param error - Indique si le champ de saisie a une erreur.
 * @param placeholder - Placeholder du champ de saisie.
 * @param unit - Unité de valeur du champ de saisie.
 * @return Composant InputNumber.
 */
export const InputNumber: FunctionComponent<{
  error?: boolean;
  label?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  placeholder?: string;
  value?: string | number | readonly string[];
  unit?: string;
}> = ({ label, value, onChange = undefined, error = false, placeholder, unit }) => {
  /**
   * @constant Elle permet de définir éventuellement un `id` pour le `input` afin que les labels faasent bien référence au champ.
   */
  const inputId = label ? `input-${label.toLowerCase().replace(' ', '-')}` : undefined;

  /**
   * Gère le changement de valeur dans le composant parent.
   *
   * @param e - L'événement de changement de valeur du champ de saisie.
   */
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(e);
      }
    },
    [onChange],
  );

  return (
    <Fragment>
      {label && (
        <label className={`text-xs ${error ? 'text-red-500' : ''}`} htmlFor={inputId}>
          {label}
        </label>
      )}
      <div className='rounded-lg flex flex-row items-stretch'>
        <input
          className={`relative w-full px-4 py-2 text-sm leading-none text-left text-secondary-1 border border-gray-400 rounded outline-none focus:border-secondary-2 ${
            !unit ? '' : 'rounded-r-none'
          } ${error ? 'border-red-500' : ''}`}
          type='number'
          value={value !== undefined && value !== null ? value : ''}
          onChange={handleChange}
          id={inputId}
          placeholder={placeholder}
        />
        {unit && <span className='flex items-center rounded-r text-sm px-2 text-gray-600 border-gray-400 bg-gray-100 border border-l-0'>{unit}</span>}
      </div>
    </Fragment>
  );
};

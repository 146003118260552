//import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { App } from './App';
import { ThemeProvider } from '@material-tailwind/react';
import { theme } from './config/theme';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  //<React.StrictMode>
  <ThemeProvider value={theme}>
    <App />
  </ThemeProvider>,
  //</React.StrictMode>
);

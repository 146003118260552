import { Dialog, DialogHeader, DialogBody, DialogFooter, Button } from '@material-tailwind/react';
import { FunctionComponent, useState } from 'react';
import { InputSwitch } from '../../Inputs/InputSwitch';
import { LoadingAbsolute } from '../../LoadingAbsolute';

/**
 * Ce composant permet d'afficher une Modale pour accepter un souhait, côté dirigeant.
 *
 * @param visible - Précise si la modale et ouverte ou non. Provient du parent.
 * @param setVisible - Accesseur qui modifie la visibilité de la modale. Provient du parent.
 * @param apiIsLoading - Précise si on affiche le loader ou non. Provient du parent.
 * @param handleAccepte - Callback qui réalise l'appel pour accepter le souhait.
 */
export const ModalAcceptationSouhaitDirigeant: FunctionComponent<{
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  apiIsLoading: boolean;
  handleAccepte: (effectuerDemande: boolean) => void;
}> = ({ visible, setVisible, apiIsLoading, handleAccepte }) => {
  const [effectuerDemande, setEffectuerDemande] = useState<boolean>(false);

  return (
    <Dialog open={visible} size='xs' handler={setVisible}>
      <DialogHeader>Acceptation du souhait</DialogHeader>
      <DialogBody divider>
        Ceci informera votre salarié que vous avez accepté son souhait de formation. Confirmer ?
        <InputSwitch
          label='Souhaitez-vous nous envoyer la demande dès maintenant ?'
          checked={effectuerDemande}
          onChange={() => setEffectuerDemande(!effectuerDemande)}
        />
        {apiIsLoading ? <LoadingAbsolute /> : ''}
      </DialogBody>
      <DialogFooter>
        <Button variant='text' color='gray' onClick={() => setVisible(false)} className='mr-1 !rounded-full'>
          Annuler
        </Button>
        <Button
          variant='filled'
          color='orange'
          onClick={() => {
            handleAccepte(effectuerDemande);
          }}>
          Confirmer
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

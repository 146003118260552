import { Dialog, DialogHeader, DialogBody, DialogFooter, Button } from '@material-tailwind/react';
import { FunctionComponent, useState } from 'react';
import toast from 'react-hot-toast';
import { useApi } from '../../../providers/ApiProvider';
import { ErrorApiResponse, GoodApiResponse } from '../../../types/api';
import { Invitation } from '../../../types/SalariesRessources';
import { handleError } from '../../../utils/ErrorHandler';
import { LoadingAbsolute } from '../../LoadingAbsolute';

/**
 * Cette modale permet de confirmer l'annulation d'un invitaion envoyée à un salarié.
 *
 * @param visible - Précise si la modale et ouverte ou non. Provient du parent.
 * @param setVisible - Accesseur qui modifie la visibilité de la modale. Provient du parent.
 * @param invitationToken - Jeton de l'invitation.
 * @param handleIndex - Callback qui réalise l'appel pour recharger les données des invitations. Provient du parent.
 */
export const ModalAnnulationInvitationEntreprise: FunctionComponent<{
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  invitationToken: Invitation['token'];
  handleIndex: () => void;
}> = ({ visible, setVisible, invitationToken, handleIndex }) => {
  /**
   * On stocke si on est en train d'utiliser l'API ou non.
   */
  const [apiIsLoading, setApiIsLoading] = useState(false);

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  /**
   * Cet méthode permet d'annuler une invitation que l'on a envoyé.
   */
  const handleAnnuler = () => {
    setApiIsLoading(true);
    client
      .delete<GoodApiResponse>(`/entreprise/invitations/${invitationToken}`)
      .then(() => {
        handleIndex();
        toast.success(`Invitation retirée`);
      })
      .catch((error: ErrorApiResponse<Invitation>) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  return (
    <Dialog open={visible} size='xs' handler={setVisible}>
      <DialogHeader>Confirmation d'annulation de l'invitation</DialogHeader>
      <DialogBody divider>Voulez-vous vraiment annuler l'invitation envoyée au salarié ?</DialogBody>
      <DialogFooter>
        <Button variant='text' color='gray' onClick={() => setVisible(false)} className='mr-1 !rounded-full'>
          Annuler
        </Button>
        <Button
          variant='filled'
          color='orange'
          onClick={() => {
            handleAnnuler();
            setVisible(false);
          }}>
          Confirmer
        </Button>
      </DialogFooter>
      {apiIsLoading ? <LoadingAbsolute /> : ''}
    </Dialog>
  );
};

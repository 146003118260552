import { Fragment, FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';
import { Container } from '../components/Container';
import { FilDariane } from '../components/FilDariane';
import { MainMenu } from '../components/MainMenu';
import { Config } from '../config/config';

/**
 * Affiche les mentions légales.
 */
export const MentionsLegalesScreen: FunctionComponent = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Mentions légales - {Config.app_label}</title>
      </Helmet>
      <div
        className='bg-cover bg-center h-auto text-white py-3 px-4 lg:px-8 xl:px-11 object-fill relative'
        style={{
          backgroundImage: "url('/assets/header-fiche-formations.webp')",
        }}>
        <div className='absolute top-0 right-0 left-0 bottom-0 w-full h-full bg-secondary-2/70'></div>
        <div className='relative flex flex-col items-center h-full mx-auto'>
          <MainMenu className='relative pb-20' />
        </div>
        <div className='lg:max-w-xl xl:max-w-7xl w-full relative h-full 2xl:px-8 mt-20 lg:mt-[120px] text-center md:text-left mr-auto xl:mx-auto'>
          <h1 className='text-white text-3xl w-full uppercase poppins-semibold xl:pr-96'>Mentions légales</h1>
          <FilDariane
            className='bg-transparent w-full text-white pl-0 !mb-2 mt-2 xl:pr-96'
            items={[{ label: 'Mentions légales', link: `/mentions-legales` }]}
          />
        </div>
      </div>
      <Container className='bg-gray-100 h-full'>
        <div className='md:flex md:flex-row w-full'>
          <div className='lg:basis-2/3 h-full mt-11'>
            <div className='md:mr-4 mb-11 text-gray-700'>
              <h3 className='poppins-semibold text-lg'>
                Le site <a href='https://formationscourtes.compagnons-du-devoir.com/'>formationscourtes.compagnons-du-devoir.com</a> est édité par&nbsp;:
              </h3>
              <br />
              <p>Association Ouvrière des compagnons du devoir et du tour de France - 82, rue de l’Hôtel-de-Ville 75004 Paris</p>
              <br />
              <p>Téléphone&nbsp;: 01 44 78 22 50</p>
              <br />
              <p>Directeur de la publication&nbsp;: Christian PONS, Premier Conseiller.</p>
              <br />
              <h5 className='poppins-semibold text-lg'>Ce site est hébergé par&nbsp;: </h5>
              <br />
              <p>
                Claranet SAS au capital de 5.332.300 euros, immatriculée au Registre du Commerce et des Sociétés de Rennes sous le numéro 419 632 286 dont le
                siège social est situé au 2 rue des Landelles, CS 87739, 35577 CESSON SÉVIGNÉ Cedex.
              </p>
              <br />
              <ul>
                <li> Numéro de téléphone&nbsp;: 02 99 12 57 57</li>
                <li>
                  {' '}
                  Adresse e-mail&nbsp;: <a href='mailto:info@fr.clara.net'>info@fr.clara.net</a>
                </li>
                <li> N° de TVA intracommunautaire&nbsp;: FR 24 419 632 286</li>
                <li> Directeur de la publication&nbsp;: Olivier Beaudet, en qualité de Directeur Général</li>
              </ul>
              <br />
            </div>
          </div>
        </div>
      </Container>
    </Fragment>
  );
};

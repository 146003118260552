import { InformationCircleIcon } from '@heroicons/react/20/solid';
import { FunctionComponent } from 'react';

export const ImageHints: FunctionComponent<{
  largeur?: string | null;
  poids?: string | null;
}> = ({ largeur, poids }) => {
  return (
    <div className='p-3 bg-blue-50 rounded-lg text-sm mb-7 text-secondary-1 flex flex-row gap-2'>
      <InformationCircleIcon className='w-5 h-5 flex-none' strokeWidth={2.2} />
      <p className='leading-4'>
        <b>Conseils :</b>
        <ul>
          <li>Largeur maximale : {largeur ? largeur : '1920'} px</li>
          <li>Poids maximal : {poids ? poids : '100'} Ko</li>
          <li>Formats conseillés : .webp ou .jpeg</li>
          <li>Formats déconseillés : .png ou .gif</li>
        </ul>
      </p>
    </div>
  );
};

import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Config } from '../../../config/config';
import { useApi } from '../../../providers/ApiProvider';
import { GoodApiResponse, ErrorApiResponse } from '../../../types/api';
import { EllipsisVerticalIcon, PlusIcon } from '@heroicons/react/24/outline';
import { InputDate } from '../../../components/Inputs/InputDate';
import { handleError } from '../../../utils/ErrorHandler';
import { Devis } from '../../../types/AdminRessources';
import { dateEstCompriseDansIntervalle, ajouterJour } from '../../../utils/Temps';
import { useNavigate } from 'react-router-dom';
import { Button, Menu, MenuHandler, MenuItem, MenuList } from '@material-tailwind/react';
import { ModalAjouterDevisAdmin } from '../../../components/Modales/Admin/ModalAjouterDevisAdmin';
import toast from 'react-hot-toast';
import { ModalSuppressionDevis } from '../../../components/Modales/Admin/ModalSuppressionDevis';
import { EntrepriseDisplay } from '../../../components/EntrepriseDisplay';
import { UserDisplay } from '../../../components/UserDisplay';
import { FormationSessionDisplay } from '../../../components/FormationSessionDisplay';
import { DownloadLink } from '../../../components/DownloadLink';

/**
 * Ce composant affiche la liste des devis côté administrateur.
 */
export const AdminDevisScreen: FunctionComponent = () => {
  /**
   * On stocke si on est en train d'utiliser l'API ou non.
   */
  const [apiIsLoading, setApiIsLoading] = useState(false);

  /**
   * Date du jour.   *
   * @constant
   */
  const currentDate = new Date();

  /**
   * Date d'il y a 7 jour.
   * @constant
   */
  const sevenDayAgo = new Date(new Date().setDate(currentDate.getDate() - 7));

  /**
   * État local qui gère le champ de la date de départ de la recherche de devis.
   * @defaultValue La value stocké dans l'état local ou la date du jour au format MySQL
   */
  const [from, setFrom] = useState<string>(
    localStorage.getItem(`AdminDevisScreen-From`) ||
      `${sevenDayAgo.getFullYear()}-${sevenDayAgo.getMonth() < 9 ? `0${sevenDayAgo.getMonth() + 1}` : sevenDayAgo.getMonth() + 1}-${
        sevenDayAgo.getDate() < 10 ? `0${sevenDayAgo.getDate()}` : sevenDayAgo.getDate()
      }`,
  );

  /**
   * État local qui gère le champ de la date de fin de la recherche de devis.
   * @defaultValue La value stocké dans l'état local ou la date du jour au format MySQL
   */
  const [to, setTo] = useState<string>(
    localStorage.getItem(`AdminDevisScreen-To`) ||
      `${currentDate.getFullYear()}-${currentDate.getMonth() < 9 ? `0${currentDate.getMonth() + 1}` : currentDate.getMonth() + 1}-${
        currentDate.getDate() < 10 ? `0${currentDate.getDate()}` : currentDate.getDate()
      }`,
  );

  /**
   * Permet de stocker la liste des devis.
   */
  const [devis, setDevis] = useState<Devis[] | null>(null);

  /**
   * Permet de filtrer sur l'avancement du devis.
   */
  const [filtreAvancement, setFiltreAvancement] = useState<
    'tous' | 'enattente' | 'delaisattentedepasse' | 'accepte' | 'enattentetraitement' | 'delaistraitementdepasse' | 'refuse' | 'retracte' | 'traite'
  >(
    (localStorage.getItem(`AdminDevisScreen-filtreAvancement`) as
      | 'tous'
      | 'enattente'
      | 'delaisattentedepasse'
      | 'accepte'
      | 'enattentetraitement'
      | 'delaistraitementdepasse'
      | 'refuse'
      | 'retracte'
      | 'traite') || 'tous',
  );

  /**
   * Permet d'ouvrir une modale lorsque l'on souhaite ajouter un devis.
   */
  const [modalAjouterDevisEstVisible, setModalAjouterDevisEstVisible] = useState<boolean>(false);

  /**
   * État local pour gérer le devis selectionné en vue d'ouvrir une modale dessus.
   */
  const [devisSelectionne, setDevisSelectionne] = useState<Devis | null>(null);

  /**
   * Permet d'ouvrir une modale lorsque l'on souhaite supprimer un devis.
   */
  const [modalSuppressionDevisEstVisible, setModalSuppressionDevisEstVisible] = useState<boolean>(false);

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  /**
   * Permet au composant de changer de page.
   */
  const navigate = useNavigate();

  /**
   * Permet de recharger les données au chargement de page ou au changement de paramètres
   */
  useEffect(() => {
    handleIndex();
  }, [client, from, to]);

  /**
   * Retrouve par le réseau la liste des devis et les rangent et la met dans un état local.
   */
  const handleIndex = () => {
    if (apiIsLoading === true) {
      return;
    }

    setApiIsLoading(true);
    client
      .get<GoodApiResponse<Devis[]>>(`/admin/devis`, { params: { from, to } })
      .then((response) => {
        setDevis(response.data.data);
      })
      .catch((error: ErrorApiResponse<Devis>) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Ouvre une modale pour informer l'administrateur qu'il s'appreète à supprimer un devis.
   * @param devi - Devis selectionné.
   */
  const handleModaleSuppressionDevis = (devi: Devis) => {
    setDevisSelectionne(devi);
    setModalSuppressionDevisEstVisible(true);
  };

  /**
   * Cette méthode est passé en paramètre de la modale de suppression de devis.
   * Elle permet de refuser un souhait.
   */
  const handleSupprime = () => {
    setApiIsLoading(true);

    client
      .delete<GoodApiResponse>(`/admin/devis/${(devisSelectionne as Devis).id}`)
      .then(() => {
        handleIndex();
        toast.success(`Devis supprimé`);
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Cette méthode sert à afficher un résumé de l'avancement pour chaque devis.
   *
   * @param devi - Devis dont il est question dans la boucle.
   * @returns Le résumé des étapes de l'avancement du devis.
   */
  const afficheAvancement = (devi: Devis) => {
    if (devi.date_acceptation === null && devi.date_refus === null) {
      if (currentDate < new Date(devi.date_limite_acceptation)) {
        // Devis en attente
        return (
          <div
            className={
              devi.date_limite_acceptation &&
              dateEstCompriseDansIntervalle(new Date(devi.created_at as string), ajouterJour(new Date(devi.date_limite_acceptation), -1), currentDate)
                ? currentDate.getDate() === ajouterJour(new Date(devi.date_limite_acceptation), -1).getDate()
                  ? 'text-orange-200'
                  : ''
                : 'text-red-700'
            }>
            En attente de décision
            {devi.date_limite_acceptation
              ? `, valable jusqu'au : ${new Intl.DateTimeFormat('default', {
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                  hour12: false,
                }).format(new Date(devi.date_limite_acceptation))}.`
              : '.'}
          </div>
        );
      } else {
        // Délais d'attente dépassé
        return (
          <div className='text-red-700'>
            {devi.date_limite_acceptation
              ? `Valable jusqu'au : ${new Intl.DateTimeFormat('default', {
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                  hour12: false,
                }).format(new Date(devi.date_limite_acceptation))}.`
              : ''}
          </div>
        );
      }
    }

    if (devi.date_acceptation) {
      /**
       * Une fois le devis accepté on sait que l'on va rendre ce bloc pour tous les cas où le devis est accepté.
       * @constant
       */
      const devisAccepte = (
        <div>{`Accepté le : ${new Intl.DateTimeFormat('default', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour12: false,
        }).format(new Date(devi.date_acceptation as string))}.`}</div>
      );

      if (devi.date_traitement === null && devi.date_limite_traitement) {
        if (currentDate < new Date(devi.date_limite_traitement)) {
          // En attente de traitement
          return (
            <Fragment>
              {devisAccepte}
              <div
                className={
                  devi.date_acceptation &&
                  devi.date_limite_retractation &&
                  dateEstCompriseDansIntervalle(
                    new Date(devi.date_acceptation as string),
                    ajouterJour(new Date(devi.date_limite_retractation as string), -1),
                    currentDate,
                  )
                    ? currentDate.getDate() === ajouterJour(new Date(devi.date_limite_retractation), -1).getDate()
                      ? 'text-orange-200'
                      : ''
                    : 'text-red-700'
                }>
                {devi.date_limite_retractation
                  ? `Date limite de rétractation : ${new Intl.DateTimeFormat('default', {
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                      hour12: false,
                    }).format(new Date(devi.date_limite_retractation))}.`
                  : ''}
              </div>
            </Fragment>
          );
        } else {
          // Délais de traitement dépassé.
          return (
            <Fragment>
              {devisAccepte}
              <div className='text-red-700'>
                {devi.date_limite_traitement
                  ? `Date limite de traitement : ${new Intl.DateTimeFormat('default', {
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                      hour12: false,
                    }).format(new Date(devi.date_limite_traitement))}.`
                  : ''}
              </div>
            </Fragment>
          );
        }
      }

      if (devi.date_retractation) {
        return (
          <Fragment>
            {devisAccepte}
            <div className='text-red-700'>
              {devi.date_retractation
                ? `Rétractation le : ${new Intl.DateTimeFormat('default', {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour12: false,
                  }).format(new Date(devi.date_retractation))}.`
                : ''}
            </div>
          </Fragment>
        );
      }

      if (devi.date_traitement) {
        return (
          <Fragment>
            {devisAccepte}
            <div>
              {devi.date_traitement
                ? `Traité le : ${new Intl.DateTimeFormat('default', {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour12: false,
                  }).format(new Date(devi.date_traitement))}.`
                : ''}
            </div>
          </Fragment>
        );
      }
    }

    if (devi.date_refus) {
      return (
        <div>{`Refusé le : ${new Intl.DateTimeFormat('default', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour12: false,
        }).format(new Date(devi.date_refus as string))}.`}</div>
      );
    }
  };

  const afficheDevisFiltres = () =>
    devis
      ? devis.filter((devi) => {
          switch (filtreAvancement) {
            case 'tous':
              return true;
            case 'enattente':
              return devi.date_acceptation === null && devi.date_refus === null && currentDate < new Date(devi.date_limite_acceptation);
            case 'delaisattentedepasse':
              return devi.date_acceptation === null && devi.date_refus === null && currentDate > new Date(devi.date_limite_acceptation);
            case 'accepte':
              return devi.date_acceptation;
            case 'enattentetraitement':
              return (
                devi.date_acceptation && devi.date_traitement === null && devi.date_limite_traitement && currentDate < new Date(devi.date_limite_traitement)
              );
            case 'delaistraitementdepasse':
              return (
                devi.date_acceptation && devi.date_traitement === null && devi.date_limite_traitement && currentDate > new Date(devi.date_limite_traitement)
              );
            case 'refuse':
              return devi.date_refus;
            case 'retracte':
              return devi.date_retractation;
            case 'traite':
              return devi.date_traitement;
            default:
              break;
          }
        })
      : [];

  return (
    <Fragment>
      <Helmet>
        <title>Devis - {Config.app_label}</title>
      </Helmet>
      <div className='px-4 py-4 md:py-3 bg-gray-100 rounded-tl-lg rounded-tr-lg flex flex-col sm:flex-row gap-5 items-start lg:items-center justify-between'>
        <div className='sm:flex items-center justify-between'>
          <h1 className='text-base sm:text-lg md:text-xl poppins-bold leading-normal text-secondary-1'>Devis</h1>
        </div>
        <div className='flex flex-col lg:flex-row gap-3 items-center'>
          <div className='flex flex-row items-center gap-3'>
            <span className='text-xs text-secondary-1 mb-1'>Du&nbsp;:</span>
            <InputDate
              value={from}
              onChange={(e) => {
                setFrom(e.target.value);
                localStorage.setItem(`AdminDevisScreen-From`, e.target.value);
              }}
            />
          </div>
          <div className='flex flex-row items-center gap-3'>
            <p className='text-xs text-gray-700 mb-1'>Au&nbsp;:</p>
            <InputDate
              value={to}
              onChange={(e) => {
                setTo(e.target.value);
                localStorage.setItem(`AdminDevisScreen-To`, e.target.value);
              }}
            />
          </div>
          <div className='flex flex-row items-center gap-3'>
            <p className='text-xs text-gray-700 mb-1'>Décision :</p>
            <select
              className='min-w-11 bg-white text-gray-600 border-gray-300 rounded-md font-normal text-sm p-[5.5px] border'
              value={filtreAvancement}
              onChange={(e) => {
                setFiltreAvancement(e.target.value as typeof filtreAvancement);
                localStorage.setItem(`AdminDevisScreen-filtreAvancement`, e.target.value);
              }}>
              <option value='tous'>Tous</option>
              <option value='enattente'>En attente</option>
              <option value='delaisattentedepasse'>Délais d'attente dépassé</option>
              <option value='accepte'>Accepté</option>
              <option value='enattentetraitement'>En attente de traitement</option>
              <option value='delaistraitementdepasse'>Délais de traitement dépassé</option>
              <option value='refuse'>Refusé</option>
              <option value='retracte'>Rétracté</option>
              <option value='traite'>Traité</option>
            </select>
          </div>
        </div>
        <button
          className='mr-1 py-1 px-6 flex flex-row items-center rounded-full bg-primary text-white'
          onClick={() => {
            setModalAjouterDevisEstVisible(true);
          }}>
          <PlusIcon className='h-4 w-4 inline mr-2 flex-none' />
          <span>Ajouter</span>
        </button>
      </div>
      <div>
        <div className='bg-white w-full overflow-x-auto rounded-b-lg shadow-lg'>
          {devis ? (
            afficheDevisFiltres().length > 0 ? (
              <table className='w-full min-w-[1200px] bg-white rounded-b-lg'>
                <thead className='text-sm text-gray-500 text-left border-b'>
                  <tr>
                    <th className='py-4 text-secondary-1 px-2 text-left text-sm tracking-normal leading-4 pl-4'>Numéro</th>
                    <th className='py-4 text-secondary-1 px-2 text-left text-sm tracking-normal leading-4'>Formation et session</th>
                    <th className='py-4 text-secondary-1 px-2 text-left text-sm tracking-normal leading-4'>Entreprise</th>
                    <th className='py-4 text-secondary-1 px-2 text-left text-sm tracking-normal leading-4'>Salarié</th>
                    <th className='py-4 text-secondary-1 px-2 text-left text-sm tracking-normal leading-4'>Fichier</th>
                    <th className='py-4 text-secondary-1 px-2 text-left text-sm tracking-normal leading-4'>Avancement</th>
                    <th className='py-4 text-secondary-1 px-2 text-left text-sm tracking-normal leading-4'>Créée le</th>
                    <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'></th>
                  </tr>
                </thead>
                <tbody>
                  {afficheDevisFiltres().map((devi) => (
                    <tr key={devi.id} className=' border-gray-100 border-b hover:bg-gray-100' onDoubleClick={() => navigate(`/admin/devis/${devi.id}`)}>
                      <td className='pl-4 pr-2 py-3 text-secondary-1/90 text-xs'>{devi.numero}</td>
                      <td className='px-4 py-3'>
                        <FormationSessionDisplay formation={devi.formation} groupe={devi.groupe} />
                      </td>
                      <td className='px-2 py-3'>
                        <EntrepriseDisplay entreprise={devi.entreprise} />
                      </td>
                      <td className='px-2 py-3'>{devi.user && <UserDisplay user={devi.user} />}</td>
                      <td className='px-2 py-3 text-secondary-1 text-xs'>{devi.fichier && <DownloadLink fichier={devi.fichier} titre={devi.titre} />}</td>
                      <td className='px-2 py-3 text-secondary-1 text-xs'>
                        <div>{afficheAvancement(devi)}</div>
                      </td>
                      <td className='px-2 py-3 text-secondary-1/90 text-xs'>
                        {devi.created_at
                          ? new Intl.DateTimeFormat('default', {
                              year: 'numeric',
                              month: 'numeric',
                              day: 'numeric',
                              hour12: false,
                            }).format(new Date(devi.created_at as string))
                          : ''}
                      </td>
                      <td className='px-4 py-3 text-gray-800 text-sm'>
                        <Menu placement='right-start'>
                          <MenuHandler>
                            <Button
                              variant='text'
                              className='w-9 h-9 p-1.5 rounded-full text-gray-500 block mx-auto text-center mr-0 hover:bg-white/100 active:bg-transparent'>
                              <EllipsisVerticalIcon className='h-6 w-6' />
                            </Button>
                          </MenuHandler>
                          <MenuList>
                            <MenuItem onClick={() => navigate(`/admin/devis/${devi.id}`)}>Voir</MenuItem>
                            <MenuItem
                              onClick={() => {
                                handleModaleSuppressionDevis(devi);
                              }}>
                              Supprimer
                            </MenuItem>
                          </MenuList>
                        </Menu>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className='text-center px-4 py-7'>Aucun devis ne répond au critères choisis.</div>
            )
          ) : (
            <div className='text-center px-4 py-7'>Vous n'avez pas encore de devis.</div>
          )}
        </div>
      </div>
      <ModalAjouterDevisAdmin visible={modalAjouterDevisEstVisible} setVisible={setModalAjouterDevisEstVisible} handleIndex={handleIndex} />
      <ModalSuppressionDevis visible={modalSuppressionDevisEstVisible} setVisible={setModalSuppressionDevisEstVisible} handleSupprime={handleSupprime} />
    </Fragment>
  );
};

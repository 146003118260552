import { ArrowRightIcon } from '@heroicons/react/20/solid';
import { Dialog, DialogHeader, DialogBody, DialogFooter, Button } from '@material-tailwind/react';
import { FunctionComponent } from 'react';

/**
 * Ce composant permet d'afficher une Modale pour que le dirigeant puisse refuser un devis.
 *
 * @param visible - Précise si la modale et ouverte ou non. Provient du parent.
 * @param setVisible - Accesseur qui modifie la visibilité de la modale. Provient du parent.
 * @param handleRefuse - Callback qui permet de refuser un devis. Provient du parent.
 */
export const ModalRefusDevisEntreprise: FunctionComponent<{
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  handleRefuse: () => void;
}> = ({ visible, setVisible, handleRefuse }) => {
  return (
    <Dialog open={visible} size='xs' handler={setVisible}>
      <DialogHeader>Refus du devis</DialogHeader>
      <DialogBody divider>
        <div className='text-secondary-1 flex items-start mb-3'>
          <ArrowRightIcon className='flex-none w-5 h-5 mr-3' strokeWidth={2} />
          <div className='grow text-sm'>En cliquant sur le bouton confirmer, vous allez nous indiquer que vous avez refusé le devis.</div>
        </div>
        <div className='text-secondary-1 flex items-start mb-3'>
          <ArrowRightIcon className='flex-none w-5 h-5 mr-3' strokeWidth={2} />
          <div className='grow text-sm'>Ce devis ne sera alors plus valide.</div>
        </div>
        <div className='text-secondary-1 flex items-start mb-3'>
          <ArrowRightIcon className='flex-none w-5 h-5 mr-3' strokeWidth={2} />
          <div className='grow text-sm'>Vous pourrez toutefois recevoir d'autres propositions de notre part.</div>
        </div>
      </DialogBody>
      <DialogFooter>
        <Button variant='text' color='gray' onClick={() => setVisible(false)} className='mr-1 !rounded-full'>
          Annuler
        </Button>
        <Button
          variant='filled'
          color='orange'
          onClick={() => {
            handleRefuse();
            setVisible(false);
          }}>
          Confirmer
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

import type { FunctionComponent } from 'react';

/**
 * Ce composant affiche une barre de progression.
 *
 * @param color - Couleur de la barre de progression.
 * @param value - Valeur pour le remplissage de la barre de progression.
 */
export const ProgressBar: FunctionComponent<{
  color: string;
  value: number;
}> = ({ color, value }) => {
  /**
   * Classes Tailwind pour styliser le composant.
   *
   * @var
   * @defaultValue `'h-1.5 rounded-full'`
   */
  let barClass = 'h-1.5 rounded-full';
  const barStyle = {
    width: value + '%',
  };

  switch (color) {
    case 'red':
      barClass += ' bg-red-600';
      break;
    case 'amber':
      barClass += ' bg-amber-600';
      break;
    case 'indigo':
      barClass += ' bg-indigo-600';
      break;
    case 'green':
      barClass += ' bg-green-600';
      break;
    default:
      barClass += ' bg-blue-600';
  }

  return (
    <div className='w-full bg-gray-200 rounded-full h-1.5'>
      <div className={barClass} style={barStyle}></div>
    </div>
  );
};

import { EyeIcon, EyeSlashIcon } from '@heroicons/react/20/solid';
import { ChangeEvent, ChangeEventHandler, Fragment, FunctionComponent, useCallback, useState } from 'react';

/**
 * Composant InputPassword.
 *
 * @param label - Libellé du champ de saisie.
 * @param value - Valeur du champ de saisie.
 * @param onChange - Fonction de rappel appelée lorsque la valeur du champ de saisie change.
 * @param error - Indique si le champ de saisie a une erreur.
 * @param placeholder - Placeholder du champ de saisie.
 * @return Composant InputPassword.
 */
export const InputPassword: FunctionComponent<{
  error?: boolean;
  label?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  placeholder?: string;
  value?: string | number | readonly string[];
}> = ({ label, value, onChange = undefined, error = false, placeholder }) => {
  /**
   * @constant Elle permet de définir éventuellement un `id` pour le `input` afin que les labels faasent bien référence au champ.
   */
  const inputId = label ? `input-${label.toLowerCase().replace(' ', '-')}` : undefined;

  /**
   * Gère l'appui sur un bouton pour voir le mot de passe.
   */
  const [showedPassword, setShowedPassword] = useState<boolean>(false);

  /**
   * Gère le changement de valeur dans le composant parent.
   *
   * @param e - L'événement de changement de valeur du champ de saisie.
   */
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(e);
      }
    },
    [onChange],
  );

  return (
    <Fragment>
      {label && (
        <label className={`text-xs ${error ? 'text-red-500' : ''}`} htmlFor={inputId}>
          {label}
        </label>
      )}
      <div className='relative w-full min-w-[200px]'>
        <input
          type={showedPassword ? 'text' : 'password'}
          value={value !== undefined && value !== null ? value : ''}
          onChange={handleChange}
          id={inputId}
          placeholder={placeholder}
          className={`h-full w-full px-4 py-2 text-sm leading-none text-left text-secondary-1 border border-gray-400 rounded outline-none focus:border-secondary-2 ${
            error ? 'border-red-500' : ''
          }`}
        />
        <div
          className='grid place-items-center absolute text-gray-500 top-2/4 right-3 -translate-y-2/4 w-4 h-4'
          onClick={() => setShowedPassword(!showedPassword)}>
          {showedPassword ? <EyeSlashIcon className='w-4 h-4' /> : <EyeIcon className='w-4 h-4' />}
        </div>
      </div>
    </Fragment>
  );
};

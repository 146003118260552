import { Fragment, FunctionComponent } from 'react';
import { EmailVerifie } from '../EmailVerifie';

/**
 * Composant ReadOnlyText.
 *
 * @param label - Libellé du champ de saisie en lecture seule.
 * @param value - Valeur du champ de saisie en lecture seule.
 * @param error - Indique si le champ de saisie en lecture seule a une erreur.
 * @return Composant ReadOnlyText.
 */
export const ReadOnlyEmail: FunctionComponent<{
  error?: boolean;
  estVerifie?: boolean;
  label?: string;
  value?: string | number | readonly string[] | null;
}> = ({ label, value, estVerifie = false, error = false }) => {
  return (
    <Fragment>
      {label && <label className={`text-xs ${error ? 'text-red-500' : ''}`}>{label}</label>}
      <div className='flex flex-row items-center py-2 px-4 border border-gray-200 rounded cursor-not-allowed'>
        <p className={`text-gray-600 items-center ${error ? 'text-red-500' : ''}`}>
          {value !== undefined && value !== null && value !== '' ? value : 'Non renseigné'}
        </p>
        {estVerifie && <EmailVerifie />}
      </div>
    </Fragment>
  );
};

import { ChangeEvent, ChangeEventHandler, Fragment, FunctionComponent, useCallback } from 'react';

/**
 * Composant InputColor.
 *
 * @param label - Libellé du champ de saisie.
 * @param value - Valeur du champ de saisie.
 * @param onChange - Fonction de rappel appelée lorsque la valeur du champ de saisie change.
 * @param error - Indique si le champ de saisie a une erreur.
 * @param placeholder - Placeholder du champ de saisie.
 * @return Composant InputColor.
 */
export const InputColor: FunctionComponent<{
  error?: boolean;
  label?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  placeholder?: string;
  value?: string | number | readonly string[];
}> = ({ label, value, onChange = undefined, error = false, placeholder }) => {
  /**
   * @constant Elle permet de définir éventuellement un `id` pour le `input` afin que les labels faasent bien référence au champ.
   */
  const inputId = label ? `input-${label.toLowerCase().replace(' ', '-')}` : undefined;

  /**
   * Gère le changement de valeur dans le composant parent.
   *
   * @param e - L'événement de changement de valeur de la couleur.
   */
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(e);
      }
    },
    [onChange],
  );

  return (
    <Fragment>
      {label && (
        <label className={`text-xs ${error ? 'text-red-500' : ''}`} htmlFor={inputId}>
          {label}
        </label>
      )}
      <input
        className={`relative w-full px-4 py-2 text-sm leading-none text-left min-h-[36px] cursor-pointer  text-secondary-1 border border-gray-400 rounded outline-none focus:border-secondary-2 ${
          error ? 'border-red-500' : ''
        }`}
        type='color'
        value={value !== undefined && value !== null ? value : ''}
        onChange={handleChange}
        id={inputId}
        placeholder={placeholder}
      />
    </Fragment>
  );
};

import { Dialog, DialogHeader, DialogBody, DialogFooter, Button } from '@material-tailwind/react';
import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useApi } from '../../../providers/ApiProvider';
import { GoodApiResponse, ErrorApiResponse, BaseIndexParams } from '../../../types/api';
import { LoadingAbsolute } from '../../LoadingAbsolute';
import { handleError } from '../../../utils/ErrorHandler';
import { Demande, Groupe } from '../../../types/AdminRessources';
import { ajouterJour } from '../../../utils/Temps';
import { DatesDisplay } from '../../DatesDisplay';
import { CalendarDaysIcon } from '@heroicons/react/24/outline';
/**
 * Ce composant permet d'afficher une Modale pour qu'un admin puisse assigner un nouveau groupe à une demande de formation.
 *
 * @param visible - Précise si la modale et ouverte ou non. Provient du parent.
 * @param setVisible - Accesseur qui modifie la visibilité de la modale. Provient du parent.
 * @param demande - Demande. Provient du parent.
 * @param handleShow - Callback qui permet de recharger les données du composant parent. Provient du parent.
 * @param handleIndex - Callback qui permet de recharger les données du composant parent. Provient du parent.
 */
export const ModalChangerGroupeDemande: FunctionComponent<{
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  demande: Demande;
  handleShow: () => void;
  handleIndex: () => void;
}> = ({ visible, setVisible, demande, handleShow, handleIndex }) => {
  /**
   * On stocke si on est en train d'utiliser l'API ou non.
   */
  const [apiIsLoading, setApiIsLoading] = useState(false);

  /**
   * On stocke les groupes liés à une formation.
   */
  const [groupes, setGroupes] = useState<Groupe[] | null>(null);

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  /**
   * Date du jour.
   * @constant
   */
  const currentDate = new Date();

  /**
   * Au chargement du composant on effectue l'appel qui va chercher les groupes.
   */
  useEffect(() => {
    if (visible) {
      handleListeGroupes();
    }
  }, [client, visible]);

  /**
   * Cette méthode va chercher les groupes.
   */
  const handleListeGroupes = () => {
    setApiIsLoading(true);

    client
      .get<GoodApiResponse<Groupe[]>, BaseIndexParams>('/admin/groupes', {
        params: {
          no_pagination: true,
          formation_id: demande.formation.id,
          date_debut_from: ajouterJour(currentDate, -1),
          date_debut_to: ajouterJour(currentDate, 180),
          sort: 'date_debut',
        },
      })
      .then((response) => {
        setGroupes(response.data.data);
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Cette méthode permet d'ajouter un nouveau dirigeant d'entreprise au cas ou le premier cède sa place.
   *
   * @param userId
   */
  const handleChangeGroupe = (groupeId: Groupe['id']) => {
    setApiIsLoading(true);

    client
      .patch<GoodApiResponse>(`/admin/demandes/${demande.id}/change-groupe/${groupeId}`)
      .then(() => {
        handleShow();
        handleIndex();
        setVisible(false);
        toast.success('session modifiée');
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  return (
    <Fragment>
      <Dialog open={visible} size='xs' handler={setVisible}>
        <DialogHeader>Choisir la nouvelle session</DialogHeader>
        <DialogBody divider>
          <div className='bg-white w-full overflow-x-auto overflow-y-auto h-96'>
            {groupes && groupes.length > 0 ? (
              <table className='table-fixed min-w-full bg-white rounded-b-lg'>
                <tbody>
                  {groupes.map((groupe) => (
                    <tr key={groupe.id} className='border-gray-100 border-b hover:bg-gray-100'>
                      <td className='px-4 py-3 text-gray-800 text-sm'>
                        <div className='montserrat-semibold'>{groupe.nom}</div>
                        <div className='flex flex-row items-center'>
                          <CalendarDaysIcon className='w-5 h-5 mr-2 flex-none' />
                          <DatesDisplay date_start={groupe.date_debut} date_end={groupe.date_fin} />
                        </div>
                        {groupe.site && <div>{groupe.site.nom}</div>}
                      </td>
                      <td className='px-4 py-3 text-gray-800 text-sm'>
                        <Button
                          variant='filled'
                          className=' disabled:bg-primary/30 disabled:border-primary/30'
                          color='orange'
                          disabled={demande.groupe !== null && demande.groupe.id === groupe.id}
                          onClick={() => {
                            handleChangeGroupe(groupe.id);
                          }}>
                          Intégrer cette session
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className='text-center px-4 py-7'>Il n'y a pas d'autre session pour la formation liée à la demande.</div>
            )}
          </div>
        </DialogBody>
        <DialogFooter>
          <Button
            variant='text'
            color='gray'
            onClick={() => {
              setVisible(false);
            }}
            className='mr-1'
            disabled={apiIsLoading}>
            Fermer
          </Button>
        </DialogFooter>
        {apiIsLoading ? <LoadingAbsolute /> : ''}
      </Dialog>
    </Fragment>
  );
};

import { BriefcaseIcon, CurrencyEuroIcon, StarIcon, UserIcon } from '@heroicons/react/24/outline';
import { FunctionComponent } from 'react';
import { DashboardSynthese } from '../../types/AdminRessources';

/**
 * Ce composant est un encart du tableau de bord administrateur qui présente une synthèse de plusieurs données.
 *
 * @param synthese - Objet qui rassemble plusieurs données à présenter.
 */
export const DashboardAdminSynthese: FunctionComponent<{ synthese: DashboardSynthese }> = ({ synthese }) => {
  return (
    <div className='h-full bg-white rounded-md shadow-md p-5'>
      <h3 className='text-secondary-2 poppins-semibold text-center md:text-left mb-7'>Ces 30 derniers jours</h3>
      <p className='text-xs poppins-medium text-gray-600 tracking-normal font-normal leading-5 mt-5 mb-2'>Chiffre d'affaires estimé</p>
      <div className='flex flex-row items-center gap-3'>
        <div className='p-2 bg-gradient-to-r from-orange-900 to-primary/90 rounded-lg text-white flex-none'>
          <CurrencyEuroIcon className='w-9 h-9' />
        </div>
        <div className='text-secondary-1'>
          <h5 className='leading-5 poppins-bold text-4xl mt-1'>
            {new Intl.NumberFormat('default', { style: 'currency', currency: 'EUR' }).format(synthese.caestime)}
          </h5>
          <p className='text-sm poppins-medium tracking-normal font-normal mt-2'>
            Sur {synthese.nbFormations} formation{synthese.nbFormations > 1 ? 's' : ''}
          </p>
        </div>
      </div>
      <p className='text-xs poppins-medium text-gray-600 tracking-normal font-normal leading-5 mt-7 mb-2'>Inscriptions</p>
      <div className='flex gap-2'>
        <div className='basis-1/2  flex flex-row items-start gap-3'>
          <div className='p-2 bg-gradient-to-r from-secondary-1 to-secondary-1/70 rounded-lg text-white flex-none'>
            <UserIcon className='w-5 h-5' strokeWidth={2} />
          </div>
          <div className='text-secondary-1'>
            <h5 className='mb-1 leading-5 poppins-bold text-lg'>{synthese.nbInscriptionApprenants}</h5>
            <p className='text-xs poppins-medium tracking-normal font-normal leading-3'>Apprenant{synthese.nbInscriptionApprenants > 1 ? 's' : ''}</p>
          </div>
        </div>
        <div className='basis-1/2  flex flex-row items-start gap-3'>
          <div className='p-2 bg-gradient-to-r from-secondary-1 to-secondary-1/70 rounded-lg text-white flex-none'>
            <BriefcaseIcon className='w-5 h-5' strokeWidth={2} />
          </div>
          <div className='text-secondary-1'>
            <h5 className='mb-1 leading-5 poppins-bold text-lg'>{synthese.nbInscriptionEntreprises}</h5>
            <p className='text-xs poppins-medium tracking-normal font-normal leading-3'>Entreprise{synthese.nbInscriptionEntreprises > 1 ? 's' : ''}</p>
          </div>
        </div>
      </div>
      <p className='text-xs poppins-medium text-gray-600 tracking-normal font-normal leading-5 mt-5 mb-2'>Modération</p>
      <div className='flex gap-2'>
        <div className='basis-1/2 flex flex-row items-start gap-3'>
          <div className='p-2 bg-gradient-to-r from-secondary-1 to-secondary-1/70 rounded-lg text-white flex-none'>
            <StarIcon className='w-5 h-5' strokeWidth={2} />
          </div>
          <div className='text-secondary-1'>
            <h5 className='mb-1 leading-5 poppins-bold text-lg'>{synthese.nbAvis}</h5>
            <p className='text-xs poppins-medium tracking-normal font-normal leading-3'>Avis</p>
          </div>
        </div>
        <div className='basis-1/2 flex flex-row items-start gap-3'>
          <div className='p-2 bg-gradient-to-r from-secondary-1 to-secondary-1/70 rounded-lg text-white flex-none'>
            <BriefcaseIcon className='w-5 h-5' strokeWidth={2} />
          </div>
          <div className='text-secondary-1'>
            <h5 className='mb-1 leading-5 poppins-bold text-lg'>{synthese.nbEntrepriseAValider}</h5>
            <p className='text-xs poppins-medium tracking-normal font-normal leading-3'>Entreprise{synthese.nbEntrepriseAValider > 1 ? 's' : ''} à valider</p>
          </div>
        </div>
      </div>
    </div>
  );
};

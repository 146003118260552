import { FunctionComponent } from 'react';
import toast from 'react-hot-toast';
import { Navigate, Outlet } from 'react-router-dom';
import { useUser } from '../providers/UserProvider';
import { RoleEnum } from '../types/auth';

/**
 * Ce layout contient la mise en page de la partie administrateur et ce qui s'y rapporte.
 */
export const RoleAdminLayout: FunctionComponent = () => {
  /**
   * On a besoin des informations de l'utilisateur.
   */
  const { user } = useUser();

  /**
   * Si l'utilisateur n'est pas un gestionnaire de centre on le redirige vers la page de connexion.
   * Sinon on affiche le contenu des routes de l'administrateur.
   */
  if (user !== null && !user.roles.includes(RoleEnum.admin) && !user.roles.includes(RoleEnum.dev)) {
    toast.error('Accès refusé.');
    return <Navigate to='/connexion' />;
  } else {
    return <Outlet />;
  }
};

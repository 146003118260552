import { Dialog, DialogHeader, DialogBody, DialogFooter, Button } from '@material-tailwind/react';
import { FunctionComponent, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useApi } from '../../../providers/ApiProvider';
import { GoodApiResponse, ErrorApiResponse } from '../../../types/api';
import { LoadingAbsolute } from '../../LoadingAbsolute';
import { Avis } from '../../../types/SalariesRessources';
import { InputTextarea } from '../../Inputs/InputTextarea';
import { InputSwitch } from '../../Inputs/InputSwitch';
import { StarEmpty } from '../../Stars/StarEmpty';
import { StarFull } from '../../Stars/StarFull';
import { ReadOnlyTextarea } from '../../Inputs/ReadOnlyTextarea';
import { handleError } from '../../../utils/ErrorHandler';

/**
 * Ce composant permet d'afficher une Modale pour qu'un salarié puisse modifier son avis'.
 *
 * @param visible - Précise si la modale et ouverte ou non. Provient du parent.
 * @param setVisible - Accesseur qui modifie la visibilité de la modale. Provient du parent.
 * @param avis - Avis à modifier. Provient du parent.
 * @param handleIndex - Callback qui permet de recharger les données du composant parent. Provient du parent.
 */
export const ModalModificationAvis: FunctionComponent<{
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  avis: Avis;
  handleIndex: () => void;
}> = ({ visible, setVisible, avis, handleIndex }) => {
  /**
   * On stocke si on est en train d'utiliser l'API ou non.
   */
  const [apiIsLoading, setApiIsLoading] = useState(false);

  /**
   * C'est la note que l'utilisateur souhaite donner à l'avis.
   */
  const [note, setNote] = useState<Avis['note'] | null>(null);

  /**
   * C'est le texte de l'avis à proprement parler.
   */
  const [description, setDescription] = useState<Avis['description']>('');

  /**
   * Permet d'afficher son nom complet de l'utilisateur.
   */
  const [afficheNomComplet, setAfficheNomComplet] = useState<Avis['affiche_nom_complet']>(false);

  /**
   * Permet à l'utilisateur d'afficher ou pas le nom de son entreprise.
   */
  const [afficheNomEntreprise, setAfficheNomEntreprise] = useState<Avis['affiche_nom_entreprise']>(false);

  /**
   * On stocke si l'avis à été validé ou non afin de savoir si on peut modifier la description de celui-ci.
   */
  const [estValide, setEstValide] = useState<Avis['est_valide']>(false);

  /**
   * Permet à l'utilisateur d'afficher où pas son avatar.
   */
  const [afficheAvatar, setAfficheAvatar] = useState<Avis['affiche_avatar']>(false);

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  /**
   * On initialise les état locaux lorsque l'avis est chargé dans le composant.
   */
  useEffect(() => {
    if (avis) {
      setNote(avis.note);
      setDescription(avis.description);
      setAfficheAvatar(avis.affiche_avatar);
      setAfficheNomComplet(avis.affiche_nom_complet);
      setAfficheNomEntreprise(avis.affiche_nom_entreprise);
      setEstValide(avis.est_valide);
    }
  }, [avis]);

  /**
   * Cette méthode permet de modifier l'avis.
   */
  const handleModifie = () => {
    const params: {
      note: Avis['note'];
      description: Avis['description'];
      affiche_avatar: Avis['affiche_avatar'];
      affiche_nom_complet: Avis['affiche_nom_complet'];
      affiche_nom_entreprise: Avis['affiche_nom_entreprise'];
    } = {
      note: note as number,
      description: description,
      affiche_avatar: afficheAvatar,
      affiche_nom_complet: afficheNomComplet,
      affiche_nom_entreprise: afficheNomEntreprise,
    };

    setApiIsLoading(true);

    client
      .patch<
        GoodApiResponse,
        {
          note: Avis['note'];
          description: Avis['description'];
          affiche_avatar: Avis['affiche_avatar'];
          affiche_nom_complet: Avis['affiche_nom_complet'];
          affiche_nom_entreprise: Avis['affiche_nom_entreprise'];
        }
      >(`/salarie/avis/${avis.token}`, params)
      .then(() => {
        handleIndex();
        toast.success('Avis modifié.');
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  return (
    <Dialog open={visible} size='xs' handler={setVisible}>
      <DialogHeader>Modifier un avis</DialogHeader>
      <DialogBody divider>
        <div className='flex md:flex-row lg:flex-row md:mb-5'>
          <span className='pr-2'>Note&nbsp;:</span>
          <div
            onClick={() => {
              if (!avis.est_valide) {
                setNote(1);
              }
            }}>
            {note && note >= 1 ? (
              <StarFull className={`w-5 h-5 ${!avis.est_valide ? 'cursor-pointer text-primary' : ''}`} />
            ) : (
              <StarEmpty className={`w-5 h-5 ${!avis.est_valide ? 'cursor-pointer text-primary' : ''}`} />
            )}
          </div>
          <div
            onClick={() => {
              if (!avis.est_valide) {
                setNote(2);
              }
            }}>
            {note && note >= 2 ? (
              <StarFull className={`w-5 h-5 ${!avis.est_valide ? 'cursor-pointer text-primary' : ''}`} />
            ) : (
              <StarEmpty className={`w-5 h-5 ${!avis.est_valide ? 'cursor-pointer text-primary' : ''}`} />
            )}
          </div>
          <div
            onClick={() => {
              if (!avis.est_valide) {
                setNote(3);
              }
            }}>
            {note && note >= 3 ? (
              <StarFull className={`w-5 h-5 ${!avis.est_valide ? 'cursor-pointer text-primary' : ''}`} />
            ) : (
              <StarEmpty className={`w-5 h-5 ${!avis.est_valide ? 'cursor-pointer text-primary' : ''}`} />
            )}
          </div>
          <div
            onClick={() => {
              if (!avis.est_valide) {
                setNote(4);
              }
            }}>
            {note && note >= 4 ? (
              <StarFull className={`w-5 h-5 ${!avis.est_valide ? 'cursor-pointer text-primary' : ''}`} />
            ) : (
              <StarEmpty className={`w-5 h-5 ${!avis.est_valide ? 'cursor-pointer text-primary' : ''}`} />
            )}
          </div>
          <div
            onClick={() => {
              if (!avis.est_valide) {
                setNote(5);
              }
            }}>
            {note && note >= 5 ? (
              <StarFull className={`w-5 h-5 ${!avis.est_valide ? 'cursor-pointer text-primary' : ''}`} />
            ) : (
              <StarEmpty className={`w-5 h-5 ${!avis.est_valide ? 'cursor-pointer text-primary' : ''}`} />
            )}
          </div>
        </div>
        <div className='p-3 bg-blue-gray-50 rounded-lg mb-4'>
          <p className='text-xs text-secondary-1'>
            <h6 className='text-base poppins-medium text-primary'>Information&nbsp;:</h6>
            Veuillez éviter les propos&nbsp;: pénalement répréhensibles, incitatifs à commettre une infraction , illicites, menaçants, injurieux,
            blasphématoires, indécent, diffamatoires, racistes, discriminatoires, obscènes, sexistes, portant atteinte à la vie privée d'autrui.
          </p>
        </div>
        <div className='mb-5 leading-4 text-sm'>
          {!estValide ? <InputTextarea value={description} onChange={(e) => setDescription(e.target.value)} /> : <ReadOnlyTextarea value={description} />}
        </div>
        <div>
          <InputSwitch label='Afficher le nom complet' checked={afficheNomComplet} onChange={() => setAfficheNomComplet(!afficheNomComplet)} />
        </div>
        <div className='my-2'>
          <InputSwitch label='Afficher le nom entreprise' checked={afficheNomEntreprise} onChange={() => setAfficheNomEntreprise(!afficheNomEntreprise)} />
        </div>
        <div>
          <InputSwitch label="Afficher l'avatar" checked={afficheAvatar} onChange={() => setAfficheAvatar(!afficheAvatar)} />
        </div>
      </DialogBody>
      <DialogFooter>
        <Button
          variant='text'
          color='gray'
          onClick={() => {
            setVisible(false);
          }}
          className='mr-1 !rounded-full'
          disabled={apiIsLoading}>
          Annuler
        </Button>
        <Button
          variant='filled'
          color='orange'
          onClick={() => {
            handleModifie();
            setVisible(false);
          }}
          disabled={note === null}>
          Confirmer
        </Button>
      </DialogFooter>
      {apiIsLoading ? <LoadingAbsolute /> : ''}
    </Dialog>
  );
};

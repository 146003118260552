import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { InputNumber } from '../../../components/Inputs/InputNumber';
import { LoadingAbsolute } from '../../../components/LoadingAbsolute';
import { Config } from '../../../config/config';
import { useApi } from '../../../providers/ApiProvider';
import { Delais } from '../../../types/AdminRessources';
import { ErrorApiResponse, GoodApiResponse } from '../../../types/api';
import { handleError } from '../../../utils/ErrorHandler';
import { SubmitSave } from '../../../components/SubmitSave';

export const AdminParamDelaisScreen: FunctionComponent = () => {
  /**
   * On stocke si on est en train d'utiliser l'API ou non.
   */
  const [apiIsLoading, setApiIsLoading] = useState(false);

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  /**
   * Permet au composant de changer de page.
   */
  const navigate = useNavigate();

  /**
   * Contient un état général des valeurs des champs du formulaire ainsi que les champs qui ont reçu une erreur lors de l'envoi du formulaire.
   */
  const [formInputs, setFormInputs] = useState<{ [K in keyof Delais]: { value: Delais[K]; error: boolean } }>({
    delai_admin_reponse_demande: { value: '', error: false },
    delai_admin_traitement_devis: { value: '', error: false },
    delai_entreprise_acceptation_devis: { value: '', error: false },
    delai_entreprise_retractation_devis: { value: '', error: false },
  });

  /**
   * On recharge les données lorsque l'on arrive sur le composant.
   */
  useEffect(() => {
    handleIndex();
  }, [client]);

  /**
   * On va chercher les differents délais et on met à jour un état local.
   */
  const handleIndex = () => {
    if (apiIsLoading === true) {
      return;
    }

    setApiIsLoading(true);
    client
      .get<GoodApiResponse<Delais>>(`/admin/settings`)
      .then((reponse) => {
        const updatedInputs = Object.keys(reponse.data.data).reduce((acc, key) => {
          return { ...acc, [key]: { value: parseInt(reponse.data.data[key as keyof Delais]), error: false } };
        }, formInputs);
        setFormInputs(updatedInputs);
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error, () => {
          navigate('/mon-espace');
        });
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Met à jour, par le réseau, les délais à partir du formulaire via un état local.
   */
  const handleUpdate = () => {
    setApiIsLoading(true);

    let ressource: Partial<Delais> = {};
    Object.keys(formInputs).forEach((key) => {
      if (formInputs[key as keyof Delais]?.value !== null) {
        ressource = { ...ressource, [key]: formInputs[key as keyof Delais]?.value.toString() };
      }
    });

    client
      .patch<GoodApiResponse<Delais>>(`/admin/settings`, ressource)
      .then(() => {
        toast.success(`Modification éffectuée`);
        navigate('/mon-espace');
      })
      .catch((error: ErrorApiResponse<Delais>) => {
        handleError(error, () => {
          let object = formInputs;
          Object.keys(error.response.data.errors).forEach((key) => {
            if (error.response.data.errors[key as keyof Delais]) {
              object = { ...object, [key]: { value: parseInt(formInputs[key as keyof Delais]?.value), error: true } };
            }
          }, formInputs);
          setFormInputs(object);
        });
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Cette méthode permet de gérer l'envoi du formulaire en fonction du mode dans lequel on se trouve.
   * L'envoi ne fait rien si on est en mode voir.
   *
   * @param event - Évenement de l'envoie.
   */
  const handleSubmit = (event: { preventDefault: () => void }): void => {
    event.preventDefault();

    if (apiIsLoading === true) {
      return;
    }

    handleUpdate();
  };

  /**
   * Cette méthode permet de gérer les changements à l'interieur des champs du formulaire et de gérer les valeurs des champs via un état local.
   *
   * @param field - Champs du formulaire dont on doit stocker la valeur et s'il est en erreur.
   */
  const handleInputChange = (field: keyof Delais) => {
    return (event: { target: { value: string } }) => {
      setFormInputs((prev) => ({
        ...prev,
        [field]: { value: event.target.value, error: false },
      }));
    };
  };

  return (
    <Fragment>
      <Helmet>
        <title>Délais légaux - {Config.app_label}</title>
      </Helmet>
      <form className='relative' onSubmit={handleSubmit}>
        <div className='px-4 py-4 md:py-3 bg-gray-100 rounded-tl-lg rounded-tr-lg flex flex-col sm:flex-row gap-7 items-center'>
          <div className='sm:flex items-center justify-between'>
            <h1 className='text-base sm:text-lg md:text-xl poppins-bold leading-normal text-secondary-1'>Délais légaux</h1>
          </div>
        </div>
        <div className='bg-white w-full overflow-x-auto rounded-b-lg shadow-lg p-5'>
          <div className='container mx-auto bg-white rounded'>
            <fieldset className='pb-7 mb-7 border-b'>
              <legend className='block text-left mb-3 text-xl text-secondary-1'>Administrateur</legend>
              <div className='mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 pt-3'>
                <div className='relative'>
                  <InputNumber
                    label='Délai de réponse aux demandes des entreprises'
                    value={formInputs.delai_admin_reponse_demande.value as string}
                    onChange={handleInputChange('delai_admin_reponse_demande')}
                    error={formInputs.delai_admin_reponse_demande.error}
                    unit='Jours'
                  />
                </div>
                <div>
                  <InputNumber
                    label='Délai de traitement après acceptation devis'
                    value={formInputs.delai_admin_traitement_devis.value as string}
                    onChange={handleInputChange('delai_admin_traitement_devis')}
                    error={formInputs.delai_admin_traitement_devis.error}
                    unit='Jours'
                  />
                </div>
              </div>
            </fieldset>
            <fieldset className='pb-7 mb-7'>
              <legend className='block text-left mb-3 text-xl text-secondary-1'>Entreprise</legend>
              <div className='mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 pt-3'>
                <div>
                  <InputNumber
                    label="Délai d'acceptation des devis"
                    value={formInputs.delai_entreprise_acceptation_devis.value as string}
                    onChange={handleInputChange('delai_entreprise_acceptation_devis')}
                    error={formInputs.delai_entreprise_acceptation_devis.error}
                    unit='Jours'
                  />
                </div>
                <div>
                  <InputNumber
                    label='Délai de rétractation après acceptation devis'
                    value={formInputs.delai_entreprise_retractation_devis.value as string}
                    onChange={handleInputChange('delai_entreprise_retractation_devis')}
                    error={formInputs.delai_entreprise_retractation_devis.error}
                    unit='Jours'
                  />
                </div>
              </div>
            </fieldset>
          </div>

          <div className='container mx-auto w-11/12 xl:w-full'>
            <div className='w-full py-4 sm:px-0 bg-white flex justify-end'>
              <SubmitSave />
            </div>
          </div>
        </div>
        {apiIsLoading ? <LoadingAbsolute /> : ''}
      </form>
    </Fragment>
  );
};

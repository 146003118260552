import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { Fragment, FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Container } from '../components/Container';
import { FilDariane } from '../components/FilDariane';
import { MainMenu } from '../components/MainMenu';
import { Config } from '../config/config';

/**
 * Cette page affiche .
 */
export const CtaFormationNonTrouvee: FunctionComponent = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Formation non trouvée - {Config.app_label}</title>
      </Helmet>
      <div
        className='bg-cover bg-center h-auto text-white py-3 px-4 lg:px-8 xl:px-11 object-fill relative poppins'
        style={{
          backgroundImage: "url('/assets/header-fiche-formations.webp')",
        }}>
        <div className='absolute top-0 right-0 left-0 bottom-0 w-full h-full bg-secondary-2/70'></div>
        <div className='relative flex flex-col items-center h-full mx-auto'>
          <MainMenu className='relative pb-20' />
        </div>
        <div className='lg:max-w-xl xl:max-w-7xl w-full relative h-full 2xl:px-8 mt-20 lg:mt-[120px] text-center md:text-left mr-auto xl:mx-auto'>
          <h1 className='text-white text-3xl w-full uppercase poppins-semibold'>Formation non trouvée</h1>
          <FilDariane className='bg-transparent w-full text-white pl-0 !mb-2 mt-2' items={[{ label: 'Formation non trouvée', link: null }]} />
        </div>
      </div>
      <Container className='bg-gray-100 h-full'>
        <div className='md:flex md:flex-row w-full'>
          <div className='lg:basis-2/3 h-full mt-11'>
            <div className='md:mr-4 mb-11 text-gray-700'>
              <h3 className='text-xl poppins-semibold'>La formation que vous recherchez n'est pas accessible. </h3>
              <br />
              <div className='poppins-semibold pb-2'>Pour aller plus loin :</div>
              <ul>
                <li className='flex flex-row items-center gap-3'>
                  <div className='flex-none'>
                    <div className='rounded-full bg-primary'>
                      <ChevronRightIcon className='w-5 h-5 text-white' />
                    </div>
                  </div>
                  <Link to='/' className='poppins grow'>
                    Rechercher d'autres formations.
                  </Link>
                </li>
                <li className='flex flex-row items-center gap-3'>
                  <div className='flex-none'>
                    <div className='rounded-full bg-primary'>
                      <ChevronRightIcon className='w-5 h-5 text-white' />
                    </div>
                  </div>
                  <a href='https://www.compagnons-du-devoir.com/nous-contacter' className='poppins grow'>
                    Nous contacter.
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Container>
    </Fragment>
  );
};

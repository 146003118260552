import { Fragment, FunctionComponent } from 'react';
import { DropdownRessource } from '../../types';

/**
 * Composant ReadOnlySelect.
 *
 * @param label - Libellé du champ de saisie en lecture seule.
 * @param value - Valeur du champ de saisie  en lecture seule.
 * @param error - Indique si le champ de saisie en lecture seule a une erreur.
 * @param options - Options du champ.
 * @return Composant ReadOnlySelect.
 */
export const ReadOnlySelect: FunctionComponent<{
  error?: boolean;
  label?: string;
  value: string | number | readonly string[] | null;
  options: DropdownRessource[];
}> = ({ label, value, options, error = false }) => {
  const option = options && options.length > 0 ? options.find((option) => option.id === value) : null;

  return (
    <Fragment>
      {label && <label className={`text-xs text-gray-600 ${error ? 'text-red-500' : ''}`}>{label}</label>}
      <p
        className={`text-gray-600 text-sm font-normal items-center py-2 px-4 border border-gray-200 rounded cursor-not-allowed ${error ? 'text-red-500' : ''}`}>
        {value !== undefined && value !== null && value !== '' && option && option.label ? option.label : 'Non renseigné'}
      </p>
    </Fragment>
  );
};

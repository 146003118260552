/**
 * @const joursSemaineAbrege - Liste les jours de la semaine en abrégé en commençant par lundi
 */
export const joursSemaineAbrege = ['Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa', 'Di'];

/**
 * @const libelleMois - Liste les mois de l'année en français
 */
export const libelleMois = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];

/**
 * Retourne le nombre de jours que contient un mois pour une année donnée.
 *
 * @param annee - Année.
 * @param mois - Mois, de 1 à 12.
 */
export const nbJoursMois = (annee: number, mois: number) => {
  // Le mois est 0-indexé en JavaScript, donc soustrayez 1 du mois
  const date = new Date(annee, mois - 1, 1);
  // Passez au mois suivant et soustrayez un jour pour obtenir le dernier jour du mois en cours
  date.setMonth(date.getMonth() + 1);
  date.setDate(date.getDate() - 1);
  // Obtenez le jour du mois
  const lastDayOfMonth = date.getDate();
  return lastDayOfMonth;
};

/**
 * Renvoit le numéro du premier jour de la semaine en Français.
 *
 * (0 = Lundi - 6 = Dimanche).
 *
 * @param annee - Année.
 * @param mois - Mois, de 1 à 12.
 */
export const numeroPremierJourSemaineDuMois = (annee: number, mois: number) => {
  // Le mois est 0-indexé en JavaScript, donc soustrayez 1 du mois
  return (new Date(annee, mois - 1, 1).getDay() + 6) % 7;
};

/**
 * Fusionne des intervalles de date.
 *
 * @param intervalles
 * @returns Tableau d'intervalles.
 */
export const fusionIntervalles = (intervalles: { dateDebut: Date; dateFin: Date }[]) => {
  if (intervalles.length <= 1) {
    return intervalles;
  }

  // Triez les intervalles par date de début
  intervalles.sort((a, b) => a.dateDebut.getTime() - b.dateDebut.getTime());

  const intervallesFusionnes: { dateDebut: Date; dateFin: Date }[] = [intervalles[0]];

  for (let i = 1; i < intervalles.length; i++) {
    const intervalleCourant = intervalles[i];
    const dernierIntervalleFusionne = intervallesFusionnes[intervallesFusionnes.length - 1];

    const debutIntervalleCourant = intervalleCourant.dateDebut.getTime();
    const finIntervalleCourant = intervalleCourant.dateFin.getTime();
    const finDernierIntervalleFusionne = dernierIntervalleFusionne.dateFin.getTime();

    if (debutIntervalleCourant <= finDernierIntervalleFusionne) {
      // Les intervalles se chevauchent, fusionnez-les
      dernierIntervalleFusionne.dateFin = new Date(Math.max(finIntervalleCourant, finDernierIntervalleFusionne));
    } else {
      // Pas de chevauchement, ajoutez l'intervalle actuel à la liste fusionnée
      intervallesFusionnes.push(intervalleCourant);
    }
  }

  return intervallesFusionnes;
};

/**
 * Teste si une date est comprise dans un intervalle.
 *
 * @param dateDebut - Date de début.
 * @param dateFin - Date de fin.
 * @param dateAComparer - Date à tester.
 */
export const dateEstCompriseDansIntervalle = (dateDebut: Date, dateFin: Date, dateAComparer: Date) => {
  dateDebut.setHours(0);
  dateDebut.setMinutes(0);
  dateDebut.setSeconds(0);
  dateDebut.setMilliseconds(0);

  dateFin.setHours(23);
  dateFin.setMinutes(59);
  dateFin.setSeconds(59);
  dateFin.setMilliseconds(999);

  return dateDebut <= dateAComparer && dateAComparer <= dateFin;
};

/**
 * Cette méthode permet de renvoyer une date à partir d'une autre et d'un nombre de jours à ajouter ou retirer
 *
 * @param date - Date à laquelle on souhaite ajouter ou retirer des jours
 * @param nbJourAAjouter - Si positif, on ajoute des jours, si négatif, on retire des jours.
 * @returns le nouvel objet `Date` calculé.
 */
export const ajouterJour = (date: Date, nbJourAAjouter: number) => {
  return new Date(date.setDate(date.getDate() + nbJourAAjouter));
};

import { type FunctionComponent } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { Container } from '../components/Container';
import { useApi } from '../providers/ApiProvider';
import { useUser } from '../providers/UserProvider';
import { FooterLegal } from '../components/FooterLegal';

/**
 * Ce layout contient la mise en page par la partie Authentification et ce qui s'y rapporte.
 */
export const AuthLayout: FunctionComponent = () => {
  /**
   * On a besoin des informations de l'utilisateur et du profil courant de celui-ci.
   */
  const { user, profil } = useUser();

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  // On verifie si on a un utilisateur qui est connecté, auquel cas on assigne le jeton d'authentification dans les en-têtes de l'instance de classe API.
  if (user && user.token) {
    client.setToken(user.token);
  }

  // On verifie si on a un profil, auquel cas on assigne son ID dans les en-têtes de l'instance de classe API.
  if (profil) {
    client.setProfil(profil.id);
  }

  return (
    <div className='flex flex-col min-h-screen'>
      <Container className='bg-secondary-1 pb-11 grow'>
        <div className='w-full max-w-md px-2 pt-10 pb-5 sm:px-0 mx-auto'>
          <Link to='/'>
            <img src='/assets/aocdtf-logo-white-orange.svg' className='max-h-64 mx-auto mb-5' alt='' />
          </Link>
          <Outlet />
          <div className='text-sm text-center mt-6'>
            <Link to='/' className='text-primary montserrat-medium'>
              Retour à l'accueil
            </Link>
          </div>
        </div>
      </Container>
      <FooterLegal />
    </div>
  );
};

import { Fragment, FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';
import { Container } from '../components/Container';
import { FilDariane } from '../components/FilDariane';
import { MainMenu } from '../components/MainMenu';
import { Config } from '../config/config';

/**
 * Affiche les conditions générales de ventes.
 */
export const ConditionsGeneralesScreen: FunctionComponent = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Conditions générales de vente - {Config.app_label}</title>
      </Helmet>

      <div
        className='bg-cover bg-center h-auto text-white py-3 px-4 lg:px-8 xl:px-11 object-fill relative'
        style={{
          backgroundImage: "url('/assets/header-fiche-formations.webp')",
        }}>
        <div className='absolute top-0 right-0 left-0 bottom-0 w-full h-full bg-secondary-2/70'></div>
        <div className='relative flex flex-col items-center h-full mx-auto'>
          <MainMenu className='relative pb-20' />
        </div>
        <div className='lg:max-w-xl xl:max-w-7xl w-full relative h-full 2xl:px-8 mt-20 lg:mt-[120px] text-center md:text-left mr-auto xl:mx-auto'>
          <h1 className='text-white text-3xl w-full uppercase poppins-semibold xl:pr-96'>Condition générales de vente</h1>
          <FilDariane
            className='bg-transparent w-full text-white pl-0 !mb-2 mt-2 xl:pr-96'
            items={[{ label: 'Conditions générales', link: `/conditions-generales` }]}
          />
        </div>
      </div>
      <Container className='bg-gray-100 h-full'>
        <div className='md:flex md:flex-row w-full'>
          <div className='lg:basis-2/3 h-full py-11'>
            <div className='md:mr-4 mb-11 text-secondary-1'>
              <h2 className='pt-4 text-xl mb-3 mt-5 poppins-medium'>Introduction</h2>
              <p>
                Les Compagnons du Devoir et du Tour de France (ci-après désignés « AOCDTF ») dispensent des actions de formation, de préparation à la validation
                des acquis de l’expérience, d’accompagnement et d’ingénierie conseil. L’AOCDTF assure la conception, la réalisation et l’édition de produits
                pédagogiques sur divers supports. Toute commande de prestation à l’AOCDTF par le client est soumise aux présentes CGV et à la signature par le
                client d’un des documents contractuels prévus à l’article 1, laquelle signifie l’adhésion pleine et entière du client aux présentes CGV. Aucune
                dérogation aux présentes conditions ne pourra être admise sans l’accord exprès écrit et préalable de l’AOCDTF, et, toute condition contraire aux
                présentes CGV posée par le client sera inopposable à l’AOCDTF. Les présentes CGV se substituent à tout accord antérieurement conclu.
              </p>
              <h2 id='article1' className='pt-4 text-xl mb-3 mt-5 poppins-medium'>
                Article 1 – L’achat de prestation
              </h2>
              <p>La commande d’une ou plusieurs prestations par le client à l’AOCDTF prend l’une des formes contractuelles suivantes&nbsp;:</p>
              <ul className='list-inside list-decimal mt-3'>
                <li className='pb-3 poppins-semibold'>
                  Engagement contracté par une personne morale ou par une personne physique qui agit dans le cadre de son activité commerciale, industrielle,
                  artisanale ou libérale&nbsp;:
                  <ul className='list-disc list-inside font-normal'>
                    <li>Un bon de commande émis, daté et signé par le client reprenant les mentions exactes du devis adressé préalablement par l’AOCDTF ;</li>
                    <li>
                      Une convention de formation professionnelle signée par le client (les articles L. 6313-1 et suivants nouveaux et les articles L. 6353-1 et
                      suivants nouveaux du Code du travail).
                    </li>
                  </ul>
                </li>
                <li className='pb-3 poppins-semibold'>
                  Engagement contracté par une personne physique agissant en dehors de son activité professionnelle&nbsp;:
                  <ul className='list-disc list-inside font-normal'>
                    <li>
                      Un contrat de formation professionnelle signé par le client non-professionnel. Outre les mentions légales, figurent sur le document
                      contractuel, l’identité du client et, pour les personnes morales, sa dénomination ou raison sociale, son n° SIRET, sa domiciliation, le
                      nom de son représentant dûment habilité, et, pour tous les clients, tout renseignement d’ordre pratique (adresse, téléphone, courriel,
                      télécopie).
                    </li>
                  </ul>
                </li>
              </ul>
              <p>
                Si au moment de la passation de commande le ou les noms des participants ne sont pas connus par le client, celui-ci a la possibilité de les
                communiquer à l’AOCDTF dans les 10 jours précédant le démarrage des actions. L’acte d’achat est réputé définitivement réalisé dès lors que le
                document contractuel est signé par les parties concernées. Chacun des cocontractants est destinataire d’un des exemplaires de ce document
                original. Toute demande de modification de la prestation commandée par le client doit être signifiée obligatoirement par courrier recommandé
                avec accusé de réception (RAR) avant la date de la formation concernée. L’AOCDTF n’a pas d’obligation d’accepter la modification demandée, mais
                cherchera à faire le nécessaire dans la mesure du possible et des disponibilités et sous réserve du paiement de la différence de prix. Les
                modifications en cours d’exécution de la prestation ayant fait l’objet d’un accord des parties donnent lieu à la signature d’un avenant au
                document contractuel. L’AOCDTF effectue la ou les prestations commandées sous sa responsabilité, soit avec ses moyens propres soit avec le
                concours d’autres organismes avec lesquels elle aura passé des contrats de sous-traitance, ce que le client accepte. Dans le cas où un client
                passerait une commande à l’AOCDTF, sans avoir procédé au paiement de la (des) commande(s) précédente(s), l’AOCDTF se réserve le droit de refuser
                d’honorer la nouvelle commande et de ne pas délivrer les formations concernées, sans que le client puisse prétendre à une quelconque indemnité,
                pour quelque raison que ce soit.
              </p>
              <h2 id='article2' className='pt-4 text-xl mb-3 mt-5 poppins-medium'>
                Article 2 – Prix
              </h2>
              <p>
                Les prix des prestations sont valables pour l’année civile en cours. Ces prix sont révisés tous les ans. Sauf dispositions contraires écrites,
                ils incluent la formation, les frais pédago- giques, l’utilisation des salles et/ou ateliers de formation ainsi que le matériel pédagogique. Les
                tarifs des services associés tels que la restauration et/ou l’hébergement des bénéficiaires des prestations sont en sus des prix des prestations
                et sont ceux applicables au moment de leur utilisation et peuvent varier d’un établissement à l’autre, et d’année en année. L’AOCDTF ayant opté
                pour le régime d’exonération de TVA (article 261-4-4a° du Code général des impôts), toutes ses prestations de formation ou prestations
                assimilées ainsi que les prestations associées sont facturées nettes de TVA.
              </p>
              <h2 id='article3' className='pt-4 text-xl mb-3 mt-5 poppins-medium'>
                Article 3 – Justification de la prestation de formation
              </h2>
              <p>
                Une attestation de présence est établie par l’AOCDTF à l’intention de chaque bénéficiaire et adressée, à l’issue de la formation, au client qui
                s’engage à la leur remettre.
              </p>
              <h2 id='article4' className='pt-4 text-xl mb-3 mt-5 poppins-medium'>
                Article 4 – Facturation et règlement
              </h2>
              <p>Sauf accord contraire écrit, les règlements des prestations seront effectués aux conditions suivantes&nbsp;:</p>
              <ul className='list-inside list-disc'>
                <li>Le paiement doit être effectué par le client, au plus tard dans un délai de 30 (trente) jours à compter de la date de la facture ;</li>
                <li>Le règlement est accepté par chèque ou virement bancaire ;</li>
                <li>
                  Aucun escompte ne sera appliqué en cas de règlement avant l’échéance, sauf mention différente convenue et indiquée sur la facture. Pour les
                  clauses dérogatoires et les retards de paiement, se reporter à l’article 6.
                </li>
              </ul>
              <h2 id='article5' className='pt-4 text-xl mb-3 mt-5 poppins-medium'>
                Article 5 – Participation financière d’un éventuel organisme financeur
              </h2>
              <p>
                Le client peut faire une demande de participation financière auprès d’un éventuel organisme financeur. Le client doit informer l’AOCDTF de cette
                demande et de son accord de participation, au plus tard avant le démarrage de la formation. L’AOCDTF ne peut en aucun cas être tenue responsable
                de la décision d’un organisme financeur, notamment pour non-respect des délais administratifs. À la demande du client, l’AOCDTF peut réaliser
                une subrogation de paiement auprès d’un organisme financeur. Dans tous les cas, qu’il y ait accord ou refus d’un organisme financeur, le client
                s’engage à régler en totalité la somme restant due, ce qu’il accepte.
              </p>
              <h2 id='article6' className='pt-4 text-xl mb-3 mt-5 poppins-medium'>
                Article 6 – Paiement – Retard ou défaut de paiement
              </h2>
              <p>
                L’attention du client est attirée sur le fait que toute formation ou tout stage commencé est dû dans sa totalité. Par dérogation à l’article
                4&nbsp;:
              </p>
              <ul className='list-inside list-disc'>
                <li>Pour les formations d’une durée égale ou inférieure à 70 heures, le règlement total a lieu au moment de l’inscription;</li>
                <li>
                  Pour les formations d’une durée supérieure à 70 heures, un règlement égal à 50 % du coût total a lieu au moment de l’inscription et le
                  règlement du solde aura lieu en fin de formation ou de stage. L’AOCDTF se réserve expressément le droit de disposer librement des places
                  retenues et pour lesquelles le paiement n’a pas été effectué. Tout retard de paiement par un client professionnel&nbsp;:
                </li>
                <li>
                  Est passible, après une mise en demeure restée infructueuse au bout de 8 jours, d’intérêts de retard calculés au taux de 3 fois le taux de
                  l’intérêt légal en vigueur (art. L. 441-6 du Code du commerce);
                </li>
                <li>
                  Ouvre droit, en sus des intérêts de retard précités, pour tout professionnel, à une indem- nité forfaitaire pour frais de recouvrement de 40
                  €. Une indemnisation complémentaire pourra être demandée, sur justification, si les frais de recouvrement exposés sont supérieurs au montant
                  de l’indemnité forfaitaire (art. L. 441-6 du Code du commerce). À défaut de règlement par l’organisme financeur, le client reste seul
                  redevable du paiement de la formation.
                </li>
              </ul>
              <h2 id='article7' className='pt-4 text-xl mb-3 mt-5 poppins-medium'>
                Article 7 – Conditions d’annulation ou de report
              </h2>
              <p>En cas de demande de report ou d’annulation par le client des actions commandées&nbsp;:</p>
              <ul className='list-inside list-disc'>
                <li>
                  La demande ne donnera lieu à aucune responsabilité contractuelle, si le report ou l’annulation a pour cause une maladie ou un accident du
                  participant aux dates de formation, justifié par un certificat médical, ou le décès d’un membre de la famille proche du participant avec
                  justificatif (conjoint, parents, frères, sœurs, grands-parents et enfants ; cette liste étant exhaustive);
                </li>
                <li>
                  Dans tous les autres cas, l’intégralité du coût de la formation reste due en cas d’avertissement dans les 8 jours précédant la date prévue du
                  début de la formation ou en l’absence de toute information. Si l’AOCTDF est informée par courrier RAR avant ce délai de 8 jours, le
                  dédommagement contractuel est fixé à 50 % du coût total de la formation. Si une solution de report peut être trouvée par les parties, les
                  sommes versées à titre de responsabilité contractuelle s’imputeront sur le prix final de l’action. De son côté, l’AOCDTF se réserve le droit
                  d’annuler ou de reporter la formation, notamment s’il n’y a pas assez de participants ou en cas de force majeure. Le client sera informé dans
                  un délai maximum de 8 jours avant la date prévue de la formation et ne pourra prétendre à aucun dédommagement contractuel ni remboursement des
                  frais qui auraient éventuellement pu être déjà engagés. La présente clause n’est pas applicable en cas d’absence(s) individuelle(s) lors d’une
                  formation ayant un prix forfaitaire indépendant du nombre de participants. Dans un tel cas, la facturation a lieu selon les modalités
                  initialement convenues.
                </li>
              </ul>
              <h2 id='article8' className='pt-4 text-xl mb-3 mt-5 poppins-medium'>
                Article 8 – Responsabilité de l’AOCDTF
              </h2>
              <p>
                L’obligation souscrite par l’AOCDTF dans le cadre des prestations qu’elle délivre est une obligation de moyens et ne peut en aucun cas être
                interprétée comme une obligation de résultat.
              </p>
              <h2 id='article9' className='pt-4 text-xl mb-3 mt-5 poppins-medium'>
                Article 9 – Force majeure
              </h2>
              <p>
                L’AOCDTF ne pourra être tenue responsable à l’égard du client en cas d’inexécution de ses obligations résultant d’un événement de force majeure.
                Sont considérés comme cas de force majeure ou cas fortuit, outre ceux habituellement reconnus par la jurisprudence des cours et tribunaux
                français et sans que cette liste ne soit restrictive&nbsp;: la maladie ou l’accident d’un consultant ou d’un animateur de formation, les grèves
                ou les conflits sociaux internes ou externes à l’AOCDTF, les désastres naturels, les incendies, la non-obtention de visas, d’autorisations de
                travail ou d’autres permis, les lois ou règlements mis en place ultérieurement, l’interruption des télécommunications, l’interruption de
                l’approvisionnement en énergie, l’interruption des communications ou des transports de tout type, ou toute autre circonstance échappant au
                contrôle raisonnable de l’AOCDTF.
              </p>

              <h2 id='article10' className='pt-4 text-xl mb-3 mt-5 poppins-medium'>
                Article 10 – Propriété intellectuelle
              </h2>
              <p>
                En application de la législation relative à la propriété intellectuelle (notamment la protection en matière de droit d’auteur, de marque
                déposée), les logiciels, supports, brochures, documentaires, outils, cours et tout document en général mis à la disposition du client et de son
                personnel par l’AOCDTF sont la propriété de l’AOCDTF ou de ses donneurs de licence. Il n’y a donc ni cession ni transfert des droits de
                propriété intellectuelle de l’AOCDTF ou de son sous-traitant au profit du client. En conséquence, l’exploitation, la reproduction, l’adaptation,
                la traduction, la commercialisation et/ou la représentation de tout procédé de communication, de tout ou partie de ceux-ci, peu important la
                nature du support, sont strictement interdites tant pour le client personne physique que pour le client personne morale et ses salariés sous
                peine de poursuites judiciaires.
              </p>

              <h2 id='article11' className='pt-4 text-xl mb-3 mt-5 poppins-medium'>
                Article 11 – Protection des données personnelles
              </h2>
              <p className='poppins-semibold mb-3'>
                Conformément à la loi n° 78-17 du 6 janvier 1978 modifiée (dite « loi Informatique et libertés ») et au Règlement général sur la protection des
                données 2016/679 du 27 avril 2016 (« RGPD »), les données à caractère personnel du participant, personne physique, ou de son représentant légal,
                font l’objet d’un traitement par l’Association ouvrière des Compagnons du Devoir et du Tour de France, agissant en qualité de responsable de
                traitement.
              </p>
              <p>
                traitement. L’AOCDTF collecte lors de l’inscription des informations nominatives (nom, prénom, adresse courriel, adresse postale, n° de
                téléphone fixe et portable), n° de carte bancaire et date d’expiration uniquement lors d’un paiement par carte, copie d’une pièce d’identité
                pour les mineurs. Ces informations sont nécessaires et obligatoires à la gestion de la prestation et à la rédaction du contrat ou de la
                convention de formation. La base légale du traitement réside dans l’exécution du contrat de formation. Les autres demandes d’information
                appelant une réponse facultative ou les informations relatives à l’intérêt du participant pour les offres susceptibles de lui être adressée sont
                destinées à mieux le connaître ainsi qu’à améliorer les services qui lui sont proposés. L’AOCDTF veille à ce que seules les personnes habilitées
                puissent accéder aux données des participants. Cela concerne les services administratifs et pédagogiques, ainsi que des sous- traitants aux
                données uniquement pour les finalités précédemment mentionnées et dans la limite nécessaire à l’accomplissement des tâches qui leur sont
                confiées. Les données ne font pas l’objet d’un transfert hors Union européenne. Lorsque le paiement est effectué par carte de crédit, les
                coordonnées bancaires sont transmises par voie sécurisée aux établissements financiers concernés qui autorisent la transaction et procèdent au
                paiement. Ces données sont conservées pendant la durée strictement nécessaire à l’accomplissement des finalités rappelées ci-dessus et des
                obligations légales. Le client dispose d’un droit d’accès, de rectification, de portabilité, d’effacement de ses données personnelles ou une
                limitation de leur traitement, du droit d’opposition à leur traitement pour des motifs légitimes ainsi que du droit de retirer son consentement
                à tout moment. Enfin, chacun dispose du droit d’introduire une réclamation auprès d’une autorité de contrôle. Pour exercer ses droits, le
                participant doit contacter le service du délégué à la protection des données à l’adresse suivante&nbsp;:{' '}
                <a href='mailto:dpo@compagnons-du-devoir.com' className='text-primary hover:text-primary/70 poppins'>
                  dpo@compagnons-du-devoir.com
                </a>{' '}
                Toute demande doit indiquer le nom, prénom, courriel du participant ainsi que la formation suivie. Elle doit être signée et accompagnée de la
                photocopie d’un titre d’identité et préciser l’adresse à laquelle doit être envoyée la réponse. Une réponse sera alors adressée dans un délai de
                2 mois suivant la réception de la demande.
              </p>
              <h2 id='article12' className='pt-4 text-xl mb-3 mt-5 poppins-medium'>
                Article 12 – Droit de rétractation
              </h2>
              <p>
                Conformément aux articles L. 221-18 et suivants du Code de la consommation, le consommateur, tel qu’ils le définissent dans ses relations avec
                des professionnels et limitativement étendues aux relations entre deux professionnels, par rapport à son activité professionnelle principale et
                au nombre de ses salariés, dispose d’un délai de 14 jours pour exercer son droit de rétractation à compter de la réception de la commande.
                L’AOCDTF s’engage alors à lui rembourser l’intégralité des sommes versées pour ses achats dans un délai maximum de 14 jours à compter de la date
                à laquelle l’AOCDTF est informée de la décision de rétractation.
              </p>
              <h2 id='article13' className='pt-4 text-xl mb-3 mt-5 poppins-medium'>
                Article 13 – Litiges
              </h2>
              <p className='italic poppins-semibold'>Clause attributive de juridiction</p>
              <p>
                Les parties s’efforceront de résoudre à l’amiable les litiges qui pourraient survenir à l’occasion de l’exécution ou l’interprétation des
                présentes conditions générale de vente. En cas de désaccord persistant, seuls les tribunaux de Paris seront compétents.
              </p>
              <h2 id='article14' className='pt-4 text-xl mb-3 mt-5 poppins-medium'>
                Article 14 – Renonciation
              </h2>
              <p>
                Le fait pour l’AOCDTF de ne pas se prévaloir à un moment donné d’une quelconque des clauses des présentes ne peut valoir renonciation à se
                prévaloir ultérieurement de ces mêmes clauses.
              </p>
              <h2 id='article15' className='pt-4 text-xl mb-3 mt-5 poppins-medium'>
                Article 15 – Divisibilité
              </h2>
              <p>
                Si une ou plusieurs stipulations desdites CGV étaient tenues pour non-valides ou déclarées comme telles en application d’un traité, d’une loi ou
                d’un règlement, ou à la suite d’une décision définitive d’une juridiction compétente, les autres stipulations garderont toute leur force et leur
                portée.
              </p>
              <h2 id='article16' className='pt-4 text-xl mb-3 mt-5 poppins-medium'>
                Article 16 – Loi applicable
              </h2>
              <p>
                Toute question relative aux présentes conditions générales de vente ainsi qu’aux ventes qu’elles régissent, qui ne serait pas traitée par les
                présentes stipulations contractuelles, sera régie par la loi française à l’exclusion de tout autre droit. Par ailleurs, si les conditions
                générales de vente de l’AOCDTF sont établies en français et en différentes langues, en cas de contrariété ou de doute dans l’interprétation, ce
                sont toujours les conditions générales de vente en français qui prévaudront et feront foi.
              </p>
            </div>
          </div>
        </div>
      </Container>
    </Fragment>
  );
};

import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { ConstTypeProfil } from '../../../components/ConstTypeProfil';
import { EmailVerifie } from '../../../components/EmailVerifie';
import { LoadingAbsolute } from '../../../components/LoadingAbsolute';
import { Config } from '../../../config/config';
import { useApi } from '../../../providers/ApiProvider';
import { GoodApiResponse, ErrorApiResponse } from '../../../types/api';
import { ProfilEntreprise } from '../../../types/auth';
import { Entreprise } from '../../../types/schematics';
import { Logo } from '../../../components/Logo';
import { Avatar } from '../../../components/Avatar';
import { handleError } from '../../../utils/ErrorHandler';
import { toast } from 'react-hot-toast';

export const AdminEntreprisesEnAttenteScreen: FunctionComponent = () => {
  /**
   * On stocke si on est en train d'utiliser l'API ou non.
   */
  const [apiIsLoading, setApiIsLoading] = useState(false);

  const [entreprises, setEntreprises] = useState<(Entreprise & { profils: ProfilEntreprise[] })[] | null>(null);

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  useEffect(() => {
    handleIndex();
  }, [client]);

  /**
   * Retrouve des données paginées de L'API et les stocke dans un état local.
   *
   * @param indexParams - Paramètres de la recherche.
   */
  const handleIndex = () => {
    setApiIsLoading(true);
    client
      .get<GoodApiResponse<(Entreprise & { profils: ProfilEntreprise[] })[]>>('/admin/entreprises/en-attente')
      .then((reponse) => {
        setEntreprises(reponse.data.data as unknown as (Entreprise & { profils: ProfilEntreprise[] })[]);
      })
      .catch((error: ErrorApiResponse<Entreprise>) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  const handleDecide = (entrepriseId: Entreprise['id'], estApprouvee: boolean) => {
    setApiIsLoading(true);
    client
      .patch<GoodApiResponse>(`admin/entreprises/${entrepriseId}/${estApprouvee ? 'approuve' : 'refuse'}`)
      .then(() => {
        setEntreprises((entreprises as (Entreprise & { profils: ProfilEntreprise[] })[]).filter((entreprise) => entreprise.id !== entrepriseId));
        toast.success(`L'entreprise ${estApprouvee ? 'a bien été validée' : 'a été refusée'}.`);
        if (estApprouvee) {
          window.location.href = `/admin/entreprises/editer/${entrepriseId}`;
        }
      })
      .catch((error: ErrorApiResponse<Entreprise>) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  if (entreprises === null) {
    return <Fragment>Chargement...</Fragment>;
  }

  return (
    <Fragment>
      <Helmet>
        <title>Entreprises à valider - {Config.app_label}</title>
      </Helmet>
      <h1 className='text-base text-center sm:text-lg md:text-2xl poppins-bold leading-normal text-secondary-1 mb-7'>Entreprises à valider</h1>
      {entreprises && entreprises.length > 0 ? (
        entreprises.map((entreprise) => (
          <div key={entreprise.id} className='sm:flex justify-between items-center max-w-2xl mx-auto mt-4 rounded-lg bg-white shadow-lg p-5'>
            <div className='w-full'>
              <div className='flex gap-4'>
                <div className='w-20 h-20'>
                  <Logo src={entreprise.image_logo} />
                </div>
                <div className=''>
                  <h3 className='text-lg poppins-semibold'>{entreprise.raison_sociale}</h3>
                  <p className='text-gray-600 text-sm'>
                    SIRET :{' '}
                    {entreprise.siret && (
                      <a
                        className='text-primary underline'
                        href={`https://www.societe.com/cgi-bin/search?champs=${entreprise.siret}`}
                        target='_blank'
                        rel='noreferrer'>
                        {entreprise.siret}
                      </a>
                    )}
                  </p>
                  <p className='text-gray-600 text-sm'>
                    Créée le :{' '}
                    {entreprise.created_at
                      ? new Intl.DateTimeFormat('default', {
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                          hour: 'numeric',
                          minute: 'numeric',
                          hour12: false,
                        }).format(new Date(entreprise.created_at as string))
                      : ''}
                  </p>
                </div>
              </div>
              {entreprise.profils.length > 0 && (
                <div className='grid grid-cols-1 lg:grid-cols-2 gap-3 mt-4'>
                  {entreprise.profils.map((profilEntreprise) => (
                    <div key={profilEntreprise.id} className='border rounded-lg p-4 text-center flex flex-row items-center justify-center gap-3'>
                      <div className='flex-none w-20'>
                        <div className='w-14 h-14'>
                          <Avatar className='rounded-md aspect-1 h-14 w-14' src={profilEntreprise.user.image_avatar} />
                        </div>
                      </div>
                      <div className='w-full text-left'>
                        <span className='text-md poppins-semibold block'>
                          {profilEntreprise.user.prenom} {profilEntreprise.user.nom}
                        </span>
                        <div className='text-sm text-gray-600 flex items-center'>
                          <div>{profilEntreprise.user.email}</div>
                          {profilEntreprise.user.email_verified_at && (
                            <div>
                              <EmailVerifie />
                            </div>
                          )}
                        </div>
                        <span className='text-sm text-gray-600 block'>
                          <ConstTypeProfil text={profilEntreprise.type_profil} />
                        </span>
                        <span className='text-xs text-gray-600 block'>
                          {`${profilEntreprise.created_at ? 'Depuis le : ' : ''} ${
                            profilEntreprise.created_at
                              ? new Intl.DateTimeFormat('default', {
                                  year: 'numeric',
                                  month: 'numeric',
                                  day: 'numeric',
                                  hour: 'numeric',
                                  minute: 'numeric',
                                  hour12: false,
                                }).format(new Date(profilEntreprise.created_at as string))
                              : ''
                          }`}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              <div className='text-center sm:text-left pt-4 sm:mt-0 flex flex-row justify-center md:justify-end'>
                <button
                  className='bg-secondary-1 uppercase text-xs poppins-bold hover:bg-gray-300 text-white hover:text-secondary-1 py-2 px-5 rounded-full shadow-sm mr-3'
                  onClick={() => handleDecide(entreprise.id, false)}>
                  Refuser
                </button>
                <button
                  className='bg-primary uppercase text-xs poppins-bold text-white py-2 px-5 rounded-full shadow-sm hover:bg-secondary-1'
                  onClick={() => handleDecide(entreprise.id, true)}>
                  Accepter
                </button>
              </div>
            </div>
          </div>
        ))
      ) : (
        <p className='max-w-2xl mx-auto mt-4 rounded-lg bg-white shadow-lg p-5 text-center text-sm text-gray-700'>
          Aucune entreprise en attente de validation.
        </p>
      )}
      {apiIsLoading ? <LoadingAbsolute /> : ''}
    </Fragment>
  );
};

import { FunctionComponent } from 'react';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import {
  dateEstCompriseDansIntervalle,
  fusionIntervalles,
  joursSemaineAbrege,
  libelleMois,
  nbJoursMois,
  numeroPremierJourSemaineDuMois,
} from '../../utils/Temps';
import { useUser } from '../../providers/UserProvider';
import { TypeProfilEnum } from '../../types/auth';

/**
 * Ce composant affiche un mini aganda côté entreprise et salariés qui afiche les jour ou des évenements sont prévues.
 * Il y a un lien permettant de d'aller sur la page agenda.
 *
 * @param datesSessions - Dates des différentes sessions prévues.
 */
export const DashboardMiniAgenda: FunctionComponent<{ datesSessions: { dateDebut: Date; dateFin: Date }[] | null }> = ({ datesSessions }) => {
  /**
   * On a besoin du profil voir savoir vers quelle page d'agenda on renvoit.
   */
  const { profil } = useUser();

  /**
   * @constant Date actuelle.
   */
  const date = new Date();

  /**
   * @constant Stocke le numéro du premier jour de la semaine pour l'année et le mois courant.
   */
  const premierJourDeLaSemaineDuMois = numeroPremierJourSemaineDuMois(date.getFullYear(), date.getMonth() + 1);

  /**
   * @constant Stocke le nombre de jours total pour un mois.
   */
  const totalJoursMois = nbJoursMois(date.getFullYear(), date.getMonth() + 1);

  /**
   * @var Compteur pour la boucle affiche le mini-agenda
   */
  let compteurDeJour = 0;

  /**
   * @constant Calcule le nombre de lignes nécessaires pour afficher tous les jours du mois.
   */
  const nombreLignes = Math.ceil((nbJoursMois(date.getFullYear(), date.getMonth() + 1) + premierJourDeLaSemaineDuMois) / 7);

  return (
    <div className='mx-auto mt-6 shadow-md rounded-md bg-white'>
      <div className='flex flex-col sm:flex-row items-center justify-between p-5'>
        <h3 className='text-secondary-2 poppins-semibold text-center md:text-left'>Mois de {libelleMois[date.getMonth()].toLocaleLowerCase()}</h3>
        <Link
          to={
            profil !== null && (profil.type_profil === TypeProfilEnum.dirigeant || profil.type_profil === TypeProfilEnum.delegue)
              ? '/entreprise/agenda'
              : '/mon-agenda'
          }
          className='text-sm text-secondary-1/90 flex items-center hover:text-primary'>
          Voir l'agenda
          <ChevronRightIcon className='w-3 h-3' />
        </Link>
      </div>
      <div className='flex items-center justify-center py-8 px-0'>
        <div className='max-w-sm w-full'>
          <div className=' bg-white rounded-t'>
            <div className='flex items-center justify-between overflow-x-auto'>
              <table className='w-full'>
                <thead>
                  <tr>
                    {joursSemaineAbrege.map((jourAbrege) => (
                      <th key={jourAbrege}>
                        <div className='w-full flex justify-center'>
                          <p className='text-base font-medium text-center text-gray-800'>{jourAbrege}</p>
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {Array.from({ length: nombreLignes }, (_, index) => 0 + index * 1).map((numeroLigne) => (
                    <tr key={numeroLigne}>
                      {Array.from({ length: 7 }, (_, index) => 0 + index * 1).map((jourSemaine) => {
                        if (numeroLigne === 0 && jourSemaine < premierJourDeLaSemaineDuMois) {
                          // Cellules vides avant le premier jour du mois

                          return (
                            <td key={jourSemaine} className='pt-6'>
                              <div className='px-2 py-2 cursor-pointer flex w-full justify-center'></div>
                            </td>
                          );
                        } else if (compteurDeJour > totalJoursMois - 1) {
                          // Cellules vides après la fin du mois

                          return (
                            <td key={jourSemaine} className='pt-6'>
                              <div className='px-2 py-2 flex w-full justify-center'></div>
                            </td>
                          );
                        } else {
                          // Cellules avec le numéro du jour du mois

                          compteurDeJour++;

                          return (
                            <td key={jourSemaine} className='p-1 sm:p-2'>
                              <div
                                className={`rounded-full w-9 text-center aspect-1 flex justify-center items-center ${
                                  date.getDate() === compteurDeJour &&
                                  datesSessions !== null &&
                                  fusionIntervalles(datesSessions).some((datesSession) =>
                                    dateEstCompriseDansIntervalle(
                                      new Date(datesSession.dateDebut),
                                      new Date(datesSession.dateFin),
                                      new Date(date.getFullYear(), date.getMonth(), compteurDeJour),
                                    ),
                                  )
                                    ? 'bg-primary ring ring-offset-2 ring-primary text-white'
                                    : `${date.getDate() === compteurDeJour ? 'bg-transparent text-primary border-2 border-primary' : 'text-gray-500'} ${
                                        datesSessions !== null &&
                                        fusionIntervalles(datesSessions).some((datesSession) =>
                                          dateEstCompriseDansIntervalle(
                                            new Date(datesSession.dateDebut),
                                            new Date(datesSession.dateFin),
                                            new Date(date.getFullYear(), date.getMonth(), compteurDeJour),
                                          ),
                                        )
                                          ? 'bg-primary text-white'
                                          : ''
                                      }`
                                } `}>
                                <p className='text-base poppins-medium'>{compteurDeJour}</p>
                              </div>
                            </td>
                          );
                        }
                      })}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import { FunctionComponent } from 'react';

/**
 * Ce composant affiche une étoile à moitié pleine.
 *
 * @param className - classes tailwind afin d'ajuster et styliser l'étoile à moité pleine.
 */
export const StarHalf: FunctionComponent<{ className?: string }> = ({ className = '' }) => (
  <div className={className}>
    <svg
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 24 24'
      style={{ background: 'new 0 0 24 24' }}
      xmlSpace='preserve'>
      <style type='text/css'>
        {`.st0{fill:#EA5E00;}
    .st1{fill:none;stroke:#EA5E00;stroke-width:1.5;stroke-linecap:round;stroke-linejoin:round;}`}
      </style>
      <path
        className='st0'
        d='M12,17.6c-0.1,0-0.2,0-0.3,0.1L7,20.5c-0.4,0.3-1-0.1-0.8-0.6l1.3-5.4c0-0.2,0-0.4-0.2-0.6L3,10.4
    c-0.4-0.3-0.2-0.9,0.3-1L8.9,9c0.2,0,0.4-0.1,0.5-0.3l2.1-5.1c0.1-0.2,0.3-0.3,0.5-0.3'
      />
      <path
        className='st1'
        d='M11.5,3.5c0.2-0.5,0.8-0.5,1,0l2.1,5.1c0.1,0.2,0.3,0.3,0.5,0.3l5.5,0.4c0.5,0,0.7,0.7,0.3,1L16.8,14
    c-0.2,0.1-0.2,0.4-0.2,0.6l1.3,5.4c0.1,0.5-0.4,0.9-0.8,0.6l-4.7-2.9c-0.2-0.1-0.4-0.1-0.6,0L7,20.5c-0.4,0.3-1-0.1-0.8-0.6l1.3-5.4
    c0-0.2,0-0.4-0.2-0.6L3,10.4c-0.4-0.3-0.2-0.9,0.3-1L8.9,9c0.2,0,0.4-0.1,0.5-0.3L11.5,3.5z'
      />
    </svg>
  </div>
);

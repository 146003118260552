import { MapPinIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Button, Dialog, DialogBody, DialogFooter, DialogHeader } from '@material-tailwind/react';
import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { Formation, Groupe } from '../../../types/visitorRessources';
import { Profil, ProfilEntreprise, TypeProfilEnum } from '../../../types/auth';
import { LoadingAbsolute } from '../../LoadingAbsolute';
import { ErrorApiResponse, GoodApiResponse } from '../../../types/api';
import { Api } from '../../../api/Api';
import { handleError } from '../../../utils/ErrorHandler';
import { Avatar } from '../../Avatar';
import toast from 'react-hot-toast';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';

/**
 * Ce composant permet d'afficher une Modale pour proposer à l'utilisateur de s'authentifier.
 *
 * @param visible - Précise si la modale et ouverte ou non. Provient du parent.
 * @param setVisible - Accesseur qui modifie la visibilité de la modale. Provient du parent.

 */
export const ModaleChoixSession: FunctionComponent<{
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  client: Api;
  profil: Profil | null;
  formation: Formation | null;
  sessions: Groupe[] | null;
}> = ({ visible, setVisible, client, profil, formation, sessions }) => {
  const [tokens, setTokens] = useState<string[]>([]);

  const [apiIsLoading, setApiIsLoading] = useState(false);

  /**
   * On stocke les salariés de l'entreprise
   */
  const [salaries, setSalaries] = useState<ProfilEntreprise[] | null>(null);

  /**
   * Salarié sélectionné (e-mail ou null)
   */
  const [salarieSelectionne, setSalarieSelectionne] = useState<string | null>(null);

  /**
   * Affiche ou non modale d'action
   */
  const [selecteurSalarieDeplie, setSelecteurSalarieDeplie] = useState<boolean>(false);

  const toggleSelecteurSalarie = () => {
    if (selecteurSalarieDeplie === true) {
      setSelecteurSalarieDeplie(false);
    } else {
      setSelecteurSalarieDeplie(true);
    }
  };

  /**
   * Ajoute un token
   *
   * @param value - Token à ajouter.
   */
  const addToken = (value: string) => {
    setTokens([...tokens, value]);
  };

  /**
   * Retire un Token
   * @param value - Token à supprimer.
   */
  const removeToken = (value: string) => {
    setTokens([...tokens.filter((token) => token !== value)]);
  };

  /**
   * Ajouter ou de retirer un token si celui est déjà présent.
   *
   * @param value - Token à toggle.
   */
  const toggleToken = (value: string) => {
    if (value && tokens.includes(value)) {
      removeToken(value);
    } else {
      addToken(value);
    }
  };

  /**
   * Récupère la liste des salariés de l'entreprise du profil actif.
   */
  const handleListeSalarie = () => {
    if (profil !== null && (profil.type_profil === TypeProfilEnum.dirigeant || profil.type_profil === TypeProfilEnum.delegue)) {
      setApiIsLoading(true);

      client
        .get<GoodApiResponse<ProfilEntreprise[]>>('/entreprise/salaries')
        .then((response) => {
          setSalaries(response.data.data);
          //Affecter automatiquement le premier salarié
          if (response.data.data.length > 0) {
            setSalarieSelectionne(response.data.data[0].user.email);
          }
        })
        .catch((error: ErrorApiResponse) => {
          handleError(error);
        })
        .finally(() => {
          setApiIsLoading(false);
        });
    }
  };

  /**
   * Cette méthode effectue l'appel API pour ajouter un souhait à un salarié.
   *
   * @param groupeToken - Jeton du groupe.
   */
  const handleSouhaitSalarie = (tokens: string[]) => {
    setApiIsLoading(true);

    if (tokens.length > 0) {
      tokens.forEach((token) => {
        client
          .post<GoodApiResponse>(`/salarie/souhaits/${formation?.slug}/${token}`)
          .then(() => {
            toast.success(`Session de formation proposée`);
          })
          .catch((error: ErrorApiResponse) => {
            handleError(error);
          })
          .finally(() => {
            setApiIsLoading(false);
            setTokens([]);
          });
      });
    } else {
      client
        .post<GoodApiResponse>(`/salarie/souhaits/${formation?.slug}`)
        .then(() => {
          toast.success(`Formation proposée`);
        })
        .catch((error: ErrorApiResponse) => {
          handleError(error);
        })
        .finally(() => {
          setApiIsLoading(false);
          setTokens([]);
        });
    }
  };

  /**
   * Cette méthode effectue l'appel API pour ajouter un souhait à des salarié lorsque l'on est dirigeant.
   *
   * @param email - E-mail du salarié.
   * @param groupeToken - Jeton du groupe.
   */
  const handleSouhaitDirigeant = (email: string, tokens: string[]) => {
    setApiIsLoading(true);
    if (tokens.length > 0) {
      tokens.forEach((token) => {
        client
          .post<GoodApiResponse>(`/entreprise/souhaits/${formation?.slug}/${token}`, { email: email })
          .then(() => {
            toast.success(`Session de formation proposée`);
          })
          .catch((error: ErrorApiResponse) => {
            handleError(error);
          })
          .finally(() => {
            setApiIsLoading(false);
            setTokens([]);
            setSelecteurSalarieDeplie(false);
          });
      });
    } else {
      client
        .post<GoodApiResponse>(`/entreprise/souhaits/${formation?.slug}`, { email: email })
        .then(() => {
          toast.success(`Formation proposée`);
        })
        .catch((error: ErrorApiResponse) => {
          handleError(error);
        })
        .finally(() => {
          setApiIsLoading(false);
          setTokens([]);
          setSelecteurSalarieDeplie(false);
        });
    }
  };

  useEffect(() => {
    handleListeSalarie();
  }, [client]);

  return (
    <Fragment>
      <Dialog open={visible} size='md' handler={setVisible} className='rounded-md bg-[#0D2235] leading-normal overflow-hidden'>
        <DialogHeader
          className='flex gap-2 justify-between items-center text-white p-6 bg-[#0D2235] bg-opacity-100 bg-blend-exclusion bg-cover bg-top bg-no-repeat'
          style={{
            backgroundImage: `url("/assets/waves.webp")`,
          }}>
          <h3 className='text-3xl poppins-medium'>Sessions disponibles</h3>
          <Button variant='text' color='white' onClick={() => setVisible(false)} className='mr-1 !rounded-full p-0'>
            <XMarkIcon className='w-7 h-7' />
          </Button>
        </DialogHeader>

        <DialogBody className='p-6'>
          {profil !== null && (profil.type_profil === TypeProfilEnum.dirigeant || profil.type_profil === TypeProfilEnum.delegue) && salaries !== null && (
            <div>
              <p className='text-sm text-gray-200'>Formation pour&nbsp;:</p>
              <div className='relative'>
                {(salaries as ProfilEntreprise[])
                  .filter((salarie) => salarie.user.email === salarieSelectionne)
                  .map((salarie) => (
                    <div
                      key={profil.id}
                      onClick={() => {
                        toggleSelecteurSalarie();
                      }}
                      className='cursor-pointer py-2 text-gray-200 flex flex-row gap-2 text-sm items-center'>
                      <div className='flex-none'>
                        <Avatar src={salarie.user.image_avatar as string} />
                      </div>
                      <div className='poppins-semibold'>{`${salarie.user.prenom} ${salarie.user.nom}`}</div>
                      <div className='grow'>{selecteurSalarieDeplie ? <ChevronUpIcon className='h-5 w-5' /> : <ChevronDownIcon className='h-5 w-5' />}</div>
                    </div>
                  ))}

                <div className={`absolute rounded-md p-2 top-full w-full bg-white ${selecteurSalarieDeplie ? '' : 'hidden'}`}>
                  <div className='overflow-y-auto max-h-48'>
                    {(salaries as ProfilEntreprise[])
                      .filter((salarie) => salarie.type_profil === TypeProfilEnum.salarie)
                      .map((salarie) => (
                        <div
                          key={profil.id}
                          className={`border-gray-200 border-b hover:bg-gray-200 px-1 py-1 text-gray-800 text-sm flex flex-row gap-2 items-center montserrat ${
                            salarieSelectionne === salarie.user.email ? 'opacity-50 cursor-default' : 'cursor-pointer'
                          }`}
                          onClick={() => {
                            setSalarieSelectionne(salarie.user.email);
                            setSelecteurSalarieDeplie(false);
                          }}>
                          <div className='flex-none'>
                            <Avatar src={salarie.user.image_avatar as string} />
                          </div>
                          <div>{`${salarie.user.prenom} ${salarie.user.nom}`}</div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          )}
          {sessions !== null && sessions.length > 0 ? (
            <div className='mt-2'>
              <table className='w-full text-gray-300 border-b border-b-secondary-1 text-sm'>
                <tr>
                  <th className='py-1 text-left font-normal montserrat'>Options</th>
                  <th className='py-1 text-left font-normal montserrat'>Dates</th>
                  <th className='py-1 text-left font-normal montserrat'>Ville</th>
                  <th className='py-1 text-left font-normal montserrat pl-3'>Etat de la session</th>
                </tr>
                {sessions.map((session) => (
                  <tr key={session.token} className='border-t border-t-secondary-1'>
                    <td className='py-1'>
                      <input
                        type='checkbox'
                        className='text-red-600 cursor-pointer'
                        checked={session.token !== null && tokens.includes(session.token)}
                        onClick={() => toggleToken(session.token ? session.token : 'nope')}
                      />{' '}
                    </td>
                    <td className='py-1 montserrat'>
                      Du{' '}
                      {session.date_debut
                        ? `${new Intl.DateTimeFormat('default', {
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                            hour12: false,
                          }).format(new Date(session.date_debut))}`
                        : ''}{' '}
                      au{' '}
                      {session.date_fin
                        ? `${new Intl.DateTimeFormat('default', {
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                            hour12: false,
                          }).format(new Date(session.date_fin))}`
                        : ''}
                    </td>
                    <td className='py-1 montserrat'>
                      <div className='basis-6/12 lg:basis-5/12 text-xs flex flex-row items-center'>
                        <div className='mr-1 flex-none'>
                          <MapPinIcon className='w-5 h-5' />
                        </div>
                        <span>{session.site && <span>{session.site.nom}</span>}</span>
                      </div>
                    </td>
                    <td className='py-1 montserrat'>
                      {(!session.places_restantes || session.places_restantes < 1) && (
                        <div className='px-3 py-1 rounded-full bg-[#333333] text-[#AAAAAA]'>Complet</div>
                      )}
                      {session.places_restantes && session.places_restantes <= 4 && (
                        <div className='px-3 py-1 rounded-full bg-[#242635] text-[#B93B32]'>Dernières places</div>
                      )}
                      {session.places_restantes && session.places_restantes > 4 && (
                        <div className='px-3 py-1 rounded-full bg-[#0E2F40] text-[#02847A]'>Places disponibles</div>
                      )}
                    </td>
                  </tr>
                ))}
              </table>

              <p className='text-sm text-gray-400 leading-relaxed mt-2'>
                <div className='mb-2'>Vous pouvez sélectionner plusieurs options pour une seule formation.</div>
                <div>
                  Aucune session proposée ne vous intéresse&nbsp;? <span className='text-primary montserrat-semibold'>Envoyez un souhait sans date</span>
                </div>
              </p>
            </div>
          ) : (
            <div>
              <p className='text-sm text-gray-400 leading-relaxed'>
                <div className='mb-2'>Aucune session n'est programmée pour l'instant.</div>
                <div>
                  Vous pouvez envoyer un souhait de formation{' '}
                  {profil !== null && (profil.type_profil === TypeProfilEnum.dirigeant || profil.type_profil === TypeProfilEnum.delegue) && (
                    <Fragment>à votre salarié</Fragment>
                  )}
                  {profil !== null && profil.type_profil === TypeProfilEnum.salarie && <Fragment>à votre entreprise</Fragment>} sans date définie pour{' '}
                  <span className='text-white montserrat-semibold'>mettre une option pour la prochaine session organisée</span>
                </div>
              </p>
            </div>
          )}
        </DialogBody>

        <DialogFooter
          className='p-6 bg-[#0D2235] bg-opacity-100 bg-blend-exclusion bg-cover bg-bottom bg-no-repeat'
          style={{
            backgroundImage: `url("/assets/waves.webp")`,
          }}>
          {sessions !== null && sessions.length > 0 ? (
            <button
              onClick={() => {
                setVisible(false);
                if (
                  profil !== null &&
                  (profil.type_profil === TypeProfilEnum.dirigeant || profil.type_profil === TypeProfilEnum.delegue) &&
                  salarieSelectionne !== null
                ) {
                  handleSouhaitDirigeant(salarieSelectionne, tokens);
                }
                if (profil !== null && profil.type_profil === TypeProfilEnum.salarie) {
                  handleSouhaitSalarie(tokens);
                }
              }}
              className='rounded-md bg-primary px-8 pb-[2px] text-white cursor-pointer text-sm'>
              Envoyer les options sélectionnées
            </button>
          ) : (
            <button
              onClick={() => {
                setVisible(false);
                if (
                  profil !== null &&
                  (profil.type_profil === TypeProfilEnum.dirigeant || profil.type_profil === TypeProfilEnum.delegue) &&
                  salarieSelectionne !== null
                ) {
                  handleSouhaitDirigeant(salarieSelectionne, tokens);
                }
                if (profil !== null && profil.type_profil === TypeProfilEnum.salarie) {
                  handleSouhaitSalarie(tokens);
                }
              }}
              className='rounded-md bg-primary px-8 pb-[2px] text-white cursor-pointer text-sm'>
              Envoyer
            </button>
          )}
        </DialogFooter>
      </Dialog>
      {apiIsLoading ? <LoadingAbsolute /> : ''}
    </Fragment>
  );
};

import { AccordionHeader, ListItem, ListItemPrefix } from '@material-tailwind/react';
import { FunctionComponent } from 'react';

export const DashboardSidebarCategory: FunctionComponent<{
  icon: JSX.Element;
  text: string;
  open: number;
  index: number;
  handleClick: () => void;
}> = ({ icon, text, open, index, handleClick }) => {
  return (
    <ListItem className='p-0' selected={open === index}>
      <AccordionHeader
        onClick={handleClick}
        className={`border-b-0 transition-colors ${
          open === index ? 'bg-transparent hover:bg-primary/10 hover:text-black p-3' : 'border-b-0 p-3 hover:bg-primary/10  text-white'
        }`}>
        <ListItemPrefix>{icon}</ListItemPrefix>
        <span className={`text-sm font-normal poppins-semibold mr-auto`}>{text}</span>
      </AccordionHeader>
    </ListItem>
  );
};

import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingAbsolute } from '../../../components/LoadingAbsolute';
import { useApi } from '../../../providers/ApiProvider';
import { HeaderForm } from '../../../components/HeaderForm';
import { DropdownRessource, ModeFormulaire, Ressource } from '../../../types';
import { Apprenant, Civilite, Nationalite, Pays } from '../../../types/schematics';
import { BaseIndexParams, PaginatedApiResponse, ErrorApiResponse, GoodApiResponse } from '../../../types/api';
import { Config } from '../../../config/config';
import { Helmet } from 'react-helmet';
import { ReadOnlyNumber } from '../../../components/Inputs/ReadOnlyNumber';
import { handleError } from '../../../utils/ErrorHandler';
import { Tab, TabPanel, Tabs, TabsBody, TabsHeader } from '../../../components/Onglets';
import { ReadOnlyText } from '../../../components/Inputs/ReadOnlyText';
import { ReadOnlySelect } from '../../../components/Inputs/ReadOnlySelect';
import { ReadOnlyDate } from '../../../components/Inputs/ReadOnlyDate';

type Props = { modeFormulaire: ModeFormulaire };

/**
 * Ce composant doit afficher un formulaire qui à 3 modes de fonctionnement. Les 2 principaux sont la création et l'édition.
 * On a également un mode lecture seule. L'idée c'est de gérer une partie du CRUD.
 * C'est dans ce composant que vont se faire les appels API pour créer, éditer et voir une ressource en particulier.
 *
 * @param modeFormulaire - Mode du formulaire (creer, editer et voir).
 * @returns JSX du composant
 */
export const AdminApprenantFormScreen: FunctionComponent<Props> = ({ modeFormulaire }) => {
  // On initialise les données dont on va avoir besoin sur cette page

  /**
   * On stocke si on est en train d'utiliser l'API ou non.
   */
  const [apiIsLoading, setApiIsLoading] = useState(false);

  /**
   * On stocke l'état des civilités.
   */
  const [civilites, setCivilites] = useState<DropdownRessource[] | null>(null);

  /**
   * On stocke l'état des communes de naissance.
   */
  //const [communes, setCommunes] = useState<DropdownRessource<Commune>[] | null>(null);

  /**
   * On stocke l'état des pays.
   */
  const [pays, setPays] = useState<DropdownRessource[] | null>(null);

  /**
   * On stocke l'état des nationalités.
   */
  const [nationalites, setNationalites] = useState<DropdownRessource[] | null>(null);

  /**
   * On stocke le nom afin de l'afficher en mode édition et voir.
   */
  const [nomPrenom, setNomPrenom] = useState<string>('');

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  /**
   * Contient un état général des valeurs des champs du formulaire ainsi que les champs qui ont reçu une erreur lors de l'envoi du formulaire.
   */
  const [formInputs, setFormInputs] = useState<{ [K in keyof Apprenant]: { value: Apprenant[K]; error: boolean } }>({
    civilite_id: { value: null, error: false },
    code_ine: { value: null, error: false },
    numero_badge: { value: null, error: false },
    nom: { value: '', error: false },
    nom_jeune_fille: { value: null, error: false },
    prenom: { value: '', error: false },
    prenom_second: { value: null, error: false },
    prenom_troisieme: { value: null, error: false },
    sexe: { value: null, error: false },
    date_naissance: { value: null, error: false },
    lieu_naissance: { value: null, error: false },
    dept_naissance: { value: null, error: false },
    commune_naissance_id: { value: null, error: false },
    pays_naissance_id: { value: null, error: false },
    nationalite_id: { value: null, error: false },
    adr1: { value: null, error: false },
    adr2: { value: null, error: false },
    adr3: { value: null, error: false },
    adr4: { value: null, error: false },
    code_postal: { value: null, error: false },
    ville: { value: null, error: false },
    pays: { value: null, error: false },
    email: { value: null, error: false },
    yp_code: { value: null, error: false },
    created_at: { value: null, error: false },
  });

  /**
   * Permet au composant de changer de page.
   */
  const navigate = useNavigate();

  /**
   * On a besoin des paramètres pour avoir l'ID de la ressource'.
   */
  const params = useParams();

  // On a besoin du useEffect lorsque l'on est en mode édition et lecture pour précharger le formulaire avec les données de la ressource.

  useEffect(() => {
    handleIndex();
    switch (modeFormulaire) {
      case ModeFormulaire.creer:
        break;
      case ModeFormulaire.voir:
      case ModeFormulaire.editer:
        if (params.id === undefined) {
          navigate('/admin/apprenants');
        }
        handleShow(parseInt(params.id as string));
        break;

      default:
        break;
    }
  }, [client]);

  /**
   * Retrouve Les ressources nécessaires pour afficher les dropdown et les stockent dans des .
   *
   * @param indexParams - Paramètres de la recherche.
   */
  const handleIndex = () => {
    setApiIsLoading(true);
    const baseParams: BaseIndexParams = { as_foreign: true, no_pagination: true };

    const promises = [
      client.get<PaginatedApiResponse<Civilite>, BaseIndexParams>('/admin/civilites', { params: { ...baseParams, sort: 'nom' } }),
      //client.get<PaginatedApiResponse<Commune>, BaseIndexParams>('/admin/communes', { params: { ...baseParams, sort: 'nom' } }),
      client.get<PaginatedApiResponse<Pays>, BaseIndexParams>('/admin/pays', { params: { ...baseParams, sort: 'nom' } }),
      client.get<PaginatedApiResponse<Nationalite>, BaseIndexParams>('/admin/nationalites', { params: { ...baseParams, sort: 'nom' } }),
    ];

    if (modeFormulaire !== ModeFormulaire.creer) {
      //promises.push(client.get<GoodApiResponse<ProfilApprenant>>(`/admin/apprenants/${parseInt(params.id as string)}/profils`) as Promise<AxiosResponse>);
    }

    Promise.all(promises)
      .then((response) => {
        setCivilites(response[0].data.data as unknown as DropdownRessource[]);
        //setCommunes(response[1].data.data as unknown as DropdownRessource<Commune>[]);
        setPays(response[1].data.data as unknown as DropdownRessource[]);
        setNationalites(response[2].data.data as unknown as DropdownRessource[]);
        if (modeFormulaire !== ModeFormulaire.creer) {
          //setOwnedProfils(response[4].data.data as unknown as Ressource<ProfilApprenant>[]);
        }
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Retrouve par le réseau la ressource liée à l'ID foruni et la met dans un état local.
   * Ne doit être utilisé qu'en mode édition et lecture seule.
   *
   * @param ressourceId - ID de la ressource dont on veut retrouver les inapprenants.
   */
  const handleShow = (ressourceId: Ressource['id']) => {
    if (apiIsLoading === true) {
      return;
    }

    setApiIsLoading(true);
    client
      .get<GoodApiResponse<Apprenant>>(`/admin/apprenants/${ressourceId}`)
      .then((reponse) => {
        const updatedInputs = Object.keys(reponse.data.data).reduce((acc, key) => {
          return { ...acc, [key]: { value: reponse.data.data[key as keyof Apprenant], error: false } };
        }, formInputs);
        setFormInputs(updatedInputs);
        setNomPrenom(`${updatedInputs.prenom.value} ${updatedInputs.nom.value}`);
      })
      .catch((error: ErrorApiResponse<Apprenant>) => {
        handleError(error, () => navigate('/admin/apprenants'));
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Permet d'écrire le mot correspondant au mode de fomulaire. Ce mot s'affiche au début du formulaire juste à coté de la ressource.
   *
   * @param modeFormulaire - Mode du formulaire (creer, editer et voir).
   * @returns Mot à afficher correspondant au mode de formulaire.
   * @throws Lance une erreur si aucun mode formulaire n'est fourni.
   */
  const afficheModeFormulaire = (modeFormulaire: ModeFormulaire) => {
    switch (modeFormulaire) {
      case ModeFormulaire.creer:
        return 'Création';
      case ModeFormulaire.editer:
        return 'Édition';
      case ModeFormulaire.voir:
        return 'Voir';

      default:
        throw new Error("Vous n'avez pas choisi un mode de formulaire adéquat.");
    }
  };

  if (
    civilites === null ||
    pays === null ||
    nationalites === null
    //(modeFormulaire !== ModeFormulaire.creer && ownedProfils === null)
  ) {
    return <Fragment>Chargement...</Fragment>;
  }

  return (
    <Fragment>
      <Helmet>
        <title>
          {afficheModeFormulaire(modeFormulaire)} apprenant - {Config.app_label}
        </title>
      </Helmet>
      <HeaderForm
        text={`${afficheModeFormulaire(modeFormulaire)} apprenant ${modeFormulaire !== ModeFormulaire.creer ? nomPrenom : ''}`}
        returnUrl='/admin/apprenants'
      />
      <Tabs value='general'>
        <TabsHeader>
          <Tab value='general'>Général</Tab>
        </TabsHeader>
        <TabsBody>
          <TabPanel value='general'>
            <form className='relative'>
              <div className='bg-white p-5 rounded-b-lg'>
                <div className='container mx-auto bg-white '>
                  <fieldset className='pb-7 mb-7 border-b'>
                    <legend className='block text-left mb-3 text-xl text-secondary-1'>Identité</legend>
                    <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-2 items-center'>
                      <div>
                        <ReadOnlySelect
                          label='Civilité'
                          value={
                            formInputs.civilite_id.value !== null && formInputs.civilite_id.value !== undefined ? formInputs.civilite_id.value.toString() : ''
                          }
                          error={formInputs.civilite_id.error}
                          options={civilites}
                        />
                      </div>
                      <div>
                        <ReadOnlyText label='Nom' value={formInputs.nom.value as string} error={formInputs.nom.error} />
                      </div>
                      <div>
                        <ReadOnlyText label='Nom de jeune fille' value={formInputs.nom_jeune_fille.value as string} error={formInputs.nom_jeune_fille.error} />
                      </div>
                      <div>
                        <ReadOnlyText label='Prénom' value={formInputs.prenom.value as string} error={formInputs.prenom.error} />
                      </div>
                      <div>
                        <ReadOnlyText label='Prénom second' value={formInputs.prenom_second.value as string} error={formInputs.prenom_second.error} />
                      </div>
                      <div>
                        <ReadOnlyText label='Prénom troisième' value={formInputs.prenom_troisieme.value as string} error={formInputs.prenom_troisieme.error} />
                      </div>
                    </div>
                    <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-2 items-center'>
                      <div>
                        <ReadOnlyText label='Sexe' value={formInputs.sexe.value as string} error={formInputs.sexe.error} />
                      </div>
                      <div>
                        <ReadOnlySelect
                          label={'Nationalité'}
                          value={
                            formInputs.nationalite_id.value !== null && formInputs.nationalite_id.value !== undefined
                              ? formInputs.nationalite_id.value.toString()
                              : ''
                          }
                          error={formInputs.nationalite_id.error}
                          options={pays}
                        />
                      </div>
                    </div>
                    <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-2 items-center'>
                      <div>
                        <ReadOnlyDate label='Date de naissance' value={formInputs.date_naissance.value as string} error={formInputs.date_naissance.error} />
                      </div>
                      <div>
                        <ReadOnlyText label='Lieu de naissance' value={formInputs.lieu_naissance.value as string} error={formInputs.lieu_naissance.error} />
                      </div>
                      <div>
                        <ReadOnlyText
                          label='Département de naissance'
                          value={formInputs.dept_naissance.value as string}
                          error={formInputs.dept_naissance.error}
                        />
                      </div>
                      <div>
                        <ReadOnlySelect
                          label={'Pays de naissance'}
                          value={
                            formInputs.pays_naissance_id.value !== null && formInputs.pays_naissance_id.value !== undefined
                              ? formInputs.pays_naissance_id.value.toString()
                              : ''
                          }
                          error={formInputs.pays_naissance_id.error}
                          options={pays}
                        />
                      </div>
                    </div>
                    <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-2 items-center'>
                      <div>
                        <ReadOnlyText label='Code INE' value={formInputs.code_ine.value as string} error={formInputs.code_ine.error} />
                      </div>
                      <div>
                        <ReadOnlyText label='Numéro badge' value={formInputs.numero_badge.value as string} error={formInputs.numero_badge.error} />
                      </div>
                      <div>
                        <ReadOnlyNumber label='YParéo CodeApprenant' value={formInputs.yp_code.value} error={formInputs.yp_code.error} />
                      </div>
                    </div>
                  </fieldset>
                  <fieldset className='pb-7 mb-7 border-b'>
                    <legend className='block text-left mb-3 text-xl text-secondary-1'>Adresse</legend>
                    <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-2 items-center'>
                      <div>
                        <ReadOnlyText label='Adresse 1' value={formInputs.adr1.value as string} error={formInputs.adr1.error} />
                      </div>
                      <div>
                        <ReadOnlyText label='Adresse 2' value={formInputs.adr2.value as string} error={formInputs.adr2.error} />
                      </div>
                      <div>
                        <ReadOnlyText label='Adresse 3' value={formInputs.adr3.value as string} error={formInputs.adr3.error} />
                      </div>
                      <div>
                        <ReadOnlyText label='Adresse 4' value={formInputs.adr4.value as string} error={formInputs.adr4.error} />
                      </div>
                      <div>
                        <ReadOnlyText label='Code postal' value={formInputs.code_postal.value as string} error={formInputs.code_postal.error} />
                      </div>
                      <div>
                        <ReadOnlyText label='Ville' value={formInputs.ville.value as string} error={formInputs.ville.error} />
                      </div>
                      <div>
                        <ReadOnlyText label='Pays' value={formInputs.pays.value as string} error={formInputs.pays.error} />
                      </div>
                    </div>
                  </fieldset>
                  <fieldset className='pb-7 mb-7'>
                    <legend className='block text-left mb-3 text-xl text-secondary-1'>Contact</legend>
                    <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-2 items-center'>
                      <div>
                        <ReadOnlyText label='E-mail' value={formInputs.email.value as string} error={formInputs.email.error} />
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>
            </form>
          </TabPanel>
        </TabsBody>
      </Tabs>
      {apiIsLoading ? <LoadingAbsolute /> : ''}
    </Fragment>
  );
};

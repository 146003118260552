import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { AcademicCapIcon, EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import { Helmet } from 'react-helmet';
import toast from 'react-hot-toast';
import { Menu, MenuHandler, Button, MenuList, MenuItem } from '@material-tailwind/react';
import { Config } from '../../config/config';
import { LoadingAbsolute } from '../../components/LoadingAbsolute';
import { useApi } from '../../providers/ApiProvider';
import { GoodApiResponse, ErrorApiResponse } from '../../types/api';
import { Avis } from '../../types/SalariesRessources';
import nl2br from 'react-nl2br';
import { ModalModificationAvis } from '../../components/Modales/Salarie/ModalModificationAvis';
import { ModalSuppression } from '../../components/Modales/Admin/ModalSuppression';
import { StarGroupDisplay } from '../../components/Stars/StarGroupDisplay';
import { Link } from 'react-router-dom';
import { handleError } from '../../utils/ErrorHandler';

/**
 * Ce composant affiche la liste des avis côté salarié.
 */
export const MesAvisScreen: FunctionComponent = () => {
  /**
   * On stocke si on est en train d'utiliser l'API ou non.
   */
  const [apiIsLoading, setApiIsLoading] = useState(false);

  /**
   * On stocke les Souhait possédés par le profil.
   */
  const [avis, setAvis] = useState<Avis[] | null>(null);

  /**
   * On doit stocker l'ID de la ressource selectionneée pour gérer sa suppression dans la modale
   */
  const [avisSelectionne, setAvisSelectionne] = useState<Avis | null>(null);

  /**
   * Permet d'ouvrir une modale lorsque l'on souhaite supprimer un avis.
   */
  const [modalModificationEstVisible, setModalModificationEstVisible] = useState<boolean>(false);

  /**
   * Permet d'ouvrir une modale lorsque l'on souhaite supprimer un avis.
   */
  const [modalSuppressionEstVisible, setModalSuppressionEstVisible] = useState<boolean>(false);

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  /**
   * Permet de recharger les données au chargement de page
   */
  useEffect(() => {
    handleIndex();
  }, [client]);

  /**
   * Retrouve par le réseau les avis du salarié et les met dans un état local.
   */
  const handleIndex = () => {
    setApiIsLoading(true);

    client
      .get<GoodApiResponse<Avis[]>>(`/salarie/avis`)
      .then((response) => {
        setAvis(response.data.data);
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Supprime un avis.
   *
   * @param avisToken - Token de l'avis à supprimer.
   */
  const handleDestroy = (avisToken: Avis['token']) => {
    setApiIsLoading(true);

    client
      .delete<GoodApiResponse>(`/salarie/avis/${avisToken}`)
      .then(() => {
        setAvis(avis && avis.filter((avi) => avi.token !== avisToken));
        toast.success('Avis supprimé.');
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Cette méthode gère l'ouverture de la modale de modification d'avis.
   *
   * @param avi - Avis que l'eon souhaite modifier.
   */
  const handleModalModification = (avi: Avis) => {
    setAvisSelectionne(avi);
    setModalModificationEstVisible(true);
  };

  /**
   * Cette méthode gère l'ouverture de la modale de suppression d'avis.
   *
   * @param avi - Avis que l'eon souhaite modifier.
   */
  const handleModalSuppression = (avi: Avis) => {
    setAvisSelectionne(avi);
    setModalSuppressionEstVisible(true);
  };

  if (avis === null) {
    return <Fragment>Chargement...</Fragment>;
  }

  return (
    <Fragment>
      <Helmet>
        <title>Mes avis - {Config.app_label}</title>
      </Helmet>
      <div className='px-4 py-4 md:py-3 bg-gray-100 rounded-tl-lg rounded-tr-lg flex flex-col sm:flex-row gap-7 items-center'>
        <div className='sm:flex items-center justify-between'>
          <h1 className='text-base sm:text-lg md:text-xl poppins-bold leading-normal text-secondary-1'>Mes avis</h1>
        </div>
      </div>
      <div>
        <div className='bg-white rounded-b-lg w-full overflow-x-auto'>
          {avis && avis.length > 0 ? (
            <table className='table-auto min-w-[1000px] bg-white rounded-b-lg'>
              <thead className='text-sm text-gray-500 text-left border-b'>
                <tr>
                  <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Nom de la formation</th>
                  <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Note</th>
                  <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Commentaire</th>
                  <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Date de validation</th>
                  <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'></th>
                </tr>
              </thead>
              <tbody>
                {avis.map((avi) => (
                  <tr
                    key={avi.token}
                    className={`border-gray-100 border-b hover:bg-gray-100 ${!avi.est_valide && 'cursor-pointer'}`}
                    onDoubleClick={() => !avi.est_valide && handleModalModification(avi)}>
                    <td className='px-4 py-3 text-gray-800 text-sm'>
                      <Link to={'/formations/' + avi.formation.slug} target='blank'>
                        <div className='flex flex-row gap-2 items-center'>
                          {avi.formation.image_couverture ? (
                            <img
                              alt={avi.formation.nom as string}
                              src={avi.formation.image_couverture}
                              tabIndex={0}
                              className='rounded-lg shadow-md object-cover w-10 h-10'
                              loading='lazy'
                            />
                          ) : (
                            <div className={`rounded-lg shadow-md object-cover w-10 h-10 bg-gray-700 flex items-center justify-center`}>
                              <AcademicCapIcon className='h-7 w-7 text-white/50' />
                            </div>
                          )}
                          <div>
                            <div className='poppins-bold poppins-medium text-secondary-1'>{avi.formation.nom}</div>
                            <div className='text-xs poppins-medium text-gray-600'>
                              {`Émis le ${new Intl.DateTimeFormat('default', {
                                year: 'numeric',
                                month: 'numeric',
                                day: 'numeric',
                                hour12: false,
                              }).format(new Date(avi.created_at as string))}`}
                            </div>
                          </div>
                        </div>
                      </Link>
                    </td>
                    <td className='px-4 py-3 text-gray-800 text-sm'>
                      <StarGroupDisplay note={avi.note} />
                    </td>
                    <td className='px-4 py-3 text-gray-800 text-sm poppins line-clamp-3'>{nl2br(avi.description)}</td>
                    <td className='px-4 py-3 poppins text-gray-800 text-sm'>
                      {avi.date_validation
                        ? new Intl.DateTimeFormat('default', {
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                            second: 'numeric',
                            hour12: false,
                          }).format(new Date(avi.date_validation as string))
                        : 'Pas encore validé'}
                    </td>
                    <td className='px-4 py-3 text-gray-800 text-sm'>
                      <Menu placement='right-start'>
                        <MenuHandler>
                          <Button
                            variant='text'
                            className='w-9 h-9 p-1.5 rounded-full text-gray-500 block mx-auto text-center mr-0 hover:bg-white/100 active:bg-transparent'>
                            <EllipsisVerticalIcon className='h-6 w-6' />
                          </Button>
                        </MenuHandler>
                        <MenuList>
                          <MenuItem>
                            <Link to={`/formations/${avi.formation.slug}`} target='blank'>
                              Voir la page de la formation
                            </Link>
                          </MenuItem>
                          <MenuItem onClick={() => handleModalModification(avi)}>Modifier</MenuItem>
                          <MenuItem onClick={() => handleModalSuppression(avi)}>Supprimer</MenuItem>
                        </MenuList>
                      </Menu>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className='text-center px-4 py-7'>Vous n'avez pas encore d'avis.</div>
          )}
        </div>
        {avisSelectionne && (
          <ModalModificationAvis
            visible={modalModificationEstVisible}
            setVisible={setModalModificationEstVisible}
            avis={avisSelectionne as Avis}
            handleIndex={handleIndex}
          />
        )}
        <ModalSuppression
          visible={modalSuppressionEstVisible}
          setVisible={setModalSuppressionEstVisible}
          confirmer={() => handleDestroy(avisSelectionne?.token as Avis['token'])}
        />
        {apiIsLoading ? <LoadingAbsolute /> : ''}
      </div>
    </Fragment>
  );
};

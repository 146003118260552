import { TypeProfilEnum } from '../types/auth';

/**
 * Renvoit le type de profil avec style.
 *
 * @param text
 * @returns
 */
export const typeProfil = (text: TypeProfilEnum) => {
  let translatedText = '';
  switch (text) {
    case TypeProfilEnum.dirigeant:
      translatedText = 'Dirigeant';
      break;
    case TypeProfilEnum.delegue:
      translatedText = 'Délégué';
      break;
    case TypeProfilEnum.salarie:
      translatedText = 'Salarié';
      break;
    default:
      translatedText = text;
  }
  return translatedText;
};

/**
 * Cette méthode transforme des chaine de caractères en slug.
 * @param args Textes à transformer en slug.
 * @returns La chaine modifiée et jointe.
 */
export const slugify = (...args: (string | number)[]): string => {
  const value = args.join(' ');

  return value
    .normalize('NFD') // split an accented letter in the base letter and the acent
    .replace(/[\u0300-\u036f]/g, '') // remove all previously split accents
    .toLowerCase()
    .replace(/[^a-z0-9\- ]/g, '') // remove all chars not letters, numbers and spaces (to be replaced)
    .replace(/\s+/g, '-'); // separator
};

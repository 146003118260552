export type GeoCoord = {
  latitude: number;
  longitude: number;
};

/**
 * Convertit un angle en degrés, en radians.
 *
 * @param degrees - Angle en degrés
 *
 * @returns Angle en radians
 */
const toRadians = (degrees: number) => {
  return degrees * (Math.PI / 180);
};

/**
 * Calcule la distance à vol d'oiseau entre deux point géométriques terrestres en utilisant la méthode de Haversine.
 *
 * @param point1 - Premier point.
 * @param point2 - Deuxième point.
 *
 * @returns distance entre les deux points, exprimée en mètres.
 */
export const calculateDistance = (point1: GeoCoord, point2: GeoCoord) => {
  /**
   * @constant earthRadius - Rayon moyen de la Terre, exprimé en mètres.
   */
  const earthRadius = 6371009;

  const dLat = toRadians(point2.latitude - point1.latitude);
  const dLon = toRadians(point2.longitude - point1.longitude);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(point1.latitude)) * Math.cos(toRadians(point2.latitude)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const distance = earthRadius * c;

  return distance;
};

import { AcademicCapIcon } from '@heroicons/react/24/outline';
import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { DatesSiteDisplay } from './DateSiteDisplay';

export const FormationSessionDisplay: FunctionComponent<{
  formation?: any | null;
  groupe?: any | null;
}> = ({ formation = null, groupe = null }) => {
  if (formation) {
    return (
      <div className='flex flex-row gap-3 items-center montserrat'>
        {formation.image_couverture ? (
          <Link to={'/formations/' + formation.slug} target='blank' className='flex-none'>
            <img
              alt={formation.nom as string}
              src={formation.image_couverture}
              tabIndex={0}
              className='rounded-md shadow-md object-cover w-16 h-16'
              loading='lazy'
            />
          </Link>
        ) : (
          <Link to={'/formations/' + formation.slug} target='blank' className='flex-none'>
            <div className={`rounded-md shadow-md object-cover w-16 h-16 bg-gray-700 flex items-center justify-center`}>
              <AcademicCapIcon className='h-7 w-7 text-white/50' />
            </div>
          </Link>
        )}
        <div>
          <div className='text-secondary-1 leading-3 mb-2 text-sm'>
            <Link to={'/formations/' + formation.slug} target='blank' title={formation.nom} className='montserrat-semibold line-clamp-1'>
              {formation.nom}
            </Link>
          </div>
          <DatesSiteDisplay groupe={groupe} />
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
};

import { Dialog, DialogHeader, DialogBody, DialogFooter, Button } from '@material-tailwind/react';
import { FunctionComponent } from 'react';

/**
 * Ce composant permet d'afficher une Modale pour que le salarié puisse refuser un souhait.
 *
 * @param visible - Précise si la modale et ouverte ou non. Provient du parent.
 * @param setVisible - Accesseur qui modifie la visibilité de la modale. Provient du parent.
 * @param handleAnnule - Callback qui permet d'annuler un souhait. Provient du parent.
 */
export const ModalAnnuleSouhaitSalarie: FunctionComponent<{
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  handleAnnule: () => void;
}> = ({ visible, setVisible, handleAnnule }) => {
  return (
    <Dialog open={visible} size='xs' handler={setVisible}>
      <DialogHeader>Annulation du souhait</DialogHeader>
      <DialogBody divider>Ceci informera votre entreprise que vous avez annulé votre souhait de formation. Confirmer ?</DialogBody>
      <DialogFooter>
        <Button variant='text' color='gray' onClick={() => setVisible(false)} className='mr-1 !rounded-full'>
          Annuler
        </Button>
        <Button
          variant='filled'
          color='orange'
          onClick={() => {
            handleAnnule();
            setVisible(false);
          }}>
          Confirmer
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Config } from '../../../config/config';
import { useApi } from '../../../providers/ApiProvider';
import { GoodApiResponse, ErrorApiResponse } from '../../../types/api';
import { EllipsisVerticalIcon, PlusIcon } from '@heroicons/react/24/outline';
import { InputDate } from '../../../components/Inputs/InputDate';
import { handleError } from '../../../utils/ErrorHandler';
import { Convocation } from '../../../types/AdminRessources';
import { useNavigate } from 'react-router-dom';
import { Button, Menu, MenuHandler, MenuItem, MenuList } from '@material-tailwind/react';
import toast from 'react-hot-toast';
import { ModalAjouterConvocationAdmin } from '../../../components/Modales/Admin/ModalAjouterConvocationAdmin';
import { ModalSuppressionConvocation } from '../../../components/Modales/Admin/ModalSuppressionConvocation';
import { EntrepriseDisplay } from '../../../components/EntrepriseDisplay';
import { UserDisplay } from '../../../components/UserDisplay';
import { FormationSessionDisplay } from '../../../components/FormationSessionDisplay';
import { DownloadLink } from '../../../components/DownloadLink';

/**
 * Ce composant affiche la liste des convocation côté administrateur.
 */
export const AdminConvocationsScreen: FunctionComponent = () => {
  /**
   * On stocke si on est en train d'utiliser l'API ou non.
   */
  const [apiIsLoading, setApiIsLoading] = useState(false);

  /**
   * Date du jour.
   * @constant
   */
  const currentDate = new Date();

  /**
   * Date d'il y a 7 jour.
   * @constant
   */
  const sevenDayAgo = new Date(new Date().setDate(currentDate.getDate() - 7));

  /**
   * État local qui gère le champ de la date de départ de la recherche de devis.
   * @defaultValue La value stocké dans l'état local ou la date du jour au format MySQL
   */
  const [from, setFrom] = useState<string>(
    localStorage.getItem(`AdminConvocationsScreen-From`) ||
      `${sevenDayAgo.getFullYear()}-${sevenDayAgo.getMonth() < 9 ? `0${sevenDayAgo.getMonth() + 1}` : sevenDayAgo.getMonth() + 1}-${
        sevenDayAgo.getDate() < 10 ? `0${sevenDayAgo.getDate()}` : sevenDayAgo.getDate()
      }`,
  );

  /**
   * État local qui gère le champ de la date de fin de la recherche de devis.
   * @defaultValue La value stocké dans l'état local ou la date du jour au format MySQL
   */
  const [to, setTo] = useState<string>(
    localStorage.getItem(`AdminConvocationsScreen-To`) ||
      `${currentDate.getFullYear()}-${currentDate.getMonth() < 9 ? `0${currentDate.getMonth() + 1}` : currentDate.getMonth() + 1}-${
        currentDate.getDate() < 10 ? `0${currentDate.getDate()}` : currentDate.getDate()
      }`,
  );

  /**
   * Permet de stocker la liste des convocations.
   */
  const [convocations, setConvocations] = useState<Convocation[] | null>(null);

  /**
   * Permet d'ouvrir une modale lorsque l'on souhaite ajouter une facture.
   */
  const [modalAjouterConvocationEstVisible, setModalAjouterConvocationEstVisible] = useState<boolean>(false);

  /**
   * État local pour gérer la facture selectionné en vue d'ouvrir une modale dessus.
   */
  const [convocationSelectionne, setConvocationSelectionne] = useState<Convocation | null>(null);

  /**
   * Permet d'ouvrir une modale lorsque l'on souhaite supprimer une facture.
   */
  const [modalSuppressionConvocationEstVisible, setModalSuppressionConvocationEstVisible] = useState<boolean>(false);

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  /**
   * Permet au composant de changer de page.
   */
  const navigate = useNavigate();

  /**
   * Permet de recharger les données au chargement de page ou au changement de paramètres
   */
  useEffect(() => {
    handleIndex();
  }, [client, from, to]);

  /**
   * Retrouve par le réseau la liste des devis et les rangent et la met dans un état local.
   */
  const handleIndex = () => {
    if (apiIsLoading === true) {
      return;
    }

    setApiIsLoading(true);
    client
      .get<GoodApiResponse<Convocation[]>>(`/admin/convocations`, { params: { from, to } })
      .then((response) => {
        setConvocations(response.data.data);
      })
      .catch((error: ErrorApiResponse<Convocation>) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Ouvre une modale pour informer l'administrateur qu'il s'apprète à supprimer une convocation.
   * @param convocation - Convocation selectionnée.
   */
  const handleModaleSuppressionConvocation = (convocation: Convocation) => {
    setConvocationSelectionne(convocation);
    setModalSuppressionConvocationEstVisible(true);
  };

  /**
   * Cette méthode est passé en paramètre de la modale de suppression de convocation.
   */
  const handleSupprime = () => {
    setApiIsLoading(true);

    client
      .delete<GoodApiResponse>(`/admin/convocations/${(convocationSelectionne as Convocation).id}`)
      .then(() => {
        handleIndex();
        toast.success(`Convocation supprimée`);
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  return (
    <Fragment>
      <Helmet>
        <title>Convocations - {Config.app_label}</title>
      </Helmet>
      <div className='px-4 py-4 md:py-3 bg-gray-100 rounded-tl-lg rounded-tr-lg flex flex-col sm:flex-row gap-5 items-start lg:items-center justify-between'>
        <div className='sm:flex items-center justify-between'>
          <h1 className='text-base sm:text-lg md:text-xl poppins-bold leading-normal text-secondary-1'>Convocations</h1>
        </div>
        <div className='flex flex-col lg:flex-row gap-3 items-center'>
          <div className='flex flex-row items-center gap-3'>
            <span className='text-xs text-secondary-1 mb-1'>Du&nbsp;:</span>
            <InputDate
              value={from}
              onChange={(e) => {
                setFrom(e.target.value);
                localStorage.setItem(`AdminConvocationsScreen-From`, e.target.value);
              }}
            />
          </div>
          <div className='flex flex-row items-center gap-3'>
            <p className='text-xs text-gray-700 mb-1'>Au&nbsp;:</p>
            <InputDate
              value={to}
              onChange={(e) => {
                setTo(e.target.value);
                localStorage.setItem(`AdminConvocationsScreen-To`, e.target.value);
              }}
            />
          </div>
        </div>
        <button
          className='mr-1 py-1 px-6 flex flex-row items-center rounded-full bg-primary text-white'
          onClick={() => {
            setModalAjouterConvocationEstVisible(true);
          }}>
          <PlusIcon className='h-4 w-4 inline mr-2 flex-none' />
          <span>Ajouter</span>
        </button>
      </div>
      <div>
        <div className='bg-white w-full overflow-x-auto rounded-b-lg shadow-lg'>
          {convocations && convocations.length > 0 ? (
            <table className='w-full min-w-[1200px] bg-white rounded-b-lg'>
              <thead className='text-sm text-gray-500 text-left border-b'>
                <tr>
                  <th className='pl-4 py-4 text-secondary-1 px-2 text-left text-sm tracking-normal leading-4'>Formation et session</th>
                  <th className='py-4 text-secondary-1 px-2 text-left text-sm tracking-normal leading-4'>Entreprise</th>
                  <th className='py-4 text-secondary-1 px-2 text-left text-sm tracking-normal leading-4'>Salarié</th>
                  <th className='py-4 text-secondary-1 px-2 text-left text-sm tracking-normal leading-4'>Fichier</th>
                  <th className='py-4 text-secondary-1 px-2 text-left text-sm tracking-normal leading-4 '>Date de convocation</th>
                  <th className='py-4 text-secondary-1 px-2 text-left text-sm tracking-normal leading-4'>Créée le</th>
                  <th className='py-4 text-secondary-1 px-2 text-left text-sm tracking-normal leading-4'></th>
                </tr>
              </thead>
              <tbody>
                {convocations.map((convocation) => (
                  <tr
                    key={convocation.id}
                    className=' border-gray-100 border-b hover:bg-gray-100'
                    onDoubleClick={() => navigate(`/admin/convocations/${convocation.id}`)}>
                    <td className='px-4 py-3'>
                      <FormationSessionDisplay formation={convocation.formation} groupe={convocation.groupe} />
                    </td>
                    <td className='px-2 py-3'>
                      <EntrepriseDisplay entreprise={convocation.entreprise} />
                    </td>
                    <td className='px-2 py-3'>
                      <UserDisplay user={convocation.user} />
                    </td>
                    <td className='px-2 py-3 text-secondary-1 text-xs'>
                      {convocation.fichier && <DownloadLink fichier={convocation.fichier} titre={convocation.titre} />}
                    </td>
                    <td className='px-2 py-3 text-secondary-1/90 text-xs'>
                      {convocation.date_convocation
                        ? new Intl.DateTimeFormat('default', {
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                            hour12: false,
                          }).format(new Date(convocation.date_convocation as string))
                        : ''}
                    </td>
                    <td className='px-2 py-3 text-secondary-1/90 text-xs'>
                      {convocation.created_at
                        ? new Intl.DateTimeFormat('default', {
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                            hour12: false,
                          }).format(new Date(convocation.created_at as string))
                        : ''}
                    </td>
                    <td className='px-4 py-3 text-gray-800 text-sm'>
                      <Menu placement='right-start'>
                        <MenuHandler>
                          <Button
                            variant='text'
                            className='w-9 h-9 p-1.5 rounded-full text-gray-500 block mx-auto text-center mr-0 hover:bg-white/100 active:bg-transparent'>
                            <EllipsisVerticalIcon className='h-6 w-6' />
                          </Button>
                        </MenuHandler>
                        <MenuList>
                          <MenuItem onClick={() => navigate(`/admin/convocations/${convocation.id}`)}>Voir</MenuItem>
                          <MenuItem
                            onClick={() => {
                              handleModaleSuppressionConvocation(convocation);
                            }}>
                            Supprimer
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className='text-center px-4 py-7'>Il n'y a pas de convocation pour la période choisie.</div>
          )}
        </div>
      </div>
      <ModalAjouterConvocationAdmin visible={modalAjouterConvocationEstVisible} setVisible={setModalAjouterConvocationEstVisible} handleIndex={handleIndex} />
      <ModalSuppressionConvocation
        visible={modalSuppressionConvocationEstVisible}
        setVisible={setModalSuppressionConvocationEstVisible}
        handleSupprime={handleSupprime}
      />
    </Fragment>
  );
};

import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import { FunctionComponent } from 'react';

export const SubmitSave: FunctionComponent<{ text?: string | undefined | null }> = ({ text }) => {
  return (
    <button type='submit' className='mr-1 py-1 px-6 flex flex-row items-center rounded-full bg-primary text-white'>
      <ArrowDownTrayIcon className='h-4 w-4 inline mr-2 flex-none' />
      <span>{text ? text : 'Sauvegarder'}</span>
    </button>
  );
};

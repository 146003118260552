import { FunctionComponent } from 'react';
import { AcademicCapIcon, CalendarDaysIcon, ChevronRightIcon, MapPinIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import { Avatar } from '../Avatar';
import { DashboardDemande } from '../../types/EntreprisesRessources';
import { Button, Chip, Tooltip } from '@material-tailwind/react';
import { DatesDisplay } from '../DatesDisplay';

/**
 * Ce composant est un encart du tableau de bord entreprises concernant les demandes.
 *
 * @param demandes - Tableau de demandes.
 */
export const DashboardEntrepriseDemandes: FunctionComponent<{ demandes: DashboardDemande[] }> = ({ demandes }) => {
  return (
    <div className='mx-auto mt-2 shadow-md rounded-md bg-white'>
      <div className='flex flex-col sm:flex-row items-center justify-between p-5'>
        <h3 className='text-secondary-2 poppins-semibold text-center md:text-left'>Demandes de formation</h3>
        <Link to='/entreprise/demandes' className='text-sm text-secondary-1/90 flex items-center hover:text-primary'>
          Tout voir
          <ChevronRightIcon className='w-3 h-3' />
        </Link>
      </div>
      {demandes && demandes.length > 0 ? (
        demandes.slice(0, 5).map((demande) => (
          <Link key={demande.token} to={`/entreprise/demandes/${demande.token}`}>
            <div className='border-b last:border-none border-secondary-1/10 mx-auto p-5 hover:bg-gray-100'>
              <div className='flex flex-col md:flex-row lg:flex-col xl:flex-row justify-between items-center gap-5 text-secondary-1'>
                <div className='w-full xl:max-w-[40%] flex md:justify-start gap-2'>
                  {demande.formation.image_couverture ? (
                    <img
                      alt={demande.formation.nom as string}
                      src={demande.formation.image_couverture}
                      tabIndex={0}
                      className='rounded-lg shadow-md object-cover w-10 h-10 aspect-1'
                      loading='lazy'
                    />
                  ) : (
                    <div className='rounded-lg shadow-md object-cover w-10 h-10 bg-gray-700 flex items-center justify-center aspect-1'>
                      <AcademicCapIcon className='h-7 w-7 text-white/50' />
                    </div>
                  )}
                  <div>
                    <h3 className='poppins-medium leading-5'>{demande.formation.nom}</h3>
                    <div className='text-secondary-1/80 flex flex-row items-center gap-1'>
                      <CalendarDaysIcon className='w-5 h-5 flex-none' />
                      {demande.groupe && demande.groupe.date_debut && demande.groupe.date_fin ? (
                        <DatesDisplay className='text-xs' date_start={demande.groupe.date_debut} date_end={demande.groupe.date_fin} />
                      ) : (
                        <span className='text-xs font-medium pl-0'>Dates non définies</span>
                      )}
                    </div>
                  </div>
                </div>
                <div className='w-full xl:max-w-[60%] flex flex-row items-center justify-between gap-3'>
                  <div className='flex items-center justify-center md:justify-start gap-1 max-w-[100px] sm:max-w-none'>
                    <div>
                      <MapPinIcon className='w-5 h-5 flex-none' />
                    </div>
                    <span className='text-sm'>{demande.groupe && demande.groupe.site ? demande.groupe.site.nom : 'Lieu non défini'}</span>
                  </div>
                  <Tooltip content={`${demande.user.prenom} ${demande.user.nom}`}>
                    <Button variant='text' className='p-0 rounded-md hover:bg-transparent mr-auto md:mr-0 lg:mr-auto xl:mr-0 ml-auto aspect-1'>
                      <Avatar key={demande.user.email} className='w-11 h-11 rounded-md hover:z-10 focus:z-10 aspect-1' src={demande.user.image_avatar} />
                    </Button>
                  </Tooltip>

                  <div className='text-xs text-secondary-1/70 flex flex-col gap-1 min-w-[85px]'>
                    <div className='flex items-center justify-center md:justify-end'>
                      {demande.date_acceptation === null && demande.date_refus === null ? (
                        <Chip value='En attente' className='rounded-full bg-[#fcf9da] text-[#A16207] border border-[#cfa468]/50 normal-case py-1' />
                      ) : demande.date_acceptation ? (
                        <Chip value='Accepté' className='rounded-full bg-[#DCFCE7] text-green-700 border border-green-300 normal-case py-1' />
                      ) : demande.date_refus ? (
                        <Chip value='Refusé' className='rounded-full  bg-[#FEE2E2] text-[#B91C1C] border border-[#e47171] normal-case py-1' />
                      ) : (
                        <Chip value='Inconnu' className='rounded-full bg-secondary-1/10 text-secondary-1 border border-secondary-1/50 normal-case py-1' />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        ))
      ) : (
        <p className='p-5 text-center text-sm font-medium text-secondary-1/70'>Pas de demande de formation pour le moment.</p>
      )}
    </div>
  );
};

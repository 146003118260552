import { Dialog, DialogHeader, DialogBody, DialogFooter, Button } from '@material-tailwind/react';
import { FunctionComponent, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useApi } from '../../../providers/ApiProvider';
import { Convocation, Demande } from '../../../types/AdminRessources';
import { GoodApiResponse, ErrorApiResponse } from '../../../types/api';
import { handleError } from '../../../utils/ErrorHandler';
import { AlertError } from '../../Alertes';
import { InputDate } from '../../Inputs/InputDate';
import { InputFile } from '../../Inputs/InputFile';
import { InputTextarea } from '../../Inputs/InputTextarea';
import { LoadingAbsolute } from '../../LoadingAbsolute';

/**
 * Ce composant permet d'afficher une Modale pour que les admins puissent ajouter une convocation au salarié.
 *
 * @param visible - Précise si la modale et ouverte ou non. Provient du parent.
 * @param setVisible - Accesseur qui modifie la visibilité de la modale. Provient du parent.
 * @param demande - Informations de la demande. Provient du parent.
 * @param handleShow - Callback qui réalise l'appel pour recharger les données de la demande. Provient du parent.
 */
export const ModalAjouterConvocationDemande: FunctionComponent<{
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  demande: Demande;
  handleShow: () => void;
}> = ({ visible, setVisible, demande, handleShow }) => {
  /**
   * On stocke si on est en train d'utiliser l'API ou non.
   */
  const [apiIsLoading, setApiIsLoading] = useState(false);

  /**
   * Permet de donner un titre au document.
   */
  const [titre, setTitre] = useState<Convocation['titre']>('');

  /**
   * Fichier à envoyer.
   */
  const [fichier, setFichier] = useState<Convocation['fichier']>('');

  /**
   * Description du fichier, optionnel
   */
  const [description, setDescription] = useState<Convocation['description']>('');

  /**
   * Date de la convocation
   */
  const [dateConvocation, setDateConvocation] = useState<Convocation['date_convocation']>('');

  /**
   * On stocke l'eventuelle erreur.
   */
  const [error, setError] = useState<string | null>(null);

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  /**
   * Permet de réinitialiser les champs de la modale à chaque fois qu'on l'ouvre où qu'on la ferme.
   */
  useEffect(() => {
    setTitre('');
    setFichier('');
    setDescription('');
    setDateConvocation('');
    setError(null);
  }, [visible]);

  /**
   * Envoie le fichier par l'API.
   */
  const handleEnvoie = () => {
    setApiIsLoading(true);

    client
      .post<GoodApiResponse>(`/admin/convocations`, {
        titre: titre,
        fichier: fichier,
        description: description,
        date_convocation: dateConvocation,
        demande_id: demande.id,
        profil_id: demande.profil.id,
      })
      .then(() => {
        setVisible(false);
        handleShow();
        toast.success(`Envoie de la convocation réussi`);
      })
      .catch((error: ErrorApiResponse<Convocation>) => {
        handleError(
          error,
          () => {
            setError(error.response.data.message);
          },
          () => {
            setError(error.message);
          },
        );
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  return (
    <Dialog open={visible} size='lg' handler={setVisible}>
      <DialogHeader>Ajouter une convocation</DialogHeader>
      <DialogBody divider>
        {error && <AlertError text={error} />}
        <div>
          <p>Fichier :</p>
        </div>
        <InputFile
          onChange={(titre, base64) => {
            setTitre(titre);
            setFichier(base64);
            setError(null);
          }}
        />
        <div className='pt-4'>
          <p>Description (facultatif)</p>
        </div>
        <InputTextarea value={description ? description : ''} onChange={(e) => setDescription(e.target.value)} />

        <div className='pt-4'>
          <p>Date de convocation</p>
        </div>
        <InputDate
          value={dateConvocation as string}
          onChange={(e) => {
            setDateConvocation(e.target.value);
          }}
        />
      </DialogBody>
      <DialogFooter>
        <Button
          variant='text'
          color='gray'
          onClick={() => {
            setTitre('');
            setFichier('');
            setDateConvocation('');
            setError(null);
            setVisible(false);
          }}
          className='mr-1 !rounded-full'
          disabled={apiIsLoading}>
          Annuler
        </Button>
        <Button
          variant='filled'
          color='orange'
          onClick={() => {
            handleEnvoie();
          }}
          disabled={apiIsLoading}>
          Confirmer
        </Button>
      </DialogFooter>
      {apiIsLoading ? <LoadingAbsolute /> : ''}
    </Dialog>
  );
};

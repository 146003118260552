import { FunctionComponent, Fragment } from 'react';
import { Helmet } from 'react-helmet';

import { CategoriesGrid } from '../components/CategoriesGrid';

import { CtaHomeEspaceClient } from '../components/CtaHomeEspaceClient';
import { CtaHomeFSM } from '../components/CtaHomeFSM';
import { CtaHomeHandi } from '../components/CtaHomeHandi';

import { HeroSections } from '../components/HeroSections';
import { Container } from '../components/Container';

import { Config } from '../config/config';
import { SearchBar } from '../components/SearchBar';

/**
 * Il s'agit du composant qui affiche la page d'acceuil de la partie Front-Office de l'application.
 */
export const HomeScreen: FunctionComponent = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Bienvenue - {Config.app_label}</title>
        <meta name='description' content={Config.app_description} />
      </Helmet>
      <div>
        <HeroSections />
        <Container className={''}>
          <div className='bg-secondary-2 px-7 py-6 rounded-lg -mt-14 relative mx-5 md:mx-0'>
            <SearchBar className='mx-auto' fromBackOffice={false} />
          </div>
        </Container>
        <CategoriesGrid />
        <CtaHomeEspaceClient />
        <CtaHomeFSM />
        <CtaHomeHandi />
      </div>
    </Fragment>
  );
};

import { Fragment, FunctionComponent } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { InscriptionApprenant } from '../../components/InscriptionApprenant';
import { InscriptionEntreprise } from '../../components/InscriptionEntreprise';

import { useUser } from '../../providers/UserProvider';
import { ProfilInscription } from '../../types/auth';
import { Helmet } from 'react-helmet';
import { Config } from '../../config/config';
import { Tab, TabPanel, Tabs, TabsBody, TabsHeader } from '../../components/Onglets';

/**
 * Ce composant affiche la page d'incription.
 * On peut choisir si on s'inscrit en tant que salarié ou entreprise.
 */
export const RegisterScreen: FunctionComponent = () => {
  /**
   * On a besoin des informations de l'utilisateur.
   */
  const { user } = useUser();

  // Vue
  if (!user || !user.token) {
    return (
      <Fragment>
        <Helmet>
          <title>Inscription - {Config.app_label}</title>
        </Helmet>
        <div className='rounded shadow-sm bg-[#F7FBFE] overflow-hidden'>
          <div className='p-8'>
            <Tabs value='salarie'>
              <TabsHeader>
                <Tab value='salarie'>{ProfilInscription.salarie}</Tab>
                <Tab value='entreprise'>{ProfilInscription.entreprise}</Tab>
              </TabsHeader>
              <TabsBody>
                <TabPanel value='salarie'>
                  <InscriptionApprenant />
                </TabPanel>
                <TabPanel value='entreprise'>
                  <InscriptionEntreprise />
                </TabPanel>
              </TabsBody>
            </Tabs>
          </div>
          <div className='py-4 px-5 lg:px-6 w-full text-sm text-center bg-white text-gray-600'>
            Vous avez déjà un compte&nbsp;?
            <Link to='/connexion' className='montserrat-semibold text-primary hover:text-secondary-1'>
              &nbsp;Connectez-vous.
            </Link>
          </div>
        </div>
      </Fragment>
    );
  } else {
    return <Navigate to='/mon-espace' />;
  }
};

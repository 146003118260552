import { StatutAffiliationEnum, TypeProfilEnum, UserInfo } from './auth';
import { SouhaitDirigeant } from './EntreprisesRessources';
import {
  Avis as AvisDb,
  ConventionCollective,
  Convocation as ConvocationDb,
  Demande as DemandeDb,
  DemandeDocument as DemandeDocumentDb,
  DemandeInformation as DemandeInformationDb,
  Devis as DevisDb,
  Document as DocumentDb,
  Entreprise as EntrepriseDb,
  Facture as FactureDb,
  Formation as FormationDb,
  FormeJuridique,
  Groupe as GroupeDb,
  Log as LogDb,
  Notification as NotificationDb,
  Octa,
  Profil as ProfilDb,
  Site as SiteDb,
  TypeEmployeur,
  User as UserDb,
} from './schematics';

export type Avis = {
  id: AvisDb['id'];
  user: (UserInfo & { id: UserDb['id'] }) | null;
  entreprise: EntrepriseDb | null;
  formation: {
    id: string;
    nom: FormationDb['nom_commercial'];
    image_couverture: FormationDb['image_couverture'];
    slug: FormationDb['slug'];
  };
  note: AvisDb['note'];
  description: AvisDb['description'];
  affiche_avatar: AvisDb['affiche_avatar'];
  affiche_nom_complet: AvisDb['affiche_nom_complet'];
  affiche_nom_entreprise: AvisDb['affiche_nom_entreprise'];
  ip: AvisDb['ip'];
  est_valide: AvisDb['est_valide'];
  date_validation: AvisDb['date_validation'];
  logs: {
    id: number;
    user_id: number;
    profil_id: number | null;
    controller: string;
    action: string;
    resource_id: string;
    ip: string;
    created_at: string;
    user: {
      id: UserDb['id'];
      nom: UserDb['nom'];
      prenom: UserDb['prenom'];
      email: UserDb['email'];
      image_avatar: UserDb['image_avatar'];
      est_actif: boolean;
    };
  }[];
  created_at: AvisDb['created_at'];
  updated_at: string | null;
};

export type Convocation = {
  id: ConvocationDb['id'];
  user: {
    id: UserDb['id'];
    nom: UserDb['nom'];
    prenom: UserDb['prenom'];
    email: UserDb['email'];
    email_verified_at: UserDb['email_verified_at'];
    image_avatar: UserDb['image_avatar'];
    created_at: UserDb['created_at'];
  };
  profil: {
    id: ProfilDb['id'];
    couleur: ProfilDb['couleur'];
    type_profil: TypeProfilEnum;
    statut_affiliation: StatutAffiliationEnum;
  };
  entreprise: {
    id: EntrepriseDb['id'];
    raison_sociale: EntrepriseDb['raison_sociale'];
    enseigne: EntrepriseDb['enseigne'];
    image_logo: EntrepriseDb['image_logo'];
    code_postal: EntrepriseDb['code_postal'];
    ville: EntrepriseDb['ville'];
    pays: EntrepriseDb['pays'];
    effectif: EntrepriseDb['effectif'];
    est_assujetti_taxe: EntrepriseDb['est_assujetti_taxe'];
    num_tva_intracom: EntrepriseDb['num_tva_intracom'];
    siret: EntrepriseDb['siret'];
    site_web: EntrepriseDb['site_web'];
    est_approuvee: EntrepriseDb['est_approuvee'];
    yp_code: EntrepriseDb['yp_code'];
    created_at: EntrepriseDb['created_at'];
    updated_at: string | null;
  };
  demande: {
    id: DemandeDb['id'];
    est_en_attente_informations: DemandeDb['est_en_attente_informations'];
    est_en_attente_documents: DemandeDb['est_en_attente_documents'];
    est_complete: DemandeDb['est_complete'];
    est_paye: DemandeDb['est_paye'];
    est_finalise: DemandeDb['est_finalise'];
    est_archive: DemandeDb['est_archive'];
    date_acceptation: DemandeDb['date_acceptation'];
    date_refus: DemandeDb['date_refus'];
    created_at: DemandeDb['created_at'];
    updated_at: string | null;
  } | null;
  formation: {
    id: FormationDb['id'];
    nom: FormationDb['nom'];
    image_couverture: FormationDb['image_couverture'];
    slug: FormationDb['slug'];
  } | null;
  groupe: Groupe | null;
  titre: ConvocationDb['titre'];
  fichier: ConvocationDb['fichier'];
  description: ConvocationDb['description'];
  telechargements: ConvocationDb['telechargements'];
  date_convocation: ConvocationDb['date_convocation'];
  created_at: ConvocationDb['created_at'];
  updated_at: string | null;
};

export type Demande = {
  id: DemandeDb['id'];
  user: (UserInfo & { id: UserDb['id'] }) | null;
  profil: {
    id: ProfilDb['id'];
    couleur: ProfilDb['couleur'];
    type_profil: string;
    statut_affiliation: string;
  };
  entreprise: EntrepriseDb | null;
  formation: {
    id: FormationDb['id'];
    nom: FormationDb['nom_commercial'];
    image_couverture: FormationDb['image_couverture'];
    slug: FormationDb['slug'];
  };
  groupe: Groupe | null;
  souhait: {
    par_entreprise: SouhaitDirigeant['par_entreprise'];
    par_salarie: SouhaitDirigeant['par_salarie'];
    date_acceptation: SouhaitDirigeant['date_acceptation'];
    date_refus: SouhaitDirigeant['date_refus'];
  };
  token: DemandeDb['token'];
  est_en_attente_informations: DemandeDb['est_en_attente_informations'];
  est_en_attente_documents: DemandeDb['est_en_attente_documents'];
  est_complete: DemandeDb['est_complete'];
  est_paye: DemandeDb['est_paye'];
  date_paiement: DemandeDb['date_paiement'];
  informations_paiement: DemandeDb['informations_paiement'];
  est_finalise: DemandeDb['est_finalise'];
  est_archive: DemandeDb['est_archive'];
  delai_admin_reponse_demande: DemandeDb['delai_admin_reponse_demande'];
  date_limite_admin_reponse_demande: DemandeDb['date_limite_admin_reponse_demande'];
  date_acceptation: DemandeDb['date_acceptation'];
  date_refus: DemandeDb['date_refus'];
  motif_refus: DemandeDb['motif_refus'];
  documents: DemandeDocument[];
  informations: DemandeInformation[];
  convocations: ConvocationDb[];
  devis: DevisDb[];
  factures: FactureDb[];
  created_at: DemandeDb['created_at'];
  updated_at: string | null;
};

export type DemandeDocument = {
  id: DemandeDocumentDb['id'];
  question: DemandeDocumentDb['question'];
  titre: DemandeDocumentDb['titre'];
  fichier: DemandeDocumentDb['fichier'];
  token: DemandeDocumentDb['token'];
  est_traite: DemandeDocumentDb['est_traite'];
  date_traitement: DemandeDocumentDb['date_traitement'];
  created_at: DemandeDocumentDb['created_at'];
  updated_at: DemandeDocumentDb['updated_at'];
};

export type DemandeInformation = {
  id: DemandeInformationDb['id'];
  question: DemandeInformationDb['question'];
  reponse: DemandeInformationDb['reponse'];
  token: DemandeInformationDb['token'];
  est_traite: DemandeInformationDb['est_traite'];
  date_traitement: DemandeInformationDb['date_traitement'];
  created_at: DemandeInformationDb['created_at'];
  updated_at: DemandeInformationDb['updated_at'];
};

export enum DemandeEtatAvancement {
  adecider = 'adecider',
  accepte = 'accepte',
  refuse = 'refuse',
  complete = 'complete',
  paye = 'paye',
  finalise = 'finalise',
  archive = 'archive',
}

export type Devis = {
  id: DevisDb['id'];
  user: {
    id: UserDb['id'];
    nom: UserDb['nom'];
    prenom: UserDb['prenom'];
    email: UserDb['email'];
    email_verified_at: UserDb['email_verified_at'];
    image_avatar: UserDb['image_avatar'];
    created_at: UserDb['created_at'];
  } | null;
  profil: {
    id: ProfilDb['id'];
    couleur: ProfilDb['couleur'];
    type_profil: TypeProfilEnum;
    statut_affiliation: StatutAffiliationEnum;
  } | null;
  entreprise: {
    id: EntrepriseDb['id'];
    raison_sociale: EntrepriseDb['raison_sociale'];
    enseigne: EntrepriseDb['enseigne'];
    image_logo: EntrepriseDb['image_logo'];
    code_postal: EntrepriseDb['code_postal'];
    ville: EntrepriseDb['ville'];
    pays: EntrepriseDb['pays'];
    effectif: EntrepriseDb['effectif'];
    est_assujetti_taxe: EntrepriseDb['est_assujetti_taxe'];
    num_tva_intracom: EntrepriseDb['num_tva_intracom'];
    siret: EntrepriseDb['siret'];
    site_web: EntrepriseDb['site_web'];
    est_approuvee: EntrepriseDb['est_approuvee'];
    yp_code: EntrepriseDb['yp_code'];
    created_at: EntrepriseDb['created_at'];
    updated_at: string | null;
  };
  demande: {
    id: DemandeDb['id'];
    est_en_attente_informations: DemandeDb['est_en_attente_informations'];
    est_en_attente_documents: DemandeDb['est_en_attente_documents'];
    est_complete: DemandeDb['est_complete'];
    est_paye: DemandeDb['est_paye'];
    est_finalise: DemandeDb['est_finalise'];
    est_archive: DemandeDb['est_archive'];
    date_acceptation: DemandeDb['date_acceptation'];
    date_refus: DemandeDb['date_refus'];
    created_at: DemandeDb['created_at'];
    updated_at: string | null;
  } | null;
  formation: {
    id: FormationDb['id'];
    nom: FormationDb['nom'];
    image_couverture: FormationDb['image_couverture'];
    slug: FormationDb['slug'];
  } | null;
  groupe: Groupe | null;
  numero: DevisDb['numero'];
  titre: DevisDb['titre'];
  fichier: DevisDb['fichier'];
  description: DevisDb['description'];
  telechargements: DevisDb['telechargements'];
  date_limite_acceptation: DevisDb['date_limite_acceptation'];
  date_acceptation: DevisDb['date_acceptation'];
  date_refus: DevisDb['date_refus'];
  date_limite_retractation: DevisDb['date_limite_retractation'];
  date_retractation: DevisDb['date_retractation'];
  date_limite_traitement: DevisDb['date_limite_traitement'];
  date_traitement: DevisDb['date_traitement'];
  created_at: DevisDb['created_at'];
  updated_at: string | null;
};

export type Document = {
  id: DocumentDb['id'];
  user: {
    id: UserDb['id'];
    nom: UserDb['nom'];
    prenom: UserDb['prenom'];
    email: UserDb['email'];
    email_verified_at: UserDb['email_verified_at'];
    image_avatar: UserDb['image_avatar'];
    created_at: UserDb['created_at'];
  } | null;
  profil: {
    id: ProfilDb['id'];
    couleur: ProfilDb['couleur'];
    type_profil: TypeProfilEnum;
    statut_affiliation: StatutAffiliationEnum;
  } | null;
  entreprise: {
    id: EntrepriseDb['id'];
    raison_sociale: EntrepriseDb['raison_sociale'];
    enseigne: EntrepriseDb['enseigne'];
    image_logo: EntrepriseDb['image_logo'];
    code_postal: EntrepriseDb['code_postal'];
    ville: EntrepriseDb['ville'];
    pays: EntrepriseDb['pays'];
    effectif: EntrepriseDb['effectif'];
    est_assujetti_taxe: EntrepriseDb['est_assujetti_taxe'];
    num_tva_intracom: EntrepriseDb['num_tva_intracom'];
    siret: EntrepriseDb['siret'];
    site_web: EntrepriseDb['site_web'];
    est_approuvee: EntrepriseDb['est_approuvee'];
    yp_code: EntrepriseDb['yp_code'];
    created_at: EntrepriseDb['created_at'];
    updated_at: string | null;
  } | null;
  formation: {
    id: FormationDb['id'];
    nom: FormationDb['nom'];
    image_couverture: FormationDb['image_couverture'];
    slug: FormationDb['slug'];
  } | null;
  groupe: Groupe | null;
  titre: DocumentDb['titre'];
  fichier: DocumentDb['fichier'];
  description: DocumentDb['description'];
  created_at: DocumentDb['created_at'];
  updated_at: string | null;
};

export type Entreprise = {
  id: EntrepriseDb['id'];
  forme_juridique_id: FormeJuridique['id'] | null;
  type_employeur_id: TypeEmployeur['id'] | null;
  convention_collective_id: ConventionCollective['id'] | null;
  octa_id: Octa['id'] | null;
  raison_sociale: EntrepriseDb['raison_sociale'];
  enseigne: EntrepriseDb['enseigne'];
  image_logo: EntrepriseDb['image_logo'];
  code_naf: EntrepriseDb['code_naf'];
  date_creation: EntrepriseDb['date_creation'];
  date_modification: EntrepriseDb['date_modification'];
  date_radiation: EntrepriseDb['date_radiation'];
  effectif: EntrepriseDb['effectif'];
  est_assujetti_taxe: EntrepriseDb['est_assujetti_taxe'];
  num_rep_metier: EntrepriseDb['num_rep_metier'];
  num_tva_intracom: EntrepriseDb['num_tva_intracom'];
  siret: EntrepriseDb['siret'];
  site_web: EntrepriseDb['site_web'];
  adr1: EntrepriseDb['adr1'];
  adr2: EntrepriseDb['adr2'];
  adr3: EntrepriseDb['adr3'];
  adr4: EntrepriseDb['adr4'];
  code_postal: EntrepriseDb['code_postal'];
  ville: EntrepriseDb['ville'];
  pays: EntrepriseDb['pays'];
  fact_adr1: EntrepriseDb['fact_adr1'];
  fact_adr2: EntrepriseDb['fact_adr2'];
  fact_adr3: EntrepriseDb['fact_adr3'];
  fact_adr4: EntrepriseDb['fact_adr4'];
  fact_code_postal: EntrepriseDb['fact_code_postal'];
  fact_ville: EntrepriseDb['fact_ville'];
  fact_pays: EntrepriseDb['fact_pays'];
  est_approuvee: EntrepriseDb['est_approuvee'];
  yp_code: EntrepriseDb['yp_code'];
  created_at: EntrepriseDb['created_at'];
  updated_at: string | null;
  devis: {
    id: DevisDb['id'];
    numero: DevisDb['numero'];
    titre: DevisDb['titre'];
    fichier: DevisDb['fichier'];
    description: DevisDb['description'];
    telechargements: DevisDb['telechargements'];
    date_limite_acceptation: DevisDb['date_limite_acceptation'];
    date_acceptation: DevisDb['date_acceptation'];
    date_refus: DevisDb['date_refus'];
    date_limite_retractation: DevisDb['date_limite_retractation'];
    date_retractation: DevisDb['date_retractation'];
    date_limite_traitement: DevisDb['date_limite_traitement'];
    date_traitement: DevisDb['date_traitement'];
    created_at: DevisDb['created_at'];
    updated_at: string | null;
  }[];
  factures: {
    id: FactureDb['id'];
    numero: FactureDb['numero'];
    titre: FactureDb['titre'];
    fichier: FactureDb['fichier'];
    description: FactureDb['description'];
    telechargements: FactureDb['telechargements'];
    date_limite_paiement: FactureDb['date_limite_paiement'];
    date_paiement: FactureDb['date_paiement'];
    date_relance1: FactureDb['date_relance1'];
    date_relance2: FactureDb['date_relance2'];
    date_relance3: FactureDb['date_relance3'];
    created_at: FactureDb['created_at'];
    updated_at: string | null;
  }[];
  convocations: {
    id: ConvocationDb['id'];
    titre: ConvocationDb['titre'];
    fichier: ConvocationDb['fichier'];
    description: ConvocationDb['description'];
    telechargements: ConvocationDb['telechargements'];
    date_convocation: ConvocationDb['date_convocation'];
    created_at: ConvocationDb['created_at'];
    updated_at: string | null;
  }[];
};

export type Facture = {
  id: FactureDb['id'];
  user: {
    id: UserDb['id'];
    nom: UserDb['nom'];
    prenom: UserDb['prenom'];
    email: UserDb['email'];
    email_verified_at: UserDb['email_verified_at'];
    image_avatar: UserDb['image_avatar'];
    created_at: UserDb['created_at'];
  } | null;
  profil: {
    id: ProfilDb['id'];
    couleur: ProfilDb['couleur'];
    type_profil: TypeProfilEnum;
    statut_affiliation: StatutAffiliationEnum;
  } | null;
  entreprise: {
    id: EntrepriseDb['id'];
    raison_sociale: EntrepriseDb['raison_sociale'];
    enseigne: EntrepriseDb['enseigne'];
    image_logo: EntrepriseDb['image_logo'];
    code_postal: EntrepriseDb['code_postal'];
    ville: EntrepriseDb['ville'];
    pays: EntrepriseDb['pays'];
    effectif: EntrepriseDb['effectif'];
    est_assujetti_taxe: EntrepriseDb['est_assujetti_taxe'];
    num_tva_intracom: EntrepriseDb['num_tva_intracom'];
    siret: EntrepriseDb['siret'];
    site_web: EntrepriseDb['site_web'];
    est_approuvee: EntrepriseDb['est_approuvee'];
    yp_code: EntrepriseDb['yp_code'];
    created_at: EntrepriseDb['created_at'];
    updated_at: string | null;
  };
  demande: {
    id: DemandeDb['id'];
    est_en_attente_informations: DemandeDb['est_en_attente_informations'];
    est_en_attente_documents: DemandeDb['est_en_attente_documents'];
    est_complete: DemandeDb['est_complete'];
    est_paye: DemandeDb['est_paye'];
    est_finalise: DemandeDb['est_finalise'];
    est_archive: DemandeDb['est_archive'];
    date_acceptation: DemandeDb['date_acceptation'];
    date_refus: DemandeDb['date_refus'];
    created_at: DemandeDb['created_at'];
    updated_at: string | null;
  } | null;
  devis: {
    id: DevisDb['id'];
    numero: DevisDb['numero'];
    titre: DevisDb['titre'];
    fichier: DevisDb['fichier'];
    description: DevisDb['description'];
    date_limite_acceptation: DevisDb['date_limite_acceptation'];
    date_acceptation: DevisDb['date_acceptation'];
    date_refus: DevisDb['date_refus'];
    date_limite_retractation: DevisDb['date_limite_retractation'];
    date_retractation: DevisDb['date_retractation'];
    date_limite_traitement: DevisDb['date_limite_traitement'];
    date_traitement: DevisDb['date_traitement'];
    created_at: DevisDb['created_at'];
    updated_at: string | null;
  } | null;
  formation: {
    id: FormationDb['id'];
    nom: FormationDb['nom'];
    image_couverture: FormationDb['image_couverture'];
    slug: FormationDb['slug'];
  } | null;
  groupe: Groupe | null;
  numero: FactureDb['numero'];
  titre: FactureDb['titre'];
  fichier: FactureDb['fichier'];
  description: FactureDb['description'];
  telechargements: FactureDb['telechargements'];
  date_limite_paiement: FactureDb['date_limite_paiement'];
  date_paiement: FactureDb['date_paiement'];
  date_relance1: FactureDb['date_relance1'];
  date_relance2: FactureDb['date_relance2'];
  date_relance3: FactureDb['date_relance3'];
  created_at: FactureDb['created_at'];
  updated_at: string | null;
};

export type Groupe = {
  id: GroupeDb['id'];
  nom: GroupeDb['nom_commercial'];
  date_debut: GroupeDb['date_debut'];
  date_fin: GroupeDb['date_fin'];
  capacite_min: GroupeDb['capacite_min'];
  capacite_max: GroupeDb['capacite_max'];
  prix_vente: GroupeDb['prix_vente'];
  unites_facturation: GroupeDb['unites_facturation'];
  nom_unites_facturation: GroupeDb['nom_unites_facturation'];
  est_formation_longue: GroupeDb['est_formation_longue'];
  est_modulaire: GroupeDb['est_modulaire'];
  est_plan_complexe: GroupeDb['est_plan_complexe'];
  est_bp: GroupeDb['est_bp'];
  site: Site | null;
};

export type Notification = {
  id: NotificationDb['id'];
  user_id: NotificationDb['user_id'];
  profil_id: NotificationDb['profil_id'];
  categorie: NotificationDb['categorie'];
  titre: NotificationDb['titre'];
  description: NotificationDb['description'];
  url: NotificationDb['url'];
  est_vu: NotificationDb['est_vu'];
  est_masque: NotificationDb['est_masque'];
  created_at: NotificationDb['created_at'];
};

export type Region = {
  id: SiteDb['id'];
  nom: string;
  image_couverture: string | null;
  slug: string;
  sites?: RegionSite[];
};

export type RegionSite = {
  id: SiteDb['id'];
  nom: string;
  image_couverture: string | null;
  slug: string;
  adr1: string | null;
  adr2: string | null;
  adr3: string | null;
  adr4: string | null;
  code_postal: string | null;
  ville: string | null;
  pays: string | null;
  geo_lat: SiteDb['geo_lat'];
  geo_lng: SiteDb['geo_lng'];
  formations?: FormationDb[];
};

export type Site = {
  nom: SiteDb['nom_commercial'];
  image_couverture: SiteDb['image_couverture'];
  slug: SiteDb['slug'];
  adr1: SiteDb['adr1'];
  adr2: SiteDb['adr2'];
  adr3: SiteDb['adr3'];
  adr4: SiteDb['adr4'];
  code_postal: SiteDb['code_postal'];
  ville: SiteDb['ville'];
  pays: SiteDb['pays'];
  geo_lat: SiteDb['geo_lat'];
  geo_lng: SiteDb['geo_lng'];
};

export type DashboardStatsPopulaires = {
  id: number;
  nom: string;
  image: string | null;
  compte: number;
};

export type DashboardAvisAValider = {
  id: number;
  user: UserInfo;
  entreprise: {
    id: number;
    raison_sociale: string;
    enseigne: string;
    image_logo: string | null;
    siret: string;
    site_web: string | null;
    est_approuvee: boolean;
  };
  formation: {
    nom: string;
    image_couverture: string | null;
    slug: string;
  };
  note: number;
  description: string;
  affiche_avatar: boolean;
  affiche_nom_complet: boolean;
  affiche_nom_entreprise: boolean;
  ip: string | null;
  created_at: string | null;
};

export type DashboardSynthese = {
  caestime: number;
  nbFormations: number;
  nbInscriptionApprenants: number;
  nbInscriptionEntreprises: number;
  nbAvis: number;
  nbEntrepriseAValider: number;
};

export type Delais = {
  delai_admin_reponse_demande: string;
  delai_admin_traitement_devis: string;
  delai_entreprise_acceptation_devis: string;
  delai_entreprise_retractation_devis: string;
};

export type Log = {
  id: LogDb['id'];
  user_id: UserDb['id'];
  profil_id: ProfilDb['id'] | null;
  controller: LogDb['controller'];
  action: LogDb['action'];
  resource_id: LogDb['resource_id'];
  ip: LogDb['ip'];
  created_at: LogDb['created_at'];
  user: {
    id: UserDb['id'];
    nom: UserDb['nom'];
    prenom: UserDb['prenom'];
    email: UserDb['email'];
    image_avatar: UserDb['image_avatar'];
    est_actif: boolean;
  };
  entreprise: {
    enseigne: string | null;
    raison_sociale: string | null;
  } | null;
  type_profil: string | null;
  statut_affiliation: string | null;
};

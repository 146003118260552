import { Link } from 'react-router-dom';
import { AcademicCapIcon, ArrowRightIcon, ClockIcon } from '@heroicons/react/24/outline';
import { FunctionComponent } from 'react';
import { FormationSimilaire } from '../types/visitorRessources';

/**
 * Ce composant affiche la liste des formations similaires.
 *
 * @param formations - Tableau de formations.
 */
export const CardFormationSimilaire: FunctionComponent<{
  formations: FormationSimilaire[];
}> = ({ formations }) => {
  return (
    <div className='flex flex-col lg:flex-row gap-10'>
      {formations.map((formation, index) => (
        <div
          key={index}
          className='bg-[#F7FBFE] w-full flex flex-col focus:outline-none pb-4 transition transform shadow-sm hover:shadow-lg rounded-lg overflow-hidden basis-1/1 lg:basis-1/3'>
          <div className='relative bg-secondary-1'>
            <Link to={`/formations/${formation.slug}`} className='flex-none'>
              <div>
                {formation.image_couverture ? (
                  <img
                    alt={formation.nom}
                    src={formation.image_couverture}
                    tabIndex={0}
                    className='focus:outline-none w-full h-44 object-cover opacity-70 hover:opacity-90'
                    loading='lazy'
                  />
                ) : (
                  <div className={`w-full h-44 object-cover bg-gray-700 flex items-center justify-center`}>
                    <AcademicCapIcon className='h-11 w-11 text-white/50' />
                  </div>
                )}
              </div>
            </Link>
          </div>
          <div className='grow flex flex-col justify-between'>
            <div className='px-4 pt-4 pb-1 grow'>
              <Link
                to={`/formations/${formation.slug}`}
                className='text-primary hover:text-secondary-1 focus:outline-none text-md poppins-semibold'
                title={formation.nom}>
                {formation.nom}
              </Link>
            </div>
            <div>
              <div className='px-4 flex flex-wrap items-center'>
                <ClockIcon className='w-7 h-7 pr-2 text-primary flex-none' />
                <div className='grow'>
                  {formation.duree_heures && <span className='text-gray-700 text-xs lg:text-md mr-2'>Durée : {formation.duree_heures} heures</span>}
                </div>
              </div>
              <Link to={`/formations/${formation.slug}`}>
                <div className='text-secondary-1 hover:text-primary px-4 flex items-center montserrat-semibold text-xs'>
                  <ArrowRightIcon className='w-7 h-7 pr-2 flex-none' strokeWidth={2} />
                  <div className='grow'>Voir la fiche détaillée</div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

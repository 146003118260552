import { Button } from '@material-tailwind/react';
import { FunctionComponent, ReactNode } from 'react';
import { Link, useLocation } from 'react-router-dom';

/**
 * Ce composant affiche les liens du menu de gauche du Back-Office.
 * @param label - Libellé du lien.
 * @param link - Destination du lien.
 * @param children - Dans ce cas précis on ajoute souvent des icônes.
 * @param handleMenuItemOnClick - Callback pour gérer le clic sur le lien.
 */
export const DashboardSidebarLink: FunctionComponent<{
  label: string;
  link: string;
  children: ReactNode;
  handleMenuItemOnClick: () => void;
}> = ({ label, link, children, handleMenuItemOnClick }) => {
  /**
   * @constant Sert à tester la page où l'on est pour mettre le lien qui correspond en surbrillance.
   */
  const location = useLocation();

  return (
    <Link to={link} onClick={() => handleMenuItemOnClick()}>
      <Button
        variant='text'
        className={`w-full p-3 mb-1 normal-case font-normal text-sm cursor-pointer rounded-lg text-white leading-3 tracking-normal hover:bg-primary/50 focus:outline-none ${
          location.pathname.startsWith(link) ? 'text-white bg-primary hover:bg-primary' : ''
        }`}>
        <div className='flex items-center'>
          {children}
          <span className='ml-2'>{label}</span>
        </div>
      </Button>
    </Link>
  );
};

import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { Config } from '../config/config';
import { Profil } from '../types/auth';

/**
 * Crée un classe API qui va se charger de faire tout les appel API. Ce peut être pour gérer les CRUD ou autre.
 * Elle utilise Axios dont elle est un wrapper.
 * Elle ne doit pas être appelé directement mais avec la méthode statique `init`, qui est elle-même appellée dans un hook personalisé.
 */
export class Api {
  /**
   * @property Stocke une Instance de Axios.
   * @private
   */
  private client;

  /**
   * @constructor Constructeur de la classe. Il charge dans la propriété `client` l'instance de de Axios.
   */
  constructor() {
    this.client = axios.create({
      baseURL: Config.api_url,
      data: {},
      headers: {
        Accept: 'application/json',
      },
      responseType: 'json',
      responseEncoding: 'utf8',
      timeout: 10000,
    });

    // Debug
    //this.enableInterceptors();
  }

  /**
   * @method Permet d'initialiser la classe API.
   * @public
   * @static
   * @returns Une instance de la classe API
   */
  public static init = () => new Api();

  /**
   * @method Permet de régler le `token` `Bearer` de l'instance d'Axios.
   * @public
   */
  public setToken = (token: string) => {
    this.client.defaults.headers.Authorization = 'Bearer ' + token;
  };

  /**
   * @method Permet de réinitialiser le `token` de l'instance d'Axios.
   * @public
   */
  public resetToken = () => {
    delete this.client.defaults.headers.Authorization;
  };

  /**
   * @method Permet de régler une en-tête de requète personalisée afin de maintenir le choix d'un profil.
   *
   * @param profilId - ID de profil choisi.
   * @public
   */
  public setProfil = (profilId: Profil['id']) => {
    this.client.defaults.headers.common['X-Profil'] = profilId;
  };

  /**
   * @method Permet de réinitialisé le profil d'un utilisateur.
   * @public
   */
  public resetProfil = () => {
    delete this.client.defaults.headers.common['X-Profil'];
  };

  /**
   * Cette methode enveloppe la méthode `get` de Axios.
   * Elle va chercher une ressource ou une collection de ressource.
   *
   * @param url - URL relative de la ressource que l'on cherche à obtenir.
   * @param config - Permet de configurer la requète avec des paramètres spécifiques.
   * @public
   * @returns Renvoit le renvoi de la méthode `get` de Axios.
   */
  public get = <T = unknown, D = unknown>(url: string, config?: AxiosRequestConfig<D>): Promise<AxiosResponse<T>> => {
    return this.client.get(url, config);
  };

  /**
   * Cette methode enveloppe la méthode `post` de Axios.
   * Elle va poster une ressource ou une collection de ressource.
   *
   * @param url - URL relative de là où on va poster la ressource ou la collection.
   * @param data - Ressource ou collection à poster.
   * @param config - Permet de configurer la requète avec des paramètres spécifiques.
   * @returns Renvoit le renvoi de la méthode `post` de Axios.
   */
  public post = <T = unknown, D = unknown>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<AxiosResponse<T>> => {
    return this.client.post(url, data, config);
  };

  /**
   * Cette methode enveloppe la méthode `put` de Axios.
   * Elle va modifier une ressource.
   *
   * @param url - URL relative de là où on va la ressource ou la collection.
   * @param data - Ressource à modififier.
   * @param config - Permet de configurer la requète avec des paramètres spécifiques.
   * @returns Renvoit le renvoi de la méthode `put` de Axios.
   */
  public put = <T = unknown, D = unknown>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<AxiosResponse<T>> => {
    return this.client.post(url, { ...data, _method: 'PUT' } as D & { _method: 'PUT' }, config);
  };

  /**
   * Cette methode enveloppe la méthode `patch` de Axios.
   * Elle va modifier une ressource, au moins partiellement.
   *
   * @param url - URL relative de là où on va la ressource ou la collection.
   * @param data - Ressource, ou partie de celle-ci, à modififier.
   * @param config - Permet de configurer la requète avec des paramètres spécifiques.
   * @returns Renvoit le renvoi de la méthode `patch` de Axios.
   */
  public patch = <T = unknown, D = unknown>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<AxiosResponse<T>> => {
    return this.client.post(url, { ...data, _method: 'PATCH' } as D & { _method: 'PATCH' }, config);
  };

  /**
   * Cette methode enveloppe la méthode `delete` de Axios.
   * Elle va supprimer une ressource.
   *
   * @param url - URL relative de la ressource que l'on cherche à supprimer.
   * @param config - Permet de configurer la requète avec des paramètres spécifiques.
   * @returns Renvoit le renvoi de la méthode `delete` de Axios.
   */
  public delete = <T = unknown, D = unknown>(url: string, config?: AxiosRequestConfig<D>): Promise<AxiosResponse<T>> => {
    return this.client.delete(url, config);
  };

  /**
   * Cette méthode permet d'afficher les PDF bavec la bonne instance de Axios.
   *
   * @param url - URL du PDF
   * @async
   */
  public fetchPdf = async (url: string) => {
    const response = await this.client.get(url, { responseType: 'blob' });
    window.open(URL.createObjectURL(response.data));
  };
}

import { Fragment, FunctionComponent, lazy, Suspense, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { ScrollToTop } from './components/ScrollToTop';
import { ApiProvider } from './providers/ApiProvider';

import { LoggedLayout } from './layouts/LoggedLayout';
import { AuthLayout } from './layouts/AuthLayout';
import { VisitorLayout } from './layouts/VisitorLayout';

import { ConditionsGeneralesScreen } from './screens/ConditionsGeneralesScreen';
import { HomeScreen } from './screens/HomeScreen';
import { LoginScreen } from './screens/Auth/LoginScreen';
import { LogoutScreen } from './screens/Logged/LogoutScreen';
import { RegisterScreen } from './screens/Auth/RegisterScreen';
import { MentionsLegalesScreen } from './screens/MentionsLegalesScreen';
import { MonEspaceScreen } from './screens/Logged/MonEspaceScreen';
import { MotdepasseOublieScreen } from './screens/Auth/MotdepasseOublieScreen';
import { MotdepasseReinitialiseScreen } from './screens/Auth/MotdepasseReinitialiseScreen';
import { NotFoundScreen } from './screens/NotFoundScreen';
import { VerifieEmailScreen } from './screens/Auth/VerifieEmailScreen';
import { MonMotdepasseScreen } from './screens/Logged/MonMotdepasseScreen';

const MonAgendaScreen = lazy(() => import('./screens/Logged/MonAgendaScreen'));
import { MesFavorisScreen } from './screens/Logged/MesFavorisScreen';
import { FicheMetierScreen } from './screens/FicheMetierScreen';
import { RoleStandardLayout } from './layouts/RoleStandardLayout';
import { RoleAdminLayout } from './layouts/RoleAdminLayout';
import { RoleAdmingcLayout } from './layouts/RoleAdmingcLayout';
import { ModeFormulaire } from './types';
import { ResultatsRechercheScreen } from './screens/ResultatsRechercheScreen';
import { AdminCiviliteFormScreen } from './screens/Logged/Admin/AdminCiviliteFormScreen';
import { AdminCiviliteListScreen } from './screens/Logged/Admin/AdminCiviliteListScreen';
import { AdminCommuneFormScreen } from './screens/Logged/Admin/AdminCommuneFormScreen';
import { AdminCommuneListScreen } from './screens/Logged/Admin/AdminCommuneListScreen';
import { AdminConventionCollectiveFormScreen } from './screens/Logged/Admin/AdminConventionCollectiveFormScreen';
import { AdminConventionCollectiveListScreen } from './screens/Logged/Admin/AdminConventionCollectiveListScreen';
import { AdminDiplomeObtenuFormScreen } from './screens/Logged/Admin/AdminDiplomeObtenuFormScreen';
import { AdminDiplomeObtenuListScreen } from './screens/Logged/Admin/AdminDiplomeObtenuListScreen';
import { AdminDiplomePrepareFormScreen } from './screens/Logged/Admin/AdminDiplomePrepareFormScreen';
import { AdminDiplomePrepareListScreen } from './screens/Logged/Admin/AdminDiplomePrepareListScreen';
import { AdminEntrepriseFormScreen } from './screens/Logged/Admin/AdminEntrepriseFormScreen';
import { AdminEntrepriseListScreen } from './screens/Logged/Admin/AdminEntrepriseListScreen';
import { AdminFonctionFormScreen } from './screens/Logged/Admin/AdminFonctionFormScreen';
import { AdminFonctionListScreen } from './screens/Logged/Admin/AdminFonctionListScreen';
import { AdminFormeJuridiqueFormScreen } from './screens/Logged/Admin/AdminFormeJuridiqueFormScreen';
import { AdminFormeJuridiqueListScreen } from './screens/Logged/Admin/AdminFormeJuridiqueListScreen';
import { AdminMatiereFormScreen } from './screens/Logged/Admin/AdminMatiereFormScreen';
import { AdminMatiereListScreen } from './screens/Logged/Admin/AdminMatiereListScreen';
import { AdminNationaliteFormScreen } from './screens/Logged/Admin/AdminNationaliteFormScreen';
import { AdminNationaliteListScreen } from './screens/Logged/Admin/AdminNationaliteListScreen';
import { AdminOctaFormScreen } from './screens/Logged/Admin/AdminOctaFormScreen';
import { AdminOctaListScreen } from './screens/Logged/Admin/AdminOctaListScreen';
import { AdminOpcoFormScreen } from './screens/Logged/Admin/AdminOpcoFormScreen';
import { AdminOpcoListScreen } from './screens/Logged/Admin/AdminOpcoListScreen';
import { AdminPaysFormScreen } from './screens/Logged/Admin/AdminPaysFormScreen';
import { AdminPaysListScreen } from './screens/Logged/Admin/AdminPaysListScreen';
import { AdminPeriodeFormScreen } from './screens/Logged/Admin/AdminPeriodeFormScreen';
import { AdminPeriodeListScreen } from './screens/Logged/Admin/AdminPeriodeListScreen';
import { AdminPersonnelFormScreen } from './screens/Logged/Admin/AdminPersonnelFormScreen';
import { AdminPersonnelListScreen } from './screens/Logged/Admin/AdminPersonnelListScreen';
import { AdminRegionFormScreen } from './screens/Logged/Admin/AdminRegionFormScreen';
import { AdminRegionListScreen } from './screens/Logged/Admin/AdminRegionListScreen';
import { AdminRoleFormScreen } from './screens/Logged/Admin/AdminRoleFormScreen';
import { AdminRoleListScreen } from './screens/Logged/Admin/AdminRoleListScreen';
import { AdminSecteurActiviteFormScreen } from './screens/Logged/Admin/AdminSecteurActiviteFormScreen';
import { AdminSecteurActiviteListScreen } from './screens/Logged/Admin/AdminSecteurActiviteListScreen';
import { AdminSiteFormScreen } from './screens/Logged/Admin/AdminSiteFormScreen';
import { AdminSiteListScreen } from './screens/Logged/Admin/AdminSiteListScreen';
import { AdminStatutAffiliationFormScreen } from './screens/Logged/Admin/AdminStatutAffiliationFormScreen';
import { AdminStatutAffiliationListScreen } from './screens/Logged/Admin/AdminStatutAffiliationListScreen';
import { AdminTypeEmployeurFormScreen } from './screens/Logged/Admin/AdminTypeEmployeurFormScreen';
import { AdminTypeEmployeurListScreen } from './screens/Logged/Admin/AdminTypeEmployeurListScreen';
import { AdminTypeProfilFormScreen } from './screens/Logged/Admin/AdminTypeProfilFormScreen';
import { AdminTypeProfilListScreen } from './screens/Logged/Admin/AdminTypeProfilListScreen';
import { AdminUserFormScreen } from './screens/Logged/Admin/AdminUserFormScreen';
import { AdminUserListScreen } from './screens/Logged/Admin/AdminUserListScreen';
import { AdminAnneeFormScreen } from './screens/Logged/Admin/AdminAnneeFormScreen';
import { AdminAnneeListScreen } from './screens/Logged/Admin/AdminAnneeListScreen';
import { UserProvider } from './providers/UserProvider';
import { AdminEntreprisesEnAttenteScreen } from './screens/Logged/Admin/AdminEntreprisesEnAttenteScreen';
import { AdminFormationFormScreen } from './screens/Logged/Admin/AdminFormationFormScreen';
import { AdminFormationListScreen } from './screens/Logged/Admin/AdminFormationListScreen';
import { AdminGroupeFormScreen } from './screens/Logged/Admin/AdminGroupeFormScreen';
import { AdminGroupeListScreen } from './screens/Logged/Admin/AdminGroupeListScreen';
import { AdminApprenantFormScreen } from './screens/Logged/Admin/AdminApprenantFormScreen';
import { AdminApprenantListScreen } from './screens/Logged/Admin/AdminApprenantListScreen';
import { FicheFormationScreen } from './screens/FicheFormationScreen';
import { AdminFiliereFormScreen } from './screens/Logged/Admin/AdminFiliereFormScreen';
import { AdminFiliereListScreen } from './screens/Logged/Admin/AdminFiliereListScreen';
import { AdminMetierFormScreen } from './screens/Logged/Admin/AdminMetierFormScreen';
import { AdminMetierListScreen } from './screens/Logged/Admin/AdminMetierListScreen';
import { EntrepriseInvitationsScreen } from './screens/Logged/Entreprise/EntrepriseInvitationScreen';
import { InvitationsScreen } from './screens/Logged/InvitationScreen';
import { FilieresScreen } from './screens/FilieresScreen';
import { FicheFiliereScreen } from './screens/FicheFiliereScreen';
import { ProfilsScreen } from './screens/Logged/ProfilsScreen';
import { EntrepriseComptesScreen } from './screens/Logged/Entreprise/EntrepriseComptesScreen';
import { MesInformationsScreen } from './screens/Logged/MesInformationsScreen';
import { FicheRegionScreen } from './screens/FicheRegionScreen';
import { MonEntrepriseScreen } from './screens/Logged/Entreprise/MonEntrepriseScreen';
const AdminStatistiquesInscriptionsScreen = lazy(() => import('./screens/Logged/Admin/AdminStatistiquesInscriptionsScreen'));
const AdminStatistiquesRecherchesScreen = lazy(() => import('./screens/Logged/Admin/AdminStatistiquesRecherchesScreen'));
const AdminStatistiquesRecherchesMotScreen = lazy(() => import('./screens/Logged/Admin/AdminStatistiquesRecherchesMotScreen'));
const AdminStatistiquesVisitesFilieresScreen = lazy(() => import('./screens/Logged/Admin/AdminStatistiquesVisitesFilieresScreen'));
const AdminStatistiquesVisitesFiliereScreen = lazy(() => import('./screens/Logged/Admin/AdminStatistiquesVisitesFiliereScreen'));
const AdminStatistiquesVisitesFormationScreen = lazy(() => import('./screens/Logged/Admin/AdminStatistiquesVisitesFormationScreen'));
const AdminStatistiquesVisitesFormationsScreen = lazy(() => import('./screens/Logged/Admin/AdminStatistiquesVisitesFormationsScreen'));
const AdminStatistiquesVisitesMetierScreen = lazy(() => import('./screens/Logged/Admin/AdminStatistiquesVisitesMetierScreen'));
const AdminStatistiquesVisitesMetiersScreen = lazy(() => import('./screens/Logged/Admin/AdminStatistiquesVisitesMetiersScreen'));
const AdminStatistiquesVisitesSiteScreen = lazy(() => import('./screens/Logged/Admin/AdminStatistiquesVisitesSiteScreen'));
const AdminStatistiquesVisitesSitesScreen = lazy(() => import('./screens/Logged/Admin/AdminStatistiquesVisitesSitesScreen'));
import { AdminYpareoLogsSyncroScreen } from './screens/Logged/Admin/AdminYpareoLogsSyncroScreen';
import { MaListeDeSouhaitsScreen } from './screens/Logged/MaListeDeSouhaitsScreen';
import { AdminDemandesScreen } from './screens/Logged/Admin/AdminDemandesScreen';
import { AdminDemandeScreen } from './screens/Logged/Admin/AdminDemandeScreen';
import { EntrepriseDemandeScreen } from './screens/Logged/Entreprise/EntrepriseDemandeScreen';
import { EntrepriseDemandesScreen } from './screens/Logged/Entreprise/EntrepriseDemandesScreen';
import { MesAvisScreen } from './screens/Logged/MesAvisScreen';
import { AdminAvisScreen } from './screens/Logged/Admin/AdminAvisScreen';
import { AdminExportScreen } from './screens/Logged/Admin/AdminExportScreen';
const AdminStatistiquesFavorisFormationScreen = lazy(() => import('./screens/Logged/Admin/AdminStatistiquesFavorisFormationScreen'));
const AdminStatistiquesFavorisFormationsScreen = lazy(() => import('./screens/Logged/Admin/AdminStatistiquesFavorisFormationsScreen'));
const AdminStatistiquesSouhaitsFormationScreen = lazy(() => import('./screens/Logged/Admin/AdminStatistiquesSouhaitsFormationScreen'));
const AdminStatistiquesSouhaitsFormationsScreen = lazy(() => import('./screens/Logged/Admin/AdminStatistiquesSouhaitsFormationsScreen'));
const AdminStatistiquesDemandesFormationScreen = lazy(() => import('./screens/Logged/Admin/AdminStatistiquesDemandesFormationScreen'));
const AdminStatistiquesDemandesFormationsScreen = lazy(() => import('./screens/Logged/Admin/AdminStatistiquesDemandesFormationsScreen'));
const AdminStatistiquesDemandesPaiementScreen = lazy(() => import('./screens/Logged/Admin/AdminStatistiquesDemandesPaiementScreen'));
const AdminStatistiquesDemandesPaiementsScreen = lazy(() => import('./screens/Logged/Admin/AdminStatistiquesDemandesPaiementsScreen'));
const EntrepriseAgendaScreen = lazy(() => import('./screens/Logged/Entreprise/EntrepriseAgendaScreen'));
import { MesFormationsScreen } from './screens/Logged/MesFormationsScreen';
import { EntrepriseFormationsScreen } from './screens/Logged/Entreprise/EntrepriseFormationsScreen';
import { MesDocumentsScreen } from './screens/Logged/MesDocumentsScreen';
import { EntrepriseDocumentsScreen } from './screens/Logged/Entreprise/EntrepriseDocumentsScreen';
import { AdminDocumentsScreen } from './screens/Logged/Admin/AdminDocumentsScreen';
import { InactiviteScreen } from './screens/Auth/InactiviteScreen';
import { NotificationsScreen } from './screens/Logged/NotificationsScreen';
import { Config } from './config/config';

import { AdmingcDemandeScreen } from './screens/Logged/GestionnaireCentre/AdmingcDemandeScreen';
import { AdmingcDemandesScreen } from './screens/Logged/GestionnaireCentre/AdmingcDemandesScreen';
import { AdmingcFormationFormScreen } from './screens/Logged/GestionnaireCentre/AdmingcFormationFormScreen';
import { AdmingcFormationListScreen } from './screens/Logged/GestionnaireCentre/AdmingcFormationListScreen';
const AdmingcStatistiquesDemandesFormationScreen = lazy(() => import('./screens/Logged/GestionnaireCentre/AdmingcStatistiquesDemandesFormationScreen'));
const AdmingcStatistiquesDemandesFormationsScreen = lazy(() => import('./screens/Logged/GestionnaireCentre/AdmingcStatistiquesDemandesFormationsScreen'));
const AdmingcStatistiquesDemandesPaiementScreen = lazy(() => import('./screens/Logged/GestionnaireCentre/AdmingcStatistiquesDemandesPaiementScreen'));
const AdmingcStatistiquesDemandesPaiementsScreen = lazy(() => import('./screens/Logged/GestionnaireCentre/AdmingcStatistiquesDemandesPaiementsScreen'));
const AdmingcStatistiquesRecherchesMotScreen = lazy(() => import('./screens/Logged/GestionnaireCentre/AdmingcStatistiquesRecherchesMotScreen'));
const AdmingcStatistiquesRecherchesScreen = lazy(() => import('./screens/Logged/GestionnaireCentre/AdmingcStatistiquesRecherchesScreen'));
const AdmingcStatistiquesSouhaitsFormationScreen = lazy(() => import('./screens/Logged/GestionnaireCentre/AdmingcStatistiquesSouhaitsFormationScreen'));
const AdmingcStatistiquesSouhaitsFormationsScreen = lazy(() => import('./screens/Logged/GestionnaireCentre/AdmingcStatistiquesSouhaitsFormationsScreen'));
const AdmingcStatistiquesVisitesFiliereScreen = lazy(() => import('./screens/Logged/GestionnaireCentre/AdmingcStatistiquesVisitesFiliereScreen'));
const AdmingcStatistiquesVisitesFilieresScreen = lazy(() => import('./screens/Logged/GestionnaireCentre/AdmingcStatistiquesVisitesFilieresScreen'));
const AdmingcStatistiquesVisitesFormationScreen = lazy(() => import('./screens/Logged/GestionnaireCentre/AdmingcStatistiquesVisitesFormationScreen'));
const AdmingcStatistiquesVisitesFormationsScreen = lazy(() => import('./screens/Logged/GestionnaireCentre/AdmingcStatistiquesVisitesFormationsScreen'));
const AdmingcStatistiquesVisitesMetierScreen = lazy(() => import('./screens/Logged/GestionnaireCentre/AdmingcStatistiquesVisitesMetierScreen'));
const AdmingcStatistiquesVisitesMetiersScreen = lazy(() => import('./screens/Logged/GestionnaireCentre/AdmingcStatistiquesVisitesMetiersScreen'));
const AdmingcStatistiquesVisitesSiteScreen = lazy(() => import('./screens/Logged/GestionnaireCentre/AdmingcStatistiquesVisitesSiteScreen'));
const AdmingcStatistiquesVisitesSitesScreen = lazy(() => import('./screens/Logged/GestionnaireCentre/AdmingcStatistiquesVisitesSitesScreen'));
import { EntrepriseListeDeSouhaitsScreen } from './screens/Logged/Entreprise/EntrepriseListeDeSouhaitsScreen';
import { FicheSiteScreen } from './screens/FicheSiteScreen';
import { SitesScreen } from './screens/SitesScreen';
import { AdminLogsScreen } from './screens/Logged/Admin/AdminLogsScreen';
import { AdminParamDelaisScreen } from './screens/Logged/Admin/AdminParamDelaisScreen';
import { EntrepriseConvocationsScreen } from './screens/Logged/Entreprise/EntrepriseConvocationsScreen';
import { EntrepriseDevisScreen } from './screens/Logged/Entreprise/EntrepriseDevisScreen';
import { EntrepriseFacturesScreen } from './screens/Logged/Entreprise/EntrepriseFacturesScreen';
import { MesConvocationScreen } from './screens/Logged/MesConvocationScreen';
import { AdminDevisScreen } from './screens/Logged/Admin/AdminDevisScreen';
import { AdminConvocationsScreen } from './screens/Logged/Admin/AdminConvocationsScreen';
import { AdminFacturesScreen } from './screens/Logged/Admin/AdminFacturesScreen';
import { EntrepriseDeviScreen } from './screens/Logged/Entreprise/EntrepriseDeviScreen';
import { AdminDeviScreen } from './screens/Logged/Admin/AdminDeviScreen';
import { AdminFactureScreen } from './screens/Logged/Admin/AdminFactureScreen';
import { AdminConvocationScreen } from './screens/Logged/Admin/AdminConvocationScreen';
import { EntrepriseFactureScreen } from './screens/Logged/Entreprise/EntrepriseFactureScreen';
import { EntrepriseConvocationScreen } from './screens/Logged/Entreprise/EntrepriseConvocationScreen';
import { ConvocationScreen } from './screens/Logged/ConvocationScreen';
import { MerciInscriptionScreen } from './screens/Auth/MerciInscriptionScreen';
import { RenvoiVerificationEmail } from './screens/Auth/RenvoiVerificationEmail';
import { DocumentScreen } from './screens/Logged/DocumentScreen';
import { EntrepriseDocumentScreen } from './screens/Logged/Entreprise/EntrepriseDocumentScreen';
import { AdminDocumentScreen } from './screens/Logged/Admin/AdminDocumentScreen';

export const App: FunctionComponent = () => {
  useEffect(() => {
    // AXEPTIO

    if (window.location.hostname !== 'localhost' && window.location.host !== '127.0.0.1') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.axeptioSettings = {
        clientId: Config.axeptio_clientId,
      };

      (function (d, s) {
        const t = d.getElementsByTagName(s)[0];
        const e = d.createElement(s);

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        e.async = true;

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        e.src = '//static.axept.io/sdk-slim.js';

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        t.parentNode.insertBefore(e, t);
      })(document, 'script');
    }
  }, []);

  return (
    <Fragment>
      <Toaster
        containerStyle={{
          zIndex: 99999999, // For the container
        }}
        position='top-center'
        reverseOrder={true}
        toastOptions={{
          style: { padding: '20px' },
          duration: 6000,
          success: {
            style: {
              border: '1px solid rgba(22,174,38,0.5)',
              borderLeft: '4px solid #16AE26',
              background: '#EAF7EE',
            },
            iconTheme: {
              primary: '#16AE26',
              secondary: '#fff',
            },
          },
          error: {
            style: {
              border: '1px solid rgba(208,14,14, 0.5)',
              borderLeft: '4px solid #D00E0E',
              background: '#FCEDE9',
            },
            iconTheme: {
              primary: '#D00E0E',
              secondary: '#fff',
            },
          },
        }}
      />
      <ApiProvider>
        <UserProvider>
          <BrowserRouter>
            <Suspense fallback={<div>Chargement...</div>}>
              <ScrollToTop />
              <Routes>
                <Route element={<VisitorLayout />}>
                  <Route path='/' element={<HomeScreen />} />
                  <Route path='/filieres' element={<FilieresScreen />} />
                  <Route path='/filieres/:slug' element={<FicheFiliereScreen />} />
                  <Route path='/metiers/:slug' element={<FicheMetierScreen />} />
                  <Route path='/regions/:slug' element={<FicheRegionScreen />} />
                  <Route path='/sites/:slug' element={<FicheSiteScreen />} />
                  <Route path='/nos-centres-de-formation' element={<SitesScreen />} />
                  <Route path='/formations/:slug' element={<FicheFormationScreen />} />
                  <Route path='/mentions-legales' element={<MentionsLegalesScreen />} />
                  <Route path='/conditions-generales' element={<ConditionsGeneralesScreen />} />
                  <Route path='/resultats-recherche' element={<ResultatsRechercheScreen />} />
                </Route>
                <Route element={<AuthLayout />}>
                  <Route path='/connexion' element={<LoginScreen />} />
                  <Route path='/inscription' element={<RegisterScreen />} />
                  <Route path='/motdepasse-oublie' element={<MotdepasseOublieScreen />} />
                  <Route path='/reinitialise-motdepasse/:email/:token' element={<MotdepasseReinitialiseScreen />} />
                  <Route path='/verifie-email/:email/:token' element={<VerifieEmailScreen />} />
                  <Route path='/merci-inscription' element={<MerciInscriptionScreen />} />
                  <Route path='/inactivite' element={<InactiviteScreen />} />
                  <Route path='/renvoi-verification-email' element={<RenvoiVerificationEmail />} />
                </Route>
                <Route element={<LoggedLayout />}>
                  <Route path='/deconnexion' element={<LogoutScreen />} />
                  <Route path='/mon-espace' element={<MonEspaceScreen />} />
                  <Route path='/mes-informations' element={<MesInformationsScreen />} />
                  <Route path='/mon-motdepasse' element={<MonMotdepasseScreen />} />
                  <Route path='/notifications' element={<NotificationsScreen />} />
                  <Route element={<RoleStandardLayout />}>
                    <Route path='/mes-favoris' element={<MesFavorisScreen />} />
                    <Route path='/mon-agenda' element={<MonAgendaScreen />} />
                    <Route path='/invitations' element={<InvitationsScreen />} />
                    <Route path='/entreprise/invitations' element={<EntrepriseInvitationsScreen />} />
                    <Route path='/entreprise/comptes' element={<EntrepriseComptesScreen />} />
                    <Route path='/entreprise/monEntreprise' element={<MonEntrepriseScreen />} />
                    <Route path='/entreprise/demandes' element={<EntrepriseDemandesScreen />} />
                    <Route path='/entreprise/agenda' element={<EntrepriseAgendaScreen />} />
                    <Route path='/entreprise/demandes/:token' element={<EntrepriseDemandeScreen />} />
                    <Route path='/entreprise/groupes' element={<EntrepriseFormationsScreen />} />
                    <Route path='/entreprise/documents' element={<EntrepriseDocumentsScreen />} />
                    <Route path='/entreprise/documents/:token' element={<EntrepriseDocumentScreen />} />
                    <Route path='/entreprise/devis' element={<EntrepriseDevisScreen />} />
                    <Route path='/entreprise/devis/:token' element={<EntrepriseDeviScreen />} />
                    <Route path='/entreprise/convocations' element={<EntrepriseConvocationsScreen />} />
                    <Route path='/entreprise/convocations/:token' element={<EntrepriseConvocationScreen />} />
                    <Route path='/entreprise/factures' element={<EntrepriseFacturesScreen />} />
                    <Route path='/entreprise/factures/:token' element={<EntrepriseFactureScreen />} />
                    <Route path='/profils' element={<ProfilsScreen />} />
                    <Route path='/souhaits-de-formation' element={<MaListeDeSouhaitsScreen />} />
                    <Route path='/entreprise/souhaits-de-formation' element={<EntrepriseListeDeSouhaitsScreen />} />
                    <Route path='/avis' element={<MesAvisScreen />} />
                    <Route path='/mes-formations' element={<MesFormationsScreen />} />
                    <Route path='/mes-documents' element={<MesDocumentsScreen />} />
                    <Route path='/mes-documents/:token' element={<DocumentScreen />} />
                    <Route path='/mes-convocations' element={<MesConvocationScreen />} />
                    <Route path='/mes-convocations/:token' element={<ConvocationScreen />} />
                  </Route>
                  <Route element={<RoleAdminLayout />}>
                    <Route path='/admin/annees' element={<AdminAnneeListScreen />} />
                    <Route path='/admin/annees/creer' element={<AdminAnneeFormScreen modeFormulaire={ModeFormulaire.creer} />} />
                    <Route path='/admin/annees/editer/:id' element={<AdminAnneeFormScreen modeFormulaire={ModeFormulaire.editer} />} />
                    <Route path='/admin/annees/voir/:id' element={<AdminAnneeFormScreen modeFormulaire={ModeFormulaire.voir} />} />

                    <Route path='/admin/apprenants' element={<AdminApprenantListScreen />} />
                    <Route path='/admin/apprenants/creer' element={<AdminApprenantFormScreen modeFormulaire={ModeFormulaire.creer} />} />
                    <Route path='/admin/apprenants/editer/:id' element={<AdminApprenantFormScreen modeFormulaire={ModeFormulaire.editer} />} />
                    <Route path='/admin/apprenants/voir/:id' element={<AdminApprenantFormScreen modeFormulaire={ModeFormulaire.voir} />} />

                    <Route path='/admin/civilites' element={<AdminCiviliteListScreen />} />
                    <Route path='/admin/civilites/creer' element={<AdminCiviliteFormScreen modeFormulaire={ModeFormulaire.creer} />} />
                    <Route path='/admin/civilites/editer/:id' element={<AdminCiviliteFormScreen modeFormulaire={ModeFormulaire.editer} />} />
                    <Route path='/admin/civilites/voir/:id' element={<AdminCiviliteFormScreen modeFormulaire={ModeFormulaire.voir} />} />

                    <Route path='/admin/communes' element={<AdminCommuneListScreen />} />
                    <Route path='/admin/communes/creer' element={<AdminCommuneFormScreen modeFormulaire={ModeFormulaire.creer} />} />
                    <Route path='/admin/communes/editer/:id' element={<AdminCommuneFormScreen modeFormulaire={ModeFormulaire.editer} />} />
                    <Route path='/admin/communes/voir/:id' element={<AdminCommuneFormScreen modeFormulaire={ModeFormulaire.voir} />} />

                    <Route path='/admin/conventions-collectives' element={<AdminConventionCollectiveListScreen />} />
                    <Route
                      path='/admin/conventions-collectives/creer'
                      element={<AdminConventionCollectiveFormScreen modeFormulaire={ModeFormulaire.creer} />}
                    />
                    <Route
                      path='/admin/conventions-collectives/editer/:id'
                      element={<AdminConventionCollectiveFormScreen modeFormulaire={ModeFormulaire.editer} />}
                    />
                    <Route
                      path='/admin/conventions-collectives/voir/:id'
                      element={<AdminConventionCollectiveFormScreen modeFormulaire={ModeFormulaire.voir} />}
                    />

                    <Route path='/admin/diplomes-obtenus' element={<AdminDiplomeObtenuListScreen />} />
                    <Route path='/admin/diplomes-obtenus/creer' element={<AdminDiplomeObtenuFormScreen modeFormulaire={ModeFormulaire.creer} />} />
                    <Route path='/admin/diplomes-obtenus/editer/:id' element={<AdminDiplomeObtenuFormScreen modeFormulaire={ModeFormulaire.editer} />} />
                    <Route path='/admin/diplomes-obtenus/voir/:id' element={<AdminDiplomeObtenuFormScreen modeFormulaire={ModeFormulaire.voir} />} />

                    <Route path='/admin/diplomes-prepares' element={<AdminDiplomePrepareListScreen />} />
                    <Route path='/admin/diplomes-prepares/creer' element={<AdminDiplomePrepareFormScreen modeFormulaire={ModeFormulaire.creer} />} />
                    <Route path='/admin/diplomes-prepares/editer/:id' element={<AdminDiplomePrepareFormScreen modeFormulaire={ModeFormulaire.editer} />} />
                    <Route path='/admin/diplomes-prepares/voir/:id' element={<AdminDiplomePrepareFormScreen modeFormulaire={ModeFormulaire.voir} />} />

                    <Route path='/admin/entreprises' element={<AdminEntrepriseListScreen />} />
                    <Route path='/admin/entreprises/creer' element={<AdminEntrepriseFormScreen modeFormulaire={ModeFormulaire.creer} />} />
                    <Route path='/admin/entreprises/editer/:id' element={<AdminEntrepriseFormScreen modeFormulaire={ModeFormulaire.editer} />} />
                    <Route path='/admin/entreprises/voir/:id' element={<AdminEntrepriseFormScreen modeFormulaire={ModeFormulaire.voir} />} />

                    <Route path='/admin/filieres' element={<AdminFiliereListScreen />} />
                    <Route path='/admin/filieres/creer' element={<AdminFiliereFormScreen modeFormulaire={ModeFormulaire.creer} />} />
                    <Route path='/admin/filieres/editer/:id' element={<AdminFiliereFormScreen modeFormulaire={ModeFormulaire.editer} />} />
                    <Route path='/admin/filieres/voir/:id' element={<AdminFiliereFormScreen modeFormulaire={ModeFormulaire.voir} />} />

                    <Route path='/admin/fonctions' element={<AdminFonctionListScreen />} />
                    <Route path='/admin/fonctions/creer' element={<AdminFonctionFormScreen modeFormulaire={ModeFormulaire.creer} />} />
                    <Route path='/admin/fonctions/editer/:id' element={<AdminFonctionFormScreen modeFormulaire={ModeFormulaire.editer} />} />
                    <Route path='/admin/fonctions/voir/:id' element={<AdminFonctionFormScreen modeFormulaire={ModeFormulaire.voir} />} />

                    <Route path='/admin/formations' element={<AdminFormationListScreen />} />
                    <Route path='/admin/formations/creer' element={<AdminFormationFormScreen modeFormulaire={ModeFormulaire.creer} />} />
                    <Route path='/admin/formations/editer/:id' element={<AdminFormationFormScreen modeFormulaire={ModeFormulaire.editer} />} />
                    <Route path='/admin/formations/voir/:id' element={<AdminFormationFormScreen modeFormulaire={ModeFormulaire.voir} />} />

                    <Route path='/admin/formes-juridiques' element={<AdminFormeJuridiqueListScreen />} />
                    <Route path='/admin/formes-juridiques/creer' element={<AdminFormeJuridiqueFormScreen modeFormulaire={ModeFormulaire.creer} />} />
                    <Route path='/admin/formes-juridiques/editer/:id' element={<AdminFormeJuridiqueFormScreen modeFormulaire={ModeFormulaire.editer} />} />
                    <Route path='/admin/formes-juridiques/voir/:id' element={<AdminFormeJuridiqueFormScreen modeFormulaire={ModeFormulaire.voir} />} />

                    <Route path='/admin/groupes' element={<AdminGroupeListScreen />} />
                    <Route path='/admin/groupes/creer' element={<AdminGroupeFormScreen modeFormulaire={ModeFormulaire.creer} />} />
                    <Route path='/admin/groupes/editer/:id' element={<AdminGroupeFormScreen modeFormulaire={ModeFormulaire.editer} />} />
                    <Route path='/admin/groupes/voir/:id' element={<AdminGroupeFormScreen modeFormulaire={ModeFormulaire.voir} />} />

                    <Route path='/admin/matieres' element={<AdminMatiereListScreen />} />
                    <Route path='/admin/matieres/creer' element={<AdminMatiereFormScreen modeFormulaire={ModeFormulaire.creer} />} />
                    <Route path='/admin/matieres/editer/:id' element={<AdminMatiereFormScreen modeFormulaire={ModeFormulaire.editer} />} />
                    <Route path='/admin/matieres/voir/:id' element={<AdminMatiereFormScreen modeFormulaire={ModeFormulaire.voir} />} />

                    <Route path='/admin/metiers' element={<AdminMetierListScreen />} />
                    <Route path='/admin/metiers/creer' element={<AdminMetierFormScreen modeFormulaire={ModeFormulaire.creer} />} />
                    <Route path='/admin/metiers/editer/:id' element={<AdminMetierFormScreen modeFormulaire={ModeFormulaire.editer} />} />
                    <Route path='/admin/metiers/voir/:id' element={<AdminMetierFormScreen modeFormulaire={ModeFormulaire.voir} />} />

                    <Route path='/admin/nationalites' element={<AdminNationaliteListScreen />} />
                    <Route path='/admin/nationalites/creer' element={<AdminNationaliteFormScreen modeFormulaire={ModeFormulaire.creer} />} />
                    <Route path='/admin/nationalites/editer/:id' element={<AdminNationaliteFormScreen modeFormulaire={ModeFormulaire.editer} />} />
                    <Route path='/admin/nationalites/voir/:id' element={<AdminNationaliteFormScreen modeFormulaire={ModeFormulaire.voir} />} />

                    <Route path='/admin/octas' element={<AdminOctaListScreen />} />
                    <Route path='/admin/octas/creer' element={<AdminOctaFormScreen modeFormulaire={ModeFormulaire.creer} />} />
                    <Route path='/admin/octas/editer/:id' element={<AdminOctaFormScreen modeFormulaire={ModeFormulaire.editer} />} />
                    <Route path='/admin/octas/voir/:id' element={<AdminOctaFormScreen modeFormulaire={ModeFormulaire.voir} />} />

                    <Route path='/admin/opcos' element={<AdminOpcoListScreen />} />
                    <Route path='/admin/opcos/creer' element={<AdminOpcoFormScreen modeFormulaire={ModeFormulaire.creer} />} />
                    <Route path='/admin/opcos/editer/:id' element={<AdminOpcoFormScreen modeFormulaire={ModeFormulaire.editer} />} />
                    <Route path='/admin/opcos/voir/:id' element={<AdminOpcoFormScreen modeFormulaire={ModeFormulaire.voir} />} />

                    <Route path='/admin/pays' element={<AdminPaysListScreen />} />
                    <Route path='/admin/pays/creer' element={<AdminPaysFormScreen modeFormulaire={ModeFormulaire.creer} />} />
                    <Route path='/admin/pays/editer/:id' element={<AdminPaysFormScreen modeFormulaire={ModeFormulaire.editer} />} />
                    <Route path='/admin/pays/voir/:id' element={<AdminPaysFormScreen modeFormulaire={ModeFormulaire.voir} />} />

                    <Route path='/admin/periodes' element={<AdminPeriodeListScreen />} />
                    <Route path='/admin/periodes/creer' element={<AdminPeriodeFormScreen modeFormulaire={ModeFormulaire.creer} />} />
                    <Route path='/admin/periodes/editer/:id' element={<AdminPeriodeFormScreen modeFormulaire={ModeFormulaire.editer} />} />
                    <Route path='/admin/periodes/voir/:id' element={<AdminPeriodeFormScreen modeFormulaire={ModeFormulaire.voir} />} />

                    <Route path='/admin/personnels' element={<AdminPersonnelListScreen />} />
                    <Route path='/admin/personnels/creer' element={<AdminPersonnelFormScreen modeFormulaire={ModeFormulaire.creer} />} />
                    <Route path='/admin/personnels/editer/:id' element={<AdminPersonnelFormScreen modeFormulaire={ModeFormulaire.editer} />} />
                    <Route path='/admin/personnels/voir/:id' element={<AdminPersonnelFormScreen modeFormulaire={ModeFormulaire.voir} />} />

                    <Route path='/admin/regions' element={<AdminRegionListScreen />} />
                    <Route path='/admin/regions/creer' element={<AdminRegionFormScreen modeFormulaire={ModeFormulaire.creer} />} />
                    <Route path='/admin/regions/editer/:id' element={<AdminRegionFormScreen modeFormulaire={ModeFormulaire.editer} />} />
                    <Route path='/admin/regions/voir/:id' element={<AdminRegionFormScreen modeFormulaire={ModeFormulaire.voir} />} />

                    <Route path='/admin/roles' element={<AdminRoleListScreen />} />
                    <Route path='/admin/roles/creer' element={<AdminRoleFormScreen modeFormulaire={ModeFormulaire.creer} />} />
                    <Route path='/admin/roles/editer/:id' element={<AdminRoleFormScreen modeFormulaire={ModeFormulaire.editer} />} />
                    <Route path='/admin/roles/voir/:id' element={<AdminRoleFormScreen modeFormulaire={ModeFormulaire.voir} />} />

                    <Route path='/admin/secteurs-activite' element={<AdminSecteurActiviteListScreen />} />
                    <Route path='/admin/secteurs-activite/creer' element={<AdminSecteurActiviteFormScreen modeFormulaire={ModeFormulaire.creer} />} />
                    <Route path='/admin/secteurs-activite/editer/:id' element={<AdminSecteurActiviteFormScreen modeFormulaire={ModeFormulaire.editer} />} />
                    <Route path='/admin/secteurs-activite/voir/:id' element={<AdminSecteurActiviteFormScreen modeFormulaire={ModeFormulaire.voir} />} />

                    <Route path='/admin/sites' element={<AdminSiteListScreen />} />
                    <Route path='/admin/sites/creer' element={<AdminSiteFormScreen modeFormulaire={ModeFormulaire.creer} />} />
                    <Route path='/admin/sites/editer/:id' element={<AdminSiteFormScreen modeFormulaire={ModeFormulaire.editer} />} />
                    <Route path='/admin/sites/voir/:id' element={<AdminSiteFormScreen modeFormulaire={ModeFormulaire.voir} />} />

                    <Route path='/admin/statuts-affiliation' element={<AdminStatutAffiliationListScreen />} />
                    <Route path='/admin/statuts-affiliation/creer' element={<AdminStatutAffiliationFormScreen modeFormulaire={ModeFormulaire.creer} />} />
                    <Route path='/admin/statuts-affiliation/editer/:id' element={<AdminStatutAffiliationFormScreen modeFormulaire={ModeFormulaire.editer} />} />
                    <Route path='/admin/statuts-affiliation/voir/:id' element={<AdminStatutAffiliationFormScreen modeFormulaire={ModeFormulaire.voir} />} />

                    <Route path='/admin/types-employeurs' element={<AdminTypeEmployeurListScreen />} />
                    <Route path='/admin/types-employeurs/creer' element={<AdminTypeEmployeurFormScreen modeFormulaire={ModeFormulaire.creer} />} />
                    <Route path='/admin/types-employeurs/editer/:id' element={<AdminTypeEmployeurFormScreen modeFormulaire={ModeFormulaire.editer} />} />
                    <Route path='/admin/types-employeurs/voir/:id' element={<AdminTypeEmployeurFormScreen modeFormulaire={ModeFormulaire.voir} />} />

                    <Route path='/admin/types-profils' element={<AdminTypeProfilListScreen />} />
                    <Route path='/admin/types-profils/creer' element={<AdminTypeProfilFormScreen modeFormulaire={ModeFormulaire.creer} />} />
                    <Route path='/admin/types-profils/editer/:id' element={<AdminTypeProfilFormScreen modeFormulaire={ModeFormulaire.editer} />} />
                    <Route path='/admin/types-profils/voir/:id' element={<AdminTypeProfilFormScreen modeFormulaire={ModeFormulaire.voir} />} />

                    <Route path='/admin/users' element={<AdminUserListScreen />} />
                    <Route path='/admin/users/creer' element={<AdminUserFormScreen modeFormulaire={ModeFormulaire.creer} />} />
                    <Route path='/admin/users/editer/:id' element={<AdminUserFormScreen modeFormulaire={ModeFormulaire.editer} />} />
                    <Route path='/admin/users/voir/:id' element={<AdminUserFormScreen modeFormulaire={ModeFormulaire.voir} />} />

                    <Route path='/admin/e-en-attente' element={<AdminEntreprisesEnAttenteScreen />} />

                    <Route path='/admin/statistiques/inscriptions' element={<AdminStatistiquesInscriptionsScreen />} />
                    <Route path='/admin/statistiques/recherches' element={<AdminStatistiquesRecherchesScreen />} />
                    <Route path='/admin/statistiques/recherches/mot/:mot/mode/:mode/annee/:annee' element={<AdminStatistiquesRecherchesMotScreen />} />
                    <Route
                      path='/admin/statistiques/recherches/mot/:mot/mode/:mode/annee/:annee/mois/:mois'
                      element={<AdminStatistiquesRecherchesMotScreen />}
                    />
                    <Route
                      path='/admin/statistiques/recherches/mot/:mot/mode/:mode/annee/:annee/mois/:mois/jour/:jour'
                      element={<AdminStatistiquesRecherchesMotScreen />}
                    />
                    <Route path='/admin/statistiques/visites-filieres' element={<AdminStatistiquesVisitesFilieresScreen />} />
                    <Route
                      path='/admin/statistiques/visites-filieres/id/:id/nom/:nom/mode/:mode/annee/:annee'
                      element={<AdminStatistiquesVisitesFiliereScreen />}
                    />
                    <Route
                      path='/admin/statistiques/visites-filieres/id/:id/nom/:nom/mode/:mode/annee/:annee/mois/:mois'
                      element={<AdminStatistiquesVisitesFiliereScreen />}
                    />
                    <Route
                      path='/admin/statistiques/visites-filieres/id/:id/nom/:nom/mode/:mode/annee/:annee/mois/:mois/jour/:jour'
                      element={<AdminStatistiquesVisitesFiliereScreen />}
                    />

                    <Route path='/admin/statistiques/visites-formations' element={<AdminStatistiquesVisitesFormationsScreen />} />
                    <Route
                      path='/admin/statistiques/visites-formations/id/:id/nom/:nom/mode/:mode/annee/:annee'
                      element={<AdminStatistiquesVisitesFormationScreen />}
                    />
                    <Route
                      path='/admin/statistiques/visites-formations/id/:id/nom/:nom/mode/:mode/annee/:annee/mois/:mois'
                      element={<AdminStatistiquesVisitesFormationScreen />}
                    />
                    <Route
                      path='/admin/statistiques/visites-formations/id/:id/nom/:nom/mode/:mode/annee/:annee/mois/:mois/jour/:jour'
                      element={<AdminStatistiquesVisitesFormationScreen />}
                    />

                    <Route path='/admin/statistiques/visites-metiers' element={<AdminStatistiquesVisitesMetiersScreen />} />
                    <Route
                      path='/admin/statistiques/visites-metiers/id/:id/nom/:nom/mode/:mode/annee/:annee'
                      element={<AdminStatistiquesVisitesMetierScreen />}
                    />
                    <Route
                      path='/admin/statistiques/visites-metiers/id/:id/nom/:nom/mode/:mode/annee/:annee/mois/:mois'
                      element={<AdminStatistiquesVisitesMetierScreen />}
                    />
                    <Route
                      path='/admin/statistiques/visites-metiers/id/:id/nom/:nom/mode/:mode/annee/:annee/mois/:mois/jour/:jour'
                      element={<AdminStatistiquesVisitesMetierScreen />}
                    />

                    <Route path='/admin/statistiques/visites-sites' element={<AdminStatistiquesVisitesSitesScreen />} />
                    <Route path='/admin/statistiques/visites-sites/id/:id/nom/:nom/mode/:mode/annee/:annee' element={<AdminStatistiquesVisitesSiteScreen />} />
                    <Route
                      path='/admin/statistiques/visites-sites/id/:id/nom/:nom/mode/:mode/annee/:annee/mois/:mois'
                      element={<AdminStatistiquesVisitesSiteScreen />}
                    />
                    <Route
                      path='/admin/statistiques/visites-sites/id/:id/nom/:nom/mode/:mode/annee/:annee/mois/:mois/jour/:jour'
                      element={<AdminStatistiquesVisitesSiteScreen />}
                    />

                    <Route path='/admin/statistiques/favoris-formations' element={<AdminStatistiquesFavorisFormationsScreen />} />
                    <Route
                      path='/admin/statistiques/favoris-formations/id/:id/nom/:nom/mode/:mode/annee/:annee'
                      element={<AdminStatistiquesFavorisFormationScreen />}
                    />
                    <Route
                      path='/admin/statistiques/favoris-formations/id/:id/nom/:nom/mode/:mode/annee/:annee/mois/:mois'
                      element={<AdminStatistiquesFavorisFormationScreen />}
                    />
                    <Route
                      path='/admin/statistiques/favoris-formations/id/:id/nom/:nom/mode/:mode/annee/:annee/mois/:mois/jour/:jour'
                      element={<AdminStatistiquesFavorisFormationScreen />}
                    />

                    <Route path='/admin/statistiques/souhaits-formations' element={<AdminStatistiquesSouhaitsFormationsScreen />} />
                    <Route
                      path='/admin/statistiques/souhaits-formations/id/:id/nom/:nom/mode/:mode/annee/:annee'
                      element={<AdminStatistiquesSouhaitsFormationScreen />}
                    />
                    <Route
                      path='/admin/statistiques/souhaits-formations/id/:id/nom/:nom/mode/:mode/annee/:annee/mois/:mois'
                      element={<AdminStatistiquesSouhaitsFormationScreen />}
                    />
                    <Route
                      path='/admin/statistiques/souhaits-formations/id/:id/nom/:nom/mode/:mode/annee/:annee/mois/:mois/jour/:jour'
                      element={<AdminStatistiquesSouhaitsFormationScreen />}
                    />

                    <Route path='/admin/statistiques/demandes-formations' element={<AdminStatistiquesDemandesFormationsScreen />} />
                    <Route
                      path='/admin/statistiques/demandes-formations/id/:id/nom/:nom/mode/:mode/annee/:annee'
                      element={<AdminStatistiquesDemandesFormationScreen />}
                    />
                    <Route
                      path='/admin/statistiques/demandes-formations/id/:id/nom/:nom/mode/:mode/annee/:annee/mois/:mois'
                      element={<AdminStatistiquesDemandesFormationScreen />}
                    />
                    <Route
                      path='/admin/statistiques/demandes-formations/id/:id/nom/:nom/mode/:mode/annee/:annee/mois/:mois/jour/:jour'
                      element={<AdminStatistiquesDemandesFormationScreen />}
                    />

                    <Route path='/admin/statistiques/demandes-paiements' element={<AdminStatistiquesDemandesPaiementsScreen />} />
                    <Route
                      path='/admin/statistiques/demandes-paiements/id/:id/nom/:nom/mode/:mode/annee/:annee'
                      element={<AdminStatistiquesDemandesPaiementScreen />}
                    />
                    <Route
                      path='/admin/statistiques/demandes-paiements/id/:id/nom/:nom/mode/:mode/annee/:annee/mois/:mois'
                      element={<AdminStatistiquesDemandesPaiementScreen />}
                    />
                    <Route
                      path='/admin/statistiques/demandes-paiements/id/:id/nom/:nom/mode/:mode/annee/:annee/mois/:mois/jour/:jour'
                      element={<AdminStatistiquesDemandesPaiementScreen />}
                    />

                    <Route path='/admin/ypareo/logs-synchronisation' element={<AdminYpareoLogsSyncroScreen />} />

                    <Route path='/admin/demandes' element={<AdminDemandesScreen />} />
                    <Route path='/admin/demandes/:id' element={<AdminDemandeScreen />} />

                    <Route path='/admin/avis' element={<AdminAvisScreen />} />

                    <Route path='/admin/export' element={<AdminExportScreen />} />

                    <Route path='/admin/documents' element={<AdminDocumentsScreen />} />
                    <Route path='/admin/documents/:id' element={<AdminDocumentScreen />} />

                    <Route path='/admin/logs' element={<AdminLogsScreen />} />
                    <Route path='/admin/paramdelais' element={<AdminParamDelaisScreen />} />
                    <Route path='/admin/devis' element={<AdminDevisScreen />} />
                    <Route path='/admin/devis/:id' element={<AdminDeviScreen />} />
                    <Route path='/admin/convocations' element={<AdminConvocationsScreen />} />
                    <Route path='/admin/convocations/:id' element={<AdminConvocationScreen />} />
                    <Route path='/admin/factures' element={<AdminFacturesScreen />} />
                    <Route path='/admin/factures/:id' element={<AdminFactureScreen />} />
                  </Route>
                  <Route element={<RoleAdmingcLayout />}>
                    <Route path='/admingc/formations' element={<AdmingcFormationListScreen />} />
                    <Route path='/admingc/formations/creer' element={<AdmingcFormationFormScreen modeFormulaire={ModeFormulaire.creer} />} />
                    <Route path='/admingc/formations/editer/:id' element={<AdmingcFormationFormScreen modeFormulaire={ModeFormulaire.editer} />} />
                    <Route path='/admingc/formations/voir/:id' element={<AdmingcFormationFormScreen modeFormulaire={ModeFormulaire.voir} />} />

                    <Route path='/admingc/statistiques/recherches' element={<AdmingcStatistiquesRecherchesScreen />} />
                    <Route path='/admingc/statistiques/recherches/mot/:mot/mode/:mode/annee/:annee' element={<AdmingcStatistiquesRecherchesMotScreen />} />
                    <Route
                      path='/admingc/statistiques/recherches/mot/:mot/mode/:mode/annee/:annee/mois/:mois'
                      element={<AdmingcStatistiquesRecherchesMotScreen />}
                    />
                    <Route
                      path='/admingc/statistiques/recherches/mot/:mot/mode/:mode/annee/:annee/mois/:mois/jour/:jour'
                      element={<AdmingcStatistiquesRecherchesMotScreen />}
                    />
                    <Route path='/admingc/statistiques/visites-filieres' element={<AdmingcStatistiquesVisitesFilieresScreen />} />
                    <Route
                      path='/admingc/statistiques/visites-filieres/id/:id/nom/:nom/mode/:mode/annee/:annee'
                      element={<AdmingcStatistiquesVisitesFiliereScreen />}
                    />
                    <Route
                      path='/admingc/statistiques/visites-filieres/id/:id/nom/:nom/mode/:mode/annee/:annee/mois/:mois'
                      element={<AdmingcStatistiquesVisitesFiliereScreen />}
                    />
                    <Route
                      path='/admingc/statistiques/visites-filieres/id/:id/nom/:nom/mode/:mode/annee/:annee/mois/:mois/jour/:jour'
                      element={<AdmingcStatistiquesVisitesFiliereScreen />}
                    />

                    <Route path='/admingc/statistiques/visites-formations' element={<AdmingcStatistiquesVisitesFormationsScreen />} />
                    <Route
                      path='/admingc/statistiques/visites-formations/id/:id/nom/:nom/mode/:mode/annee/:annee'
                      element={<AdmingcStatistiquesVisitesFormationScreen />}
                    />
                    <Route
                      path='/admingc/statistiques/visites-formations/id/:id/nom/:nom/mode/:mode/annee/:annee/mois/:mois'
                      element={<AdmingcStatistiquesVisitesFormationScreen />}
                    />
                    <Route
                      path='/admingc/statistiques/visites-formations/id/:id/nom/:nom/mode/:mode/annee/:annee/mois/:mois/jour/:jour'
                      element={<AdmingcStatistiquesVisitesFormationScreen />}
                    />

                    <Route path='/admingc/statistiques/visites-metiers' element={<AdmingcStatistiquesVisitesMetiersScreen />} />
                    <Route
                      path='/admingc/statistiques/visites-metiers/id/:id/nom/:nom/mode/:mode/annee/:annee'
                      element={<AdmingcStatistiquesVisitesMetierScreen />}
                    />
                    <Route
                      path='/admingc/statistiques/visites-metiers/id/:id/nom/:nom/mode/:mode/annee/:annee/mois/:mois'
                      element={<AdmingcStatistiquesVisitesMetierScreen />}
                    />
                    <Route
                      path='/admingc/statistiques/visites-metiers/id/:id/nom/:nom/mode/:mode/annee/:annee/mois/:mois/jour/:jour'
                      element={<AdmingcStatistiquesVisitesMetierScreen />}
                    />

                    <Route path='/admingc/statistiques/visites-sites' element={<AdmingcStatistiquesVisitesSitesScreen />} />
                    <Route
                      path='/admingc/statistiques/visites-sites/id/:id/nom/:nom/mode/:mode/annee/:annee'
                      element={<AdmingcStatistiquesVisitesSiteScreen />}
                    />
                    <Route
                      path='/admingc/statistiques/visites-sites/id/:id/nom/:nom/mode/:mode/annee/:annee/mois/:mois'
                      element={<AdmingcStatistiquesVisitesSiteScreen />}
                    />
                    <Route
                      path='/admingc/statistiques/visites-sites/id/:id/nom/:nom/mode/:mode/annee/:annee/mois/:mois/jour/:jour'
                      element={<AdmingcStatistiquesVisitesSiteScreen />}
                    />

                    <Route path='/admingc/statistiques/souhaits-formations' element={<AdmingcStatistiquesSouhaitsFormationsScreen />} />
                    <Route
                      path='/admingc/statistiques/souhaits-formations/id/:id/nom/:nom/mode/:mode/annee/:annee'
                      element={<AdmingcStatistiquesSouhaitsFormationScreen />}
                    />
                    <Route
                      path='/admingc/statistiques/souhaits-formations/id/:id/nom/:nom/mode/:mode/annee/:annee/mois/:mois'
                      element={<AdmingcStatistiquesSouhaitsFormationScreen />}
                    />
                    <Route
                      path='/admingc/statistiques/souhaits-formations/id/:id/nom/:nom/mode/:mode/annee/:annee/mois/:mois/jour/:jour'
                      element={<AdmingcStatistiquesSouhaitsFormationScreen />}
                    />

                    <Route path='/admingc/statistiques/demandes-formations' element={<AdmingcStatistiquesDemandesFormationsScreen />} />
                    <Route
                      path='/admingc/statistiques/demandes-formations/id/:id/nom/:nom/mode/:mode/annee/:annee'
                      element={<AdmingcStatistiquesDemandesFormationScreen />}
                    />
                    <Route
                      path='/admingc/statistiques/demandes-formations/id/:id/nom/:nom/mode/:mode/annee/:annee/mois/:mois'
                      element={<AdmingcStatistiquesDemandesFormationScreen />}
                    />
                    <Route
                      path='/admingc/statistiques/demandes-formations/id/:id/nom/:nom/mode/:mode/annee/:annee/mois/:mois/jour/:jour'
                      element={<AdmingcStatistiquesDemandesFormationScreen />}
                    />

                    <Route path='/admingc/statistiques/demandes-paiements' element={<AdmingcStatistiquesDemandesPaiementsScreen />} />
                    <Route
                      path='/admingc/statistiques/demandes-paiements/id/:id/nom/:nom/mode/:mode/annee/:annee'
                      element={<AdmingcStatistiquesDemandesPaiementScreen />}
                    />
                    <Route
                      path='/admingc/statistiques/demandes-paiements/id/:id/nom/:nom/mode/:mode/annee/:annee/mois/:mois'
                      element={<AdmingcStatistiquesDemandesPaiementScreen />}
                    />
                    <Route
                      path='/admingc/statistiques/demandes-paiements/id/:id/nom/:nom/mode/:mode/annee/:annee/mois/:mois/jour/:jour'
                      element={<AdmingcStatistiquesDemandesPaiementScreen />}
                    />

                    <Route path='/admingc/demandes' element={<AdmingcDemandesScreen />} />
                    <Route path='/admingc/demandes/:id' element={<AdmingcDemandeScreen />} />
                  </Route>
                </Route>
                <Route path='*' element={<NotFoundScreen />} />
              </Routes>
            </Suspense>
          </BrowserRouter>
        </UserProvider>
      </ApiProvider>
    </Fragment>
  );
};

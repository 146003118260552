import { FunctionComponent, useEffect, useState } from 'react';
import { YpareoLog } from '../../../types/schematics';
import { LoadingAbsolute } from '../../../components/LoadingAbsolute';
import { useApi } from '../../../providers/ApiProvider';
import { ErrorApiResponse, GoodApiResponse } from '../../../types/api';
import { InputDate } from '../../../components/Inputs/InputDate';
import { CheckIcon, ExclamationCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { handleError } from '../../../utils/ErrorHandler';

export const AdminYpareoLogsSyncroScreen: FunctionComponent = () => {
  /**
   * On stocke si on est en train d'utiliser l'API ou non.
   */
  const [apiIsLoading, setApiIsLoading] = useState(false);

  /**
   * Date du jour.
   * @constant
   */
  const currentDate = new Date();

  /**
   * Date d'il y a 7 jour.
   * @constant
   */
  const sevenDayAgo = new Date(new Date().setDate(currentDate.getDate() - 7));

  const [from, setFrom] = useState<string>(
    localStorage.getItem(`AdminYpareoLogsSyncroScreen-From`) ||
      `${sevenDayAgo.getFullYear()}-${sevenDayAgo.getMonth() < 9 ? `0${sevenDayAgo.getMonth() + 1}` : sevenDayAgo.getMonth() + 1}-${
        sevenDayAgo.getDate() < 10 ? `0${sevenDayAgo.getDate()}` : sevenDayAgo.getDate()
      }`,
  );

  const [to, setTo] = useState<string>(
    localStorage.getItem(`AdminYpareoLogsSyncroScreen-To`) ||
      `${currentDate.getFullYear()}-${currentDate.getMonth() < 9 ? `0${currentDate.getMonth() + 1}` : currentDate.getMonth() + 1}-${
        currentDate.getDate() < 10 ? `0${currentDate.getDate()}` : currentDate.getDate()
      }`,
  );

  const [logs, setLogs] = useState<YpareoLog[] | null>(null);

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  useEffect(() => {
    if (from && to) {
      handleIndex();
    }
  }, [client, from, to]);

  const handleIndex = () => {
    setApiIsLoading(true);

    client
      .get<GoodApiResponse<YpareoLog>>('/admin/ypareo/logs-synchronisation', { params: { from, to } })
      .then((response) => {
        setLogs(response.data.data as unknown as YpareoLog[]);
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  return (
    <div className='relative'>
      <div className='px-4 py-4 md:py-3 bg-gray-100 rounded-tl-lg rounded-tr-lg flex flex-col sm:flex-row gap-7 items-center'>
        <div>
          <p className='text-base sm:text-lg md:text-xl poppins-bold leading-normal text-secondary-1 pl-4'>Logs de synchro</p>
        </div>
        <div className='flex flex-col md:flex-row gap-4 items-center'>
          <div className='flex flex-row items-center gap-3'>
            <p className='text-xs text-secondary-1 mb-1'>Du&nbsp;:</p>
            <InputDate
              value={from}
              onChange={(e) => {
                setFrom(e.target.value);
                localStorage.setItem(`AdminYpareoLogsSyncroScreen-From`, e.target.value);
              }}
            />
          </div>
          <div className='flex flex-row items-center gap-3'>
            <p className='text-xs text-secondary-1 mb-1'>Au&nbsp;:</p>
            <InputDate
              value={to}
              onChange={(e) => {
                setTo(e.target.value);
                localStorage.setItem(`AdminYpareoLogsSyncroScreen-To`, e.target.value);
              }}
            />
          </div>
        </div>
      </div>
      <div className='container mx-auto bg-white rounded-b-lg'>
        <div className='w-full overflow-x-auto'>
          {apiIsLoading ? <LoadingAbsolute /> : ''}
          {logs && logs.length > 0 ? (
            <table className='table-fixed min-w-full bg-white rounded-b-lg'>
              <thead>
                <tr className='w-full h-12 border-gray-300  border-b py-8'>
                  <th className='text-secondary-1 pl-4 text-left text-sm tracking-normal leading-4'>Nom de la ressource</th>
                  <th className='text-secondary-1 pl-4 text-sm tracking-normal leading-4'>Démarré le</th>
                  <th className='text-secondary-1 pl-4 text-sm tracking-normal leading-4'>Terminé le</th>
                  <th className='text-secondary-1 pl-4 text-sm tracking-normal leading-4 w-24'>Créés</th>
                  <th className='text-secondary-1 pl-4 text-sm tracking-normal leading-4 w-24'>Modifiés</th>
                  <th className='text-secondary-1 pl-4 text-sm tracking-normal leading-4 w-24'>Intacts</th>
                  <th className='text-secondary-1 pl-4 text-sm tracking-normal leading-4 w-24'>Données reçues ?</th>
                  <th className='text-secondary-1 pl-4 text-sm tracking-normal leading-4 w-24'>Données traitées ?</th>

                  <th className='text-secondary-1 pl-4 text-sm tracking-normal leading-4 w-24'>Possède erreur ?</th>
                </tr>
              </thead>
              <tbody>
                {logs.map((log) => (
                  <tr key={log.id} className='border-gray-100 border-b hover:bg-gray-100'>
                    <td className='pl-4 py-3 text-gray-800 text-sm'>{log.resource_name}</td>
                    <td className='pl-4 py-3 text-gray-800 text-sm text-right'>
                      {log.started_at
                        ? new Intl.DateTimeFormat('default', {
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                            second: 'numeric',
                            hour12: false,
                          }).format(new Date(log.started_at as string))
                        : ''}
                    </td>
                    <td className='pr-4 py-3 text-gray-800 text-sm text-right'>
                      {log.ended_at
                        ? new Intl.DateTimeFormat('default', {
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                            second: 'numeric',
                            hour12: false,
                          }).format(new Date(log.ended_at as string))
                        : ''}
                    </td>
                    <td className='pr-4 py-3 text-gray-800 text-sm text-right'>{log.created_count}</td>
                    <td className='pr-4 py-3 text-gray-800 text-sm text-right'>{log.updated_count}</td>
                    <td className='pr-4 py-3 text-gray-800 text-sm text-right'>{log.untouched_count}</td>
                    <td className='pr-4 py-3 text-gray-800 text-sm text-right'>
                      {log.data_received ? (
                        <CheckIcon className='w-4 h-4 text-white block mx-auto bg-light-green-300 rounded-full p-0.5' />
                      ) : (
                        <XMarkIcon className='w-4 h-4 block mx-auto text-gray-900/20' />
                      )}
                    </td>
                    <td className='pr-4 py-3 text-gray-800 text-sm'>
                      {log.data_processed ? (
                        <CheckIcon className='w-4 h-4 text-white block mx-auto bg-light-green-300 rounded-full p-0.5' />
                      ) : (
                        <XMarkIcon className='w-4 h-4 block mx-auto text-gray-900/20' />
                      )}
                    </td>
                    <td className='pr-4 py-3 text-gray-800 text-sm'>
                      {log.has_error ? (
                        <ExclamationCircleIcon
                          className='w-6 h-6 text-white block mx-auto bg-red-300 rounded-full p-0.5'
                          title={log.error_string ? log.error_string : ''}
                        />
                      ) : (
                        <XMarkIcon className='w-4 h-4 block mx-auto text-gray-900/20' />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className='text-center px-4 py-7'>Il n'y pas de log de synchro pour la période choisie.</div>
          )}
        </div>
      </div>
    </div>
  );
};

import { Fragment, FunctionComponent, useState } from 'react';
import nl2br from 'react-nl2br';
import { FormationSimilaire, Groupe, Matiere } from '../types/visitorRessources';
import { CardFormationSimilaire } from './CardFormationSimilaire';
import { Accordion } from './Accordeon/Accordion';

/**
 * Crée l'icône pour déplier ou replier l'accordéon.
 *
 * @param id - ID de la partie à déplier.
 * @param open - ID de la partie dépliée.
 */
const Icon = ({ id, open }: { id: number; open: number }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={`${id === open ? 'text-secondary-1 rotate-180' : ''} h-5 w-5 transition-transform text-secondary-1`}
      fill='none'
      viewBox='0 0 24 24'
      stroke='currentColor'
      strokeWidth={3}>
      <path strokeLinecap='round' strokeLinejoin='round' d='M19 9l-7 7-7-7' />
    </svg>
  );
};

/**
 * Ce composant contient plusieurs parties, en raport avec la formation, qui se déplient lorsque l'on clique dessus.
 *
 * @param objectifs - Objetifs de la formation.
 * @param contenu - Contenu de la formation.
 * @param methode - Méthode de la formation.
 * @param evaluation - Évaluation de la formation.
 * @param modules - Modules de la formation.
 * @param sessions - Sessions de la formation.
 * @param acces - Modalités d'accès.
 * @param accessibilite - Accéssibilité de la formation.
 * @param similaires - formations similaires.
 */
export const Accordeon: FunctionComponent<{
  objectifs?: string | null;
  contenu?: string | null;
  methode?: string | null;
  evaluation?: string | null;
  modules?: Matiere[] | null;
  sessions?: Groupe[] | null;
  acces?: string | null;
  accessibilite?: string | null;
  similaires?: FormationSimilaire[] | null;
}> = ({
  objectifs = null,
  contenu = null,
  methode = null,
  evaluation = null,
  modules = null,
  sessions = null,
  acces = null,
  accessibilite = null,
  similaires = null,
}) => {
  /**
   * @var Tableau qui va contenir
   */
  const accordeonList = [];

  let flag = false;

  let firstOpen = 0;

  if (objectifs !== null) {
    accordeonList.push({
      titre: 'Objectifs de la formation',
      id: 1,
      description: nl2br(objectifs),
    });

    firstOpen = 1;
    flag = true;
  }

  if (contenu !== null) {
    accordeonList.push({
      titre: 'Contenu de la formation',
      id: 2,
      description: nl2br(contenu),
    });

    if (!flag) {
      firstOpen = 2;
      flag = true;
    }
  }

  if (methode !== null) {
    accordeonList.push({
      titre: 'Méthode mobilisée',
      id: 3,
      description: nl2br(methode),
    });

    if (!flag) {
      firstOpen = 3;
      flag = true;
    }
  }

  if (evaluation !== null) {
    accordeonList.push({
      titre: "Modalité d'évaluation",
      id: 4,
      description: nl2br(evaluation),
    });

    if (!flag) {
      firstOpen = 4;
      flag = true;
    }
  }

  if (modules !== null && modules.length > 0) {
    accordeonList.push({
      titre: 'Modules',
      id: 5,
      description:
        modules !== null && modules.length ? (
          <ul>
            {modules.map((module) => (
              <li key={module.nom}>{module.nom}</li>
            ))}
          </ul>
        ) : null,
    });

    if (!flag) {
      firstOpen = 5;
      flag = true;
    }
  }

  if (acces !== null) {
    accordeonList.push({
      titre: "Modalités d'accès",
      id: 6,
      description: nl2br(acces),
    });

    if (!flag) {
      firstOpen = 6;
      flag = true;
    }
  }

  if (accessibilite !== null) {
    accordeonList.push({
      titre: 'Accessibilité',
      id: 7,
      description: nl2br(accessibilite),
    });

    if (!flag) {
      firstOpen = 7;
      flag = true;
    }
  }

  if (similaires !== null) {
    accordeonList.push({
      titre: 'Formations similaires',
      id: 8,
      description: <CardFormationSimilaire formations={similaires} />,
    });

    if (!flag) {
      firstOpen = 8;
      flag = true;
    }
  }

  if (sessions !== null && sessions.length > 0) {
    accordeonList.push({
      titre: 'Sessions',
      id: 9,
      description:
        sessions !== null && sessions.length > 0 ? (
          <ul>
            {sessions.map((session) => (
              <li key={session.nom}>{session.nom}</li>
            ))}
          </ul>
        ) : null,
    });

    if (!flag) {
      firstOpen = 10;
    }
  }

  const [open, setOpen] = useState(firstOpen);

  const handleOpen = (value: number) => {
    setOpen(open === value ? 0 : value);
  };

  return (
    <Fragment>
      {accordeonList.map((accordeon) => {
        if (accordeon && accordeon.description) {
          return (
            <Accordion
              key={accordeon.id}
              open={open === accordeon.id}
              icon={<Icon id={accordeon.id} open={open} />}
              titre={accordeon.titre}
              description={accordeon.description}
              onClick={() => handleOpen(accordeon.id)}
            />
          );
        }
        return null;
      })}
    </Fragment>
  );
};

import { FunctionComponent, Fragment, useState } from 'react';
import { Input } from '@material-tailwind/react';
import { Link, Navigate } from 'react-router-dom';
import toast, { Renderable, Toast, ValueFunction } from 'react-hot-toast';
import { Helmet } from 'react-helmet';

import { Config } from '../../config/config';
import { LoadingAbsolute } from '../../components/LoadingAbsolute';
import { useApi } from '../../providers/ApiProvider';
import { useUser } from '../../providers/UserProvider';
import { LoginResponse, LoginData, UserLocal, StatutAffiliationEnum } from '../../types/auth';
import { EyeSlashIcon, EyeIcon } from '@heroicons/react/20/solid';
import { ToastInfo } from '../../components/toasts/ToastInfo';
import { handleError } from '../../utils/ErrorHandler';

/**
 * Ce composant affiche la page de connexion.
 */
export const LoginScreen: FunctionComponent = () => {
  /**
   * On stocke si on est en train d'utiliser l'API ou non.
   */
  const [apiIsLoading, setApiIsLoading] = useState(false);

  /**
   * On a besoin des informations de l'utilisateur et du profil courant de celui-ci ainsi que du moyen de modifier ces informations.
   */
  const { user, changeUser, changeProfil: setCurrentProfil, changeAfficheSelecteurProfil } = useUser();

  /**
   * État local pour gérer le champ `Adresse e-mail` du formulaire de connexion
   */
  const [email, setEmail] = useState({ value: '', error: false });

  /**
   * État local pour gérer le champ `Mot de passe` du formulaire de connexion
   */
  const [password, setPassword] = useState({ value: '', error: false });

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  /**
   * Gère l'appui sur un bouton pour voir le mot de passe.
   */
  const [showedPassword, setShowedPassword] = useState<boolean>(false);

  /**
   * Cette méthode gère le changement de valeur du champ `Adresse e-mail`
   *
   * @param event - Évènement de changement de valeur.
   */
  const handleEmailChange = (event: { target: { value: string } }) => {
    setEmail({ value: event.target.value, error: false });
  };

  /**
   * Cette méthode gère le changement de valeur du champ `Mot de passe`
   *
   * @param event - Évènement de changement de valeur.
   */
  const handlePasswordChange = (event: { target: { value: string } }) => {
    setPassword({ value: event.target.value, error: false });
  };

  /**
   * Cette méthode effectue l'appel API pour authentifier un utilisateur.
   *
   * @param event - Évènement d'envoi du formulaire.
   */
  const handleLogin = (event: { preventDefault: () => void }) => {
    event.preventDefault();

    if (apiIsLoading === true) {
      return;
    }

    setApiIsLoading(true);

    client
      .post<LoginResponse, LoginData>('/login', {
        email: email.value,
        password: password.value,
        device_name: Config.app_name + '_' + Config.app_version,
      })
      .then((response) => {
        // Stockage des infos utilisateur
        const oUser: UserLocal = {
          token: response.data.authorization.token,
          nom: response.data.user.nom,
          prenom: response.data.user.prenom,
          email: response.data.user.email,
          image_avatar: response.data.user.image_avatar,
          roles: response.data.roles,
          profils: response.data.profils,
        };
        toast.custom(<ToastInfo>Vous êtes maintenant connecté.</ToastInfo>);
        setApiIsLoading(false);
        changeUser(oUser);
        const tempUserProfils = oUser.profils.filter((profil) => profil.statut_affiliation === StatutAffiliationEnum.affilie);
        if (tempUserProfils.length > 0) {
          setCurrentProfil(tempUserProfils[0]);
        }
        if (tempUserProfils.length > 1) {
          changeAfficheSelecteurProfil(true);
        }
      })
      .catch(
        (error: {
          response: {
            status: number;
            data: {
              message: Renderable | ValueFunction<Renderable, Toast>;
              errors: { email: string };
            };
          };
          message: Renderable | ValueFunction<Renderable, Toast>;
        }) => {
          setApiIsLoading(false);
          handleError(error as any, () => {
            if (error.response.data.errors.email) {
              setEmail({ value: email.value, error: true });
            }
            setPassword({ value: password.value, error: true });
          });
        },
      );
  };

  if (!user || !user.token) {
    return (
      <Fragment>
        <Helmet>
          <title>Connexion - {Config.app_label}</title>
        </Helmet>

        <div className='rounded shadow-sm  overflow-hidden bg-[#F7FBFE]'>
          <div className='p-8'>
            <h2 className='text-3xl text-center mb-1 space-x-3 poppins-bold text-secondary-2'>Connectez-vous</h2>
            <p className='text-gray-700 text-center text-sm'>Veuillez saisir votre identifiant et votre mot de passe afin de vous connecter.</p>

            <form className='relative mt-6' onSubmit={handleLogin}>
              <div className='mt-6'>
                <Input
                  //className='block border border-gray-200 rounded px-5 py-3 leading-6 w-full focus:border-primary focus:ring focus:ring-primary focus:ring-opacity-50'
                  type='email'
                  label='Adresse e-mail'
                  onChange={handleEmailChange}
                  error={email.error}
                />
              </div>
              <div className='mt-6'>
                <Input
                  //className='block border border-gray-200 rounded px-5 py-3 leading-6 w-full focus:border-primary focus:ring focus:ring-primary focus:ring-opacity-50'
                  type={showedPassword ? 'text' : 'password'}
                  label='Mot de passe'
                  onChange={handlePasswordChange}
                  error={password.error}
                  icon={
                    <div onClick={() => setShowedPassword(!showedPassword)}>
                      {showedPassword ? <EyeSlashIcon className='w-5 h-5' /> : <EyeIcon className='w-5 h-5' />}
                    </div>
                  }
                />
              </div>

              <div className='mt-6'>
                <button type='submit' className='poppins-semibold w-full px-2 py-1 rounded-md bg-primary text-white  disabled:bg-primary/50 uppercase'>
                  Je me connecte
                </button>
              </div>
              <div className='flex items-center justify-between mt-2'>
                <Link to='/motdepasse-oublie' className='inline-block text-primary hover:text-secondary-1 text-sm'>
                  Mot de passe oublié&nbsp;?
                </Link>
              </div>

              {apiIsLoading ? <LoadingAbsolute /> : ''}
            </form>
          </div>

          <div className='py-4 px-5 lg:px-6 w-full text-sm text-center bg-white text-gray-600'>
            Vous n'avez pas de compte&nbsp;?
            <Link to='/inscription' className='montserrat-semibold text-primary hover:text-secondary-1'>
              &nbsp;Inscrivez-vous.
            </Link>
          </div>
          <div className='pb-4 px-5 lg:px-6 w-full text-sm text-center bg-white text-gray-600'>
            Je n'ai pas reçu l'e-mail de vérification.
            <Link to='/renvoi-verification-email' className='montserrat-semibold text-primary hover:text-secondary-1'>
              &nbsp;Renvoyer
            </Link>
          </div>
        </div>
      </Fragment>
    );
  } else {
    return <Navigate to='/mon-espace' />;
  }
};

import { FunctionComponent, useState } from 'react';
import { ErrorApiResponse, GoodApiResponse } from '../../types/api';
import { useApi } from '../../providers/ApiProvider';
import toast from 'react-hot-toast';
import { XMarkIcon, AcademicCapIcon, CheckIcon, CalendarDaysIcon, ChevronRightIcon, MapPinIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import { LoadingAbsolute } from '../LoadingAbsolute';
import { Button, Tooltip } from '@material-tailwind/react';
import { DashboardSouhaitEnAttente, Groupe, SouhaitDirigeant } from '../../types/EntreprisesRessources';
import { ModalAcceptationSouhaitDirigeant } from '../Modales/Entreprise/ModalAcceptationSouhaitDirigeant';
import { ModalRefusSouhaitDirigeant } from '../Modales/Entreprise/ModalRefusSouhaitDirigeant';
import { handleError } from '../../utils/ErrorHandler';
import { ModalEffectuerDemande } from '../Modales/Entreprise/ModalEffectuerDemande';
import { DatesDisplay } from '../DatesDisplay';

/**
 * Ce composant est un encart du tableau de bord entreprises concernant les souhaits.
 *
 * @param souhaits - Souhaits en attente des salariés de l'entreprise.
 * @param handleIndex - Callback qui permet le rechargement des données du tableau de bord en entier.
 */
export const DashboardEntrepriseSouhaits: FunctionComponent<{ souhaits: DashboardSouhaitEnAttente[]; handleIndex: () => void }> = ({
  souhaits,
  handleIndex,
}) => {
  /**
   * On stocke si on est en train d'utiliser l'API ou non.
   */
  const [apiIsLoading, setApiIsLoading] = useState(false);

  /**
   * Permet de selectionner un souhait qui sera sera ensuite chargé dans une modale.
   */
  const [souhaitSelectionne, setSouhaitSelectionne] = useState<DashboardSouhaitEnAttente | null>(null);

  /**
   * Permet d'ouvrir une modale lorsque l'on souhaite accepter un souhait en temps que deirigeant.
   */
  const [modalAcceptationSouhaitEstVisible, setModalAcceptationSouhaitEstVisible] = useState<boolean>(false);

  /**
   * Permet d'ouvrir une modale lorsque l'on souhaite refuser un souhait en temps que deirigeant.
   */
  const [modalRefusSouhaitEstVisible, setModalRefusSouhaitEstVisible] = useState<boolean>(false);

  /**
   * Permet d'ouvrir une modale lorsque l'on souhaite refuser un souhait en temps que dirigeant.
   */
  const [modalEffectuerDemandeEstVisible, setModalEffectuerDemandeEstVisible] = useState<boolean>(false);

  /**
   * On a besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  /**
   * Ouvre une modale pour confirmer la validation du souhait sélectionné.
   *
   * @param souhait - Souhait selectionné, sur lequel on ouvre la modale.
   */
  const handleModaleAcceptationSouhait = (souhait: DashboardSouhaitEnAttente) => {
    setSouhaitSelectionne(souhait);
    setModalAcceptationSouhaitEstVisible(true);
  };

  /**
   * Cette méthode est passé en paramètre de la modale d'acceptation de souhait.
   * Elle permet d'accepter un souhait. et d'effectuer une demande dans la foulée si on le souhaite.
   *
   * @param effectuerDemande - Permet de déterminer s'il faut effectuer la demande dans la foulée de l'acceptation.
   */
  const handleAccepte = (effectuerDemande: boolean) => {
    setApiIsLoading(true);

    client
      .patch<GoodApiResponse>(`/entreprise/souhaits/${(souhaitSelectionne as DashboardSouhaitEnAttente).token}/accepte`)
      .then(() => {
        setModalAcceptationSouhaitEstVisible(false);
        handleIndex();
        toast.success(`Souhait accepté`);
        if (effectuerDemande) {
          setModalEffectuerDemandeEstVisible(true);
        }
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Ouvre une modale pour confirmer le refus du souhait sélectionné.
   *
   * @param souhait - Souhait selectionné, sur lequel on ouvre la modale.
   */
  const handleModaleRefusSouhait = (souhait: DashboardSouhaitEnAttente) => {
    setSouhaitSelectionne(souhait);
    setModalRefusSouhaitEstVisible(true);
  };

  /**
   * Permet de refuser le souhait en faisant l'appel API dédié.
   */
  const handleRefuse = () => {
    setApiIsLoading(true);

    client
      .patch<GoodApiResponse>(`/entreprise/souhaits/${(souhaitSelectionne as DashboardSouhaitEnAttente).token}/refuse`)
      .then(() => {
        handleIndex();
        toast.success(`Souhait refusé`);
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Cette méthode permet d'effectuer une demande
   *
   * @param token - Jeton de la session
   */
  const handleEffectuerDemande = (token?: Groupe['token']) => {
    setApiIsLoading(true);

    let url = `/entreprise/demandes/${(souhaitSelectionne as DashboardSouhaitEnAttente).token}`;

    if (token) {
      url += `/${token}`;
    }

    client
      .post<GoodApiResponse>(url)
      .then(() => {
        setModalEffectuerDemandeEstVisible(false);
        handleIndex();
        toast.success(`Demande envoyée`);
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  return (
    <div className='mx-auto mt-2 shadow-md rounded-md bg-white'>
      <div className='block sm:flex flex-row items-center justify-between p-5'>
        <h3 className='text-secondary-2 poppins-semibold text-center md:text-left'>Souhaits de formation</h3>
        <Link
          to='/entreprise/souhaits-de-formation'
          className='text-sm text-secondary-1/90 flex items-center justify-center sm:justify-start hover:text-primary'>
          Tout voir
          <ChevronRightIcon className='w-3 h-3' />
        </Link>
      </div>
      {souhaits && souhaits.length > 0 ? (
        souhaits.slice(0, 4).map((souhait) => (
          <Link key={souhait.token} to='#' className='block border-b border-secondary-1/10 mx-auto p-5 hover:bg-gray-100'>
            <div className='block sm:flex justify-between items-start gap-3 text-secondary-1'>
              <div className='flex flex-row gap-2 mb-5'>
                {souhait.formation.image_couverture ? (
                  <img
                    alt={souhait.formation.nom as string}
                    src={souhait.formation.image_couverture}
                    tabIndex={0}
                    className='rounded-md shadow-md object-cover w-10 h-10 aspect-1'
                    loading='lazy'
                  />
                ) : (
                  <div className={`rounded-md shadow-md object-cover w-10 h-10 bg-gray-700 flex items-center justify-center aspect-1`}>
                    <AcademicCapIcon className='h-7 w-7 text-white/50' />
                  </div>
                )}
                <h3 className='text-md montserrat-semibold leading-5'>{souhait.formation.nom}</h3>
              </div>
            </div>
            <div className='flex flex-row justify-between items-center text-xs'>
              <div className='flex items-center gap-1 text-secondary-1/80'>
                <span>
                  <CalendarDaysIcon className='w-5 h-5' />
                </span>
                {souhait.groupe && souhait.groupe.date_debut && souhait.groupe.date_fin ? (
                  <DatesDisplay date_start={souhait.groupe.date_debut} date_end={souhait.groupe.date_fin} />
                ) : (
                  <span className='font-medium pl-0'>Dates non définies</span>
                )}
              </div>
              <div className='flex items-center justify-end min-w-[90px] text-secondary-1/90'>
                <MapPinIcon className='w-5 h-5 flex-none' />
                <span>{souhait.groupe && souhait.groupe.site ? souhait.groupe.site.nom : 'Lieu non défini'}</span>
              </div>
            </div>
            <div className='flex flex-row items-center gap-3 justify-between mt-2'>
              <p className='text-sm poppins-medium text-secondary-1'>
                Proposé par {souhait.user?.prenom} {souhait.user?.nom}
              </p>
              <div className='flex gap-3 justify-center md:justify-start'>
                <Tooltip content='Refuser'>
                  <Button
                    variant='filled'
                    color='gray'
                    className='w-8 h-8 p-1.5 rounded-full block mx-auto text-center mr-0 bg-secondary-1 hover:shadow-lg hover:bg-secondary-2 active:bg-secondary-2'
                    onClick={() => handleModaleRefusSouhait(souhait)}>
                    <XMarkIcon className='h-5 w-5' strokeWidth={2} />
                  </Button>
                </Tooltip>
                <Tooltip content='Accepter'>
                  <Button
                    variant='filled'
                    className='w-8 h-8 p-1.5 rounded-full block mx-auto text-center mr-0 hover:bg-primary active:bg-orange-600'
                    onClick={() => handleModaleAcceptationSouhait(souhait)}>
                    <CheckIcon className='h-5 w-5' strokeWidth={2} />
                  </Button>
                </Tooltip>
              </div>
            </div>
          </Link>
        ))
      ) : (
        <p className='p-5 text-center text-sm font-medium text-secondary-1/70'>Aucune proposition de souhait reçue.</p>
      )}

      <ModalAcceptationSouhaitDirigeant
        visible={modalAcceptationSouhaitEstVisible}
        setVisible={setModalAcceptationSouhaitEstVisible}
        apiIsLoading={apiIsLoading}
        handleAccepte={handleAccepte}
      />

      <ModalRefusSouhaitDirigeant visible={modalRefusSouhaitEstVisible} setVisible={setModalRefusSouhaitEstVisible} handleRefuse={handleRefuse} />

      <ModalEffectuerDemande
        visible={modalEffectuerDemandeEstVisible}
        setVisible={setModalEffectuerDemandeEstVisible}
        apiIsLoading={apiIsLoading}
        handleEffectuerDemande={handleEffectuerDemande}
        groupes={
          souhaitSelectionne && ((souhaitSelectionne as unknown as SouhaitDirigeant).formation.groupes as unknown as Groupe[])
            ? ((souhaitSelectionne as unknown as SouhaitDirigeant).formation.groupes as unknown as Groupe[])
            : []
        }
        groupeSelectionne={souhaitSelectionne ? (souhaitSelectionne as unknown as SouhaitDirigeant).groupe : null}
      />
      {apiIsLoading ? <LoadingAbsolute /> : ''}
    </div>
  );
};

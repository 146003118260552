import { Fragment, FunctionComponent } from 'react';

/**
 * Composant ReadOnlyDateTime.
 *
 * @param label - Libellé du champ de saisie en lecture seule.
 * @param value - Valeur du champ de saisie en lecture seule.
 * @param error - Indique si le champ de saisie en lecture seule a une erreur.
 * @return Composant ReadOnlyDateTime.
 */
export const ReadOnlyDateTime: FunctionComponent<{
  error?: boolean;
  label?: string;
  value?: string | number | readonly string[] | null;
}> = ({ label, value, error = false }) => {
  return (
    <Fragment>
      {label && <label className={`text-xs text-gray-600 ${error ? 'text-red-500' : ''}`}>{label}</label>}
      <p
        className={`text-gray-600 text-sm font-normal items-center py-2 px-4 border border-gray-200 rounded cursor-not-allowed ${error ? 'text-red-500' : ''}`}>
        {value !== undefined && value !== null && value !== ''
          ? new Intl.DateTimeFormat('default', {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              second: 'numeric',
              hour12: false,
            }).format(new Date(value as string))
          : 'Non renseigné'}
      </p>
    </Fragment>
  );
};

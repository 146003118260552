/**
 * Thème Material Tailwind
 */
export const theme = {
  button: {
    defaultProps: {
      color: 'orange',
      className: 'rounded-full',
    },
  },
  chip: {
    defaultProps: {
      color: 'orange',
    },
  },
  dialog: {
    defaultProps: {
      color: 'orange',
    },
    styles: {
      base: {
        backdrop: {
          backgroundColor: 'bg-[#0D2235]',
          backgroundOpacity: 'bg-opacity-70',
          backdropFilter: 'backdrop-blur-sm',
        },
      },
    },
  },
  iconButton: {
    defaultProps: {
      color: 'orange',
    },
  },
  menu: {
    defaultProps: {
      color: 'orange',
    },
  },
  switch: {
    defaultProps: {
      color: 'orange',
    },
  },
  checkbox: {
    defaultProps: {
      color: 'orange',
    },
    styles: {
      base: {
        label: {
          className: 'text-sm font-medium text-white',
        },
      },
    },
  },
  radio: {
    defaultProps: {
      color: 'orange',
    },
    styles: {
      base: {
        label: {
          className: 'text-sm font-medium text-gray-700',
        },
      },
    },
  },
  input: {
    defaultProps: {
      color: 'orange',
    },
  },
  progress: {
    defaultProps: {
      color: 'orange',
      className: 'rounded-full h-2',
    },
  },
  cardHeader: {
    defaultProps: {
      color: 'orange',
    },
  },
  cardBody: {
    defaultProps: {
      color: 'orange',
    },
  },
  textarea: {
    defaultProps: {
      color: 'orange',
    },
  },
  select: {
    defaultProps: {
      color: 'orange',
    },
  },
};

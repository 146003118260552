import { XMarkIcon, CheckIcon } from '@heroicons/react/24/outline';
import { Button, Tooltip } from '@material-tailwind/react';
import { FunctionComponent, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useApi } from '../../providers/ApiProvider';
import { GoodApiResponse, ErrorApiResponse } from '../../types/api';
import { TypeProfilEnum, StatutAffiliationEnum } from '../../types/auth';
import { Entreprise } from '../../types/schematics';
import { typeProfil } from '../../utils/ChangeText';
import { handleError } from '../../utils/ErrorHandler';
import { Avatar } from '../Avatar';
import { Logo } from '../Logo';
import { ModalRefusEntreprise } from '../Modales/Admin/ModalRefusEntreprise';
import { ModalValidationEntreprise } from '../Modales/Admin/ModalValidationEntreprise';

/**
 * Ce composant est un encart du tableau de bord administrateur qui permet de valider ou non une entreprise.
 *
 * @param entreprises - Tableau d'entreprises en attention de validation.
 * @param handleIndex - Callback pour effectuer un appel de rechargement.
 */
export const DashboardAdminEntrepriseAValider: FunctionComponent<{
  entreprises: {
    id: number;
    raison_sociale: string;
    image_logo: string | null;
    siret: string;
    created_at: string | null;
    profils: {
      user: {
        id: number;
        nom: string;
        prenom: string;
        email: string;
        email_verified_at: string | null;
        image_avatar: string | null;
        created_at: string | null;
      };
      type_profil: TypeProfilEnum;
      statut_affiliation: StatutAffiliationEnum;
      created_at: string | null;
    }[];
  }[];
  handleIndex: () => void;
}> = ({ entreprises, handleIndex }) => {
  /**
   * On doit stocker l'ID de la ressource selectionneée pour gérer sa suppression dans la modale
   */
  const [entrepriseIdSelectionne, setEntrepriseIdSelectionne] = useState<Entreprise['id'] | null>(null);

  /**
   * Permet d'ouvrir une modale lorsque l'on souhaite refuser une entreprise.
   */
  const [modalRefusEstVisible, setModalRefusEstVisible] = useState<boolean>(false);

  /**
   * Permet d'ouvrir une modale lorsque l'on souhaite Valider une entreprise.
   */
  const [modalValidationEstVisible, setModalValidationEstVisible] = useState<boolean>(false);

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  const handleDecide = (entrepriseId: Entreprise['id'], estApprouvee: boolean) => {
    client
      .patch<GoodApiResponse>(`admin/entreprises/${entrepriseId}/${estApprouvee ? 'approuve' : 'refuse'}`)
      .then(() => {
        handleIndex();
        if (estApprouvee) {
          toast.success('Entreprise validée');
        } else {
          toast.success('Entreprise refusée');
        }
      })
      .catch((error: ErrorApiResponse<Entreprise>) => {
        handleError(error);
      });
  };

  /**
   * Ouvre une modale pour confirmer le refus de l'entreprise sélectionnée.
   * @param entrepriseId - ID de l'entreprise selectionnée, sur lequel on ouvre la modale.
   */
  const handleModalRefus = (entrepriseId: Entreprise['id']) => {
    setEntrepriseIdSelectionne(entrepriseId);
    setModalRefusEstVisible(true);
  };

  /**
   * Ouvre une modale pour confirmer la validation de l'entreprise sélectionnée.
   * @param entrepriseId - ID de l'entreprise selectionnée, sur lequel on ouvre la modale.
   */
  const handleModalValidation = (entrepriseId: Entreprise['id']) => {
    setEntrepriseIdSelectionne(entrepriseId);
    setModalValidationEstVisible(true);
  };
  return (
    <div>
      {entreprises.length > 0 ? (
        entreprises.map((entreprise) => (
          <div key={entreprise.id} className='flex flex-col lg:flex-row items-center gap-2 mb-2 p-2 border-b last:border-none'>
            <div className='basis-full md:basis-3/6 flex items-center gap-2'>
              <div className='w-11 h-11'>
                <Logo src={entreprise.image_logo} />
              </div>
              <div>
                <span className='block poppins-medium text-secondary-1'>{entreprise.raison_sociale}</span>
                <span className='block text-gray-600 text-xs font-normal'>
                  SIRET&nbsp;:&nbsp;
                  <a
                    className='text-primary underline'
                    href={`https://www.societe.com/cgi-bin/search?champs=${entreprise.siret}`}
                    target='_blank'
                    rel='noreferrer'>
                    {entreprise.siret}
                  </a>
                </span>

                <span className='block text-gray-600 text-xs font-normal'>
                  Créée le :{' '}
                  {entreprise.created_at
                    ? new Intl.DateTimeFormat('default', {
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: false,
                      }).format(new Date(entreprise.created_at as string))
                    : ''}
                </span>
              </div>
            </div>
            <div className='basis-full md:basis-2/6'>
              <div className='flex items-center justify-center md:justify-start -space-x-4'>
                {entreprise.profils.slice(0, 3).map((user) => (
                  <Tooltip key={user.user.id} content={`${user.user.prenom} ${user.user.nom} (${typeProfil(user.type_profil)})`}>
                    <Button variant='text' className='p-0 rounded-full hover:bg-transparent'>
                      <Avatar className='w-11 h-11 rounded-md border-2 border-white hover:z-10 focus:z-10' src={user.user.image_avatar} />
                    </Button>
                  </Tooltip>
                ))}
                {entreprise.profils.length > 4 && <span className='text-secondary-1/70 text-sm pl-5'>+ {entreprise.profils.length - 4}</span>}
              </div>
            </div>
            <div className='basis-1/6 flex flex-row justify-end gap-2'>
              <Tooltip content='Refuser'>
                <Button
                  variant='filled'
                  color='gray'
                  className='w-8 h-8 p-1.5 rounded-full block mx-auto text-center mr-0 bg-secondary-1 hover:shadow-lg hover:bg-secondary-2 active:bg-secondary-2'
                  onClick={() => handleModalRefus(entreprise.id)}>
                  <XMarkIcon className='h-5 w-5' strokeWidth={2} />
                </Button>
              </Tooltip>
              <Tooltip content='Valider'>
                <Button
                  variant='filled'
                  className='w-8 h-8 p-1.5 rounded-full block mx-auto text-center mr-0 hover:bg-primary active:bg-primary'
                  onClick={() => handleModalValidation(entreprise.id)}>
                  <CheckIcon className='h-5 w-5' strokeWidth={2} />
                </Button>
              </Tooltip>
            </div>
          </div>
        ))
      ) : (
        <p className='p-5 text-center text-sm poppins text-secondary-1/70'>Aucune entreprise à valider pour le moment.</p>
      )}
      <ModalRefusEntreprise
        visible={modalRefusEstVisible}
        setVisible={setModalRefusEstVisible}
        confirmer={() => handleDecide(entrepriseIdSelectionne as Entreprise['id'], false)}
      />
      <ModalValidationEntreprise
        visible={modalValidationEstVisible}
        setVisible={setModalValidationEstVisible}
        confirmer={() => handleDecide(entrepriseIdSelectionne as Entreprise['id'], true)}
      />
    </div>
  );
};

import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { InputText } from '../../../components/Inputs/InputText';
import { LoadingAbsolute } from '../../../components/LoadingAbsolute';
import { useApi } from '../../../providers/ApiProvider';
import { DropdownRessource, ModeFormulaire, Ressource } from '../../../types';
import { InputSwitch } from '../../../components/Inputs/InputSwitch';
import { AxiosResponse } from 'axios';
import { GoodApiResponse, ErrorApiResponse } from '../../../types/api';
import { Groupe, Formation, Region, Site } from '../../../types/schematics';
import { Config } from '../../../config/config';
import { Helmet } from 'react-helmet';
import { InputImage } from '../../../components/Inputs/InputImage';
import { ReadOnlyNumber } from '../../../components/Inputs/ReadOnlyNumber';
import { handleError } from '../../../utils/ErrorHandler';
import { ArrowUturnLeftIcon, MapPinIcon } from '@heroicons/react/24/outline';
import { Tab, TabPanel, Tabs, TabsBody, TabsHeader } from '../../../components/Onglets';
import { ImageHints } from '../../../components/ImageHints';
import { slugify } from '../../../utils/ChangeText';
import { ReadOnlyText } from '../../../components/Inputs/ReadOnlyText';
import { SubmitSave } from '../../../components/SubmitSave';

type Props = { modeFormulaire: ModeFormulaire };

/**
 * Ce composant doit afficher un formulaire qui à 3 modes de Sitenement. Les 2 principaux sont la création et l'édition.
 * On a également un mode lecture seule. L'idée c'est de gérer une partie du CRUD.
 * C'est dans ce composant que vont se faire les appels API pour créer, éditer et voir une ressource en particulier.
 *
 * @param modeFormulaire - Mode du formulaire (creer, editer et voir).
 * @returns JSX du composant
 */
export const AdminSiteFormScreen: FunctionComponent<Props> = ({ modeFormulaire }) => {
  // On initialise les données dont on va avoir besoin sur cette page

  /**
   * On stocke si on est en train d'utiliser l'API ou non.
   */
  const [apiIsLoading, setApiIsLoading] = useState(false);

  /**
   * On stocke les groupes possédés par le site.
   */
  const [ownedGroupes, setOwnedGroupes] = useState<Groupe[] | null>(null);

  /**
   * On stocke les formations possédés par le site.
   */
  const [ownedFormations, setOwnedFormations] = useState<Formation[] | null>(null);

  /**
   * On stocke les régions possédés par le site.
   */
  const [ownedRegions, setOwnedRegions] = useState<Region[] | null>(null);

  /**
   * On stocke les rôles possédés par l'utilisateur.
   */
  const [ownedGestionnaires, setOwnedGestionnaires] = useState<DropdownRessource[] | null>(null);

  /**
   * On stocke le nom afin de l'afficher en mode édition et voir.
   */
  const [nom, setNom] = useState<Site['nom']>('');

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  /**
   * Contient un état général des valeurs des champs du formulaire ainsi que les champs qui ont reçu une erreur lors de l'envoi du formulaire.
   */
  const [formInputs, setFormInputs] = useState<{ [K in keyof Site]: { value: Site[K]; error: boolean } }>({
    nom: { value: '', error: false },
    nom_commercial: { value: null, error: false },
    image_couverture: { value: null, error: false },
    slug: { value: '', error: false },
    est_visible: { value: false, error: false },
    rne: { value: null, error: false },
    code_naf: { value: null, error: false },
    siret: { value: null, error: false },
    adr1: { value: null, error: false },
    adr2: { value: null, error: false },
    adr3: { value: null, error: false },
    adr4: { value: null, error: false },
    code_postal: { value: null, error: false },
    ville: { value: null, error: false },
    pays: { value: null, error: false },
    geo_lat: { value: null, error: false },
    geo_lng: { value: null, error: false },
    yp_code: { value: null, error: false },
    created_at: { value: null, error: false },
  });

  const [imageCovertureString, setImageCovertureString] = useState<Site['image_couverture'] | null>(null);

  /**
   * Permet au composant de changer de page.
   */
  const navigate = useNavigate();

  const params = useParams();

  // On a besoin du useEffect lorsque l'on est en mode édition et lecture pour précharger le formulaire avec les données de la ressource.

  useEffect(() => {
    handleIndex();
    switch (modeFormulaire) {
      case ModeFormulaire.creer:
        break;
      case ModeFormulaire.voir:
      case ModeFormulaire.editer:
        if (params.id === undefined) {
          navigate('/admin/sites');
        }
        handleShow(parseInt(params.id as string));
        break;

      default:
        break;
    }
  }, [client]);

  /**
   * Retrouve Les ressources nécessaires pour afficher les dropdown et les stockent dans des liste déroulantes.
   *
   * @param indexParams - Paramètres de la recherche.
   */
  const handleIndex = () => {
    setApiIsLoading(true);
    //const baseParams: BaseIndexParams = { as_foreign: true, no_pagination: true };

    const promises = [];

    if (modeFormulaire !== ModeFormulaire.creer) {
      promises.push(client.get<GoodApiResponse<Groupe>>(`/admin/sites/${parseInt(params.id as string)}/groupes`) as Promise<AxiosResponse>);
      promises.push(client.get<GoodApiResponse<Formation>>(`/admin/sites/${parseInt(params.id as string)}/formations`) as Promise<AxiosResponse>);
      promises.push(client.get<GoodApiResponse<Region>>(`/admin/sites/${parseInt(params.id as string)}/regions`) as Promise<AxiosResponse>);
    }

    Promise.all(promises)
      .then((response) => {
        if (modeFormulaire !== ModeFormulaire.creer) {
          setOwnedGroupes(response[0].data.data as unknown as Groupe[]);
          setOwnedFormations(response[1].data.data as unknown as Formation[]);
          setOwnedRegions(response[2].data.data as unknown as Region[]);
        }
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Retrouve par le réseau la ressource liée à l'ID foruni et la met dans un état local.
   * Ne doit être utilisé qu'en mode édition et lecture seule.
   *
   * @param ressourceId - ID de la ressource dont on veut retrouver les informations.
   */
  const handleShow = (ressourceId: Ressource['id']) => {
    if (apiIsLoading === true) {
      return;
    }

    setApiIsLoading(true);
    client
      .get<GoodApiResponse<Site>>(`/admin/sites/${ressourceId}`)
      .then((reponse) => {
        const updatedInputs = Object.keys(reponse.data.data).reduce((acc, key) => {
          if (key === 'image_couverture' && reponse.data.data.image_couverture !== null) {
            setImageCovertureString(reponse.data.data.image_couverture);
          }
          return { ...acc, [key]: { value: reponse.data.data[key as keyof Site], error: false } };
        }, formInputs);
        setFormInputs(updatedInputs);
        setNom(updatedInputs.nom.value);
        setOwnedGestionnaires((reponse.data.data as Site & { users: DropdownRessource[] }).users);
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error, () => {
          navigate('/admin/sites');
        });
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Poste par le réseau une ressource crée dans le formulaire via un état local.
   * Ne doit être utilisé qu'en mode création.
   */
  const handleStore = () => {
    setApiIsLoading(true);

    let ressource: Partial<Site> = {};
    Object.keys(formInputs).forEach((key) => {
      if (formInputs[key as keyof Site]?.value !== null && !(key === 'image_couverture' && formInputs.image_couverture.value === imageCovertureString)) {
        ressource = { ...ressource, [key]: formInputs[key as keyof Site]?.value };
      }
    });
    client
      .post<GoodApiResponse<Site>, Site>('/admin/sites', ressource as Site)
      .then(() => {
        toast.success(`Création éffectuée`);
        navigate('/admin/sites');
      })
      .catch((error: ErrorApiResponse<Site>) => {
        handleError(error, () => {
          let object = formInputs;
          Object.keys(error.response.data.errors).forEach((key) => {
            if (error.response.data.errors[key as keyof Site]) {
              object = { ...object, [key]: { value: formInputs[key as keyof Site]?.value, error: true } };
            }
          }, formInputs);
          setFormInputs(object);
        });
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Met à jour, par le réseau, une ressource à partir du formulaire via un état local.
   * Ne doit être utilisé qu'en mode édition.
   *
   * @param ressourceId - ID de la ressource dont on veut modifier les informations.
   */
  const handleUpdate = (ressourceId: Ressource['id']) => {
    setApiIsLoading(true);

    let ressource: Partial<Site> = {};
    Object.keys(formInputs).forEach((key) => {
      if (formInputs[key as keyof Site]?.value !== null && !(key === 'image_couverture' && formInputs.image_couverture.value === imageCovertureString)) {
        ressource = { ...ressource, [key]: formInputs[key as keyof Site]?.value };
      }
    });

    client
      .patch<GoodApiResponse<Site>>(`/admin/sites/${ressourceId}`, ressource)
      .then(() => {
        toast.success(`Modification éffectuée`);
        navigate('/admin/sites');
      })
      .catch((error: ErrorApiResponse<Site>) => {
        handleError(error, () => {
          let object = formInputs;
          Object.keys(error.response.data.errors).forEach((key) => {
            if (error.response.data.errors[key as keyof Site]) {
              object = { ...object, [key]: { value: formInputs[key as keyof Site]?.value, error: true } };
            }
          }, formInputs);
          setFormInputs(object);
        });
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Cette méthode permet de gérer l'envoi du formulaire en Site du mode dans lequel on se trouve.
   * L'envoi ne fait rien si on est en mode voir.
   *
   * @param event - Évenement de l'envoie.
   */
  const handleSubmit = (event: { preventDefault: () => void }): void => {
    event.preventDefault();

    if (apiIsLoading === true) {
      return;
    }

    if (modeFormulaire === ModeFormulaire.creer) {
      handleStore();
    }

    if (modeFormulaire === ModeFormulaire.editer) {
      if (params.id !== undefined) {
        handleUpdate(parseInt(params.id as string));
      }
    }
  };

  /**
   * Cette méthode permet de gérer les changements à l'interieur des champs du formulaire et de gérer les valeurs des champs via un état local.
   *
   * @param field - Champs du formulaire dont on doit stocker la valeur et s'il est en erreur.
   */
  const handleInputChange = (field: keyof Site) => {
    return (event: { target: { value: string } }) => {
      setFormInputs((prev) => ({
        ...prev,
        [field]: { value: field === 'slug' ? slugify(event.target.value) : event.target.value, error: false },
      }));
    };
  };

  const handleSwitchChange = (field: keyof Site) => {
    setFormInputs((prev) => ({
      ...prev,
      [field]: { value: !prev[field as keyof Site]?.value, error: false },
    }));
  };

  const handleImageChange = (field: keyof Site) => {
    return (imageString: string) => {
      setFormInputs((prev) => ({
        ...prev,
        [field]: { value: imageString, error: false },
      }));
    };
  };

  /**
   * Permet d'écrire le mot correspondant au mode de fomulaire. Ce mot s'affiche au début du formulaire juste à coté de la ressource.
   *
   * @param modeFormulaire - Mode du formulaire (creer, editer et voir).
   * @returns Mot à afficher correspondant au mode de formulaire.
   * @throws Lance une erreur si aucun mode formulaire n'est fourni.
   */
  const afficheModeFormulaire = (modeFormulaire: ModeFormulaire) => {
    switch (modeFormulaire) {
      case ModeFormulaire.creer:
        return 'Création';
      case ModeFormulaire.editer:
        return 'Édition';
      case ModeFormulaire.voir:
        return 'Voir';

      default:
        throw new Error("Vous n'avez pas choisi un mode de formulaire adéquat.");
    }
  };

  /**
   * Paramètres tabs
   * @constant
   */
  const data = [
    {
      label: 'Sessions',
      value: 'groupes',
    },
    {
      label: 'Formations',
      value: 'formations',
    },
    {
      label: 'Régions',
      value: 'regions',
    },
    {
      label: 'Gestionnaires',
      value: 'gestionnaires',
    },
  ];

  if (modeFormulaire !== ModeFormulaire.creer && (ownedGroupes === null || ownedFormations === null || ownedRegions === null)) {
    return <Fragment>Chargement...</Fragment>;
  }

  return (
    <Fragment>
      <Helmet>
        <title>
          {afficheModeFormulaire(modeFormulaire)} site - {Config.app_label}
        </title>
      </Helmet>

      <div className='px-4 md:px-5 py-4 md:py-3 bg-gray-100 rounded-tl-lg rounded-tr-lg'>
        <div className='flex w-11/12 mx-auto xl:w-full xl:mx-0 justify-between'>
          <div className='flex flex-row items-center gap-3'>
            <Link className='flex-none' to='/admin/sites'>
              <button className='mr-1 py-1 px-6 flex flex-row items-center rounded-full bg-secondary-1/10 text-gray-700'>
                <ArrowUturnLeftIcon className='h-4 w-4 inline mr-2 flex-none' />
                <span>Retour à la liste</span>
              </button>
            </Link>
            <p className='text-base sm:text-lg md:text-xl poppins-bold leading-normal text-secondary-1 pl-4'>{`${afficheModeFormulaire(
              modeFormulaire,
            )} centre de formation ${modeFormulaire !== ModeFormulaire.creer ? nom : ''}`}</p>
          </div>

          <Link to={`/sites/${formInputs.slug.value}`} className='flex-none ml-3' target='_blank'>
            <button className='mr-1 py-1 px-6 flex flex-row items-center rounded-full bg-secondary-1 text-white'>
              <MapPinIcon className='h-4 w-4 inline mr-2 flex-none' />
              <span>Voir la page du centre de formation</span>
            </button>
          </Link>
        </div>
      </div>

      <Tabs value='general'>
        <TabsHeader>
          <Tab value='general'>Général</Tab>
          {modeFormulaire !== ModeFormulaire.creer &&
            data.map(({ label, value }) => (
              <Tab key={value} value={value}>
                {label}
              </Tab>
            ))}
        </TabsHeader>
        <TabsBody>
          <TabPanel value='general'>
            <form className='relative' onSubmit={handleSubmit}>
              <div className='bg-white p-5 rounded-b-lg'>
                <div className='container mx-auto bg-white rounded'>
                  <fieldset className='pb-7 mb-7 border-b'>
                    <legend className='block text-left mb-3 text-xl text-secondary-1'>Informations</legend>
                    <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-2 items-center'>
                      <div>
                        <ReadOnlyText label='Nom' value={formInputs.nom.value as string} error={formInputs.nom.error} />
                      </div>
                      <div>
                        <InputText
                          label='Nom commercial'
                          value={formInputs.nom_commercial.value as string}
                          onChange={handleInputChange('nom_commercial')}
                          error={formInputs.nom_commercial.error}
                        />
                      </div>
                      <div>
                        <InputText label='Slug' value={formInputs.slug.value as string} error={formInputs.slug.error} />
                      </div>
                      <div>
                        <ReadOnlyText label='RNE' value={formInputs.rne.value as string} error={formInputs.rne.error} />
                      </div>
                      <div>
                        <ReadOnlyText label='Code NAF' value={formInputs.code_naf.value as string} error={formInputs.code_naf.error} />
                      </div>
                      <div>
                        <ReadOnlyText label='Siret' value={formInputs.siret.value as string} error={formInputs.siret.error} />
                      </div>
                      <div>
                        <ReadOnlyNumber label='YParéo CodeSite' value={formInputs.yp_code.value} error={formInputs.yp_code.error} />
                      </div>
                    </div>
                  </fieldset>
                  <fieldset className='pb-7 mb-7 border-b'>
                    <legend className='block text-left mb-3 text-xl text-secondary-1'>Adresse</legend>
                    <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-2 items-center'>
                      <div>
                        <ReadOnlyText label='Adresse 1' value={formInputs.adr1.value as string} error={formInputs.adr1.error} />
                      </div>
                      <div>
                        <ReadOnlyText label='Adresse 2' value={formInputs.adr2.value as string} error={formInputs.adr2.error} />
                      </div>
                      <div>
                        <ReadOnlyText label='Adresse 3' value={formInputs.adr3.value as string} error={formInputs.adr3.error} />
                      </div>
                      <div>
                        <ReadOnlyText label='Adresse 4' value={formInputs.adr4.value as string} error={formInputs.adr4.error} />
                      </div>
                      <div>
                        <ReadOnlyText label='Code postal' value={formInputs.code_postal.value as string} error={formInputs.code_postal.error} />
                      </div>
                      <div>
                        <ReadOnlyText label='Ville' value={formInputs.ville.value as string} error={formInputs.ville.error} />
                      </div>
                      <div>
                        <ReadOnlyText label='Pays' value={formInputs.pays.value as string} error={formInputs.pays.error} />
                      </div>
                      <div>
                        <ReadOnlyText label='Latitude' value={formInputs.geo_lat.value as number} error={formInputs.geo_lat.error} />
                      </div>
                      <div>
                        <ReadOnlyText label='Longitude' value={formInputs.geo_lng.value as number} error={formInputs.geo_lng.error} />
                      </div>
                    </div>
                  </fieldset>
                  <fieldset className='pb-7 mb-7 border-b'>
                    <legend className='block text-left mb-3 text-xl text-secondary-1'>Image</legend>
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-2'>
                      <div>
                        <InputImage label='Image de couverture' imageUrl={imageCovertureString as string} onChange={handleImageChange('image_couverture')} />
                      </div>
                      <div className='pt-6'>
                        <ImageHints />
                      </div>
                    </div>
                  </fieldset>
                  <fieldset className='pb-7 mb-7'>
                    <legend className='block text-left mb-3 text-xl text-secondary-1'>Paramètres</legend>
                    <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-2 items-center'>
                      <div>
                        <InputSwitch
                          label='Est visible'
                          checked={formInputs.est_visible.value !== null ? formInputs.est_visible.value : false}
                          onChange={() => handleSwitchChange('est_visible')}
                        />
                      </div>
                    </div>
                  </fieldset>
                </div>

                <div className='container mx-auto w-11/12 xl:w-full'>
                  <div className='w-full py-4 sm:px-0 bg-white flex justify-end'>{modeFormulaire !== ModeFormulaire.voir && <SubmitSave />}</div>
                </div>
              </div>
              {apiIsLoading ? <LoadingAbsolute /> : ''}
            </form>
          </TabPanel>
          {modeFormulaire !== ModeFormulaire.creer && (
            <TabPanel value='groupes'>
              <div className='bg-white w-full overflow-x-auto'>
                {ownedGroupes && ownedGroupes.length > 0 ? (
                  <table className='table-fixed min-w-full bg-white rounded-b-lg'>
                    <thead className='text-sm text-gray-500 text-left border-b'>
                      <tr>
                        <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Nom</th>
                        <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Date de début</th>
                        <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Date de fin</th>
                        <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Capacité min</th>
                        <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Nombre d'aprenants</th>
                        <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Capacité max</th>
                        <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>prix de vente</th>
                        <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Unités de facturation</th>
                        <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Nom Unités de facturation</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ownedGroupes.map((groupe) => (
                        <tr key={groupe.id} className=' border-gray-100 border-b hover:bg-gray-100'>
                          <td className='px-4 py-3 text-gray-800 text-sm'>{groupe.nom_commercial}</td>
                          <td className='px-4 py-3 text-gray-800 text-sm'>
                            {groupe.date_debut
                              ? new Intl.DateTimeFormat('default', {
                                  year: 'numeric',
                                  month: 'numeric',
                                  day: 'numeric',
                                }).format(new Date(groupe.date_debut as string))
                              : ''}
                          </td>
                          <td className='px-4 py-3 text-gray-800 text-sm'>
                            {groupe.date_fin
                              ? new Intl.DateTimeFormat('default', {
                                  year: 'numeric',
                                  month: 'numeric',
                                  day: 'numeric',
                                }).format(new Date(groupe.date_fin as string))
                              : ''}
                          </td>
                          <td className='px-4 py-3 text-gray-800 text-sm text-right'>{groupe.capacite_min}</td>
                          <td className='px-4 py-3 text-gray-800 text-sm text-right'>{groupe.nb_apprenants_actifs}</td>
                          <td className='px-4 py-3 text-gray-800 text-sm text-right'>{groupe.capacite_max}</td>
                          <td className='px-4 py-3 text-gray-800 text-sm text-right'>
                            {groupe.prix_vente !== undefined && groupe.prix_vente !== null
                              ? new Intl.NumberFormat('default', { style: 'currency', currency: 'EUR' }).format(groupe.prix_vente / 100)
                              : 'Non renseigné'}
                          </td>
                          <td className='px-4 py-3 text-gray-800 text-sm text-right'>{groupe.unites_facturation}</td>
                          <td className='px-4 py-3 text-gray-800 text-sm'>{groupe.nom_unites_facturation}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div className='text-center px-4 py-7'>Il n'y pas de session pour ce centre de formation.</div>
                )}
              </div>
            </TabPanel>
          )}
          {modeFormulaire !== ModeFormulaire.creer && (
            <TabPanel value='formations'>
              <div className='bg-white w-full overflow-x-auto'>
                {ownedFormations && ownedFormations.length > 0 ? (
                  <table className='table-fixed min-w-full bg-white rounded-b-lg'>
                    <thead className='text-sm text-gray-500 text-left border-b'>
                      <tr>
                        <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Nom</th>
                        <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Slug</th>
                        <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Niveau</th>
                        <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Durée en heures</th>
                        <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Prix de vente</th>
                        <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Mode d'évaluation</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ownedFormations.map((formation) => (
                        <tr key={formation.id} className=' border-gray-100 border-b hover:bg-gray-100'>
                          <td className='px-4 py-3 text-gray-800 text-sm'>{formation.nom_commercial}</td>
                          <td className='px-4 py-3 text-gray-800 text-sm'>{formation.slug}</td>
                          <td className='px-4 py-3 text-gray-800 text-sm text-right'>{formation.niveau}</td>
                          <td className='px-4 py-3 text-gray-800 text-sm text-right'>{formation.duree_heures}</td>
                          <td className='px-4 py-3 text-gray-800 text-sm text-right'>
                            {formation.prix_vente !== undefined && formation.prix_vente !== null
                              ? new Intl.NumberFormat('default', { style: 'currency', currency: 'EUR' }).format(formation.prix_vente / 100)
                              : 'Non renseigné'}
                          </td>
                          <td className='px-4 py-3 text-gray-800 text-sm'>{formation.mode_evaluation}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div className='text-center px-4 py-7'>Il n'y pas de formation pour ce centre de formation.</div>
                )}
              </div>
            </TabPanel>
          )}
          {modeFormulaire !== ModeFormulaire.creer && (
            <TabPanel value='regions'>
              <div className='bg-white w-full overflow-x-auto'>
                {ownedRegions && ownedRegions.length > 0 ? (
                  <table className='table-fixed min-w-full bg-white rounded-b-lg'>
                    <thead className='text-sm text-gray-500 text-left border-b'>
                      <tr>
                        <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Nom</th>
                        <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Slug</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ownedRegions.map((region) => (
                        <tr key={region.id} className=' border-gray-100 border-b hover:bg-gray-100'>
                          <td className='px-4 py-3 text-gray-800 text-sm'>{region.nom_commercial}</td>
                          <td className='px-4 py-3 text-gray-800 text-sm'>{region.slug}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div className='text-center px-4 py-7'>Il n'y pas de region pour ce centre de formation.</div>
                )}
              </div>
            </TabPanel>
          )}
          {modeFormulaire !== ModeFormulaire.creer && (
            <TabPanel value='gestionnaires'>
              <div className='bg-white p-5 rounded w-full overflow-x-auto'>
                <fieldset className='pb-7 mb-7'>
                  <legend className='block text-left mb-3 text-xl text-secondary-1'>Gestionnaires</legend>
                  {ownedGestionnaires ? (
                    <ul>
                      {ownedGestionnaires.map((ownedGestionnaire) => (
                        <li
                          key={ownedGestionnaire.id}
                          className='px-4 py-3 text-gray-800 text-sm cursor-pointer'
                          onClick={() => navigate(`/admin/users/editer/${ownedGestionnaire.id}`)}>
                          {ownedGestionnaire.label}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <div className='text-center px-4 py-7'>Il n'y pas de gestionnaire pour ce centre de formation.</div>
                  )}
                </fieldset>
              </div>
            </TabPanel>
          )}
        </TabsBody>
      </Tabs>
    </Fragment>
  );
};

import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { DashboardEntrepriseDemandes } from '../../../components/Dashboard/DashboardEntrepriseDemandes';
import { DashboardEntrepriseSessions } from '../../../components/Dashboard/DashboardEntrepriseSessions';
import { DashboardEntrepriseSouhaits } from '../../../components/Dashboard/DashboardEntrepriseSouhaits';
import { LoadingAbsolute } from '../../../components/LoadingAbsolute';
import { useApi } from '../../../providers/ApiProvider';
import { EntrepriseDashboard, SalariesDashboard, ErrorApiResponse } from '../../../types/api';
import { DashboardInscription, DashboardInscriptions } from '../../../types/EntreprisesRessources';
import { DashboardMiniAgenda } from '../../../components/Dashboard/DashboardMiniAgenda';
import { handleError } from '../../../utils/ErrorHandler';
import { DashboardEntrepriseDevis } from '../../../components/Dashboard/DashboardEntrepriseDevis';
import { DashboardEntrepriseConvocations } from '../../../components/Dashboard/DashboardEntrepriseConvocations';
import { DashboardEntrepriseFactures } from '../../../components/Dashboard/DashboardEntrepriseFactures';
import { CtaEntrepriseNonValide } from '../../../components/CtaEntrepriseNoneValide';

/**
 * Ce composant affiche le tableau de bord entreprise
 */
export const EntrepriseDashboardScreen: FunctionComponent = () => {
  /**
   * On stocke si on est en train d'utiliser l'API ou non.
   */
  const [apiIsLoading, setApiIsLoading] = useState(false);

  const [entrepriseInvalide, setEntrepriseInvalide] = useState(false);

  /**
   * Stocke les groupe dans un état local.
   */
  const [entrepriseDashboard, setEntrepriseDashboard] = useState<EntrepriseDashboard | null>(null);

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  /**
   * Charge les données du tableau de bord au chargenment du composant.
   */
  useEffect(() => {
    handleIndex();
  }, []);

  /**
   * Retrouve des données de L'API et les stocke dans un état local.
   */
  const handleIndex = () => {
    setApiIsLoading(true);

    client
      .get<{
        data: EntrepriseDashboard;
      }>('/entreprise/dashboard')
      .then((response) => {
        let newInscriptions: DashboardInscriptions[] = [];

        response.data.data.inscriptions.forEach((inscription) => {
          const newInscriptionsIndex = newInscriptions.findIndex((newInscription) => newInscription.token === inscription.token);
          if (newInscriptionsIndex === -1) {
            newInscriptions = [...newInscriptions, { ...inscription, users: [(inscription as unknown as DashboardInscription).user] }];
          } else {
            newInscriptions = [
              ...newInscriptions.slice(0, newInscriptionsIndex),
              {
                ...newInscriptions[newInscriptionsIndex],
                users: [...newInscriptions[newInscriptionsIndex].users, (inscription as unknown as DashboardInscription).user],
              },
              ...newInscriptions.slice(newInscriptionsIndex + 1),
            ];
          }
        });

        setEntrepriseDashboard({ ...response.data.data, inscriptions: newInscriptions });
      })
      .catch((error: ErrorApiResponse<SalariesDashboard>) => {
        if (error.response && parseInt(error.response.status) === 403) {
          setEntrepriseInvalide(true);
        } else {
          handleError(error);
        }
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  return (
    <div className='flex flex-wrap lg:flex-nowrap lg:flex-row gap-5 mt-11'>
      {entrepriseInvalide === true ? (
        <div className='basis-full'>
          <CtaEntrepriseNonValide />
        </div>
      ) : (
        <Fragment>
          <div className='basis-full lg:basis-1/2 xl:basis-8/12 order-2 lg:order-1'>
            <DashboardEntrepriseSessions inscriptions={entrepriseDashboard ? entrepriseDashboard.inscriptions : []} />
            <DashboardEntrepriseDemandes demandes={entrepriseDashboard ? entrepriseDashboard.demandes : []} />
            <DashboardEntrepriseConvocations convocations={entrepriseDashboard ? entrepriseDashboard.convocations : []} />
            <DashboardEntrepriseDevis devis={entrepriseDashboard ? entrepriseDashboard.devis_en_attente : []} />
            <DashboardEntrepriseFactures factures={entrepriseDashboard ? entrepriseDashboard.factures_en_attente : []} />
          </div>
          <div className='basis-full lg:basis-1/2 xl:basis-4/12 order-1 lg:order-2'>
            <DashboardEntrepriseSouhaits souhaits={entrepriseDashboard ? entrepriseDashboard.souhaits_en_attente : []} handleIndex={handleIndex} />
            <DashboardMiniAgenda
              datesSessions={
                entrepriseDashboard &&
                entrepriseDashboard.inscriptions
                  .filter((inscription) => inscription && inscription.date_debut && inscription.date_fin)
                  .map((inscription) => ({ dateDebut: new Date(inscription.date_debut as string), dateFin: new Date(inscription.date_fin as string) }))
              }
            />
          </div>
        </Fragment>
      )}
      {apiIsLoading ? <LoadingAbsolute /> : ''}
    </div>
  );
};

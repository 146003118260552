import { Fragment, FunctionComponent, useState } from 'react';
import { Helmet } from 'react-helmet';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { LoadingAbsolute } from '../../../components/LoadingAbsolute';
import { ModalSuppression } from '../../../components/Modales/Admin/ModalSuppression';
import { RessourceList } from '../../../components/RessourceList';
import { Config } from '../../../config/config';
import { useApi } from '../../../providers/ApiProvider';
import { Ressource, ForeignRessources } from '../../../types';
import { PaginatedApiResponse, IndexParams, ErrorApiResponse, GoodApiResponse } from '../../../types/api';
import { RessourceListColumn, SortDirection } from '../../../types/ressourceList';
import { Octa } from '../../../types/schematics';
import { handleError } from '../../../utils/ErrorHandler';

/**
 * Ce composant contient la liste de ressource ainsi que les méthode pour gérer cette liste.
 */
export const AdminOctaListScreen: FunctionComponent = () => {
  /**
   * On stocke si on est en train d'utiliser l'API ou non.
   */
  const [apiIsLoading, setApiIsLoading] = useState(false);

  /**
   * Permet d'ouvrir une modale lorsque l'on souhaite supprimer une ressource.
   */
  const [modalEstVisible, setModalEstVisible] = useState<boolean>(false);

  /**
   * On doit stocker l'ID de la ressource selectionneée pour gérer sa suppression dans la modale
   */
  const [ressourceSelectionnee, setRessourceSelectionnee] = useState<Ressource['id']>();

  /**
   * Contient une liste de ressources. Ces ressource arrivent par le réseau.
   */
  const [paginatedApiData, setPaginatedApiData] = useState<PaginatedApiResponse<Octa> | null>(null);

  /**
   * Contient une liste de colonnes pour le tableau. Ces ressource arrivent par le réseau.
   */
  const [apiColumns, setApiColumns] = useState<RessourceListColumn<Octa>[] | null>(null);

  /**
   * Contient une liste de clées étrangères avec le libellé à afficher. Ces ressource arrivent par le réseau.
   */
  const [apiForeigns, setApiForeigns] = useState<ForeignRessources | null>(null);

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  /**
   * Permet au composant de changer de page.
   */
  const navigate = useNavigate();

  /**
   * Retrouve des données paginées de L'API et les stocke dans un état local.
   *
   * @param indexParams - Paramètres de la recherche.
   */
  const handleIndex = (indexParams?: Partial<IndexParams>) => {
    setApiIsLoading(true);
    client
      .get<PaginatedApiResponse<Octa>, IndexParams>('/admin/octas', { params: indexParams })
      .then((reponse) => {
        setPaginatedApiData(reponse.data);
        if (apiColumns === null) {
          setApiColumns(reponse.data.columns as RessourceListColumn<Octa>[]);
        }
        if (apiForeigns === null) {
          setApiForeigns(reponse.data.foreigns as ForeignRessources);
        }
      })
      .catch((error: ErrorApiResponse<Octa>) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Supprime une ressource qui à l'ID fournie.
   *
   * @param ressourceId - ID de la ressource que l'on veut supprimer.
   */
  const handleDestroy = (ressourceId: Ressource['id']) => {
    if (apiIsLoading === true) {
      return;
    }

    setApiIsLoading(true);
    client
      .delete<GoodApiResponse<Octa>>(`/admin/octas/${ressourceId}`)
      .then(() => {
        if (paginatedApiData === null) {
          throw new Error('Une erreur est survenue.');
        }
        setPaginatedApiData({ ...paginatedApiData, data: paginatedApiData.data.filter((ressource) => ressource.id !== ressourceId) });
        toast.success(`Suppression éffectuée`);
      })
      .catch((error: ErrorApiResponse<Octa>) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Cette méthode gére la modale pour la suppression d'une ressource
   * @param ressourceId
   */
  const handleModal = (ressourceId: Ressource['id']) => {
    setModalEstVisible(true);
    setRessourceSelectionnee(ressourceId);
  };

  /**
   * Cette methode permet d'envoyer l'utilisateur sur un formulaire prévu pour ajouter une ressource.
   */
  const handleStore = () => {
    navigate(`/admin/octas/creer`);
  };

  /**
   * Cette méthode envoie l'utilisateur sur un formulaire pour mettre à jour la ressource ciblé avec son ID.
   * @param ressourceId - ID de la ressource à mettre à jour.
   */
  const handleUpdate = (ressourceId: Ressource['id']) => {
    navigate(`/admin/octas/editer/${ressourceId}`);
  };

  return (
    <Fragment>
      <Helmet>
        <title>Octas - {Config.app_label}</title>
      </Helmet>
      <div className='relative'>
        <RessourceList
          name={'octa'}
          labels={'Octas'}
          defaultSortBy={'id'}
          defaultSortDirection={SortDirection.ASC}
          paginatedApiData={paginatedApiData as PaginatedApiResponse<Ressource>}
          columns={apiColumns as RessourceListColumn[]}
          foreigns={apiForeigns as ForeignRessources}
          handleIndex={handleIndex}
          onAdd={handleStore}
          onUpdate={handleUpdate}
          onDelete={handleModal}
        />
        {apiIsLoading ? <LoadingAbsolute /> : ''}
        <ModalSuppression visible={modalEstVisible} setVisible={setModalEstVisible} confirmer={() => handleDestroy(ressourceSelectionnee)} />
      </div>
    </Fragment>
  );
};

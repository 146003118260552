import { Entreprise as EntrepriseDb, Profil as ProfilDb, Role as RoleDb, User as UserDb } from './schematics';

export enum ProfilInscription {
  salarie = 'Particulier(e)',
  entreprise = 'Entreprise',
}

export enum RoleEnum {
  admin = 'admin',
  dev = 'dev',
  gestionnaireCentre = 'gestionnaire_centre',
  standard = 'standard',
}

export enum StatutAffiliationEnum {
  affilie = 'affilie',
  suspendu = 'suspendu',
  revoque = 'revoque',
}

export enum TypeProfilEnum {
  delegue = 'delegue',
  dirigeant = 'dirigeant',
  salarie = 'salarie',
}

export type UserLocal = {
  token: string;
  nom: UserDb['nom'];
  prenom: UserDb['prenom'];
  email: UserDb['email'];
  image_avatar: UserDb['image_avatar'];
  roles: RoleDb['name'][];
  profils: Profil[];
};

export type Profil = {
  id: ProfilDb['id'];
  type_profil: TypeProfilEnum;
  entreprise: {
    enseigne: string | null;
    raison_sociale: string;
    image_logo: string | null;
  };
  statut_affiliation: StatutAffiliationEnum;
  created_at: string;
  updated_at: string;
};

export type BaseProfil = {
  id: ProfilDb['id'];
  type_profil: TypeProfilEnum;
  statut_affiliation: StatutAffiliationEnum;
  created_at: string;
  updated_at: string;
};

export type ProfilUser = BaseProfil & {
  entreprise: EntrepriseInfo;
};

export type ProfilEntreprise = BaseProfil & {
  user: UserInfo;
  couleur: ProfilDb['couleur'];
};

export type RegisterApprenant = {
  nom: string;
  prenom: string;
  date_naissance: string;
  email: string;
  password: string;
};

export type RegisterEntreprise = RegisterApprenant & {
  raison_sociale: string;
  siret: string;
};

export type LoginData = {
  email: string;
  password: string;
  device_name: string;
};

export type LoginResponse = {
  authorization: {
    token: string;
  };
  user: {
    nom: UserDb['nom'];
    prenom: UserDb['prenom'];
    email: UserDb['email'];
    image_avatar: UserDb['image_avatar'];
  };
  roles: RoleDb['name'][];
  profils: Profil[];
};

export type UserInfo = {
  nom: UserDb['nom'];
  prenom: UserDb['prenom'];
  date_naissance: UserDb['date_naissance'];
  image_avatar: UserDb['image_avatar'];
  email: UserDb['email'];
  email_verified_at: UserDb['email_verified_at'];
};

export type EntrepriseInfo = {
  raison_sociale: EntrepriseDb['raison_sociale'];
  enseigne: EntrepriseDb['enseigne'];
  image_logo: EntrepriseDb['image_logo'];
  adr1: EntrepriseDb['adr1'];
  adr2: EntrepriseDb['adr2'];
  adr3: EntrepriseDb['adr3'];
  adr4: EntrepriseDb['adr4'];
  code_postal: EntrepriseDb['code_postal'];
  ville: EntrepriseDb['ville'];
  pays: EntrepriseDb['pays'];
  siret: EntrepriseDb['siret'];
  est_approuvee: EntrepriseDb['est_approuvee'];
};

import { ArrowUturnLeftIcon } from '@heroicons/react/20/solid';
import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

/**
 * Ce composant est l'en-tête des formulaires pour les ressources.
 *
 * @param text - C'est le titre de la page en quelque sorte.
 * @param returnUrl - URL pour le bouton retour.
 */
export const HeaderForm: FunctionComponent<{ text: string; returnUrl: string }> = ({ text, returnUrl }) => (
  <div className='px-4 py-4 md:py-3 bg-gray-100 rounded-tl rounded-tr'>
    <div className='flex w-11/12 mx-auto xl:w-full xl:mx-0 items-center'>
      <Link className='flex-none' to={returnUrl}>
        <button className='mr-1 py-1 px-6 flex flex-row items-center rounded-full bg-secondary-1/10 text-gray-700'>
          <ArrowUturnLeftIcon className='h-4 w-4 inline mr-2 flex-none' />
          <span>Retour à la liste</span>
        </button>
      </Link>
      <p className='text-base sm:text-lg md:text-xl poppins-bold leading-normal text-secondary-1 pl-4'>{text}</p>
    </div>
  </div>
);

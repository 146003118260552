import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { Button } from '@material-tailwind/react';
import { useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { ConstStatutAffiliation } from '../../../components/ConstStatutAffiliation';
import { ConstTypeProfil } from '../../../components/ConstTypeProfil';
import { InputEmail } from '../../../components/Inputs/InputEmail';
import { InputPassword } from '../../../components/Inputs/InputPassword';
import { InputSwitch } from '../../../components/Inputs/InputSwitch';
import { InputText } from '../../../components/Inputs/InputText';
import { LoadingAbsolute } from '../../../components/LoadingAbsolute';
import { useApi } from '../../../providers/ApiProvider';
import { HeaderForm } from '../../../components/HeaderForm';
import { ModeFormulaire, DropdownRessource, Ressource } from '../../../types';
import { BaseIndexParams, PaginatedApiResponse, GoodApiResponse, ErrorApiResponse, IndexParams } from '../../../types/api';
import { ProfilUser, RoleEnum } from '../../../types/auth';
import { User, Role, Apprenant, Civilite, Pays, Nationalite } from '../../../types/schematics';
import { Config } from '../../../config/config';
import { Helmet } from 'react-helmet';
import { InputImage } from '../../../components/Inputs/InputImage';
import { ModalAnonymisation } from '../../../components/Modales/Admin/ModalAnonymisation';
import { InputDate } from '../../../components/Inputs/InputDate';
import { ConstRoles } from '../../../components/ConstRoles';
import { Region, RegionSite } from '../../../types/AdminRessources';
import { ReadOnlySelect } from '../../../components/Inputs/ReadOnlySelect';
import { ReadOnlyNumber } from '../../../components/Inputs/ReadOnlyNumber';
import { ReadOnlyText } from '../../../components/Inputs/ReadOnlyText';
import { ReadOnlyDate } from '../../../components/Inputs/ReadOnlyDate';
import { ModalModificationYpareoCodeApprenant } from '../../../components/Modales/Admin/ModalModificationYpareoCodeApprenant';
import { handleError } from '../../../utils/ErrorHandler';
import { Tab, TabPanel, Tabs, TabsBody, TabsHeader } from '../../../components/Onglets';
import { ImageHints } from '../../../components/ImageHints';
import { AlertInfo, AlertWarning } from '../../../components/Alertes';
import { SubmitSave } from '../../../components/SubmitSave';

type Props = { modeFormulaire: ModeFormulaire };

/**
 * Ce composant doit afficher un formulaire qui à 3 modes de fonctionnement. Les 2 principaux sont la création et l'édition.
 * On a également un mode lecture seule. L'idée c'est de gérer une partie du CRUD.
 * C'est dans ce composant que vont se faire les appels API pour créer, éditer et voir une ressource en particulier.
 *
 * @param modeFormulaire - Mode du formulaire (creer, editer et voir).
 * @returns JSX du composant
 */
export const AdminUserFormScreen: FunctionComponent<Props> = ({ modeFormulaire }) => {
  // On initialise les données dont on va avoir besoin sur cette page

  /**
   * On stocke si on est en train d'utiliser l'API ou non.
   */
  const [apiIsLoading, setApiIsLoading] = useState(false);

  /**
   * On stocke l'état des rôles.
   */
  const [roles, setRoles] = useState<DropdownRessource[] | null>(null);

  /**
   * On stocke les rôles possédés par l'utilisateur.
   */
  const [ownedRoles, setOwnedRoles] = useState<DropdownRessource[]>([]);

  /**
   * On stocke les profils possédés par l'utilisateur.
   */
  const [ownedProfils, setOwnedProfils] = useState<ProfilUser[] | null>(null);

  /**
   * On stocke le nom et le prénom, afin de l'afficher en mode édition et voir.
   */
  const [nomPrenom, setNomPrenom] = useState<string>('');

  /**
   * Permet d'ouvrir une modale lorsque l'on souhaite anonymiser un utilisateur.
   */
  const [modalAnonymisationEstVisible, setModalAnonymisationEstVisible] = useState<boolean>(false);

  const [regions, setRegions] = useState<Region[] | null>(null);

  const [selectedSites, setSelectedSites] = useState<RegionSite['id'][]>([]);

  const [selectedRegions, setSelectedRegions] = useState<Region['id'][]>([]);

  /**
   * On stocke l'état des civilités.
   */
  const [civilites, setCivilites] = useState<DropdownRessource[] | null>(null);

  /**
   * On stocke l'état des pays.
   */
  const [pays, setPays] = useState<DropdownRessource[] | null>(null);

  /**
   * On stocke l'état des nationalités.
   */
  const [nationalites, setNationalites] = useState<DropdownRessource[] | null>(null);

  /**
   * Permet d'ouvrir une modale lorsque l'on souhaite supprimer un avis.
   */
  const [modalModificationYpareoCodeEstVisible, setModalModificationYpareoCodeEstVisible] = useState<boolean>(false);

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  /**
   * Contient un état général des valeurs des champs du formulaire ainsi que les champs qui ont reçu une erreur lors de l'envoi du formulaire.
   */
  const [formInputs, setFormInputs] = useState<{ [K in keyof User]: { value: User[K]; error: boolean } } & { est_actif: { value: boolean; error: boolean } }>({
    apprenant_id: { value: null, error: false },
    nom: { value: '', error: false },
    prenom: { value: '', error: false },
    date_naissance: { value: null, error: false },
    email: { value: '', error: false },
    email_verification_token: { value: null, error: false },
    email_verified_at: { value: null, error: false },
    password: { value: '', error: false },
    password_forgot_token: { value: null, error: false },
    remember_token: { value: null, error: false },
    image_avatar: { value: null, error: false },
    est_actif: { value: false, error: false },
    est_rgpd_exporte: { value: false, error: false },
    date_rgpd_export: { value: null, error: false },
    est_rgpd_anonymise: { value: false, error: false },
    date_rgpd_anonymisation: { value: null, error: false },
    created_at: { value: null, error: false },
  });

  const [imageAvatarString, setImageAvatarString] = useState<User['image_avatar'] | null>(null);

  /**
   * Contient un état général des valeurs des champs du formulaire ainsi que les champs qui ont reçu une erreur lors de l'envoi du formulaire.
   */
  const [formInputsApprenant, setFormInputsApprenant] = useState<{ [K in keyof Apprenant]: { value: Apprenant[K]; error: boolean } }>({
    civilite_id: { value: null, error: false },
    code_ine: { value: null, error: false },
    numero_badge: { value: null, error: false },
    nom: { value: '', error: false },
    nom_jeune_fille: { value: null, error: false },
    prenom: { value: '', error: false },
    prenom_second: { value: null, error: false },
    prenom_troisieme: { value: null, error: false },
    sexe: { value: null, error: false },
    date_naissance: { value: null, error: false },
    lieu_naissance: { value: null, error: false },
    dept_naissance: { value: null, error: false },
    commune_naissance_id: { value: null, error: false },
    pays_naissance_id: { value: null, error: false },
    nationalite_id: { value: null, error: false },
    adr1: { value: null, error: false },
    adr2: { value: null, error: false },
    adr3: { value: null, error: false },
    adr4: { value: null, error: false },
    code_postal: { value: null, error: false },
    ville: { value: null, error: false },
    pays: { value: null, error: false },
    email: { value: null, error: false },
    yp_code: { value: null, error: false },
    created_at: { value: null, error: false },
  });

  /**
   * Permet au composant de changer de page.
   */
  const navigate = useNavigate();

  const params = useParams();

  // On a besoin du useEffect lorsque l'on est en mode édition et lecture pour précharger le formulaire avec les données de la ressource.

  useEffect(() => {
    switch (modeFormulaire) {
      case ModeFormulaire.creer:
        client.get<PaginatedApiResponse<Role>, BaseIndexParams>('/admin/roles', { params: { as_foreign: true, no_pagination: true } }).then((response) => {
          setRoles(response.data.data as unknown as DropdownRessource[]);
        });
        break;
      case ModeFormulaire.voir:
      case ModeFormulaire.editer:
        if (params.id === undefined) {
          navigate('/admin/users');
        }
        handleShow(parseInt(params.id as string));
        handleIndex();
        break;

      default:
        break;
    }
  }, [client, params.id]);

  /**
   * Retrouve Les ressources nécessaires pour afficher les dropdown et les stockent dans des .
   *
   * @param indexParams - Paramètres de la recherche.
   */
  const handleIndex = () => {
    setApiIsLoading(true);
    const baseParams: BaseIndexParams = { as_foreign: true, no_pagination: true };
    Promise.all([
      client.get<PaginatedApiResponse<Role>, BaseIndexParams>('/admin/roles', { params: baseParams }),
      client.get<GoodApiResponse<ProfilUser>>(`/admin/users/${parseInt(params.id as string)}/profils`),
      client.get<GoodApiResponse<Region[]>, IndexParams>('/regions-sites', { params: { no_pagination: true, as_foreign: true } }),
    ])
      .then((response) => {
        setRoles(response[0].data.data as unknown as DropdownRessource[]);
        setOwnedProfils(response[1].data.data as unknown as ProfilUser[]);
        setRegions(response[2].data.data);

        let sites: RegionSite[] = [];

        (response[2].data.data as Region[]).forEach((region) => {
          if (region.sites) {
            sites = [...sites, ...region.sites];
          }
        });
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Retrouve par le réseau la ressource liée à l'ID foruni et la met dans un état local.
   * Ne doit être utilisé qu'en mode édition et lecture seule.
   *
   * @param ressourceId - ID de la ressource dont on veut retrouver les informations.
   */
  const handleShow = (ressourceId: Ressource['id']) => {
    if (apiIsLoading === true) {
      return;
    }

    setApiIsLoading(true);
    client
      .get<GoodApiResponse<User>>(`/admin/users/${ressourceId}`)
      .then((reponse) => {
        const updatedInputs = Object.keys(reponse.data.data).reduce((acc, key) => {
          if (key === 'image_avatar' && reponse.data.data.image_avatar !== null) {
            setImageAvatarString(reponse.data.data.image_avatar);
          }
          return { ...acc, [key]: { value: reponse.data.data[key as keyof User], error: false } };
        }, formInputs);
        setFormInputs(updatedInputs);
        setNomPrenom(`${updatedInputs.prenom.value} ${updatedInputs.nom.value}`);
        setOwnedRoles((reponse.data.data as User & { roles: DropdownRessource[] }).roles);
        setSelectedSites((reponse.data.data as User & { sites: DropdownRessource[] }).sites.map((site) => site.id));
        if (reponse.data.data.apprenant_id) {
          handleShowApprenant(reponse.data.data.apprenant_id);
        }
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error, () => {
          navigate('/admin/users');
        });
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Poste par le réseau une ressource crée dans le formulaire via un état local.
   * Ne doit être utilisé qu'en mode création.
   */
  const handleStore = () => {
    setApiIsLoading(true);

    let ressource: Partial<User> = {};
    Object.keys(formInputs).forEach((key) => {
      if (formInputs[key as keyof User]?.value !== null && !(key === 'image_avatar' && formInputs.image_avatar.value === imageAvatarString)) {
        ressource = { ...ressource, [key]: formInputs[key as keyof User]?.value };
      }
    });
    client
      .post<{ id: User['id']; status: string }, User>('/admin/users', ressource as User)
      .then((response) => {
        Promise.all(
          ownedRoles.map((ownedRole) => {
            client.post(`/admin/users/${response.data.id}/roles/${ownedRole.id}`);
          }),
        )
          .then(() => {
            toast.success(`Création éffectuée`);
            navigate(`/admin/users/editer/${response.data.id}`);
          })
          .catch((error) => {
            handleError(error);
          })
          .finally(() => {
            setApiIsLoading(false);
          });
      })
      .catch((error: ErrorApiResponse<User>) => {
        handleError(error, () => {
          let object = formInputs;
          Object.keys(error.response.data.errors).forEach((key) => {
            if (error.response.data.errors[key as keyof User]) {
              object = { ...object, [key]: { value: formInputs[key as keyof User]?.value, error: true } };
            }
          }, formInputs);
          setFormInputs(object);
        });
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Met à jour, par le réseau, une ressource à partir du formulaire via un état local.
   * Ne doit être utilisé qu'en mode édition.
   *
   * @param ressourceId - ID de la ressource dont on veut modifier les informations.
   */
  const handleUpdate = (ressourceId: Ressource['id']) => {
    setApiIsLoading(true);

    let ressource: Partial<User> = {};
    Object.keys(formInputs).forEach((key) => {
      if (
        formInputs[key as keyof User]?.value !== null &&
        !(key === 'image_avatar' && formInputs.image_avatar.value === imageAvatarString) &&
        !(key === 'password' && formInputs.password.value === '')
      ) {
        ressource = { ...ressource, [key]: formInputs[key as keyof User]?.value };
      }
    });

    client
      .patch<GoodApiResponse<User>>(`/admin/users/${ressourceId}`, ressource)
      .then(() => {
        toast.success(`Modification éffectuée`);
        navigate('/admin/users');
      })
      .catch((error: ErrorApiResponse<User>) => {
        handleError(error, () => {
          let object = formInputs;
          Object.keys(error.response.data.errors).forEach((key) => {
            if (error.response.data.errors[key as keyof User]) {
              object = { ...object, [key]: { value: formInputs[key as keyof User]?.value, error: true } };
            }
          }, formInputs);
          setFormInputs(object);
        });
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Retrouve par le réseau la ressource liée à l'ID foruni et la met dans un état local.
   * Ne doit être utilisé qu'en mode édition et lecture seule.
   *
   * @param ressourceId - ID de la ressource dont on veut retrouver les inapprenants.
   */
  const handleShowApprenant = (apprenantId: Ressource['id']) => {
    if (apiIsLoading === true) {
      return;
    }

    setApiIsLoading(true);
    client
      .get<GoodApiResponse<Apprenant>>(`/admin/apprenants/${apprenantId as number}`)
      .then((reponse) => {
        const updatedInputs = Object.keys(reponse.data.data).reduce((acc, key) => {
          return { ...acc, [key]: { value: reponse.data.data[key as keyof Apprenant], error: false } };
        }, formInputsApprenant);
        setFormInputsApprenant(updatedInputs);
        handleIndexApprenant();
      })
      .catch((error: ErrorApiResponse<Apprenant>) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Retrouve Les ressources nécessaires pour afficher les dropdown et les stockent dans des .
   *
   * @param indexParams - Paramètres de la recherche.
   */
  const handleIndexApprenant = () => {
    setApiIsLoading(true);
    const baseParams: BaseIndexParams = { as_foreign: true, no_pagination: true };

    const promises = [
      client.get<PaginatedApiResponse<Civilite>, BaseIndexParams>('/admin/civilites', { params: { ...baseParams, sort: 'nom' } }),
      client.get<PaginatedApiResponse<Pays>, BaseIndexParams>('/admin/pays', { params: { ...baseParams, sort: 'nom' } }),
      client.get<PaginatedApiResponse<Nationalite>, BaseIndexParams>('/admin/nationalites', { params: { ...baseParams, sort: 'nom' } }),
    ];

    Promise.all(promises)
      .then((response) => {
        setCivilites(response[0].data.data as unknown as DropdownRessource[]);
        setPays(response[1].data.data as unknown as DropdownRessource[]);
        setNationalites(response[2].data.data as unknown as DropdownRessource[]);
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Cette méthode permet de gérer l'envoi du formulaire en fonction du mode dans lequel on se trouve.
   * L'envoi ne fait rien si on est en mode voir.
   *
   * @param event - Évenement de l'envoie.
   */
  const handleSubmit = (event: { preventDefault: () => void }): void => {
    event.preventDefault();

    if (apiIsLoading === true) {
      return;
    }

    if (modeFormulaire === ModeFormulaire.creer) {
      handleStore();
    }

    if (modeFormulaire === ModeFormulaire.editer) {
      if (params.id !== undefined) {
        handleUpdate(parseInt(params.id as string));
      }
    }
  };

  /**
   * Cette méthode permet de gérer les changements à l'interieur des champs du formulaire et de gérer les valeurs des champs via un état local.
   *
   * @param field - Champs du formulaire dont on doit stocker la valeur et s'il est en erreur.
   */
  const handleInputChange = (field: keyof User) => {
    return (event: { target: { value: string } }) => {
      setFormInputs((prev) => ({
        ...prev,
        [field]: { value: event.target.value, error: false },
      }));
    };
  };

  const handleSwitchChange = (field: keyof (User & { est_actif: boolean })) => {
    setFormInputs((prev) => ({
      ...prev,
      [field]: { value: !prev[field as keyof (User & { est_actif: boolean })]?.value, error: false },
    }));
  };

  /**
   * Permet de gérer le le changement d'image dans l'état local.
   * @param field
   * @returns
   */
  const handleImageChange = (field: keyof User) => {
    return (imageString: string) => {
      setFormInputs((prev) => ({
        ...prev,
        [field]: { value: imageString, error: false },
      }));
    };
  };

  /**
   * Gère les changement de rôles.
   *
   * @param roleId - ID du rôle dont on soihaite changer l'état pour l'utilisateur.
   */
  const handleRolesChange = async (roleId: Role['id']) => {
    setApiIsLoading(true);
    try {
      if (ownedRoles?.findIndex((ownedRole) => ownedRole.id === roleId) === -1) {
        await client.post(`/admin/users/${parseInt(params.id as string)}/roles/${roleId}`);
        setOwnedRoles([...(ownedRoles as DropdownRessource[]), { id: roleId, label: roles?.find((role) => role.id === roleId)?.label as string }]);
        toast.success(`Rôle ajouté à l'utilisateur`);
      } else {
        await client.delete(`/admin/users/${parseInt(params.id as string)}/roles/${roleId}`);
        setOwnedRoles((ownedRoles as DropdownRessource[]).filter((ownedRole) => ownedRole.id !== roleId));
        toast.success(`Rôle retiré à l'utilisateur`);
      }
    } catch (error: any) {
      if (error.response && [400, 401, 403, 404, 422].includes(parseInt(error.response.status)) && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
    setApiIsLoading(false);
  };

  const handleRolesAnticipation = (roleId: Role['id']) => {
    if (ownedRoles?.findIndex((ownedRole) => ownedRole.id === roleId) === -1) {
      setOwnedRoles([...(ownedRoles as DropdownRessource[]), { id: roleId, label: roles?.find((role) => role.id === roleId)?.label as string }]);
    } else {
      setOwnedRoles((ownedRoles as DropdownRessource[]).filter((ownedRole) => ownedRole.id !== roleId));
    }
  };

  /**
   * Permet de demander à l'API de forcer la vérification d'e-mail de l'utilisateur.
   *
   * @return void
   */
  const handleVerifie = () => {
    setApiIsLoading(true);

    client
      .patch<GoodApiResponse>(`/admin/users/${parseInt(params.id as string)}/verifie`)
      .then(() => {
        formInputs.email_verified_at.value = new Date().toISOString();
        toast.success(`L'utilisateur a été vérifié`);
      })
      .catch((error) => {
        if (error.response && parseInt(error.response.status) >= 400 && error.response.data.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Permet de demander à l'API d'annuler la vérification d'e-mail de l'utilisateur.
   *
   * @return void
   */
  const handleDeverifie = () => {
    setApiIsLoading(true);

    client
      .patch<GoodApiResponse>(`/admin/users/${parseInt(params.id as string)}/deverifie`)
      .then(() => {
        formInputs.email_verified_at.value = null;
        toast.success(`L'utilisateur a été dévérifié`);
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Permet de demander à l'API faire le lien entre l'utilisateur connecté et un apprenant.
   *
   * @return void
   */
  const handleLienApprenant = () => {
    setApiIsLoading(true);

    client
      .patch<GoodApiResponse>(`/admin/users/${parseInt(params.id as string)}/ypareo-relie-apprenant-auto`)
      .then(() => {
        toast.success(`L'utilisateur a été lié à un apprenant.`);
        window.location.reload();
      })
      .catch((error) => {
        if (error.response && parseInt(error.response.status) >= 400 && error.response.data.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  const handleExport = () => {
    setApiIsLoading(true);

    client
      .patch<{ url: string }>(`/admin/users/${parseInt(params.id as string)}/rgpd/export`)
      .then((response) => {
        toast.success('Données exportées');
        window.location.href = response.data.url;
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  const handleAnonymisation = () => {
    setApiIsLoading(true);

    client
      .patch<GoodApiResponse>(`/admin/users/${parseInt(params.id as string)}/rgpd/anonymise`)
      .then(() => {
        toast.success('Données anonymisées');
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  const handleModalAnonymisation = () => {
    setModalAnonymisationEstVisible(true);
  };

  /**
   * Permet d'écrire le mot correspondant au mode de fomulaire. Ce mot s'affiche au début du formulaire juste à coté de la ressource.
   *
   * @param modeFormulaire - Mode du formulaire (creer, editer et voir).
   * @returns Mot à afficher correspondant au mode de formulaire.
   * @throws Lance une erreur si aucun mode formulaire n'est fourni.
   */
  const afficheModeFormulaire = (modeFormulaire: ModeFormulaire) => {
    switch (modeFormulaire) {
      case ModeFormulaire.creer:
        return 'Création';
      case ModeFormulaire.editer:
        return 'Édition';
      case ModeFormulaire.voir:
        return 'Voir';

      default:
        throw new Error("Vous n'avez pas choisi un mode de formulaire adéquat.");
    }
  };

  // Sélection Sites pour le role admin_centre.

  /**
   * "Gère" le clic sur sur un elément. Ca réagit contextuellement en fonction de ce sur quoi on clique.
   *
   * L'idée c'est d'ajouter les sites dans un tableau et qu'il n'y ait pas de doublon.
   *
   * @param typeOfChoice - Type d'élément sur lequel on a cliqué.
   *
   * @param value - Contientle slug du site, de la region ou n'est pas défini.
   */
  const handleToggleSite = (typeOfChoice: 'region' | 'site', value?: RegionSite | Region) => {
    switch (typeOfChoice) {
      case 'region':
        if (selectedRegions.includes(value?.id as Region['id'])) {
          (((regions as Region[]).find((region) => region.id === value?.id) as Region).sites as RegionSite[]).forEach((site) => {
            setSelectedSites((selectedSites) => [...selectedSites.filter((selectedSite) => selectedSite !== site.id)]);
          });

          setSelectedRegions([...selectedRegions.filter((selectedRegion) => selectedRegion !== value?.id)]);
        } else {
          setSelectedSites([
            ...selectedSites,
            ...(((regions as Region[]).find((region) => region.id === value?.id) as Region).sites as RegionSite[])
              .filter((site) => !selectedSites.includes(site.id))
              .map((site) => site.id),
          ]);

          setSelectedRegions([...selectedRegions, value?.id as Region['id']]);
        }
        break;
      case 'site':
        toogleSite(value as RegionSite);
        break;

      default:
        break;
    }
  };

  const addSite = (value: RegionSite) => {
    setSelectedSites([...selectedSites, value.id]);
  };

  const removeSite = (value: RegionSite) => {
    setSelectedSites([...selectedSites.filter((selectedSite) => selectedSite !== value.id)]);
  };

  const toogleSite = (value: RegionSite) => {
    if (value && selectedSites.includes(value.id)) {
      removeSite(value);
    } else {
      addSite(value);
    }
  };

  const assigneGestionnaireCentre = () => {
    client
      .post<GoodApiResponse>(`/admin/users/${parseInt(params.id as string)}/sites/${selectedSites && selectedSites.length ? selectedSites.join('|') : 0}`)
      .then(() => {
        handleIndex();
        toast.success('Centres assignés');
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error);
      });
  };

  // Fin sélection sites

  /**
   * Paramètres tabs
   * @constant
   */
  const data = [
    {
      label: 'Rôles',
      value: 'roles',
    },
    {
      label: 'Profils',
      value: 'profils',
    },
    {
      label: 'Vérification',
      value: 'verification',
    },
    {
      label: 'YParéo',
      value: 'ypareo',
    },
    {
      label: 'RGPD',
      value: 'rgpd',
    },
  ] as const;

  if (modeFormulaire !== ModeFormulaire.creer && (roles === null || ownedRoles === null || ownedProfils === null)) {
    return <Fragment>Chargement...</Fragment>;
  }

  return (
    <Fragment>
      <Helmet>
        <title>
          {afficheModeFormulaire(modeFormulaire)} utilisateur - {Config.app_label}
        </title>
      </Helmet>
      <HeaderForm
        text={`${afficheModeFormulaire(modeFormulaire)} utilisateur ${modeFormulaire !== ModeFormulaire.creer ? nomPrenom : ''}`}
        returnUrl='/admin/users'
      />
      <Tabs value='general'>
        <TabsHeader>
          <Tab value='general'>Général</Tab>
          {modeFormulaire !== ModeFormulaire.creer &&
            data.map(({ label, value }) => (
              <Tab key={value} value={value}>
                {label}
              </Tab>
            ))}
        </TabsHeader>
        <TabsBody>
          <TabPanel value='general'>
            <form className='relative' onSubmit={handleSubmit}>
              <div className='bg-white p-5 rounded-b-lg'>
                <div className='container mx-auto bg-white rounded'>
                  <fieldset className='pb-7 mb-7 border-b'>
                    <legend className='block text-left mb-3 text-xl text-secondary-1'>Informations</legend>
                    <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-2 items-center'>
                      <div>
                        <InputText
                          label='Prénom'
                          value={formInputs.prenom.value as string}
                          onChange={handleInputChange('prenom')}
                          error={formInputs.prenom.error}
                        />
                      </div>
                      <div>
                        <InputText label='Nom' value={formInputs.nom.value as string} onChange={handleInputChange('nom')} error={formInputs.nom.error} />
                      </div>
                      <div>
                        <InputDate
                          label='Date de naissance'
                          value={formInputs.date_naissance.value as string}
                          onChange={handleInputChange('date_naissance')}
                          error={formInputs.date_naissance.error}
                        />
                      </div>
                    </div>
                  </fieldset>

                  {modeFormulaire === ModeFormulaire.creer && roles !== null && (
                    <fieldset className='pb-7 mb-7 border-b'>
                      <legend className='block text-left mb-3 text-xl text-secondary-1'>Rôles</legend>
                      <div>
                        {(roles as DropdownRessource[]).map((role) => (
                          <div key={role.id} className='mb-2'>
                            <InputSwitch
                              label={(<ConstRoles text={role.label as RoleEnum} />) as unknown as string}
                              checked={ownedRoles?.findIndex((ownedRole) => ownedRole.id === role.id) !== -1}
                              onChange={() => handleRolesAnticipation(role.id)}
                            />
                          </div>
                        ))}
                      </div>
                    </fieldset>
                  )}

                  <fieldset className='pb-7 mb-7 border-b'>
                    <legend className='block text-left mb-3 text-xl text-secondary-1'>Image</legend>
                    <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-2'>
                      <div>
                        <InputImage label='Avatar' imageUrl={imageAvatarString as string} onChange={handleImageChange('image_avatar')} />
                      </div>
                      <div className='pt-6'>
                        <ImageHints largeur='512' />
                      </div>
                    </div>
                  </fieldset>
                  <fieldset className='pb-7 mb-7'>
                    <legend className='block text-left mb-3 text-xl text-secondary-1'>Identifiants</legend>
                    <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-2 items-center'>
                      <div>
                        <InputEmail
                          label='E-mail'
                          value={formInputs.email.value as string}
                          onChange={handleInputChange('email')}
                          error={formInputs.email.error}
                        />
                      </div>
                      <div>
                        <InputPassword
                          label='Mot de passe'
                          value={formInputs.password.value as string}
                          onChange={handleInputChange('password')}
                          error={formInputs.password.error}
                        />
                      </div>
                    </div>
                  </fieldset>
                  <fieldset className='pb-7 mb-7'>
                    <legend className='block text-left mb-3 text-xl text-secondary-1'>Administration</legend>
                    <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-2 items-center'>
                      <div>
                        <InputSwitch
                          label='Est Actif'
                          checked={formInputs.est_actif.value !== null ? formInputs.est_actif.value : false}
                          onChange={() => handleSwitchChange('est_actif')}
                        />
                      </div>
                    </div>
                  </fieldset>
                </div>

                <div className='container mx-auto w-11/12 xl:w-full'>
                  <div className='w-full py-4 sm:px-0 bg-white flex justify-end'>{modeFormulaire !== ModeFormulaire.voir && <SubmitSave />}</div>
                </div>
              </div>
            </form>
          </TabPanel>
          {modeFormulaire !== ModeFormulaire.creer && (
            <TabPanel value='roles'>
              <div className='bg-white p-5 rounded-b-lg'>
                <fieldset className='pb-7 mb-7 border-b'>
                  <legend className='block text-left mb-3 text-xl text-secondary-1'>Rôles</legend>
                  <div>
                    {roles && roles.length > 0 ? (
                      (roles as DropdownRessource[]).map((role) => (
                        <div key={role.id} className='mb-2'>
                          <InputSwitch
                            label={(<ConstRoles text={role.label as RoleEnum} />) as unknown as string}
                            checked={ownedRoles?.findIndex((ownedRole) => ownedRole.id === role.id) !== -1}
                            onChange={() => handleRolesChange(role.id)}
                          />
                        </div>
                      ))
                    ) : (
                      <div className='text-center px-4 py-7'>Il n'y pas de rôle pour cet utilisateur.</div>
                    )}
                  </div>
                </fieldset>
                {ownedRoles && ownedRoles.findIndex((ownedRole) => ownedRole.id === 3) !== -1 && (
                  <div>
                    <fieldset className='pb-7 mb-7'>
                      <legend className='block text-left mb-3 text-xl text-secondary-1'>Centres administrés</legend>
                      <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-2'>
                        <div>
                          {regions && regions.length > 0 ? (
                            regions.map((region, indexr) => (
                              <div key={indexr}>
                                <div className='flex flex-row gap-1' onClick={() => handleToggleSite('region', region)}>
                                  <input type='checkbox' className='text-red-600 cursor-pointer' checked={selectedRegions.includes(region.id)} />
                                  <p className='text-sm poppins-semibold text-secondary-1 mb-1 hover:text-primary cursor-pointer'>{region.nom}</p>
                                </div>
                                <div className='pl-5'>
                                  {region.sites && region.sites.length > 0 ? (
                                    region.sites.map((site, indexs) => (
                                      <div
                                        key={indexs}
                                        className='flex flex-row gap-1 text-xs poppins-semibold py-0.5'
                                        onClick={() => handleToggleSite('site', site)}>
                                        <input type='checkbox' className='text-red-600 cursor-pointer' checked={selectedSites.includes(site.id)} />
                                        <p className='cursor-pointer'>{site.nom}</p>
                                      </div>
                                    ))
                                  ) : (
                                    <div className='text-center px-4 py-7'>Il n'y pas de sites disponibles.</div>
                                  )}
                                </div>
                              </div>
                            ))
                          ) : (
                            <div className='text-center px-4 py-7'>Il n'y pas de régions disponibles.</div>
                          )}
                        </div>
                      </div>
                    </fieldset>

                    <Button
                      variant='filled'
                      color='orange'
                      onClick={() => {
                        assigneGestionnaireCentre();
                      }}>
                      Confirmer
                    </Button>
                  </div>
                )}
              </div>
            </TabPanel>
          )}
          {modeFormulaire !== ModeFormulaire.creer && (
            <TabPanel value='profils'>
              <div className='bg-white w-full overflow-x-auto rounded-b-lg'>
                {ownedProfils && ownedProfils.length > 0 ? (
                  <table className='table-fixed min-w-full bg-white rounded-b-lg'>
                    <thead>
                      <tr className='w-full h-12 border-gray-300  border-b py-8'>
                        <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Entreprise</th>
                        <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Fonction</th>
                        <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Statut</th>
                        <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Affilié depuis le </th>
                      </tr>
                    </thead>
                    <tbody>
                      {(ownedProfils as ProfilUser[]).map((ownedProfil) => (
                        <tr key={ownedProfil.id} className=' border-gray-100 border-b hover:bg-gray-100'>
                          <td className='px-4 py-3 text-gray-800 text-sm'>
                            {ownedProfil.entreprise.raison_sociale}({ownedProfil.entreprise.enseigne})
                          </td>
                          <td className='px-4 py-3 text-gray-800 text-sm'>{<ConstTypeProfil text={ownedProfil.type_profil} />}</td>
                          <td className='px-4 py-3 text-gray-800 text-sm'>{<ConstStatutAffiliation text={ownedProfil.statut_affiliation} />}</td>
                          <td className='px-4 py-3 text-gray-800 text-sm'>
                            {ownedProfil.created_at !== null
                              ? new Intl.DateTimeFormat('default', {
                                  year: 'numeric',
                                  month: 'numeric',
                                  day: 'numeric',
                                  hour: 'numeric',
                                  minute: 'numeric',
                                  hour12: false,
                                }).format(new Date(ownedProfil.created_at as string))
                              : ''}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div className='text-center px-4 py-7'>Il n'y pas de profil pour cet utilisateur.</div>
                )}
              </div>
            </TabPanel>
          )}
          {modeFormulaire !== ModeFormulaire.creer && (
            <TabPanel value='verification'>
              <div className='bg-white p-5 rounded-b-lg flex gap-4'>
                <p>
                  {formInputs.email_verified_at.value
                    ? `L'utilisateur a été vérifié le : ${new Intl.DateTimeFormat('default', {
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                        second: 'numeric',
                        hour12: false,
                      }).format(new Date(formInputs.email_verified_at.value as string))}`
                    : "L'utilisateur n'a pas encore été vérifié"}
                </p>
                {formInputs.email_verified_at.value ? (
                  <button
                    className='bg-secondary-1 uppercase text-xs poppins-bold hover:bg-gray-300 text-white hover:text-secondary-1 py-2 px-5 rounded-full shadow-sm mr-3'
                    onClick={() => handleDeverifie()}>
                    Dévérifier
                  </button>
                ) : (
                  <button
                    className='bg-primary uppercase text-xs poppins-bold text-white py-2 px-5 rounded-full shadow-sm hover:bg-secondary-1'
                    onClick={() => handleVerifie()}>
                    Vérifier
                  </button>
                )}
              </div>
            </TabPanel>
          )}
          {modeFormulaire !== ModeFormulaire.creer && (
            <TabPanel value='ypareo'>
              <div className='bg-white p-5 rounded-b-lg gap-4'>
                <div>
                  <p>
                    {formInputs.apprenant_id.value ? (
                      <AlertInfo text="L'utilisateur est déjà lié à un apprenant." />
                    ) : (
                      <AlertWarning
                        text={
                          <Fragment>
                            <b>Important&nbsp;:</b>
                            {` L'utilisateur n'a pas encore été lié à un apprenant.`}
                          </Fragment>
                        }
                      />
                    )}
                  </p>
                </div>
                {formInputs.apprenant_id.value === null ? (
                  <Fragment>
                    <button className='bg-primary text-xs poppins-bold text-white py-2 px-5 rounded-full' onClick={() => handleLienApprenant()}>
                      Lier automatiquement à un apprenant YParéo
                    </button>
                    <div className='px-2'>ou</div>
                  </Fragment>
                ) : (
                  <div className='container mx-auto bg-white'>
                    <fieldset className='pb-7 mb-7 border-b'>
                      <legend className='block text-left mb-3 text-xl text-secondary-1'>Identité</legend>
                      <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-2 items-center'>
                        <div>
                          <ReadOnlySelect
                            label='Civilité'
                            value={
                              formInputsApprenant.civilite_id.value !== null && formInputsApprenant.civilite_id.value !== undefined
                                ? formInputsApprenant.civilite_id.value.toString()
                                : ''
                            }
                            error={formInputsApprenant.civilite_id.error}
                            options={civilites as DropdownRessource[]}
                          />
                        </div>
                        <div>
                          <ReadOnlyText label='Code INE' value={formInputsApprenant.code_ine.value as string} />
                        </div>
                        <div>
                          <ReadOnlyText label='Numéro badge' value={formInputsApprenant.numero_badge.value as string} />
                        </div>
                        <div>
                          <ReadOnlyText label='Nom' value={formInputsApprenant.nom.value as string} />
                        </div>
                        <div>
                          <ReadOnlyText label='Nom de jeune fille' value={formInputsApprenant.nom_jeune_fille.value as string} />
                        </div>
                        <div>
                          <ReadOnlyText label='Prénom' value={formInputsApprenant.prenom.value as string} />
                        </div>
                        <div>
                          <ReadOnlyText label='Prénom second' value={formInputsApprenant.prenom_second.value as string} />
                        </div>
                        <div>
                          <ReadOnlyText label='Prénom troisième' value={formInputsApprenant.prenom_troisieme.value as string} />
                        </div>
                        <div>
                          <ReadOnlyText label='Sexe' value={formInputsApprenant.sexe.value as string} />
                        </div>
                        <div>
                          <ReadOnlyDate label='Date de naissance' value={formInputsApprenant.date_naissance.value as string} />
                        </div>
                        <div>
                          <ReadOnlyText label='Lieu de naissance' value={formInputsApprenant.lieu_naissance.value as string} />
                        </div>
                        <div>
                          <ReadOnlyText label='Département de naissance' value={formInputsApprenant.dept_naissance.value as string} />
                        </div>
                        <div>
                          <ReadOnlySelect
                            label={'Pays de naissance'}
                            value={
                              formInputsApprenant.pays_naissance_id.value !== null && formInputsApprenant.pays_naissance_id.value !== undefined
                                ? formInputsApprenant.pays_naissance_id.value.toString()
                                : ''
                            }
                            options={pays as DropdownRessource[]}
                          />
                        </div>
                        <div>
                          <ReadOnlySelect
                            label={'Nationalité'}
                            value={
                              formInputsApprenant.nationalite_id.value !== null && formInputsApprenant.nationalite_id.value !== undefined
                                ? formInputsApprenant.nationalite_id.value.toString()
                                : ''
                            }
                            options={nationalites as DropdownRessource[]}
                          />
                        </div>
                        <div>
                          <ReadOnlyNumber label='YParéo CodeApprenant' value={formInputsApprenant.yp_code.value} error={formInputsApprenant.yp_code.error} />
                        </div>
                      </div>
                    </fieldset>
                    <fieldset className='pb-7 mb-7 border-b'>
                      <legend className='block text-left mb-3 text-xl text-secondary-1'>Adresse</legend>
                      <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-2 items-center'>
                        <div>
                          <ReadOnlyText label='Adresse 1' value={formInputsApprenant.adr1.value as string} />
                        </div>
                        <div>
                          <ReadOnlyText label='Adresse 2' value={formInputsApprenant.adr2.value as string} />
                        </div>
                        <div>
                          <ReadOnlyText label='Adresse 3' value={formInputsApprenant.adr3.value as string} />
                        </div>
                        <div>
                          <ReadOnlyText label='Adresse 4' value={formInputsApprenant.adr4.value as string} />
                        </div>
                        <div>
                          <ReadOnlyText label='Code postal' value={formInputsApprenant.code_postal.value as string} />
                        </div>
                        <div>
                          <ReadOnlyText label='Ville' value={formInputsApprenant.ville.value as string} />
                        </div>
                        <div>
                          <ReadOnlyText label='Pays' value={formInputsApprenant.pays.value as string} />
                        </div>
                      </div>
                    </fieldset>
                    <fieldset className='pb-7 mb-7'>
                      <legend className='block text-left mb-3 text-xl text-secondary-1'>Contact</legend>
                      <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-2 items-center'>
                        <div>
                          <ReadOnlyText label='E-mail' value={formInputsApprenant.email.value as string} />
                        </div>
                      </div>
                    </fieldset>
                  </div>
                )}
                <div>
                  <button
                    color='gray'
                    className='bg-secondary-1 text-xs poppins-bold text-white py-2 px-5 rounded-full'
                    onClick={() => setModalModificationYpareoCodeEstVisible(true)}>
                    <span>Affecter manuellement un codeApprenant YParéo...</span>
                  </button>
                  <ModalModificationYpareoCodeApprenant
                    visible={modalModificationYpareoCodeEstVisible}
                    setVisible={setModalModificationYpareoCodeEstVisible}
                    userId={
                      (
                        formInputs.id as {
                          value: number | undefined;
                          error: boolean;
                        }
                      ).value
                    }
                    ypareoCode={formInputsApprenant.yp_code.value ? (formInputsApprenant.yp_code.value as number).toString() : ''}
                    handleShow={handleShow}
                  />
                </div>
              </div>
            </TabPanel>
          )}
          {modeFormulaire !== ModeFormulaire.creer && (
            <TabPanel value='rgpd'>
              <div className='bg-white p-5 rounded-b-lg '>
                <div className='flex flex-row gap-4'>
                  <button
                    className='bg-primary uppercase text-xs poppins-bold text-white py-2 px-5 rounded-full shadow-sm hover:bg-secondary-1'
                    onClick={() => handleExport()}>
                    Export RGPD
                  </button>
                  {formInputs.est_rgpd_exporte.value
                    ? `Dernier export le ${new Intl.DateTimeFormat('default', {
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                        second: 'numeric',
                        hour12: false,
                      }).format(new Date(formInputs.date_rgpd_export.value as string))}`
                    : ''}
                </div>
                <div className='pt-4 flex flex-row gap-4'>
                  <button
                    className='bg-primary uppercase text-xs poppins-bold text-white py-2 px-5 rounded-full shadow-sm hover:bg-secondary-1'
                    onClick={() => handleModalAnonymisation()}>
                    Anonymisation RGPD
                  </button>
                  {formInputs.est_rgpd_anonymise.value
                    ? `Derniere anonymisation le ${new Intl.DateTimeFormat('default', {
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                        second: 'numeric',
                        hour12: false,
                      }).format(new Date(formInputs.date_rgpd_anonymisation.value as string))}`
                    : ''}
                </div>
              </div>
              <ModalAnonymisation visible={modalAnonymisationEstVisible} setVisible={setModalAnonymisationEstVisible} confirmer={() => handleAnonymisation()} />
            </TabPanel>
          )}
        </TabsBody>
      </Tabs>
      {apiIsLoading ? <LoadingAbsolute /> : ''}
    </Fragment>
  );
};

import { ArrowRightIcon, PhoneIcon } from '@heroicons/react/20/solid';
import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

/**
 * Ce composant est temporaire.
 *
 * @param children - Contenu de l'encart.
 */
export const CtaEntrepriseNonValide: FunctionComponent = () => {
  return (
    <div className='rounded-md bg-[#FFF8F0] border-dashed border-2 border-primary p-4 shadow-md'>
      <h3 className='text-2xl poppins-semibold tracking-tight '>Une dernière étape...</h3>
      <div className='mt-2  '>
        <p className='mb-7'>
          L'entreprise liée à ce profil est encore en cours de validation.
          <br />
          Vous recevrez très bientôt une confirmation de notre part et serez en mesure de sélectionner ce profil.
        </p>
        <div className='flex flex-col lg:flex-row gap-4 lg:gap-16 items-center justify-center text-sm leading-loose'>
          <div className='grow'>
            <p className='poppins-semibold'>Vous avez des questions ?</p>
            <p>Nous sommes à votre écoute par téléphone du lundi au vendredi, de 8h à 19h :</p>
            <a className='inline-flex items-center gap-2 ' href='tel:0800855777'>
              <PhoneIcon className='w-6 h-6 text-primary flex-none' />
              <span className='text-primary'>0800 855 777</span>
              (appel gratuit)
            </a>
          </div>
          <div className='flex-none'>
            <Link
              to='https://www.compagnons-du-devoir.com/nous-contacter'
              className='inline-flex items-center justify-center rounded-full bg-primary px-3 text-sm poppins-semibold text-white'>
              Contacter un(e) conseiller(e)
              <ArrowRightIcon className='pl-3 w-8 h-8 flex-none' />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

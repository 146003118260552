import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { DashboardSalariesSessions } from '../../components/Dashboard/DashboardSalariesSessions';
import { DashboardSalariesSouhaits } from '../../components/Dashboard/DashboardSalariesSouhaits';
import { LoadingAbsolute } from '../../components/LoadingAbsolute';
import { useApi } from '../../providers/ApiProvider';
import { ErrorApiResponse, SalariesDashboard } from '../../types/api';
import { DashboardMiniAgenda } from '../../components/Dashboard/DashboardMiniAgenda';
import { handleError } from '../../utils/ErrorHandler';
import { DashboardSalariesConvocations } from '../../components/Dashboard/DashboardSalariesConvocations';
import { CtaEntrepriseNonValide } from '../../components/CtaEntrepriseNoneValide';

/**
 * Ce composant affiche le tableau de bord salarié.
 */
export const SalariesDashboardScreen: FunctionComponent = () => {
  /**
   * On stocke si on est en train d'utiliser l'API ou non.
   */
  const [apiIsLoading, setApiIsLoading] = useState(false);

  const [entrepriseInvalide, setEntrepriseInvalide] = useState(false);

  /**
   * Stocke les groupe dans un état local.
   */
  const [salariesDashboard, setSalariesDashboard] = useState<SalariesDashboard | null>(null);

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  /**
   * Au chargement de la page on va chercher les données de l'entreprise.
   */
  useEffect(() => {
    handleIndex();
  }, []);

  /**
   * Retrouve des données de L'API (des groupes) et les stocke dans un état local.
   *
   * @param indexParams - Paramètres de la recherche.
   */
  const handleIndex = () => {
    setApiIsLoading(true);

    client
      .get<SalariesDashboard>('/salarie/dashboard')
      .then((response) => {
        setSalariesDashboard(response.data);
      })
      .catch((error: ErrorApiResponse<SalariesDashboard>) => {
        if (error.response && parseInt(error.response.status) === 403) {
          setEntrepriseInvalide(true);
        } else {
          handleError(error);
        }
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  return (
    <div className='flex flex-wrap lg:flex-nowrap lg:flex-row gap-5 mt-11'>
      {entrepriseInvalide === true ? (
        <div className='basis-full'>
          <CtaEntrepriseNonValide />
        </div>
      ) : (
        <Fragment>
          <div className='basis-full lg:basis-1/2 xl:basis-7/12 2xl:basis-8/12 order-2 lg:order-1'>
            <DashboardSalariesSessions formations={salariesDashboard && salariesDashboard.data ? salariesDashboard.data.formations : []} />
            <DashboardSalariesConvocations convocations={salariesDashboard && salariesDashboard.data ? salariesDashboard.data.convocations : []} />
          </div>
          <div className='basis-full lg:basis-1/2 xl:basis-5/12 2xl:basis-4/12 order-1 lg:order-2'>
            <DashboardSalariesSouhaits
              souhaits={salariesDashboard && salariesDashboard.data ? salariesDashboard.data.souhaits_en_attente : []}
              handleIndex={handleIndex}
            />
            <DashboardMiniAgenda
              datesSessions={
                salariesDashboard &&
                salariesDashboard.data &&
                salariesDashboard.data.inscriptions
                  .filter((inscription) => inscription && inscription.date_debut && inscription.date_fin)
                  .map((inscription) => ({ dateDebut: new Date(inscription.date_debut as string), dateFin: new Date(inscription.date_fin as string) }))
              }
            />
          </div>
        </Fragment>
      )}
      {apiIsLoading ? <LoadingAbsolute /> : ''}
    </div>
  );
};

import { FunctionComponent, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Navigate, Outlet } from 'react-router-dom';
import { DashboardContainer } from '../components/Dashboard/DashboardContainer';
import { DashboardSidebar } from '../components/Dashboard/DashboardSidebar';
import { DashboardTopbar } from '../components/Dashboard/DashboardTopbar';
import { useApi } from '../providers/ApiProvider';
import { useUser } from '../providers/UserProvider';

/**
 * Ce layout contient la mise en page de la partie authentification et ce qui s'y rapporte.
 */
export const LoggedLayout: FunctionComponent = () => {
  /**
   * On a besoin des informations de l'utilisateur et du profil courant de celui-ci.
   */
  const { user, profil } = useUser();

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  /**
   * État local qui gère la présence du menu de gauche. Utile pour le responsive design.
   */
  const [openNav, setOpenNav] = useState(false);

  /**
   * Cet effet permet de faire apparaitre le menu.
   */
  useEffect(() => {
    /**
     * Cette méthode gère le apparition du menu de gauche
     */
    const handleResize = () => {
      if (window.innerWidth >= 1100) {
        setOpenNav(true);
      } else {
        setOpenNav(false);
      }
    };

    handleResize();

    //Ecoute les redimensionnement pour savoir si l'on doit faire apparaitre les menus.
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [client]);

  /**
   * Cette méthode est passé au composant `DashboardSidebar`.
   */
  const handleMenuItemOnClick = () => {
    if (window.innerWidth < 1100) {
      setOpenNav(false);
    }
  };

  /**
   * Si on a pas d'utilisateur ou pas de jeton d'authentification on redirige l'utilisateur vers la page de connexion.
   * Sinon on assigne le jeton d'authenfification et l'ID du profil dans les en-têtes de l'instance de la classe API puis on affiche le layout.
   */
  if (!user || !user.token) {
    toast.error('Accès refusé.');
    return <Navigate to='/connexion' />;
  } else {
    client.setToken(user.token);

    if (profil) {
      client.setProfil(profil.id);
    }

    return (
      <div className='w-full min-h-100vh bg-gray-200'>
        <div className='flex flex-no-wrap min-h-100vh'>
          {openNav && <DashboardSidebar handleMenuItemOnClick={handleMenuItemOnClick} />}
          <div className='w-full'>
            <DashboardTopbar openNav={openNav} setOpenNav={setOpenNav} />
            <DashboardContainer>
              <div className='w-full h-full block'>
                <Outlet />
              </div>
            </DashboardContainer>
          </div>
        </div>
      </div>
    );
  }
};

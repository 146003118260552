import { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';

/**
 * Composant InputText.
 *
 * @param label - Libellé du champ de saisie.
 * @param imageUrl - Valeur de l'url de l'image.
 * @param onChange - Fonction de rappel appelée lorsque la valeur du champ de saisie change.
 * @param error - Indique si le champ de saisie a une erreur.
 * @param placeholder - Placeholder du champ de saisie.
 * @return Composant InputImage.
 */
export const InputImage: FunctionComponent<{
  //defaultValue: string;
  error?: boolean;
  label?: string;
  onChange?: (imageString: string) => void;
  placeholder?: string;
  imageUrl?: string;
}> = ({ label, imageUrl, onChange = undefined, error = false, placeholder }) => {
  /**
   * Cet état locaux gère l'affichage d'une miniature au dessus du champ.
   */
  const [preview, setPreview] = useState<string | null>(imageUrl ? imageUrl : null);

  /**
   * @constant Elle permet de définir éventuellement un `id` pour le `input` afin que les labels faasent bien référence au champ.
   */
  const inputId = label ? `input-${label.toLowerCase().replace(' ', '-')}` : undefined;

  useEffect(() => {
    setPreview(imageUrl ? imageUrl : null);
  }, [imageUrl]);

  /**
   * Gère le changement de valeur dans le composant parent.
   *
   * @param e - L'événement de changement de valeur du champ de sélection de l'image.
   */
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const readerPreview = new FileReader();
    readerPreview.onload = () => {
      setPreview(readerPreview.result as string);
    };
    readerPreview.readAsDataURL((e.target.files as FileList)[0]);

    const readerSending = new FileReader();
    readerSending.onloadend = () => {
      if (onChange) {
        onChange(window.btoa(readerSending.result as string));
      }
    };
    readerSending.readAsBinaryString((e.target.files as FileList)[0]);

    /*let binary = '';
    const bytes = new Uint8Array((e.target.files as FileList)[0]);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);*/
  };

  return (
    <div>
      {label && (
        <label className={`text-xs ${error ? 'text-red-500' : ''}`} htmlFor={inputId}>
          {label}
        </label>
      )}
      {preview && (
        <div className='flex items-center'>
          <img src={preview} className='h-full w-full overflow-hidden shadow' />
        </div>
      )}
      <input
        className={`relative w-full text-sm leading-none text-left text-secondary-1 outline-none focus:border-secondary-2 ${error ? 'border-red-500' : ''}`}
        type='file'
        onChange={handleChange}
        id={inputId}
        placeholder={placeholder}
        accept='image/jpeg, image/png, image/webp'
      />
    </div>
  );
};

import { FunctionComponent, ReactNode } from 'react';

/**
 * Ce composant affiche la banière de la partie front.
 *
 * @param children - C'est bien souvent le menu et le breadcrumb.
 */
export const BanniereVitrine: FunctionComponent<{ children?: ReactNode }> = ({ children }) => {
  return (
    <div
      className='bg-cover bg-center h-auto text-white pt-3 pb-3 px-0 md:px-11 object-fill relative min-h-[380px]'
      style={{
        backgroundImage: `url("/assets/header-fiche-flieres-et-metiers.webp")`,
      }}>
      {children}
    </div>
  );
};

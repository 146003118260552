import { Dialog, DialogHeader, DialogBody, DialogFooter, Button } from '@material-tailwind/react';
import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useApi } from '../../../providers/ApiProvider';
import { GoodApiResponse, ErrorApiResponse } from '../../../types/api';
import { LoadingAbsolute } from '../../LoadingAbsolute';
import { Avis } from '../../../types/AdminRessources';
import { InputSwitch } from '../../Inputs/InputSwitch';
import { StarEmpty } from '../../Stars/StarEmpty';
import { StarFull } from '../../Stars/StarFull';
import { ReadOnlyTextarea } from '../../Inputs/ReadOnlyTextarea';
import { ReadOnlyBoolean } from '../../Inputs/ReadOnlyBoolean';
import { handleError } from '../../../utils/ErrorHandler';

/**
 * Ce composant permet d'afficher une Modale pour qu'un admin puisse modifier un avis'.
 *
 * @param visible - Précise si la modale et ouverte ou non. Provient du parent.
 * @param setVisible - Accesseur qui modifie la visibilité de la modale. Provient du parent.
 * @param avis - Avis à modifier. Provient du parent.
 * @param handleIndex - Callback qui permet de recharger les données du composant parent. Provient du parent.
 */
export const ModalModificationAvisAdmin: FunctionComponent<{
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  avis: Avis;
  handleIndex: () => void;
}> = ({ visible, setVisible, avis, handleIndex }) => {
  /**
   * On stocke si on est en train d'utiliser l'API ou non.
   */
  const [apiIsLoading, setApiIsLoading] = useState(false);

  /**
   * C'est la note que l'utilisateur souhaite donner à l'avis.
   */
  const [note, setNote] = useState<Avis['note'] | null>(null);

  /**
   * C'est le texte de l'avis à proprement parler.
   */
  const [description, setDescription] = useState<Avis['description']>('');

  /**
   * Permet d'afficher son nom complet à l'utilisateur.
   */
  const [afficheNomComplet, setAfficheNomComplet] = useState<Avis['affiche_nom_complet']>(false);

  /**
   * Permet à l'utilisateur d'afficher ou pas le nom de son entreprise.
   */
  const [afficheNomEntreprise, setAfficheNomEntreprise] = useState<Avis['affiche_nom_entreprise']>(false);

  /**
   * Permet à l'admin de valider un avis.
   */
  const [estValide, setEstValide] = useState<Avis['est_valide']>(false);

  /**
   * Permet à l'utilisateur d'afficher où pas son avatar.
   */
  const [afficheAvatar, setAfficheAvatar] = useState<Avis['affiche_avatar']>(false);

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  /**
   * On initialise les état locaux lorsque l'avis est chargé dans le composant.
   */
  useEffect(() => {
    if (avis) {
      setNote(avis.note);
      setDescription(avis.description);
      setAfficheAvatar(avis.affiche_avatar);
      setAfficheNomComplet(avis.affiche_nom_complet);
      setAfficheNomEntreprise(avis.affiche_nom_entreprise);
      setEstValide(avis.est_valide);
    }
  }, [avis]);

  /**
   * Cette méthode permet de modifier l'avis.
   */
  const handleModifie = () => {
    const params: {
      note: Avis['note'];
      description: Avis['description'];
      affiche_avatar: Avis['affiche_avatar'];
      affiche_nom_complet: Avis['affiche_nom_complet'];
      affiche_nom_entreprise: Avis['affiche_nom_entreprise'];
      est_valide: Avis['est_valide'];
    } = {
      note: note as number,
      description: description,
      affiche_avatar: afficheAvatar,
      affiche_nom_complet: afficheNomComplet,
      affiche_nom_entreprise: afficheNomEntreprise,
      est_valide: estValide,
    };

    setApiIsLoading(true);

    client
      .patch<
        GoodApiResponse,
        {
          note: Avis['note'];
          description: Avis['description'];
          affiche_avatar: Avis['affiche_avatar'];
          affiche_nom_complet: Avis['affiche_nom_complet'];
          affiche_nom_entreprise: Avis['affiche_nom_entreprise'];
          est_valide: Avis['est_valide'];
        }
      >(`/admin/avis/${(avis as Avis).id}`, params)
      .then(() => {
        handleIndex();
        toast.success('Avis modifié.');
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  const afficheAction = (action: string) => {
    switch (action) {
      case 'valide':
        return 'a validé';
      case 'devalide':
        return 'a dévalidé';
      case 'update':
        return 'a mis à jour';

      default:
        return '';
    }
  };

  const couleurAction = (action: string) => {
    switch (action) {
      case 'valide':
        return 'text-green-300';
      case 'devalide':
        return 'text-red-300';
      case 'update':
        return 'text-gray-600';

      default:
        return '';
    }
  };

  return (
    <Dialog open={visible} size='xs' handler={setVisible}>
      <DialogHeader>Avis</DialogHeader>
      <DialogBody divider>
        <div className='flex md:flex-row lg:flex-row  mb-3'>
          <span className='pr-2'>Note&nbsp;:</span>
          <div>{note && note >= 1 ? <StarFull className={`w-5 h-5`} /> : <StarEmpty className={`w-5 h-5`} />}</div>
          <div>{note && note >= 2 ? <StarFull className={`w-5 h-5`} /> : <StarEmpty className={`w-5 h-5`} />}</div>
          <div>{note && note >= 3 ? <StarFull className={`w-5 h-5`} /> : <StarEmpty className={`w-5 h-5`} />}</div>
          <div>{note && note >= 4 ? <StarFull className={`w-5 h-5`} /> : <StarEmpty className={`w-5 h-5`} />}</div>
          <div>{note && note >= 5 ? <StarFull className={`w-5 h-5`} /> : <StarEmpty className={`w-5 h-5`} />}</div>
        </div>
        <div className='max-h-48 overflow-y-auto mb-3 leading-4 text-sm'>
          <span className='pr-2'>Commentaire&nbsp;:</span>
          <ReadOnlyTextarea value={description} />
        </div>
        <div>
          <ReadOnlyBoolean label='Afficher le nom complet' value={afficheNomComplet} />
        </div>
        <div>
          <ReadOnlyBoolean label='Afficher le nom entreprise' value={afficheNomEntreprise} />
        </div>
        <div>
          <ReadOnlyBoolean label="Afficher l'avatar" value={afficheAvatar} />
        </div>
        <div className='my-3'>
          <InputSwitch label='Est validé' checked={estValide} onChange={() => setEstValide(!estValide)} />
        </div>
        {avis && avis.logs.length > 0 && (
          <Fragment>
            <p className='text-md poppins-medium'>Historique des actions</p>
            <div className='max-h-24 overflow-y-auto leading-4 text-sm'>
              {avis.logs.map((log) => (
                <p key={`${log.id}`}>
                  {log.user.prenom} {log.user.nom} <span className={`${couleurAction(log.action)}`}>{afficheAction(log.action)}</span> l'avis le{' '}
                  {new Intl.DateTimeFormat('default', {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: '2-digit',
                    hour12: false,
                  }).format(new Date(log.created_at as string))}
                </p>
              ))}
            </div>
          </Fragment>
        )}
      </DialogBody>
      <DialogFooter>
        <Button
          variant='text'
          color='gray'
          onClick={() => {
            setVisible(false);
          }}
          className='mr-1 !rounded-full'
          disabled={apiIsLoading}>
          Annuler
        </Button>
        <Button
          variant='filled'
          color='orange'
          onClick={() => {
            handleModifie();
            setVisible(false);
          }}
          disabled={note === null}>
          Confirmer
        </Button>
      </DialogFooter>
      {apiIsLoading ? <LoadingAbsolute /> : ''}
    </Dialog>
  );
};

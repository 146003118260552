import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { BanniereVitrine } from '../components/BanniereVitrine';
import { Container } from '../components/Container';
import { FilDariane } from '../components/FilDariane';
import { LoadingAbsolute } from '../components/LoadingAbsolute';
import { MainMenu } from '../components/MainMenu';
import { Config } from '../config/config';
import { useApi } from '../providers/ApiProvider';
import { GoodApiResponse, ErrorApiResponse } from '../types/api';
import { Site } from '../types/visitorRessources';
import { ArrowRightCircleIcon } from '@heroicons/react/20/solid';
import { handleError } from '../utils/ErrorHandler';

export const SitesScreen: FunctionComponent = () => {
  /**
   * On stocke si on est en train d'utiliser l'API ou non.
   */
  const [apiIsLoading, setApiIsLoading] = useState(false);

  /**
   * État local qui stocke les sites.
   */
  const [sites, setSites] = useState<Site[] | null>(null);

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  /**
   * Permet de recharger les données au chargement de page.
   */
  useEffect(() => {
    handleIndex();
  }, [client]);

  /**
   * Cette méthode va chercher la liste des sites.
   */
  const handleIndex = () => {
    setApiIsLoading(true);
    client
      .get<GoodApiResponse<Site[]>>(`/sites`)
      .then((response) => {
        setSites(response.data.data);
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  if (sites === null) {
    return <Fragment>Chargement...{apiIsLoading ? <LoadingAbsolute /> : ''}</Fragment>;
  }

  return (
    <Fragment>
      <Helmet>
        <title>Nos centres de formation - {Config.app_label}</title>
      </Helmet>
      <BanniereVitrine>
        <div className='absolute top-0 right-0 left-0 bottom-0 w-full h-full bg-secondary-2/70'></div>
        <div className='relative flex flex-col items-center h-full mx-auto'>
          <MainMenu className='relative pb-20' />
        </div>
        <div className='lg:max-w-xl xl:max-w-7xl w-full relative h-full 2xl:px-8 mt-20 lg:mt-[120px] text-center md:text-left mr-auto xl:mx-auto'>
          <h1 className='text-white text-3xl w-full uppercase poppins-semibold xl:pr-96'>Centres de formations</h1>
          <FilDariane
            className='bg-transparent w-full text-white pl-0 !mb-2 mt-2 xl:pr-96'
            items={[{ label: 'Centres de formations', link: '/nos-centres-de-formation' }]}
          />
        </div>
      </BanniereVitrine>
      <Container className='pb-11 bg-gray-100 h-full'>
        <div className='md:flex md:flex-row w-full'>
          <div className='basis-1/1 md:basis-3/5 lg:basis-2/3 h-full pt-11'>
            <div className='md:mr-4 mb-11'>
              <h2 className='text-2xl text-secondary-1 mt-7 poppins-semibold'>Découvrez nos centres de formations</h2>
              <ul className='mt-4'>
                {sites.map((site, index) => (
                  <li key={index}>
                    <Link to={`/sites/${site.slug}`} className='hover:text-[#f9a534] flex flex-row gap-2 items-center text-gray-700 poppins'>
                      <ArrowRightCircleIcon className='w-4 h-4 text-primary' />
                      <span>{site.nom}</span>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </Container>
    </Fragment>
  );
};

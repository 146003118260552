import { Dialog, DialogHeader, DialogBody, DialogFooter, Button } from '@material-tailwind/react';
import { FunctionComponent, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useApi } from '../../../providers/ApiProvider';
import { Demande, Facture } from '../../../types/AdminRessources';
import { GoodApiResponse, ErrorApiResponse } from '../../../types/api';
import { handleError } from '../../../utils/ErrorHandler';
import { AlertError } from '../../Alertes';
import { InputDate } from '../../Inputs/InputDate';
import { InputFile } from '../../Inputs/InputFile';
import { InputText } from '../../Inputs/InputText';
import { InputTextarea } from '../../Inputs/InputTextarea';
import { LoadingAbsolute } from '../../LoadingAbsolute';

/**
 * Ce composant permet d'afficher une Modale pour que les admins puissent ajouter une facture au salarié.
 *
 * @param visible - Précise si la modale et ouverte ou non. Provient du parent.
 * @param setVisible - Accesseur qui modifie la visibilité de la modale. Provient du parent.
 * @param demande - Informations de la demande. Provient du parent.
 * @param handleShow - Callback qui réalise l'appel pour recharger les données de la demande. Provient du parent.
 */
export const ModalAjouterFactureDemande: FunctionComponent<{
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  demande: Demande;
  handleShow: () => void;
}> = ({ visible, setVisible, demande, handleShow }) => {
  /**
   * On stocke si on est en train d'utiliser l'API ou non.
   */
  const [apiIsLoading, setApiIsLoading] = useState(false);

  /**
   * Permet de donner un numéro à la facture.
   */
  const [numero, setNumero] = useState<Facture['numero']>('');

  /**
   * Permet de donner un titre au document.
   */
  const [titre, setTitre] = useState<Facture['titre']>('');

  /**
   * Fichier à envoyer.
   */
  const [fichier, setFichier] = useState<Facture['fichier']>('');

  /**
   * Description du fichier, optionnel.
   */
  const [description, setDescription] = useState<Facture['description']>('');

  /**
   * Date limite de paiement.
   */
  const [dateLimitePaiement, setDateLimitePaiement] = useState<Facture['date_limite_paiement']>('');

  /**
   * On stocke l'eventuelle erreur.
   */
  const [error, setError] = useState<string | null>(null);

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  /**
   * Permet de réinitialiser les champs de la modale à chaque fois qu'on l'ouvre où qu'on la ferme.
   */
  useEffect(() => {
    setNumero('');
    setTitre('');
    setFichier('');
    setDescription('');
    setDateLimitePaiement('');
    setError(null);
  }, [visible]);

  /**
   * Envoie le fichier par l'API.
   */
  const handleEnvoie = () => {
    setApiIsLoading(true);

    client
      .post<GoodApiResponse>(`/admin/factures`, {
        numero: numero,
        titre: titre,
        fichier: fichier,
        description: description,
        date_limite_paiement: dateLimitePaiement,
        demande_id: demande.id,
        groupe_id: demande.groupe ? demande.groupe.id : null,
        formation_id: demande.formation.id,
        profil_id: demande.profil.id,
      })
      .then(() => {
        setVisible(false);
        handleShow();
        toast.success(`Envoie de la facture réussi`);
      })
      .catch((error: ErrorApiResponse) => {
        handleError(
          error,
          () => {
            setError(error.response.data.message);
          },
          () => {
            setError(error.message);
          },
        );
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  return (
    <Dialog open={visible} size='lg' handler={setVisible}>
      <DialogHeader>Ajouter une facture</DialogHeader>
      <DialogBody divider>
        {error && <AlertError text={error} />}
        <div className='flex flex-row items-center gap-3'>
          <div className='w-1/2'>
            <div>
              <p>Numéro</p>
            </div>
            <InputText value={numero} onChange={(e) => setNumero(e.target.value)} />
          </div>
          <div className='w-1/2'>
            <div>
              <p>Fichier :</p>
            </div>
            <InputFile
              onChange={(titre, base64) => {
                setTitre(titre);
                setFichier(base64);
                setError(null);
              }}
            />
          </div>
        </div>

        <div className='pt-4'>
          <p>Description (facultatif)</p>
        </div>
        <InputTextarea value={description ? description : ''} onChange={(e) => setDescription(e.target.value)} />

        <div className='pt-4'>
          <p>Date limite de paiement</p>
        </div>
        <InputDate
          value={dateLimitePaiement as string}
          onChange={(e) => {
            setDateLimitePaiement(e.target.value);
          }}
        />
      </DialogBody>
      <DialogFooter>
        <Button
          variant='text'
          color='gray'
          onClick={() => {
            setNumero('');
            setTitre('');
            setFichier('');
            setDateLimitePaiement('');
            setError(null);
            setVisible(false);
          }}
          className='mr-1 !rounded-full'
          disabled={apiIsLoading}>
          Annuler
        </Button>
        <Button
          variant='filled'
          color='orange'
          onClick={() => {
            handleEnvoie();
          }}
          disabled={apiIsLoading}>
          Confirmer
        </Button>
      </DialogFooter>
      {apiIsLoading ? <LoadingAbsolute /> : ''}
    </Dialog>
  );
};

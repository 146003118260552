import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingAbsolute } from '../../../components/LoadingAbsolute';
import { useApi } from '../../../providers/ApiProvider';
import { HeaderForm } from '../../../components/HeaderForm';
import { AxiosResponse } from 'axios';
import { DiplomePrepare, Formation, Groupe, Matiere, Metier, Personnel, SecteurActivite, Site } from '../../../types/schematics';
import { ModeFormulaire, DropdownRessource, Ressource } from '../../../types';
import { BaseIndexParams, PaginatedApiResponse, GoodApiResponse, ErrorApiResponse } from '../../../types/api';
import { Config } from '../../../config/config';
import { Helmet } from 'react-helmet';
import { ReadOnlyNumber } from '../../../components/Inputs/ReadOnlyNumber';
import { Avis } from '../../../types/AdminRessources';
import nl2br from 'react-nl2br';
import { Logo } from '../../../components/Logo';
import { StarGroupDisplay } from '../../../components/Stars/StarGroupDisplay';
import { Avatar } from '../../../components/Avatar';
import { ReadOnlyText } from '../../../components/Inputs/ReadOnlyText';
import { ReadOnlyBoolean } from '../../../components/Inputs/ReadOnlyBoolean';
import { ReadOnlySelect } from '../../../components/Inputs/ReadOnlySelect';
import { ReadOnlyTextarea } from '../../../components/Inputs/ReadOnlyTextarea';
import { handleError } from '../../../utils/ErrorHandler';
import { Tab, TabPanel, Tabs, TabsBody, TabsHeader } from '../../../components/Onglets';
import { SubmitSave } from '../../../components/SubmitSave';

type Props = { modeFormulaire: ModeFormulaire };

/**
 * Ce composant doit afficher un formulaire qui à 3 modes de fonctionnement. Les 2 principaux sont la création et l'édition.
 * On a également un mode lecture seule. L'idée c'est de gérer une partie du CRUD.
 * C'est dans ce composant que vont se faire les appels API pour créer, éditer et voir une ressource en particulier.
 *
 * @param modeFormulaire - Mode du formulaire (creer, editer et voir).
 * @returns JSX du composant
 */
export const AdmingcFormationFormScreen: FunctionComponent<Props> = ({ modeFormulaire }) => {
  // On initialise les données dont on va avoir besoin sur cette page

  /**
   * On stocke si on est en train d'utiliser l'API ou non.
   */
  const [apiIsLoading, setApiIsLoading] = useState(false);

  /**
   * On stocke l'état des formes juridiques.
   */
  const [diplomePrepares, setDiplomePrepares] = useState<DropdownRessource[] | null>(null);

  /**
   * On stocke l'état des types d'employeurs.
   */
  const [personnelResponsables, setPersonnelResponsables] = useState<DropdownRessource[] | null>(null);

  /**
   * On stocke l'état des convention collectives.
   */
  const [secteurActivites, setSecteurActivites] = useState<DropdownRessource[] | null>(null);

  /**
   * On stocke les groupes possédés par la formation.
   */
  const [ownedGroupes, setOwnedGroupes] = useState<Groupe[] | null>(null);

  /**
   * On stocke les matières possédés par la formation.
   */
  const [ownedMatieres, setOwnedMatieres] = useState<Matiere[] | null>(null);

  /**
   * On stocke les sites possédés par la formation.
   */
  const [ownedSites, setOwnedSites] = useState<Site[] | null>(null);

  /**
   * On stocke l'état des métiers.
   */
  const [metiers, setMetiers] = useState<DropdownRessource[] | null>(null);

  /**
   * On stocke les métiers possédés par la formation.
   */
  const [ownedMetiers, setOwnedMetiers] = useState<DropdownRessource[] | null>(null);

  /**
   * On stocke les avis possédés par la formation.
   */
  const [ownedAvis, setOwnedAvis] = useState<Avis[] | null>(null);

  /**
   * On stocke le nom afin de l'afficher en mode édition et voir.
   */
  const [nom, setNom] = useState<Formation['nom']>('');

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  /**
   * Contient un état général des valeurs des champs du formulaire ainsi que les champs qui ont reçu une erreur lors de l'envoi du formulaire.
   */
  const [formInputs, setFormInputs] = useState<{ [K in keyof Formation]: { value: Formation[K]; error: boolean } }>({
    diplome_prepare_id: { value: null, error: false },
    personnel_responsable_id: { value: null, error: false },
    secteur_activite_id: { value: null, error: false },
    nom: { value: '', error: false },
    nom_abrege: { value: null, error: false },
    nom_etendu: { value: null, error: false },
    nom_commercial: { value: null, error: false },
    image_couverture: { value: null, error: false },
    slug: { value: '', error: false },
    code_en: { value: null, error: false },
    code_repertoire_specifique: { value: null, error: false },
    niveau: { value: null, error: false },
    duree_mois: { value: null, error: false },
    duree_jours: { value: null, error: false },
    duree_heures: { value: null, error: false },
    prix_vente: { value: null, error: false },
    unites_facturation: { value: null, error: false },
    nom_unites_facturation: { value: null, error: false },
    mode_evaluation: { value: null, error: false },
    description: { value: null, error: false },
    description_commerciale: { value: null, error: false },
    description_seo: { value: null, error: false },
    texte_objectifs: { value: null, error: false },
    texte_contenu: { value: null, error: false },
    texte_methodologie: { value: null, error: false },
    texte_modalites_evaluation: { value: null, error: false },
    texte_modalites_access: { value: null, error: false },
    texte_public: { value: null, error: false },
    texte_prerequis: { value: null, error: false },
    texte_accessibilite: { value: null, error: false },
    est_cpf: { value: false, error: false },
    est_certifiant: { value: false, error: false },
    est_ecommerce: { value: false, error: false },
    est_riseup: { value: false, error: false },
    est_visible: { value: false, error: false },
    yp_code: { value: null, error: false },
    created_at: { value: null, error: false },
  });

  const navigate = useNavigate();

  const params = useParams();

  // On a besoin du useEffect lorsque l'on est en mode édition et lecture pour précharger le formulaire avec les données de la ressource.

  useEffect(() => {
    handleIndex();
    switch (modeFormulaire) {
      case ModeFormulaire.creer:
        break;
      case ModeFormulaire.voir:
      case ModeFormulaire.editer:
        if (params.id === undefined) {
          navigate('/admingc/formations');
        }
        handleShow(parseInt(params.id as string));
        break;

      default:
        break;
    }
  }, [client]);

  /**
   * Retrouve Les ressources nécessaires pour afficher les dropdown et les stockent dans des liste déroulantes.
   *
   * @param indexParams - Paramètres de la recherche.
   */
  const handleIndex = () => {
    setApiIsLoading(true);
    const baseParams: BaseIndexParams = { as_foreign: true, no_pagination: true };

    const promises: Promise<AxiosResponse>[] = [
      client.get<PaginatedApiResponse<DiplomePrepare>, BaseIndexParams>('/admingc/diplomes-prepares', { params: { ...baseParams, sort: 'nom' } }),
      client.get<PaginatedApiResponse<Personnel>, BaseIndexParams>('/admingc/personnels', { params: { ...baseParams, sort: 'nom' } }),
      client.get<PaginatedApiResponse<SecteurActivite>, BaseIndexParams>('/admingc/secteurs-activite', { params: { ...baseParams, sort: 'nom' } }),
    ];

    if (modeFormulaire !== ModeFormulaire.creer) {
      promises.push(client.get<GoodApiResponse<Groupe>>(`/admingc/formations/${parseInt(params.id as string)}/groupes`) as Promise<AxiosResponse>);
      promises.push(client.get<GoodApiResponse<Matiere>>(`/admingc/formations/${parseInt(params.id as string)}/matieres`) as Promise<AxiosResponse>);
      promises.push(client.get<GoodApiResponse<Site>>(`/admingc/formations/${parseInt(params.id as string)}/sites`) as Promise<AxiosResponse>);
      promises.push(
        client.get<PaginatedApiResponse<Metier>, BaseIndexParams>('/admingc/metiers', { params: { ...baseParams, sort: 'nom' } }) as Promise<AxiosResponse>,
      );
      promises.push(client.get<PaginatedApiResponse<Metier>>(`/admingc/formations/${parseInt(params.id as string)}/metiers`) as Promise<AxiosResponse>);
      promises.push(client.get<PaginatedApiResponse<Avis>>(`/admingc/formations/${parseInt(params.id as string)}/avis`) as Promise<AxiosResponse>);
    }

    Promise.all(promises)
      .then((response) => {
        setDiplomePrepares(response[0].data.data as unknown as DropdownRessource[]);
        setPersonnelResponsables(response[1].data.data as unknown as DropdownRessource[]);
        setSecteurActivites(response[2].data.data as unknown as DropdownRessource[]);
        if (modeFormulaire !== ModeFormulaire.creer) {
          setOwnedGroupes(response[3].data.data as unknown as Groupe[]);
          setOwnedMatieres(response[4].data.data as unknown as Matiere[]);
          setOwnedSites(response[5].data.data as unknown as Site[]);
          setMetiers(response[6].data.data as unknown as DropdownRessource[]);
          setOwnedMetiers(response[7].data.data as unknown as DropdownRessource[]);
          setOwnedAvis(response[8].data.data as unknown as Avis[]);
        }
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Retrouve par le réseau la ressource liée à l'ID foruni et la met dans un état local.
   * Ne doit être utilisé qu'en mode édition et lecture seule.
   *
   * @param ressourceId - ID de la ressource dont on veut retrouver les informations.
   */
  const handleShow = (ressourceId: Ressource['id']) => {
    if (apiIsLoading === true) {
      return;
    }

    setApiIsLoading(true);
    client
      .get<GoodApiResponse<Formation>>(`/admingc/formations/${ressourceId}`)
      .then((reponse) => {
        const updatedInputs = Object.keys(reponse.data.data).reduce((acc, key) => {
          if (key === 'prix_vente' && reponse.data.data.prix_vente !== null) {
            return { ...acc, [key]: { value: parseFloat((reponse.data.data.prix_vente / 100).toFixed(2)), error: false } };
          } else {
            return { ...acc, [key]: { value: reponse.data.data[key as keyof Formation], error: false } };
          }
        }, formInputs);
        setFormInputs(updatedInputs);
        setNom(updatedInputs.nom.value);
      })
      .catch((error: ErrorApiResponse<Formation>) => {
        handleError(error, () => {
          navigate('/admingc/formations');
        });
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Permet d'écrire le mot correspondant au mode de fomulaire. Ce mot s'affiche au début du formulaire juste à coté de la ressource.
   *
   * @param modeFormulaire - Mode du formulaire (creer, editer et voir).
   * @returns Mot à afficher correspondant au mode de formulaire.
   * @throws Lance une erreur si aucun mode formulaire n'est fourni.
   */
  const afficheModeFormulaire = (modeFormulaire: ModeFormulaire) => {
    switch (modeFormulaire) {
      case ModeFormulaire.creer:
        return 'Création';
      case ModeFormulaire.editer:
        return 'Édition';
      case ModeFormulaire.voir:
        return 'Voir';

      default:
        throw new Error("Vous n'avez pas choisi un mode de formulaire adéquat.");
    }
  };

  /**
   * Paramètres tabs
   * @constant
   */
  const data = [
    {
      label: 'Sessions',
      value: 'groupes',
    },
    {
      label: 'Matières',
      value: 'matieres',
    },
    {
      label: 'Métiers',
      value: 'metiers',
    },
    {
      label: 'Sites',
      value: 'sites',
    },
    {
      label: 'Avis',
      value: 'avis',
    },
  ] as const;

  if (
    diplomePrepares === null ||
    personnelResponsables === null ||
    secteurActivites === null ||
    (modeFormulaire !== ModeFormulaire.creer &&
      (ownedGroupes === null || ownedMatieres === null || ownedSites === null || metiers === null || ownedMetiers === null || ownedAvis === null))
  ) {
    return <Fragment>Chargement...</Fragment>;
  }

  return (
    <Fragment>
      <Helmet>
        <title>
          {afficheModeFormulaire(modeFormulaire)} Formation - {Config.app_label}
        </title>
      </Helmet>
      <HeaderForm
        text={`${afficheModeFormulaire(modeFormulaire)} formation ${modeFormulaire !== ModeFormulaire.creer ? nom : ''}`}
        returnUrl='/admingc/formations'
      />
      <Tabs value='general'>
        <TabsHeader>
          <Tab value='general'>Général</Tab>
          {modeFormulaire !== ModeFormulaire.creer &&
            data.map(({ label, value }) => (
              <Tab key={value} value={value}>
                {label}
              </Tab>
            ))}
        </TabsHeader>
        <TabsBody>
          <TabPanel value='general'>
            <form className='relative'>
              <div className='bg-white p-5 rounded-b-lg'>
                <div className='container mx-auto bg-white rounded'>
                  <fieldset className='pb-7 mb-7 border-b'>
                    <legend className='block text-left mb-3 text-xl text-secondary-1'>Informations</legend>
                    <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-2 items-center'>
                      <div>
                        <ReadOnlyText label='Nom commercial' value={formInputs.nom_commercial.value as string} error={formInputs.nom_commercial.error} />
                      </div>
                      <div>
                        <ReadOnlyText label='Nom YParéo' value={formInputs.nom.value as string} error={formInputs.nom.error} />
                      </div>
                      <div>
                        <ReadOnlyText label='Nom abrégé' value={formInputs.nom_abrege.value as string} error={formInputs.nom_abrege.error} />
                      </div>
                    </div>
                    <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-2 items-center'>
                      <div>
                        <ReadOnlyText label='Slug' value={formInputs.slug.value as string} error={formInputs.slug.error} />
                      </div>
                      <div>
                        <ReadOnlyText label='Nom étendu' value={formInputs.nom_etendu.value as string} error={formInputs.nom_etendu.error} />
                      </div>
                    </div>
                    <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-2 items-center'>
                      <div>
                        <ReadOnlySelect
                          label='Diplôme préparé'
                          value={
                            formInputs.diplome_prepare_id.value !== null && formInputs.diplome_prepare_id.value !== undefined
                              ? formInputs.diplome_prepare_id.value.toString()
                              : ''
                          }
                          error={formInputs.diplome_prepare_id.error}
                          options={diplomePrepares}
                        />
                      </div>
                    </div>
                    <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-2 items-center'>
                      <div>
                        <ReadOnlyNumber label='Durée heures' value={formInputs.duree_heures.value as number} error={formInputs.duree_heures.error} />
                      </div>
                    </div>
                    <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-2 items-center'>
                      <div>
                        <ReadOnlyNumber label='Prix de vente' value={formInputs.prix_vente.value as number} error={formInputs.prix_vente.error} />
                      </div>
                    </div>
                    <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-2 items-center'>
                      <div>
                        <ReadOnlyText label='Code EN' value={formInputs.code_en.value as string} error={formInputs.code_en.error} />
                      </div>
                      <div>
                        <ReadOnlyText
                          label='Code répertoire spécifique'
                          value={formInputs.code_repertoire_specifique.value as string}
                          error={formInputs.code_repertoire_specifique.error}
                        />
                      </div>
                      <div>
                        <ReadOnlyNumber label='YParéo CodeFormation' value={formInputs.yp_code.value} error={formInputs.yp_code.error} />
                      </div>
                    </div>
                  </fieldset>
                  <fieldset className='pb-7 mb-7 border-b'>
                    <legend className='block text-left mb-3 text-xl text-secondary-1'>Descriptions</legend>
                    <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-2 gap-2 items-center'>
                      <div>
                        <ReadOnlyTextarea
                          label='Description commerciale'
                          value={formInputs.description_commerciale.value as string}
                          error={formInputs.description_commerciale.error}
                        />
                      </div>
                      <div>
                        <ReadOnlyTextarea label='Description YParéo' value={formInputs.description.value as string} error={formInputs.description.error} />
                      </div>
                      <div>
                        <ReadOnlyTextarea label='Description SEO' value={formInputs.description_seo.value as string} error={formInputs.description_seo.error} />
                      </div>
                      <div>
                        <ReadOnlyTextarea
                          label='Objectifs de la formation'
                          value={formInputs.texte_objectifs.value as string}
                          error={formInputs.texte_objectifs.error}
                        />
                      </div>
                      <div>
                        <ReadOnlyTextarea
                          label='Contenu de la formation'
                          value={formInputs.texte_contenu.value as string}
                          error={formInputs.texte_contenu.error}
                        />
                      </div>
                      <div>
                        <ReadOnlyTextarea
                          label='Méthode mobilisée'
                          value={formInputs.texte_methodologie.value as string}
                          error={formInputs.texte_methodologie.error}
                        />
                      </div>
                      <div>
                        <ReadOnlyTextarea
                          label="Modalité d'évaluation"
                          value={formInputs.texte_modalites_evaluation.value as string}
                          error={formInputs.texte_modalites_evaluation.error}
                        />
                      </div>
                      <div>
                        <ReadOnlyTextarea
                          label="Modalité d'accés"
                          value={formInputs.texte_modalites_access.value as string}
                          error={formInputs.texte_modalites_access.error}
                        />
                      </div>
                      <div>
                        <ReadOnlyTextarea label='Public' value={formInputs.texte_public.value as string} error={formInputs.texte_public.error} />
                      </div>
                      <div>
                        <ReadOnlyTextarea label='Prérequis' value={formInputs.texte_prerequis.value as string} error={formInputs.texte_prerequis.error} />
                      </div>
                      <div>
                        <ReadOnlyTextarea
                          label='Accessibilité'
                          value={formInputs.texte_accessibilite.value as string}
                          error={formInputs.texte_accessibilite.error}
                        />
                      </div>
                    </div>
                  </fieldset>
                  <fieldset className='pb-7 mb-7'>
                    <legend className='block text-left mb-3 text-xl text-secondary-1'>Paramètres</legend>
                    <div className='grid grid-cols-1 gap-2 items-center'>
                      <div>
                        <ReadOnlyBoolean label='Est e-commerce' value={formInputs.est_ecommerce.value !== null ? formInputs.est_ecommerce.value : false} />
                      </div>
                      <div>
                        <ReadOnlyBoolean label='CPF pris en charge' value={formInputs.est_cpf.value !== null ? formInputs.est_cpf.value : false} />
                      </div>
                      <div>
                        <ReadOnlyBoolean label='Est certifiant' value={formInputs.est_certifiant.value !== null ? formInputs.est_certifiant.value : false} />
                      </div>
                      <div>
                        <ReadOnlyBoolean label='Est visible' value={formInputs.est_visible.value !== null ? formInputs.est_visible.value : false} />
                      </div>
                    </div>
                  </fieldset>
                </div>

                <div className='container mx-auto w-11/12 xl:w-full'>
                  <div className='w-full py-4 sm:px-0 bg-white flex justify-end'>{modeFormulaire !== ModeFormulaire.voir && <SubmitSave />}</div>
                </div>
              </div>
            </form>
          </TabPanel>
          {modeFormulaire !== ModeFormulaire.creer && (
            <TabPanel value='groupes'>
              <div className='bg-white w-full overflow-x-auto'>
                <table className='table-fixed min-w-full bg-white rounded-b-lg'>
                  <thead className='text-sm text-gray-500 text-left border-b'>
                    <tr>
                      <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Nom</th>
                      <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Date de début</th>
                      <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Date de fin</th>
                      <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Capacité min</th>
                      <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Nombre d'aprenants</th>
                      <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Capacité max</th>
                      <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Prix de vente</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(ownedGroupes as Groupe[]).map((groupe) => (
                      <tr key={groupe.id} className=' border-gray-100 border-b hover:bg-gray-100'>
                        <td className='px-4 py-3 text-gray-800 text-sm'>{groupe.nom_commercial}</td>
                        <td className='px-4 py-3 text-gray-800 text-sm'>
                          {groupe.date_debut
                            ? new Intl.DateTimeFormat('default', {
                                year: 'numeric',
                                month: 'numeric',
                                day: 'numeric',
                              }).format(new Date(groupe.date_debut as string))
                            : ''}
                        </td>
                        <td className='px-4 py-3 text-gray-800 text-sm'>
                          {groupe.date_fin
                            ? new Intl.DateTimeFormat('default', {
                                year: 'numeric',
                                month: 'numeric',
                                day: 'numeric',
                              }).format(new Date(groupe.date_fin as string))
                            : ''}
                        </td>
                        <td className='px-4 py-3 text-gray-800 text-sm text-right'>{groupe.capacite_min}</td>
                        <td className='px-4 py-3 text-gray-800 text-sm text-right'>{groupe.nb_apprenants_actifs}</td>
                        <td className='px-4 py-3 text-gray-800 text-sm text-right'>{groupe.capacite_max}</td>
                        <td className='px-4 py-3 text-gray-800 text-sm text-right'>
                          {groupe.prix_vente !== undefined && groupe.prix_vente !== null
                            ? new Intl.NumberFormat('default', { style: 'currency', currency: 'EUR' }).format(groupe.prix_vente / 100)
                            : 'Non renseigné'}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </TabPanel>
          )}
          {modeFormulaire !== ModeFormulaire.creer && (
            <TabPanel value='matieres'>
              <div className='bg-white w-full overflow-x-auto'>
                <table className='table-fixed min-w-full bg-white rounded-b-lg'>
                  <thead className='text-sm text-gray-500 text-left border-b'>
                    <tr>
                      <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Nom</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(ownedMatieres as Matiere[]).map((matiere) => (
                      <tr key={matiere.id} className=' border-gray-100 border-b hover:bg-gray-100'>
                        <td className='px-4 py-3 text-gray-800 text-sm'>{matiere.nom}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </TabPanel>
          )}
          {modeFormulaire !== ModeFormulaire.creer && (
            <TabPanel value='metiers'>
              <div className='columns-1 sm:columns-2 xl:columns-3 gap-2 items-start bg-white p-5 rounded-b-lg'>
                {(metiers as DropdownRessource[]).map((metier) => (
                  <div key={metier.id} className='pb-2'>
                    <ReadOnlyBoolean label={metier.label} value={ownedMetiers?.findIndex((ownedMetier) => ownedMetier.id === metier.id) !== -1} />
                  </div>
                ))}
              </div>
            </TabPanel>
          )}
          {modeFormulaire !== ModeFormulaire.creer && (
            <TabPanel value='sites'>
              <div className='bg-white w-full overflow-x-auto'>
                <table className='table-fixed min-w-full bg-white rounded-b-lg'>
                  <thead className='text-sm text-gray-500 text-left border-b'>
                    <tr>
                      <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Nom</th>
                      <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Slug</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(ownedSites as Site[]).map((site) => (
                      <tr key={site.id} className=' border-gray-100 border-b hover:bg-gray-100'>
                        <td className='px-4 py-3 text-gray-800 text-sm'>{site.nom_commercial}</td>
                        <td className='px-4 py-3 text-gray-800 text-sm'>{site.slug}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </TabPanel>
          )}
          {modeFormulaire !== ModeFormulaire.creer && (
            <TabPanel value='avis'>
              <div className='bg-white w-full overflow-x-auto'>
                <table className='table-fixed min-w-full bg-white rounded-b-lg'>
                  <thead className='text-sm text-gray-500 text-left border-b'>
                    <tr>
                      <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Entreprise</th>
                      <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Salarié</th>
                      <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Note</th>
                      <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Description</th>
                      <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Date de validation</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(ownedAvis as Avis[]).map((avi) => (
                      <tr key={avi.id} className={`border-gray-100 border-b hover:bg-gray-100 cursor-pointer`}>
                        <td className='px-4 py-3 text-gray-800 text-sm'>
                          {avi.entreprise && (
                            <div className='flex flex-row gap-2 items-center'>
                              <div>
                                <Logo className='rounded h-10 w-10 object-cover' src={avi.entreprise.image_logo} />
                              </div>
                              <div>{`${avi.entreprise.enseigne}`}</div>
                            </div>
                          )}
                        </td>
                        <td className='pr-4 py-3 text-gray-800 text-sm'>
                          {avi.user && (
                            <div className='flex flex-row gap-2 items-center'>
                              <div>
                                <Avatar src={avi.user.image_avatar as string} />
                              </div>
                              <div>{`${avi.user.prenom} ${avi.user.nom}`}</div>
                            </div>
                          )}
                        </td>
                        <td className='px-4 py-3 text-gray-800 text-sm'>
                          <StarGroupDisplay note={avi.note} />
                        </td>
                        <td className='px-4 py-3 text-gray-800 text-sm'>
                          <div>{nl2br(avi.description)}</div>
                        </td>
                        <td className='px-4 py-3 text-gray-800 text-sm'>
                          {avi.date_validation
                            ? new Intl.DateTimeFormat('default', {
                                year: 'numeric',
                                month: 'numeric',
                                day: 'numeric',
                                hour: 'numeric',
                                minute: 'numeric',
                                second: 'numeric',
                                hour12: false,
                              }).format(new Date(avi.date_validation as string))
                            : 'Pas encore validé'}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </TabPanel>
          )}
        </TabsBody>
      </Tabs>
      {apiIsLoading ? <LoadingAbsolute /> : ''}
    </Fragment>
  );
};

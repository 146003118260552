import { Dialog, DialogHeader, DialogBody, DialogFooter, Button } from '@material-tailwind/react';
import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { useApi } from '../../../providers/ApiProvider';
import { GoodApiResponse, ErrorApiResponse } from '../../../types/api';
import { ProfilEntreprise } from '../../../types/auth';
import { LoadingAbsolute } from '../../LoadingAbsolute';
import { Document } from '../../../types/AdminRessources';
import nl2br from 'react-nl2br';
import { handleError } from '../../../utils/ErrorHandler';
import { FormationSessionDisplay } from '../../FormationSessionDisplay';
import { UserDisplay } from '../../UserDisplay';
import { useNavigate } from 'react-router-dom';
import { DownloadLink } from '../../DownloadLink';

/**
 * Ce composant permet d'afficher une Modale pour lister les documents du salarié.
 *
 * @param visible - Précise si la modale et ouverte ou non. Provient du parent.
 * @param setVisible - Accesseur qui modifie la visibilité de la modale. Provient du parent.
 * @param profil - Profil de l'entreprise. Provient du parent.
 */
export const ModalDocumentsSalaries: FunctionComponent<{
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  profil: ProfilEntreprise | null;
}> = ({ visible, setVisible, profil }) => {
  /**
   * On stocke si on est en train d'utiliser l'API ou non.
   */
  const [apiIsLoading, setApiIsLoading] = useState(false);

  /**
   * On stocke l'état des document liés à la demande.
   */
  const [documents, setDocuments] = useState<Document[] | null>(null);

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  /**
   * Permet au composant de changer de page.
   */
  const navigate = useNavigate();

  /**
   * On va chercher les informations du profil dès que celui-ci change. S'il est null on supprime les documents.
   */
  useEffect(() => {
    if (profil === null) {
      setDocuments(null);
    } else {
      if (visible) {
        handleListeDocuments();
      }
    }
  }, [profil, visible]);

  /**
   * Récupère les documents du salarié et les stocke dans un état local.
   */
  const handleListeDocuments = () => {
    setApiIsLoading(true);

    client
      .get<GoodApiResponse<Document[]>, { params: { profil_id: ProfilEntreprise['id'] } }>('/admin/documents', {
        params: {
          profil_id: (profil as ProfilEntreprise).id,
        },
      })
      .then((response) => {
        setDocuments(response.data.data);
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  return (
    <Dialog open={visible} size='lg' handler={setVisible}>
      <DialogHeader>Documents du salarié</DialogHeader>
      <DialogBody divider>
        <div>
          {profil ? (
            <Fragment>
              <UserDisplay user={profil.user} />
              <div>
                <div className='bg-white w-full overflow-x-auto overflow-y-auto h-96'>
                  <div className='flex flex-row justify-end'></div>
                  <table className='table-fixed min-w-full bg-white rounded-b-lg'>
                    <thead className='text-sm text-gray-500 text-left border-b'>
                      <tr>
                        <th className='py-4 text-secondary-1 font-semibold px-4 text-left text-sm tracking-normal leading-4'>Formation et session</th>
                        <th className='py-4 text-secondary-1 font-semibold px-4 text-left text-sm tracking-normal leading-4'>Fichier</th>
                        <th className='py-4 text-secondary-1 font-semibold px-4 text-left text-sm tracking-normal leading-4'>Description</th>
                        <th className='py-4 text-secondary-1 font-semibold px-4 text-left text-sm tracking-normal leading-4'>Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {documents &&
                        documents.map((document) => (
                          <tr
                            key={document.id}
                            className='border-gray-100 border-b hover:bg-gray-100'
                            onDoubleClick={() => navigate(`/admin/documents/${document.id}`)}>
                            <td className='px-4 py-3'>
                              <FormationSessionDisplay formation={document.formation} groupe={document.groupe} />
                            </td>
                            <td className='px-4 py-3 text-gray-800 text-sm'>
                              {document.fichier && <DownloadLink fichier={document.fichier} titre={document.titre} />}
                            </td>
                            <td className='px-4 py-3 text-gray-800 text-sm'>{document.description ? nl2br(document.description) : ''}</td>
                            <td className='px-4 py-3 text-gray-800 text-sm'>
                              {document.created_at
                                ? new Intl.DateTimeFormat('default', {
                                    year: 'numeric',
                                    month: 'numeric',
                                    day: 'numeric',
                                  }).format(new Date(document.created_at as string))
                                : ''}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </Fragment>
          ) : (
            <p></p>
          )}
          {apiIsLoading ? <LoadingAbsolute /> : ''}
        </div>
      </DialogBody>
      <DialogFooter>
        <Button
          variant='text'
          color='gray'
          onClick={() => {
            setVisible(false);
          }}
          className='mr-1'>
          Fermer
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

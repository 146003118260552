import { BanniereVitrine } from '../components/BanniereVitrine';
import { Container } from '../components/Container';
import { MainMenu } from '../components/MainMenu';
import { FilDariane } from '../components/FilDariane';
import { Card, CardBody, CardHeader } from '@material-tailwind/react';
import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { Config } from '../config/config';
import { Helmet } from 'react-helmet';
import { Filiere } from '../types/visitorRessources';
import { useApi } from '../providers/ApiProvider';
import { GoodApiResponse, ErrorApiResponse } from '../types/api';
import { Link } from 'react-router-dom';
import { CubeTransparentIcon } from '@heroicons/react/24/outline';
import { ArrowRightCircleIcon } from '@heroicons/react/20/solid';
import { handleError } from '../utils/ErrorHandler';

export const FilieresScreen: FunctionComponent = () => {
  /**
   * État local qui socke la liste des filières.
   */
  const [filieres, setFilieres] = useState<Filiere[]>([]);

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  /**
   * Permet de recharger les données au chargement de page ou au changement de paramètres
   */
  useEffect(() => {
    handleIndex();
  }, [client]);

  /**
   * Cette méthode va chercher les informations dans l'API pour afficher les filières.
   */
  const handleIndex = () => {
    client
      .get<GoodApiResponse<Filiere[]>>('filieres')
      .then((response) => {
        setFilieres(response.data.data);
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error);
      });
  };

  return (
    <Fragment>
      <Helmet>
        <title>Fillières - {Config.app_label}</title>
      </Helmet>
      <BanniereVitrine>
        <div className='absolute top-0 right-0 left-0 bottom-0 w-full h-full bg-secondary-2/70'></div>
        <div className='relative flex flex-col items-center h-full mx-auto'>
          <MainMenu className='relative pb-20' />
        </div>
        <div className='lg:max-w-xl xl:max-w-7xl w-full relative h-full 2xl:px-8 mt-20 lg:mt-[120px] text-center md:text-left mr-auto xl:mx-auto'>
          <h1 className='text-white text-3xl w-full uppercase mt-7 poppins-semibold xl:pr-96'>Filières et métiers</h1>
          <FilDariane className='bg-transparent w-full text-white pl-0 !mb-2 mt-2 xl:pr-96' items={[{ label: 'Filières et métiers', link: `/filieres/` }]} />
        </div>
      </BanniereVitrine>
      <Container className='bg-gray-100 h-full py-7'>
        <h2 className='text-2xl text-secondary-1 pt-11 text-center poppins-semibold'>
          Vous cherchez un métier concret et utile aux autres dans lequel vous pourrez évoluer&nbsp;?
          <br />
          Nous en avons plus de 30 à vous proposer dans différentes filières.
        </h2>
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 pb-11'>
          {filieres.map((filiere, index) => (
            <Card key={index} className='mt-7 col-span-1' style={{ backgroundColor: filiere.couleur ? filiere.couleur : '#1f2937' }}>
              <CardHeader floated={false} className='relative h-56 shadow-md shadow-gray-700/40'>
                {filiere.image_couverture ? (
                  <img src={filiere.image_couverture} className='h-full w-full object-cover' loading='lazy' />
                ) : (
                  <div className={`w-full h-full object-cover bg-gray-700 flex items-center justify-center`}>
                    <CubeTransparentIcon className='h-11 w-11 text-white/50' />
                  </div>
                )}
              </CardHeader>
              <CardBody>
                <Link to={filiere.slug}>
                  <h5 className='mb-2 text-white poppins-semibold'>{filiere.nom}</h5>
                </Link>
                <div className='w-full'>
                  <ul className='md:pr-2'>
                    {filiere.metiers.map((metier, index) => (
                      <li key={index}>
                        <Link to={`/metiers/${metier.slug}`} className='flex flex-row items-center gap-2 w-full text-white hover:text-secondary-1 poppins'>
                          <ArrowRightCircleIcon className='w-4 h-4' />
                          <span>{metier.nom}</span>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </CardBody>
            </Card>
          ))}
        </div>
      </Container>
    </Fragment>
  );
};

import { Fragment, FunctionComponent } from 'react';
import { RoleEnum } from '../types/auth';

/**
 * Ce composant affiche les rôles de manière stylisé.
 *
 * @param text - Rôle de l'utilisateur.
 */
export const ConstRoles: FunctionComponent<{ text: RoleEnum }> = ({ text }) => {
  /**
   * @var Texte transformé.
   * @defaultValue `''`.
   */
  let translatedText = '';

  switch (text) {
    case RoleEnum.admin:
      translatedText = 'Administrateur';
      break;
    case RoleEnum.dev:
      translatedText = 'Développeur';
      break;
    case RoleEnum.gestionnaireCentre:
      translatedText = 'Gestionnaire de centre(s)';
      break;
    case RoleEnum.standard:
      translatedText = 'Standard';
      break;
    default:
      translatedText = text;
  }

  return <Fragment>{translatedText}</Fragment>;
};

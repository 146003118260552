import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { AcademicCapIcon, CheckIcon, EllipsisVerticalIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Helmet } from 'react-helmet';
import toast from 'react-hot-toast';
import { Menu, MenuHandler, Button, MenuList, MenuItem, Tooltip } from '@material-tailwind/react';
import { Config } from '../../../config/config';
import nl2br from 'react-nl2br';
import { LoadingAbsolute } from '../../../components/LoadingAbsolute';
import { ModalModificationAvisAdmin } from '../../../components/Modales/Admin/ModalModificationAvisAdmin';
import { useApi } from '../../../providers/ApiProvider';
import { GoodApiResponse, ErrorApiResponse } from '../../../types/api';
import { Avis } from '../../../types/AdminRessources';
import { InputDate } from '../../../components/Inputs/InputDate';
import { StarGroupDisplay } from '../../../components/Stars/StarGroupDisplay';
import { Link } from 'react-router-dom';
import { ModalSuppressionAdminAvis } from '../../../components/Modales/Admin/ModalSuppressionAdminAvis';
import { handleError } from '../../../utils/ErrorHandler';
import { EntrepriseDisplay } from '../../../components/EntrepriseDisplay';
import { UserDisplay } from '../../../components/UserDisplay';

/**
 * Ce composant affiche la liste des avis côté administrateur.
 */
export const AdminAvisScreen: FunctionComponent = () => {
  /**
   * On stocke si on est en train d'utiliser l'API ou non.
   */
  const [apiIsLoading, setApiIsLoading] = useState(false);

  /**
   * Date du jour.
   * @constant
   */
  const currentDate = new Date();

  /**
   * Date d'il y a 7 jour.
   * @constant
   */
  const sevenDayAgo = new Date(new Date().setDate(currentDate.getDate() - 7));

  /**
   * État local qui gère le champ de la date de départ de la recherche d'avis.
   * @defaultValue La value stocké dans l'état local ou la date du jour au format MySQL
   */
  const [from, setFrom] = useState<string>(
    localStorage.getItem(`AdminAvisScreen-From`) ||
      `${sevenDayAgo.getFullYear()}-${sevenDayAgo.getMonth() < 9 ? `0${sevenDayAgo.getMonth() + 1}` : sevenDayAgo.getMonth() + 1}-${
        sevenDayAgo.getDate() < 10 ? `0${sevenDayAgo.getDate()}` : sevenDayAgo.getDate()
      }`,
  );

  /**
   * État local qui gère le champ de la date de fin de la recherche d'avis.
   * @defaultValue La value stocké dans l'état local ou la date du jour au format MySQL
   */
  const [to, setTo] = useState<string>(
    localStorage.getItem(`AdminAvisScreen-To`) ||
      `${currentDate.getFullYear()}-${currentDate.getMonth() < 9 ? `0${currentDate.getMonth() + 1}` : currentDate.getMonth() + 1}-${
        currentDate.getDate() < 10 ? `0${currentDate.getDate()}` : currentDate.getDate()
      }`,
  );

  /**
   * État local pour stocker les avis.
   */
  const [avis, setAvis] = useState<Avis[] | null>(null);

  /**
   * On doit stocker l'ID de la ressource selectionneée pour gérer sa suppression dans la modale
   */
  const [avisSelectionne, setAvisSelectionne] = useState<Avis | null>(null);

  /**
   * Permet d'ouvrir une modale lorsque l'on souhaite supprimer un avis.
   */
  const [modalModificationEstVisible, setModalModificationEstVisible] = useState<boolean>(false);

  /**
   * Permet d'ouvrir une modale lorsque l'on souhaite supprimer un avis.
   */
  const [modalSuppressionEstVisible, setModalSuppressionEstVisible] = useState<boolean>(false);

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  /**
   * Retrouve les avis au chargement du composant ou à chaque changement de paramètres.
   */
  useEffect(() => {
    if (from && to) {
      handleIndex();
    }
  }, [client, from, to]);

  /**
   * Retrouve par le réseau les avis et les met dans un état local.
   */
  const handleIndex = () => {
    setApiIsLoading(true);

    client
      .get<GoodApiResponse<Avis[]>>('/admin/avis', { params: { from, to } })
      .then((response) => {
        setAvis(response.data.data);
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Permet de supprimer un avis sur l'API et metre à jour l'état local qui les contient.
   *
   * @param avisId - ID de l'avis que l'on souhaite supprimer.
   */
  const handleDestroy = (avisId: Avis['id']) => {
    setApiIsLoading(true);

    client
      .delete<GoodApiResponse>(`/admin/avis/${avisId}`)
      .then(() => {
        setAvis(avis && avis.filter((avi) => avi.id !== avisId));
        toast.success('Avis supprimé.');
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Cette méthode gère l'ouverture de la modale de modification d'avis.
   *
   * @param avi - Avis que l'eon souhaite modifier.
   */
  const handleModalModification = (avi: Avis) => {
    setAvisSelectionne(avi);
    setModalModificationEstVisible(true);
  };

  /**
   * Cette méthode gère l'ouverture de la modale de suppression d'avis.
   *
   * @param avi - Avis que l'eon souhaite modifier.
   */
  const handleModalSuppression = (avi: Avis) => {
    setAvisSelectionne(avi);
    setModalSuppressionEstVisible(true);
  };

  /**
   * Cette méthode gère la validation d'un avis. Elle permet de valider ou dévalider un avis en fonction de son état de validation
   * @param avi - Avis que l'on souhaite valider ou dévalider.
   */
  const handleValidation = (avi: Avis) => {
    setApiIsLoading(true);

    client
      .patch<
        GoodApiResponse,
        {
          est_valide: Avis['est_valide'];
        }
      >(`/admin/avis/${avi.id}`, { est_valide: !avi.est_valide })
      .then(() => {
        if (avi.est_valide) {
          toast.success('Avis invalidé');
        } else {
          toast.success('Avis validé');
        }
        handleIndex();
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  if (avis === null) {
    return <Fragment>Chargement...</Fragment>;
  }

  return (
    <Fragment>
      <Helmet>
        <title>Avis - {Config.app_label}</title>
      </Helmet>
      <div className='px-4 py-4 md:py-3 bg-gray-100 rounded-tl-lg rounded-tr-lg flex flex-col sm:flex-row gap-5 items-start lg:items-center justify-between'>
        <div className='sm:flex items-center justify-between'>
          <h1 className='text-base sm:text-lg md:text-xl poppins-bold leading-normal text-secondary-1'>Avis</h1>
        </div>
        <div className='flex flex-col lg:flex-row gap-3 items-center'>
          <div className='flex flex-row items-center gap-3'>
            <p className='text-xs text-secondary-1 mb-1'>Du&nbsp;:</p>
            <InputDate
              value={from}
              onChange={(e) => {
                setFrom(e.target.value);
                localStorage.setItem(`AdminAvisScreen-From`, e.target.value);
              }}
            />
          </div>
          <div className='flex flex-row items-center gap-3'>
            <p className='text-xs text-secondary-1 mb-1'>Au&nbsp;:</p>
            <InputDate
              value={to}
              onChange={(e) => {
                setTo(e.target.value);
                localStorage.setItem(`AdminAvisScreen-To`, e.target.value);
              }}
            />
          </div>
        </div>
      </div>
      <div>
        <div className='bg-white w-full overflow-x-auto rounded-b-lg'>
          {avis.length ? (
            <table className='table-auto w-full min-w-[1100px] bg-white'>
              <thead className='text-sm text-gray-500 text-left border-b w-full'>
                <tr>
                  <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Nom de la formation</th>
                  <th className='py-4 text-secondary-1 px-2 text-left text-sm tracking-normal leading-4'>Entreprise</th>
                  <th className='py-4 text-secondary-1 px-2 text-left text-sm tracking-normal leading-4'>Salarié</th>
                  <th className='py-4 text-secondary-1 px-2 text-left text-sm tracking-normal leading-4'>Note</th>
                  <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4 max-w-[300px]'>Commentaire</th>
                  <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Date de validation</th>
                  <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4 w-[50px]'></th>
                </tr>
              </thead>
              <tbody>
                {avis.map((avi) => (
                  <tr key={avi.id} className={`border-gray-100 border-b hover:bg-gray-100`}>
                    <td className='pl-4 pr-2 py-3 text-gray-800 text-sm'>
                      <Link to={'/formations/' + avi.formation.slug} target='blank'>
                        <div className='flex flex-row gap-2'>
                          {avi.formation.image_couverture ? (
                            <img
                              alt={avi.formation.nom as string}
                              src={avi.formation.image_couverture}
                              tabIndex={0}
                              className='rounded-lg shadow-md object-cover w-10 h-10'
                              loading='lazy'
                            />
                          ) : (
                            <div className={`rounded-lg shadow-md w-10 h-10 bg-gray-700 flex items-center justify-center`}>
                              <AcademicCapIcon className='h-7 w-7 text-white/80' />
                            </div>
                          )}
                          <div>
                            <div className='poppins-medium text-secondary-1'>{avi.formation.nom}</div>
                            <div className='text-xs text-secondary-1'>
                              {`Émis le ${new Intl.DateTimeFormat('default', {
                                year: 'numeric',
                                month: 'numeric',
                                day: 'numeric',
                                hour12: false,
                              }).format(new Date(avi.created_at as string))}`}
                            </div>
                          </div>
                        </div>
                      </Link>
                    </td>
                    <td className='px-2 py-3 text-gray-800 text-sm'>{avi.entreprise && <EntrepriseDisplay entreprise={avi.entreprise} />}</td>
                    <td className='px-2 py-3 text-gray-800 text-sm'>{avi.user && <UserDisplay user={avi.user} />}</td>
                    <td className='px-2 py-3 text-gray-800 text-sm'>
                      <StarGroupDisplay note={avi.note} />
                    </td>
                    <td className='px-4 py-3 text-gray-600 poppins-medium text-xs max-w-[300px]'>
                      <div className='line-clamp-3'>{nl2br(avi.description)}</div>
                    </td>
                    <td className='pl-4 py-3 text-gray-600 poppins-medium text-xs'>
                      {avi.date_validation
                        ? new Intl.DateTimeFormat('default', {
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                            hour12: false,
                          }).format(new Date(avi.date_validation as string))
                        : 'Pas encore validé'}
                    </td>
                    <td className='pr-2 py-3 text-gray-800 text-sm w-[100px] flex flex-row gap-1'>
                      {avi.date_validation && (
                        <Tooltip content='Dévalider'>
                          <Button
                            variant='filled'
                            color='red'
                            className='w-8 h-8 p-1.5 rounded-full block  text-center mr-0 hover:bg-red-700 active:bg-red-700'
                            onClick={() => handleValidation(avi)}>
                            <XMarkIcon className='h-5 w-5' strokeWidth={2} />
                          </Button>
                        </Tooltip>
                      )}
                      {!avi.date_validation && (
                        <Tooltip content='Accepter'>
                          <Button
                            variant='filled'
                            color='green'
                            className='w-8 h-8 p-1.5 rounded-full block text-center mr-0 hover:bg-green-700 active:bg-green-700'
                            onClick={() => handleValidation(avi)}>
                            <CheckIcon className='h-5 w-5' strokeWidth={2} />
                          </Button>
                        </Tooltip>
                      )}
                      <Menu placement='right-start'>
                        <MenuHandler>
                          <Button
                            variant='text'
                            className='w-9 h-9 p-1.5 rounded-full text-gray-500 block mx-auto text-center mr-0 hover:bg-white/100 active:bg-transparent'>
                            <EllipsisVerticalIcon className='h-6 w-6' />
                          </Button>
                        </MenuHandler>
                        <MenuList>
                          <MenuItem onClick={() => window.open(`/formations/${avi.formation.slug}`, '_blank')}>Voir la page de la formation</MenuItem>
                          <MenuItem onClick={() => handleModalModification(avi)}>Consulter</MenuItem>
                          <MenuItem onClick={() => handleModalSuppression(avi)}>Supprimer</MenuItem>
                        </MenuList>
                      </Menu>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className='text-center px-4 py-7'>Il n'y a pas d'avis pour la période choisie.</div>
          )}
        </div>
        <ModalModificationAvisAdmin
          visible={modalModificationEstVisible}
          setVisible={setModalModificationEstVisible}
          avis={avisSelectionne as Avis}
          handleIndex={handleIndex}
        />
        <ModalSuppressionAdminAvis
          visible={modalSuppressionEstVisible}
          setVisible={setModalSuppressionEstVisible}
          confirmer={() => handleDestroy(avisSelectionne?.id as Avis['id'])}
        />
        {apiIsLoading ? <LoadingAbsolute /> : ''}
      </div>
    </Fragment>
  );
};

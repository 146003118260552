import { Fragment, FunctionComponent, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Config } from '../../config/config';
import { ProfileSelectorMini } from '../../components/ProfileSelectorMini';
import { Navigate } from 'react-router-dom';
import { useUser } from '../../providers/UserProvider';
import { RoleEnum, TypeProfilEnum } from '../../types/auth';
import { EntrepriseDashboardScreen } from './Entreprise/EntrepriseDashboardScreen';
import { SalariesDashboardScreen } from './SalariesDashboardScreen';
import { AdminDashboardScreen } from './Admin/AdminDashboardScreen';
import { ModalCreationEntreprise } from '../../components/Modales/ModalCreationEntreprise';
import { ModalInvitation } from '../../components/Modales/Salarie/ModalInvitation';
import { PlusIcon } from '@heroicons/react/24/outline';
import { AdmingcDashboardScreen } from './GestionnaireCentre/AdmingcDashboardScreen';
import { SearchBar } from '../../components/SearchBar';
import { Button, Dialog, DialogHeader, DialogBody } from '@material-tailwind/react';
import { ProfileSelector } from '../../components/ProfileSelector';
import { XMarkIcon } from '@heroicons/react/20/solid';

/**
 * Ce composant affiche la page d'acceuil pour le Back-Office.
 */
export const MonEspaceScreen: FunctionComponent = () => {
  /**
   * On a besoin des informations de l'utilisateur et du profil courant de celui-ci.
   */
  const { user, profil, afficheSelecteurProfil, changeAfficheSelecteurProfil } = useUser();

  /**
   * Permet d'ouvrir une modale lorsque l'on souhaite supprimer une ressource.
   */
  const [modalInvitationEntrepriseEstVisible, setModalInvitationEntrepriseEstVisible] = useState<boolean>(false);

  /**
   * Permet d'ouvrir une modale lorsque l'on souhaite créer une entreprise.
   */
  const [modalCreationEntrepriseEstVisible, setModalCreationEntrepriseEstVisible] = useState<boolean>(false);

  /**
   * Etat local pour gérer l'ouverture du menu.
   */
  const [open, setOpen] = useState(afficheSelecteurProfil);

  /**
   * Cette méthode inverse l'état d'ouverture du menu lorsqu'elle est appelée.
   * @returns Setter de l'état qui fixe la valeur inversée à la valeur courante.
   */
  const handleOpen = () => {
    if (profil && open === true) {
      changeAfficheSelecteurProfil(false);
    }
    setOpen(!open);
  };

  if (!user || !user.token) {
    return <Navigate to='/connexion' />;
  }

  return (
    <Fragment>
      <Helmet>
        <title>Mon espace - {Config.app_label}</title>
      </Helmet>
      <Dialog open={open} handler={handleOpen} size='lg' className='relative'>
        <img className='absolute top-0 h-full w-full shadow rounded-lg object-cover object-center' src='/assets/waves-blue.webp' />
        <div className='relative flex items-center justify-between'>
          <DialogHeader className='text-white'>Choisissez un profil</DialogHeader>
          <Button variant='text' onClick={handleOpen} className='rounded-full hover:bg-white/20 mr-4 px-3'>
            <XMarkIcon className='w-7 h-7 text-white cursor-pointer' />
          </Button>
        </div>
        <DialogBody className='pt-0'>
          <div className='py-4 max-h-[500px] overflow-y-auto no-scrollbar'>
            {user && user.profils ? (
              <ProfileSelector profils={user.profils} handleOpen={handleOpen} />
            ) : (
              <p className='text-white text-lg text-center'>Pas de profil pour le moment</p>
            )}
          </div>
        </DialogBody>
      </Dialog>
      <div>
        <div className='relative bg-white/70 bg-clip-border shadow-md rounded-md p-3 text-center xl:text-left flex flex-col md:flex-row justify-between gap-3'>
          <div className='flex flex-row items-center gap-3 md:basis-2/3 xl:basis-1/3'>
            <ProfileSelectorMini setAjoutVisible={setModalCreationEntrepriseEstVisible} />
          </div>
          <div className='md:basis-1/3 w-full ml-auto'></div>
        </div>
        <div className='bg-secondary-2 px-7 py-6 shadow-md rounded-md relative mt-7'>
          <SearchBar className='w-full' fromBackOffice={true} />
        </div>
        {user.roles.includes(RoleEnum.standard) && (
          <Fragment>
            <div className='lg:my-11'>
              {!profil && (
                <div className='bg-white rounded-lg text-center px-5 py-11 shadow-lg mt-11'>
                  <h2 className='text-2xl text-secondary-1 poppins-bold mb-5'>Aucun profil</h2>
                  <p className='text-secondary-1/70'>
                    Vous n'avez pas encore de profil. Vous pouvez vous affilier à une entreprise en tant que salarié, ou bien créer une entreprise.
                  </p>
                  <div className='grid grid-cols-1 sm:grid-cols-2 max-w-lg mx-auto justify-center mt-7 mb-2 gap-5'>
                    <Button
                      variant='outlined'
                      className='h-48 rounded-lg text-center justify-center'
                      onClick={() => setModalInvitationEntrepriseEstVisible(true)}>
                      <PlusIcon className='w-16 h-16 block mx-auto mb-3' />
                      <span>Rejoindre une entreprise</span>
                    </Button>
                    <Button variant='filled' className='rounded-lg text-center justify-center' onClick={() => setModalCreationEntrepriseEstVisible(true)}>
                      <PlusIcon className='w-16 h-16 block mx-auto mb-3' />
                      <span>Créer une entreprise</span>
                    </Button>
                  </div>
                </div>
              )}
              {profil && profil.type_profil === TypeProfilEnum.salarie && <SalariesDashboardScreen />}
              {profil && profil.type_profil === TypeProfilEnum.dirigeant && <EntrepriseDashboardScreen />}
              {profil && profil.type_profil === TypeProfilEnum.delegue && <EntrepriseDashboardScreen />}
            </div>
            <ModalInvitation visible={modalInvitationEntrepriseEstVisible} setVisible={setModalInvitationEntrepriseEstVisible} />
            <ModalCreationEntreprise visible={modalCreationEntrepriseEstVisible} setVisible={setModalCreationEntrepriseEstVisible} />
          </Fragment>
        )}
        {user.roles.includes(RoleEnum.admin) && (
          <div className='lg:my-11'>
            <AdminDashboardScreen />
          </div>
        )}
        {user.roles.includes(RoleEnum.gestionnaireCentre) && (
          <div className='lg:my-20'>
            <AdmingcDashboardScreen />
          </div>
        )}
      </div>
    </Fragment>
  );
};

import { ChevronRightIcon, EyeIcon } from '@heroicons/react/24/outline';
import { FunctionComponent } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Convocation } from '../../types/SalariesRessources';
import { FormationSessionDisplay } from '../FormationSessionDisplay';
import { DownloadLink } from '../DownloadLink';

/**
 * Ce composant est un encart du tableau de bord salariés concernant les convocations.
 *
 * @param convocations - Tableau de convocations
 */
export const DashboardSalariesConvocations: FunctionComponent<{ convocations: Convocation[] }> = ({ convocations }) => {
  /**
   * Permet au composant de changer de page.
   */
  const navigate = useNavigate();

  return (
    <div className='mx-auto mt-6 shadow-md rounded-md bg-white'>
      <div className='flex flex-col sm:flex-row items-center justify-between p-5'>
        <h3 className='text-secondary-2 poppins-semibold text-center md:text-left'>Mes convocations</h3>
        <Link to='/mes-convocations' className='text-sm text-secondary-1/90 flex items-center hover:text-primary'>
          Tout voir
          <ChevronRightIcon className='w-3 h-3' />
        </Link>
      </div>
      {convocations.length > 0 ? (
        <table className='table-auto w-full bg-white rounded-b-lg'>
          <thead className='text-sm text-gray-500 text-left border-b'>
            <tr className='w-full h-12 border-gray-300  border-b py-8'>
              <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Formation</th>
              <th className='py-4 text-secondary-1 pl-2 text-left text-sm tracking-normal leading-4'>Fichier</th>
              <th className='py-4 text-secondary-1 pl-2 text-left text-sm tracking-normal leading-4'>Date&nbsp;de&nbsp;convocation</th>
              <th className='py-4 text-secondary-1 px-2 text-center text-sm tracking-normal leading-4'>Voir</th>
            </tr>
          </thead>
          <tbody>
            {convocations &&
              convocations.map((convocation) => (
                <tr
                  key={convocation.token}
                  className=' border-gray-100 border-b hover:bg-gray-100'
                  onDoubleClick={() => navigate(`/mes-convocations/${convocation.token}`)}>
                  <td className='pl-4 pr-2 py-3 text-secondary-1 text-sm'>
                    <FormationSessionDisplay formation={convocation.formation} groupe={convocation.groupe} />
                  </td>
                  <td className='px-2 py-3 text-secondary-1 text-xs'>
                    {convocation.fichier && <DownloadLink fichier={convocation.fichier} titre={convocation.titre} />}
                  </td>
                  <td className='px-2 py-3 text-secondary-1/90 text-xs'>
                    {convocation.date_convocation
                      ? new Intl.DateTimeFormat('default', {
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                          hour12: false,
                        }).format(new Date(convocation.date_convocation as string))
                      : ''}
                  </td>
                  <td className='px-2 py-3 text-secondary-1/90 text-xs'>
                    <div className='flex justify-center'>
                      <Link to={`/mes-convocations/${convocation.token}`}>{<EyeIcon className='w-5 h-5' />}</Link>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      ) : (
        <p className='p-5 text-center text-sm font-medium text-secondary-1/70'>Aucune convocation à venir pour le moment.</p>
      )}
    </div>
  );
};

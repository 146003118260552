import type { FunctionComponent } from 'react';
import { Outlet } from 'react-router-dom';
import { FooterVitrine } from '../components/FooterVitrine';
import { TopBar } from '../components/TopBar';
import { useApi } from '../providers/ApiProvider';
import { useUser } from '../providers/UserProvider';
import { FooterLegal } from '../components/FooterLegal';

/**
 * Ce layout contient la mise en page par la partie Front et ce qui s'y rapporte.
 */
export const VisitorLayout: FunctionComponent = () => {
  /**
   * On a besoin des informations de l'utilisateur et du profil courant de celui-ci.
   */
  const { user, profil } = useUser();

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  // On verifie si on a un utilisateur qui est connecté, auquel cas on assigne le jeton d'authentification dans les en-têtes de l'instance de classe API.
  if (user && user.token) {
    client.setToken(user.token);
  }

  // On verifie si on a un profil, auquel cas on assigne son ID dans les en-têtes de l'instance de classe API.
  if (profil) {
    client.setProfil(profil.id);
  }

  return (
    <div>
      <TopBar />
      <Outlet />
      <FooterVitrine />
      <FooterLegal />
    </div>
  );
};

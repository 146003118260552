import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import { Helmet } from 'react-helmet';
import { LoadingAbsolute } from '../../../components/LoadingAbsolute';
import { useApi } from '../../../providers/ApiProvider';
import { Devis } from '../../../types/EntreprisesRessources';
import { GoodApiResponse, ErrorApiResponse } from '../../../types/api';
import { Config } from '../../../config/config';
import { handleError } from '../../../utils/ErrorHandler';
import { useNavigate } from 'react-router-dom';
import { ajouterJour, dateEstCompriseDansIntervalle } from '../../../utils/Temps';
import { Button, Menu, MenuHandler, MenuItem, MenuList } from '@material-tailwind/react';
import { UserDisplay } from '../../../components/UserDisplay';
import { FormationSessionDisplay } from '../../../components/FormationSessionDisplay';
import { DownloadLink } from '../../../components/DownloadLink';

/**
 * Cette page affiche les devis de l'entreprise.
 */
export const EntrepriseDevisScreen: FunctionComponent = () => {
  /**
   * On stocke si on est en train d'utiliser l'API ou non.
   */
  const [apiIsLoading, setApiIsLoading] = useState(false);

  /**
   * Permet de stocker la liste des devis.
   */
  const [devis, setDevis] = useState<Devis[] | null>(null);

  /**
   * Permet de filtrer sur l'avancement du devis.
   */
  const [filtreAvancement, setFiltreAvancement] = useState<
    'tous' | 'enattente' | 'delaisattentedepasse' | 'accepte' | 'enattentetraitement' | 'delaistraitementdepasse' | 'refuse' | 'retracte' | 'traite'
  >(
    (localStorage.getItem(`EntrepriseDevisScreen-filtreAvancement`) as
      | 'tous'
      | 'enattente'
      | 'delaisattentedepasse'
      | 'accepte'
      | 'enattentetraitement'
      | 'delaistraitementdepasse'
      | 'refuse'
      | 'retracte'
      | 'traite') || 'tous',
  );

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  /**
   * Permet au composant de changer de page.
   */
  const navigate = useNavigate();

  /**
   * Date du jour.
   * @constant
   */
  const currentDate = new Date();

  /**
   * Permet de recharger les données au chargement de page
   */
  useEffect(() => {
    handleIndex();
  }, [client]);

  /**
   * Cette méthode va chercher les informations dans l'API pour afficher les devis.
   */
  const handleIndex = () => {
    setApiIsLoading(true);

    client
      .get<GoodApiResponse<Devis[]>>(`/entreprise/devis`)
      .then((response) => {
        setDevis(response.data.data);
      })
      .catch((error: ErrorApiResponse<Devis[]>) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Cette méthode sert à afficher les un résumé de l'avancement pour chaque devis.
   *
   * @param devi - Devis dont il est question dans leboucle.
   * @returns Le résumé des étapes de l'avancement du devis.
   */
  const afficheAvancement = (devi: Devis) => {
    if (devi.date_acceptation === null && devi.date_refus === null) {
      if (currentDate < new Date(devi.date_limite_acceptation)) {
        // Devis en attente
        return (
          <div
            className={
              devi.date_limite_acceptation &&
              dateEstCompriseDansIntervalle(new Date(devi.created_at as string), ajouterJour(new Date(devi.date_limite_acceptation), -1), currentDate)
                ? currentDate.getDate() === ajouterJour(new Date(devi.date_limite_acceptation), -1).getDate()
                  ? 'text-orange-200'
                  : ''
                : 'text-red-700'
            }>
            En attente de décision
            {devi.date_limite_acceptation
              ? `, valable jusqu'au : ${new Intl.DateTimeFormat('default', {
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                  hour12: false,
                }).format(new Date(devi.date_limite_acceptation))}.`
              : '.'}
          </div>
        );
      } else {
        // Délais d'attente dépassé
        return (
          <div className='text-red-700'>
            {devi.date_limite_acceptation
              ? `Valable jusqu'au : ${new Intl.DateTimeFormat('default', {
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                  hour12: false,
                }).format(new Date(devi.date_limite_acceptation))}.`
              : ''}
          </div>
        );
      }
    }

    if (devi.date_acceptation) {
      /**
       * Une fois le devis accepté on sait que l'on va rendre ce bloc pour tous les cas où le devis est accepté.
       * @constant
       */
      const devisAccepte = (
        <div>{`Accepté le : ${new Intl.DateTimeFormat('default', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour12: false,
        }).format(new Date(devi.date_acceptation as string))}.`}</div>
      );

      if (devi.date_traitement === null && devi.date_limite_traitement) {
        if (currentDate < new Date(devi.date_limite_traitement)) {
          // En attente de traitement
          return (
            <Fragment>
              {devisAccepte}
              <div
                className={
                  devi.date_acceptation &&
                  devi.date_limite_retractation &&
                  dateEstCompriseDansIntervalle(
                    new Date(devi.date_acceptation as string),
                    ajouterJour(new Date(devi.date_limite_retractation as string), -1),
                    currentDate,
                  )
                    ? currentDate.getDate() === ajouterJour(new Date(devi.date_limite_retractation), -1).getDate()
                      ? 'text-orange-200'
                      : ''
                    : 'text-red-700'
                }>
                {devi.date_limite_retractation
                  ? `Vous pouvez vous rétracter jusqu'au : ${new Intl.DateTimeFormat('default', {
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                      hour12: false,
                    }).format(new Date(devi.date_limite_retractation))}.`
                  : ''}
              </div>
            </Fragment>
          );
        } else {
          // Délais de traitement dépassé.
          return (
            <Fragment>
              {devisAccepte}
              <div className='text-red-700'>
                {devi.date_limite_traitement
                  ? `Date limite de traitement le : ${new Intl.DateTimeFormat('default', {
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                      hour12: false,
                    }).format(new Date(devi.date_limite_traitement))}.`
                  : ''}
              </div>
            </Fragment>
          );
        }
      }

      if (devi.date_retractation) {
        return (
          <Fragment>
            {devisAccepte}
            <div className='text-red-700'>
              {devi.date_retractation
                ? `Rétracté le : ${new Intl.DateTimeFormat('default', {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour12: false,
                  }).format(new Date(devi.date_retractation))}.`
                : ''}
            </div>
          </Fragment>
        );
      }

      if (devi.date_traitement) {
        return (
          <Fragment>
            {devisAccepte}
            <div>
              {devi.date_traitement
                ? `Traité le : ${new Intl.DateTimeFormat('default', {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour12: false,
                  }).format(new Date(devi.date_traitement))}.`
                : ''}
            </div>
          </Fragment>
        );
      }
    }

    if (devi.date_refus) {
      return (
        <div>{`Refusé le : ${new Intl.DateTimeFormat('default', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour12: false,
        }).format(new Date(devi.date_refus as string))}.`}</div>
      );
    }
  };

  const afficheDevisFiltres = () =>
    devis
      ? devis.filter((devi) => {
          switch (filtreAvancement) {
            case 'tous':
              return true;
            case 'enattente':
              return devi.date_acceptation === null && devi.date_refus === null && currentDate < new Date(devi.date_limite_acceptation);
            case 'delaisattentedepasse':
              return devi.date_acceptation === null && devi.date_refus === null && currentDate > new Date(devi.date_limite_acceptation);
            case 'accepte':
              return devi.date_acceptation;
            case 'enattentetraitement':
              return (
                devi.date_acceptation && devi.date_traitement === null && devi.date_limite_traitement && currentDate < new Date(devi.date_limite_traitement)
              );
            case 'delaistraitementdepasse':
              return (
                devi.date_acceptation && devi.date_traitement === null && devi.date_limite_traitement && currentDate > new Date(devi.date_limite_traitement)
              );
            case 'refuse':
              return devi.date_refus;
            case 'retracte':
              return devi.date_retractation;
            case 'traite':
              return devi.date_traitement;
            default:
              break;
          }
        })
      : [];

  if (devis === null) {
    return <Fragment>Chargement...</Fragment>;
  }

  return (
    <Fragment>
      <Helmet>
        <title>Devis reçus - {Config.app_label}</title>
      </Helmet>
      <div className='px-4 py-4 md:py-3 bg-gray-100 rounded-t-lg flex flex-col sm:flex-row gap-7 items-center'>
        <div className='sm:flex items-center justify-between'>
          <h1 className='text-base sm:text-lg md:text-xl poppins-bold leading-normal text-secondary-1'>Devis reçus</h1>
        </div>
        <div>
          <p className='text-xs text-gray-700 mb-1'>Décision :</p>
          <select
            className='min-w-11 bg-white text-gray-600 border-gray-300 rounded-md font-normal text-sm p-[5.5px] border'
            value={filtreAvancement}
            onChange={(e) => {
              setFiltreAvancement(e.target.value as typeof filtreAvancement);
              localStorage.setItem(`EntrepriseDevisScreen-filtreAvancement`, e.target.value);
            }}>
            <option value='tous'>Tous</option>
            <option value='enattente'>En attente</option>
            <option value='delaisattentedepasse'>Délais d'attente dépassé</option>
            <option value='accepte'>Accepté</option>
            <option value='enattentetraitement'>En attente de traitement</option>
            <option value='delaistraitementdepasse'>Délais de traitement dépassé</option>
            <option value='refuse'>Refusé</option>
            <option value='retracte'>Rétracté</option>
            <option value='traite'>Traité</option>
          </select>
        </div>
      </div>
      <div>
        <div className='bg-white rounded-b-lg w-full overflow-x-auto'>
          {devis.length > 0 ? (
            afficheDevisFiltres().length > 0 ? (
              <table className='table-auto w-full min-w-[1200px] bg-white rounded-b-lg'>
                <thead className='text-sm text-gray-500 text-left border-b'>
                  <tr>
                    <th className='py-4 text-secondary-1 px-2 text-left text-sm tracking-normal leading-4 pl-4'>Numéro</th>
                    <th className='py-4 text-secondary-1 px-2 text-left text-sm tracking-normal leading-4'>Formation et session</th>
                    <th className='py-4 text-secondary-1 px-2 text-left text-sm tracking-normal leading-4'>Salarié</th>
                    <th className='py-4 text-secondary-1 px-2 text-left text-sm tracking-normal leading-4'>Fichier</th>
                    <th className='py-4 text-secondary-1 px-2 text-left text-sm tracking-normal leading-4'>Avancement</th>
                    <th className='py-4 text-secondary-1 px-2 text-left text-sm tracking-normal leading-4'>Créée le</th>
                    <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'></th>
                  </tr>
                </thead>
                <tbody>
                  {afficheDevisFiltres().map((devi) => (
                    <tr
                      key={devi.token}
                      className=' border-gray-100 border-b hover:bg-gray-100'
                      onDoubleClick={() => navigate(`/entreprise/devis/${devi.token}`)}>
                      <td className='pl-4 pr-2 py-3 text-secondary-1/90 text-xs'>{devi.numero}</td>
                      <td className='px-4 py-3'>
                        <FormationSessionDisplay formation={devi.formation} groupe={devi.groupe} />
                      </td>
                      <td className='px-2 py-3'>{devi.user && <UserDisplay user={devi.user} />}</td>
                      <td className='px-2 py-3 text-secondary-1 text-xs'>{devi.fichier && <DownloadLink fichier={devi.fichier} titre={devi.titre} />}</td>
                      <td className='px-2 py-3 text-secondary-1 text-xs'>
                        <div>{afficheAvancement(devi)}</div>
                      </td>
                      <td className='px-2 py-3 text-secondary-1/90 text-xs'>
                        {devi.created_at
                          ? new Intl.DateTimeFormat('default', {
                              year: 'numeric',
                              month: 'numeric',
                              day: 'numeric',
                              hour12: false,
                            }).format(new Date(devi.created_at as string))
                          : ''}
                      </td>
                      <td className='px-4 py-3 text-secondary-1 text-sm'>
                        <Menu placement='right-start'>
                          <MenuHandler>
                            <Button
                              variant='text'
                              className='w-9 h-9 p-1.5 rounded-full text-gray-500 block mx-auto text-center mr-0 hover:bg-white/100 active:bg-transparent'>
                              <EllipsisVerticalIcon className='h-6 w-6' />
                            </Button>
                          </MenuHandler>
                          <MenuList>
                            <MenuItem onClick={() => navigate(`/entreprise/devis/${devi.token}`)}>Voir</MenuItem>
                          </MenuList>
                        </Menu>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className='text-center px-4 py-7'>Aucun devis ne répond au critères choisis.</div>
            )
          ) : (
            <div className='text-center px-4 py-7'>Vous n'avez pas encore de devis.</div>
          )}
        </div>
        {apiIsLoading ? <LoadingAbsolute /> : ''}
      </div>
    </Fragment>
  );
};

import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useApi } from '../providers/ApiProvider';
import { ErrorApiResponse, GoodApiResponse, IndexParams, SearchFormation } from '../types/api';
import { Region, Site } from '../types/visitorRessources';
import { handleError } from '../utils/ErrorHandler';
import { calculateDistance } from '../utils/Haversine';

/**
 * Ce composant affiche la barre de recherche, côté Front.
 *
 * @param className - Classes Tailwind de l'élément de premier niveau.
 * @param initialSearchState - État de recherche initial pour la recherche textuelle et les sites.
 * @param fromBackOffice - Précise si le composant est appelé depuis le Front-Office ou le Back-Office.
 */
export const SearchBar: FunctionComponent<{ className: string; initialSearchState?: SearchFormation; fromBackOffice?: boolean }> = ({
  className,
  initialSearchState = { recherche: '', selectedSites: [] },
  fromBackOffice = false,
}) => {
  /**
   * Stocke les régions pour la liste des sites
   */
  const [regions, setRegions] = useState<Region[] | null>(null);

  /**
   * Liste des sites
   */
  const [sites, setSites] = useState<Site[] | null>(null);

  /**
   * État de la recherche courant. Par défaut `initialSearchState`.
   */
  const [searchState, setSearchState] = useState<SearchFormation>(initialSearchState);

  /**
   * Contient la liste des sites sélectionné.
   */
  const [displayedSearchedSites, setDisplayedSearchedSites] = useState<Site['nom'][]>([]);

  /**
   * Régions sélectionnée.
   */
  const [selectedRegions, setSelectedRegions] = useState<Region['slug'][]>([]);

  /**
   * Permet au composant de changer de page.
   */
  const navigate = useNavigate();

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  /**
   * Va chercher les informations dans l'API pour afficher les sites et les régions voulues au chargement du composant.
   */
  useEffect(() => {
    handleIndex();
  }, [client]);

  /**
   * Cette méthode va chercher les informations dans l'API pour afficher les sites et les régions voulues.
   */
  const handleIndex = () => {
    client
      .get<GoodApiResponse<Region[]>, IndexParams>('/regions-sites', { params: { no_pagination: true, sort: 'nom' } })
      .then((response) => {
        setRegions(response.data.data);

        let sites: Site[] = [];

        (response.data.data as Region[]).forEach((region) => {
          if (region.sites) {
            sites = [...sites, ...region.sites];
          }
        });

        setSites([...sites]);

        setDisplayedSearchedSites([...sites.filter((site) => initialSearchState.selectedSites.includes(site.slug)).map((site) => site.nom)]);
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error);
      });
  };

  /**
   * Cette méthode effectue concrètement la recherche.
   */
  const handleSearch = () => {
    if (fromBackOffice) {
      window.open(`/resultats-recherche?recherche=${searchState.recherche}&sites=${searchState.selectedSites.join('|')}`);
    } else {
      navigate(`/resultats-recherche?recherche=${searchState.recherche}&sites=${searchState.selectedSites.join('|')}`);
    }
  };

  /**
   * Cette méthode permet de gérer les changements à l'interieur des champs texte du formulaire et de gérer les valeurs des champs via un état local.
   *
   * @param field - Champs du formulaire dont on doit stocker la valeur et s'il est en erreur.
   */
  const handleInputChange = (field: keyof typeof searchState) => {
    return (event: { target: { value: string } }) => {
      setSearchState((prev) => ({
        ...prev,
        [field]: event.target.value,
      }));
    };
  };

  /**
   * "Gère" le clic sur sur un elément. Ca réagit contextuellement en fonction de ce sur quoi on clique.
   * L'idée c'est d'ajouter les sites dans un tableau et qu'il n'y ait pas de doublon.
   *
   * @param typeOfChoice - Type d'élément sur lequel on a cliqué.
   * @param value - Contientle slug du site, de la region ou n'est pas défini.
   */
  const handleToggleSite = (typeOfChoice: 'tout' | 'autour' | 'region' | 'site', value?: Site | Region) => {
    switch (typeOfChoice) {
      case 'tout':
        setSearchState({
          ...searchState,
          selectedSites: [],
        });
        setSelectedRegions([]);
        setDisplayedSearchedSites([]);
        setIsOpen(false);
        break;
      case 'autour':
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setSearchState({
              ...searchState,
              selectedSites: [
                ...searchState.selectedSites,
                ...(sites as Site[])
                  .filter(
                    (site) =>
                      site.geo_lat &&
                      site.geo_lng &&
                      calculateDistance(
                        { latitude: position.coords.latitude, longitude: position.coords.longitude },
                        { latitude: site.geo_lat, longitude: site.geo_lng },
                      ) < 50000 &&
                      !searchState.selectedSites.includes(site.slug),
                  )
                  .map((site) => site.slug),
              ],
            });

            setDisplayedSearchedSites([
              ...displayedSearchedSites,
              ...(sites as Site[])
                .filter(
                  (site) =>
                    site.geo_lat &&
                    site.geo_lng &&
                    calculateDistance(
                      { latitude: position.coords.latitude, longitude: position.coords.longitude },
                      { latitude: site.geo_lat, longitude: site.geo_lng },
                    ) < 50000 &&
                    !displayedSearchedSites.includes(site.nom),
                )
                .map((site) => site.nom),
            ]);

            toast.success('Les centres autour de vous ont été ajoutés.');
          },
          () => {
            toast.error('Une erreur est survenue lors de la tentative de géolocalisation.');
          },
        );
        break;
      case 'region':
        if (selectedRegions.includes(value?.slug as Region['slug'])) {
          (((regions as Region[]).find((region) => region.slug === value?.slug) as Region).sites as Site[]).forEach((site) => {
            setSearchState((searchState) => ({
              ...searchState,
              selectedSites: [...searchState.selectedSites.filter((selectedSite) => selectedSite !== site.slug)],
            }));

            setDisplayedSearchedSites([...displayedSearchedSites.filter((displayedSearchedSite) => displayedSearchedSite !== site.nom)]);
          });

          setSelectedRegions([...selectedRegions.filter((selectedRegion) => selectedRegion !== value?.slug)]);
        } else {
          setSearchState({
            ...searchState,
            selectedSites: [
              ...searchState.selectedSites,
              ...(((regions as Region[]).find((region) => region.slug === value?.slug) as Region).sites as Site[])
                .filter((site) => !searchState.selectedSites.includes(site.slug))
                .map((site) => site.slug),
            ],
          });

          setDisplayedSearchedSites([
            ...displayedSearchedSites,
            ...(((regions as Region[]).find((region) => region.slug === value?.slug) as Region).sites as Site[])
              .filter((site) => !displayedSearchedSites.includes(site.nom))
              .map((site) => site.nom),
          ]);

          setSelectedRegions([...selectedRegions, value?.slug as Region['slug']]);
        }
        break;
      case 'site':
        toogleSite(value as Site);
        break;

      default:
        break;
    }
  };

  /**
   * Ajoute un site pour la recherche.
   *
   * @param value - Site que l'on souhaite ajouter.
   */
  const addSite = (value: Site) => {
    setSearchState({ ...searchState, selectedSites: [...searchState.selectedSites, value.slug] });

    setDisplayedSearchedSites([...displayedSearchedSites, value.nom]);
  };

  /**
   * Retire un site de la recherche.
   * @param value - Site que l'on souhaite supprimer.
   */
  const removeSite = (value: Site) => {
    setSearchState((searchState) => ({
      ...searchState,
      selectedSites: [...searchState.selectedSites.filter((selectedSite) => selectedSite !== value.slug)],
    }));

    setDisplayedSearchedSites([...displayedSearchedSites.filter((displayedSearchedSite) => displayedSearchedSite !== value.nom)]);
  };

  /**
   * Cette méthode permet d'ajouter ou de retirer un site si celui est déjà présent dans l'état de recherche.
   *
   * @param value - Site sur lequel on clique.
   */
  const toogleSite = (value: Site) => {
    if (value && searchState.selectedSites.includes(value.slug)) {
      removeSite(value);
    } else {
      addSite(value);
    }
  };

  /*------------test--------------*/

  /**
   * État local qui gère l'ouverture de la liste des sites et des régions où l'on peut cliquer.
   */
  const [isOpen, setIsOpen] = useState(false);

  /**
   * Gère de façon contextuelle l'ouverture de la liste des sites et des régions où l'on peut cliquer.
   */
  const toggle = () => {
    setIsOpen((isOpen) => !isOpen);
  };

  return (
    <Fragment>
      <div className={className + ' flex flex-col md:flex-row gap-4 items-center justify-between'}>
        <div className='py-[13px] px-4 rounded-md w-full bg-white mb-3 md:mb-0'>
          <input
            className='outline-none w-full px-3 block text-sm'
            type='text'
            placeholder='Effectuez une recherche par mots-clés...'
            value={searchState.recherche}
            onChange={handleInputChange('recherche')}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                handleSearch();
              }
            }}
          />
        </div>

        <div className='flex flex-row relative justify-between rounded-md bg-white py-0 mb-3 md:mb-0 w-full'>
          <div className='py-3 pl-4 pr-2 text-gray-600'>
            <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' strokeWidth={1.5} stroke='currentColor' className='w-6 h-6'>
              <path strokeLinecap='round' strokeLinejoin='round' d='M15 10.5a3 3 0 11-6 0 3 3 0 016 0z' />
              <path strokeLinecap='round' strokeLinejoin='round' d='M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z' />
            </svg>
          </div>
          <div className='w-full cursor-pointer flex flex-row items-center justify-between mr-6 md:mr-5 text-gray-600 text-sm pl-1' onClick={toggle}>
            <div className='text-ellipsis overflow-hidden whitespace-nowrap w-40 md:w-40 lg:w-64'>
              {displayedSearchedSites.length > 0 ? displayedSearchedSites.join(', ') : 'Tous les centres'}
            </div>
            <div className='w-4 h-4 basis-3'>
              <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' strokeWidth={3} stroke='currentColor' className='w-full h-full'>
                <path strokeLinecap='round' strokeLinejoin='round' d='M19.5 8.25l-7.5 7.5-7.5-7.5' />
              </svg>
            </div>
          </div>
          {isOpen && (
            <div className='bg-white p-4 rounded-lg absolute left-0 w-full mt-11 z-50 drop-shadow-lg'>
              <div className='max-h-[400px] overflow-y-scroll'>
                <div className='text-sm poppins-semibold text-secondary-1 pb-2' onClick={() => handleToggleSite('tout')}>
                  <span className='cursor-pointer  hover:text-primary'>Tous les centres</span>
                </div>
                <div className='text-sm poppins-semibold text-secondary-1' onClick={() => handleToggleSite('autour')}>
                  <span className='cursor-pointer  hover:text-primary'>Autour de moi</span>
                </div>
                {regions && (
                  <div className='border-t pt-3 mt-3'>
                    {regions.map((region, index) => (
                      <div key={index}>
                        <div key={index} className='flex flex-row gap-1' onClick={() => handleToggleSite('region', region)}>
                          <input type='checkbox' className='text-red-600 cursor-pointer' checked={selectedRegions.includes(region.slug)} />
                          <p className='text-sm poppins-semibold text-secondary-1 mb-1 hover:text-primary cursor-pointer'>{region.nom}</p>
                        </div>
                        <div className='pl-5'>
                          {region.sites &&
                            region.sites.map((site, index) => (
                              <div key={index} className='flex flex-row gap-1 text-xs py-0.5' onClick={() => handleToggleSite('site', site)}>
                                <input type='checkbox' className='text-red-600 cursor-pointer' checked={searchState.selectedSites.includes(site.slug)} />
                                <p className='cursor-pointer poppins'>{site.nom}</p>
                              </div>
                            ))}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        <button
          className={`w-full bg-primary p-3 text-white poppins-semibold ${
            fromBackOffice ? 'rounded-full' : 'rounded-md'
          } hover:shadow-lg transition duration-3000 cursor-pointer flex justify-center`}
          onClick={() => handleSearch()}>
          <div>{fromBackOffice ? 'Chercher une formation' : 'Lancer la recherche'}</div>
          <MagnifyingGlassIcon className='w-6 h-6 ml-2' />
        </button>
      </div>
    </Fragment>
  );
};

import { Fragment, FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import { Config } from '../config/config';
import { useApi } from '../providers/ApiProvider';
import { useUser } from '../providers/UserProvider';

export const NotFoundScreen: FunctionComponent = () => {
  /**
   * On a besoin des informations de l'utilisateur et du profil courant de celui-ci.
   */
  const { user, profil } = useUser();

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  // On verifie si on a un utilisateur qui est connecté, auquel cas on assigne le jeton d'authentification dans les en-têtes de l'instance de classe API.
  if (user && user.token) {
    client.setToken(user.token);
  }

  // On verifie si on a un profil, auquel cas on assigne son ID dans les en-têtes de l'instance de classe API.
  if (profil) {
    client.setProfil(profil.id);
  }

  return (
    <Fragment>
      <Helmet>
        <title>Non trouvé - {Config.app_label}</title>
      </Helmet>

      <div className='flex items-center flex-col justify-center w-screen h-screen lg:flex-row py-28 px-6 md:px-24 md:py-20 lg:py-32 gap-16 lg:gap-28'>
        <div className='w-full lg:w-1/2'>
          <h1 className='py-4 text-3xl lg:text-4xl poppins-bold text-gray-800'>Page non trouvée&nbsp;!</h1>
          <p className='py-4 text-base text-gray-800 poppins'>
            Le contenu que vous recherchez n'existe pas. Soit il a été supprimé, soit vous avez mal saisi l'adresse.
          </p>
          <p className='py-2 text-base text-gray-800 poppins'>Désolé pour cela ! Veuillez visiter notre page d'accueil pour retrouver votre chemin.</p>
          <Link to='/'>
            <button className='w-full lg:w-auto my-4 border rounded-full px-1 sm:px-11 py-3 bg-primary text-white hover:bg-secondary-1 focus:outline-none focus:ring-2 focus:ring-secondary-1 focus:ring-opacity-50 poppins'>
              Retour à l'accueil
            </button>
          </Link>
        </div>
      </div>
    </Fragment>
  );
};

import { CheckIcon, TrashIcon } from '@heroicons/react/24/outline';
import { Button, Tooltip } from '@material-tailwind/react';
import { FunctionComponent, useState } from 'react';
import toast from 'react-hot-toast';
import { useApi } from '../../providers/ApiProvider';
import { DashboardAvisAValider } from '../../types/AdminRessources';
import { GoodApiResponse, ErrorApiResponse } from '../../types/api';
import { handleError } from '../../utils/ErrorHandler';
import { Avatar } from '../Avatar';
import { ModalConsultationAvis } from '../Modales/Admin/ModalConsultationAvis';
import { ModalSuppressionAdminAvis } from '../Modales/Admin/ModalSuppressionAdminAvis';
import { ModalValidationAdminAvis } from '../Modales/Admin/ModalValidationAdminAvis';
import { StarGroupDisplay } from '../Stars/StarGroupDisplay';

/**
 * Ce composant est un encart du tableau de bord administrateur concernant les avis à valider ou supprimer.
 *
 * @param avis - Tableau d'avis à valider ou supprimer.
 * @param handleIndex - Callback pour effectuer un appel de rechargement.
 */
export const DashboardAdminAvisAValider: FunctionComponent<{
  avis: DashboardAvisAValider[];
  handleIndex: () => void;
}> = ({ avis, handleIndex }) => {
  /**
   * On doit stocker l'ID de la ressource selectionneée pour gérer sa suppression dans la modale
   */
  const [avisSelectionne, setAvisSelectionne] = useState<DashboardAvisAValider | null>(null);

  /**
   * Permet d'ouvrir une modale lorsque l'on souhaite consulter un avis.
   */
  const [modalConsultaionEstVisible, setModalConsultaionEstVisible] = useState<boolean>(false);

  /**
   * Permet d'ouvrir une modale lorsque l'on souhaite supprimer un avis.
   */
  const [modalSuppressionEstVisible, setModalSuppressionEstVisible] = useState<boolean>(false);

  /**
   * Permet d'ouvrir une modale lorsque l'on souhaite Valider un avis.
   */
  const [modalValidationEstVisible, setModalValidationEstVisible] = useState<boolean>(false);

  /**
   * On a besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  /**
   * Supprime un avis.
   *
   * @param avi - Avis à supprimer.
   */
  const handleDestroy = (avi: DashboardAvisAValider) => {
    client
      .delete<GoodApiResponse>(`/admin/avis/${avi.id}`)
      .then(() => {
        handleIndex();
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error);
      });
  };

  /**
   * Valide un avis.
   *
   * @param avi - Avis à valider.
   */
  const handleValidation = (avi: DashboardAvisAValider) => {
    client
      .patch<
        GoodApiResponse,
        {
          est_valide: true;
        }
      >(`/admin/avis/${avi.id}`, { est_valide: true })
      .then(() => {
        toast.success('Avis validé');
        handleIndex();
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error);
      });
  };

  /**
   * Ouvre une modale pour voir l'avis en entier avec toute ses informations.
   *
   * @param avi - Avis selectionné, sur lequel on ouvre la modale.
   */
  const handleModalConsultation = (avi: DashboardAvisAValider) => {
    setAvisSelectionne(avi);
    setModalConsultaionEstVisible(true);
  };

  /**
   * Ouvre une modale pour confirmer la suppression de l'avis sélectionné.
   *
   * @param avi - Avis selectionné, sur lequel on ouvre la modale.
   */
  const handleModalSuppression = (avi: DashboardAvisAValider) => {
    setAvisSelectionne(avi);
    setModalSuppressionEstVisible(true);
  };

  /**
   * Ouvre une modale pour confirmer la validation de l'avis sélectionné.
   *
   * @param avi - Avis selectionné, sur lequel on ouvre la modale.
   */
  const handleModalvalidation = (avi: DashboardAvisAValider) => {
    setAvisSelectionne(avi);
    setModalValidationEstVisible(true);
  };

  return (
    <div>
      {avis && avis.length > 0 ? (
        avis.map((avi) => (
          <div key={avi.id} className='flex flex-col sm:flex-row md:flex-col lg:flex-row justify-between gap-3 mb-2 py-2 border-b last:border-none'>
            <div className='flex flex-row gap-3'>
              <div className='h-10 w-10 aspect-1 flex-none'>
                <Avatar src={avi.user.image_avatar as string} />
              </div>
              <div>
                <div className='block text-secondary-1 mb-2'>
                  <h6 className='leading-4 poppins-medium'>
                    Avis de {`${avi.user.prenom} ${avi.user.nom}`} sur la formation {avi.formation.nom}
                  </h6>
                </div>
                <div className='flex flex-row gap-2'>
                  <StarGroupDisplay note={avi.note} />
                  <span className='text-xs font-normal text-gray-600'>
                    {`Émis le ${new Intl.DateTimeFormat('default', {
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                      hour12: false,
                    }).format(new Date(avi.created_at as string))}`}
                  </span>
                </div>
                <p className='text-sm font-normal text-gray-700 mt-1 line-clamp-3'>{avi.description}</p>
              </div>
            </div>
            <div className='flex flex-row sm:flex-col md:flex-row lg:flex-col justify-center sm:justify-start md:justify-center lg:justify-start gap-2 min-w-[90px]'>
              <Button
                variant='outlined'
                color='gray'
                className='block sm:mx-auto md:mx-0 lg:mx-auto py-0.5 px-3 rounded-full normal-case hover:bg-secondary-1 hover:text-white hover:border-white hover:opacity-100'
                onClick={() => handleModalConsultation(avi)}>
                Voir l'avis
              </Button>
              <div className='flex flex-row items-center gap-2 justify-center'>
                <Tooltip content='Supprimer' placement='bottom'>
                  <Button
                    variant='filled'
                    color='gray'
                    className='w-8 h-8 p-1.5 rounded-full block  text-center mr-0 bg-secondary-1 hover:bg-secondary-2 active:bg-secondary-2'
                    onClick={() => handleModalSuppression(avi)}>
                    <TrashIcon className='h-5 w-5' strokeWidth={2} />
                  </Button>
                </Tooltip>
                <Tooltip content='Approuver' placement='bottom'>
                  <Button
                    variant='filled'
                    color='orange'
                    className='w-8 h-8 p-1.5 rounded-full block text-center mr-0 hover:bg-orange-900 active:bg-orange-600'
                    onClick={() => handleModalvalidation(avi)}>
                    <CheckIcon className='h-5 w-5' strokeWidth={2} />
                  </Button>
                </Tooltip>
              </div>
            </div>
          </div>
        ))
      ) : (
        <p className='p-5 text-center text-sm font-medium text-secondary-1/70'>Aucun avis à valider pour le moment.</p>
      )}
      <ModalConsultationAvis visible={modalConsultaionEstVisible} setVisible={setModalConsultaionEstVisible} avis={avisSelectionne as DashboardAvisAValider} />
      <ModalSuppressionAdminAvis
        visible={modalSuppressionEstVisible}
        setVisible={setModalSuppressionEstVisible}
        confirmer={() => handleDestroy(avisSelectionne as DashboardAvisAValider)}
      />
      <ModalValidationAdminAvis
        visible={modalValidationEstVisible}
        setVisible={setModalValidationEstVisible}
        confirmer={() => handleValidation(avisSelectionne as DashboardAvisAValider)}
      />
    </div>
  );
};

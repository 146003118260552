import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { LoadingAbsolute } from '../../../components/LoadingAbsolute';
import { useApi } from '../../../providers/ApiProvider';
import { HeaderForm } from '../../../components/HeaderForm';
import { ModeFormulaire, DropdownRessource, Ressource } from '../../../types';
import { BaseIndexParams, PaginatedApiResponse, ErrorApiResponse, GoodApiResponse } from '../../../types/api';
import { Personnel, Civilite, Fonction } from '../../../types/schematics';
import { Config } from '../../../config/config';
import { Helmet } from 'react-helmet';
import { ReadOnlyNumber } from '../../../components/Inputs/ReadOnlyNumber';
import { handleError } from '../../../utils/ErrorHandler';
import { ReadOnlyText } from '../../../components/Inputs/ReadOnlyText';
import { ReadOnlySelect } from '../../../components/Inputs/ReadOnlySelect';
import { ReadOnlyBoolean } from '../../../components/Inputs/ReadOnlyBoolean';

type Props = { modeFormulaire: ModeFormulaire };

/**
 * Ce composant doit afficher un formulaire qui à 3 modes de fonctionnement. Les 2 principaux sont la création et l'édition.
 * On a également un mode lecture seule. L'idée c'est de gérer une partie du CRUD.
 * C'est dans ce composant que vont se faire les appels API pour créer, éditer et voir une ressource en particulier.
 *
 * @param modeFormulaire - Mode du formulaire (creer, editer et voir).
 * @returns JSX du composant
 */
export const AdminPersonnelFormScreen: FunctionComponent<Props> = ({ modeFormulaire }) => {
  // On initialise les données dont on va avoir besoin sur cette page

  /**
   * On stocke si on est en train d'utiliser l'API ou non.
   */
  const [apiIsLoading, setApiIsLoading] = useState(false);

  /**
   * On stocke l'état des civilités.
   */
  const [civilites, setCivilites] = useState<DropdownRessource[] | null>(null);

  /**
   * On stocke l'état des fonctions.
   */
  const [fonctions, setFonctions] = useState<DropdownRessource[] | null>(null);

  /**
   * On stocke le nom afin de l'afficher en mode édition et voir.
   */
  const [nomPrenom, setNomPrenom] = useState<string>('');

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  /**
   * Contient un état général des valeurs des champs du formulaire ainsi que les champs qui ont reçu une erreur lors de l'envoi du formulaire.
   */
  const [formInputs, setFormInputs] = useState<{ [K in keyof Personnel]: { value: Personnel[K]; error: boolean } }>({
    civilite_id: { value: null, error: false },
    nom: { value: null, error: false },
    prenom: { value: null, error: false },
    adr1: { value: null, error: false },
    adr2: { value: null, error: false },
    adr3: { value: null, error: false },
    adr4: { value: null, error: false },
    code_postal: { value: null, error: false },
    ville: { value: null, error: false },
    pays: { value: null, error: false },
    fonction_id: { value: null, error: false },
    est_administratif: { value: false, error: false },
    est_conseiller: { value: false, error: false },
    est_formateur: { value: false, error: false },
    est_permanent: { value: false, error: false },
    yp_code: { value: null, error: false },
    created_at: { value: null, error: false },
  });

  /**
   * Permet au composant de changer de page.
   */
  const navigate = useNavigate();

  const params = useParams();

  // On a besoin du useEffect lorsque l'on est en mode édition et lecture pour précharger le formulaire avec les données de la ressource.

  useEffect(() => {
    handleIndex();
    switch (modeFormulaire) {
      case ModeFormulaire.creer:
        break;
      case ModeFormulaire.voir:
      case ModeFormulaire.editer:
        if (params.id === undefined) {
          navigate('/admin/personnels');
        }
        handleShow(parseInt(params.id as string));
        break;

      default:
        break;
    }
  }, [client]);

  /**
   * Retrouve Les ressources nécessaires pour afficher les dropdown et les stockent dans des .
   *
   * @param indexParams - Paramètres de la recherche.
   */
  const handleIndex = () => {
    setApiIsLoading(true);
    const baseParams: BaseIndexParams = { as_foreign: true, no_pagination: true };
    Promise.all([
      client.get<PaginatedApiResponse<Civilite>, BaseIndexParams>('/admin/civilites', { params: baseParams }),
      client.get<PaginatedApiResponse<Fonction>, BaseIndexParams>('/admin/fonctions', { params: baseParams }),
    ])
      .then((response) => {
        setCivilites(response[0].data.data as unknown as DropdownRessource[]);
        setFonctions(response[1].data.data as unknown as DropdownRessource[]);
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Retrouve par le réseau la ressource liée à l'ID foruni et la met dans un état local.
   * Ne doit être utilisé qu'en mode édition et lecture seule.
   *
   * @param ressourceId - ID de la ressource dont on veut retrouver les informations.
   */
  const handleShow = (ressourceId: Ressource['id']) => {
    if (apiIsLoading === true) {
      return;
    }

    setApiIsLoading(true);
    client
      .get<GoodApiResponse<Personnel>>(`/admin/personnels/${ressourceId}`)
      .then((reponse) => {
        const updatedInputs = Object.keys(reponse.data.data).reduce((acc, key) => {
          return { ...acc, [key]: { value: reponse.data.data[key as keyof Personnel], error: false } };
        }, formInputs);
        setFormInputs(updatedInputs);
        setNomPrenom(`${updatedInputs.prenom.value} ${updatedInputs.nom.value}`);
      })
      .catch((error: ErrorApiResponse<Personnel>) => {
        handleError(error, () => {
          navigate('/admin/personnels');
        });
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Poste par le réseau une ressource crée dans le formulaire via un état local.
   * Ne doit être utilisé qu'en mode création.
   */
  const handleStore = () => {
    setApiIsLoading(true);

    let ressource: Partial<Personnel> = {};
    Object.keys(formInputs).forEach((key) => {
      if (formInputs[key as keyof Personnel]?.value !== null) {
        ressource = { ...ressource, [key]: formInputs[key as keyof Personnel]?.value };
      }
    });

    client
      .post<GoodApiResponse<Personnel>>('/admin/personnels', ressource)
      .then(() => {
        toast.success(`Création éffectuée`);
        navigate('/admin/personnels');
      })
      .catch((error: ErrorApiResponse<Personnel>) => {
        handleError(error, () => {
          let object = formInputs;
          Object.keys(error.response.data.errors).forEach((key) => {
            if (error.response.data.errors[key as keyof Personnel]) {
              object = { ...object, [key]: { value: formInputs[key as keyof Personnel]?.value, error: true } };
            }
          }, formInputs);
          setFormInputs(object);
        });
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Met à jour, par le réseau, une ressource à partir du formulaire via un état local.
   * Ne doit être utilisé qu'en mode édition.
   *
   * @param ressourceId - ID de la ressource dont on veut modifier les informations.
   */
  const handleUpdate = (ressourceId: Ressource['id']) => {
    setApiIsLoading(true);

    let ressource: Partial<Personnel> = {};
    Object.keys(formInputs).forEach((key) => {
      if (formInputs[key as keyof Personnel]?.value !== null) {
        ressource = { ...ressource, [key]: formInputs[key as keyof Personnel]?.value };
      }
    });

    client
      .patch<GoodApiResponse<Personnel>>(`/admin/personnels/${ressourceId}`, ressource)
      .then(() => {
        toast.success(`Modification éffectuée`);
        navigate('/admin/personnels');
      })
      .catch((error: ErrorApiResponse<Personnel>) => {
        handleError(error, () => {
          let object = formInputs;
          Object.keys(error.response.data.errors).forEach((key) => {
            if (error.response.data.errors[key as keyof Personnel]) {
              object = { ...object, [key]: { value: formInputs[key as keyof Personnel]?.value, error: true } };
            }
          }, formInputs);
          setFormInputs(object);
        });
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Cette méthode permet de gérer l'envoi du formulaire en fonction du mode dans lequel on se trouve.
   * L'envoi ne fait rien si on est en mode voir.
   *
   * @param event - Évenement de l'envoie.
   */
  const handleSubmit = (event: { preventDefault: () => void }): void => {
    event.preventDefault();

    if (apiIsLoading === true) {
      return;
    }

    if (modeFormulaire === ModeFormulaire.creer) {
      handleStore();
    }

    if (modeFormulaire === ModeFormulaire.editer) {
      if (params.id !== undefined) {
        handleUpdate(parseInt(params.id as string));
      }
    }
  };

  /**
   * Permet d'écrire le mot correspondant au mode de fomulaire. Ce mot s'affiche au début du formulaire juste à coté de la ressource.
   *
   * @param modeFormulaire - Mode du formulaire (creer, editer et voir).
   * @returns Mot à afficher correspondant au mode de formulaire.
   * @throws Lance une erreur si aucun mode formulaire n'est fourni.
   */
  const afficheModeFormulaire = (modeFormulaire: ModeFormulaire) => {
    switch (modeFormulaire) {
      case ModeFormulaire.creer:
        return 'Création';
      case ModeFormulaire.editer:
        return 'Édition';
      case ModeFormulaire.voir:
        return 'Voir';

      default:
        throw new Error("Vous n'avez pas choisi un mode de formulaire adéquat.");
    }
  };

  if (civilites === null || fonctions === null) {
    return <Fragment>Chargement...</Fragment>;
  }

  return (
    <Fragment>
      <Helmet>
        <title>
          {afficheModeFormulaire(modeFormulaire)} personnel - {Config.app_label}
        </title>
      </Helmet>
      <HeaderForm
        text={`${afficheModeFormulaire(modeFormulaire)} personnel ${modeFormulaire !== ModeFormulaire.creer ? nomPrenom : ''}`}
        returnUrl='/admin/personnels'
      />
      <form className='relative' onSubmit={handleSubmit}>
        <div className='bg-white p-5 rounded-b-lg'>
          <div className='container mx-auto bg-white rounded'>
            <fieldset className='pb-7 mb-7 border-b'>
              <legend className='block text-left mb-3 text-xl text-secondary-1'>Identité</legend>
              <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-2 items-center'>
                <div>
                  <ReadOnlyText label='Prénom' value={formInputs.prenom.value as string} error={formInputs.prenom.error} />
                </div>
                <div>
                  <ReadOnlyText label='Nom' value={formInputs.nom.value as string} error={formInputs.nom.error} />
                </div>
                <div>
                  <ReadOnlySelect
                    label='Civilité'
                    value={formInputs.civilite_id.value !== null && formInputs.civilite_id.value !== undefined ? formInputs.civilite_id.value.toString() : ''}
                    error={formInputs.civilite_id.error}
                    options={civilites}
                  />
                </div>
                <div>
                  <ReadOnlySelect
                    label='Fonction'
                    value={formInputs.fonction_id.value !== null && formInputs.fonction_id.value !== undefined ? formInputs.fonction_id.value.toString() : ''}
                    error={formInputs.fonction_id.error}
                    options={fonctions}
                  />
                </div>
                <div>
                  <ReadOnlyNumber label='YParéo CodePersonnel' value={formInputs.yp_code.value} error={formInputs.yp_code.error} />
                </div>
              </div>
            </fieldset>
            <fieldset className='pb-7 mb-7 border-b'>
              <legend className='block text-left mb-3 text-xl text-secondary-1'>Adresse</legend>
              <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-2 items-center'>
                <div>
                  <ReadOnlyText label='Adresse 1' value={formInputs.adr1.value as string} error={formInputs.adr1.error} />
                </div>
                <div>
                  <ReadOnlyText label='Adresse 2' value={formInputs.adr2.value as string} error={formInputs.adr2.error} />
                </div>
                <div>
                  <ReadOnlyText label='Adresse 3' value={formInputs.adr3.value as string} error={formInputs.adr3.error} />
                </div>
                <div>
                  <ReadOnlyText label='Adresse 4' value={formInputs.adr4.value as string} error={formInputs.adr4.error} />
                </div>
                <div>
                  <ReadOnlyText label='Code postal' value={formInputs.code_postal.value as string} error={formInputs.code_postal.error} />
                </div>
                <div>
                  <ReadOnlyText label='Ville' value={formInputs.ville.value as string} error={formInputs.ville.error} />
                </div>
                <div>
                  <ReadOnlyText label='Pays' value={formInputs.pays.value as string} error={formInputs.pays.error} />
                </div>
              </div>
            </fieldset>
            <fieldset className='pb-7 mb-7 b'>
              <legend className='block text-left mb-3 text-xl text-secondary-1'>Paramètres</legend>
              <div className='grid grid-cols-1 gap-2 items-center'>
                <div>
                  <ReadOnlyBoolean label='Est Administratif' value={formInputs.est_administratif.value} />
                </div>
                <div>
                  <ReadOnlyBoolean label='Est conseiller' value={formInputs.est_conseiller.value} />
                </div>
                <div>
                  <ReadOnlyBoolean label='Est formatteur' value={formInputs.est_formateur.value} />
                </div>
                <div>
                  <ReadOnlyBoolean label='Est permanent' value={formInputs.est_permanent.value} />
                </div>
              </div>
            </fieldset>
          </div>
        </div>
        {apiIsLoading ? <LoadingAbsolute /> : ''}
      </form>
    </Fragment>
  );
};

import { ChangeEvent, ChangeEventHandler, Fragment, FunctionComponent, useCallback } from 'react';
import { DropdownRessource } from '../../types';

/**
 * Composant InputSelect.
 *
 * @param label - Libellé du champ de sélection.
 * @param value - Valeur du champ de sélection.
 * @param onChange - Fonction de rappel appelée lorsque la valeur du champ de sélection change.
 * @param error - Indique si le champ de sélection a une erreur.
 * @param options - Options du champ de sélection.
 * @return Composant InputSelect.
 */
export const InputSelect: FunctionComponent<{
  error?: boolean;
  label?: string;
  onChange?: ChangeEventHandler<HTMLSelectElement>;
  options?: DropdownRessource[];
  value?: string | number | readonly string[];
}> = ({ label, value, onChange = undefined, error = false, options }) => {
  /**
   * @constant Elle permet de définir éventuellement un `id` pour le `input` afin que les labels faasent bien référence au champ.
   */
  const inputId = label ? `input-${label.toLowerCase().replace(' ', '-')}` : undefined;

  /**
   * Gère le changement de valeur dans le composant parent.
   *
   * @param e - L'événement de changement de valeur du champ de sélection.
   */
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      if (onChange) {
        onChange(e);
      }
    },
    [onChange],
  );

  return (
    <Fragment>
      {label && (
        <label className={`text-xs ${error ? 'text-red-500' : ''}`} htmlFor={inputId}>
          {label}
        </label>
      )}
      <select
        className={`bg-white relative w-full px-4 py-2 text-sm leading-none text-left text-secondary-1 border border-gray-400 rounded outline-none focus:border-secondary-2 ${
          error ? 'border-red-500 text-red-500' : ''
        }`}
        value={value}
        onChange={handleChange}
        id={inputId}>
        <option value=''>-</option>
        {options &&
          options.map((option) => (
            <option key={option.id} value={option.id}>
              {option.label}
            </option>
          ))}
      </select>
    </Fragment>
  );
};

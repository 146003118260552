import { ArrowDownIcon, ArrowUpIcon, CheckIcon, EllipsisVerticalIcon, MagnifyingGlassIcon, PlusIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { Menu, MenuHandler, Button, MenuList, MenuItem } from '@material-tailwind/react';
import { useState, FunctionComponent, Fragment, useEffect } from 'react';
import { DropdownRessource, ForeignRessources, Ressource } from '../types';
import { IndexParams, PaginatedApiResponse, SearchParams } from '../types/api';
import { RoleEnum } from '../types/auth';
import { RessourceListColumn, SortDirection, ColumnTypes } from '../types/ressourceList';
import { BadgeRole } from './BadgeRole';

/**
 * Ce composant affiche un tableau de données avec un système de recherche sophistiqué.
 *
 * @param name - Nom interne de la ressource.
 * @param labels - Nom affiché du tableau au pluriel.
 * @param paginatedApiData - Données fournies. Il s'agit du retour de l'API avec la pagination.
 * @param columns - Colonnes du tableau.
 * @param handleIndex - Retrouve des données paginées de L'API et les stocke dans un état local. `indexParams` regroupe les paramètre de recherche éventuels.
 * @param onAdd - Permet d'exécuter un callback lorsque l'on souhaite ajouter une ressource. Si définie, le bouton d'ajout apparait.
 * @param onUpdate - Permet d'exécuter un callback lorsque l'on souhaite supprimer une ressource. Avec  `ressourceId` comme ID de la ressource que l'on souhaite supprimer.
 * @param onDelete - Permet d'exécuter un callback lorsque l'on souhaite mettre à jour une ressource.. Avec  `ressourceId` comme ID de la ressource que l'on souhaite modifier.
 * @param defaultSortBy - Trier par une des colonnes présente. Par défaut `'id'`.
 * @param defaultSortDirection - Direction du trie. Par défaut `'ASC'`.
 * @param foreigns - Ressources étrangères.
 * @param afficheCon - Set surtout à préciser un libellé de menu.
 */
export const RessourceList: FunctionComponent<{
  name: string;
  labels: string;
  paginatedApiData: PaginatedApiResponse<Ressource> | null;
  columns: RessourceListColumn<Ressource>[];
  handleIndex: (indexParams?: Partial<IndexParams>) => void;
  onAdd?: () => void;
  onUpdate?: (ressourceId: Ressource['id']) => void;
  onRead?: (ressourceId: Ressource['id']) => void;
  onDelete?: (ressourceId: Ressource['id']) => void;
  defaultSortBy?: RessourceListColumn<Ressource>['name'];
  defaultSortDirection?: SortDirection;
  foreigns?: ForeignRessources;
}> = ({
  name,
  labels,
  paginatedApiData,
  columns,
  handleIndex,
  onAdd,
  onUpdate,
  onRead,
  onDelete,
  defaultSortBy = 'id',
  defaultSortDirection = SortDirection.ASC,
  foreigns,
}) => {
  /**
   * On doit initialiser l'état de l'objet recherche.
   *
   * @param columns - Colonnes à afficher.
   * @returns Un objet avec les clé de la ressource initialisé à nul pour faire la recherche.
   */
  const prepareSearchState = (columns: RessourceListColumn<Ressource>[]) => {
    let object: SearchParams = {};

    columns.forEach((column) => {
      switch (column.type) {
        case ColumnTypes.int:
        case ColumnTypes.currency:
        case ColumnTypes.date:
        case ColumnTypes.datetime:
          object = { ...object, [`${column.name}_from`]: null, [`${column.name}_to`]: null };
          break;
        case ColumnTypes.id:
        case ColumnTypes.boolean:
        case ColumnTypes.trilean:
        case ColumnTypes.foreign:
        case ColumnTypes.varchar:
          object = { ...object, [column.name]: null };
          break;

        default:
          break;
      }
    });

    return object;
  };

  let initialSearchState: SearchParams = {};

  if (paginatedApiData !== null) {
    initialSearchState = prepareSearchState(columns);
  }

  const [sortColumn, setSortColumn] = useState<RessourceListColumn<Ressource>['name']>(
    (localStorage.getItem(`RessouceList-${name}-SortColumn`) as RessourceListColumn<Ressource>['name']) || defaultSortBy,
  );

  const [sortOrder, setSortOrder] = useState<SortDirection>((localStorage.getItem(`RessouceList-${name}-SortOrder`) as SortDirection) || defaultSortDirection);

  // Etat principal des champs de recherche
  const [searchValues, setSearchValues] = useState<typeof initialSearchState>(
    JSON.parse(localStorage.getItem(`RessouceList-${name}-Search`) as string) || initialSearchState,
  );

  // Etat tampon pour stocker les champs de recherche en attente
  const [searchParams, setSearchParams] = useState<typeof initialSearchState>(
    JSON.parse(localStorage.getItem(`RessouceList-${name}-Search`) as string) || initialSearchState,
  );

  const [currentPage, setCurrentPage] = useState<number>(parseInt(localStorage.getItem(`RessouceList-${name}-CurrentPage`) || '1'));

  /**
   * Ce useEffect est hyper important car c'est lui qui rappelle recharge les données lorsque l'on charge de page ou de tri.
   */
  useEffect(() => {
    if (!paginatedApiData) {
      handleIndex({
        current_page: currentPage,
        direction: sortOrder,
        per_page: 10,
        sort: sortColumn,
        with_columns: true,
        with_foreigns: true,
        ...searchValues,
      });
    } else {
      localStorage.setItem(`RessouceList-${name}-CurrentPage`, currentPage.toString());
      localStorage.setItem(`RessouceList-${name}-SortColumn`, sortColumn);
      localStorage.setItem(`RessouceList-${name}-SortOrder`, sortOrder);
      localStorage.setItem(`RessouceList-${name}-Search`, JSON.stringify(searchValues));
      handleIndex({ current_page: currentPage, direction: sortOrder, per_page: 10, sort: sortColumn, ...searchValues });
    }
  }, [currentPage, sortOrder, sortColumn, searchValues]);

  /**
   * Permet de modifier un état local tampon qui contien les valeurs des champs de recherches.
   *
   * @param field - nom de la colonne, utilise pour renseigner le nom exacte des champs pour les colonne à intervale.
   * @param columnType - Information sur la colonne et le champ du tableau.
   * @param automaticSearch - Pécise si on doit lancer la recherche générale dès le changement d'état du champs.
   *
   * @param event
   */
  const handleSearchChange = (field: string, columnType: ColumnTypes, automaticSearch = false) => {
    return (event: { target: { value: string } }) => {
      let object: SearchParams = searchParams;

      switch (columnType) {
        case ColumnTypes.id:
        case ColumnTypes.int:
        case ColumnTypes.trilean:
        case ColumnTypes.foreign:
          object = {
            ...object,
            [field]: event.target.value !== '' ? parseInt(event.target.value) : null,
          };
          break;
        case ColumnTypes.currency:
          object = {
            ...object,
            [field]: event.target.value !== '' ? Math.round(parseFloat((parseFloat(event.target.value) * 100).toFixed(2))) : null,
          };
          break;
        case ColumnTypes.boolean:
          switch (event.target.value) {
            case 'oui':
              object = {
                ...object,
                [field]: true,
              };
              break;
            case 'non':
              object = {
                ...object,
                [field]: false,
              };
              break;
            default:
              object = {
                ...object,
                [field]: null,
              };
              break;
          }
          break;

        case ColumnTypes.date:
        case ColumnTypes.datetime:
        case ColumnTypes.varchar:
          object = {
            ...object,
            [field]: event.target.value !== '' ? event.target.value : null,
          };
          break;

        default:
          break;
      }
      setSearchParams(object);
      if (automaticSearch) {
        handleSearch(object);
      }
    };
  };

  /**
   * Fonction pour réinitialiser les champs de recherche
   */
  const handleResetSearch = () => {
    setCurrentPage(1);
    setSearchParams(initialSearchState);
    setSearchValues(initialSearchState);
  };

  /**
   * Fonction pour mettre à jour l'état principal avec la valeur de l'état tampon avec l'objet de recherche fourni.
   *
   * @param serachObject - Objet de recherche.
   */
  const handleSearch = (serachObject: SearchParams): void => {
    setCurrentPage(1);
    setSearchValues(serachObject);
  };

  /**
   * Gère les tri des colonnes en modifiant les états locaux.
   *
   * @param column - colonne à trier.
   */
  const handleSort = (column: RessourceListColumn<Ressource>) => {
    if (column.sortable) {
      setCurrentPage(1);
      if (sortColumn === column.name) {
        setSortOrder(sortOrder === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC);
      } else {
        setSortColumn(column.name);
        setSortOrder(SortDirection.ASC);
      }
    }
  };

  /**
   * Gère le changement de page en modifiant un état local.
   *
   * @param page - Page que l'on veut atteindre.
   */
  const handleGotoPage = (page: number) => {
    setCurrentPage(page);
  };

  /**
   * Permet d'afficher les données convenablement dans le tableau.
   *
   * @param value - Valeur brute qui arrive de l'API
   * @param columnType - Type de la colonne.
   * @returns Renvoit la donnée formatée conformément au type de la colonne.
   */
  const displayByType = (value: unknown, column: RessourceListColumn<Ressource>, foreigns: ForeignRessources): string | JSX.Element => {
    if (!value || value === null) {
      return '';
    }

    switch (column.type) {
      case ColumnTypes.id:
        return new Intl.NumberFormat().format(value as number);
      case ColumnTypes.int:
      case ColumnTypes.decimal:
        return <div className='text-right'>{new Intl.NumberFormat().format(value as number)}</div>;
      case ColumnTypes.currency:
        return <div className='text-right'>{new Intl.NumberFormat('default', { style: 'currency', currency: 'EUR' }).format((value as number) / 100)}</div>;

      case ColumnTypes.date:
        return new Intl.DateTimeFormat('default', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        }).format(new Date(value as string));

      case ColumnTypes.datetime:
        return new Intl.DateTimeFormat('default', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
          hour12: false,
        }).format(new Date(value as string));

      case ColumnTypes.boolean:
        switch (value) {
          case true:
            return <CheckIcon className='w-4 h-4 text-white block mx-auto bg-light-green-300 rounded-full p-0.5' />;
          case false:
            return <XMarkIcon className='w-4 h-4 block mx-auto text-gray-900/20' />;

          default:
            return '';
        }
      case ColumnTypes.trilean:
        switch (value) {
          case 0:
            return <ExclamationTriangleIcon className='w-5 h-5 text-light-blue-300 block mx-auto p-0.5' />;
          case 1:
            return <CheckIcon className='w-4 h-4 text-white block mx-auto bg-light-green-300 rounded-full p-0.5' />;
          case 2:
            return <XMarkIcon className='w-4 h-4 text-white block mx-auto bg-red-300 rounded-full p-0.5' />;

          default:
            return '';
        }
      case ColumnTypes.image:
        return (
          <div className='h-10 w-10 object-cover aspect-1'>
            <img src={value as string} className='h-10 w-10 object-cover rounded-md shadow aspect-1' />
          </div>
        );

      case ColumnTypes.foreign:
        return foreigns[column.name.replace(/_id$/, 's')].filter((foreign) => foreign.id === value)[0] !== undefined
          ? foreigns[column.name.replace(/_id$/, 's')].filter((foreign) => foreign.id === value)[0].label
          : '';

      case ColumnTypes.color:
        return <div className='h-4 w-4 block mx-auto' style={{ backgroundColor: value as string }}></div>;

      case ColumnTypes.array:
        switch (column.component) {
          case 'ColoredBadge':
            return (
              <div>
                {(value as DropdownRessource[]).map((item) => (
                  <div key={item.id} className='pt-2'>
                    <BadgeRole text={item.label as RoleEnum} />
                  </div>
                ))}
              </div>
            );

          default:
            return (
              <div>
                {(value as DropdownRessource[]).map((item) => (
                  <div key={item.id} className='pt-2'>
                    {item.label}
                  </div>
                ))}
              </div>
            );
        }

      default:
        return value as string;
    }
  };

  const displaySearchInputByType = (column: RessourceListColumn<Ressource>, foreigns: ForeignRessources) => {
    switch (column.type) {
      case ColumnTypes.id:
        return (
          <input
            type='number'
            className='w-24 font-normal mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 focus:outline-none focus:border-sky-500 focus:ring-primary block rounded-md text-xs focus:ring-1 placeholder-gray-600 text-gray-600'
            placeholder={column.label.toLowerCase()}
            value={searchParams[column.name] !== null ? (searchParams[column.name] as number) : ''}
            onChange={handleSearchChange(column.name, column.type)}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                (event.target as HTMLInputElement).blur();
                handleSearch(searchParams);
              }
            }}
          />
        );
      case ColumnTypes.currency:
      case ColumnTypes.int:
        return (
          <Fragment>
            <input
              type='number'
              className='max-w-96 font-normal mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 focus:outline-none focus:border-sky-500 focus:ring-primary block rounded-md text-xs focus:ring-1 placeholder-gray-600 text-gray-600'
              placeholder={column.label.toLowerCase()}
              value={searchParams[`${column.name}_from`] !== null ? (searchParams[`${column.name}_from`] as number) : ''}
              onChange={handleSearchChange(`${column.name}_from`, column.type)}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  (event.target as HTMLInputElement).blur();
                  handleSearch(searchParams);
                }
              }}
            />
            <input
              type='number'
              className='max-w-96 font-normal mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 focus:outline-none focus:border-sky-500 focus:ring-primary block rounded-md text-xs focus:ring-1 placeholder-gray-600 text-gray-600'
              placeholder={column.label.toLowerCase()}
              value={searchParams[`${column.name}_to`] !== null ? (searchParams[`${column.name}_to`] as number) : ''}
              onChange={handleSearchChange(`${column.name}_to`, column.type)}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  (event.target as HTMLInputElement).blur();
                  handleSearch(searchParams);
                }
              }}
            />
          </Fragment>
        );
      case ColumnTypes.varchar:
        return (
          <input
            type='text'
            className='max-w-96 font-normal mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 focus:outline-none focus:border-sky-500 focus:ring-primary block rounded-md text-xs focus:ring-1 placeholder-gray-600 text-gray-600'
            placeholder={column.label.toLowerCase()}
            value={searchParams[column.name] !== null ? (searchParams[column.name] as string) : ''}
            onChange={handleSearchChange(column.name, column.type)}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                (event.target as HTMLInputElement).blur();
                handleSearch(searchParams);
              }
            }}
          />
        );
      case ColumnTypes.date:
      case ColumnTypes.datetime:
        return (
          <Fragment>
            <input
              className='font-normal w-48 mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-gray-600 text-gray-600 focus:outline-none focus:border-sky-500 focus:ring-primary block rounded-md text-xs focus:ring-1'
              type='date'
              value={searchParams[`${column.name}_from`] !== null ? (searchParams[`${column.name}_from`] as string) : ''}
              onChange={handleSearchChange(`${column.name}_from`, column.type)}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  (event.target as HTMLInputElement).blur();
                  handleSearch(searchParams);
                }
              }}
            />
            <input
              className='font-normal w-48 mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-gray-600 text-gray-600 focus:outline-none focus:border-sky-500 focus:ring-primary block rounded-md text-xs focus:ring-1'
              type='date'
              value={searchParams[`${column.name}_to`] !== null ? (searchParams[`${column.name}_to`] as string) : ''}
              onChange={handleSearchChange(`${column.name}_to`, column.type)}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  (event.target as HTMLInputElement).blur();
                  handleSearch(searchParams);
                }
              }}
            />
          </Fragment>
        );
      case ColumnTypes.boolean:
        return (
          <select
            className='min-w-11 bg-white text-gray-600 border-gray-300 rounded-md font-normal text-xs p-[5.5px] border'
            value={searchParams[column.name] !== undefined && searchParams[column.name] !== null ? ((searchParams[column.name] as string) ? 'oui' : 'non') : ''}
            onChange={handleSearchChange(column.name, column.type, true)}>
            <option value=''>-</option>
            <option value='oui'>Oui</option>
            <option value='non'>Non</option>
          </select>
        );
      case ColumnTypes.foreign:
        return (
          <select
            className='min-w-11 max-w-xs bg-white text-gray-600 border-gray-300 rounded-md font-normal text-xs p-[5.5px] border'
            value={searchParams[column.name] !== null ? (searchParams[column.name] as number) : ''}
            onChange={handleSearchChange(column.name, column.type, true)}>
            <option value=''>-</option>
            {foreigns[column.name.replace(/_id$/, 's')].map((foreign) => (
              <option key={foreign.id} value={foreign.id}>
                {foreign.label as string}
              </option>
            ))}
          </select>
        );
      case ColumnTypes.trilean:
        return (
          <select
            className='min-w-11 bg-white text-gray-600 border-gray-300 rounded-md font-normal text-xs p-[5.5px] border'
            value={searchParams[column.name] !== null ? (searchParams[column.name] as number) : ''}
            onChange={handleSearchChange(column.name, column.type, true)}>
            <option value=''>-</option>
            <option value={0}>Non défini</option>
            <option value={1}>Oui</option>
            <option value={2}>Non</option>
          </select>
        );

      default:
        break;
    }
  };

  if (paginatedApiData === null) {
    return <Fragment>Chargement...</Fragment>;
  }

  return (
    <Fragment>
      <div className='px-4 py-4 bg-gray-100 rounded-tl rounded-tr'>
        <div className='flex items-center justify-between'>
          <h1 className='text-base sm:text-lg md:text-xl poppins-bold leading-normal text-secondary-1'>{labels}</h1>
          {onAdd !== undefined && (
            <div>
              <button className='mr-1 py-1 px-6 flex flex-row items-center rounded-full bg-primary text-white' onClick={() => onAdd()}>
                <PlusIcon className='h-4 w-4 inline mr-2 flex-none' />
                <span>Ajouter</span>
              </button>
            </div>
          )}
        </div>
      </div>
      <div className='mx-auto container bg-white shadow rounded-b-xl'>
        <div className='w-full overflow-x-auto'>
          <table className='table-fixed min-w-full bg-white rounded-b-lg'>
            <thead>
              <tr className='w-full h-12 border-gray-300 border-b py-8'>
                {columns.map((column) => (
                  <th
                    key={column.name}
                    className={`text-secondary-1 pl-4 text-left text-sm tracking-normal leading-4 ${column.sortable && 'cursor-pointer'} ${
                      column.type === ColumnTypes.id ? '' : ''
                    }`}
                    onClick={() => handleSort(column)}>
                    <div className='flex flex-row items-center'>
                      {column.label}
                      {sortColumn === column.name &&
                        (sortOrder === SortDirection.ASC ? <ArrowDownIcon className='ml-2 w-4 h-4' /> : <ArrowUpIcon className='ml-2 w-4 h-4' />)}
                    </div>
                  </th>
                ))}

                <th className='text-secondary-1 w-24 px-4 text-right text-sm tracking-normal leading-4'>Actions</th>
              </tr>
              <tr className='h-16 border-gray-300 border-b py-8'>
                {columns.map((column) => (
                  <th className='pl-4' key={column.name}>
                    {column.searchable && displaySearchInputByType(column, foreigns as ForeignRessources)}
                  </th>
                ))}

                <th className='text-gray-600 font-normal pr-4 text-left text-sm tracking-normal leading-4'>
                  <div className='flex flex-row items-center'>
                    <Button className='px-1.5 py-1.5 rounded-full mx-3' variant='filled' color='orange' onClick={() => handleSearch(searchParams)}>
                      <MagnifyingGlassIcon className='w-5 h-5' />
                    </Button>
                    <Button className='px-1.5 py-1.5 rounded-full bg-primary/5' variant='text' onClick={handleResetSearch}>
                      <XMarkIcon className='w-6 h-6' />
                    </Button>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {paginatedApiData.data.map((row) => (
                <tr
                  key={row.id}
                  className=' border-gray-100 border-b hover:bg-gray-100'
                  onDoubleClick={() => {
                    onUpdate && onUpdate(row.id);
                    onRead && onRead(row.id);
                  }}>
                  {columns.map((column) => (
                    <td key={column.name} className={`pl-4 py-3 text-secondary-1 poppins-medium text-[13px]`}>
                      {displayByType(row[column.name], column, foreigns as ForeignRessources)}
                    </td>
                  ))}

                  <td className='pr-4'>
                    {(onUpdate || onRead || onDelete) && (
                      <Menu placement='right-start'>
                        <MenuHandler>
                          <Button
                            variant='text'
                            className='w-9 h-9 p-1.5 rounded-full text-gray-500 block mx-auto text-center mr-0 hover:bg-white/100 active:bg-transparent'>
                            <EllipsisVerticalIcon className='h-6 w-6' />
                          </Button>
                        </MenuHandler>
                        <MenuList>
                          {onUpdate && <MenuItem onClick={() => onUpdate(row.id)}>Modifier</MenuItem>}
                          {onRead && <MenuItem onClick={() => onRead(row.id)}>Consulter</MenuItem>}
                          {onDelete && <MenuItem onClick={() => onDelete(row.id)}>Supprimer</MenuItem>}
                        </MenuList>
                      </Menu>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className='flex max-w-full flex-row  gap-4 py-2 px-5 items-center justify-between rounded-md shadow-sm'>
          <p className='text-sm text-gray-600 w-full'>
            Résultats {paginatedApiData.meta.from} - {paginatedApiData.meta.to} sur {paginatedApiData.meta.total}
          </p>
          <div className='w-2/3 flex flex-row items-center lg:items-center justify-end'>
            <button
              className='p-2 rounded-l-md border border-gray-300 hover:bg-primary/10 disabled:hover:bg-transparent text-primary disabled:text-gray-600 disabled:opacity-50 disabled:cursor-not-allowed'
              onClick={() => handleGotoPage(1)}
              disabled={currentPage === 1}>
              <ChevronDoubleLeftIcon className='h-4 w-4 opacity-100' aria-hidden='true' />
            </button>
            <button
              className='p-2 rounded-none border border-gray-300 hover:bg-primary/10 text-primary disabled:text-gray-600 disabled:hover:bg-transparent border-x-0 disabled:opacity-50 disabled:cursor-not-allowed'
              onClick={() => handleGotoPage(currentPage - 1)}
              disabled={currentPage === 1}>
              <ChevronLeftIcon className='h-4 w-4 opacity-100' aria-hidden='true' />
            </button>
            <select
              className='min-w-11 bg-white text-gray-600 border-gray-300 rounded-none p-[5.5px] border cursor-pointer disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:bg-transparent'
              value={`${currentPage}`}
              onChange={(e) => handleGotoPage(parseInt(e.target.value))}
              disabled={paginatedApiData.meta.last_page === 1}>
              {Array.from({ length: paginatedApiData.meta.last_page }, (_, i) => i + 1).map((page) => (
                <option key={page} value={`${page}`} disabled={currentPage === page}>
                  {page}
                </option>
              ))}
            </select>
            <button
              className='p-2 rounded-none border border-gray-300 hover:bg-primary/10 text-primary disabled:text-gray-600 disabled:hover:bg-transparent border-x-0 disabled:opacity-50 disabled:cursor-not-allowed'
              onClick={() => handleGotoPage(currentPage + 1)}
              disabled={currentPage === paginatedApiData.meta.last_page}>
              <ChevronRightIcon className='h-4 w-4 opacity-100' aria-hidden='true' />
            </button>
            <button
              className='p-2 rounded-r-md border border-gray-300 hover:bg-primary/10 text-primary disabled:text-gray-600 disabled:hover:bg-transparent disabled:opacity-50 disabled:cursor-not-allowed'
              onClick={() => handleGotoPage(paginatedApiData.meta.last_page)}
              disabled={currentPage === paginatedApiData.meta.last_page}>
              <ChevronDoubleRightIcon className='h-4 w-4 opacity-100' aria-hidden='true' />
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

import { Fragment, FunctionComponent, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import toast, { Renderable, Toast, ValueFunction } from 'react-hot-toast';
import { Helmet } from 'react-helmet';

import { Config } from '../../config/config';
import { LoadingAbsolute } from '../../components/LoadingAbsolute';
import { useApi } from '../../providers/ApiProvider';
import { useUser } from '../../providers/UserProvider';

/**
 * Ce composant affiche la page d'e-mail vérifié.
 */
export const VerifieEmailScreen: FunctionComponent = () => {
  /**
   * On stocke si on est en train d'utiliser l'API ou non.
   */
  const [apiIsLoading, setApiIsLoading] = useState(false);

  /**
   * Cet état local précise si l'e-mail a été vérifié
   */
  const [emailIsVerified, setEmailIsVerified] = useState(false);

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  /**
   * On a besoin des informations de l'utilisateur.
   */
  const { user } = useUser();

  /**
   * On a besoin des paramètres pour avoir l'e-mail et le jeton.
   */
  const params = useParams();

  /**
   * Cette méthode effectue l'appel API pour vérifier le mail de l'utilisateur.
   *
   * @param event - Évènement d'envoi du formulaire.
   */
  const handleVerifyEmail = (event: { preventDefault: () => void }) => {
    event.preventDefault();

    if (apiIsLoading === true) {
      return;
    }

    setApiIsLoading(true);

    // Appel effectif
    client
      .post('/verifie-email', {
        email: params.email,
        token: params.token,
      })
      .then(() => {
        setApiIsLoading(false);
        setEmailIsVerified(true);
        toast.success('Votre email est vérifié. Vous pouvez maintenant vous connecter');
      })
      .catch(
        (error: {
          response: {
            status: number;
            data: { message: Renderable | ValueFunction<Renderable, Toast> };
          };
          message: Renderable | ValueFunction<Renderable, Toast>;
        }) => {
          setApiIsLoading(false);
          if (error.response && [400, 401, 422].includes(error.response.status) && error.response.data.message) {
            toast.error(error.response.data.message);
          } else {
            toast.error(error.message);
          }
        },
      );
  };

  // Vue
  if (!user || !user.token) {
    if (emailIsVerified === false) {
      return (
        <Fragment>
          <Helmet>
            <title>Vérification e-mail - {Config.app_label}</title>
          </Helmet>

          <form className='p-6 bg-[#F7FBFE] w-full rounded-md shadow-md relative' onSubmit={handleVerifyEmail}>
            <h2 className='text-3xl text-center mb-1 space-x-3 poppins-bold text-secondary-2'>
              Confirmez votre
              <br />
              adresse e-mail
            </h2>
            <p className='my-8 text-sm text-gray-700 text-center leading-tight'>
              Nous vous remercions pour la création de votre compte !<br />
              Afin de pouvoir l'utiliser, vous devez confirmer l'adresse e-mail indiquée lors de l'inscription en cliquant sur le bouton ci-dessous.
            </p>
            <div className='mt-3'>
              <button type='submit' className='poppins-semibold w-full px-2 py-1 rounded-md bg-primary text-white  disabled:bg-primary/50 uppercase'>
                Je confirme l'adresse e-mail
              </button>
            </div>
            {apiIsLoading ? <LoadingAbsolute /> : ''}
          </form>
        </Fragment>
      );
    } else {
      return <Navigate to='/merci-inscription' />;
    }
  } else {
    return <Navigate to='/mon-espace' />;
  }
};

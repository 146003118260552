import { Dialog, DialogHeader, DialogBody, DialogFooter, Button } from '@material-tailwind/react';
import { FunctionComponent } from 'react';
import { Groupe } from '../../../types/EntreprisesRessources';
import { LoadingAbsolute } from '../../LoadingAbsolute';
import { DatesSiteDisplay } from '../../DateSiteDisplay';

/**
 * Ce composant permet d'afficher une Modale pour que les dirigeant puissent effectuer une demande à partir du souhait.
 *
 * @param visible - Précise si la modale et ouverte ou non. Provient du parent.
 * @param setVisible - Accesseur qui modifie la visibilité de la modale. Provient du parent.
 * @param groupes - Sessions auxquelles on peut ratacher le salarié. Provient du parent.
 * @param apiIsLoading - Précise si on affiche le loader ou non. Provient du parent.
 * @param handleEffectuerDemande - Callback qui réalise l'appel pour effectuer la demande. Provient du parent.
 */
export const ModalEffectuerDemande: FunctionComponent<{
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  groupes: Groupe[];
  groupeSelectionne: Groupe | null;
  apiIsLoading: boolean;
  handleEffectuerDemande: (groupeToken?: Groupe['token']) => void;
}> = ({ visible, setVisible, groupes, groupeSelectionne, apiIsLoading, handleEffectuerDemande }) => (
  <Dialog open={visible} size='xs' handler={setVisible}>
    <DialogHeader>Assignez une session pour la demande</DialogHeader>
    <DialogBody divider>
      <p>Cette action va envoyer un mail au salarié pour l'informer que la demande a été effectuée.</p>
      <div className='bg-white w-full overflow-x-auto overflow-y-auto h-96'>
        <table className='table-fixed min-w-full bg-white rounded-b-lg'>
          <tbody>
            <tr className='border-gray-100 border-b hover:bg-gray-100'>
              <td className='px-4 py-3 text-gray-800 text-sm' colSpan={2}>
                <div>
                  <Button
                    variant='filled'
                    className=' disabled:bg-primary/30 disabled:border-primary/30 w-full'
                    color='orange'
                    onClick={() => {
                      setVisible(false);
                      handleEffectuerDemande('');
                    }}>
                    Intégrer n'importe quelle session disponible
                  </Button>
                </div>
              </td>
            </tr>
            {groupes.map((groupe) => (
              <tr
                key={groupe.token}
                className={`border-gray-100 border-b hover:bg-gray-100 ${groupeSelectionne && groupeSelectionne.token === groupe.token ? 'bg-blue-100' : ''}`}>
                <td className='px-4 py-3 text-gray-800 text-sm'>
                  <div className='montserrat-semibold'>{groupe.nom}</div>
                  <DatesSiteDisplay groupe={groupe} />
                </td>
                <td className='px-4 py-3 text-gray-800 text-sm'>
                  <Button
                    variant='filled'
                    className=' disabled:bg-primary/30 disabled:border-primary/30 w-full'
                    color='orange'
                    onClick={() => {
                      handleEffectuerDemande(groupe.token as string);
                    }}>
                    Intégrer cette session
                  </Button>
                  {groupeSelectionne && groupeSelectionne.token === groupe.token && (
                    <div className='text-blue-600 poppins text-center text-xs pt-2'>Session choisie lors du souhait de formation.</div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {apiIsLoading ? <LoadingAbsolute /> : ''}
    </DialogBody>
    <DialogFooter>
      <Button
        variant='text'
        color='gray'
        onClick={() => {
          setVisible(false);
        }}
        className='mr-1 !rounded-full'>
        Annuler
      </Button>
    </DialogFooter>
  </Dialog>
);

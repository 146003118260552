import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { LoadingAbsolute } from '../../../components/LoadingAbsolute';
import { useApi } from '../../../providers/ApiProvider';
import { ModeFormulaire, Ressource } from '../../../types';
import { HeaderForm } from '../../../components/HeaderForm';
import { GoodApiResponse, ErrorApiResponse } from '../../../types/api';
import { Opco } from '../../../types/schematics';
import { Config } from '../../../config/config';
import { Helmet } from 'react-helmet';
import { ReadOnlyNumber } from '../../../components/Inputs/ReadOnlyNumber';
import { handleError } from '../../../utils/ErrorHandler';
import { ReadOnlyText } from '../../../components/Inputs/ReadOnlyText';

type Props = { modeFormulaire: ModeFormulaire };

/**
 * Ce composant doit afficher un formulaire qui à 3 modes de fonctionnement. Les 2 principaux sont la création et l'édition.
 * On a également un mode lecture seule. L'idée c'est de gérer une partie du CRUD.
 * C'est dans ce composant que vont se faire les appels API pour créer, éditer et voir une ressource en particulier.
 *
 * @param modeFormulaire - Mode du formulaire (creer, editer et voir).
 * @returns JSX du composant
 */
export const AdminOpcoFormScreen: FunctionComponent<Props> = ({ modeFormulaire }) => {
  // On initialise les données dont on va avoir besoin sur cette page

  /**
   * On stocke si on est en train d'utiliser l'API ou non.
   */
  const [apiIsLoading, setApiIsLoading] = useState(false);

  /**
   * On stocke le nom afin de l'afficher en mode édition et voir.
   */
  const [nom, setNom] = useState<Opco['nom']>('');

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  /**
   * Contient un état général des valeurs des champs du formulaire ainsi que les champs qui ont reçu une erreur lors de l'envoi du formulaire.
   */
  const [formInputs, setFormInputs] = useState<{ [K in keyof Opco]: { value: Opco[K]; error: boolean } }>({
    nom: { value: '', error: false },
    yp_code: { value: null, error: false },
    created_at: { value: null, error: false },
  });

  /**
   * Permet au composant de changer de page.
   */
  const navigate = useNavigate();

  const params = useParams();

  // On a besoin du useEffect lorsque l'on est en mode édition et lecture pour précharger le formulaire avec les données de la ressource.

  useEffect(() => {
    switch (modeFormulaire) {
      case ModeFormulaire.creer:
        break;
      case ModeFormulaire.voir:
      case ModeFormulaire.editer:
        if (params.id === undefined) {
          navigate('/admin/opcos');
        }
        handleShow(parseInt(params.id as string));
        break;

      default:
        break;
    }
  }, [client]);

  /**
   * Retrouve par le réseau la ressource liée à l'ID foruni et la met dans un état local.
   * Ne doit être utilisé qu'en mode édition et lecture seule.
   *
   * @param ressourceId - ID de la ressource dont on veut retrouver les informations.
   */
  const handleShow = (ressourceId: Ressource['id']) => {
    if (apiIsLoading === true) {
      return;
    }

    setApiIsLoading(true);
    client
      .get<GoodApiResponse<Opco>>(`/admin/opcos/${ressourceId}`)
      .then((reponse) => {
        const updatedInputs = Object.keys(reponse.data.data).reduce((acc, key) => {
          return { ...acc, [key]: { value: reponse.data.data[key as keyof Opco], error: false } };
        }, formInputs);
        setFormInputs(updatedInputs);
        setNom(updatedInputs.nom.value);
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error, () => {
          navigate('/admin/opcos');
        });
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Poste par le réseau une ressource crée dans le formulaire via un état local.
   * Ne doit être utilisé qu'en mode création.
   */
  const handleStore = () => {
    setApiIsLoading(true);

    let ressource: Partial<Opco> = {};
    Object.keys(formInputs).forEach((key) => {
      if (formInputs[key as keyof Opco]?.value !== null) {
        ressource = { ...ressource, [key]: formInputs[key as keyof Opco]?.value };
      }
    });
    client
      .post<GoodApiResponse<Opco>, Opco>('/admin/opcos', ressource as Opco)
      .then(() => {
        toast.success(`Création éffectuée`);
        navigate('/admin/opcos');
      })
      .catch((error: ErrorApiResponse<Opco>) => {
        handleError(error, () => {
          let object = formInputs;
          Object.keys(error.response.data.errors).forEach((key) => {
            if (error.response.data.errors[key as keyof Opco]) {
              object = { ...object, [key]: { value: formInputs[key as keyof Opco]?.value, error: true } };
            }
          }, formInputs);
          setFormInputs(object);
        });
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Met à jour, par le réseau, une ressource à partir du formulaire via un état local.
   * Ne doit être utilisé qu'en mode édition.
   *
   * @param ressourceId - ID de la ressource dont on veut modifier les informations.
   */
  const handleUpdate = (ressourceId: Ressource['id']) => {
    setApiIsLoading(true);

    let ressource: Partial<Opco> = {};
    Object.keys(formInputs).forEach((key) => {
      if (formInputs[key as keyof Opco]?.value !== null) {
        ressource = { ...ressource, [key]: formInputs[key as keyof Opco]?.value };
      }
    });

    client
      .patch<GoodApiResponse<Opco>>(`/admin/opcos/${ressourceId}`, ressource)
      .then(() => {
        toast.success(`Modification éffectuée`);
        navigate('/admin/opcos');
      })
      .catch((error: ErrorApiResponse<Opco>) => {
        handleError(error, () => {
          let object = formInputs;
          Object.keys(error.response.data.errors).forEach((key) => {
            if (error.response.data.errors[key as keyof Opco]) {
              object = { ...object, [key]: { value: formInputs[key as keyof Opco]?.value, error: true } };
            }
          }, formInputs);
          setFormInputs(object);
        });
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Cette méthode permet de gérer l'envoi du formulaire en fonction du mode dans lequel on se trouve.
   * L'envoi ne fait rien si on est en mode voir.
   *
   * @param event - Évenement de l'envoie.
   */
  const handleSubmit = (event: { preventDefault: () => void }): void => {
    event.preventDefault();

    if (apiIsLoading === true) {
      return;
    }

    if (modeFormulaire === ModeFormulaire.creer) {
      handleStore();
    }

    if (modeFormulaire === ModeFormulaire.editer) {
      if (params.id !== undefined) {
        handleUpdate(parseInt(params.id as string));
      }
    }
  };

  return (
    <Fragment>
      <Helmet>
        <title>Opco - {Config.app_label}</title>
      </Helmet>
      <HeaderForm text={`Opco ${modeFormulaire !== ModeFormulaire.creer ? nom : ''}`} returnUrl='/admin/opcos' />
      <form className='relative' onSubmit={handleSubmit}>
        <div className='bg-white p-5'>
          <div className='container mx-auto bg-white rounded'>
            <div className='mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 pt-7'>
              <div>
                <ReadOnlyText label='Nom' value={formInputs.nom.value as string} error={formInputs.nom.error} />
              </div>
              <div>
                <ReadOnlyNumber label='YParéo CodeOpco' value={formInputs.yp_code.value} error={formInputs.yp_code.error} />
              </div>
            </div>
          </div>
        </div>
        {apiIsLoading ? <LoadingAbsolute /> : ''}
      </form>
    </Fragment>
  );
};

import { Fragment, FunctionComponent, useState } from 'react';
import { Input } from '@material-tailwind/react';
import { Link, Navigate } from 'react-router-dom';
import toast, { Renderable, Toast, ValueFunction } from 'react-hot-toast';
import { Helmet } from 'react-helmet';
import { useApi } from '../../providers/ApiProvider';
import { Config } from '../../config/config';
import { LoadingAbsolute } from '../../components/LoadingAbsolute';
import { PasswordStrength } from '../../components/PasswordStrength';
import { PasswordHints } from '../../components/PasswordHints';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/20/solid';

/**
 * Ce composant permet d'afficher la page pour changer le mot de passe de l'utilisateur.
 */
export const MonMotdepasseScreen: FunctionComponent = () => {
  /**
   * État local de chargement de l'API.
   */
  const [apiIsLoading, setApiIsLoading] = useState(false);

  /**
   * État local pour gérer le champ `Ancien mot de passe` du formulaire de connexion
   */
  const [oldPassword, setOldPassword] = useState({ value: '', error: false });

  /**
   * État local pour gérer le champ `Nouveau mot de passe` du formulaire de connexion
   */
  const [newPassword, setNewPassword] = useState({ value: '', error: false });

  /**
   * État local pour gérer la visibilité du mot de passe à l'aide de l'icône en forme d'oeil du formulaire de connexion
   */
  const [passwordIsUpdated, setPasswordIsUpdated] = useState(false);

  /**
   * Gère l'appui sur un bouton pour voir le mot de passe.
   */
  const [showedPassword, setShowedPassword] = useState<boolean>(false);

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  // Mise à jour du champ ancien old_password

  /**
   * Mise à jour de l'état local du champ `Ancien mot de passe`
   *
   * @param event - Evenement de changement de la valeur du champ `Ancien mot de passe`
   */
  const handleOldPasswordChange = (event: { target: { value: string } }) => {
    setOldPassword({ value: event.target.value, error: false });
  };

  // Mise à jour du champ new_password

  /**
   * Mise à jour de l'état local du champ `Nouveau mot de passe`
   *
   * @param event - Evenement de changement de la valeur du champ `Nouveau mot de passe`
   */
  const handleNewPasswordChange = (event: { target: { value: string } }) => {
    setNewPassword({ value: event.target.value, error: false });
  };

  /**
   *  Cette méthode effectue l'appel API pour réinitialiser le mot de passe.
   *
   * @param event - Évènement d'envoi du formulaire.
   */
  const handleResetPassword = (event: { preventDefault: () => void }) => {
    event.preventDefault();

    if (apiIsLoading === true) {
      return;
    }

    setApiIsLoading(true);

    // Appel effectif
    client
      .patch('/motdepasse', {
        old_password: oldPassword.value,
        new_password: newPassword.value,
      })
      .then(() => {
        setApiIsLoading(false);
        setPasswordIsUpdated(true);
        toast.success('Mot de passe modifié avec succès');
      })
      .catch(
        (error: {
          response: {
            status: number;
            data: {
              message: Renderable | ValueFunction<Renderable, Toast>;
              errors: { old_password: string; new_password: string };
            };
          };
          message: Renderable | ValueFunction<Renderable, Toast>;
        }) => {
          setApiIsLoading(false);
          if (error.response && [400, 401, 422].includes(error.response.status) && error.response.data.message) {
            if (error.response.data.errors.old_password) {
              setOldPassword({ value: oldPassword.value, error: true });
            }
            if (error.response.data.errors.new_password) {
              setNewPassword({ value: newPassword.value, error: true });
            }
            toast.error(error.response.data.message);
          } else {
            toast.error(error.message);
          }
        },
      );
  };

  // Vue

  if (passwordIsUpdated === false) {
    return (
      <Fragment>
        <Helmet>
          <title>Mon mot de passe - {Config.app_label}</title>
        </Helmet>
        <div className='flex justify-center'>
          <form className='px-8 py-4 bg-white w-80 md:w-96 rounded-md shadow-md relative' onSubmit={handleResetPassword}>
            <h3 className='text-3xl text-center mb-1 space-x-3 poppins-bold text-secondary-2'>Modifier mon mot de passe</h3>

            <div className='mt-6'>
              <Input
                label='Ancien mot de passe'
                type={showedPassword ? 'text' : 'password'}
                onChange={handleOldPasswordChange}
                error={oldPassword.error}
                icon={
                  <div onClick={() => setShowedPassword(!showedPassword)}>
                    {showedPassword ? <EyeSlashIcon className='w-5 h-5' /> : <EyeIcon className='w-5 h-5' />}
                  </div>
                }
              />
            </div>
            <div className='mt-6'>
              <Input
                label='Nouveau mot de passe'
                type={showedPassword ? 'text' : 'password'}
                onChange={handleNewPasswordChange}
                error={newPassword.error}
                icon={
                  <div onClick={() => setShowedPassword(!showedPassword)}>
                    {showedPassword ? <EyeSlashIcon className='w-5 h-5' /> : <EyeIcon className='w-5 h-5' />}
                  </div>
                }
              />
            </div>
            <div className='mt-3'>
              <PasswordStrength password={newPassword.value} />
            </div>
            <div className='mt-3'>
              <PasswordHints password={newPassword.value} />
            </div>
            <div className='mt-6'>
              <button type='submit' className='poppins-semibold w-full px-2 py-1 rounded-md bg-primary text-white  disabled:bg-primary/50 uppercase'>
                Modifier
              </button>
            </div>
            <div className='grid grid-flow-col mt-3'>
              <div>
                <Link to='/mon-espace'>&lt; Retour</Link>
              </div>
            </div>
            {apiIsLoading ? <LoadingAbsolute /> : ''}
          </form>
        </div>
      </Fragment>
    );
  } else {
    return <Navigate to='/mon-espace' />;
  }
};

import { SearchBar } from '../components/SearchBar';
import { SearchResultCard } from '../components/SearchResultCard';
import { MainMenu } from '../components/MainMenu';
import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useApi } from '../providers/ApiProvider';
import toast from 'react-hot-toast';
import { Formation } from '../types/visitorRessources';
import { GoodApiResponse, ErrorApiResponse } from '../types/api';
import { Config } from '../config/config';
import { Helmet } from 'react-helmet';
import { TypeProfilEnum } from '../types/auth';
import { useUser } from '../providers/UserProvider';
import { handleError } from '../utils/ErrorHandler';
import { CtaHomeFSM } from '../components/CtaHomeFSM';
import { FilDariane } from '../components/FilDariane';
import { Container } from '../components/Container';
import { ArrowRightIcon } from '@heroicons/react/24/outline';
import { ModaleEspaceClient } from '../components/Modales/Front/ModaleEspaceclient';

export const ResultatsRechercheScreen: FunctionComponent = () => {
  /**
   * On a besoin des informations de l'utilisateur et du profil courant de celui-ci.
   */
  const { user, profil } = useUser();

  /**
   * Les paramètre des recherches URL.
   *
   * @constant
   */
  const [searchParams] = useSearchParams();

  /**
   * Formations recherchées.
   */
  const [formations, setFormations] = useState<Formation[]>([]);

  /**
   * État local qui gère l'ouverture de la modale lorsque l'on clique sur favoris.
   */
  const [modalFavorisEstVisible, setModalFavorisEstVisible] = useState<boolean>(false);

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  /**
   * Permet de recharger les données au chargement de page ou au changement de paramètres.
   */
  useEffect(() => {
    handleIndex();
  }, [searchParams]);

  /**
   * Cette méthode va chercher les informations dans l'API pour afficher les filières.
   */
  const handleIndex = () => {
    client
      .get<GoodApiResponse<Formation[]>>(
        `/formations/recherche?texte=${searchParams.get('recherche') as string}${searchParams.get('sites') ? `&sites=${searchParams.get('sites')}` : ''}`,
      )
      .then((response) => {
        setFormations(response.data.data);
      })
      .catch((error: ErrorApiResponse) => {
        if (error.response && parseInt(error.response.status) === 404) {
          setFormations([]);
        } else {
          handleError(error);
        }
      });
  };

  /**
   * Cette méthode permet d'ajouter une formation en favoris.
   *
   * @param formation - Formation à mettre en favoris.
   */
  const handleFavorite = async (formation: Formation) => {
    if (user === null || !user.roles.includes('standard')) {
      handleModalFavoris();
      return;
    }

    const url = `/${
      profil !== null && (profil.type_profil === TypeProfilEnum.dirigeant || profil.type_profil === TypeProfilEnum.delegue) ? 'entreprise' : 'salarie'
    }/favoris/${formation.slug}`;

    try {
      if (formation.est_favori) {
        await client.delete(url);
        setFormations([
          ...formations.map((format) => {
            if (format.slug === formation.slug) {
              return { ...format, est_favori: false }; // mettre à jour l'âge de l'objet avec id = 2
            }
            return format;
          }),
        ]);
        toast.success(`Formation retirée des favoris`);
      } else {
        await client.post(url);
        setFormations([
          ...formations.map((format) => {
            if (format.slug === formation.slug) {
              return { ...format, est_favori: true }; // mettre à jour l'âge de l'objet avec id = 2
            }
            return format;
          }),
        ]);
        toast.success(`Formation ajoutée aux favoris`);
      }
    } catch (error: any) {
      handleError(error);
    }
  };

  const handleModalFavoris = () => {
    setModalFavorisEstVisible(true);
  };

  return (
    <Fragment>
      <Helmet>
        <title>Résultats de recherche - {Config.app_label}</title>
      </Helmet>

      <div
        className='px-0 lg:px-11 pt-3 isolate bg-secondary-1 bg-fixed bg-opacity-70 bg-blend-multiply bg-cover bg-center bg-no-repeat'
        style={{
          backgroundImage: `url("/assets/new-header-accueil-compagnons-du-devoir-formations-courtes.webp")`,
        }}>
        <MainMenu />

        <div className='relative px-4 lg:px-8'>
          <div className='mx-auto max-w-7xl pt-24 pb-20 px-4 lg:px-8'>
            <div>
              <h2 className='text-white text-3xl w-full uppercase mt-11 text-center md:text-left poppins-semibold'>Trouvez votre formation courte</h2>
            </div>
            <FilDariane className='bg-transparent w-full text-white pl-0 !mb-2 mt-2' items={[]} />
          </div>
        </div>
      </div>

      <Container>
        <div className='bg-secondary-2 px-7 py-6 rounded-lg -mt-14 relative mx-5 md:mx-0'>
          <SearchBar
            className='mx-auto'
            initialSearchState={{ recherche: searchParams.get('recherche') as string, selectedSites: searchParams.get('sites')?.split('|') as string[] }}
            fromBackOffice={false}
          />
        </div>
      </Container>
      {formations && formations.length > 0 && (
        <Container>
          <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 mt-14'>
            <SearchResultCard formations={formations} handleFavorite={handleFavorite} />
          </div>
        </Container>
      )}
      {(!formations || formations.length < 1) && (
        <Container>
          <div className='rounded-md bg-white p-8 flex flex-col lg:flex-row gap-4 lg:gap-16 items-center justify-center shadow-md my-14'>
            <div className='grow'>
              <h3 className='text-xl poppins-bold tracking-tight text-secondary-2'>Pas de résultats</h3>
              <div className='mt-2 text-gray-600 text'>
                <p>
                  Nous sommes désolés, nous ne trouvons pas exactement de formations correspondant à vos critères de recherche. Nous vous invitons à modifier
                  vos critères. Vous pouvez également nous contacter afin que nous analysions ensemble vos attentes pour construire le dispositif de formation
                  qui répond à vos objectifs.
                </p>
              </div>
            </div>
            <div className='flex-none'>
              <Link
                to='https://www.compagnons-du-devoir.com/nous-contacter'
                className='inline-flex items-center justify-center rounded-full bg-primary px-3 text-sm poppins-semibold text-white'>
                Contacter un(e) conseiller(e)
                <ArrowRightIcon className='pl-3 w-8 h-8' />
              </Link>
            </div>
          </div>
        </Container>
      )}
      {formations && formations.length > 0 && <CtaHomeFSM />}
      <ModaleEspaceClient visible={modalFavorisEstVisible} setVisible={setModalFavorisEstVisible}></ModaleEspaceClient>
    </Fragment>
  );
};

import { Dialog, DialogHeader, DialogBody, DialogFooter, Button } from '@material-tailwind/react';
import { FunctionComponent, useState } from 'react';
import toast from 'react-hot-toast';
import { useApi } from '../../../providers/ApiProvider';
import { GoodApiResponse, ErrorApiResponse } from '../../../types/api';
import { Invitation } from '../../../types/EntreprisesRessources';
import { InputText } from '../../Inputs/InputText';
import { LoadingAbsolute } from '../../LoadingAbsolute';
import { Entreprise } from '../../../types/schematics';
import { handleError } from '../../../utils/ErrorHandler';
import { AlertInfo } from '../../Alertes';

/**
 * Ce composant permet d'afficher une Modale pour qu'un admin puisse modifier le code YPAREO d'une entreprise'.
 *
 * @param visible - Précise si la modale et ouverte ou non. Provient du parent.
 * @param setVisible - Accesseur qui modifie la visibilité de la modale. Provient du parent.
 * @param entrepriseId - ID de l'entreprise. Provient du parent.
 * @param ypareoCode - Code YPAREO. Provient du parent.
 * @param handleShow - Callback qui permet de recharger les données du composant parent. Provient du parent.
 */
export const ModalModificationYpareoCodeEntreprise: FunctionComponent<{
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  entrepriseId: Entreprise['id'];
  ypareoCode: string;
  handleShow: (entrepriseId: Entreprise['id']) => void;
}> = ({ visible, setVisible, entrepriseId, ypareoCode, handleShow }) => {
  /**
   * On stocke si on est en train d'utiliser l'API ou non.
   */
  const [apiIsLoading, setApiIsLoading] = useState(false);

  /**
   * Nouveau code YPAREO
   */
  const [newYpareoCode, setNewYpareoCode] = useState<string>(ypareoCode);

  /**
   * On stocke l'eventuelle erreur.
   */
  const [error, setError] = useState<string | null>(null);

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  const handleUpdateYpareoCode = () => {
    setApiIsLoading(true);

    client
      .patch<GoodApiResponse>(`/admin/entreprises/${entrepriseId}/ypareo-relie-entreprise-manuel`, { yp_code: newYpareoCode })
      .then(() => {
        setError(null);
        setVisible(false);
        toast.success(`Code YParéo modifié`);
        handleShow(entrepriseId);
      })
      .catch((error: ErrorApiResponse<Invitation>) => {
        handleError(
          error,
          () => {
            setError(error.response.data.message);
          },
          () => {
            setError(error.message);
          },
        );
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  return (
    <Dialog open={visible} size='xs' handler={setVisible}>
      <DialogHeader>Modifier le code YParéo</DialogHeader>
      <DialogBody divider>
        <AlertInfo text="Veuillez bien vérifier le code que vous saisissez, cette action peut avoir des conséquences sur l'intégrité des données." />
        <p className='text-sm mb-2'>Veuillez saisir le nouveau code YParéo.</p>
        <InputText
          value={newYpareoCode}
          onChange={(e) => {
            setNewYpareoCode(e.target.value);
          }}
        />
        {error && <p className='text-sm text-red-500'>{error}</p>}
      </DialogBody>
      <DialogFooter>
        <Button
          variant='text'
          color='gray'
          onClick={() => {
            setError(null);
            setVisible(false);
          }}
          className='mr-1 !rounded-full'
          disabled={apiIsLoading}>
          Annuler
        </Button>
        <Button
          variant='filled'
          color='orange'
          onClick={() => {
            handleUpdateYpareoCode();
          }}
          disabled={apiIsLoading}>
          Confirmer
        </Button>
      </DialogFooter>
      {apiIsLoading ? <LoadingAbsolute /> : ''}
    </Dialog>
  );
};

import { FunctionComponent } from 'react';
import { DatesDisplay } from './DatesDisplay';
import { CalendarDaysIcon, MapPinIcon } from '@heroicons/react/24/outline';

export const DatesSiteDisplay: FunctionComponent<{
  groupe?: any | null;
  className?: string | undefined;
}> = ({ groupe = null, className = '' }) => {
  return (
    <div className={className}>
      <div className='flex flex-row items-center text-xs montserrat text-gray-800'>
        <CalendarDaysIcon className='w-5 h-5 mr-2 flex-none' />
        <DatesDisplay date_start={groupe && groupe.date_debut} date_end={groupe && groupe.date_fin} />
      </div>
      <div className='flex flex-row items-center text-xs montserrat text-gray-800'>
        <MapPinIcon className='w-5 h-5 mr-2 flex-none' />
        <span>{groupe && groupe.site && groupe.site.nom ? <div>{groupe.site.nom}</div> : 'Lieu non précisé'}</span>
      </div>
    </div>
  );
};

import { Dialog, DialogHeader, DialogBody, DialogFooter, Button } from '@material-tailwind/react';
import { FunctionComponent, useEffect, useState } from 'react';
import { Facture } from '../../../types/AdminRessources';
import { handleError } from '../../../utils/ErrorHandler';
import { AlertError } from '../../Alertes';
import { InputDate } from '../../Inputs/InputDate';
import { LoadingAbsolute } from '../../LoadingAbsolute';

/**
 * Ce composant permet d'afficher une Modale pour que les admins puissent renseigner une facture comme payée.
 *
 * @param visible - Précise si la modale et ouverte ou non. Provient du parent.
 * @param setVisible - Accesseur qui modifie la visibilité de la modale. Provient du parent.
 * @param apiIsLoading - Précise si on affiche le loader ou non. Provient du parent.
 * @param handlePaiement - Callback qui réalise l'appel pour recharger les données de la facture. Provient du parent.
 */
export const ModalMarquerFactureCommePayee: FunctionComponent<{
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  apiIsLoading: boolean;
  handlePaiement: (datePaiement: Facture['date_paiement']) => void;
}> = ({ visible, setVisible, apiIsLoading, handlePaiement }) => {
  /**
   * Date du jour.
   * @constant
   */
  const currentDate = new Date();

  /**
   * Date  de paiement.
   */
  const [datePaiement, setDatePaiement] = useState<Facture['date_paiement']>(
    `${currentDate.getFullYear()}-${currentDate.getMonth() < 9 ? `0${currentDate.getMonth() + 1}` : currentDate.getMonth() + 1}-${
      currentDate.getDate() < 10 ? `0${currentDate.getDate()}` : currentDate.getDate()
    }`,
  );

  /**
   * On stocke l'eventuelle erreur.
   */
  const [error, setError] = useState<string | null>(null);

  /**
   * Permet de réinitialiser les champs de la modale à chaque fois qu'on l'ouvre où qu'on la ferme.
   */
  useEffect(() => {
    setDatePaiement(
      `${currentDate.getFullYear()}-${currentDate.getMonth() < 9 ? `0${currentDate.getMonth() + 1}` : currentDate.getMonth() + 1}-${
        currentDate.getDate() < 10 ? `0${currentDate.getDate()}` : currentDate.getDate()
      }`,
    );
    setError(null);
  }, [visible]);

  return (
    <Dialog open={visible} size='lg' handler={setVisible}>
      <DialogHeader>Marquer la facture comme réglée</DialogHeader>
      <DialogBody divider>
        {error && <AlertError text={error} />}
        <div className='pt-4'>
          <p>Date de paiement</p>
        </div>
        <InputDate
          value={datePaiement as string}
          onChange={(e) => {
            setDatePaiement(e.target.value);
          }}
        />

        {apiIsLoading ? <LoadingAbsolute /> : ''}
      </DialogBody>
      <DialogFooter>
        <Button
          variant='text'
          color='gray'
          onClick={() => {
            setDatePaiement('');
            setError(null);
            setVisible(false);
          }}
          className='mr-1 !rounded-full'>
          Annuler
        </Button>
        <Button
          variant='filled'
          color='orange'
          onClick={() => {
            try {
              handlePaiement(datePaiement);
            } catch (error: any) {
              handleError(
                error,
                () => {
                  setError(error.response.data.message);
                },
                () => {
                  setError(error.message);
                },
              );
            }
          }}>
          Confirmer
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

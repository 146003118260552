import { Fragment, FunctionComponent } from 'react';

/**
 * Composant ReadOnlyTrilean.
 *
 * @param label - Libellé du champ de saisie en lecture seule.
 * @param value - Valeur du champ de saisie en lecture seule.
 * @param error - Indique si le champ de saisie en lecture seule a une erreur.
 * @return Composant ReadOnlyTrilean.
 */
export const ReadOnlyTrilean: FunctionComponent<{
  error?: boolean;
  label?: string;
  value?: number | null;
}> = ({ label, value, error = false }) => {
  return (
    <Fragment>
      {label && <label className={`text-xs text-gray-600 ${error ? 'text-red-500' : ''}`}>{label}</label>}
      <p className={`text-secondary-1 text-sm font-normal items-center pt-1 ${error ? 'text-red-500' : ''}`}>
        {value ? (value === 1 ? 'Oui' : 'Non') : 'Non renseigné'}
      </p>
    </Fragment>
  );
};

import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { CheckIcon, EllipsisVerticalIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Helmet } from 'react-helmet';
import toast from 'react-hot-toast';
import { LoadingAbsolute } from '../../components/LoadingAbsolute';
import { useApi } from '../../providers/ApiProvider';
import { GoodApiResponse, ErrorApiResponse } from '../../types/api';
import { Config } from '../../config/config';
import { Souhait } from '../../types/SalariesRessources';
import { Menu, MenuHandler, Button, MenuList, MenuItem, Tooltip } from '@material-tailwind/react';
import { ModalAcceptationSouhait } from '../../components/Modales/Salarie/ModalAcceptationSouhait';
import { ModalRefusSouhait } from '../../components/Modales/Salarie/ModalRefusSouhait';
import { handleError } from '../../utils/ErrorHandler';
import { ModalAnnuleSouhaitSalarie } from '../../components/Modales/Salarie/ModalAnnuleSouhaitSalarie';
import { FormationSessionDisplay } from '../../components/FormationSessionDisplay';

/**
 * Ce composant affiche la liste de souhaits pour le salarié.
 */
export const MaListeDeSouhaitsScreen: FunctionComponent = () => {
  /**
   * On stocke si on est en train d'utiliser l'API ou non.
   */
  const [apiIsLoading, setApiIsLoading] = useState(false);

  /**
   * On stocke les Souhait possédés par le profil.
   */
  const [souhaits, setSouhaits] = useState<Souhait[] | null>(null);

  /**
   * État local pour gérer lle souhait selectionné en vue d'ouvrir une modale dessus.
   */
  const [souhaitSelectionne, setSouhaitSelectionne] = useState<Souhait | null>(null);

  /**
   * Permet d'ouvrir une modale lorsque l'on souhaite accepter un souhait en temps que salarié.
   */
  const [modalAcceptationSouhaitEstVisible, setModalAcceptationSouhaitEstVisible] = useState<boolean>(false);

  /**
   * Permet d'ouvrir une modale lorsque l'on souhaite refuser un souhait en temps que salarié.
   */
  const [modalRefusSouhaitEstVisible, setModalRefusSouhaitEstVisible] = useState<boolean>(false);

  /**
   * Permet de filtrer par provenance.
   */
  const [filtreProposePar, setFiltreProposePar] = useState<'tous' | 'salarie' | 'entreprise'>(
    (localStorage.getItem(`MaListeDeSouhaitsScreen-FiltreProposePar`) as 'tous' | 'salarie' | 'entreprise') || 'tous',
  );

  /**
   * Permet de filtrer par provenance.
   */
  const [filtreDecision, setFiltreDecision] = useState<'tous' | 'enattente' | 'accepte' | 'refuse' | 'demandeEnvoyee'>(
    (localStorage.getItem(`MaListeDeSouhaitsScreen-FiltreDecision`) as 'tous' | 'enattente' | 'accepte' | 'refuse' | 'demandeEnvoyee') || 'tous',
  );

  /**
   * Permet d'ouvrir une modale lorsque l'on souhaite ajouter un document à la demande.
   */
  const [modalAnnuleSouhaitEstVisible, setModalAnnuleSouhaitEstVisible] = useState<boolean>(false);

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  /**
   * Cet effet permet la récupération des donnés via l'API au chargement du composant.
   */
  useEffect(() => {
    handleIndex();
  }, [client]);

  /**
   * Cette méthode va chercher les informations dans l'API pour afficher les souhaits des salariés et les stocker dans un état local.
   */
  const handleIndex = () => {
    setApiIsLoading(true);

    client
      .get<GoodApiResponse<Souhait[]>>(`/salarie/souhaits`)
      .then((response) => {
        setSouhaits(response.data.data);
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Ouvre une modale pour informer le salarié qu'il s'appreète à accepter un souhait.
   *
   * @param souhait - souhait selectionné.
   */
  const handleModaleAcceptationSouhait = (souhait: Souhait) => {
    setSouhaitSelectionne(souhait);
    setModalAcceptationSouhaitEstVisible(true);
  };

  /**
   * Cette méthode est passé en paramètre de la modale d'acceptation de souhait.
   * Elle permet d'accepter un souhait.
   */
  const handleAccepte = () => {
    setApiIsLoading(true);

    client
      .patch<GoodApiResponse>(`/salarie/souhaits/${(souhaitSelectionne as Souhait).token}/accepte`)
      .then(() => {
        setSouhaits([
          ...(souhaits as Souhait[]).map((souhait) => {
            if (souhait.token === (souhaitSelectionne as Souhait).token) {
              return { ...souhait, date_acceptation: new Date().toISOString() }; // mettre à jour l'âge de l'objet avec id = 2
            }
            return souhait;
          }),
        ]);
        toast.success(`Souhait accepté`);
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Ouvre une modale pour informer le salarié qu'il s'appreète à refuser un souhait.
   *
   * @param souhait - souhait selectionné.
   */
  const handleModaleRefusSouhait = (souhait: Souhait) => {
    setSouhaitSelectionne(souhait);
    setModalRefusSouhaitEstVisible(true);
  };

  /**
   * Cette méthode est passé en paramètre de la modale de refus de souhait.
   * Elle permet de refuser un souhait.
   */
  const handleRefuse = () => {
    setApiIsLoading(true);

    client
      .patch<GoodApiResponse>(`/salarie/souhaits/${(souhaitSelectionne as Souhait).token}/refuse`)
      .then(() => {
        setSouhaits([
          ...(souhaits as Souhait[]).map((souhait) => {
            if (souhait.token === (souhaitSelectionne as Souhait).token) {
              return { ...souhait, date_refus: new Date().toISOString() }; // mettre à jour l'âge de l'objet avec id = 2
            }
            return souhait;
          }),
        ]);
        toast.success(`Souhait refusé`);
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Ouvre une modale pour informer le salarié qu'il s'appreète à annuler un souhait.
   *
   * @param souhait - souhait selectionné.
   */
  const handleModaleAnnuleSouhait = (souhait: Souhait) => {
    setSouhaitSelectionne(souhait);
    setModalAnnuleSouhaitEstVisible(true);
  };

  /**
   * Cette méthode est passé en paramètre de la modale d'annulation de souhait.
   * Elle permet d'annuler un souhait.
   */
  const handleAnnule = () => {
    setApiIsLoading(true);

    client
      .delete<GoodApiResponse>(`/salarie/souhaits/${(souhaitSelectionne as Souhait).token}`)
      .then(() => {
        setSouhaits([...(souhaits as Souhait[]).filter((souhait) => souhait.token !== (souhaitSelectionne as Souhait).token)]);
        toast.success(`Souhait annulé`);
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  const afficheSouhaitsFiltres = () =>
    souhaits
      ? souhaits
          .filter((souhait) => {
            switch (filtreProposePar) {
              case 'tous':
                return true;
              case 'salarie':
                return souhait.par_salarie;
              case 'entreprise':
                return souhait.par_entreprise;
              default:
                break;
            }
          })
          .filter((souhait) => {
            switch (filtreDecision) {
              case 'tous':
                return true;
              case 'enattente':
                return souhait.date_acceptation === null && souhait.date_refus === null;
              case 'accepte':
                return souhait.date_acceptation;
              case 'refuse':
                return souhait.date_refus;
              case 'demandeEnvoyee':
                return souhait.demande && souhait.demande.created_at;
              default:
                break;
            }
          })
      : [];

  if (souhaits === null) {
    return <Fragment>Chargement...</Fragment>;
  }

  return (
    <Fragment>
      <Helmet>
        <title>Souhaits de formation - {Config.app_label}</title>
      </Helmet>
      <div className='px-4 py-4 md:py-3 bg-gray-100 rounded-tl-lg rounded-tr-lg flex flex-col sm:flex-row gap-7 items-center'>
        <div className='sm:flex items-center justify-between'>
          <h1 className='text-base sm:text-lg md:text-xl poppins-bold leading-normal text-secondary-1'>Souhaits de formation</h1>
        </div>
        <div className='flex flex-col md:flex-row gap-4 items-center'>
          <div>
            <p className='text-xs text-gray-700 mb-1'>Proposé par :</p>
            <select
              className='min-w-11 bg-white text-gray-600 border-gray-300 rounded-md font-normal text-sm p-[5.5px] border'
              value={filtreProposePar}
              onChange={(e) => {
                setFiltreProposePar(e.target.value as 'tous' | 'salarie' | 'entreprise');
                localStorage.setItem(`MaListeDeSouhaitsScreen-FiltreProposePar`, e.target.value);
              }}>
              <option value='tous'>Tous</option>
              <option value='salarie'>Moi</option>
              <option value='entreprise'>Entreprise</option>
            </select>
          </div>
          <div>
            <p className='text-xs text-gray-700 mb-1'>Décision :</p>
            <select
              className='min-w-11 bg-white text-gray-600 border-gray-300 rounded-md font-normal text-sm p-[5.5px] border'
              value={filtreDecision}
              onChange={(e) => {
                setFiltreDecision(e.target.value as 'tous' | 'enattente' | 'accepte' | 'refuse' | 'demandeEnvoyee');
                localStorage.setItem(`MaListeDeSouhaitsScreen-FiltreDecision`, e.target.value);
              }}>
              <option value='tous'>Tous</option>
              <option value='enattente'>En attente</option>
              <option value='accepte'>Accepté</option>
              <option value='refuse'>Refusé</option>
              <option value='demandeEnvoyee'>Demande envoyée</option>
            </select>
          </div>
        </div>
      </div>
      <div>
        <div className='bg-white rounded-b-lg max-w-auto overflow-x-auto'>
          {souhaits && souhaits.length > 0 ? (
            afficheSouhaitsFiltres().length > 0 ? (
              <table className='table-auto min-w-[1050px] w-full bg-white rounded-b-lg'>
                <thead className='text-sm text-gray-500 text-left border-b'>
                  <tr>
                    <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Formation et session</th>
                    <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Proposé par</th>
                    <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Statut</th>
                    <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'></th>
                  </tr>
                </thead>
                <tbody>
                  {afficheSouhaitsFiltres().map((souhait, index) => (
                    <tr key={index} className=' border-gray-100 border-b hover:bg-gray-100'>
                      <td className='px-4 py-3'>
                        <FormationSessionDisplay formation={souhait.formation} groupe={souhait.groupe} />
                      </td>
                      <td className='px-4 py-3 text-gray-800 text-sm'>{souhait.par_entreprise ? 'Entreprise' : 'Moi'}</td>
                      <td className='px-4 py-3 text-gray-800 text-sm'>
                        <div>{`Émis le ${new Intl.DateTimeFormat('default', {
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                        }).format(new Date(souhait.created_at as string))}`}</div>
                        {souhait.date_acceptation === null && souhait.date_refus === null
                          ? 'En attente'
                          : souhait.date_acceptation
                            ? `Accepté le : ${new Intl.DateTimeFormat('default', {
                                year: 'numeric',
                                month: 'numeric',
                                day: 'numeric',
                                hour12: false,
                              }).format(new Date(souhait.date_acceptation as string))}`
                            : souhait.date_refus
                              ? `Refusé le : ${new Intl.DateTimeFormat('default', {
                                  year: 'numeric',
                                  month: 'numeric',
                                  day: 'numeric',
                                  hour12: false,
                                }).format(new Date(souhait.date_refus as string))}`
                              : ''}

                        {souhait.demande?.created_at && (
                          <div>{`Demande envoyée le ${new Intl.DateTimeFormat('default', {
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                            hour12: false,
                          }).format(new Date(souhait.demande?.created_at as string))}`}</div>
                        )}
                      </td>
                      <td className='px-4 py-3 text-gray-800 text-sm'>
                        <div className='flex flex-row items-center gap-2'>
                          {souhait.par_entreprise && souhait.date_acceptation === null && souhait.date_refus === null && (
                            <Tooltip content='Refuser'>
                              <Button
                                variant='filled'
                                color='gray'
                                className='w-8 h-8 p-1.5 rounded-full block mx-auto text-center mr-0 bg-secondary-1 hover:shadow-lg hover:bg-secondary-2 active:bg-secondary-2'
                                onClick={() => handleModaleRefusSouhait(souhait)}>
                                <XMarkIcon className='h-5 w-5' strokeWidth={2} />
                              </Button>
                            </Tooltip>
                          )}
                          {souhait.par_entreprise && souhait.date_acceptation === null && souhait.date_refus === null && (
                            <Tooltip content='Accepter'>
                              <Button
                                variant='filled'
                                color='orange'
                                className='w-8 h-8 p-1.5 rounded-full block mx-auto text-center mr-0 hover:bg-primary active:bg-orange-600'
                                onClick={() => handleModaleAcceptationSouhait(souhait)}>
                                <CheckIcon className='h-5 w-5' strokeWidth={2} />
                              </Button>
                            </Tooltip>
                          )}
                          <Menu placement='right-start'>
                            <MenuHandler>
                              <Button
                                variant='text'
                                className='w-9 h-9 p-1.5 rounded-full text-gray-500 block mx-auto text-center mr-0 hover:bg-white/100 active:bg-transparent'>
                                <EllipsisVerticalIcon className='h-6 w-6' />
                              </Button>
                            </MenuHandler>
                            <MenuList>
                              <MenuItem onClick={() => window.open(`/formations/${souhait.formation.slug}`, '_blank')}>Voir la page de la formation</MenuItem>
                              {souhait.par_salarie && souhait.date_acceptation === null && souhait.date_refus === null && (
                                <MenuItem onClick={() => handleModaleAnnuleSouhait(souhait)}>Annuler</MenuItem>
                              )}
                            </MenuList>
                          </Menu>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className='text-center px-4 py-7'>Aucun souhait ne répond au critères choisis.</div>
            )
          ) : (
            <div className='text-center px-4 py-7'>Vous n'avez pas encore de souhait.</div>
          )}
        </div>
        <ModalAcceptationSouhait visible={modalAcceptationSouhaitEstVisible} setVisible={setModalAcceptationSouhaitEstVisible} handleAccepte={handleAccepte} />

        <ModalRefusSouhait visible={modalRefusSouhaitEstVisible} setVisible={setModalRefusSouhaitEstVisible} handleRefuse={handleRefuse} />

        <ModalAnnuleSouhaitSalarie visible={modalAnnuleSouhaitEstVisible} setVisible={setModalAnnuleSouhaitEstVisible} handleAnnule={handleAnnule} />
        {apiIsLoading ? <LoadingAbsolute /> : ''}
      </div>
    </Fragment>
  );
};

import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { Button } from '@material-tailwind/react';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingAbsolute } from '../../../components/LoadingAbsolute';
import { useApi } from '../../../providers/ApiProvider';
import { HeaderForm } from '../../../components/HeaderForm';
import { GoodApiResponse, ErrorApiResponse } from '../../../types/api';
import { Helmet } from 'react-helmet';
import {
  CheckCircleIcon,
  CheckIcon,
  CurrencyEuroIcon,
  DocumentIcon,
  EnvelopeIcon,
  ExclamationTriangleIcon,
  FolderIcon,
  PlayCircleIcon,
  PlusIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { Convocation, Demande, DemandeDocument, /*DemandeInformation,*/ Devis, Document, Facture } from '../../../types/EntreprisesRessources';
import { ModalEntrepriseDemandeDocument } from '../../../components/Modales/Entreprise/ModalEntrepriseDemandeDocument';
//import { ModalEntrepriseDemandeInformation } from '../../../components/ModalEntrepriseDemandeInformation';
import { Config } from '../../../config/config';
import nl2br from 'react-nl2br';
import { handleError } from '../../../utils/ErrorHandler';
import { dateEstCompriseDansIntervalle, ajouterJour } from '../../../utils/Temps';
import { Tab, TabPanel, Tabs, TabsBody, TabsHeader } from '../../../components/Onglets';
import { ModalAnnulationDemandeEntreprise } from '../../../components/Modales/Entreprise/ModalAnnulationDemandeEntreprise';
import { FormationSessionDisplay } from '../../../components/FormationSessionDisplay';
import { UserWithMailDisplay } from '../../../components/UserWithMailDisplay';
import { DownloadLink } from '../../../components/DownloadLink';

/**
 * Ce composant doit afficher la demande lié à une entreprise.
 */
export const EntrepriseDemandeScreen: FunctionComponent = () => {
  // On initialise les données dont on va avoir besoin sur cette page

  /**
   * On stocke si on est en train d'utiliser l'API ou non.
   */
  const [apiIsLoading, setApiIsLoading] = useState(false);

  /**
   * Permet de stocker les informations relatives à la demande.
   */
  const [demande, setDemande] = useState<Demande | null>(null);

  /**
   * On stocke l'état des document liés à la demande.
   */
  const [documents, setDocuments] = useState<Document[] | null>(null);

  /**
   * Permet d'ouvrir une modale lorsque l'on souhaite ajouter un document à la demande.
   */
  const [modalDocumentsEstVisible, setModalDocumentsEstVisible] = useState<boolean>(false);

  /**
   * Permet d'ouvrir une modale lorsque l'on souhaite ajouter une information à la demande.
   */
  //const [modalInformationsEstVisible, setModalInformationsEstVisible] = useState<boolean>(false);

  /**
   * État local qui garde la question liée à la demande de document, sélectionnée.
   */
  const [selectedQuestion, setSelectedQuestion] = useState<DemandeDocument['question'] | null>(null);

  /**
   * État local qui gère le jeton de la demande de document.
   */
  const [selectedToken, setSelectedToken] = useState<DemandeDocument['token'] | null>(null);

  /**
   * Permet de selectionner une convocation.
   */
  const [convocationTokenSelectionne, setConvocationTokenSelectionne] = useState<Convocation['token'] | null>(null);

  /**
   * Permet de selectionner un devis.
   */
  const [devisTokenSelectionne, setDevisTokenSelectionne] = useState<Devis['token'] | null>(null);

  /**
   * Permet de selectionner une facture.
   */
  const [factureTokenSelectionne, setFactureTokenSelectionne] = useState<Facture['token'] | null>(null);

  /**
   * Permet d'ouvrir une modale lorsque l'on souhaite ajouter un document à la demande.
   */
  const [modalAnnulationEstVisible, setModalAnnulationEstVisible] = useState<boolean>(false);

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  /**
   * Permet au composant de changer de page.
   */
  const navigate = useNavigate();

  /**
   * On a besoin des paramètres pour avoir le token de la demande.
   */
  const params = useParams();

  /**
   * Date du jour.
   * @constant
   */
  const currentDate = new Date();

  /**
   * Permet de recharger les données au chargement de la page.
   */
  useEffect(() => {
    handleShow();
  }, [client]);

  /**
   * Permet de recharger les données au changement de demande.
   */
  useEffect(() => {
    handleIndex();
  }, [demande]);

  /**
   * Retrouve par le réseau la demande et la met dans un état local.
   */
  const handleShow = () => {
    if (apiIsLoading === true) {
      return;
    }

    setApiIsLoading(true);
    client
      .get<GoodApiResponse<Demande>>(`/entreprise/demandes/${params.token}`)
      .then((response) => {
        setDemande(response.data.data as unknown as Demande);
      })
      .catch((error: ErrorApiResponse<Demande>) => {
        handleError(error, () => {
          navigate('/entreprise/demandes');
        });
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Retrouve Les ressources nécessaires pour afficher les dropdown et les stockent dans des .
   *
   * @param indexParams - Paramètres de la recherche.
   */
  const handleIndex = () => {
    setApiIsLoading(true);

    if (demande) {
      const promises = [
        client.get<GoodApiResponse<Document[]>, { params: { demande_id: Demande['id'] } }>('/entreprise/salaries/documents', {
          params: {
            demande_id: demande?.id,
          },
        }),
      ];

      Promise.all(promises)
        .then((response) => {
          setDocuments(response[0].data.data as unknown as Document[]);
        })
        .catch((error: ErrorApiResponse) => {
          handleError(error);
        })
        .finally(() => {
          setApiIsLoading(false);
        });
    }
  };

  /**
   * Ouvre la modale pour ajouter un document en réponse à la question posée par l'admin.
   *
   * @param question - Question posée par l'admin.
   * @param token - Jeton de la demande de document.
   */
  const handleModalDocuments = (question: DemandeDocument['question'], token: DemandeDocument['token']) => {
    setSelectedQuestion(question);
    setSelectedToken(token);
    setModalDocumentsEstVisible(true);
  };

  /**
   * Ouvre la modale pour ajouter une réponse à une question posée par l'admin
   *
   * @param question - Question posée par l'admin.
   * @param token - Jeton de la demande de document.
   */
  /*const handleModalInformations = (question: DemandeDocument['question'], token: DemandeDocument['token']) => {
    setSelectedQuestion(question);
    setSelectedToken(token);
    setModalInformationsEstVisible(true);
  };*/

  /**
   * Cette méthode sert à afficher les un résumé de l'avancement pour chaque devis.
   *
   * @param devi - Devis dont il est question dans leboucle.
   * @returns Le résumé des étapes de l'avancement du devis.
   */
  const afficheAvancement = (devi: {
    numero: Devis['numero'];
    titre: Devis['titre'];
    fichier: Devis['fichier'];
    description: Devis['description'];
    token: Devis['token'];
    date_limite_acceptation: Devis['date_limite_acceptation'];
    date_acceptation: Devis['date_acceptation'];
    date_refus: Devis['date_refus'];
    date_limite_retractation: Devis['date_limite_retractation'];
    date_retractation: Devis['date_retractation'];
    date_limite_traitement: Devis['date_limite_traitement'];
    date_traitement: Devis['date_traitement'];
    created_at: Devis['created_at'];
    updated_at: string | null;
  }) => {
    if (devi.date_acceptation === null && devi.date_refus === null) {
      if (currentDate < new Date(devi.date_limite_acceptation)) {
        // Devis en attente
        return (
          <div
            className={
              devi.date_limite_acceptation &&
              dateEstCompriseDansIntervalle(new Date(devi.created_at as string), ajouterJour(new Date(devi.date_limite_acceptation), -1), currentDate)
                ? currentDate.getDate() === ajouterJour(new Date(devi.date_limite_acceptation), -1).getDate()
                  ? 'text-orange-200'
                  : ''
                : 'text-red-700'
            }>
            En attente de décision
            {devi.date_limite_acceptation
              ? `, valable jusqu'au : ${new Intl.DateTimeFormat('default', {
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                  hour12: false,
                }).format(new Date(devi.date_limite_acceptation))}.`
              : '.'}
          </div>
        );
      } else {
        // Délais d'attente dépassé
        return (
          <div className='text-red-700'>
            {devi.date_limite_acceptation
              ? `Valable jusqu'au : ${new Intl.DateTimeFormat('default', {
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                  hour12: false,
                }).format(new Date(devi.date_limite_acceptation))}.`
              : ''}
          </div>
        );
      }
    }

    if (devi.date_acceptation) {
      /**
       * Une fois le devis accepté on sait que l'on va rendre ce bloc pour tous les cas où le devis est accepté.
       * @constant
       */
      const devisAccepte = (
        <div>{`Accepté le : ${new Intl.DateTimeFormat('default', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour12: false,
        }).format(new Date(devi.date_acceptation as string))}.`}</div>
      );

      if (devi.date_traitement === null && devi.date_limite_traitement) {
        if (currentDate < new Date(devi.date_limite_traitement)) {
          // En attente de traitement
          return (
            <Fragment>
              {devisAccepte}
              <div
                className={
                  devi.date_acceptation &&
                  devi.date_limite_retractation &&
                  dateEstCompriseDansIntervalle(
                    new Date(devi.date_acceptation as string),
                    ajouterJour(new Date(devi.date_limite_retractation as string), -1),
                    currentDate,
                  )
                    ? currentDate.getDate() === ajouterJour(new Date(devi.date_limite_retractation), -1).getDate()
                      ? 'text-orange-200'
                      : ''
                    : 'text-red-700'
                }>
                {devi.date_limite_retractation
                  ? `Rétractation possible jusqu'au : ${new Intl.DateTimeFormat('default', {
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                      hour12: false,
                    }).format(new Date(devi.date_limite_retractation))}.`
                  : ''}
              </div>
            </Fragment>
          );
        } else {
          // Délais de traitement dépassé.
          return (
            <Fragment>
              {devisAccepte}
              <div className='text-red-700'>
                {devi.date_limite_traitement
                  ? `Date limite de traitement le : ${new Intl.DateTimeFormat('default', {
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                      hour12: false,
                    }).format(new Date(devi.date_limite_traitement))}.`
                  : ''}
              </div>
            </Fragment>
          );
        }
      }

      if (devi.date_retractation) {
        return (
          <Fragment>
            {devisAccepte}
            <div className='text-red-700'>
              {devi.date_retractation
                ? `Rétracté le : ${new Intl.DateTimeFormat('default', {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour12: false,
                  }).format(new Date(devi.date_retractation))}.`
                : ''}
            </div>
          </Fragment>
        );
      }

      if (devi.date_traitement) {
        return (
          <Fragment>
            {devisAccepte}
            <div>
              {devi.date_traitement
                ? `Traité le : ${new Intl.DateTimeFormat('default', {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour12: false,
                  }).format(new Date(devi.date_traitement))}.`
                : ''}
            </div>
          </Fragment>
        );
      }
    }

    if (devi.date_refus) {
      return (
        <div>{`Refusé le : ${new Intl.DateTimeFormat('default', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour12: false,
        }).format(new Date(devi.date_refus as string))}.`}</div>
      );
    }
  };

  /**
   * Contenu des onglets.
   * @constant
   */
  const data = [
    {
      label: 'Général',
      value: 'general',
      icone: <CheckCircleIcon className='w-5 h-5 flex-none' />,
    },
    {
      label: 'Documents demandés',
      value: 'documentsDemandes',
      icone: <PlayCircleIcon className='w-5 h-5 flex-none' />,
    },
    {
      label: 'Documents salarié',
      value: 'documents',
      icone: <FolderIcon className='w-5 h-5 flex-none' />,
    },
    {
      label: 'Convocations',
      value: 'convocations',
      icone: <EnvelopeIcon className='w-5 h-5 flex-none' />,
    },
    {
      label: 'Devis',
      value: 'devis',
      icone: <DocumentIcon className='w-5 h-5 flex-none' />,
    },
    {
      label: 'Factures',
      value: 'factures',
      icone: <CurrencyEuroIcon className='w-5 h-5 flex-none' />,
    },
  ] as const;

  if (demande === null) {
    return <Fragment>Chargement...</Fragment>;
  }

  return (
    <Fragment>
      <Helmet>
        <title>
          Demande N°{demande.id?.toString().padStart(6, '0')} - {Config.app_label}
        </title>
      </Helmet>
      <HeaderForm text={`Demande N°${demande.id?.toString().padStart(6, '0')}`} returnUrl='/entreprise/demandes' />
      <Tabs value='general'>
        <TabsHeader>
          {data.map(({ label, value, icone }) => (
            <Tab key={value} value={value}>
              {icone}
              <span>{label}</span>
            </Tab>
          ))}
        </TabsHeader>
        <TabsBody>
          <TabPanel value='general'>
            <form className='relative'>
              <div className='bg-white p-5 rounded-b-lg'>
                <div className='container mx-auto bg-white rounded-none'>
                  <fieldset className='flex flex-col md:flex-row gap-3 pb-7 '>
                    <div className='basis-3/5 md:border-r border-secondary-1/30'>
                      <legend className='text-secondary-1 poppins-semibold text-lg mb-3'>Formation</legend>
                      <FormationSessionDisplay formation={demande.formation} groupe={demande.groupe} />
                    </div>
                    <div className='basis-2/5'>
                      <legend className='text-secondary-1 poppins-semibold text-lg mb-3'>Souhait</legend>
                      <div className='text-sm'>
                        <div>{`Souhait émis le ${new Intl.DateTimeFormat('default', {
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                          hour: 'numeric',
                          minute: 'numeric',
                          hour12: false,
                        }).format(new Date(demande.created_at as string))}`}</div>
                        <div>
                          {demande.souhait.date_acceptation
                            ? `Souhait validé le ${new Intl.DateTimeFormat('default', {
                                year: 'numeric',
                                month: 'numeric',
                                day: 'numeric',
                                hour: 'numeric',
                                minute: 'numeric',
                                hour12: false,
                              }).format(new Date(demande.souhait.date_acceptation as string))}`
                            : ''}
                        </div>
                      </div>
                    </div>
                  </fieldset>
                  <fieldset className='py-3 mb-7 border-b'>
                    <div className='flex flex-wrap gap-2 items-center justify-center'>
                      {demande.date_acceptation === null && demande.date_refus === null && (
                        <Fragment>
                          <Button
                            variant='filled'
                            className='flex flex-row items-center rounded-full'
                            size='sm'
                            onClick={() => setModalAnnulationEstVisible(true)}>
                            Annuler
                          </Button>
                        </Fragment>
                      )}
                    </div>
                  </fieldset>
                  <fieldset className='pb-2'>
                    <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2'>
                      <div>
                        <legend className='text-secondary-1 poppins-semibold text-lg mb-3'>Avancement</legend>
                        <div className='text-sm'>
                          {demande.created_at && (
                            <div>{`Demande envoyée le ${new Intl.DateTimeFormat('default', {
                              year: 'numeric',
                              month: 'numeric',
                              day: 'numeric',
                              hour: 'numeric',
                              minute: 'numeric',
                              hour12: false,
                            }).format(new Date(demande.created_at as string))}`}</div>
                          )}
                          {demande.date_acceptation && (
                            <div>
                              Demande acceptée le{' '}
                              {new Intl.DateTimeFormat('default', {
                                year: 'numeric',
                                month: 'numeric',
                                day: 'numeric',
                                hour: 'numeric',
                                minute: 'numeric',
                                hour12: false,
                              }).format(new Date(demande.date_acceptation as string))}
                            </div>
                          )}

                          {demande.date_refus && (
                            <div>
                              Demande acceptée le{' '}
                              {new Intl.DateTimeFormat('default', {
                                year: 'numeric',
                                month: 'numeric',
                                day: 'numeric',
                                hour: 'numeric',
                                minute: 'numeric',
                                hour12: false,
                              }).format(new Date(demande.date_refus as string))}
                            </div>
                          )}
                          {demande.motif_refus && <div>Motif de refus : {demande.motif_refus}</div>}
                        </div>
                      </div>
                      <div className='border-secondary-1/30 sm:px-3 mt-7 sm:mt-0 text-sm'>
                        <div className='text-secondary-1 poppins-semibold text-lg mb-3'>Statut</div>
                        {/*<div>
                          {demande.est_en_attente_informations ? (
                            <ExclamationTriangleIcon className='w-4 h-4 inline-block mx-auto text-red-300' />
                          ) : (
                            <XMarkIcon className='w-4 h-4 inline-block mx-auto text-gray-900/20' />
                          )}{' '}
                          En attente d'informations
                          </div> */}
                        <div>
                          {demande.est_en_attente_documents ? (
                            <ExclamationTriangleIcon className='w-4 h-4 inline-block mx-auto text-red-300' />
                          ) : (
                            <XMarkIcon className='w-4 h-4 inline-block mx-auto text-gray-900/20' />
                          )}{' '}
                          Documents en attente
                        </div>
                        <div>
                          {demande.est_complete ? (
                            <CheckIcon className='w-4 h-4 text-white inline-block mx-auto bg-light-green-300 rounded-full p-0.5' />
                          ) : (
                            <XMarkIcon className='w-4 h-4 inline-block mx-auto text-gray-900/20' />
                          )}{' '}
                          Demande complète
                        </div>
                        <div>
                          {demande.est_paye ? (
                            <CheckIcon className='w-4 h-4 text-white inline-block mx-auto bg-light-green-300 rounded-full p-0.5' />
                          ) : (
                            <XMarkIcon className='w-4 h-4 inline-block mx-auto text-gray-900/20' />
                          )}{' '}
                          Paiement effectué
                        </div>
                        <div>
                          {demande.est_finalise ? (
                            <CheckIcon className='w-4 h-4 text-white inline-block mx-auto bg-light-green-300 rounded-full p-0.5' />
                          ) : (
                            <XMarkIcon className='w-4 h-4 inline-block mx-auto text-gray-900/20' />
                          )}{' '}
                          Finalisée
                        </div>
                      </div>
                      <div className='mt-7 lg:mt-0'>
                        <legend className='text-secondary-1 poppins-semibold text-lg mb-3'>Salarié</legend>
                        <UserWithMailDisplay user={demande.user} />
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>
            </form>
          </TabPanel>
          <TabPanel value='documentsDemandes'>
            <div className='bg-white w-full overflow-x-auto'>
              {(demande.documents as DemandeDocument[]) && (demande.documents as DemandeDocument[]).length > 0 ? (
                <table className='table-fixed min-w-full bg-white rounded-b-lg'>
                  <thead className='text-sm text-gray-500 text-left border-b'>
                    <tr>
                      <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Document attendu</th>
                      <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Fichier</th>
                      <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Est traité</th>
                      <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Créée le</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(demande.documents as DemandeDocument[]).map((document) => (
                      <tr key={document.token as string} className=' border-gray-100 border-b hover:bg-gray-100'>
                        <td className='px-4 py-3 text-gray-800 text-sm'>{document.question}</td>
                        <td className='px-4 py-3 text-gray-800 text-sm'>
                          {document.fichier ? (
                            <DownloadLink fichier={document.fichier} titre={document.titre} />
                          ) : (
                            <Button
                              className='flex flex-row items-center rounded-full'
                              size='sm'
                              onClick={() => handleModalDocuments(document.question, document.token)}>
                              <PlusIcon className='h-6 w-6 inline mr-2' />
                              Joindre un fichier
                            </Button>
                          )}
                        </td>
                        <td className='px-4 py-3 text-gray-800 text-sm'>{document.est_traite ? 'Oui' : 'Non'}</td>
                        <td className='px-4 py-3 text-gray-800 text-sm'>
                          {document.created_at
                            ? new Intl.DateTimeFormat('default', {
                                year: 'numeric',
                                month: 'numeric',
                                day: 'numeric',
                                hour: 'numeric',
                                minute: 'numeric',
                                second: 'numeric',
                                hour12: false,
                              }).format(new Date(document.created_at as string))
                            : ''}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className='text-center px-4 py-7'>Vous n'avez pas de document lié à cette demande.</div>
              )}
            </div>
          </TabPanel>

          <TabPanel value='documents'>
            <div className='bg-white w-full overflow-x-auto'>
              {documents && documents.length > 0 ? (
                <table className='table-fixed min-w-full bg-white rounded-b-lg'>
                  <thead className='text-sm text-gray-500 text-left border-b'>
                    <tr>
                      <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Fichier</th>
                      <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Description</th>
                      <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Créée le</th>
                    </tr>
                  </thead>
                  <tbody>
                    {documents.map((document) => (
                      <tr
                        key={document.token}
                        className='border-gray-100 border-b hover:bg-gray-100'
                        onDoubleClick={() => navigate(`/entreprise/documents/${document.token}`)}>
                        <td className='px-4 py-3 text-gray-800 text-sm'>
                          {document.fichier && <DownloadLink fichier={document.fichier} titre={document.titre} />}
                        </td>
                        <td className='px-4 py-3 text-gray-800 text-xs'>{document.description ? nl2br(document.description) : ''}</td>
                        <td className='px-4 py-3 text-gray-800 text-sm'>
                          {document.created_at
                            ? new Intl.DateTimeFormat('default', {
                                year: 'numeric',
                                month: 'numeric',
                                day: 'numeric',
                                hour: 'numeric',
                                minute: 'numeric',
                                hour12: false,
                              }).format(new Date(document.created_at as string))
                            : ''}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className='text-center px-4 py-7'>Vous n'avez pas de document salarié lié à cette demande.</div>
              )}
            </div>
          </TabPanel>
          <TabPanel value='convocations'>
            <div className='bg-white rounded-b-lg w-full overflow-x-auto'>
              {demande.convocations && demande.convocations.length > 0 ? (
                <table className='table-auto w-full min-w-[1200px] bg-white rounded-b-lg'>
                  <thead className='text-sm text-gray-500 text-left border-b'>
                    <tr>
                      <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Fichier</th>
                      <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Description</th>
                      <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Date de convocation</th>
                      <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Créée le</th>
                    </tr>
                  </thead>
                  <tbody>
                    {demande.convocations &&
                      demande.convocations.map((convocation) => (
                        <tr
                          key={convocation.token}
                          className=' border-gray-100 border-b hover:bg-gray-100'
                          onDoubleClick={() => navigate(`/entreprise/convocations/${convocation.token}`)}>
                          <td className='px-4 py-3 text-gray-800 text-sm'>
                            {convocation.fichier && <DownloadLink fichier={convocation.fichier} titre={convocation.titre} />}
                          </td>
                          <td
                            className={`px-4 py-3 text-gray-800 text-xs ${convocation.token !== convocationTokenSelectionne ? 'cursor-row-resize' : ''}`}
                            onClick={() => setConvocationTokenSelectionne(convocation.token)}>
                            <div className={`${convocation.token !== convocationTokenSelectionne ? 'line-clamp-6' : ''}`}>
                              {convocation.description ? nl2br(convocation.description) : ''}
                            </div>
                          </td>
                          <td className='px-4 py-3 text-gray-800 text-sm'>
                            {convocation.date_convocation
                              ? new Intl.DateTimeFormat('default', {
                                  year: 'numeric',
                                  month: 'numeric',
                                  day: 'numeric',
                                  hour12: false,
                                }).format(new Date(convocation.date_convocation as string))
                              : ''}
                          </td>
                          <td className='px-4 py-3 text-gray-800 text-sm'>
                            {convocation.created_at
                              ? new Intl.DateTimeFormat('default', {
                                  year: 'numeric',
                                  month: 'numeric',
                                  day: 'numeric',
                                  hour12: false,
                                }).format(new Date(convocation.created_at as string))
                              : ''}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              ) : (
                <div className='text-center px-4 py-7'>Vous n'avez pas de convocation liée à cette demande.</div>
              )}
            </div>
          </TabPanel>
          <TabPanel value='devis'>
            <div className='bg-white rounded-b-lg w-full overflow-x-auto'>
              {demande.devis && demande.devis.length > 0 ? (
                <table className='table-auto w-full min-w-[1200px] bg-white rounded-b-lg'>
                  <thead className='text-sm text-gray-500 text-left border-b'>
                    <tr>
                      <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Numéro</th>
                      <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Fichier</th>
                      <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Description</th>
                      <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Avancement</th>
                      <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Créée le</th>
                    </tr>
                  </thead>
                  <tbody>
                    {demande.devis &&
                      demande.devis.map((devi) => (
                        <tr
                          key={devi.token}
                          className=' border-gray-100 border-b hover:bg-gray-100'
                          onDoubleClick={() => navigate(`/entreprise/devis/${devi.token}`)}>
                          <td className='px-4 py-3 text-gray-800 text-sm'>{devi.numero}</td>
                          <td className='px-4 py-3 text-gray-800 text-sm'>{devi.fichier && <DownloadLink fichier={devi.fichier} titre={devi.titre} />}</td>
                          <td
                            className={`px-4 py-3 text-gray-800 text-xs ${devi.token !== devisTokenSelectionne ? 'cursor-row-resize' : ''}`}
                            onClick={() => setDevisTokenSelectionne(devi.token)}>
                            <div className={`${devi.token !== devisTokenSelectionne ? 'line-clamp-6' : ''}`}>
                              {devi.description ? nl2br(devi.description) : ''}
                            </div>
                          </td>
                          <td className='px-4 py-3 text-gray-800 text-xs'>
                            <div>{afficheAvancement(devi)}</div>
                          </td>
                          <td className='px-4 py-3 text-gray-800 text-sm'>
                            {devi.created_at
                              ? new Intl.DateTimeFormat('default', {
                                  year: 'numeric',
                                  month: 'numeric',
                                  day: 'numeric',
                                  hour12: false,
                                }).format(new Date(devi.created_at as string))
                              : ''}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              ) : (
                <div className='text-center px-4 py-7'>Vous n'avez pas de devis lié à cette demande.</div>
              )}
            </div>
          </TabPanel>
          <TabPanel value='factures'>
            <div className='bg-white rounded-b-lg w-full overflow-x-auto'>
              {demande.factures && demande.factures.length > 0 ? (
                <table className='table-auto w-full min-w-[1200px] bg-white rounded-b-lg'>
                  <thead className='text-sm text-gray-500 text-left border-b'>
                    <tr>
                      <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Numéro</th>
                      <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Fichier</th>
                      <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Description</th>
                      <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Date limite de paiement</th>
                      <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Payée le</th>
                      <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Créée le</th>
                    </tr>
                  </thead>
                  <tbody>
                    {demande.factures &&
                      demande.factures.map((facture) => (
                        <tr
                          key={facture.token}
                          className=' border-gray-100 border-b hover:bg-gray-100'
                          onDoubleClick={() => navigate(`/entreprise/factures/${facture.token}`)}>
                          <td className='px-4 py-3 text-gray-800 text-sm'>{facture.numero}</td>
                          {/*<td className='px-2 py-3 text-secondary-1 text-sm'>{facture.devis ? facture.devis.numero : ''}</td>*/}
                          <td className='px-4 py-3 text-gray-800 text-sm'>
                            {facture.fichier && <DownloadLink fichier={facture.fichier} titre={facture.titre} />}
                          </td>
                          <td
                            className={`px-4 py-3 text-gray-800 text-xs ${facture.token !== factureTokenSelectionne ? 'cursor-row-resize' : ''}`}
                            onClick={() => setFactureTokenSelectionne(facture.token)}>
                            <div className={`${facture.token !== factureTokenSelectionne ? 'line-clamp-6' : ''}`}>
                              {facture.description ? nl2br(facture.description) : ''}
                            </div>
                          </td>
                          <td className='px-4 py-3 text-gray-800 text-sm'>
                            {facture.date_limite_paiement
                              ? new Intl.DateTimeFormat('default', {
                                  year: 'numeric',
                                  month: 'numeric',
                                  day: 'numeric',
                                  hour12: false,
                                }).format(new Date(facture.date_limite_paiement as string))
                              : ''}
                          </td>
                          <td className='px-4 py-3 text-gray-800 text-sm'>
                            {facture.date_paiement
                              ? new Intl.DateTimeFormat('default', {
                                  year: 'numeric',
                                  month: 'numeric',
                                  day: 'numeric',
                                  hour12: false,
                                }).format(new Date(facture.date_paiement as string))
                              : ''}
                          </td>
                          <td className='px-4 py-3 text-gray-800 text-sm'>
                            {facture.created_at
                              ? new Intl.DateTimeFormat('default', {
                                  year: 'numeric',
                                  month: 'numeric',
                                  day: 'numeric',
                                  hour12: false,
                                }).format(new Date(facture.created_at as string))
                              : ''}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              ) : (
                <div className='text-center px-4 py-7'>Vous n'avez pas de facture liée à cette demande.</div>
              )}
            </div>
          </TabPanel>
        </TabsBody>
      </Tabs>
      {apiIsLoading ? <LoadingAbsolute /> : ''}
      <ModalEntrepriseDemandeDocument
        visible={modalDocumentsEstVisible}
        setVisible={setModalDocumentsEstVisible}
        handleShow={handleShow}
        question={selectedQuestion}
        tokenDemande={params.token as string}
        tokenDocument={selectedToken}
      />
      <ModalAnnulationDemandeEntreprise visible={modalAnnulationEstVisible} setVisible={setModalAnnulationEstVisible} demandeToken={params.token as string} />
    </Fragment>
  );
};

import { FunctionComponent } from 'react';
import { UserInfo } from '../types/auth';
import { Groupe } from '../types/SalariesRessources';
import { Avatar } from './Avatar';
import { EmailVerifie } from './EmailVerifie';
import { FormationSessionDisplay } from './FormationSessionDisplay';

/**
 * Carte de l'entreprise avec ces salariés.
 *
 * @param groupe - Groupe qui contient les salariés de l'entreprise.
 */
export const EntrepriseGroupeCard: FunctionComponent<{ groupe: Groupe & { users: UserInfo[] } }> = ({ groupe }) => (
  <div className='max-w-2xl mx-auto mb-4 rounded-lg bg-white shadow-lg p-5'>
    <div className='full mb-7'>
      <FormationSessionDisplay formation={groupe.formation} groupe={groupe} />
    </div>
    <p className='text-secondary-1 poppins-semibold'>Participants&nbsp;:</p>
    {groupe.users.length > 0 && (
      <div className='grid grid-cols-1 lg:grid-cols-2 gap-3 mt-4'>
        {groupe.users.map((user) => (
          <div key={user.email} className='border rounded-lg p-4 text-center flex flex-row items-center justify-center gap-3'>
            <div className='flex-none'>
              <div className='w-14 h-14'>
                <Avatar className='rounded-md aspect-1 h-14 w-14' src={user.image_avatar} />
              </div>
            </div>
            <div className='w-full text-left'>
              <span className='text-md poppins-semibold block'>
                {user.prenom} {user.nom}
              </span>
              <div className='text-sm text-gray-600 flex items-center'>
                <div>{user.email}</div>
                {user.email_verified_at && (
                  <div>
                    <EmailVerifie />
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    )}
  </div>
);

import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { GroupeCard } from '../../components/GroupeCard';
import { LoadingAbsolute } from '../../components/LoadingAbsolute';
import { Config } from '../../config/config';
import { useApi } from '../../providers/ApiProvider';
import { GoodApiResponse, ErrorApiResponse } from '../../types/api';
import { Groupe } from '../../types/SalariesRessources';
import { CheckCircleIcon, ClockIcon, PlayCircleIcon } from '@heroicons/react/24/outline';
import { handleError } from '../../utils/ErrorHandler';
import { Tab, TabPanel, Tabs, TabsBody, TabsHeader } from '../../components/Onglets';

/**
 * Ce coposant affiche la liste des formations du salarié.
 */
export const MesFormationsScreen: FunctionComponent = () => {
  /**
   * On stocke si on est en train d'utiliser l'API ou non.
   */
  const [apiIsLoading, setApiIsLoading] = useState(false);

  /**
   * État local qui gère les sessions de formations
   */
  const [groupes, setGroupes] = useState<Groupe[] | null>();

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  /**
   * Date du jour.
   *
   * @constant
   */
  const currentDate = new Date();

  /**
   * Permet de recharger les données au chargement de page
   */
  useEffect(() => {
    handleIndex();
  }, [client]);

  /**
   * Retrouve des données paginées de L'API et les stocke dans un état local.
   *
   * @param indexParams - Paramètres de la recherche.
   */
  const handleIndex = () => {
    setApiIsLoading(true);
    client
      .get<GoodApiResponse<Groupe[]>>('/salarie/groupes')
      .then((response) => {
        setGroupes(response.data.data);
      })
      .catch((error: ErrorApiResponse<Groupe>) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Contenu des onglets.
   *
   * @constant
   */
  const data = [
    {
      label: 'Toutes',
      value: 'toutes',
      icone: <CheckCircleIcon className='w-5 h-5 flex-none' />,
    },
    {
      label: 'En cours',
      value: 'encours',
      icone: <PlayCircleIcon className='w-5 h-5 flex-none' />,
    },
    {
      label: 'A venir',
      value: 'avenir',
      icone: <ClockIcon className='w-5 h-5 flex-none' />,
    },
    {
      label: 'Terminées',
      value: 'terminees',
      icone: <CheckCircleIcon className='w-5 h-5 flex-none' />,
    },
  ] as const;

  if (groupes === null) {
    return <Fragment>Chargement...</Fragment>;
  }

  const g_encours = groupes
    ? groupes.filter((groupe) => groupe.date_debut && groupe.date_fin && new Date(groupe.date_debut) <= currentDate && currentDate <= new Date(groupe.date_fin))
    : null;

  const g_avenir = groupes ? groupes.filter((groupe) => groupe.date_debut && currentDate < new Date(groupe.date_debut)) : null;

  const g_termines = groupes ? groupes.filter((groupe) => groupe.date_fin && new Date(groupe.date_fin) < currentDate) : null;

  return (
    <Fragment>
      <Helmet>
        <title>Mes formations - {Config.app_label}</title>
      </Helmet>
      <div className='px-4 py-4 md:py-3 bg-gray-100 rounded-tl-lg rounded-tr-lg'>
        <div className='flex flex-col lg:flex-row items-center justify-between'>
          <h1 className='text-base sm:text-lg md:text-xl poppins-bold leading-normal text-secondary-1'>Mes formations</h1>
        </div>
      </div>
      <Tabs value='encours'>
        <TabsHeader>
          {data.map(({ label, value, icone }) => (
            <Tab key={value} value={value}>
              {icone}
              <span>{label}</span>
            </Tab>
          ))}
        </TabsHeader>

        <TabsBody>
          <TabPanel value='toutes'>
            <div className='bg-white p-5 rounded-b-lg'>
              {groupes && groupes.length > 0 ? (
                groupes.map((groupe) => <GroupeCard key={groupe.token} groupe={groupe} />)
              ) : (
                <div className='text-center'>Vous n'avez pas de formations planifiées pour l'instant.</div>
              )}
            </div>
          </TabPanel>

          <TabPanel value='encours'>
            <div className='bg-white p-5 rounded-b-lg'>
              {g_encours && g_encours.length > 0 ? (
                g_encours.map((groupe) => <GroupeCard key={groupe.token} groupe={groupe} />)
              ) : (
                <div className='text-center'>Vous n'avez pas de formations en cours pour l'instant.</div>
              )}
            </div>
          </TabPanel>

          <TabPanel value='avenir'>
            <div className='bg-white p-5 rounded-b-lg'>
              {g_avenir && g_avenir.length > 0 ? (
                g_avenir.map((groupe) => <GroupeCard key={groupe.token} groupe={groupe} />)
              ) : (
                <div className='text-center'>Vous n'avez pas de formations à venir pour l'instant.</div>
              )}
            </div>
          </TabPanel>

          <TabPanel value='terminees'>
            <div className='bg-white p-5 rounded-b-lg'>
              {g_termines && g_termines.length > 0 ? (
                g_termines.map((groupe) => <GroupeCard key={groupe.token} groupe={groupe} />)
              ) : (
                <div className='text-center'>Vous n'avez pas de formations terminées pour l'instant.</div>
              )}
            </div>
          </TabPanel>
        </TabsBody>
      </Tabs>
      {apiIsLoading ? <LoadingAbsolute /> : ''}
    </Fragment>
  );
};

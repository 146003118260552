import { Dialog, DialogHeader, DialogBody, DialogFooter, Button } from '@material-tailwind/react';
import { FunctionComponent, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useApi } from '../../../providers/ApiProvider';
import { ErrorApiResponse, GoodApiResponse } from '../../../types/api';
import { Demande } from '../../../types/EntreprisesRessources';
import { handleError } from '../../../utils/ErrorHandler';
import { LoadingAbsolute } from '../../LoadingAbsolute';

/**
 * Cette modale permet de confirmer l'annulation d'une demande envoyée à l'admin.
 *
 * @param visible - Précise si la modale et ouverte ou non. Provient du parent.
 * @param setVisible - Accesseur qui modifie la visibilité de la modale. Provient du parent.
 * @param demandeToken - Jeton de l'invitation.
 * @param handleIndex - Callback qui réalise l'appel pour recharger les données des invitations. Provient du parent.
 */
export const ModalAnnulationDemandeEntreprise: FunctionComponent<{
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  demandeToken: Demande['token'];
}> = ({ visible, setVisible, demandeToken }) => {
  /**
   * On stocke si on est en train d'utiliser l'API ou non.
   */
  const [apiIsLoading, setApiIsLoading] = useState(false);

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  /**
   * Permet au composant de changer de page.
   */
  const navigate = useNavigate();

  /**
   * Cet méthode permet d'annuler une invitation que l'on a envoyé.
   */
  const handleAnnuler = () => {
    setApiIsLoading(true);
    client
      .delete<GoodApiResponse>(`/entreprise/demandes/${demandeToken}`)
      .then(() => {
        toast.success(`Demande annulée`);
        navigate('/entreprise/demandes');
      })
      .catch((error: ErrorApiResponse<Demande>) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
        setVisible(false);
      });
  };

  return (
    <Dialog open={visible} size='xs' handler={setVisible}>
      <DialogHeader>Confirmation d'annulation de la demande</DialogHeader>
      <DialogBody divider>Confirmez-vous l'annulation de la demande auprès des Compagnons du Devoir ?</DialogBody>
      <DialogFooter>
        <Button variant='text' color='gray' onClick={() => setVisible(false)} className='mr-1 !rounded-full'>
          Annuler
        </Button>
        <Button
          variant='filled'
          color='orange'
          onClick={() => {
            handleAnnuler();
          }}>
          Confirmer
        </Button>
      </DialogFooter>
      {apiIsLoading ? <LoadingAbsolute /> : ''}
    </Dialog>
  );
};

import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { ConstStatutAffiliation } from '../../components/ConstStatutAffiliation';
import { LoadingAbsolute } from '../../components/LoadingAbsolute';
import { useApi } from '../../providers/ApiProvider';
import { GoodApiResponse, ErrorApiResponse } from '../../types/api';
import { Config } from '../../config/config';
import { Profil, StatutAffiliationEnum, UserLocal } from '../../types/auth';
import { useUser } from '../../providers/UserProvider';
import Button from '@material-tailwind/react/components/Button';
import { ConstTypeProfil } from '../../components/ConstTypeProfil';
import { Logo } from '../../components/Logo';
import { ModalCreationEntreprise } from '../../components/Modales/ModalCreationEntreprise';
import { handleError } from '../../utils/ErrorHandler';

export const ProfilsScreen: FunctionComponent = () => {
  /**
   * On stocke si on est en train d'utiliser l'API ou non.
   */
  const [apiIsLoading, setApiIsLoading] = useState(false);

  /**
   * On stocke les profils possédés par l'entreprise, les salariés.
   */
  const [profils, setProfils] = useState<Profil[] | null>([]);

  /**
   * Permet d'ouvrir une modale lorsque l'on souhaite créer une entreprise.
   */
  const [modalCreationEntrepriseEstVisible, setModalCreationEntrepriseEstVisible] = useState<boolean>(false);

  /**
   * On a besoin des informations de l'utilisateur et du profil courant de celui-ci ainsi que du moyen de modifier ces informations.
   */
  const { user, changeUser, profil: currentProfil, changeProfil: setCurrentProfil } = useUser();

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  /**
   * Permet de recharger les données au chargement de page ou au changement de paramètres
   */
  useEffect(() => {
    handleIndex();
  }, [client]);

  /**
   * Cette méthoded permet de changer de profil courant.
   *
   * @param profilId - ID du profil que l'on choisit dans le menu.
   */
  const handleProfilClick = (profilId: Profil['id']) => {
    const profil = user?.profils.find((profil) => profil.id === profilId);

    if (profil) {
      setCurrentProfil(profil);
    }
  };

  /**
   * Cette méthode appelle la liste des profils depepuis l'API et les range dans l'état local.
   */
  const handleIndex = () => {
    setApiIsLoading(true);

    client
      .get<GoodApiResponse<Profil[]>>('/profils')
      .then((response) => {
        setProfils(response.data.data);
        changeUser({ ...(user as UserLocal), profils: [...response.data.data] });
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  if (profils === undefined || profils === null) {
    return <Fragment>Chargement...</Fragment>;
  }

  return (
    <Fragment>
      <Helmet>
        <title>Profils - {Config.app_label}</title>
      </Helmet>
      <div className='px-4 py-4 md:py-3 bg-gray-100 rounded-tl-lg rounded-tr-lg flex flex-col sm:flex-row gap-7 items-center'>
        <div className='sm:flex items-center justify-between'>
          <h1 className='text-base sm:text-lg md:text-xl poppins-bold leading-normal text-secondary-1'>Mes profils</h1>
        </div>
      </div>
      <div>
        <div className='bg-white w-full overflow-x-auto'>
          <table className='table-fixed min-w-full bg-white rounded-b-lg'>
            <thead className='text-sm text-gray-500 text-left border-b'>
              <tr>
                <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Raison sociale</th>
                <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Enseigne</th>
                <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Statut</th>
                <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Fonction</th>
                <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Depuis le</th>
                <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Actions</th>
              </tr>
            </thead>
            <tbody>
              {profils
                //.filter((profil) => profil.type_profil === TypeProfilEnum.salarie)
                .map((profil) => (
                  <tr key={profil.id} className={`border-gray-100 border-b hover:bg-gray-100 ${currentProfil?.id === profil.id && 'font-bold'}`}>
                    <td className='px-4 py-3 text-secondary-1 poppins text-sm flex flex-row items-center gap-3'>
                      <div>
                        <Logo className='rounded h-10 w-10 object-cover' src={profil.entreprise.image_logo} />
                      </div>
                      <div>{profil.entreprise.raison_sociale}</div>
                    </td>
                    <td className='px-4 py-3 text-secondary-1 poppins text-sm'>{profil.entreprise.enseigne}</td>
                    <td className='px-4 py-3 text-secondary-1 poppins text-sm'>{<ConstStatutAffiliation text={profil.statut_affiliation} />}</td>
                    <td className='px-4 py-3 text-secondary-1 poppins text-sm'>{<ConstTypeProfil text={profil.type_profil} />}</td>
                    <td className='px-4 py-3 text-secondary-1 poppins text-sm'>
                      {profil.created_at
                        ? new Intl.DateTimeFormat('default', {
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                            hour12: false,
                          }).format(new Date(profil.created_at as string))
                        : ''}
                    </td>
                    <td className='px-4 py-3'>
                      <Button
                        className='flex flex-row items-center rounded-full'
                        size='sm'
                        onClick={() => handleProfilClick(profil.id)}
                        disabled={currentProfil?.id === profil.id || profil.statut_affiliation == StatutAffiliationEnum.revoque}>
                        Choisir
                      </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        {apiIsLoading ? <LoadingAbsolute /> : ''}
        <div className='text-center py-5'>
          {' '}
          <Button className='rounded-full' size='sm' onClick={() => setModalCreationEntrepriseEstVisible(true)}>
            Créer une entreprise
          </Button>
        </div>

        <ModalCreationEntreprise visible={modalCreationEntrepriseEstVisible} setVisible={setModalCreationEntrepriseEstVisible} handleIndex={handleIndex} />
      </div>
    </Fragment>
  );
};

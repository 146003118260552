import { Dialog, DialogHeader, DialogBody, DialogFooter, Button } from '@material-tailwind/react';
import { FunctionComponent, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useApi } from '../../../providers/ApiProvider';
import { Demande, Document } from '../../../types/AdminRessources';
import { GoodApiResponse, ErrorApiResponse } from '../../../types/api';
import { EntrepriseInfo, ProfilEntreprise } from '../../../types/auth';
import { handleError } from '../../../utils/ErrorHandler';
import { AlertError } from '../../Alertes';
import { InputFile } from '../../Inputs/InputFile';
import { InputTextarea } from '../../Inputs/InputTextarea';
import { LoadingAbsolute } from '../../LoadingAbsolute';
import { FormationSessionDisplay } from '../../FormationSessionDisplay';
import { UserDisplay } from '../../UserDisplay';

/**
 * Ce composant permet d'afficher une Modale pour que les admins puissent ajouter un document au salarié.
 *
 * @param visible - Précise si la modale et ouverte ou non. Provient du parent.
 * @param setVisible - Accesseur qui modifie la visibilité de la modale. Provient du parent.
 * @param profil - Profil entreprise. Provient du parent.
 * @param handleIndex - Callback qui réalise l'appel pour recharger les données annexes à la demande. Provient du parent.
 */
export const ModalAjouterDocumentEntreprise: FunctionComponent<{
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  profil: ProfilEntreprise | null;
  handleIndex: () => void;
}> = ({ visible, setVisible, profil, handleIndex }) => {
  /**
   * On stocke si on est en train d'utiliser l'API ou non.
   */
  const [apiIsLoading, setApiIsLoading] = useState(false);

  /**
   * Permet de donner un titre au document.
   */
  const [titre, setTitre] = useState<Document['titre']>('');

  /**
   * Fichier à envoyer.
   */
  const [fichier, setFichier] = useState<Document['fichier']>('');

  /**
   * Description du fichier, optionnel
   */
  const [description, setDescription] = useState<Document['description']>('');

  /**
   * Liste des demandes
   */
  const [demandes, setDemandes] = useState<Demande[] | null>(null);

  /**
   * Demande sélectionnée
   */
  const [demandeId, setDemandeId] = useState<Demande['id'] | null>(null);

  /**
   * On stocke l'eventuelle erreur.
   */
  const [error, setError] = useState<string | null>(null);

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  /**
   * Permet de réinitialiser les champs de la modale à chaque fois qu'on l'ouvre où qu'on la ferme.
   */
  useEffect(() => {
    setTitre('');
    setFichier('');
    setDescription('');
    setDemandeId(null);
    setError(null);
  }, [visible]);

  /**
   * Permet de rafraichir la liste des demandes, lorsque l'on change de profil.
   */
  useEffect(() => {
    if (visible) {
      handleListeDemandes();
    }
  }, [profil, visible]);

  /**
   * Cette méthode permet d'obtenir la liste des demandes
   */
  const handleListeDemandes = () => {
    setApiIsLoading(true);
    if (profil) {
      client
        .get<GoodApiResponse<Demande[]>>('/admin/demandes', { params: { profil_id: profil?.id } })
        .then((response) => {
          setDemandes(response.data.data as unknown as (Demande & { id: number; entreprise: EntrepriseInfo })[]);
        })
        .catch((error: ErrorApiResponse) => {
          handleError(error);
        })
        .finally(() => {
          setApiIsLoading(false);
        });
    }
  };

  /**
   * Envoie le fichier par l'API.
   *
   * @param profilId
   */
  const handleEnvoie = (profilId: ProfilEntreprise['id']) => {
    setApiIsLoading(true);

    client
      .post<GoodApiResponse>(`/admin/documents`, { titre: titre, fichier: fichier, profil_id: profilId, description: description, demande_id: demandeId })
      .then(() => {
        setVisible(false);
        handleIndex();
        toast.success(`Envoie du document réussi`);
      })
      .catch((error: ErrorApiResponse<Document>) => {
        handleError(
          error,
          () => {
            setError(error.response.data.message);
          },
          () => {
            setError(error.message);
          },
        );
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  return (
    <Dialog open={visible} size='lg' handler={setVisible}>
      <DialogHeader>{`Ajouter un document à ${profil ? profil.user.prenom : ''} ${profil ? profil.user.nom : ''}`}</DialogHeader>
      <DialogBody divider>
        {error && <AlertError text={error} />}

        {profil && (
          <div>
            <div>
              <p>Fichier :</p>
            </div>
            <InputFile
              onChange={(titre, base64) => {
                setTitre(titre);
                setFichier(base64);
                setError(null);
              }}
            />

            <div className='container mx-auto bg-white rounded-b-lg pt-4'>
              <div>
                <p>Demande liée (facultatif) :</p>
              </div>
              <div className='w-full overflow-x-auto overflow-y-auto border h-96'>
                {apiIsLoading ? <LoadingAbsolute /> : ''}
                <table className='table-fixed min-w-full bg-white rounded-b-lg'>
                  <thead>
                    <tr className='w-full h-12 border-gray-300 border-b py-8'>
                      <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Numéro</th>
                      <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Formation et session</th>
                      <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Salarié</th>
                      <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className='border-gray-100 border-b hover:bg-gray-100'>
                      <td colSpan={3} className='pl-4 py-3 text-gray-800 text-sm text-center'>
                        Aucune
                      </td>
                      <td className='px-4 py-3 text-gray-800 text-sm'>
                        <Button className='flex flex-row items-center rounded-full' size='sm' onClick={() => setDemandeId(null)} disabled={demandeId === null}>
                          Choisir
                        </Button>
                      </td>
                    </tr>
                    {demandes &&
                      demandes.map((demande) => (
                        <tr key={demande.id} className={`border-gray-100 border-b hover:bg-gray-100 ${demande.est_archive && 'bg-gray-200'}`}>
                          <td className='pl-4 py-3 text-gray-800 text-sm'>{demande.id?.toString().padStart(6, '0')}</td>
                          <td className='px-4 py-3'>
                            <FormationSessionDisplay formation={demande.formation} groupe={demande.groupe} />
                          </td>
                          <td className='px-4 py-3'>
                            <UserDisplay user={demande.user} />
                          </td>
                          <td className='px-4 py-3 text-gray-800 text-sm'>
                            <Button
                              className='flex flex-row items-center rounded-full'
                              size='sm'
                              onClick={() => setDemandeId(demande.id)}
                              disabled={demandeId === demande.id}>
                              Choisir
                            </Button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className='pt-4'>
              <p>Description (facultatif)</p>
            </div>
            <InputTextarea value={description ? description : ''} onChange={(e) => setDescription(e.target.value)} />
          </div>
        )}
      </DialogBody>
      <DialogFooter>
        <Button
          variant='text'
          color='gray'
          onClick={() => {
            setVisible(false);
          }}
          className='mr-1 !rounded-full'
          disabled={apiIsLoading}>
          Annuler
        </Button>
        <Button
          variant='filled'
          color='orange'
          onClick={() => {
            handleEnvoie((profil as ProfilEntreprise).id);
          }}
          disabled={apiIsLoading}>
          Confirmer
        </Button>
      </DialogFooter>
      {apiIsLoading ? <LoadingAbsolute /> : ''}
    </Dialog>
  );
};

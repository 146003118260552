import { Dialog, DialogHeader, DialogBody, DialogFooter, Button } from '@material-tailwind/react';
import { FunctionComponent, useState } from 'react';
import toast from 'react-hot-toast';
import { useApi } from '../../../providers/ApiProvider';
import { GoodApiResponse, ErrorApiResponse } from '../../../types/api';
import { Demande, DemandeDocument } from '../../../types/EntreprisesRessources';
import { handleError } from '../../../utils/ErrorHandler';
import { AlertError } from '../../Alertes';
import { InputFile } from '../../Inputs/InputFile';
import { LoadingAbsolute } from '../../LoadingAbsolute';

/**
 * Ce composant permet d'afficher une Modale pour que les dirigeant puissent envoyer un document demandé.
 *
 * @param visible - Précise si la modale et ouverte ou non. Provient du parent.
 * @param setVisible - Accesseur qui modifie la visibilité de la modale. Provient du parent.
 * @param handleShow - Callback qui réalise l'appel pour recharger les données du composant parent. Provient du parent.
 * @param question - Question lié à la demande. Provient du parent.
 * @param tokenDemande - Jeton de la demande. Provient du parent.
 * @param tokenDocument - Jeton du document. Provient du parent.
 */
export const ModalEntrepriseDemandeDocument: FunctionComponent<{
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  handleShow: () => void;
  question: DemandeDocument['question'];
  tokenDemande: Demande['token'];
  tokenDocument: DemandeDocument['token'];
}> = ({ visible, setVisible, handleShow, question, tokenDemande, tokenDocument }) => {
  /**
   * On stocke si on est en train d'utiliser l'API ou non.
   */
  const [apiIsLoading, setApiIsLoading] = useState(false);

  /**
   * Titre du document.
   */
  const [titre, setTitre] = useState<DemandeDocument['titre']>('');

  /**
   * Fichier
   */
  const [fichier, setFichier] = useState<DemandeDocument['fichier']>('');

  /**
   * On stocke l'eventuelle erreur.
   */
  const [error, setError] = useState<string | null>(null);

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  /**
   * Cette méthode permet d'effectuer l'envoie du document. Si l'appel est réussie on réinitialise les états locaux.
   */
  const handleEnvoie = () => {
    setApiIsLoading(true);

    client
      .patch<GoodApiResponse>(`/entreprise/demandes/${tokenDemande}/documents/${tokenDocument}`, { titre: titre, fichier: fichier })
      .then(() => {
        handleShow();
        setTitre('');
        setFichier('');
        setError(null);
        setVisible(false);
        toast.success(`Envoie du document réussi`);
      })
      .catch((error: ErrorApiResponse<DemandeDocument>) => {
        handleError(
          error,
          () => {
            setError(error.response.data.message);
          },
          () => {
            setError(error.message);
          },
        );
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  return (
    <Dialog open={visible} size='xs' handler={setVisible}>
      <DialogHeader>Envoyer un document</DialogHeader>
      <DialogBody divider>
        {error && <AlertError text={error} />}
        <p>{question}</p>
        <InputFile
          onChange={(titre, base64) => {
            setTitre(titre);
            setFichier(base64);
            setError(null);
          }}
        />
      </DialogBody>
      <DialogFooter>
        <Button
          variant='text'
          color='gray'
          onClick={() => {
            setTitre('');
            setFichier('');
            setError(null);
            setVisible(false);
          }}
          className='mr-1 !rounded-full'
          disabled={apiIsLoading}>
          Annuler
        </Button>
        <Button
          variant='filled'
          color='orange'
          onClick={() => {
            handleEnvoie();
          }}
          disabled={apiIsLoading}>
          Confirmer
        </Button>
      </DialogFooter>
      {apiIsLoading ? <LoadingAbsolute /> : ''}
    </Dialog>
  );
};

import { Dialog, DialogHeader, DialogBody, DialogFooter, Button } from '@material-tailwind/react';
import { FunctionComponent, useState } from 'react';
import toast from 'react-hot-toast';
import { useApi } from '../../../providers/ApiProvider';
import { GoodApiResponse, ErrorApiResponse } from '../../../types/api';
import { Invitation } from '../../../types/EntreprisesRessources';
import { InputText } from '../../Inputs/InputText';
import { LoadingAbsolute } from '../../LoadingAbsolute';
import { handleError } from '../../../utils/ErrorHandler';
import { AlertInfo } from '../../Alertes';

/**
 * Ce composant permet d'afficher une Modale pour qu'un salarié puisse rejoindre leur entreprise'.
 *
 * @param visible - Précise si la modale et ouverte ou non. Provient du parent.
 * @param setVisible - Accesseur qui modifie la visibilité de la modale. Provient du parent.
 * @param handleIndex - Callback qui permet de recharger les données du composant parent. Provient du parent.
 */
export const ModalInvitation: FunctionComponent<{
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  handleIndex?: () => void;
}> = ({ visible, setVisible, handleIndex }) => {
  /**
   * On stocke si on est en train d'utiliser l'API ou non.
   */
  const [apiIsLoading, setApiIsLoading] = useState(false);

  /**
   * Numéro de SIRET de l'entreprise que l'on soite joindre.
   */
  const [siretInvitation, setSiretInvitation] = useState<string>('');

  /**
   * On stocke l'eventuelle erreur.
   */
  const [error, setError] = useState<string | null>(null);

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  const handleInvite = (siretInvitation: string) => {
    setApiIsLoading(true);

    client
      .post<GoodApiResponse>(`/invitations/invite`, { siret: siretInvitation })
      .then(() => {
        setSiretInvitation('');
        setError(null);
        setVisible(false);
        toast.success(`Invitation envoyée`);
        if (handleIndex) {
          handleIndex();
        }
      })
      .catch((error: ErrorApiResponse<Invitation>) => {
        handleError(
          error,
          () => {
            setError(error.response.data.message);
          },
          () => {
            setError(error.message);
          },
        );
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  return (
    <Dialog open={visible} size='xs' handler={setVisible}>
      <DialogHeader>Envoyer une invitation</DialogHeader>
      <DialogBody divider>
        <AlertInfo text="Cette action va envoyer une invitation d'affiliation à l'entreprise." />
        <p className='text-sm mb-2'>Veuillez saisir le SIRET de l'entreprise à inviter</p>
        <InputText
          value={siretInvitation}
          onChange={(e) => {
            setSiretInvitation(e.target.value);
          }}
        />
        {error && <p className='text-sm text-red-500'>{error}</p>}
      </DialogBody>
      <DialogFooter>
        <Button
          variant='text'
          color='gray'
          onClick={() => {
            setSiretInvitation('');
            setError(null);
            setVisible(false);
          }}
          className='mr-1 !rounded-full'
          disabled={apiIsLoading}>
          Annuler
        </Button>
        <Button
          variant='filled'
          color='orange'
          onClick={() => {
            handleInvite(siretInvitation);
          }}
          disabled={apiIsLoading}>
          Confirmer
        </Button>
      </DialogFooter>
      {apiIsLoading ? <LoadingAbsolute /> : ''}
    </Dialog>
  );
};

import { CheckIcon, ExclamationTriangleIcon, InformationCircleIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { FunctionComponent } from 'react';

export const AlertInfo: FunctionComponent<{ text: string | JSX.Element }> = ({ text }) => (
  <p className='p-3 bg-blue-50 rounded-lg text-sm mb-4 poppins-semibold text-secondary-1 flex flex-row items-center gap-2'>
    <InformationCircleIcon className='w-5 h-5 flex-none' strokeWidth={2.2} />
    <span className='leading-4'>{text}</span>
  </p>
);

export const AlertWarning: FunctionComponent<{ text: string | JSX.Element }> = ({ text }) => (
  <p className='p-3 bg-amber-200 rounded-lg text-sm mb-4 poppins-semibold text-secondary-1 flex flex-row items-center gap-2'>
    <ExclamationTriangleIcon className='w-5 h-5 flex-none' strokeWidth={2.2} />
    <span className='leading-4'>{text}</span>
  </p>
);

export const AlertSuccess: FunctionComponent<{ text: string | JSX.Element }> = ({ text }) => (
  <p className='p-3 bg-green-100 rounded-lg text-sm mb-4 poppins-semibold text-secondary-1 flex flex-row items-center gap-2'>
    <CheckIcon className='w-5 h-5 flex-none' strokeWidth={2.2} />
    <span className='leading-4'>{text}</span>
  </p>
);

export const AlertError: FunctionComponent<{ text: string | JSX.Element }> = ({ text }) => (
  <p className='p-3 bg-red-100 rounded-lg text-sm mb-4 poppins-semibold text-secondary-1 flex flex-row items-center gap-2'>
    <XCircleIcon className='w-5 h-5 flex-none' strokeWidth={2.2} />
    <span className='leading-4'>{text}</span>
  </p>
);

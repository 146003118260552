import { FunctionComponent, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * Ce composant permet de simuler une remonter en haut de page.
 */
export const ScrollToTop: FunctionComponent = () => {
  /**
   * On a besoin du chemin pour savoir quand on change de page.
   */
  const { pathname } = useLocation();

  /**
   * Cet effet ramène l'utilisateur en haut de la page qui s'affiche dès que l'on en change.
   */
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

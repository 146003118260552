import { FunctionComponent } from 'react';
import { Avatar } from './Avatar';

export const UserDisplay: FunctionComponent<{
  user?: any | null;
}> = ({ user = null }) => {
  if (user) {
    return (
      <div className='flex flex-row gap-2 items-center text-xs text-gray-800'>
        <div className='flex-none'>
          <Avatar src={user.image_avatar as string} />
        </div>
        <div className='montserrat'>{`${user.prenom} ${user.nom}`}</div>
      </div>
    );
  } else {
    return <div></div>;
  }
};

import { FunctionComponent } from 'react';

export const DatesDisplay: FunctionComponent<{
  date_start?: string | null;
  date_end?: string | null;
  className?: string | undefined;
}> = ({ date_start = null, date_end = null, className = '' }) => {
  if (date_start && date_end) {
    if ((date_start as string) === (date_end as string)) {
      return (
        <span className={className}>
          le&nbsp;
          {new Intl.DateTimeFormat('default', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          }).format(new Date(date_start as string))}
        </span>
      );
    } else {
      return (
        <span className={className}>
          du&nbsp;
          {new Intl.DateTimeFormat('default', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          }).format(new Date(date_start as string))}{' '}
          au&nbsp;
          {new Intl.DateTimeFormat('default', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          }).format(new Date(date_end as string))}
        </span>
      );
    }
  } else {
    return <span>Date non précisée</span>;
  }
};

import { ArrowRightIcon } from '@heroicons/react/24/outline';
import { Dialog } from '@material-tailwind/react';
import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

/**
 * Ce composant permet d'afficher une Modale pour proposer à l'utilisateur de s'authentifier.
 *
 * @param visible - Précise si la modale et ouverte ou non. Provient du parent.
 * @param setVisible - Accesseur qui modifie la visibilité de la modale. Provient du parent.

 */
export const ModaleEspaceClient: FunctionComponent<{
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ visible, setVisible }) => {
  return (
    <Dialog open={visible} size='lg' handler={setVisible}>
      <div
        className='rounded-md px-6 py-6 md:px-14 md:py-10 lg:px-24 lg:py-24 isolate bg-secondary-1 bg-opacity-90 bg-blend-multiply bg-cover bg-center bg-no-repeat'
        style={{
          backgroundImage: `url("/assets/new-espace-client.webp")`,
        }}>
        <div className='rounded-md bg-white p-6'>
          <h3 className='text-2xl poppins-bold tracking-tight text-secondary-1'>Créer ou se connecter à son espace client</h3>
          <div className='mt-2 leading-tight text-sm'>
            <span className='montserrat-semibold'>Pour consulter les formations</span> dans leur intégralité et{' '}
            <span className='montserrat-semibold'>connaître les sessions à venir</span>, vous devez avoir au préalable créé un compte. Vous aurez alors un
            espace dédié à votre projet professionnel, ou à votre entreprise, et tous les outils nécessaires à chaque étape de votre parcours.
          </div>
          <div className='mt-4 flex flex-col md:flex-row justify-start gap-4'>
            <Link
              to='/inscription'
              className='inline-flex items-center justify-center rounded-full border border-transparent bg-primary px-3 text-sm poppins-semibold text-white'>
              Créer son compte
              <ArrowRightIcon className=' pl-3 w-8 h-8' />
            </Link>
            <Link
              to='/connexion'
              className='inline-flex items-center justify-center rounded-full border border-primary bg-white px-3 text-sm poppins-semibold text-primary hover:bg-primary/10'>
              Se connecter
              <ArrowRightIcon className=' pl-3 w-8 h-8' />
            </Link>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

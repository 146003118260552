import { FunctionComponent, useEffect, useState } from 'react';
import { LoadingAbsolute } from '../../../components/LoadingAbsolute';
import { useApi } from '../../../providers/ApiProvider';
import { ErrorApiResponse, GoodApiResponse } from '../../../types/api';
import { InputDate } from '../../../components/Inputs/InputDate';
import { Link } from 'react-router-dom';
import { handleError } from '../../../utils/ErrorHandler';
import { Log } from '../../../types/AdminRessources';
import { ConstTypeProfil } from '../../../components/ConstTypeProfil';
import { TypeProfilEnum } from '../../../types/auth';

/**
 * Ce composant affiche la liste des avis côté administrateur.
 */
export const AdminLogsScreen: FunctionComponent = () => {
  /**
   * On stocke si on est en train d'utiliser l'API ou non.
   */
  const [apiIsLoading, setApiIsLoading] = useState(false);

  /**
   * Date du jour.
   * @constant
   */
  const currentDate = new Date();

  /**
   * Date d'il y a 7 jour.
   * @constant
   */
  const sevenDayAgo = new Date(new Date().setDate(currentDate.getDate() - 7));

  /**
   * État local qui gère le champ de la date de départ de la recherche de logs.
   * @defaultValue La value stocké dans l'état local ou la date du jour au format MySQL
   */
  const [from, setFrom] = useState<string>(
    localStorage.getItem(`AdminLogsScreen-From`) ||
      `${sevenDayAgo.getFullYear()}-${sevenDayAgo.getMonth() < 9 ? `0${sevenDayAgo.getMonth() + 1}` : sevenDayAgo.getMonth() + 1}-${
        sevenDayAgo.getDate() < 10 ? `0${sevenDayAgo.getDate()}` : sevenDayAgo.getDate()
      }`,
  );

  /**
   * État local qui gère le champ de la date de départ de la recherche de logs'.
   * @defaultValue La value stocké dans l'état local ou la date du jour au format MySQL
   */
  const [to, setTo] = useState<string>(
    localStorage.getItem(`AdminLogsScreen-To`) ||
      `${currentDate.getFullYear()}-${currentDate.getMonth() < 9 ? `0${currentDate.getMonth() + 1}` : currentDate.getMonth() + 1}-${
        currentDate.getDate() < 10 ? `0${currentDate.getDate()}` : currentDate.getDate()
      }`,
  );

  /**
   * État local pour stocker les logs.
   */
  const [logs, setLogs] = useState<Log[] | null>(null);

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  /**
   * Retrouve les logs au chargement du composant ou à chaque changement de paramètres.
   */
  useEffect(() => {
    if (from && to) {
      handleIndex();
    }
  }, [client, from, to]);

  /**
   * Retrouve par le réseau les logs et les met dans un état local.
   */
  const handleIndex = () => {
    setApiIsLoading(true);

    client
      .get<GoodApiResponse<Log>>('/admin/logs', { params: { from, to } })
      .then((response) => {
        setLogs(response.data.data as unknown as Log[]);
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  return (
    <div className='relative'>
      <div className='px-4 py-4 md:py-3 bg-gray-100 rounded-tl-lg rounded-tr-lg flex flex-col sm:flex-row gap-7 items-center'>
        <div>
          <p className='text-base sm:text-lg md:text-xl poppins-bold leading-normal text-secondary-1 pl-4'>Logs</p>
        </div>
        <div className='flex flex-col md:flex-row gap-4 items-center'>
          <div className='flex flex-row items-center gap-3'>
            <p className='text-xs text-secondary-1 mb-1'>Du&nbsp;:</p>
            <InputDate
              value={from}
              onChange={(e) => {
                setFrom(e.target.value);
                localStorage.setItem(`AdminLogsScreen-From`, e.target.value);
              }}
            />
          </div>
          <div className='flex flex-row items-center gap-3'>
            <p className='text-xs text-secondary-1 mb-1'>Au&nbsp;:</p>
            <InputDate
              value={to}
              onChange={(e) => {
                setTo(e.target.value);
                localStorage.setItem(`AdminLogsScreen-To`, e.target.value);
              }}
            />
          </div>
        </div>
      </div>
      <div className='container mx-auto bg-white rounded-b-lg'>
        <div className='w-full overflow-x-auto'>
          {apiIsLoading ? <LoadingAbsolute /> : ''}
          {logs && logs.length > 0 ? (
            <table className='table-fixed min-w-full bg-white rounded-b-lg'>
              <thead>
                <tr className='w-full h-12 border-gray-300  border-b py-8'>
                  <th className='text-secondary-1 pl-4 text-left text-sm tracking-normal leading-4'>Utilisateur</th>
                  <th className='text-secondary-1 pl-4 text-center text-sm tracking-normal leading-4'>Profil</th>
                  <th className='text-secondary-1 pl-4 text-center text-sm tracking-normal leading-4'>Contrôleur</th>
                  <th className='text-secondary-1 pl-4 text-center text-sm tracking-normal leading-4 w-24'>action</th>
                  <th className='text-secondary-1 pl-4 text-center text-sm tracking-normal leading-4 w-24'>ID ressource</th>
                  <th className='text-secondary-1 pl-4 text-center text-sm tracking-normal leading-4 w-24'>Créé le</th>
                </tr>
              </thead>
              <tbody>
                {logs.map((log) => (
                  <tr key={log.id} className='border-gray-100 border-b hover:bg-gray-100'>
                    <td className='pl-4 py-3 text-gray-800 text-sm'>
                      <Link to={`/admin/users/editer/${log.user_id}`}>
                        {log.user.prenom} {log.user.nom} &lt;{log.user.email}&gt;
                      </Link>
                    </td>
                    <td className='pl-4 py-3 text-gray-800 text-sm text-center'>
                      <ConstTypeProfil text={log.type_profil as TypeProfilEnum} />
                      {log.type_profil ? ' de ' : ''}
                      {log.entreprise && log.entreprise.enseigne ? log.entreprise.enseigne : ''}
                    </td>
                    <td className='pr-4 py-3 text-gray-800 text-sm text-center'>{log.controller}</td>
                    <td className='pr-4 py-3 text-gray-800 text-sm text-center'>{log.action}</td>
                    <td className='pr-4 py-3 text-gray-800 text-sm text-center'>{log.resource_id ? log.resource_id : ''}</td>
                    <td className='pr-4 py-3 text-gray-800 text-sm text-center'>
                      {log.created_at
                        ? new Intl.DateTimeFormat('default', {
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                            second: 'numeric',
                            hour12: false,
                          }).format(new Date(log.created_at as string))
                        : ''}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className='text-center px-4 py-7'>Il n'y pas de log pour la période choisie.</div>
          )}
        </div>
      </div>
    </div>
  );
};

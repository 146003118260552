import toast from 'react-hot-toast';
import { ErrorApiResponse } from '../types/api';

/**
 * Permet d'attrapper les erreurs de provennant de l'API.
 *
 * @param error - Erreur de l'api.
 * @param gestionErreurClient - Callback à appeler en cas d'erreur API côté
 * @param gestionErreurInconnue
 */
export const handleError = (error: ErrorApiResponse, gestionErreurClient?: () => void, gestionErreurInconnue?: () => void) => {
  try {
    if (error.response && parseInt(error.response.status) >= 500) {
      toast.error('Une erreur est survenue. Veuillez réessayer ultérieurement.');
    } else if (error.response && parseInt(error.response.status) >= 400 && error.response.data.message) {
      toast.error(error.response.data.message);
      if (gestionErreurClient) {
        gestionErreurClient();
      }
    } else {
      toast.error('Erreur de communication avec le serveur. Veuillez réessayer ultérieurement.');
      if (gestionErreurInconnue) {
        gestionErreurInconnue();
      }
    }
  } catch (error) {
    toast.error('Erreur de communication avec le serveur. Veuillez réessayer ultérieurement.');
    console.error(error);
  }
};

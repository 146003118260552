import type { FunctionComponent } from 'react';

/**
 * Ce composant affiche un loader sur la page.
 * Il est souvent utiliser lors des appels API afin de faire comprendre à l'utilisateur que des données sont en train d'être chargées.
 */
export const LoadingAbsolute: FunctionComponent = () => {
  return (
    <div className='absolute top-0 left-0 right-0 bottom-0 z-50 overflow-hidden flex flex-col items-center justify-center'>
      <div className='loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4'></div>
    </div>
  );
};

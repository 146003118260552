import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { InputText } from '../../../components/Inputs/InputText';
import { LoadingAbsolute } from '../../../components/LoadingAbsolute';
import { useApi } from '../../../providers/ApiProvider';
import { InputSwitch } from '../../../components/Inputs/InputSwitch';
import { ModeFormulaire, DropdownRessource, Ressource } from '../../../types';
import { BaseIndexParams, PaginatedApiResponse, ErrorApiResponse, GoodApiResponse } from '../../../types/api';
import { Filiere, Metier } from '../../../types/schematics';
import { InputTextarea } from '../../../components/Inputs/InputTextarea';
import { Config } from '../../../config/config';
import { Helmet } from 'react-helmet';
import { InputImage } from '../../../components/Inputs/InputImage';
import { InputColor } from '../../../components/Inputs/InputColor';
import { handleError } from '../../../utils/ErrorHandler';
import { ArrowUturnLeftIcon, CubeTransparentIcon } from '@heroicons/react/24/outline';
import { Tab, TabPanel, Tabs, TabsBody, TabsHeader } from '../../../components/Onglets';
import { ImageHints } from '../../../components/ImageHints';
import { slugify } from '../../../utils/ChangeText';
import { SubmitSave } from '../../../components/SubmitSave';

type Props = { modeFormulaire: ModeFormulaire };

/**
 * Ce composant doit afficher un formulaire qui à 3 modes de fonctionnement. Les 2 principaux sont la création et l'édition.
 * On a également un mode lecture seule. L'idée c'est de gérer une partie du CRUD.
 * C'est dans ce composant que vont se faire les appels API pour créer, éditer et voir une ressource en particulier.
 *
 * @param modeFormulaire - Mode du formulaire (creer, editer et voir).
 * @returns JSX du composant
 */
export const AdminFiliereFormScreen: FunctionComponent<Props> = ({ modeFormulaire }) => {
  // On initialise les données dont on va avoir besoin sur cette page

  /**
   * On stocke si on est en train d'utiliser l'API ou non.
   */
  const [apiIsLoading, setApiIsLoading] = useState(false);

  /**
   * On stocke l'état des métiers.
   */
  const [metiers, setMetiers] = useState<DropdownRessource[] | null>(null);

  /**
   * On stocke les métiers possédés par la filière.
   */
  const [ownedMetiers, setOwnedMetiers] = useState<DropdownRessource[] | null>(null);

  /**
   * On stocke le nom afin de l'afficher en mode édition et voir.
   */
  const [nom, setNom] = useState<Filiere['nom_commercial']>('');

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  /**
   * Contient un état général des valeurs des champs du formulaire ainsi que les champs qui ont reçu une erreur lors de l'envoi du formulaire.
   */
  const [formInputs, setFormInputs] = useState<{ [K in keyof Filiere]: { value: Filiere[K]; error: boolean } }>({
    nom_commercial: { value: '', error: false },
    description_commerciale: { value: null, error: false },
    image_couverture: { value: null, error: false },
    couleur: { value: null, error: false },
    slug: { value: '', error: false },
    est_visible: { value: false, error: false },
    created_at: { value: null, error: false },
  });

  const [imageCovertureString, setImageCovertureString] = useState<Filiere['image_couverture'] | null>(null);

  /**
   * Permet au composant de changer de page.
   */
  const navigate = useNavigate();

  const params = useParams();

  // On a besoin du useEffect lorsque l'on est en mode édition et lecture pour précharger le formulaire avec les données de la ressource.

  useEffect(() => {
    switch (modeFormulaire) {
      case ModeFormulaire.creer:
        break;
      case ModeFormulaire.voir:
      case ModeFormulaire.editer:
        if (params.id === undefined) {
          navigate('/admin/filieres');
        }
        handleShow(parseInt(params.id as string));
        handleIndex();
        break;

      default:
        break;
    }
  }, [client]);

  /**
   * Retrouve Les ressources nécessaires pour afficher les dropdown et les stockent dans des états locaux.
   *
   * @param indexParams - Paramètres de la recherche.
   */
  const handleIndex = () => {
    setApiIsLoading(true);
    const baseParams: BaseIndexParams = { as_foreign: true, no_pagination: true };
    Promise.all([
      client.get<PaginatedApiResponse<Metier>, BaseIndexParams>('/admin/metiers', { params: { ...baseParams, sort: 'nom' } }),
      client.get<PaginatedApiResponse<Metier>>(`/admin/filieres/${parseInt(params.id as string)}/metiers`),
    ])
      .then((response) => {
        setMetiers(response[0].data.data as unknown as DropdownRessource[]);
        setOwnedMetiers(response[1].data.data as unknown as DropdownRessource[]);
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Retrouve par le réseau la ressource liée à l'ID foruni et la met dans un état local.
   * Ne doit être utilisé qu'en mode édition et lecture seule.
   *
   * @param ressourceId - ID de la ressource dont on veut retrouver les informations.
   */
  const handleShow = (ressourceId: Ressource['id']) => {
    if (apiIsLoading === true) {
      return;
    }

    setApiIsLoading(true);
    client
      .get<GoodApiResponse<Filiere>>(`/admin/filieres/${ressourceId}`)
      .then((reponse) => {
        const updatedInputs = Object.keys(reponse.data.data).reduce((acc, key) => {
          if (key === 'image_couverture' && reponse.data.data.image_couverture !== null) {
            setImageCovertureString(reponse.data.data.image_couverture);
          }
          return { ...acc, [key]: { value: reponse.data.data[key as keyof Filiere], error: false } };
        }, formInputs);
        setFormInputs(updatedInputs);
        setNom(updatedInputs.nom_commercial.value);
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error, () => {
          navigate('/admin/filieres');
        });
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Poste par le réseau une ressource crée dans le formulaire via un état local.
   * Ne doit être utilisé qu'en mode création.
   */
  const handleStore = () => {
    setApiIsLoading(true);

    let ressource: Partial<Filiere> = {};
    Object.keys(formInputs).forEach((key) => {
      if (formInputs[key as keyof Filiere]?.value !== null && !(key === 'image_couverture' && formInputs.image_couverture.value === imageCovertureString)) {
        ressource = { ...ressource, [key]: formInputs[key as keyof Filiere]?.value };
      }
    });

    client
      .post<GoodApiResponse<Filiere>>('/admin/filieres', ressource)
      .then(() => {
        toast.success(`Création éffectuée`);
        navigate('/admin/filieres');
      })
      .catch((error: ErrorApiResponse<Filiere>) => {
        handleError(error, () => {
          let object = formInputs;
          Object.keys(error.response.data.errors).forEach((key) => {
            if (error.response.data.errors[key as keyof Filiere]) {
              object = { ...object, [key]: { value: formInputs[key as keyof Filiere]?.value, error: true } };
            }
          }, formInputs);
          setFormInputs(object);
        });
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Met à jour, par le réseau, une ressource à partir du formulaire via un état local.
   * Ne doit être utilisé qu'en mode édition.
   *
   * @param ressourceId - ID de la ressource dont on veut modifier les informations.
   */
  const handleUpdate = (ressourceId: Ressource['id']) => {
    setApiIsLoading(true);

    let ressource: Partial<Filiere> = {};
    Object.keys(formInputs).forEach((key) => {
      if (formInputs[key as keyof Filiere]?.value !== null && !(key === 'image_couverture' && formInputs.image_couverture.value === imageCovertureString)) {
        ressource = { ...ressource, [key]: formInputs[key as keyof Filiere]?.value };
      }
    });

    client
      .patch<GoodApiResponse<Filiere>>(`/admin/filieres/${ressourceId}`, ressource)
      .then(() => {
        toast.success(`Modification éffectuée`);
        navigate('/admin/filieres');
      })
      .catch((error: ErrorApiResponse<Filiere>) => {
        handleError(error, () => {
          let object = formInputs;
          Object.keys(error.response.data.errors).forEach((key) => {
            if (error.response.data.errors[key as keyof Filiere]) {
              object = { ...object, [key]: { value: formInputs[key as keyof Filiere]?.value, error: true } };
            }
          }, formInputs);
          setFormInputs(object);
        });
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Cette méthode permet de gérer l'envoi du formulaire en fonction du mode dans lequel on se trouve.
   * L'envoi ne fait rien si on est en mode voir.
   *
   * @param event - Évenement de l'envoie.
   */
  const handleSubmit = (event: { preventDefault: () => void }): void => {
    event.preventDefault();

    if (apiIsLoading === true) {
      return;
    }

    if (modeFormulaire === ModeFormulaire.creer) {
      handleStore();
    }

    if (modeFormulaire === ModeFormulaire.editer) {
      if (params.id !== undefined) {
        handleUpdate(parseInt(params.id as string));
      }
    }
  };

  /**
   * Cette méthode permet de gérer les changements à l'interieur des champs du formulaire et de gérer les valeurs des champs via un état local.
   *
   * @param field - Champs du formulaire dont on doit stocker la valeur et s'il est en erreur.
   */
  const handleInputChange = (field: keyof Filiere) => {
    return (event: { target: { value: string } }) => {
      setFormInputs((prev) => ({
        ...prev,
        [field]: { value: field === 'slug' ? slugify(event.target.value) : event.target.value, error: false },
      }));
    };
  };

  const handleSwitchChange = (field: keyof Filiere) => {
    setFormInputs((prev) => ({
      ...prev,
      [field]: { value: !prev[field as keyof Filiere]?.value, error: false },
    }));
  };

  const handleImageChange = (field: keyof Filiere) => {
    return (imageString: string) => {
      setFormInputs((prev) => ({
        ...prev,
        [field]: { value: imageString, error: false },
      }));
    };
  };

  /**
   * Gère les changement de Métiers.
   *
   * @param metierId - ID du métier dont on soihaite changer l'état pour la filière.
   */
  const handleMetiersAssignation = async (metierId: Metier['id']) => {
    setApiIsLoading(true);
    try {
      if (ownedMetiers?.findIndex((ownedMetier) => ownedMetier.id === metierId) === -1) {
        await client.post(`/admin/filieres/${parseInt(params.id as string)}/metiers/${metierId}`);
        setOwnedMetiers([
          ...(ownedMetiers as DropdownRessource[]),
          { id: metierId, label: (metiers as DropdownRessource[]).find((metier) => metier.id === metierId)?.label as string },
        ]);
        toast.success(`Métier assigné à la filière`);
      } else {
        await client.delete(`/admin/filieres/${parseInt(params.id as string)}/metiers/${metierId}`);
        setOwnedMetiers((ownedMetiers as DropdownRessource[]).filter((ownedMetier) => ownedMetier.id !== metierId));
        toast.success(`Métier retiré à la filière`);
      }
    } catch (error: any) {
      handleError(error);
    }
    setApiIsLoading(false);
  };

  /**
   * Permet d'écrire le mot correspondant au mode de fomulaire. Ce mot s'affiche au début du formulaire juste à coté de la ressource.
   *
   * @param modeFormulaire - Mode du formulaire (creer, editer et voir).
   * @returns Mot à afficher correspondant au mode de formulaire.
   * @throws Lance une erreur si aucun mode formulaire n'est fourni.
   */
  const afficheModeFormulaire = (modeFormulaire: ModeFormulaire) => {
    switch (modeFormulaire) {
      case ModeFormulaire.creer:
        return 'Création';
      case ModeFormulaire.editer:
        return 'Édition';
      case ModeFormulaire.voir:
        return 'Voir';

      default:
        throw new Error("Vous n'avez pas choisi un mode de formulaire adéquat.");
    }
  };

  /**
   * Paramètres tabs
   * @constant
   */
  const data = [
    {
      label: 'Métiers',
      value: 'metiers',
    },
  ] as const;

  if (modeFormulaire !== ModeFormulaire.creer && (metiers === null || ownedMetiers === null)) {
    return <Fragment>Chargement...</Fragment>;
  }

  return (
    <Fragment>
      <Helmet>
        <title>
          {afficheModeFormulaire(modeFormulaire)} filière - {Config.app_label}
        </title>
      </Helmet>

      <div className='px-4 py-4 md:py-3 bg-gray-100 rounded-tl-lg rounded-tr-lg'>
        <div className='flex w-11/12 mx-auto xl:w-full xl:mx-0 justify-between'>
          <div className='flex flex-row items-center gap-3'>
            <Link className='flex-none' to='/admin/filieres'>
              <button className='mr-1 py-1 px-6 flex flex-row items-center rounded-full bg-secondary-1/10 text-gray-700'>
                <ArrowUturnLeftIcon className='h-4 w-4 inline mr-2 flex-none' />
                <span>Retour à la liste</span>
              </button>
            </Link>
            <p className='text-base sm:text-lg md:text-xl poppins-bold leading-normal text-secondary-1 pl-4'>{`${afficheModeFormulaire(
              modeFormulaire,
            )} filière ${modeFormulaire !== ModeFormulaire.creer ? nom : ''}`}</p>
          </div>

          <Link to={`/filieres/${formInputs.slug.value}`} className='flex-none ml-3' target='_blank'>
            <button className='mr-1 py-1 px-6 flex flex-row items-center rounded-full bg-secondary-1 text-white'>
              <CubeTransparentIcon className='h-4 w-4 inline mr-2 flex-none' />
              <span>Voir la page de la filière</span>
            </button>
          </Link>
        </div>
      </div>

      <Tabs value='general'>
        <TabsHeader>
          <Tab value='general'>Général</Tab>
          {modeFormulaire !== ModeFormulaire.creer &&
            data.map(({ label, value }) => (
              <Tab key={value} value={value}>
                {label}
              </Tab>
            ))}
        </TabsHeader>
        <TabsBody>
          <TabPanel value='general'>
            <form className='relative' onSubmit={handleSubmit}>
              <div className='bg-white p-5 rounded-b-lg'>
                <div className='container mx-auto bg-white rounded'>
                  <fieldset className='pb-7 mb-7 border-b'>
                    <legend className='block text-left mb-3 text-xl text-secondary-1'>Informations</legend>
                    <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-2 items-center'>
                      <div>
                        <InputText
                          label='Nom commercial'
                          value={formInputs.nom_commercial.value as string}
                          onChange={handleInputChange('nom_commercial')}
                          error={formInputs.nom_commercial.error}
                        />
                      </div>
                      <div>
                        <InputText label='Slug' value={formInputs.slug.value as string} onChange={handleInputChange('slug')} error={formInputs.slug.error} />
                      </div>
                      <div>
                        <InputColor
                          label='Couleur'
                          value={formInputs.couleur.value as string}
                          onChange={handleInputChange('couleur')}
                          error={formInputs.couleur.error}
                        />
                      </div>
                    </div>
                  </fieldset>
                  <fieldset className='pb-7 mb-7 border-b'>
                    <legend className='block text-left mb-3 text-xl text-secondary-1'>Descriptions</legend>
                    <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-2 gap-2'>
                      <div>
                        <InputTextarea
                          label='Description commerciale'
                          value={formInputs.description_commerciale.value as string}
                          onChange={handleInputChange('description_commerciale')}
                          error={formInputs.description_commerciale.error}
                        />
                      </div>
                    </div>
                  </fieldset>
                  <fieldset className='pb-7 mb-7 border-b'>
                    <legend className='block text-left mb-3 text-xl text-secondary-1'>Image</legend>
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-2'>
                      <div>
                        <InputImage label='Image de couverture' imageUrl={imageCovertureString as string} onChange={handleImageChange('image_couverture')} />
                      </div>
                      <div className='pt-6'>
                        <ImageHints />
                      </div>
                    </div>
                  </fieldset>
                  <fieldset className='pb-7 mb-7'>
                    <legend className='block text-left mb-3 text-xl text-secondary-1'>Paramètres</legend>
                    <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-2 items-center'>
                      <div>
                        <InputSwitch
                          label='Est visible'
                          checked={formInputs.est_visible.value !== null ? formInputs.est_visible.value : false}
                          onChange={() => handleSwitchChange('est_visible')}
                        />
                      </div>
                    </div>
                  </fieldset>
                </div>

                <div className='container mx-auto w-11/12 xl:w-full'>
                  <div className='w-full py-4 sm:px-0 bg-white flex justify-end'>{modeFormulaire !== ModeFormulaire.voir && <SubmitSave />}</div>
                </div>
              </div>
              {apiIsLoading ? <LoadingAbsolute /> : ''}
            </form>
          </TabPanel>
          {modeFormulaire !== ModeFormulaire.creer && (
            <TabPanel value='metiers'>
              <div className='relative'>
                <div className='relative columns-1 sm:columns-2 xl:columns-3 gap-2 bg-white p-5 rounded-b-lg'>
                  {metiers && metiers.length > 0 ? (
                    metiers.map((metier) => (
                      <div key={metier.id} className='pb-2 break-inside-avoid'>
                        <InputSwitch
                          label={metier.label}
                          checked={ownedMetiers?.findIndex((ownedMetier) => ownedMetier.id === metier.id) !== -1}
                          onChange={() => handleMetiersAssignation(metier.id)}
                        />
                      </div>
                    ))
                  ) : (
                    <div className='text-center px-4 py-7'>Il n'y pas de metiers pour cette filière.</div>
                  )}
                </div>
              </div>
            </TabPanel>
          )}
        </TabsBody>
      </Tabs>
    </Fragment>
  );
};

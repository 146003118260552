import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { InputDate } from '../../components/Inputs/InputDate';
import { Config } from '../../config/config';
import { useApi } from '../../providers/ApiProvider';
import { GoodApiResponse, ErrorApiResponse } from '../../types/api';
import { Document } from '../../types/SalariesRessources';
import { handleError } from '../../utils/ErrorHandler';
import { useNavigate } from 'react-router-dom';
import { Button, Menu, MenuHandler, MenuItem, MenuList } from '@material-tailwind/react';
import { FormationSessionDisplay } from '../../components/FormationSessionDisplay';
import { DownloadLink } from '../../components/DownloadLink';

/**
 * Ce composant affiche la liste des documents côté salarié.
 */
export const MesDocumentsScreen: FunctionComponent = () => {
  /**
   * On stocke si on est en train d'utiliser l'API ou non.
   */
  const [apiIsLoading, setApiIsLoading] = useState(false);

  /**
   * Date du jour.
   * @constant
   */
  const currentDate = new Date();

  /**
   * Date d'il y a 7 jour.
   * @constant
   */
  const sevenDayAgo = new Date(new Date().setDate(currentDate.getDate() - 7));

  /**
   * État local qui gère le champ de la date de départ de la recherche de document.
   * @defaultValue La value stocké dans l'état local ou la date du jour au format MySQL
   */
  const [from, setFrom] = useState<string>(
    localStorage.getItem(`MesDocumentsScreen-From`) ||
      `${sevenDayAgo.getFullYear()}-${sevenDayAgo.getMonth() < 9 ? `0${sevenDayAgo.getMonth() + 1}` : sevenDayAgo.getMonth() + 1}-${
        sevenDayAgo.getDate() < 10 ? `0${sevenDayAgo.getDate()}` : sevenDayAgo.getDate()
      }`,
  );

  /**
   * État local qui gère le champ de la date de départ de la recherche de document.
   * @defaultValue La value stocké dans l'état local ou la date du jour au format MySQL
   */
  const [to, setTo] = useState<string>(
    localStorage.getItem(`MesDocumentsScreen-To`) ||
      `${currentDate.getFullYear()}-${currentDate.getMonth() < 9 ? `0${currentDate.getMonth() + 1}` : currentDate.getMonth() + 1}-${
        currentDate.getDate() < 10 ? `0${currentDate.getDate()}` : currentDate.getDate()
      }`,
  );

  /**
   * Cet état local ermet de stocker les informations les documents.
   */
  const [documents, setDocuments] = useState<Document[] | null>(null);

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  /**
   * Permet au composant de changer de page.
   */
  const navigate = useNavigate();

  /**
   * Retrouve les Document au chargement du composant ou à chaque changement de paramètres.
   */
  useEffect(() => {
    if (from && to) {
      handleIndex();
    }
  }, [client, from, to]);

  /**
   * Retrouve par le réseau les documents du salarié et les met dans un état local.
   */
  const handleIndex = () => {
    if (apiIsLoading === true) {
      return;
    }

    setApiIsLoading(true);
    client
      .get<GoodApiResponse<Document[]>>(`/salarie/documents`, { params: { from, to } })
      .then((response) => {
        setDocuments(response.data.data);
      })
      .catch((error: ErrorApiResponse<Document[]>) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  return (
    <Fragment>
      <Helmet>
        <title>Mes documents - {Config.app_label}</title>
      </Helmet>
      <div className='px-4 py-4 md:py-3 bg-gray-100 rounded-tl-lg rounded-tr-lg flex flex-col sm:flex-row gap-7 items-center'>
        <div className='sm:flex items-center justify-between'>
          <h1 className='text-base sm:text-lg md:text-xl poppins-bold leading-normal text-secondary-1'>Mes documents</h1>
        </div>
        <div className='flex flex-col md:flex-row gap-4 items-center'>
          <div className='flex flex-row items-center gap-3'>
            <p className='text-xs text-secondary-1 mb-1'>Du&nbsp;:</p>
            <InputDate
              value={from}
              onChange={(e) => {
                setFrom(e.target.value);
                localStorage.setItem(`MesDocumentsScreen-From`, e.target.value);
              }}
            />
          </div>
          <div className='flex flex-row items-center gap-3'>
            <p className='text-xs text-secondary-1 mb-1'>Au&nbsp;:</p>
            <InputDate
              value={to}
              onChange={(e) => {
                setTo(e.target.value);
                localStorage.setItem(`MesDocumentsScreen-To`, e.target.value);
              }}
            />
          </div>
        </div>
      </div>
      <div>
        <div className='bg-white rounded-b-lg w-full overflow-x-auto'>
          {documents && documents.length > 0 ? (
            <table className='table-fixed min-w-full bg-white rounded-b-lg'>
              <thead className='text-sm text-gray-500 text-left border-b'>
                <tr>
                  <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Formation et session</th>
                  <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Fichier</th>
                  <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Créée le</th>
                  <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4 w-[70px]'></th>
                </tr>
              </thead>
              <tbody>
                {documents.map((document) => (
                  <tr
                    key={document.token as string}
                    className=' border-gray-100 border-b hover:bg-gray-100'
                    onDoubleClick={() => navigate(`/mes-documents/${document.token}`)}>
                    <td className='px-4 py-3'>
                      <FormationSessionDisplay formation={document.formation} groupe={document.groupe} />
                    </td>
                    <td className='px-4 py-3 text-gray-800 text-sm'>
                      {document.fichier && <DownloadLink fichier={document.fichier} titre={document.titre} />}
                    </td>
                    <td className='px-4 py-3 text-gray-800 text-sm'>
                      {document.created_at
                        ? new Intl.DateTimeFormat('default', {
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                            second: 'numeric',
                            hour12: false,
                          }).format(new Date(document.created_at as string))
                        : ''}
                    </td>
                    <td className='px-4 py-3 text-secondary-1 text-sm'>
                      <Menu placement='right-start'>
                        <MenuHandler>
                          <Button
                            variant='text'
                            className='w-9 h-9 p-1.5 rounded-full text-gray-500 block mx-auto text-center mr-0 hover:bg-white/100 active:bg-transparent'>
                            <EllipsisVerticalIcon className='h-6 w-6' />
                          </Button>
                        </MenuHandler>
                        <MenuList>
                          <MenuItem onClick={() => navigate(`/mes-documents/${document.token}`)}>Voir</MenuItem>
                        </MenuList>
                      </Menu>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className='text-center px-4 py-7'>Vous n'avez pas de document dans la période choisie.</div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

import { Fragment, FunctionComponent, useState } from 'react';
import { Helmet } from 'react-helmet';
import toast from 'react-hot-toast';
import { LoadingAbsolute } from '../../../components/LoadingAbsolute';
import { Config } from '../../../config/config';
import { useApi } from '../../../providers/ApiProvider';
import { ErrorApiResponse } from '../../../types/api';
import { handleError } from '../../../utils/ErrorHandler';

export const AdminExportScreen: FunctionComponent = () => {
  /**
   * On stocke si on est en train d'utiliser l'API ou non.
   */
  const [apiIsLoading, setApiIsLoading] = useState(false);

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  const handleExport = () => {
    setApiIsLoading(true);

    client
      .post<{ url: string }>(`/admin/export/all`)
      .then((response) => {
        toast.success('Données exportées');
        window.location.href = response.data.url;
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  return (
    <Fragment>
      <Helmet>
        <title>Export des données - {Config.app_label}</title>
      </Helmet>
      <div className='px-4 md:px-5 py-4 md:py-3 bg-white rounded-lg'>
        <div className='sm:flex items-center justify-between'>
          <h1 className='text-base sm:text-lg md:text-xl poppins-bold leading-normal text-secondary-1'>Export des données</h1>
        </div>
        <div className='pt-4 flex flex-row gap-4'>
          <button
            className='bg-primary uppercase text-xs poppins-bold text-white py-2 px-5 rounded-full shadow-sm hover:bg-secondary-1'
            onClick={() => handleExport()}>
            Exporter les données
          </button>
        </div>
        {apiIsLoading ? <LoadingAbsolute /> : ''}
      </div>
    </Fragment>
  );
};

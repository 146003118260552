import { Fragment, FunctionComponent } from 'react';
import { DashboardStatsPopulaires } from '../../types/AdminRessources';

/**
 * Ce composant est un encart du tableau de bord administrateur concernant les visites de filières.
 *
 * @param visitesFilieres - Liste des visites de filières.
 */
export const DashboardAdminVisitesFilieres: FunctionComponent<{
  visitesFilieres: DashboardStatsPopulaires[];
}> = ({ visitesFilieres }) => {
  return (
    <Fragment>
      {visitesFilieres.map((visiteFiliere) => (
        <div key={visiteFiliere.id} className='text-secondary-1 flex flex-row items-center justify-between gap-2 border-b last:border-none py-2'>
          <h6 className='montserrat-semibold'>{visiteFiliere.nom}</h6>
          <span className='text-sm'>{visiteFiliere.compte} visites</span>
        </div>
      ))}
    </Fragment>
  );
};

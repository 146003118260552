import { PhoneIcon } from '@heroicons/react/20/solid';
import { ArrowRightIcon } from '@heroicons/react/24/outline';
import type { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

/**
 * Ce composant est une partie de la fiche formation.
 */
export const CtaHomeFSM: FunctionComponent = () => {
  return (
    <div className='px-11 isolate'>
      <div className='mx-auto max-w-7xl my-14 px-0 md:px-4 lg:px-8'>
        <div className='rounded-md bg-secondary-2 p-4 flex flex-col lg:flex-row gap-4 lg:gap-16 items-center justify-center shadow-md'>
          <div className='grow'>
            <h3 className='text-2xl poppins-semibold tracking-tight text-white'>Formations sur-mesure</h3>
            <div className='mt-2 text-white/80 text-sm pl-2 leading-loose'>
              <p>
                Vous ne trouvez pas exactement la formation que vous cherchez ? Nous vous proposons des formations conçues sur-mesure pour répondre pleinement
                aux enjeux de votre entreprise. Ensemble nous analyserons vos attentes pour construire le dispositif de formation qui répond à vos objectifs.
              </p>
              <p className='poppins-semibold mt-3 text-white'>Vous avez des questions ?</p>
              <p>Nous sommes à votre écoute par téléphone du lundi au vendredi, de 8h à 19h :</p>
              <a className='inline-flex items-center gap-2 text-white' href='tel:0800855777'>
                <PhoneIcon className='w-6 h-6 text-primary' />
                <span className='text-primary'>0800 855 777</span>
                (appel gratuit)
              </a>
            </div>
          </div>
          <div className='flex-none'>
            <Link
              to='https://www.compagnons-du-devoir.com/nous-contacter'
              className='inline-flex items-center justify-center rounded-full bg-white px-3 text-sm poppins-semibold text-primary'>
              Contacter un(e) conseiller(e)
              <ArrowRightIcon className='pl-3 w-8 h-8' />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

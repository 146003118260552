import { FunctionComponent } from 'react';
import { StarEmpty } from './StarEmpty';
import { StarFull } from './StarFull';
import { StarHalf } from './StarHalf';

/**
 * Ce composant affiche un groupe de cinq étoiles et les rempli en fonction de la note.
 *
 * @param note - Note de l'avis sur 5.
 * @param className - Classes tailwind afin d'ajuster et styliser le groupe d'étoiles. Par défaut `'w-4 h-4 text-primary'`.
 */
export const StarGroupDisplay: FunctionComponent<{ note: number; className?: string }> = ({ note, className = 'w-4 h-4 text-primary' }) => (
  <div className='text-sm text-gray-700 flex items-center' title={`${new Intl.NumberFormat('default', { maximumFractionDigits: 1 }).format(note)} / 5`}>
    {note && note >= 1 ? <StarFull className={className} /> : <StarEmpty className={className} />}
    {note && note >= 1.5 ? note && note >= 2 ? <StarFull className={className} /> : <StarHalf className={className} /> : <StarEmpty className={className} />}
    {note && note >= 2.5 ? note && note >= 3 ? <StarFull className={className} /> : <StarHalf className={className} /> : <StarEmpty className={className} />}
    {note && note >= 3.5 ? note && note >= 4 ? <StarFull className={className} /> : <StarHalf className={className} /> : <StarEmpty className={className} />}
    {note && note >= 4.5 ? note && note >= 5 ? <StarFull className={className} /> : <StarHalf className={className} /> : <StarEmpty className={className} />}
  </div>
);

import { FunctionComponent, useState, useEffect, lazy, Suspense } from 'react';
const DashboardAdminCAEstime = lazy(() => import('../../../components/Dashboard/DashboardAdminCAEstime'));
const DashboardAdminDemandes = lazy(() => import('../../../components/Dashboard/DashboardAdminDemandes'));
import { DashboardAdminFormationsPopulaires } from '../../../components/Dashboard/DashboardAdminFormationsPopulaires';
import { DashboardAdminSitesPopulaires } from '../../../components/Dashboard/DashboardAdminSitesPopulaires';
import { LoadingAbsolute } from '../../../components/LoadingAbsolute';
import { useApi } from '../../../providers/ApiProvider';
import { Link } from 'react-router-dom';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { GestionnaireCentreDashboard, ErrorApiResponse } from '../../../types/api';
import { DashboardSynthese } from '../../../types/GestionnaireCentreRessources';
import { DashboardGestionnaireCentreSynthese } from '../../../components/Dashboard/DashboardGestionnaireCentreSynthese';
import { DashboardAdminRecherches } from '../../../components/Dashboard/DashboardAdminRecherches';
import { DashboardAdminVisitesFilieres } from '../../../components/Dashboard/DashboardAdminVisitesFilieres';
import { DashboardAdminVisitesFormations } from '../../../components/Dashboard/DashboardAdminVisitesFormations';
import { DashboardAdminVisitesMetiers } from '../../../components/Dashboard/DashboardAdminVisitesMetiers';
import { handleError } from '../../../utils/ErrorHandler';

export const AdmingcDashboardScreen: FunctionComponent = () => {
  /**
   * On stocke si on est en train d'utiliser l'API ou non.
   */
  const [apiIsLoading, setApiIsLoading] = useState(false);

  /**
   * Stocke les groupe dans un état local.
   */
  const [adminDashboard, setAdminDashboard] = useState<GestionnaireCentreDashboard | null>(null);

  const [compteParJour, setCompteParJour] = useState<{ [key: string]: number }>({});

  const [montantParJour, setMontantParJour] = useState<{ [key: string]: number }>({});

  const [adminDashboardSynthese, setAdminDashboardSynthese] = useState<DashboardSynthese>({
    caestime: 0,
    nbFormations: 0,
  });

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  useEffect(() => {
    handleIndex();
  }, []);

  /**
   * Retrouve des données de L'API et les stocke dans un état local.
   */
  const handleIndex = () => {
    setApiIsLoading(true);

    client
      .get<GestionnaireCentreDashboard>('/admingc/dashboard')
      .then((response) => {
        setAdminDashboard(response.data as GestionnaireCentreDashboard);

        // Partie Chiffre d'affaire.
        const compte: { [key: string]: number } = {};

        const montant: { [key: string]: number } = {};

        let nbFormations = 0;

        let caestime = 0;

        /**
         * Giga boucle qui fait la ventilation de toutes les données à la fois.
         */
        Object.keys(response.data.data.stats_ca_mois_glissant).forEach((jour) => {
          compte[jour] = response.data.data.stats_ca_mois_glissant[jour].compte;
          montant[jour] = response.data.data.stats_ca_mois_glissant[jour].montant / 100;
          nbFormations += compte[jour];
          caestime += response.data.data.stats_ca_mois_glissant[jour].montant;
        });

        setCompteParJour({ ...compte });
        setMontantParJour({ ...montant });

        setAdminDashboardSynthese({
          ...adminDashboardSynthese,
          caestime: caestime / 100,
          nbFormations: nbFormations,
        });
      })
      .catch((error: ErrorApiResponse<GestionnaireCentreDashboard>) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  return (
    <div>
      <Suspense fallback={<div>Chargement...</div>}>
        <div>
          <h2 className='text-sm poppins-medium text-secondary-1 mb-3'>Ces 30 derniers jours</h2>
          <DashboardAdminFormationsPopulaires
            formationsPopulaires={adminDashboard && adminDashboard.data ? adminDashboard.data.formations_populaires_mois_glissant : []}
          />
        </div>
        <div className='flex flex-col md:flex-row items-stretch gap-5 mt-11 pb-7'>
          <div className='basis-4/6'>
            <h2 className='text-sm poppins-medium text-secondary-1 mb-3'>Flux e-commerce</h2>
            <div className='h-full bg-white rounded-md shadow-md p-5'>
              <div className='flex flex-col sm:flex-row items-center justify-between mb-7'>
                <h3 className='text-secondary-2 poppins-semibold text-center md:text-left'>Chiffre d'affaires estimé</h3>
                <Link to='/admingc/statistiques/demandes-paiements' className='text-sm text-secondary-1/90 flex items-center hover:text-primary'>
                  Tout voir
                  <ChevronRightIcon className='w-3 h-3' />
                </Link>
              </div>
              <DashboardAdminCAEstime compte={compteParJour} montant={montantParJour} />
            </div>
          </div>
          <div className='basis-2/6'>
            <h2 className='text-sm poppins-medium text-secondary-1 mb-3'>Synthèse</h2>
            <DashboardGestionnaireCentreSynthese synthese={adminDashboardSynthese} />
          </div>
        </div>
        <h2 className='text-sm poppins-medium text-secondary-1 mb-3 mt-11'>A propos des formations</h2>
        <div className='flex flex-col md:flex-row gap-5'>
          <div className='basis-2/6'>
            <div className='h-full bg-white rounded-md shadow-md p-5'>
              <div className='flex flex-col sm:flex-row items-center justify-between mb-7'>
                <h3 className='text-secondary-2 poppins-semibold text-center md:text-left'>Top recherches</h3>
                <Link to='/admingc/statistiques/visites-sites' className='text-sm text-secondary-1/90 flex items-center hover:text-primary'>
                  Tout voir
                  <ChevronRightIcon className='w-3 h-3' />
                </Link>
              </div>
              <DashboardAdminSitesPopulaires sites={adminDashboard && adminDashboard.data ? adminDashboard.data.stats_sites_mois_glissant : []} />
            </div>
          </div>
          <div className='basis-4/6'>
            <div className='bg-white rounded-md shadow-md p-5'>
              <div className='flex flex-col sm:flex-row items-center justify-between mb-7'>
                <h3 className='text-secondary-2 poppins-semibold text-center md:text-left'>Flux de demandes</h3>
                <Link to='/admingc/statistiques/demandes-formations' className='text-sm text-secondary-1/90 flex items-center hover:text-primary'>
                  Tout voir
                  <ChevronRightIcon className='w-3 h-3' />
                </Link>
              </div>
              <DashboardAdminDemandes demandes={adminDashboard && adminDashboard.data ? adminDashboard.data.stats_demandes_mois_glissant : {}} />
            </div>
          </div>
        </div>
        <h2 className='text-sm poppins-medium text-secondary-1 mb-3 mt-11'>Popularité</h2>
        <div className='flex flex-col md:flex-row gap-5'>
          <div className='basis-1/2'>
            <div className='h-full bg-white rounded-md shadow-md p-5'>
              <div className='flex flex-col sm:flex-row items-center justify-between mb-7'>
                <h3 className='text-secondary-2 poppins-semibold text-center md:text-left'>Top recherches</h3>
                <Link to='/admingc/statistiques/recherches' className='text-sm text-secondary-1/90 flex items-center hover:text-primary'>
                  Tout voir
                  <ChevronRightIcon className='w-3 h-3' />
                </Link>
              </div>
              <DashboardAdminRecherches recherches={adminDashboard && adminDashboard.data ? adminDashboard.data.stats_recherches_mois_glissant : []} />
            </div>
          </div>
          <div className='basis-1/2'>
            <div className='h-full bg-white rounded-md shadow-md p-5'>
              <div className='flex flex-col sm:flex-row items-center justify-between mb-7'>
                <h3 className='text-secondary-2 poppins-semibold text-center md:text-left'>Top formations</h3>
                <Link to='/admingc/statistiques/visites-formations' className='text-sm text-secondary-1/90 flex items-center hover:text-primary'>
                  Tout voir
                  <ChevronRightIcon className='w-3 h-3' />
                </Link>
              </div>
              <DashboardAdminVisitesFormations
                visitesFormations={adminDashboard && adminDashboard.data ? adminDashboard.data.stats_formations_mois_glissant : []}
              />
            </div>
          </div>
        </div>
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 mt-7'>
          <div className='h-full bg-white rounded-md shadow-md p-5'>
            <div className='flex flex-col sm:flex-row items-center justify-between mb-7'>
              <h3 className='text-secondary-2 poppins-semibold text-center md:text-left'>Top filières</h3>
              <Link to='/admingc/statistiques/visites-filieres' className='text-sm text-secondary-1/90 flex items-center hover:text-primary'>
                Tout voir
                <ChevronRightIcon className='w-3 h-3' />
              </Link>
            </div>
            <DashboardAdminVisitesFilieres visitesFilieres={adminDashboard && adminDashboard.data ? adminDashboard.data.stats_filieres_mois_glissant : []} />
          </div>
          <div className='h-full bg-white rounded-md shadow-md p-5'>
            <div className='flex flex-col sm:flex-row items-center justify-between mb-7'>
              <h3 className='text-secondary-2 poppins-semibold text-center md:text-left'>Top métiers</h3>
              <Link to='/admingc/statistiques/visites-metiers' className='text-sm text-secondary-1/90 flex items-center hover:text-primary'>
                Tout voir
                <ChevronRightIcon className='w-3 h-3' />
              </Link>
            </div>
            <DashboardAdminVisitesMetiers visitesMetiers={adminDashboard && adminDashboard.data ? adminDashboard.data.stats_metiers_mois_glissant : []} />
          </div>
          <div className='h-full bg-white rounded-md shadow-md p-5'>
            <div className='flex flex-col sm:flex-row items-center justify-between mb-7'>
              <h3 className='text-secondary-2 poppins-semibold text-center md:text-left'>Top sites</h3>
              <Link to='/admingc/statistiques/visites-sites' className='text-sm text-secondary-1/90 flex items-center hover:text-primary'>
                Tout voir
                <ChevronRightIcon className='w-3 h-3' />
              </Link>
            </div>
          </div>
        </div>
        {apiIsLoading ? <LoadingAbsolute /> : ''}
      </Suspense>
    </div>
  );
};

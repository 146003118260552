import { Fragment, FunctionComponent } from 'react';
import { DashboardStatsPopulaires } from '../../types/AdminRessources';

/**
 * Ce composant est un encart du tableau de bord administrateur concernant les visites de formations.
 *
 * @param visitesFormations - Liste des visites de formations.
 */
export const DashboardAdminVisitesFormations: FunctionComponent<{
  visitesFormations: DashboardStatsPopulaires[];
}> = ({ visitesFormations }) => {
  return (
    <Fragment>
      {visitesFormations.map((visiteFormation) => (
        <div key={visiteFormation.id} className='text-secondary-1 flex flex-row items-center justify-between gap-2 border-b last:border-none py-2'>
          <h6 className='montserrat-semibold basis-4/6 leading-4'>{visiteFormation.nom}</h6>
          <span className='text-sm basis-2/6 text-right'>{visiteFormation.compte} visites</span>
        </div>
      ))}
    </Fragment>
  );
};

import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { Button, Menu, MenuHandler, MenuItem, MenuList } from '@material-tailwind/react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { InputSwitch } from '../../../components/Inputs/InputSwitch';
import { InputText } from '../../../components/Inputs/InputText';
import { LoadingAbsolute } from '../../../components/LoadingAbsolute';
import { useApi } from '../../../providers/ApiProvider';
import { InputTextarea } from '../../../components/Inputs/InputTextarea';
import { AxiosResponse } from 'axios';
import { DiplomePrepare, Formation, Groupe, Matiere, Metier, Personnel, SecteurActivite, Site } from '../../../types/schematics';
import { ModeFormulaire, DropdownRessource, Ressource } from '../../../types';
import { BaseIndexParams, PaginatedApiResponse, GoodApiResponse, ErrorApiResponse } from '../../../types/api';
import { Config } from '../../../config/config';
import { Helmet } from 'react-helmet';
import { InputImage } from '../../../components/Inputs/InputImage';
import { ReadOnlyNumber } from '../../../components/Inputs/ReadOnlyNumber';
import { Avis } from '../../../types/AdminRessources';
import { ArrowUturnLeftIcon, EllipsisVerticalIcon, PresentationChartBarIcon } from '@heroicons/react/24/outline';
import nl2br from 'react-nl2br';
import { Logo } from '../../../components/Logo';
import { ModalModificationAvisAdmin } from '../../../components/Modales/Admin/ModalModificationAvisAdmin';
import { ModalSuppression } from '../../../components/Modales/Admin/ModalSuppression';
import { StarGroupDisplay } from '../../../components/Stars/StarGroupDisplay';
import { Avatar } from '../../../components/Avatar';
import { handleError } from '../../../utils/ErrorHandler';
import { ReadOnlyText } from '../../../components/Inputs/ReadOnlyText';
import { ReadOnlySelect } from '../../../components/Inputs/ReadOnlySelect';
import { ReadOnlyTextarea } from '../../../components/Inputs/ReadOnlyTextarea';
import { ReadOnlyBoolean } from '../../../components/Inputs/ReadOnlyBoolean';
import { Tab, TabPanel, Tabs, TabsBody, TabsHeader } from '../../../components/Onglets';
import { ImageHints } from '../../../components/ImageHints';
import { slugify } from '../../../utils/ChangeText';
import { SubmitSave } from '../../../components/SubmitSave';

type Props = { modeFormulaire: ModeFormulaire };

/**
 * Ce composant doit afficher un formulaire qui à 3 modes de fonctionnement. Les 2 principaux sont la création et l'édition.
 * On a également un mode lecture seule. L'idée c'est de gérer une partie du CRUD.
 * C'est dans ce composant que vont se faire les appels API pour créer, éditer et voir une ressource en particulier.
 *
 * @param modeFormulaire - Mode du formulaire (creer, editer et voir).
 * @returns JSX du composant
 */
export const AdminFormationFormScreen: FunctionComponent<Props> = ({ modeFormulaire }) => {
  // On initialise les données dont on va avoir besoin sur cette page

  /**
   * On stocke si on est en train d'utiliser l'API ou non.
   */
  const [apiIsLoading, setApiIsLoading] = useState(false);

  /**
   * On stocke l'état des formes juridiques.
   */
  const [diplomePrepares, setDiplomePrepares] = useState<DropdownRessource[] | null>(null);

  /**
   * On stocke les groupes possédés par la formation.
   */
  const [ownedGroupes, setOwnedGroupes] = useState<Groupe[] | null>(null);

  /**
   * On stocke les matières possédés par la formation.
   */
  const [ownedMatieres, setOwnedMatieres] = useState<Matiere[] | null>(null);

  /**
   * On stocke les sites possédés par la formation.
   */
  const [ownedSites, setOwnedSites] = useState<Site[] | null>(null);

  /**
   * On stocke l'état des métiers.
   */
  const [metiers, setMetiers] = useState<DropdownRessource[] | null>(null);

  /**
   * On stocke les métiers possédés par la formation.
   */
  const [ownedMetiers, setOwnedMetiers] = useState<DropdownRessource[] | null>(null);

  /**
   * On stocke les avis possédés par la formation.
   */
  const [ownedAvis, setOwnedAvis] = useState<Avis[] | null>(null);

  /**
   * On doit stocker l'ID de la ressource selectionneée pour gérer sa suppression dans la modale
   */
  const [avisSelectionne, setAvisSelectionne] = useState<Avis | null>(null);

  /**
   * Permet d'ouvrir une modale lorsque l'on souhaite supprimer un avis.
   */
  const [modalModificationEstVisible, setModalModificationEstVisible] = useState<boolean>(false);

  /**
   * Permet d'ouvrir une modale lorsque l'on souhaite supprimer un avis.
   */
  const [modalSuppressionEstVisible, setModalSuppressionEstVisible] = useState<boolean>(false);

  /**
   * On stocke le nom afin de l'afficher en mode édition et voir.
   */
  const [nom, setNom] = useState<Formation['nom']>('');

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  /**
   * Contient un état général des valeurs des champs du formulaire ainsi que les champs qui ont reçu une erreur lors de l'envoi du formulaire.
   */
  const [formInputs, setFormInputs] = useState<{ [K in keyof Formation]: { value: Formation[K]; error: boolean } }>({
    diplome_prepare_id: { value: null, error: false },
    personnel_responsable_id: { value: null, error: false },
    secteur_activite_id: { value: null, error: false },
    nom: { value: '', error: false },
    nom_abrege: { value: null, error: false },
    nom_etendu: { value: null, error: false },
    nom_commercial: { value: null, error: false },
    image_couverture: { value: null, error: false },
    slug: { value: '', error: false },
    code_en: { value: null, error: false },
    code_repertoire_specifique: { value: null, error: false },
    niveau: { value: null, error: false },
    duree_mois: { value: null, error: false },
    duree_jours: { value: null, error: false },
    duree_heures: { value: null, error: false },
    prix_vente: { value: null, error: false },
    unites_facturation: { value: null, error: false },
    nom_unites_facturation: { value: null, error: false },
    mode_evaluation: { value: null, error: false },
    description: { value: null, error: false },
    description_commerciale: { value: null, error: false },
    description_seo: { value: null, error: false },
    texte_objectifs: { value: null, error: false },
    texte_contenu: { value: null, error: false },
    texte_methodologie: { value: null, error: false },
    texte_modalites_evaluation: { value: null, error: false },
    texte_modalites_access: { value: null, error: false },
    texte_public: { value: null, error: false },
    texte_prerequis: { value: null, error: false },
    texte_accessibilite: { value: null, error: false },
    est_cpf: { value: false, error: false },
    est_certifiant: { value: false, error: false },
    est_ecommerce: { value: false, error: false },
    est_riseup: { value: false, error: false },
    est_visible: { value: false, error: false },
    yp_code: { value: null, error: false },
    created_at: { value: null, error: false },
  });

  const [imageCovertureString, setImageCovertureString] = useState<Formation['image_couverture'] | null>(null);

  /**
   * Permet au composant de changer de page.
   */
  const navigate = useNavigate();

  const params = useParams();

  // On a besoin du useEffect lorsque l'on est en mode édition et lecture pour précharger le formulaire avec les données de la ressource.

  useEffect(() => {
    handleIndex();
    switch (modeFormulaire) {
      case ModeFormulaire.creer:
        break;
      case ModeFormulaire.voir:
      case ModeFormulaire.editer:
        if (params.id === undefined) {
          navigate('/admin/formations');
        }
        handleShow(parseInt(params.id as string));
        break;

      default:
        break;
    }
  }, [client]);

  /**
   * Retrouve Les ressources nécessaires pour afficher les dropdown et les stockent dans des liste déroulantes.
   *
   * @param indexParams - Paramètres de la recherche.
   */
  const handleIndex = () => {
    setApiIsLoading(true);
    const baseParams: BaseIndexParams = { as_foreign: true, no_pagination: true };

    const promises: Promise<AxiosResponse>[] = [
      client.get<PaginatedApiResponse<DiplomePrepare>, BaseIndexParams>('/admin/diplomes-prepares', { params: { ...baseParams, sort: 'nom' } }),
      client.get<PaginatedApiResponse<Personnel>, BaseIndexParams>('/admin/personnels', { params: { ...baseParams, sort: 'nom' } }),
      client.get<PaginatedApiResponse<SecteurActivite>, BaseIndexParams>('/admin/secteurs-activite', { params: { ...baseParams, sort: 'nom' } }),
    ];

    if (modeFormulaire !== ModeFormulaire.creer) {
      promises.push(client.get<GoodApiResponse<Groupe>>(`/admin/formations/${parseInt(params.id as string)}/groupes`) as Promise<AxiosResponse>);
      promises.push(client.get<GoodApiResponse<Matiere>>(`/admin/formations/${parseInt(params.id as string)}/matieres`) as Promise<AxiosResponse>);
      promises.push(client.get<GoodApiResponse<Site>>(`/admin/formations/${parseInt(params.id as string)}/sites`) as Promise<AxiosResponse>);
      promises.push(
        client.get<PaginatedApiResponse<Metier>, BaseIndexParams>('/admin/metiers', { params: { ...baseParams, sort: 'nom' } }) as Promise<AxiosResponse>,
      );
      promises.push(client.get<PaginatedApiResponse<Metier>>(`/admin/formations/${parseInt(params.id as string)}/metiers`) as Promise<AxiosResponse>);
      promises.push(client.get<PaginatedApiResponse<Avis>>(`/admin/formations/${parseInt(params.id as string)}/avis`) as Promise<AxiosResponse>);
    }

    Promise.all(promises)
      .then((response) => {
        setDiplomePrepares(response[0].data.data as unknown as DropdownRessource[]);
        if (modeFormulaire !== ModeFormulaire.creer) {
          setOwnedGroupes(response[3].data.data as unknown as Groupe[]);
          setOwnedMatieres(response[4].data.data as unknown as Matiere[]);
          setOwnedSites(response[5].data.data as unknown as Site[]);
          setMetiers(response[6].data.data as unknown as DropdownRessource[]);
          setOwnedMetiers(response[7].data.data as unknown as DropdownRessource[]);
          setOwnedAvis(response[8].data.data as unknown as Avis[]);
        }
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Retrouve par le réseau la ressource liée à l'ID foruni et la met dans un état local.
   * Ne doit être utilisé qu'en mode édition et lecture seule.
   *
   * @param ressourceId - ID de la ressource dont on veut retrouver les informations.
   */
  const handleShow = (ressourceId: Ressource['id']) => {
    if (apiIsLoading === true) {
      return;
    }

    setApiIsLoading(true);
    client
      .get<GoodApiResponse<Formation>>(`/admin/formations/${ressourceId}`)
      .then((reponse) => {
        const updatedInputs = Object.keys(reponse.data.data).reduce((acc, key) => {
          if (key === 'image_couverture' && reponse.data.data.image_couverture !== null) {
            setImageCovertureString(reponse.data.data.image_couverture);
          }
          if (key === 'prix_vente' && reponse.data.data.prix_vente !== null) {
            return { ...acc, [key]: { value: parseFloat((reponse.data.data.prix_vente / 100).toFixed(2)), error: false } };
          } else {
            return { ...acc, [key]: { value: reponse.data.data[key as keyof Formation], error: false } };
          }
        }, formInputs);
        setFormInputs(updatedInputs);
        setNom(updatedInputs.nom.value);
      })
      .catch((error: ErrorApiResponse<Formation>) => {
        handleError(error, () => {
          navigate('/admin/formations');
        });
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Poste par le réseau une ressource crée dans le formulaire via un état local.
   * Ne doit être utilisé qu'en mode création.
   */
  const handleStore = () => {
    setApiIsLoading(true);

    /**
     * On transforme un unpeu les données avant de les envoyer, notament pour le prix de vente.
     */
    let ressource: Partial<Formation> = {};
    Object.keys(formInputs).forEach((key) => {
      if (formInputs[key as keyof Formation]?.value !== null && !(key === 'image_couverture' && formInputs.image_couverture.value === imageCovertureString)) {
        if (key === 'prix_vente') {
          ressource = { ...ressource, prix_vente: Math.round(parseFloat((parseFloat(formInputs.prix_vente.value as unknown as string) * 100).toFixed(2))) };
        } else {
          ressource = { ...ressource, [key]: formInputs[key as keyof Formation]?.value };
        }
      }
    });

    client
      .post<GoodApiResponse<Formation>>('/admin/formations', ressource)
      .then(() => {
        toast.success(`Création éffectuée`);
        navigate('/admin/formations');
      })
      .catch((error: ErrorApiResponse<Formation>) => {
        handleError(error, () => {
          let object = formInputs;
          Object.keys(error.response.data.errors).forEach((key) => {
            if (error.response.data.errors[key as keyof Formation]) {
              object = { ...object, [key]: { value: formInputs[key as keyof Formation]?.value, error: true } };
            }
          }, formInputs);
          setFormInputs(object);
        });
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Met à jour, par le réseau, une ressource à partir du formulaire via un état local.
   * Ne doit être utilisé qu'en mode édition.
   *
   * @param ressourceId - ID de la ressource dont on veut modifier les informations.
   */
  const handleUpdate = (ressourceId: Ressource['id']) => {
    setApiIsLoading(true);

    /**
     * On transforme un unpeu les données avant de les envoyer, notament pour le prix de vente.
     */
    let ressource: Partial<Formation> = {};
    Object.keys(formInputs).forEach((key) => {
      if (formInputs[key as keyof Formation]?.value !== null && !(key === 'image_couverture' && formInputs.image_couverture.value === imageCovertureString)) {
        if (key === 'prix_vente') {
          ressource = { ...ressource, prix_vente: Math.round(parseFloat((parseFloat(formInputs.prix_vente.value as unknown as string) * 100).toFixed(2))) };
        } else {
          ressource = { ...ressource, [key]: formInputs[key as keyof Formation]?.value };
        }
      }
    });

    client
      .patch<GoodApiResponse<Formation>>(`/admin/formations/${ressourceId}`, ressource)
      .then(() => {
        toast.success(`Modification éffectuée`);
        handleShow(parseInt(params.id as string));
      })
      .catch((error: ErrorApiResponse<Formation>) => {
        handleError(error, () => {
          let object = formInputs;
          Object.keys(error.response.data.errors).forEach((key) => {
            if (error.response.data.errors[key as keyof Formation]) {
              object = { ...object, [key]: { value: formInputs[key as keyof Formation]?.value, error: true } };
            }
          }, formInputs);
          setFormInputs(object);
        });
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Cette méthode permet de gérer l'envoi du formulaire en fonction du mode dans lequel on se trouve.
   * L'envoi ne fait rien si on est en mode voir.
   *
   * @param event - Évenement de l'envoie.
   */
  const handleSubmit = (event: { preventDefault: () => void }): void => {
    event.preventDefault();

    if (apiIsLoading === true) {
      return;
    }

    if (modeFormulaire === ModeFormulaire.creer) {
      handleStore();
    }

    if (modeFormulaire === ModeFormulaire.editer) {
      if (params.id !== undefined) {
        handleUpdate(parseInt(params.id as string));
      }
    }
  };

  /**
   * Cette méthode permet de gérer les changements à l'interieur des champs texte du formulaire et de gérer les valeurs des champs via un état local.
   *
   * @param field - Champs du formulaire dont on doit stocker la valeur et s'il est en erreur.
   */
  const handleInputChange = (field: keyof Formation) => {
    return (event: { target: { value: string } }) => {
      setFormInputs((prev) => ({
        ...prev,
        [field]: { value: field === 'slug' ? slugify(event.target.value) : event.target.value, error: false },
      }));
    };
  };
  const handleSwitchChange = (field: keyof Formation) => {
    setFormInputs((prev) => ({
      ...prev,
      [field]: { value: !prev[field as keyof Formation]?.value, error: false },
    }));
  };

  const handleImageChange = (field: keyof Formation) => {
    return (imageString: string) => {
      setFormInputs((prev) => ({
        ...prev,
        [field]: { value: imageString, error: false },
      }));
    };
  };

  /**
   * Gère les changement de Métiers.
   *
   * @param metierId - ID du métier dont on soihaite changer l'état pour la formation.
   */
  /*const handleMetiersAssignation = async (metierId: Metier['id']) => {
    setApiIsLoading(true);
    try {
      if (ownedMetiers?.findIndex((ownedMetier) => ownedMetier.id === metierId) === -1) {
        await client.post(`/admin/formations/${parseInt(params.id as string)}/metiers/${metierId}`);
        setOwnedMetiers([
          ...(ownedMetiers as DropdownRessource[]),
          { id: metierId, label: (metiers as DropdownRessource[]).find((metier) => metier.id === metierId)?.label as string },
        ]);
        toast.success(`Métier assigné à la formation`);
      } else {
        await client.delete(`/admin/formations/${parseInt(params.id as string)}/metiers/${metierId}`);
        setOwnedMetiers((ownedMetiers as DropdownRessource[]).filter((ownedMetier) => ownedMetier.id !== metierId));
        toast.success(`Métier retiré à la formation`);
      }
    } catch (error: any) {
      handleError(error);
    }
    setApiIsLoading(false);
  };*/

  /**
   * Permet d'écrire le mot correspondant au mode de fomulaire. Ce mot s'affiche au début du formulaire juste à coté de la ressource.
   *
   * @param modeFormulaire - Mode du formulaire (creer, editer et voir).
   * @returns Mot à afficher correspondant au mode de formulaire.
   * @throws Lance une erreur si aucun mode formulaire n'est fourni.
   */
  const afficheModeFormulaire = (modeFormulaire: ModeFormulaire) => {
    switch (modeFormulaire) {
      case ModeFormulaire.creer:
        return 'Création';
      case ModeFormulaire.editer:
        return 'Édition';
      case ModeFormulaire.voir:
        return 'Voir';

      default:
        throw new Error("Vous n'avez pas choisi un mode de formulaire adéquat.");
    }
  };

  const handleDestroy = (avisId: Avis['id']) => {
    setApiIsLoading(true);

    client
      .delete<GoodApiResponse>(`/admin/avis/${avisId}`)
      .then(() => {
        setOwnedAvis(ownedAvis && ownedAvis.filter((avi) => avi.id !== avisId));
        toast.success('Avis supprimé.');
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Gère les changement de Métiers.
   *
   * @param metierId - ID du métier dont on soihaite changer l'état pour la formation.
   */
  const handleMetiersAssignation = async (metierId: Metier['id']) => {
    setApiIsLoading(true);
    try {
      if (ownedMetiers?.findIndex((ownedMetier) => ownedMetier.id === metierId) === -1) {
        await client.post(`/admin/formations/${parseInt(params.id as string)}/metiers/${metierId}`);
        setOwnedMetiers([
          ...(ownedMetiers as DropdownRessource[]),
          { id: metierId, label: (metiers as DropdownRessource[]).find((metier) => metier.id === metierId)?.label as string },
        ]);
        toast.success(`Métier associé à la formation`);
      } else {
        await client.delete(`/admin/formations/${parseInt(params.id as string)}/metiers/${metierId}`);
        setOwnedMetiers((ownedMetiers as DropdownRessource[]).filter((ownedMetier) => ownedMetier.id !== metierId));
        toast.success(`Métier désassocié de la formation`);
      }
    } catch (error: any) {
      handleError(error);
    }
    setApiIsLoading(false);
  };

  const handleModalModification = (avi: Avis) => {
    setAvisSelectionne(avi);
    setModalModificationEstVisible(true);
  };

  const handleModalSuppression = (avi: Avis) => {
    setAvisSelectionne(avi);
    setModalSuppressionEstVisible(true);
  };

  //paramètres tabs

  const data = [
    {
      label: 'Sessions',
      value: 'groupes',
    },
    {
      label: 'Matières',
      value: 'matieres',
    },
    {
      label: 'Métiers',
      value: 'metiers',
    },
    {
      label: 'Sites',
      value: 'sites',
    },
    {
      label: 'Avis',
      value: 'avis',
    },
  ];

  if (
    diplomePrepares === null ||
    (modeFormulaire !== ModeFormulaire.creer &&
      (ownedGroupes === null || ownedMatieres === null || ownedSites === null || metiers === null || ownedMetiers === null || ownedAvis === null))
  ) {
    return <Fragment>Chargement...</Fragment>;
  }

  return (
    <Fragment>
      <Helmet>
        <title>
          {afficheModeFormulaire(modeFormulaire)} Formation - {Config.app_label}
        </title>
      </Helmet>

      <div className='px-4 py-4 md:py-3 bg-gray-100 rounded-tl-lg rounded-tr-lg'>
        <div className='flex w-11/12 mx-auto xl:w-full xl:mx-0 justify-between'>
          <div className='flex flex-row items-center gap-3'>
            <Link className='flex-none' to='/admin/formations'>
              <button className='mr-1 py-1 px-6 flex flex-row items-center rounded-full bg-secondary-1/10 text-gray-700'>
                <ArrowUturnLeftIcon className='h-4 w-4 inline mr-2 flex-none' />
                <span>Retour à la liste</span>
              </button>
            </Link>
            <p className='text-base sm:text-lg md:text-xl poppins-bold leading-normal text-secondary-1 pl-4'>{`${afficheModeFormulaire(
              modeFormulaire,
            )} formation ${modeFormulaire !== ModeFormulaire.creer ? nom : ''}`}</p>
          </div>

          <Link to={`/formations/${formInputs.slug.value}`} className='flex-none ml-3' target='_blank'>
            <button className='mr-1 py-1 px-6 flex flex-row items-center rounded-full bg-secondary-1 text-white'>
              <PresentationChartBarIcon className='h-4 w-4 inline mr-2 flex-none' />
              <span>Voir la page de la formation</span>
            </button>
          </Link>
        </div>
      </div>

      <Tabs value='general'>
        <TabsHeader>
          <Tab value='general'>Général</Tab>
          {modeFormulaire !== ModeFormulaire.creer &&
            data.map(({ label, value }) => (
              <Tab key={value} value={value}>
                {label}
              </Tab>
            ))}
        </TabsHeader>
        <TabsBody>
          <TabPanel value='general'>
            <form className='relative' onSubmit={handleSubmit}>
              <div className='bg-white p-5 rounded-b-lg'>
                <div className='container mx-auto bg-white rounded'>
                  <fieldset className='pb-7 mb-7 border-b'>
                    <legend className='block text-left mb-3 text-xl text-secondary-1'>Informations</legend>
                    <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-4 mb-4'>
                      <div className='col-span-2'>
                        <InputText
                          label='Nom commercial'
                          value={formInputs.nom_commercial.value as string}
                          onChange={handleInputChange('nom_commercial')}
                          error={formInputs.nom_commercial.error}
                        />
                      </div>
                      <div>
                        <ReadOnlyText label='Nom Yparéo' value={formInputs.nom.value as string} error={formInputs.nom.error} />
                      </div>
                      <div>
                        <ReadOnlyText label='Nom abrégé' value={formInputs.nom_abrege.value as string} error={formInputs.nom_abrege.error} />
                      </div>
                    </div>
                    <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-4 mb-4'>
                      <div className='col-span-2'>
                        <InputText label='Slug' value={formInputs.slug.value as string} onChange={handleInputChange('slug')} error={formInputs.slug.error} />
                      </div>
                      <div className='col-span-2'>
                        <ReadOnlyText label='Nom étendu' value={formInputs.nom_etendu.value as string} error={formInputs.nom_etendu.error} />
                      </div>
                    </div>
                    <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-4 mb-4'>
                      <div>
                        <ReadOnlySelect
                          label='Diplôme préparé'
                          value={
                            formInputs.diplome_prepare_id.value !== null && formInputs.diplome_prepare_id.value !== undefined
                              ? formInputs.diplome_prepare_id.value
                              : ''
                          }
                          error={formInputs.diplome_prepare_id.error}
                          options={diplomePrepares}
                        />
                      </div>
                    </div>
                    <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-4 mb-4'>
                      <div>
                        <ReadOnlyNumber label='Durée heures' value={formInputs.duree_heures.value as number} error={formInputs.duree_heures.error} />
                      </div>
                    </div>
                    <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-4 mb-4'>
                      <div>
                        <ReadOnlyNumber label='Prix de vente &euro; TTC' value={formInputs.prix_vente.value as number} error={formInputs.prix_vente.error} />
                      </div>
                    </div>
                    <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-4 mb-4'>
                      <div>
                        <ReadOnlyText label='Code EN' value={formInputs.code_en.value as string} error={formInputs.code_en.error} />
                      </div>
                      <div>
                        <ReadOnlyText
                          label='Code répertoire spécifique'
                          value={formInputs.code_repertoire_specifique.value as string}
                          error={formInputs.code_repertoire_specifique.error}
                        />
                      </div>
                      <div>
                        <ReadOnlyNumber label='YParéo CodeFormation' value={formInputs.yp_code.value} error={formInputs.yp_code.error} />
                      </div>
                    </div>
                  </fieldset>
                  <fieldset className='pb-7 mb-7 border-b'>
                    <legend className='block text-left mb-3 text-xl text-secondary-1'>Descriptions</legend>
                    <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-2 gap-2'>
                      <div>
                        <InputTextarea
                          label='Description commerciale'
                          value={formInputs.description_commerciale.value as string}
                          onChange={handleInputChange('description_commerciale')}
                          error={formInputs.description_commerciale.error}
                          rows={10}
                        />
                      </div>
                      <div>
                        <ReadOnlyTextarea label='Description YParéo' value={formInputs.description.value as string} error={formInputs.description.error} />
                      </div>
                      <div>
                        <InputTextarea
                          label='Description SEO'
                          value={formInputs.description_seo.value as string}
                          onChange={handleInputChange('description_seo')}
                          error={formInputs.description_seo.error}
                          maxLength={255}
                          rows={10}
                        />
                      </div>
                      <div>
                        <InputTextarea
                          label='Objectifs de la formation'
                          value={formInputs.texte_objectifs.value as string}
                          onChange={handleInputChange('texte_objectifs')}
                          error={formInputs.texte_objectifs.error}
                          rows={10}
                        />
                      </div>
                      <div>
                        <InputTextarea
                          label='Contenu de la formation'
                          value={formInputs.texte_contenu.value as string}
                          onChange={handleInputChange('texte_contenu')}
                          error={formInputs.texte_contenu.error}
                          rows={10}
                        />
                      </div>
                      <div>
                        <InputTextarea
                          label='Méthode mobilisée'
                          value={formInputs.texte_methodologie.value as string}
                          onChange={handleInputChange('texte_methodologie')}
                          error={formInputs.texte_methodologie.error}
                          rows={10}
                        />
                      </div>
                      <div>
                        <InputTextarea
                          label="Modalité d'évaluation"
                          value={formInputs.texte_modalites_evaluation.value as string}
                          onChange={handleInputChange('texte_modalites_evaluation')}
                          error={formInputs.texte_modalites_evaluation.error}
                          rows={10}
                        />
                      </div>
                      <div>
                        <InputTextarea
                          label="Modalité d'accés"
                          value={formInputs.texte_modalites_access.value as string}
                          onChange={handleInputChange('texte_modalites_access')}
                          error={formInputs.texte_modalites_access.error}
                          rows={10}
                        />
                      </div>
                      <div>
                        <InputTextarea
                          label='Public'
                          value={formInputs.texte_public.value as string}
                          onChange={handleInputChange('texte_public')}
                          error={formInputs.texte_public.error}
                          rows={10}
                        />
                      </div>
                      <div>
                        <InputTextarea
                          label='Prérequis'
                          value={formInputs.texte_prerequis.value as string}
                          onChange={handleInputChange('texte_prerequis')}
                          error={formInputs.texte_prerequis.error}
                          rows={10}
                        />
                      </div>
                      <div>
                        <InputTextarea
                          label='Accessibilité'
                          value={formInputs.texte_accessibilite.value as string}
                          onChange={handleInputChange('texte_accessibilite')}
                          error={formInputs.texte_accessibilite.error}
                          rows={10}
                        />
                      </div>
                    </div>
                  </fieldset>
                  <fieldset className='pb-7 mb-7 border-b'>
                    <legend className='block text-left mb-3 text-xl text-secondary-1'>Image</legend>
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-2'>
                      <div>
                        <InputImage label='Image de couverture' imageUrl={imageCovertureString as string} onChange={handleImageChange('image_couverture')} />
                      </div>
                      <div className='pt-6'>
                        <ImageHints />
                      </div>
                    </div>
                  </fieldset>
                  <fieldset className='pb-7 mb-7'>
                    <legend className='block text-left mb-3 text-xl text-secondary-1'>Paramètres</legend>
                    <div className='grid grid-cols-1 sm:grid-cols-2 items-center'>
                      <div>
                        <div>
                          <ReadOnlyBoolean label='Est e-commerce' value={formInputs.est_ecommerce.value !== null ? formInputs.est_ecommerce.value : false} />
                        </div>
                      </div>
                      <div>
                        <div className='mb-2'>
                          <InputSwitch
                            label='CPF pris en charge'
                            checked={formInputs.est_cpf.value !== null ? formInputs.est_cpf.value : false}
                            onChange={() => handleSwitchChange('est_cpf')}
                          />
                        </div>
                        <div className='mb-2'>
                          <InputSwitch
                            label='Est certifiant'
                            checked={formInputs.est_certifiant.value !== null ? formInputs.est_certifiant.value : false}
                            onChange={() => handleSwitchChange('est_certifiant')}
                          />
                        </div>
                        <div className='mb-2'>
                          <InputSwitch
                            label='Est visible'
                            checked={formInputs.est_visible.value !== null ? formInputs.est_visible.value : false}
                            onChange={() => handleSwitchChange('est_visible')}
                          />
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>
                <div className='container mx-auto w-11/12 xl:w-full'>
                  <div className='w-full py-4 sm:px-0 bg-white flex justify-end'>{modeFormulaire !== ModeFormulaire.voir && <SubmitSave />}</div>
                </div>
              </div>
            </form>
          </TabPanel>
          {modeFormulaire !== ModeFormulaire.creer && (
            <TabPanel value='groupes'>
              <div className='bg-white w-full overflow-x-auto'>
                {ownedGroupes && ownedGroupes.length > 0 ? (
                  <table className='table-fixed min-w-full bg-white rounded-b-lg'>
                    <thead className='text-sm text-gray-500 text-left border-b'>
                      <tr>
                        <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Nom</th>
                        <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Date de début</th>
                        <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Date de fin</th>
                        <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Capacité min</th>
                        <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Nombre d'aprenants</th>
                        <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Capacité max</th>
                        <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Prix de vente &euro; TTC</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(ownedGroupes as Groupe[]).map((groupe) => (
                        <tr key={groupe.id} className=' border-gray-100 border-b hover:bg-gray-100'>
                          <td className='px-4 py-3 text-gray-800 text-sm'>{groupe.nom_commercial}</td>
                          <td className='px-4 py-3 text-gray-800 text-sm'>
                            {groupe.date_debut
                              ? new Intl.DateTimeFormat('default', {
                                  year: 'numeric',
                                  month: 'numeric',
                                  day: 'numeric',
                                }).format(new Date(groupe.date_debut as string))
                              : ''}
                          </td>
                          <td className='px-4 py-3 text-gray-800 text-sm'>
                            {groupe.date_fin
                              ? new Intl.DateTimeFormat('default', {
                                  year: 'numeric',
                                  month: 'numeric',
                                  day: 'numeric',
                                }).format(new Date(groupe.date_fin as string))
                              : ''}
                          </td>
                          <td className='px-4 py-3 text-gray-800 text-sm text-right'>{groupe.capacite_min}</td>
                          <td className='px-4 py-3 text-gray-800 text-sm text-right'>{groupe.nb_apprenants_actifs}</td>
                          <td className='px-4 py-3 text-gray-800 text-sm text-right'>{groupe.capacite_max}</td>
                          <td className='px-4 py-3 text-gray-800 text-sm text-right'>
                            {groupe.prix_vente !== undefined && groupe.prix_vente !== null
                              ? new Intl.NumberFormat('default', { style: 'currency', currency: 'EUR' }).format(groupe.prix_vente / 100)
                              : 'Non renseigné'}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div className='text-center px-4 py-7'>Il n'y pas de session pour cette formation.</div>
                )}
              </div>
            </TabPanel>
          )}
          {modeFormulaire !== ModeFormulaire.creer && (
            <TabPanel value='matieres'>
              <div className='bg-white w-full overflow-x-auto'>
                {ownedMatieres && ownedMatieres.length > 0 ? (
                  <table className='table-fixed min-w-full bg-white rounded-b-lg'>
                    <thead className='text-sm text-gray-500 text-left border-b'>
                      <tr>
                        <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Nom</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ownedMatieres.map((matiere) => (
                        <tr key={matiere.id} className=' border-gray-100 border-b hover:bg-gray-100'>
                          <td className='px-4 py-3 text-gray-800 text-sm'>{matiere.nom}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div className='text-center px-4 py-7'>Il n'y pas de matière pour cette formation.</div>
                )}
              </div>
            </TabPanel>
          )}
          {modeFormulaire !== ModeFormulaire.creer && (
            <TabPanel value='metiers'>
              <div className='columns-1 sm:columns-2 xl:columns-3 gap-2 items-start bg-white p-5 rounded-b-lg'>
                {metiers && metiers.length > 0 ? (
                  metiers.map((metier) => (
                    <div key={metier.id} className='pb-2 break-inside-avoid'>
                      <InputSwitch
                        label={metier.label}
                        checked={ownedMetiers?.findIndex((ownedMetier) => ownedMetier.id === metier.id) !== -1}
                        onChange={() => handleMetiersAssignation(metier.id)}
                      />
                    </div>
                  ))
                ) : (
                  <div className='text-center px-4 py-7'>Il n'y pas de métier pour cette formation.</div>
                )}
              </div>
            </TabPanel>
          )}
          {modeFormulaire !== ModeFormulaire.creer && (
            <TabPanel value='sites'>
              <div className='bg-white w-full overflow-x-auto'>
                {ownedSites && ownedSites.length > 0 ? (
                  <table className='table-fixed min-w-full bg-white rounded-b-lg'>
                    <thead className='text-sm text-gray-500 text-left border-b'>
                      <tr>
                        <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Nom</th>
                        <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Slug</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ownedSites.map((site) => (
                        <tr key={site.id} className=' border-gray-100 border-b hover:bg-gray-100'>
                          <td className='px-4 py-3 text-gray-800 text-sm'>{site.nom_commercial}</td>
                          <td className='px-4 py-3 text-gray-800 text-sm'>{site.slug}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div className='text-center px-4 py-7'>Il n'y pas de site pour cette formation.</div>
                )}
              </div>
            </TabPanel>
          )}

          {modeFormulaire !== ModeFormulaire.creer && (
            <TabPanel value='avis'>
              <div className='bg-white w-full overflow-x-auto'>
                {ownedAvis && ownedAvis.length > 0 ? (
                  <table className='table-fixed min-w-full bg-white rounded-b-lg'>
                    <thead className='text-sm text-gray-500 text-left border-b'>
                      <tr>
                        <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Entreprise</th>
                        <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Salarié</th>
                        <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Note</th>
                        <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Description</th>
                        <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Date de validation</th>
                        <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'></th>
                      </tr>
                    </thead>
                    <tbody>
                      {(ownedAvis as Avis[]).map((avi) => (
                        <tr
                          key={avi.id}
                          className={`border-gray-100 border-b hover:bg-gray-100 cursor-pointer`}
                          onDoubleClick={() => handleModalModification(avi)}>
                          <td className='px-4 py-3 text-gray-800 text-sm'>
                            {avi.entreprise && (
                              <Fragment>
                                <div className='flex flex-row gap-2 items-center'>
                                  <div>
                                    <Logo className='rounded h-10 w-10 object-cover' src={avi.entreprise.image_logo} />
                                  </div>
                                  <div>{`${avi.entreprise.enseigne}`}</div>
                                </div>
                              </Fragment>
                            )}
                          </td>
                          <td className='pr-4 py-3 text-gray-800 text-sm'>
                            {avi.user && (
                              <div className='flex flex-row gap-2 items-center'>
                                <div>
                                  <Avatar src={avi.user.image_avatar as string} />
                                </div>
                                <div>{`${avi.user.prenom} ${avi.user.nom}`}</div>
                              </div>
                            )}
                          </td>
                          <td className='px-4 py-3 text-gray-800 text-sm'>
                            <StarGroupDisplay note={avi.note} />
                          </td>
                          <td className='px-4 py-3 text-gray-800 text-sm line-clamp-3'>{nl2br(avi.description)}</td>
                          <td className='px-4 py-3 text-gray-800 text-sm'>
                            {avi.date_validation
                              ? new Intl.DateTimeFormat('default', {
                                  year: 'numeric',
                                  month: 'numeric',
                                  day: 'numeric',
                                  hour: 'numeric',
                                  minute: 'numeric',
                                  second: 'numeric',
                                  hour12: false,
                                }).format(new Date(avi.date_validation as string))
                              : 'Pas encore validé'}
                          </td>
                          <td className='px-4 py-3 text-gray-800 text-sm'>
                            <Menu placement='right-start'>
                              <MenuHandler>
                                <Button
                                  variant='text'
                                  className='w-9 h-9 p-1.5 rounded-full text-gray-500 block mx-auto text-center mr-0 hover:bg-white/100 active:bg-transparent'>
                                  <EllipsisVerticalIcon className='h-6 w-6' />
                                </Button>
                              </MenuHandler>
                              <MenuList>
                                <MenuItem onClick={() => handleModalModification(avi)}>Consulter</MenuItem>
                                <MenuItem onClick={() => handleModalSuppression(avi)}>Supprimer</MenuItem>
                              </MenuList>
                            </Menu>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div className='text-center px-4 py-7'>Il n'y pas d'avis pour cette formation.</div>
                )}
              </div>
            </TabPanel>
          )}
        </TabsBody>
      </Tabs>
      <ModalModificationAvisAdmin
        visible={modalModificationEstVisible}
        setVisible={setModalModificationEstVisible}
        avis={avisSelectionne as Avis}
        handleIndex={handleIndex}
      />
      <ModalSuppression
        visible={modalSuppressionEstVisible}
        setVisible={setModalSuppressionEstVisible}
        confirmer={() => handleDestroy(avisSelectionne?.id as Avis['id'])}
      />
      {apiIsLoading ? <LoadingAbsolute /> : ''}
    </Fragment>
  );
};

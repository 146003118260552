import { Dialog, DialogHeader, DialogBody, DialogFooter, Button } from '@material-tailwind/react';
import { FunctionComponent, useState } from 'react';
import toast from 'react-hot-toast';
import { useApi } from '../../../providers/ApiProvider';
import { Demande, DemandeEtatAvancement } from '../../../types/AdminRessources';
import { GoodApiResponse } from '../../../types/api';
import { handleError } from '../../../utils/ErrorHandler';
import { AlertError, AlertInfo } from '../../Alertes';
import { InputTextarea } from '../../Inputs/InputTextarea';
import { LoadingAbsolute } from '../../LoadingAbsolute';

/**
 * Ce composant permet d'afficher une Modale pour gérer l'avancement de la demande.
 *
 * @param visible - Précise si la modale et ouverte ou non. Provient du parent.
 * @param setVisible - Accesseur qui modifie la visibilité de la modale. Provient du parent.
 * @param handleShow - Callback qui affiche les informations de la demande dans le composant parent. Provient du parent.
 * @param demandeId - ID de la demande. Provient du parent.
 */
export const ModalMarquerAussiDemandePayee: FunctionComponent<{
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  handleShow: () => void;
  demandeId: Demande['id'];
}> = ({ visible, setVisible, handleShow, demandeId }) => {
  /**
   * On stocke si on est en train d'utiliser l'API ou non.
   */
  const [apiIsLoading, setApiIsLoading] = useState(false);

  /**
   * On stocke les éventuelles informations de paiement.
   */
  const [informationsPaiement, setInformationsPaiement] = useState<string>('');

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  /**
   * On stocke l'eventuelle erreur.
   */
  const [error, setError] = useState<string | null>(null);

  /**
   * Effectue un appel en fonction de l'état d'avancement de la demande
   */
  const handlePayer = async () => {
    setApiIsLoading(true);

    client
      .patch<GoodApiResponse>(`/admin/demandes/${demandeId}/${DemandeEtatAvancement.paye}`, { informations_paiement: informationsPaiement })
      .then(() => {
        setApiIsLoading(false);
        toast.success('Demande également marquée comme réglée');
        handleShow();
        setVisible(false);
      })
      .catch((error) => {
        handleError(
          error,
          () => {
            setError(error.response.data.message);
          },
          () => {
            setError(error.message);
          },
        );
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  return (
    <Dialog open={visible} size='xs' handler={setVisible}>
      <DialogHeader>Demande payée ?</DialogHeader>
      <DialogBody divider>
        {error && <AlertError text={error} />}
        <AlertInfo text='Désirez-vous également marquer la demande liée comme intégralement réglée ?' />
        <p className='text-sm mb-2'>Informations sur le paiement (facultatif)</p>

        <InputTextarea value={informationsPaiement} onChange={(e) => setInformationsPaiement(e.target.value)}></InputTextarea>

        {apiIsLoading ? <LoadingAbsolute /> : ''}
      </DialogBody>
      <DialogFooter>
        <Button
          variant='text'
          color='gray'
          onClick={() => {
            setVisible(false);
          }}
          className='mr-1 !rounded-full'>
          Non
        </Button>
        <Button
          variant='filled'
          color='orange'
          onClick={() => {
            handlePayer();
          }}>
          Oui
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

import { Dialog, DialogHeader, DialogBody, DialogFooter, Button } from '@material-tailwind/react';
import { FunctionComponent, useEffect, useState } from 'react';
import { DashboardAvisAValider } from '../../../types/AdminRessources';
import { StarEmpty } from '../../Stars/StarEmpty';
import { StarFull } from '../../Stars/StarFull';
import { ReadOnlyTextarea } from '../../Inputs/ReadOnlyTextarea';
import { ReadOnlyBoolean } from '../../Inputs/ReadOnlyBoolean';

/**
 * Ce composant permet d'afficher une Modale pour consulter les détails de l'avis.
 *
 * @param visible - Précise si la modale et ouverte ou non. Provient du parent.
 * @param setVisible - Accesseur qui modifie la visibilité de la modale. Provient du parent.
 * @param avis - Avis que l'on souhaite consulter. Provient du parent.
 */
export const ModalConsultationAvis: FunctionComponent<{
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  avis: DashboardAvisAValider;
}> = ({ visible, setVisible, avis }) => {
  /**
   * C'est la note de l'avis.
   */
  const [note, setNote] = useState<DashboardAvisAValider['note'] | null>(null);

  /**
   * C'est le texte de l'avis à proprement parler.
   */
  const [description, setDescription] = useState<DashboardAvisAValider['description']>('');

  /**
   * Indique si on affiche le nom complet de l'utilisateur.
   */
  const [afficheNomComplet, setAfficheNomComplet] = useState<DashboardAvisAValider['affiche_nom_complet']>(false);

  /**
   * Indique si on affiche le nom de l'entreprise de l'utilisateur.
   */
  const [afficheNomEntreprise, setAfficheNomEntreprise] = useState<DashboardAvisAValider['affiche_nom_entreprise']>(false);

  /**
   * Indique si on affiche l'avatar de l'utilisateur.
   */
  const [afficheAvatar, setAfficheAvatar] = useState<DashboardAvisAValider['affiche_avatar']>(false);

  useEffect(() => {
    if (avis) {
      setNote(avis.note);
      setDescription(avis.description);
      setAfficheAvatar(avis.affiche_avatar);
      setAfficheNomComplet(avis.affiche_nom_complet);
      setAfficheNomEntreprise(avis.affiche_nom_entreprise);
    }
  }, [avis]);

  return (
    <Dialog open={visible} size='xs' handler={setVisible}>
      <DialogHeader>Consulter un avis</DialogHeader>
      <DialogBody divider>
        <div className='flex md:flex-row lg:flex-row  md:mb-5'>
          <span className='pr-2'>Note&nbsp;:</span>
          <div>{note && note >= 1 ? <StarFull className={`w-5 h-5`} /> : <StarEmpty className={`w-5 h-5`} />}</div>
          <div>{note && note >= 2 ? <StarFull className={`w-5 h-5`} /> : <StarEmpty className={`w-5 h-5`} />}</div>
          <div>{note && note >= 3 ? <StarFull className={`w-5 h-5`} /> : <StarEmpty className={`w-5 h-5`} />}</div>
          <div>{note && note >= 4 ? <StarFull className={`w-5 h-5`} /> : <StarEmpty className={`w-5 h-5`} />}</div>
          <div>{note && note >= 5 ? <StarFull className={`w-5 h-5`} /> : <StarEmpty className={`w-5 h-5`} />}</div>
        </div>
        <div className='max-h-48 overflow-y-auto mb-5 leading-4 text-sm'>
          <ReadOnlyTextarea value={description} />
        </div>
        <div>
          <ReadOnlyBoolean label='Afficher le nom complet' value={afficheNomComplet} />
        </div>
        <div>
          <ReadOnlyBoolean label='Afficher le nom entreprise' value={afficheNomEntreprise} />
        </div>
        <div>
          <ReadOnlyBoolean label="Afficher l'avatar" value={afficheAvatar} />
        </div>
        <div className='mt-3'>
          <ReadOnlyBoolean label='Est validé' value={false} />
        </div>
      </DialogBody>
      <DialogFooter>
        <Button
          variant='text'
          color='gray'
          onClick={() => {
            setVisible(false);
          }}
          className='mr-1 !rounded-full'>
          Fermer
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

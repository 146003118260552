import { FunctionComponent, useCallback } from 'react';

/**
 * Composant InputSwitch.
 *
 * @param label - Libellé du champ de commutation.
 * @param onChange - Fonction de rappel appelée lorsque la valeur du champ de commutation change.
 * @param checked - Indique si le champ de commutation est sélectionné ou non.
 * @return Composant InputSwitch.
 */
export const InputSwitch: FunctionComponent<{
  label: string;
  onChange?: () => void;
  checked: boolean;
}> = ({ label, onChange, checked }) => {
  /**
   * Gère le changement de valeur dans le composant parent.
   */
  const handleChange = useCallback(() => {
    if (onChange) {
      onChange();
    }
  }, [onChange]);

  return (
    <div className='flex items-start cursor-pointer relative' onClick={handleChange}>
      <div
        className={`flex-none toggle-bg border-2 h-6 w-11 rounded-full flex items-center flex-row px-0.5 ${
          checked ? ' bg-primary border-primary justify-end' : 'bg-gray-300 border-gray-300'
        }`}>
        <div className={`h-4 w-4 rounded-full bg-white border border-gray-200`}></div>
      </div>
      <span className='ml-3 text-gray-900 text-xs font-medium'>{label}</span>
    </div>
  );
};

import { Dialog, DialogHeader, DialogBody, DialogFooter, Button } from '@material-tailwind/react';
import { FunctionComponent, useState } from 'react';
import toast from 'react-hot-toast';
import { useApi } from '../../providers/ApiProvider';
import { GoodApiResponse, ErrorApiResponse } from '../../types/api';
import { Invitation } from '../../types/EntreprisesRessources';
import { InputText } from '../Inputs/InputText';
import { LoadingAbsolute } from '../LoadingAbsolute';
import { handleError } from '../../utils/ErrorHandler';
import { AlertInfo, AlertWarning } from '../Alertes';

/**
 * Ce composant permet d'afficher une Modale pour remplir les information pour la création d'une entreprise.
 *
 * @param visible - Précise si la modale et ouverte ou non. Provient du parent.
 * @param setVisible - Accesseur qui modifie la visibilité de la modale. Provient du parent.
 * @param handleIndex - Callback pour recharger les informations du composant parent.
 */
export const ModalCreationEntreprise: FunctionComponent<{
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  handleIndex?: () => void;
}> = ({ visible, setVisible, handleIndex }) => {
  /**
   * On stocke si on est en train d'utiliser l'API ou non.
   */
  const [apiIsLoading, setApiIsLoading] = useState(false);

  /**
   * On stocke la raison sociale de l'entreprise.
   */
  const [raisonSociale, setRaisonSociale] = useState<string>('');

  /**
   * On stocke le SIRET de l'entreprise.
   */
  const [siretInvitation, setSiretInvitation] = useState<string>('');

  /**
   * On stocke l'eventuelle erreur.
   */
  const [error, setError] = useState<string | null>(null);

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  const handleCreate = (siretInvitation: string) => {
    setApiIsLoading(true);

    client
      .post<GoodApiResponse>(`/profils/entreprise`, { raison_sociale: raisonSociale, siret: siretInvitation })
      .then(() => {
        setRaisonSociale('');
        setSiretInvitation('');
        setError(null);
        setVisible(false);
        toast.success(`Votre entreprise a été créée avec succès.`);
        if (handleIndex) {
          handleIndex();
        }
      })
      .catch((error: ErrorApiResponse<Invitation>) => {
        handleError(
          error,
          () => {
            setError(error.response.data.message);
          },
          () => {
            setError(error.message);
          },
        );
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  return (
    <Dialog open={visible} size='xs' handler={setVisible}>
      <DialogHeader>Création d'entreprise</DialogHeader>
      <DialogBody divider>
        {error && <AlertWarning text={error} />}
        <div>
          <AlertInfo text='Cette action va créer une entreprise et vous désigner comme dirigeant.' />
        </div>
        <div>
          <p className='text-sm mb-2'>Veuillez saisir la raison sociale de l'entreprise à créer</p>
          <InputText
            value={raisonSociale}
            onChange={(e) => {
              setRaisonSociale(e.target.value);
            }}
          />
        </div>
        <div>
          <p className='text-sm mt-5 mb-2'>Veuillez saisir le SIRET de l'entreprise à créer</p>
          <InputText
            value={siretInvitation}
            onChange={(e) => {
              setSiretInvitation(e.target.value);
            }}
          />
        </div>
      </DialogBody>
      <DialogFooter>
        <Button
          variant='text'
          color='gray'
          onClick={() => {
            setRaisonSociale('');
            setSiretInvitation('');
            setError(null);
            setVisible(false);
          }}
          className='mr-1 !rounded-full'
          disabled={apiIsLoading}>
          Annuler
        </Button>
        <Button
          variant='filled'
          color='orange'
          onClick={() => {
            handleCreate(siretInvitation);
          }}
          disabled={apiIsLoading}>
          Confirmer
        </Button>
      </DialogFooter>
      {apiIsLoading ? <LoadingAbsolute /> : ''}
    </Dialog>
  );
};

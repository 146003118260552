import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Config } from '../../config/config';
import { useApi } from '../../providers/ApiProvider';
import { InputImage } from '../../components/Inputs/InputImage';
import { InputText } from '../../components/Inputs/InputText';
import toast from 'react-hot-toast';
import { GoodApiResponse, ErrorApiResponse } from '../../types/api';
import { useNavigate } from 'react-router-dom';
import { UserInfo, UserLocal } from '../../types/auth';
import { useUser } from '../../providers/UserProvider';
import { ReadOnlyEmail } from '../../components/Inputs/ReadOnlyEmail';
import { InputDate } from '../../components/Inputs/InputDate';
import { handleError } from '../../utils/ErrorHandler';
import { SubmitSave } from '../../components/SubmitSave';

/**
 * Ce composant affiche une page contenant les information de l'utilisateur courant.
 */
export const MesInformationsScreen: FunctionComponent = () => {
  /**
   * On a besoin des informations de l'utilisateur et de pouvoir modifier de celui-ci.
   */
  const { user, changeUser } = useUser();

  /**
   * On stocke si on est en train d'utiliser l'API ou non.
   */
  const [apiIsLoading, setApiIsLoading] = useState(false);

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  const navigate = useNavigate();

  /**
   * Contient un état général des valeurs des champs du formulaire ainsi que les champs qui ont reçu une erreur lors de l'envoi du formulaire.
   */
  const [formInputs, setFormInputs] = useState<{ [K in keyof UserInfo]: { value: UserInfo[K]; error: boolean } }>({
    nom: { value: '', error: false },
    prenom: { value: '', error: false },
    date_naissance: { value: null, error: false },
    email: { value: '', error: false },
    email_verified_at: { value: null, error: false },
    image_avatar: { value: null, error: false },
  });

  const [imageAvatarString, setImageAvatarString] = useState<UserInfo['image_avatar'] | null>(null);

  useEffect(() => {
    handleShow();
  }, [client]);

  /**
   * Retrouve par le réseau la ressource liée à l'ID foruni et la met dans un état local.
   * Ne doit être utilisé qu'en mode édition et lecture seule.
   *
   * @param ressourceId - ID de la ressource dont on veut retrouver les informations.
   */
  const handleShow = () => {
    if (apiIsLoading === true) {
      return;
    }

    setApiIsLoading(true);
    client
      .get<GoodApiResponse<UserInfo>>('/user')
      .then((reponse) => {
        const updatedInputs = Object.keys(reponse.data.data).reduce((acc, key) => {
          if (key === 'image_avatar' && reponse.data.data.image_avatar !== null) {
            setImageAvatarString(reponse.data.data.image_avatar);
          }
          return { ...acc, [key]: { value: reponse.data.data[key as keyof UserInfo], error: false } };
        }, formInputs);
        setFormInputs(updatedInputs);
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error, () => {
          navigate('mon-espace');
        });
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Met à jour, par le réseau, une ressource à partir du formulaire via un état local.
   * Ne doit être utilisé qu'en mode édition.
   *
   * @param ressourceId - ID de la ressource dont on veut modifier les informations.
   */
  const handleUpdate = () => {
    setApiIsLoading(true);

    let ressource: Partial<UserInfo> = {};
    Object.keys(formInputs).forEach((key) => {
      if (formInputs[key as keyof UserInfo]?.value !== null && !(key === 'image_avatar' && formInputs.image_avatar.value === imageAvatarString)) {
        ressource = { ...ressource, [key]: formInputs[key as keyof UserInfo]?.value };
      }
    });

    client
      .patch<GoodApiResponse<UserInfo>>(`/informations`, ressource)
      .then((response) => {
        changeUser({
          ...(user as UserLocal),
          nom: response.data.data.nom as UserInfo['nom'],
          prenom: response.data.data.prenom as UserInfo['prenom'],
          image_avatar: response.data.data.image_avatar as UserInfo['image_avatar'],
        });
        toast.success(`Modification éffectuée`);
        navigate('/mon-espace');
      })
      .catch((error: ErrorApiResponse<UserInfo>) => {
        handleError(error, () => {
          let object = formInputs;
          Object.keys(error.response.data.errors).forEach((key) => {
            if (error.response.data.errors[key as keyof UserInfo]) {
              object = { ...object, [key]: { value: formInputs[key as keyof UserInfo]?.value, error: true } };
            }
          }, formInputs);
          setFormInputs(object);
        });
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Cette méthode permet de gérer l'envoi du formulaire en fonction du mode dans lequel on se trouve.
   * L'envoi ne fait rien si on est en mode voir.
   *
   * @param event - Évenement de l'envoie.
   */
  const handleSubmit = (event: { preventDefault: () => void }): void => {
    event.preventDefault();

    if (apiIsLoading === true) {
      return;
    }

    handleUpdate();
  };

  /**
   * Cette méthode permet de gérer les changements à l'interieur des champs du formulaire et de gérer les valeurs des champs via un état local.
   *
   * @param field - Champs du formulaire dont on doit stocker la valeur et s'il est en erreur.
   */
  const handleInputChange = (field: keyof UserInfo) => {
    return (event: { target: { value: string } }) => {
      setFormInputs((prev) => ({
        ...prev,
        [field]: { value: event.target.value, error: false },
      }));
    };
  };

  const handleImageChange = (field: keyof UserInfo) => {
    return (imageString: string) => {
      setFormInputs((prev) => ({
        ...prev,
        [field]: { value: imageString, error: false },
      }));
    };
  };

  return (
    <Fragment>
      <Helmet>
        <title>Mes informations - {Config.app_label}</title>
      </Helmet>
      <div className='px-4 py-4 md:py-3 bg-gray-100 rounded-tl-lg rounded-tr-lg flex flex-col sm:flex-row gap-7 items-center'>
        <h1 className='text-base sm:text-lg md:text-xl poppins-bold leading-normal text-secondary-1'>Mes informations</h1>
      </div>
      <form className='relative' onSubmit={handleSubmit}>
        <div className='bg-white p-5 rounded-b-lg'>
          <div className='container mx-auto bg-white rounded'>
            <fieldset className='pb-7 mb-7 border-b'>
              <legend className='block text-left mb-3 text-xl text-secondary-1'>Identité</legend>
              <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-2 items-center'>
                <div>
                  <InputText label='Prénom' value={formInputs.prenom.value as string} onChange={handleInputChange('prenom')} error={formInputs.prenom.error} />
                </div>
                <div>
                  <InputText label='Nom' value={formInputs.nom.value as string} onChange={handleInputChange('nom')} error={formInputs.nom.error} />
                </div>
                <div>
                  <InputDate
                    label='Date de naissance'
                    value={formInputs.date_naissance.value as string}
                    onChange={handleInputChange('date_naissance')}
                    error={formInputs.date_naissance.error}
                  />
                </div>
                <div>
                  <div>
                    <ReadOnlyEmail label='E-mail' value={formInputs.email.value} estVerifie={true} />
                  </div>
                </div>
              </div>
            </fieldset>
            <fieldset className='pb-7 mb-7 border-b'>
              <legend className='block text-left mb-3 text-xl text-secondary-1'>Photo de profil</legend>
              <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-2'>
                <div>
                  <InputImage imageUrl={imageAvatarString as string} onChange={handleImageChange('image_avatar')} />
                </div>
              </div>
            </fieldset>
          </div>

          <div className='container mx-auto w-11/12 xl:w-full'>
            <div className='w-full py-4 sm:px-0 bg-white flex justify-end'>
              <SubmitSave />
            </div>
          </div>
        </div>
      </form>
    </Fragment>
  );
};

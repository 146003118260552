import type { Config as ConfigType } from '../types';

/**
 * Objet de cofiguration qui va chercher ses valeurs dans un fichier `.env` à la racine du projet.
 *
 * @property api_url - URL de l'API.
 * @property app_name - Nom de l'application.
 * @property app_version - Version de l'application.
 * @property app_label - Libellé de l'application.
 * @property app_description - Description de l'application..
 * @property axeptio_clientId - ID d'axeptio.
 */
export const Config: ConfigType = {
  api_url: process.env.REACT_APP_API_URL as string,
  app_name: process.env.REACT_APP_NAME as string,
  app_version: process.env.REACT_APP_VERSION as string,
  app_label: process.env.REACT_APP_LABEL as string,
  app_description: process.env.REACT_APP_DESCRIPTION as string,
  axeptio_clientId: process.env.REACT_APP_AXEPTIO_CLIENTID as string,
};

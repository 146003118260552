import React, { FunctionComponent, ReactNode } from 'react';

/**
 * Ce composant englobe toute la partie Back-Office.
 *
 * @param children - Contient toute les pages du Back-Office.
 */
export const DashboardContainer: FunctionComponent<{ children?: ReactNode }> = ({ children }) => {
  return (
    <div className='relative h-full 2xl:ml-[19rem] overflow-hidden p-6 mt-7 touch-auto'>
      <div className='container mx-auto w-full min-h-full'>{children}</div>
    </div>
  );
};

import { Dialog, DialogHeader, DialogBody, DialogFooter, Button } from '@material-tailwind/react';
import { FunctionComponent } from 'react';

/**
 * Ce composant permet d'afficher une modale pour que l'administrateur puisse supprimer un devis.
 *
 * @param visible - Précise si la modale et ouverte ou non. Provient du parent.
 * @param setVisible - Accesseur qui modifie la visibilité de la modale. Provient du parent.
 * @param handleSupprime - Callback qui permet de supprimer un devis. Provient du parent.
 */
export const ModalSuppressionDevis: FunctionComponent<{
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  handleSupprime: () => void;
}> = ({ visible, setVisible, handleSupprime }) => {
  return (
    <Dialog open={visible} size='xs' handler={setVisible}>
      <DialogHeader>Suppression du devis</DialogHeader>
      <DialogBody divider>Cette action supprimera le devis. Vous devez avoir la permission de l'entreprise pour cela. Confirmer ?</DialogBody>
      <DialogFooter>
        <Button variant='text' color='gray' onClick={() => setVisible(false)} className='mr-1 !rounded-full'>
          Annuler
        </Button>
        <Button
          variant='filled'
          color='orange'
          onClick={() => {
            handleSupprime();
            setVisible(false);
          }}>
          Confirmer
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

import { ChangeEvent, ChangeEventHandler, Fragment, FunctionComponent, useCallback } from 'react';

/**
 * Composant InputDate.
 *
 * @param label - Libellé du champ de saisie.
 * @param value - Valeur du champ de saisie.
 * @param onChange - Fonction de rappel appelée lorsque la valeur du champ de saisie change.
 * @param error - Indique si le champ de saisie a une erreur.
 * @return Composant InputDate.
 */
export const InputDate: FunctionComponent<{
  error?: boolean;
  label?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  value?: string | number | readonly string[];
}> = ({ label, value, onChange = undefined, error = false }) => {
  /**
   * @constant Elle permet de définir éventuellement un `id` pour le `input` afin que les labels faasent bien référence au champ.
   */
  const inputId = label ? `input-${label.toLowerCase().replace(' ', '-')}` : undefined;

  /**
   * Gère le changement de valeur dans le composant parent.
   *
   * @param e - L'événement de changement de valeur du champ date.
   */
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(e);
      }
    },
    [onChange],
  );

  /**
   * Convertir la valeur de la date en format ISO 8601
   */
  const isoDate = value ? new Date(value.toString()).toISOString().split('T')[0] : '';

  return (
    <Fragment>
      {label && (
        <label className={`text-xs ${error ? 'text-red-500' : ''}`} htmlFor={inputId}>
          {label}
        </label>
      )}
      <input
        className={`relative w-full px-4 py-2 text-sm leading-none text-left text-secondary-1 border border-gray-400 rounded outline-none focus:border-secondary-2 ${
          error ? 'border-red-500' : ''
        }`}
        type='date'
        value={isoDate !== undefined && isoDate !== null ? isoDate : ''}
        onChange={handleChange}
        id={inputId}
      />
    </Fragment>
  );
};

import { ChangeEvent, Fragment, FunctionComponent } from 'react';

/**
 * Composant InputFile.
 *
 * @param label - Libellé du champ de saisie.
 * @param onChange - Fonction de rappel appelée lorsque la valeur du champ de saisie change.
 * @param error - Indique si le champ de saisie a une erreur.
 * @param placeholder - Placeholder du champ de sélection de fichier.
 * @return Composant InputFile.
 */
export const InputFile: FunctionComponent<{
  //defaultValue: string;
  error?: boolean;
  label?: string;
  onChange?: (titre: string, base64: string) => void;
  placeholder?: string;
}> = ({ label, onChange = undefined, error = false, placeholder }) => {
  /**
   * @constant Elle permet de définir éventuellement un `id` pour le `input` afin que les labels faasent bien référence au champ.
   */
  const inputId = label ? `input-${label.toLowerCase().replace(' ', '-')}` : undefined;

  /**
   * Gère le changement de valeur dans le composant parent.
   *
   * @param e - L'événement de changement de valeur du champ de sélection de fichier.
   */
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const readerSending = new FileReader();
    readerSending.onloadend = () => {
      if (onChange) {
        onChange((e.target.files as FileList)[0].name, window.btoa(readerSending.result as string));
      }
    };
    readerSending.readAsBinaryString((e.target.files as FileList)[0]);
  };

  return (
    <Fragment>
      {label && (
        <label className={`text-xs ${error ? 'text-red-500' : ''}`} htmlFor={inputId}>
          {label}
        </label>
      )}
      <input
        className={`relative w-full text-sm leading-none text-left text-secondary-1 outline-none ${error ? 'border-red-500' : ''}`}
        type='file'
        onChange={handleChange}
        id={inputId}
        placeholder={placeholder}
        accept='image/jpeg, image/png, image/webp, application/msword, application/pdf, application/rtf, application/vnd.ms-excel, application/vnd.ms-powerpoint, application/vnd.oasis.opendocument.presentation, application/vnd.oasis.opendocument.spreadsheet, application/vnd.oasis.opendocument.spreadsheet, application/vnd.oasis.opendocument.text, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/x-7z-compressed, application/zip'
      />
    </Fragment>
  );
};

import { Fragment, FunctionComponent } from 'react';
import { TypeProfilEnum } from '../types/auth';
import { typeProfil } from '../utils/ChangeText';

/**
 * Ce composant affiche les statuts d'affiliation de manière stylisé.
 *
 * @param text - Statut d'affiliation de l'utilisateur.
 */
export const ConstTypeProfil: FunctionComponent<{ text: TypeProfilEnum }> = ({ text }) => {
  /**
   * @var Texte transformé.
   */
  const translatedText = typeProfil(text);

  return <Fragment>{translatedText}</Fragment>;
};

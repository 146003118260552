import { FunctionComponent } from 'react';
import { Groupe } from '../types/SalariesRessources';
import { FormationSessionDisplay } from './FormationSessionDisplay';

/**
 * Ce composant affiche les informations d'un groupe.
 *
 * @param groupe - auquel est rattaché un salarié.
 */
export const GroupeCard: FunctionComponent<{ groupe: Groupe }> = ({ groupe }) => (
  <div className='max-w-2xl mx-auto mb-4 rounded-lg bg-white shadow-lg p-5'>
    <FormationSessionDisplay formation={groupe.formation} groupe={groupe} />
  </div>
);

import { type FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

/**
 * Ce composant affiche le pied de la page, côté Front.
 */
export const FooterVitrine: FunctionComponent = () => {
  return (
    <div className='bg-secondary-1 pb-7 poppins'>
      <div className='px-4 pt-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 text-center md:text-left'>
        <div className='grid gap-4 mb-20 md:grid-cols-2 lg:grid-cols-4'>
          <div className='md:max-w-md'>
            <a href='/' aria-label='Accueil' title='Accueil'>
              <img src='/assets/aocdtf-logo-white.svg' className='h-40 mx-auto' />
            </a>
            <a href='https://www.compagnons-du-devoir.com/nous-contacter' className='flex flex-row justify-center'>
              <button className='w-[200px] text-center px-8 py-1 poppins text-white mt-7 border border-white rounded-full hover:border-primary hover:text-primary'>
                Nous contacter
              </button>
            </a>
            <div className='flex gap-4 mt-7 justify-center md:-justify-start'>
              <a href='https://www.facebook.com/CompagnonsDuDevoir'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='currentColor'
                  viewBox='0 0 24.07 24.07'
                  className='w-[20px] aspect-1 fill-white hover:fill-primary'>
                  <path d='m12,24.07C5.37,24.07,0,18.7,0,12.07c0-3.18,1.26-6.24,3.51-8.49,4.65-4.72,12.25-4.79,16.97-.14,4.72,4.65,4.79,12.25.14,16.97l-.14.14c-2.25,2.26-5.3,3.52-8.48,3.52Zm-3.7-13.97c-.16,0-.3.12-.3.28v2.3c0,.16.14.29.3.28h1.93v5.81c0,.16.14.29.3.28h2.52c.16,0,.3-.12.3-.28v-5.81h2.26c.16,0,.3-.12.3-.28v-2.3c0-.08-.03-.15-.09-.2s-.13-.08-.21-.08h-2.25v-1.35c0-.65.16-.98,1.05-.98h1.3c.16,0,.3-.12.3-.28v-2.14c0-.16-.14-.29-.3-.29h-1.92c-1.8-.15-3.39,1.19-3.54,2.99-.01.15-.02.3,0,.45v1.59h-1.95Z' />
                </svg>
              </a>
              <a href='https://www.instagram.com/les_compagnons/'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='currentColor'
                  viewBox='0 0 24.07 24.07'
                  className='w-[20px] aspect-1 fill-white hover:fill-primary'>
                  <path d='m12,24.08C5.4,24.08,0,18.68,0,12.08c0-3.2,1.3-6.2,3.5-8.5,4.6-4.7,12.2-4.8,17-.1,4.8,4.7,4.8,12.2.1,17l-.1.1c-2.3,2.2-5.3,3.5-8.5,3.5ZM7.1,5.08c-1.2,0-2.1.9-2.1,2.1v9.9c0,1.1.9,2,2.1,2h9.9c1.1,0,2-.9,2-2.1V7.08c0-1.1-.9-2-2.1-2H7.1Zm4.9,10.7c-2,0-3.7-1.7-3.7-3.7s1.7-3.7,3.7-3.7,3.7,1.7,3.7,3.7c0,2.1-1.7,3.7-3.7,3.7Zm0-6.5c-1.6,0-2.9,1.3-2.9,2.9s1.3,2.9,2.9,2.9,2.9-1.3,2.9-2.9c0-1.7-1.3-2.9-2.9-2.9Zm4.5-.9c-.5,0-.8-.4-.8-.8,0-.5.4-.8.8-.8.5,0,.8.4.8.8,0,.5-.3.8-.8.8h0Z' />
                </svg>
              </a>
              <a href='https://twitter.com/les_compagnons'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='currentColor'
                  viewBox='0 0 24.07 24.07'
                  className='w-[20px] aspect-1 fill-white hover:fill-primary'>
                  <path d='m12,24.08C5.4,24.08,0,18.68,0,12.08c0-3.2,1.3-6.2,3.5-8.5,4.6-4.7,12.2-4.8,17-.1,4.8,4.7,4.8,12.2.1,17l-.1.1c-2.3,2.2-5.3,3.5-8.5,3.5Zm-7-7.2h0c1.3.8,2.8,1.2,4.4,1.2,2.3.1,4.5-.9,6.1-2.6,1.3-1.5,2.1-3.4,2.1-5.3v-.4c.6-.4,1.1-.9,1.4-1.4-.5.2-1.1.4-1.7.4.6-.3,1-.9,1.3-1.5-.6.3-1.2.6-1.8.7-.6-.6-1.3-.9-2.1-.9-1.6,0-2.8,1.2-2.9,2.8,0,.2,0,.4.1.6-2.3-.1-4.4-1.1-5.9-2.9-.3.4-.4.9-.4,1.4,0,.9.5,1.8,1.3,2.3-.5,0-.9-.1-1.3-.3h0c0,1.3,1,2.5,2.3,2.7-.2.1-.5.1-.8.1h-.5c.4,1.1,1.4,1.9,2.6,1.9-1,.8-2.3,1.2-3.6,1.2-.1,0-.4,0-.6,0Z' />
                </svg>
              </a>
              <a href='https://www.linkedin.com/company/les-compagnons-du-devoir/?trk=biz-companies-cym'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='currentColor'
                  viewBox='0 0 24.07 24.07'
                  className='w-[20px] aspect-1 fill-white hover:fill-primary'>
                  <path d='m12,24.02C5.4,24.02,0,18.63,0,12.02c0-3.2,1.3-6.2,3.5-8.5C8.2-1.17,15.8-1.17,20.5,3.52c4.7,4.7,4.7,12.3,0,17h0c-2.3,2.2-5.3,3.5-8.5,3.5Zm2.1-11.9c1,0,1,.8,1,1.8v3.7c0,.1.1.2.2.2h2.4c.1,0,.2-.1.2-.2v-4.2c0-1.9-.4-3.8-3.1-3.8-.8,0-1.6.3-2.1.9v-.5c0-.1-.1-.2-.2-.2h-2.3c-.1,0-.2.1-.2.2h0v7.7c0,.1.1.2.2.2h2.4c.1,0,.2-.1.2-.2h0v-3.8c0-1.3.4-1.8,1.3-1.8Zm-7.7-2.3c-.1,0-.2.1-.2.2v7.7c0,.1.1.2.2.2h2.4c.1,0,.2-.1.2-.2h0v-7.7c0-.1-.1-.2-.2-.2h-2.4Zm1.2-3.8c-.9,0-1.6.7-1.6,1.6s.7,1.6,1.6,1.6,1.6-.7,1.6-1.6-.8-1.6-1.6-1.6Z' />
                </svg>
              </a>
              <a href='https://www.youtube.com/user/CompagnonsDevoir?sub_confirmation=1'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='currentColor'
                  viewBox='0 0 24.07 24.07'
                  className='w-[20px] aspect-1 fill-white hover:fill-primary'>
                  <path d='m12,24.02C5.4,24.02,0,18.63,0,12.02c0-3.2,1.3-6.2,3.5-8.5C8.2-1.17,15.8-1.17,20.5,3.52c4.7,4.7,4.7,12.2,0,16.9-2.3,2.3-5.3,3.6-8.5,3.6Zm-3.3-17c-1.8,0-2.7.3-3.2.9s-.5,1.5-.5,2.8v2.4c0,2.4.6,3.7,3.7,3.7h6.4c1.5,0,2.4-.2,2.9-.7s.8-1.4.8-2.9v-2.4c0-1.3,0-2.2-.5-2.8s-1.4-1-3.2-1h-6.4Zm2.1,6.8c-.2,0-.4-.2-.4-.4h0v-3c0-.2.2-.4.4-.4q.1,0,.2.1l2.9,1.5c.2.1.3.4.2.6,0,.1-.1.1-.2.2l-2.9,1.3c-.1,0-.2.1-.2.1h0Z' />
                </svg>
              </a>
              <a href='https://www.tiktok.com/@lescompagnonsdudevoir'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='currentColor'
                  viewBox='0 0 24.07 24.07'
                  className='w-[20px] aspect-1 fill-white hover:fill-primary'>
                  <path d='m12,24.02C5.4,24.02,0,18.63,0,12.02c0-3.2,1.3-6.2,3.5-8.5C8.2-1.17,15.8-1.17,20.5,3.52c4.7,4.7,4.7,12.2,0,16.9-2.3,2.3-5.3,3.6-8.5,3.6Zm-1.7-13.7c-1,0-2,.4-2.8,1.1-.8.6-1.3,1.6-1.4,2.7v.9c.1,1.4.9,2.6,2.1,3.3.6.4,1.4.6,2.1.6h.2c1.4,0,2.6-.7,3.4-1.8.5-.7.7-1.4.8-2.3v-5c.3.2.6.4.9.5.8.3,1.6.5,2.4.6v-2.3c-.9-.1-1.8-.4-2.4-1-.6-.6-1-1.5-1-2.4h-2.2v7.7h0v2.2c0,.2-.1.4-.2.6h0c-.3.6-.9.9-1.6.9h-.1c-.9,0-1.7-.7-1.9-1.6v-.1c0-.3,0-.5.1-.8.1-.4.4-.8.8-1,.3-.2.7-.3,1.1-.3.2,0,.4,0,.7.1v-1.1h0v-1.5c-.5,0-.7,0-1,0h0Z' />
                </svg>
              </a>
            </div>
          </div>
          <div>
            <div>
              <a
                href='https://compagnons-du-devoir.com/notre-association/a-propos/'
                className='tracking-wide text-white transition-colors duration-300 text-sm hover:text-primary uppercase poppins-bold mb-5'>
                Notre association
              </a>
            </div>
            <ul className='mt-2 space-y-1 pb-4'>
              <li>
                <a
                  href='https://compagnons-du-devoir.com/notre-association/a-propos/'
                  className='text-white transition-colors duration-300 hover:text-primary text-xs poppins'>
                  À propos
                </a>
              </li>
              <li>
                <a
                  href='https://compagnons-du-devoir.com/notre-association/le-college-des-metiers/'
                  className='text-white transition-colors duration-300 hover:text-primary text-xs poppins'>
                  Le collège des métiers
                </a>
              </li>
              <li>
                <a
                  href='https://compagnons-du-devoir.com/notre-association/les-delegations-regionales/'
                  className='text-white transition-colors duration-300 hover:text-primary text-xs poppins'>
                  Les délégations régionales
                </a>
              </li>
              <li>
                <a
                  href='https://compagnons-du-devoir.com/nos-actualites/'
                  className='text-white transition-colors duration-300 hover:text-primary text-xs poppins'>
                  Nos actualités
                </a>
              </li>
            </ul>
            <div>
              <a
                href='https://compagnons-du-devoir.com/nous-soutenir/'
                className='text-white transition-colors duration-300 uppercase poppins-bold hover:text-primary text-sm'>
                Nous soutenir
              </a>
            </div>
            <div>
              <a
                href='https://compagnons-du-devoir.com/nos-maisons-et-cfa/'
                className='text-white transition-colors duration-300 uppercase poppins-bold hover:text-primary text-sm'>
                Nos centres de formation
              </a>
            </div>
            <div>
              <a
                href='https://www.librairie-compagnons.com/'
                className='text-white transition-colors duration-300 uppercase poppins-bold hover:text-primary text-sm'>
                La librairie du compagnonnage
              </a>
            </div>
          </div>
          <div>
            <a
              href='https://compagnons-du-devoir.com/se-former/'
              className='tracking-wide text-white transition-colors duration-300 hover:text-primary text-sm uppercase poppins-bold mb-5'>
              Se former
            </a>
            <ul className='mt-2 space-y-1 pb-4'>
              <li>
                <a
                  href='https://compagnons-du-devoir.com/se-former/parcours-tour-de-france-moins-de-25-ans/'
                  className='text-white transition-colors duration-300 hover:text-primary text-xs poppins'>
                  Parcours Tour de France (- de 25 ans)
                </a>
              </li>
              <li>
                <a
                  href='https://compagnons-du-devoir.com/se-former/formation-continue-25-ans-et-plus/'
                  className='text-white transition-colors duration-300 hover:text-primary text-xs poppins'>
                  Formation continue (25 ans et plus)
                </a>
              </li>
              <li>
                <a
                  href='https://compagnons-du-devoir.com/se-former/devenir-compagnon-du-devoir/'
                  className='text-white transition-colors duration-300 hover:text-primary text-xs poppins'>
                  Devenir Compagnon du Devoir
                </a>
              </li>
            </ul>
            <div>
              <Link to='/filieres' className='text-white transition-colors duration-300 uppercase poppins-bold hover:text-primary text-sm'>
                Nos filières et métiers
              </Link>
            </div>
            <div>
              <a
                href='https://compagnons-du-devoir.com/voyager/'
                className='text-white transition-colors duration-300 uppercase poppins-bold hover:text-primary text-sm'>
                Voyager
              </a>
            </div>
            <ul className='mt-2 space-y-1 pb-4'>
              <li>
                <a
                  href='https://compagnons-du-devoir.com/voyager/le-tour-de-france/'
                  className='text-white transition-colors duration-300 hover:text-primary text-xs poppins'>
                  Le Tour de France
                </a>
              </li>
              <li>
                <a
                  href='https://compagnons-du-devoir.com/voyager/la-mobilite-internationale/'
                  className='text-white transition-colors duration-300 hover:text-primary text-xs poppins'>
                  La mobilité internationale
                </a>
              </li>
            </ul>
            <div>
              <a
                href='https://compagnons-du-devoir.com/rejoignez-les-compagnons-du-devoir/'
                className='text-white transition-colors duration-300 uppercase poppins-bold hover:text-primary text-sm'>
                S'inscrire
              </a>
            </div>
            <div>
              <a
                href='https://compagnons-du-devoir.com/se-rencontrer/'
                className='text-white transition-colors duration-300 uppercase poppins-bold hover:text-primary text-sm'>
                Se rencontrer
              </a>
            </div>
            <div>
              <a
                href='https://compagnons-du-devoir.com/foire-aux-questions/'
                className='text-white transition-colors duration-300 uppercase poppins-bold hover:text-primary text-sm'>
                Foire aux questions
              </a>
            </div>
          </div>
          <div>
            <a
              href='https://compagnons-du-devoir.com/espace-entreprises/'
              className='tracking-wide text-white transition-colors duration-300 hover:text-primary text-sm uppercase poppins-bold mb-5'>
              Espace entreprises
            </a>
            <ul className='mt-2 space-y-1 pb-4'>
              <li>
                <a
                  href='https://compagnons-du-devoir.com/espace-entreprises/accueillir-un-jeune/'
                  className='text-white transition-colors duration-300 hover:text-primary text-xs poppins'>
                  Recruter un(e) alternant(e)
                </a>
              </li>
              <li>
                <a
                  href='https://compagnons-du-devoir.com/espace-entreprises/former-ses-salaries/'
                  className='text-white transition-colors duration-300 hover:text-primary text-xs poppins'>
                  Former ses salariés
                </a>
              </li>
            </ul>
            <div>
              <a
                href='https://compagnons-du-devoir.com/espace-compagnons/'
                className='text-white transition-colors duration-300 uppercase poppins-bold hover:text-primary text-sm'>
                Espace Compagnons
              </a>
            </div>
            <ul className='mt-2 space-y-1 pb-4'>
              <li>
                <a
                  href='https://compagnons-du-devoir.com/espace-compagnons/cotiser/'
                  className='text-white transition-colors duration-300 hover:text-primary text-xs poppins'>
                  Cotiser
                </a>
              </li>
              <li>
                <a
                  href='https://compagnons-du-devoir.com/espace-compagnons/entreprendre/'
                  className='text-white transition-colors duration-300 hover:text-primary text-xs poppins'>
                  Entreprendre
                </a>
              </li>
              <li>
                <a
                  href='https://compagnons-du-devoir.com/espace-compagnons/les-assises/'
                  className='text-white transition-colors duration-300 hover:text-primary text-xs poppins'>
                  Les assises du compagnonnage
                </a>
              </li>
              <li>
                <a
                  href='https://www.compagnons-du-devoir.com/la-ruche/?_ga=2.199072940.2012810369.1676292484-436908553.1659602686'
                  className='text-white transition-colors duration-300 hover:text-primary text-xs'>
                  La ruche
                </a>
              </li>
            </ul>
            <div>
              <a
                href='https://compagnons-du-devoir.com/espace-professionnels-de-lorientation/'
                className='text-white transition-colors duration-300 uppercase poppins-bold hover:text-primary text-sm'>
                Espace professionnels de l'orientation
              </a>
            </div>
            <div>
              <a
                href='https://compagnons-du-devoir.com/espace-presse/'
                className='text-white transition-colors duration-300 uppercase poppins-bold hover:text-primary text-sm'>
                Espace presse
              </a>
            </div>
          </div>
        </div>
        <div className='block md:flex flex-row gap-20 justify-center'>
          <a href='https://www.cge.asso.fr/'>
            <img src='/assets/CGE-Blanc.svg' alt='Logo Conférence des Grandes Écoles' className='block w-52 md:w-32 mx-auto' />
          </a>
          <a href='https://ideas.asso.fr/le-label/'>
            <img src='/assets/label_IDEAS_091020-reserve.svg' alt="Logo Label Ideas L'exigence en action" className='block w-52 md:w-32 mx-auto my-7 md:my-0' />
          </a>
          <a href='https://www.culture.gouv.fr/Thematiques/Patrimoine-culturel-immateriel'>
            <img src='/assets/PCI_blanc_fond_transparent.svg' alt='Logo Patrimoine Culturel Immatériel en France' className='block w-52 md:w-32 mx-auto' />
          </a>
        </div>
      </div>
    </div>
  );
};

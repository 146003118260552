/* This example requires Tailwind CSS v3.0+ */
import { Typography, Button, Menu, MenuHandler, MenuList, MenuItem } from '@material-tailwind/react';
import { FunctionComponent } from 'react';
import { ArrowRightIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import { useUser } from '../providers/UserProvider';

/**
 * Tableau de lien pour le menu.
 *
 * @constant
 */
const seformer = [
  {
    name: 'Parcours Tour de France (moins de 25 ans)',
    href: 'https://compagnons-du-devoir.com/se-former/parcours-tour-de-france-moins-de-25-ans/',
  },
  {
    name: 'Formation continue (25 ans et plus)',
    href: 'https://compagnons-du-devoir.com/se-former/formation-continue-25-ans-et-plus/',
  },
  {
    name: 'Devenir Compagnon du Devoir',
    href: 'https://compagnons-du-devoir.com/se-former/devenir-compagnon-du-devoir/',
  },
  {
    name: 'Foire aux questions',
    href: 'https://compagnons-du-devoir.com/foire-aux-questions/',
  },
];

/**
 * Tableau de lien pour le menu.
 *
 * @constant
 */
const metiers = [
  {
    name: 'Bâtiment & aménagement',
    href: 'https://compagnons-du-devoir.com/metiers/batiment-amenagement/',
  },
  {
    name: "Technologies de l'industrie",
    href: 'https://compagnons-du-devoir.com/metiers/technologies-de-lindustrie/',
  },
  {
    name: 'Métier du goût',
    href: 'https://compagnons-du-devoir.com/metiers/metiers-du-gout/',
  },
  {
    name: 'Matériaux souples',
    href: 'https://compagnons-du-devoir.com/metiers/materiaux-souples/',
  },
  {
    name: 'Et aussi...',
    href: 'https://compagnons-du-devoir.com/metiers/et-aussi/',
  },
];

/**
 * Tableau de lien pour le menu.
 *
 * @constant
 */
const serencontrer = [
  {
    name: 'Journées ports ouvertes',
    href: 'https://compagnons-du-devoir.com/se-rencontrer/jsf/jet-engine:listingevents/tax/type-devenement:237/',
  },
  {
    name: "Les salons d'orientation",
    href: 'https://compagnons-du-devoir.com/se-rencontrer/jsf/jet-engine:listingevents/tax/type-devenement:238/',
  },
  {
    name: 'Les salons professionnels',
    href: 'https://compagnons-du-devoir.com/se-rencontrer/jsf/jet-engine:listingevents/tax/type-devenement:239/',
  },
];

/**
 * Ce composant affiche le menu principal côté Front.
 */
export const MainMenu: FunctionComponent<{ className?: string }> = () => {
  /**
   * On a besoin des informations de l'utilisateur.
   */
  const { user } = useUser();

  /**
   * Affiche le menu navigable.
   */
  const navList = (
    <ul className='mb-4 md:mb-1 mt-2 flex lg:mb-0 lg:mt-0 flex-row md:items-center justify-center gap-4'>
      <Typography as='li' variant='small' color='white'>
        <Menu allowHover={true}>
          <MenuHandler>
            <Button
              variant='text'
              ripple={false}
              className='poppins-medium tracking-widest hover:outline-none hover:border-none outline-none px-2 md:-pl-0 lg:pl-2 text-white text-xs md:text-md xl:text-[17px] flex items-center hover:bg-transparent active:bg-transparent hover:text-primary'>
              Se former
              <ChevronDownIcon className='lg:ml-2 h-4 w-4 group-hover:text-primary' aria-hidden='true' strokeWidth={4} />
            </Button>
          </MenuHandler>
          <MenuList className='border-none flex flex-row gap-5 p-11 w-full max-w-[900px]'>
            <div className='basis-3/5 !outline-none'>
              {seformer.map((item) => (
                <MenuItem key={item.name} className='hover:bg-transparent'>
                  <a href={item.href} className='-m-3 pl-0 py-2 flex items-start rounded-none p-3 text-secondary-2 hover:text-primary'>
                    <p className='poppins-semibold tracking-wide text-[18px]'>{item.name}</p>
                  </a>
                </MenuItem>
              ))}
              <a
                href='https://compagnons-du-devoir.com/formations-en-alternance/'
                className='poppins-medium text-[17px] tracking-widest flex flex-row items-center justify-between my-4 mr-2 py-1 px-4 text-white bg-primary rounded-full hover:bg-secondary-1'>
                Les formations en alternance
                <ArrowRightIcon className='pl-3 w-10 h-10' />
              </a>
              <a
                href='https://compagnons-du-devoir.com/se-former/formations-courtes/'
                className='poppins-medium text-[17px] tracking-widest flex flex-row items-center justify-between my-4 py-1 px-4 text-white bg-primary rounded-full hover:bg-secondary-1'>
                Les formations courtes
                <ArrowRightIcon className='pl-3 w-10 h-10' />
              </a>
            </div>
            <div className='basis-2/5 pl-5 border-l border-gray-300 hover:outline-none'>
              <img src='/assets/menu-se-former-compagnons-768x512.webp' className='rounded-lg' />
            </div>
          </MenuList>
        </Menu>
      </Typography>
      <Typography as='li' variant='small' color='white'>
        <Menu allowHover={true}>
          <MenuHandler>
            <Button
              variant='text'
              ripple={false}
              className='poppins-medium tracking-widest hover:outline-none hover:border-none outline-none px-2 md:-pl-0 lg:pl-2 text-white text-xs md:text-md xl:text-[17px] flex items-center hover:bg-transparent active:bg-transparent hover:text-primary'>
              Métiers
              <ChevronDownIcon className='lg:ml-2 h-4 w-4 group-hover:text-primary' aria-hidden='true' strokeWidth={4} />
            </Button>
          </MenuHandler>
          <MenuList className='border-none flex flex-row gap-5 p-11 w-full max-w-[900px]'>
            <div className='basis-3/5 !outline-none'>
              {metiers.map((item) => (
                <MenuItem key={item.name} className='hover:bg-transparent'>
                  <a href={item.href} className='-m-3 pl-0 py-2 flex items-start rounded-none p-3 text-secondary-2 hover:text-primary'>
                    <p className='poppins-semibold tracking-wide text-[18px]'>{item.name}</p>
                  </a>
                </MenuItem>
              ))}
              <a
                href='https://compagnons-du-devoir.com/les-metiers/'
                className='poppins-medium text-[17px] tracking-widest flex flex-row items-center justify-between my-4 mr-2 py-1 px-4 text-white bg-primary rounded-full hover:bg-secondary-1'>
                Découvrir tous les métiers
                <ArrowRightIcon className='pl-3 w-10 h-10' />
              </a>
            </div>
            <div className='basis-2/5 pl-5 border-l border-gray-300 hover:outline-none'>
              <img src='/assets/menu-les-metiers-compagnons-768x512.webp' className='rounded-lg' />
            </div>
          </MenuList>
        </Menu>
      </Typography>
      <Typography as='li' variant='small' color='white'>
        <Menu allowHover={true}>
          <MenuHandler>
            <Button
              variant='text'
              ripple={false}
              className='poppins-medium tracking-widest hover:outline-none hover:border-none outline-none px-2 md:-pl-0 lg:pl-2 text-white text-xs md:text-md xl:text-[17px] flex items-center hover:bg-transparent active:bg-transparent hover:text-primary'>
              Se rencontrer
              <ChevronDownIcon className='lg:ml-2 h-4 w-4 group-hover:text-primary' aria-hidden='true' strokeWidth={4} />
            </Button>
          </MenuHandler>
          <MenuList className='border-none flex flex-row gap-5 p-11 w-full max-w-[900px]'>
            <div className='basis-3/5 !outline-none'>
              {serencontrer.map((item) => (
                <MenuItem key={item.name} className='hover:bg-transparent'>
                  <a href={item.href} className='-m-3 pl-0 py-2 flex items-start rounded-none p-3 text-secondary-2 hover:text-primary'>
                    <p className='poppins-semibold tracking-wide text-[18px]'>{item.name}</p>
                  </a>
                </MenuItem>
              ))}
              <a
                href='https://compagnons-du-devoir.com/se-rencontrer/'
                className='poppins-medium text-[17px] tracking-widest flex flex-row items-center justify-between my-4 mr-2 py-1 px-4 text-white bg-primary rounded-full hover:bg-secondary-1'>
                Tous les événements
                <ArrowRightIcon className='pl-3 w-10 h-10' />
              </a>
            </div>

            <div className='basis-2/5 pl-5 border-l border-gray-300 hover:outline-none'>
              <img src='/assets/menu-se-rencontrer-compagnons-768x512.webp' className='rounded-lg' />
            </div>
          </MenuList>
        </Menu>
      </Typography>
    </ul>
  );

  return (
    <div className='w-full flex flex-col md:flex-row items-center justify-between text-white'>
      <Link to='/' className='hidden lg:flex -m-1.5 p-1.5'>
        <img className='h-20 md:h-32 xl:h-40 mx-auto' src='/assets/aocdtf-logo-white-orange.svg' alt='Compagnons du devoir et du Tour de France' />
      </Link>
      <div className='flex flex-col w-full'>
        <div className='flex flex-col md:flex-row items-center justify-center lg:justify-end'>
          {navList}
          <div className='flex flex-row items-center'>
            <Link
              to='/connexion'
              className='poppins-bold tracking-widest inline-block md:mr-4 lg:mr-0 mb-4 md:mb-0 text-center px-5 md:px-2 lg:px-3 pt-0.5 pb-0.5 text-xs md:text-md xl:text-[17px] leading-6 text-white hover:text-primary uppercase'>
              {user && user.token ? 'Espace client' : 'Se connecter'}
            </Link>
            {!(user && user.token) && (
              <Link
                to='/inscription'
                className='poppins-medium inline-block rounded-full px-2 lg:px-7 pt-0.5 pb-0.5 text-xs md:text-md xl:text-lg leading-6 text-center lg:ml-2 mb-5 md:mb-0 text-white shadow-sm ring-1 ring-white bg-transparent hover:ring-primary hover:bg-primary hover:text-white'>
                Je m'inscris
                <ArrowRightIcon className='w-9 h-9 inline pl-3' />
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

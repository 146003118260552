import { FunctionComponent } from 'react';
import { RoleEnum } from '../types/auth';

/**
 * Ce composant affiche un Badge pour chaque role d'utilisateur.
 *
 * @param text - Rôle de l'utilisateur.
 */
export const BadgeRole: FunctionComponent<{ text: RoleEnum }> = ({ text }) => {
  /**
   * @var Texte transformé.
   * @defaultValue `''`.
   */
  let translatedText = '';

  /**
   * @var Classe qui s'ajuste en fonction du rôle.
   * @defaultValue `''`.
   */
  let className = '';

  switch (text) {
    case RoleEnum.admin:
      translatedText = 'Administrateur';
      className = 'bg-red-100 text-red-800 text-[10px] font-medium mr-2 px-2 py-0.5 rounded-full border border-red-400';
      break;
    case RoleEnum.dev:
      translatedText = 'Développeur';
      className = 'bg-gray-100 text-gray-800 text-[10px] font-medium mr-2 px-2 py-0.5 rounded-full border border-gray-500';
      break;
    case RoleEnum.gestionnaireCentre:
      translatedText = 'Gestionnaire de centre';
      className = 'block bg-indigo-100 text-indigo-800 text-[10px] font-medium px-2 leading-3 text-center py-0.5 rounded-full border border-indigo-400';
      break;
    case RoleEnum.standard:
      translatedText = 'Standard';
      className = 'bg-green-100 text-green-800 text-[10px] font-medium mr-2 px-2 py-0.5 rounded-full border border-green-400';
      break;
    default:
      translatedText = text;
  }

  return <span className={className}>{translatedText}</span>;
};

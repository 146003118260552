import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Config } from '../../../config/config';
import { useApi } from '../../../providers/ApiProvider';
import { GoodApiResponse, ErrorApiResponse } from '../../../types/api';
import { Demande, Profil } from '../../../types/schematics';
import { Document } from '../../../types/EntreprisesRessources';
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import { InputDate } from '../../../components/Inputs/InputDate';
import { InputSelect } from '../../../components/Inputs/InputSelect';
import { DropdownRessource } from '../../../types';
import { ProfilEntreprise } from '../../../types/auth';
import { handleError } from '../../../utils/ErrorHandler';
import { useNavigate } from 'react-router-dom';
import { Menu, MenuHandler, Button, MenuList, MenuItem } from '@material-tailwind/react';
import { UserDisplay } from '../../../components/UserDisplay';
import { FormationSessionDisplay } from '../../../components/FormationSessionDisplay';
import { DownloadLink } from '../../../components/DownloadLink';

/**
 * Cette page affiche les documents de l'entreprise compris entre les dates.
 */
export const EntrepriseDocumentsScreen: FunctionComponent = () => {
  /**
   * On stocke si on est en train d'utiliser l'API ou non.
   */
  const [apiIsLoading, setApiIsLoading] = useState(false);

  /**
   * Date du jour.
   * @constant
   */
  const currentDate = new Date();

  /**
   * Date d'il y a 7 jour.
   * @constant
   */
  const sevenDayAgo = new Date(new Date().setDate(currentDate.getDate() - 7));

  /**
   * État local qui gère le champ de la date de départ de la recherche de document.
   * @defaultValue La value stocké dans l'état local ou la date du jour au format MySQL
   */
  const [from, setFrom] = useState<string>(
    localStorage.getItem(`EntrepriseDocumentsScreen-From`) ||
      `${sevenDayAgo.getFullYear()}-${sevenDayAgo.getMonth() < 9 ? `0${sevenDayAgo.getMonth() + 1}` : sevenDayAgo.getMonth() + 1}-${
        sevenDayAgo.getDate() < 10 ? `0${sevenDayAgo.getDate()}` : sevenDayAgo.getDate()
      }`,
  );

  /**
   * État local qui gère le champ de la date de départ de la recherche de document.
   * @defaultValue La value stocké dans l'état local ou la date du jour au format MySQL
   */
  const [to, setTo] = useState<string>(
    localStorage.getItem(`EntrepriseDocumentsScreen-To`) ||
      `${currentDate.getFullYear()}-${currentDate.getMonth() < 9 ? `0${currentDate.getMonth() + 1}` : currentDate.getMonth() + 1}-${
        currentDate.getDate() < 10 ? `0${currentDate.getDate()}` : currentDate.getDate()
      }`,
  );

  /**
   * État local qui gère les profils pour la liste des salarié.
   */
  const [profils, setProfils] = useState<DropdownRessource[] | null>(null);

  /**
   * ID du profil du salarié dont on veut voir les documents.
   */
  const [profilId, setProfilId] = useState<Profil['id'] | null>(
    (localStorage.getItem(`EntrepriseDocumentsScreen-ProfilId`) && parseInt(localStorage.getItem(`EntrepriseDocumentsScreen-ProfilId`) as string)) || null,
  );

  /**
   * Cet état local ermet de stocker les informations les documents.
   */
  const [documents, setDocuments] = useState<Document[] | null>(null);

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  /**
   * Permet au composant de changer de page.
   */
  const navigate = useNavigate();

  /**
   * Recharge la liste des salariés lorsque l'on arrive sur la page
   */
  useEffect(() => {
    handleProfils();
  }, [client]);

  /**
   * Retrouve les Document au chargement du composant ou à chaque changement de paramètres.
   */
  useEffect(() => {
    if (from && to) {
      handleIndex();
    }
  }, [client, from, to, profilId]);

  /**
   * Retrouve par le réseau les documents des salariés et les met dans un état local.
   */
  const handleIndex = () => {
    if (apiIsLoading === true) {
      return;
    }

    let params: {
      from: string;
      to: string;
      profil_id?: Profil['id'] | null;
    } = { from, to };

    if (profilId) {
      params = { ...params, profil_id: profilId };
    }

    setApiIsLoading(true);
    client
      .get<GoodApiResponse<Document[]>>(`/entreprise/salaries/documents`, { params: params })
      .then((response) => {
        setDocuments(response.data.data);
      })
      .catch((error: ErrorApiResponse<Demande>) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Cet méthode va chercher les profils salarié des salarié de l'entreprise.
   */
  const handleProfils = () => {
    setApiIsLoading(true);

    client
      .get<GoodApiResponse<ProfilEntreprise[]>>('/entreprise/salaries')
      .then((response) => {
        setProfils(response.data.data.map((profil) => ({ id: profil.id, label: `${profil.user.prenom} ${profil.user.nom}` })));
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  return (
    <Fragment>
      <Helmet>
        <title>Documents des salariés - {Config.app_label}</title>
      </Helmet>
      <div className='px-4 py-4 md:py-3 bg-gray-100 rounded-tl-lg rounded-tr-lg flex flex-col sm:flex-row gap-7 items-center'>
        <div className='sm:flex items-center justify-between'>
          <h1 className='text-base sm:text-lg md:text-xl poppins-bold leading-normal text-secondary-1'>Documents des salariés</h1>
        </div>
        <div className='flex flex-col md:flex-row gap-4 items-center'>
          <div className='flex flex-row items-center gap-3'>
            <p className='text-xs text-secondary-1 mb-1'>Du&nbsp;:</p>
            <InputDate
              value={from}
              onChange={(e) => {
                setFrom(e.target.value);
                localStorage.setItem(`EntrepriseDocumentsScreen-From`, e.target.value);
              }}
            />
          </div>
          <div className='flex flex-row items-center gap-3'>
            <p className='text-xs text-secondary-1 mb-1'>Au&nbsp;:</p>
            <InputDate
              value={to}
              onChange={(e) => {
                setTo(e.target.value);
                localStorage.setItem(`EntrepriseDocumentsScreen-To`, e.target.value);
              }}
            />
          </div>
          {profils && profils.length > 0 && (
            <div className='flex flex-row items-center gap-3'>
              <p className='text-xs text-secondary-1 mb-1'>Salarié&nbsp;:</p>
              <InputSelect
                value={profilId ? profilId : ''}
                onChange={(e) => {
                  setProfilId(parseInt(e.target.value));
                  localStorage.setItem(`EntrepriseDocumentsScreen-ProfilId`, e.target.value);
                }}
                options={profils}
              />
            </div>
          )}
        </div>
      </div>
      <div>
        <div className='bg-white rounded-b-lg w-full overflow-x-auto'>
          {documents && documents.length ? (
            <table className='min-w-full bg-white rounded-b-lg'>
              <thead className='text-sm text-gray-500 text-left border-b'>
                <tr>
                  <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Formation et session</th>
                  <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Salarié</th>
                  <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Fichier</th>
                  <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Créée le</th>
                  <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'></th>
                </tr>
              </thead>
              <tbody>
                {documents &&
                  documents.map((document) => (
                    <tr
                      key={document.token as string}
                      className='border-gray-100 border-b hover:bg-gray-100'
                      onDoubleClick={() => navigate(`/entreprise/documents/${document.token}`)}>
                      <td className='px-4 py-3'>
                        <FormationSessionDisplay formation={document.formation} groupe={document.groupe} />
                      </td>
                      <td className='px-4 py-3'>
                        <UserDisplay user={document.user} />
                      </td>
                      <td className='px-4 py-3 text-gray-800 text-sm'>
                        {document.fichier && <DownloadLink fichier={document.fichier} titre={document.titre} />}
                      </td>
                      <td className='px-4 py-3 text-gray-800 text-sm'>
                        {document.created_at
                          ? new Intl.DateTimeFormat('default', {
                              year: 'numeric',
                              month: 'numeric',
                              day: 'numeric',
                              hour: 'numeric',
                              minute: 'numeric',
                              second: 'numeric',
                              hour12: false,
                            }).format(new Date(document.created_at as string))
                          : ''}
                      </td>
                      <td className='px-4 py-3 text-secondary-1 text-sm'>
                        <Menu placement='right-start'>
                          <MenuHandler>
                            <Button
                              variant='text'
                              className='w-9 h-9 p-1.5 rounded-full text-gray-500 block mx-auto text-center mr-0 hover:bg-white/100 active:bg-transparent'>
                              <EllipsisVerticalIcon className='h-6 w-6' />
                            </Button>
                          </MenuHandler>
                          <MenuList>
                            <MenuItem onClick={() => navigate(`/entreprise/documents/${document.token}`)}>Voir</MenuItem>
                          </MenuList>
                        </Menu>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          ) : (
            <div className='text-center px-4 py-7'>Vous n'avez pas de document avec les critères choisis.</div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

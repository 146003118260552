import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { LoadingAbsolute } from '../../../components/LoadingAbsolute';
import { useApi } from '../../../providers/ApiProvider';
import { ErrorApiResponse, GoodApiResponse } from '../../../types/api';
import { InputDate } from '../../../components/Inputs/InputDate';
import { BookOpenIcon, CheckIcon, EllipsisVerticalIcon, ExclamationTriangleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { EntrepriseInfo } from '../../../types/auth';
import { Demande } from '../../../types/AdminRessources';
import { Button, Menu, MenuHandler, MenuItem, MenuList } from '@material-tailwind/react';
import { useNavigate } from 'react-router-dom';
import { DropdownRessource } from '../../../types';
import { InputSelect } from '../../../components/Inputs/InputSelect';
import { Entreprise } from '../../../types/schematics';
import { handleError } from '../../../utils/ErrorHandler';
import { EntrepriseDisplay } from '../../../components/EntrepriseDisplay';
import { UserDisplay } from '../../../components/UserDisplay';
import { FormationSessionDisplay } from '../../../components/FormationSessionDisplay';

export const AdmingcDemandesScreen: FunctionComponent = () => {
  /**
   * On stocke si on est en train d'utiliser l'API ou non.
   */
  const [apiIsLoading, setApiIsLoading] = useState(false);

  /**
   * Date du jour.
   * @constant
   */
  const currentDate = new Date();

  /**
   * Date d'il y a 7 jour.
   * @constant
   */
  const sevenDayAgo = new Date(new Date().setDate(currentDate.getDate() - 7));

  const [from, setFrom] = useState<string>(
    localStorage.getItem(`AdmingcDemandesScreen-From`) ||
      `${sevenDayAgo.getFullYear()}-${sevenDayAgo.getMonth() < 9 ? `0${sevenDayAgo.getMonth() + 1}` : sevenDayAgo.getMonth() + 1}-${
        sevenDayAgo.getDate() < 10 ? `0${sevenDayAgo.getDate()}` : sevenDayAgo.getDate()
      }`,
  );

  const [to, setTo] = useState<string>(
    localStorage.getItem(`AdmingcDemandesScreen-To`) ||
      `${currentDate.getFullYear()}-${currentDate.getMonth() < 9 ? `0${currentDate.getMonth() + 1}` : currentDate.getMonth() + 1}-${
        currentDate.getDate() < 10 ? `0${currentDate.getDate()}` : currentDate.getDate()
      }`,
  );

  const [demandes, setDemandes] = useState<(Demande & { id: number; entreprise: EntrepriseInfo })[] | null>(null);

  const [entreprises, setEntreprises] = useState<DropdownRessource[] | null>(null);

  const [entrepriseId, setEntrepriseId] = useState<Entreprise['id'] | null>(
    (localStorage.getItem(`AdmingcDemandesScreen-EntrepriseId`) && parseInt(localStorage.getItem(`AdmingcDemandesScreen-EntrepriseId`) as string)) || null,
  );

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  const navigate = useNavigate();

  useEffect(() => {
    handleEntreprises();
  }, [client]);

  useEffect(() => {
    if (from && to) {
      handleIndex();
    }
  }, [client, from, to, entrepriseId]);

  const handleIndex = () => {
    setApiIsLoading(true);

    let params: {
      from: string;
      to: string;
      entreprise_id?: Entreprise['id'] | null;
    } = { from, to };

    if (entrepriseId) {
      params = { ...params, entreprise_id: entrepriseId };
    }

    client
      .get<GoodApiResponse<Demande[]>>('/admingc/demandes', { params: params })
      .then((response) => {
        setDemandes(response.data.data as unknown as (Demande & { id: number; entreprise: EntrepriseInfo })[]);
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  const handleEntreprises = () => {
    setApiIsLoading(true);

    client
      .get<GoodApiResponse<DropdownRessource[]>>('/admingc/entreprises', { params: { as_foreign: true, no_pagination: true, est_ecommerce: true } })
      .then((response) => {
        setEntreprises(response.data.data as unknown as DropdownRessource[]);
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  return (
    <div className='relative'>
      <div className='px-4 py-4 md:py-3 bg-gray-100 rounded-tl-lg rounded-tr-lg flex flex-col sm:flex-row gap-7 items-center'>
        <div className='sm:flex items-center justify-between'>
          <p className='text-base sm:text-lg md:text-xl poppins-bold leading-normal text-secondary-1'>Demandes</p>
        </div>
        <div className='flex flex-col md:flex-row gap-4 items-center'>
          <div className='flex flex-row items-center gap-3'>
            <p className='text-xs text-secondary-1 mb-1'>Du&nbsp;:</p>
            <InputDate
              value={from}
              onChange={(e) => {
                setFrom(e.target.value);
                localStorage.setItem(`AdmingcDemandesScreen-From`, e.target.value);
              }}
            />
          </div>
          <div className='flex flex-row items-center gap-3'>
            <p className='text-xs text-secondary-1 mb-1'>Au&nbsp;:</p>
            <InputDate
              value={to}
              onChange={(e) => {
                setTo(e.target.value);
                localStorage.setItem(`AdmingcDemandesScreen-To`, e.target.value);
              }}
            />
          </div>
          {entreprises && entreprises.length > 0 && (
            <div className='flex flex-row items-center gap-3'>
              <p className='text-xs text-secondary-1 mb-1'>Entreprise&nbsp;:</p>
              <InputSelect
                value={entrepriseId ? entrepriseId : ''}
                onChange={(e) => {
                  setEntrepriseId(parseInt(e.target.value));
                  localStorage.setItem(`AdmingcDemandesScreen-EntrepriseId`, e.target.value);
                }}
                options={entreprises}
              />
            </div>
          )}
        </div>
      </div>
      <div className='container mx-auto bg-white rounded-b-lg'>
        <div className='w-full overflow-x-auto'>
          {apiIsLoading ? <LoadingAbsolute /> : ''}
          {demandes && demandes.length > 0 ? (
            <table className='min-w-full bg-white rounded-b-lg'>
              <thead>
                <tr className='w-full h-12 border-gray-300  border-b py-8'>
                  <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Numéro</th>
                  <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Formation et session</th>
                  <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Entreprise</th>
                  <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Salarié</th>
                  <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Avancement</th>
                  <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Décision</th>
                  <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'></th>
                </tr>
              </thead>
              <tbody>
                {demandes &&
                  demandes.map((demande) => (
                    <tr
                      key={demande.id}
                      className={`border-gray-100 border-b hover:bg-gray-100 ${demande.est_archive && 'bg-gray-200'}`}
                      onDoubleClick={() => navigate(`/admingc/demandes/${demande.id}`)}>
                      <td className='pl-4 py-3 text-secondary-1 text-xs'>{demande.id?.toString().padStart(6, '0')}</td>
                      <td className='px-4 py-3'>
                        <FormationSessionDisplay formation={demande.formation} groupe={demande.groupe} />
                      </td>
                      <td className='pr-4 py-3'>
                        <EntrepriseDisplay entreprise={demande.entreprise} />
                      </td>
                      <td className='pr-4 py-3'>{demande.user && <UserDisplay user={demande.user} />}</td>
                      <td className='pr-4 py-3 text-gray-800 text-xs'>
                        <div>
                          {/*<div>
                          {demande.est_en_attente_informations ? (
                            <ExclamationTriangleIcon className='w-4 h-4 inline-block mx-auto text-red-300' />
                          ) : (
                            <XMarkIcon className='w-4 h-4 inline-block mx-auto text-gray-900/20' />
                          )}{' '}
                          En attente d'informations
                        </div>*/}
                          <div>
                            {demande.est_en_attente_documents ? (
                              <ExclamationTriangleIcon className='w-4 h-4 inline-block mx-auto text-red-300' />
                            ) : (
                              <XMarkIcon className='w-4 h-4 inline-block mx-auto text-gray-900/20' />
                            )}{' '}
                            Documents en attente
                          </div>
                          <div>
                            {demande.est_complete ? (
                              <CheckIcon className='w-4 h-4 text-white inline-block mx-auto bg-light-green-300 rounded-full p-0.5' />
                            ) : (
                              <XMarkIcon className='w-4 h-4 inline-block mx-auto text-gray-900/20' />
                            )}{' '}
                            Demande complète
                          </div>
                          <div>
                            {demande.est_paye ? (
                              <CheckIcon className='w-4 h-4 text-white inline-block mx-auto bg-light-green-300 rounded-full p-0.5' />
                            ) : (
                              <XMarkIcon className='w-4 h-4 inline-block mx-auto text-gray-900/20' />
                            )}{' '}
                            Paiement effectué
                          </div>
                          <div>
                            {demande.est_finalise ? (
                              <CheckIcon className='w-4 h-4 text-white inline-block mx-auto bg-light-green-300 rounded-full p-0.5' />
                            ) : (
                              <XMarkIcon className='w-4 h-4 inline-block mx-auto text-gray-900/20' />
                            )}{' '}
                            Finalisée
                          </div>
                          <div>
                            {demande.est_archive && (
                              <Fragment>
                                <BookOpenIcon className='w-4 h-4 inline-block mx-auto text-gray-900/20' /> Archivée
                              </Fragment>
                            )}
                          </div>
                        </div>
                      </td>
                      <td className='pr-4 py-3 text-gray-800 text-xs'>
                        {demande.date_acceptation === null && demande.date_refus === null
                          ? 'En attente'
                          : demande.date_acceptation
                            ? `Acceptée le ${new Intl.DateTimeFormat('default', {
                                year: 'numeric',
                                month: 'numeric',
                                day: 'numeric',
                                hour12: false,
                              }).format(new Date(demande.date_acceptation as string))}`
                            : demande.date_refus
                              ? `Refusée le : ${new Intl.DateTimeFormat('default', {
                                  year: 'numeric',
                                  month: 'numeric',
                                  day: 'numeric',
                                  hour12: false,
                                }).format(new Date(demande.date_refus as string))}`
                              : ''}
                      </td>
                      <td className='px-4 py-3 text-gray-800 text-sm'>
                        <Menu placement='right-start'>
                          <MenuHandler>
                            <Button
                              variant='text'
                              className='w-9 h-9 p-1.5 rounded-full text-gray-500 block mx-auto text-center mr-0 hover:bg-white/100 active:bg-transparent'>
                              <EllipsisVerticalIcon className='h-6 w-6' />
                            </Button>
                          </MenuHandler>
                          <MenuList>
                            <MenuItem onClick={() => navigate(`/admingc/demandes/${demande.id}`)}>Voir la demande</MenuItem>
                            <MenuItem onClick={() => window.open(`/formations/${demande.formation.slug}`, '_blank')}>Voir la page de la formation</MenuItem>
                          </MenuList>
                        </Menu>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          ) : (
            <div className='text-center px-4 py-7'>Il n'y a pas de demande avec les critères choisis.</div>
          )}
        </div>
      </div>
    </div>
  );
};

import {
  Annee,
  Apprenant,
  Civilite,
  Commune,
  ConventionCollective,
  DiplomeObtenu,
  DiplomePrepare,
  Entreprise,
  Fonction,
  Formation,
  FormeJuridique,
  Groupe,
  Matiere,
  Metier,
  Nationalite,
  Octa,
  Opco,
  Pays,
  Periode,
  Personnel,
  Region,
  Role,
  SecteurActivite,
  Site,
  StatutAffiliation,
  TypeEmployeur,
  TypeProfil,
  User,
} from './types/schematics';

export type Config = {
  api_url: string;
  app_name: string;
  app_version: string;
  app_label: string;
  app_description: string;
  axeptio_clientId: string;
};

export type Ressource =
  | Annee
  | Apprenant
  | Civilite
  | Commune
  | ConventionCollective
  | DiplomeObtenu
  | DiplomePrepare
  | Entreprise
  | Fonction
  | Formation
  | FormeJuridique
  | Groupe
  | Matiere
  | Metier
  | Nationalite
  | Octa
  | Opco
  | Pays
  | Periode
  | Personnel
  | Region
  | Role
  | SecteurActivite
  | Site
  | StatutAffiliation
  | TypeEmployeur
  | TypeProfil
  | User;

export type DropdownRessource = { id: Ressource['id']; label: string };

export type ForeignRessources = { [key: string]: DropdownRessource[] };

export enum ModeFormulaire {
  creer = 'creer',
  editer = 'editer',
  voir = 'voir',
}

import { Dispatch, FunctionComponent, SetStateAction } from 'react';
import { BellIcon, CheckIcon, ClockIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Button, Menu, MenuHandler, MenuList, MenuItem, Navbar, IconButton, Badge } from '@material-tailwind/react';
import { Link, useNavigate } from 'react-router-dom';
import { useUser } from '../../providers/UserProvider';
import { Profil, RoleEnum, UserLocal } from '../../types/auth';
import { Avatar } from '../Avatar';
import toast from 'react-hot-toast';
import { ToastInfo } from '../toasts/ToastInfo';
import { useApi } from '../../providers/ApiProvider';
import { Notification } from '../../types/AdminRessources';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

/**
 * Ce composant affiche la barre superieure pour le Back-Office.
 * Il est sensible au responsive. Un bouton qui gère un menu apparait lorsque l'on réduit la résolution.
 *
 * @param openNav - État d'ouverture du menu pour le responsive.
 * @param setOpenNav - Fixeur de l'état d'ouverture du menu pour le responsive.
 */
export const DashboardTopbar: FunctionComponent<{ openNav: boolean; setOpenNav: Dispatch<SetStateAction<boolean>> }> = ({ openNav, setOpenNav }) => {
  /**
   * On a besoin des informations de l'utilisateur, du profil courant de celui-ci et de l'acces aux notifications.
   */
  const { user, profil, changeProfil, notifications, viewNotification } = useUser();

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  /**
   * Permet au composant de changer de page.
   */
  const navigate = useNavigate();

  const notificationsFiltered = notifications?.filter((notification) => !notification.est_vu && !notification.est_masque);

  return (
    <Navbar fullWidth className='sticky top-0 !bg-secondary-2 z-[70] py-2 2xl:py-0 text-right 2xl:text-left border-0'>
      <div className='mx-auto flex flex-row items-center justify-between'>
        <Link to='/' className='flex 2xl:hidden -m-1.5 p-1.5'>
          <img className='h-10 2xl:h-22 2xl:h-40 mx-auto hidden 2xl:block' src='/assets/logo-aocdtf.webp' alt='Compagnons du devoir et du Tour de France' />
        </Link>
        <div className='2xl:w-full flex flex-row items-center pl-8 justify-end'>
          <div className='h-full flex items-center justify-center'>
            <Menu placement='bottom-end'>
              <MenuHandler>
                <Button
                  variant='text'
                  ripple={false}
                  className='flex items-center font-normal normal-case active:bg-transparent hover:bg-transparent text-secondary-1 px-3'>
                  <div className='relative cursor-pointer text-white'>
                    <Badge
                      color='orange'
                      placement='bottom-end'
                      content={notificationsFiltered ? notificationsFiltered.length : 0}
                      withBorder={false}
                      invisible={notificationsFiltered ? notificationsFiltered.length === 0 : true}>
                      <BellIcon className='w-7 h-7' />
                    </Badge>
                  </div>
                </Button>
              </MenuHandler>
              <MenuList className='max-h-72 min-w-[375px] max-w-[520px]'>
                {notificationsFiltered && notificationsFiltered.length > 0 ? (
                  notificationsFiltered.map((notification) => (
                    <MenuItem
                      key={notification.id}
                      className='mb-1 flex flex-row gap-3'
                      onClick={() => {
                        viewNotification(notification.id).then(() => {
                          if (profil && notification.profil_id && profil.id !== notification.profil_id) {
                            changeProfil(
                              (user as UserLocal).profils.find(
                                (profil) => profil.id === (notification.profil_id as Notification['profil_id'] as number),
                              ) as Profil,
                            );
                            client.setProfil(notification.profil_id as Notification['profil_id'] as number);
                            toast.custom(<ToastInfo>Vous avez changé de profil !</ToastInfo>);
                          }
                        });

                        navigate(notification.url as string);
                      }}>
                      <div className='w-[35px]'>
                        {notification.categorie === 'info' && (
                          <div className='rounded-full w-full aspect-1 bg-[#0E65D0] text-white flex items-center justify-center poppins-semibold text-lg'>
                            i
                          </div>
                        )}
                        {notification.categorie === 'success' && (
                          <div className='rounded-full w-full aspect-1 bg-[#16AE26] text-white flex items-center justify-center'>
                            <CheckIcon className='w-5 h-5' strokeWidth={2.2} />
                          </div>
                        )}
                        {notification.categorie === 'warning' && (
                          <div className='rounded-full w-full aspect-1 bg-[#f9a534] text-white flex items-center justify-center poppins-semibold text-lg'>
                            !
                          </div>
                        )}
                        {notification.categorie === 'error' && (
                          <div className='rounded-full w-full aspect-1 bg-[#D00E0E] text-white flex items-center justify-center'>
                            <XMarkIcon className='w-5 h-5' strokeWidth={2.3} />
                          </div>
                        )}
                      </div>
                      <div className='w-full'>
                        <p className='poppins-semibold text-md text-secondary-2'>{notification.titre}</p>
                        <p className='text-secondary-1/70 text-xs'>{notification.description}</p>
                        <p className='flex flex-row items-center text-xs text-gray-500'>
                          <ClockIcon className='w-4 h-4 mr-1 pt-0.5 text-left' />
                          <span className='leading-normal'>
                            {notification.created_at
                              ? `${new Intl.DateTimeFormat('default', {
                                  year: 'numeric',
                                  month: 'numeric',
                                  day: 'numeric',
                                  hour: 'numeric',
                                  minute: 'numeric',
                                  hour12: false,
                                }).format(new Date(notification.created_at))}`
                              : ''}
                          </span>
                        </p>
                      </div>
                    </MenuItem>
                  ))
                ) : (
                  <div className='mb-3'>
                    <img src='/assets/sleeping-fox-notifications.png' className='mx-auto my-2' />
                    <p className='text-center'>Aucune notification pour le moment</p>
                  </div>
                )}
                <MenuItem className='text-primary text-center hover:!text-primary hover:!bg-primary/10' onClick={() => navigate('/notifications')}>
                  Toutes les notifications
                </MenuItem>
              </MenuList>
            </Menu>
          </div>
          <div className='mr-2 2xl:mr-0'>
            <Menu placement='bottom-end'>
              <MenuHandler>
                <Button
                  variant='text'
                  ripple={false}
                  className='flex items-center font-normal normal-case active:bg-transparent hover:bg-transparent text-white px-3'>
                  {user && (
                    <div className='relative'>
                      <Avatar src={user.image_avatar} />
                      <div className='w-4 h-4 rounded-full bg-white text-black absolute right-[-8px] bottom-[-4px] flex items-center justify-center'>
                        <ChevronDownIcon className='w-3 h-3' />
                      </div>
                    </div>
                  )}
                </Button>
              </MenuHandler>
              <MenuList>
                <Link to='/mes-informations'>
                  <MenuItem>Mes informations</MenuItem>
                </Link>
                {user !== null && user.roles.includes(RoleEnum.standard) && (
                  <Link to='/profils'>
                    <MenuItem>Mes profils</MenuItem>
                  </Link>
                )}
                {user !== null && user.roles.includes(RoleEnum.standard) && (
                  <Link to='/invitations'>
                    <MenuItem>Mes invitations</MenuItem>
                  </Link>
                )}
                <Link to='/mon-motdepasse'>
                  <MenuItem>Mot de passe</MenuItem>
                </Link>
                <Link to='/deconnexion'>
                  <MenuItem>Déconnexion</MenuItem>
                </Link>
              </MenuList>
            </Menu>
          </div>
          <IconButton
            variant='text'
            className='ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent 2xl:hidden text-white'
            ripple={false}
            onClick={() => setOpenNav(!openNav)}>
            {openNav ? (
              <svg xmlns='http://www.w3.org/2000/svg' fill='none' className='h-6 w-6' viewBox='0 0 24 24' stroke='currentColor' strokeWidth={2}>
                <path strokeLinecap='round' strokeLinejoin='round' d='M6 18L18 6M6 6l12 12' />
              </svg>
            ) : (
              <svg xmlns='http://www.w3.org/2000/svg' className='h-6 w-6' fill='none' stroke='currentColor' strokeWidth={2}>
                <path strokeLinecap='round' strokeLinejoin='round' d='M4 6h16M4 12h16M4 18h16' />
              </svg>
            )}
          </IconButton>
        </div>
      </div>
    </Navbar>
  );
};

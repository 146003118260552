import { FunctionComponent } from 'react';
import { CalendarDaysIcon, ChevronRightIcon, MapPinIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import { Button, Chip, Tooltip } from '@material-tailwind/react';
import { Avatar } from '../Avatar';
import { DashboardInscriptions } from '../../types/EntreprisesRessources';
import { DatesDisplay } from '../DatesDisplay';

/**
 * Ce composant est un encart du tableau de bord entreprise concernant les inscriptions dans les sessions.
 *
 * @param inscriptions - inscriptions dans les sessions.
 */
export const DashboardEntrepriseSessions: FunctionComponent<{ inscriptions: DashboardInscriptions[] }> = ({ inscriptions }) => {
  /**
   * Date du jour.
   * @constant
   */
  const currentDate = new Date();

  /**
   * Permet de renvoyer le statut de la session en fonction de la date courante.
   *
   * @param inscription - Inscription à la session.
   * @returns Le statut de la session.
   */
  const afficheStatut = (inscription: DashboardInscriptions) => {
    if (inscription.date_debut && inscription.date_fin && new Date(inscription.date_debut) <= currentDate && currentDate <= new Date(inscription.date_fin)) {
      return 'En cours';
    } else if (inscription.date_debut && currentDate < new Date(inscription.date_debut)) {
      return 'À venir';
    } else if (inscription.date_fin && new Date(inscription.date_fin) < currentDate) {
      return 'Terminée';
    } else {
      return 'N/A';
    }
  };

  /**
   * Permet d'afficher une claasse tailwind en fonction du statut, afin de faire ressortir plus clairement le statut.
   *
   * @param statut Statut de la session.
   * @returns une classe tailwind.
   */
  const afficheClasse = (statut: string) => {
    switch (statut) {
      case 'En cours':
        return 'rounded-full bg-blue-300 text-blue-700 border border-blue-500 normal-case py-1';

      case 'À venir':
        return 'rounded-full bg-secondary-1/10 text-secondary-1 border border-secondary-1/50 normal-case py-1';

      case 'Terminée':
        return 'rounded-full bg-[#DCFCE7] text-green-700 border border-green-300 normal-case py-1';

      case 'N/A':
        return 'rounded-full bg-secondary-1/10 text-secondary-1 border border-secondary-1/50 normal-case py-1';

      default:
        return 'rounded-full bg-secondary-1/10 text-secondary-1 border border-secondary-1/50 normal-case py-1';
    }
  };

  return (
    <div className='mx-auto mt-2 shadow-md rounded-md bg-white mb-5'>
      <div className='flex flex-col sm:flex-row items-center justify-between p-5'>
        <h3 className='text-secondary-2 poppins-semibold text-center md:text-left'>Sessions de formation</h3>
        <Link to='/entreprise/groupes' className='text-sm text-secondary-1/90 flex items-center hover:text-primary'>
          Tout voir
          <ChevronRightIcon className='w-3 h-3' />
        </Link>
      </div>
      {inscriptions && inscriptions.length > 0 ? (
        inscriptions.slice(0, 5).map((session) => (
          <div key={session.token} className='border-b border-secondary-1/10 mx-auto p-5'>
            <div className='flex flex-col md:flex-row xl:flex-col 2xl:flex-row justify-between items-center gap-5 text-secondary-1'>
              <div className='w-full basis-full max-w-[40%] flex justify-center md:justify-start gap-2'>
                <img
                  src='/images/logo-512.png'
                  className='mt-1 transform transition ease-out group-hover:scale-110 h-11 w-11 object-cover rounded-lg'
                  loading='lazy'
                />
                <div>
                  <h3 className='poppins-medium text-left'>{session.nom}</h3>
                  <div className='text-secondary-1/80 flex flex-row items-center gap-1'>
                    <CalendarDaysIcon className='w-5 h-5 flex-none' />
                    {session.date_debut && session.date_fin ? (
                      <DatesDisplay className='text-xs' date_start={session.date_debut} date_end={session.date_fin} />
                    ) : (
                      <span className='text-xs font-medium pl-0'>Dates non définies</span>
                    )}
                  </div>
                </div>
              </div>
              <div className='w-full 2xl:max-w-[60%] flex flex-row justify-between gap-3'>
                <div className='flex items-center justify-center md:justify-start gap-1'>
                  <div>
                    <MapPinIcon className='w-5 h-5 flex-none' />
                  </div>
                  <span className='text-sm'>{session.site.nom}</span>
                </div>
                <div className='flex items-center justify-center md:justify-start -space-x-4'>
                  {session.users.slice(0, 3).map((user) => (
                    <Tooltip key={user.email} content={`${user.prenom} ${user.nom}`}>
                      <Button variant='text' className='rounded-md p-0 hover:bg-transparent'>
                        <Avatar
                          className='w-11 h-11 rounded-md border-2 border-white hover:z-10 focus:z-10'
                          src={user.image_avatar}
                          title={`${user.prenom} ${user.nom}`}
                        />
                      </Button>
                    </Tooltip>
                  ))}
                  {session.users.length > 4 && <span className='text-secondary-1/70 text-sm pl-5'>+ {session.users.length - 4}</span>}
                </div>
                <div className='flex items-center justify-center md:justify-start'>
                  <Chip value={afficheStatut(session)} className={afficheClasse(afficheStatut(session))} />
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <p className='p-5 text-center text-sm font-medium text-secondary-1/70'>Aucune session de formation pour le moment.</p>
      )}
    </div>
  );
};

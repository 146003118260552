import { FunctionComponent } from 'react';
import { Avatar } from './Avatar';
import { CheckBadgeIcon } from '@heroicons/react/20/solid';
import { EnvelopeIcon } from '@heroicons/react/24/outline';

export const UserWithMailDisplay: FunctionComponent<{
  user?: any | null;
  isAdmin?: boolean | null | undefined;
}> = ({ user = null, isAdmin = false }) => {
  if (user) {
    return (
      <div className='flex flex-row gap-2 items-center text-secondary-1 text-sm'>
        <div className='flex-none'>
          <Avatar src={user.image_avatar} />
        </div>
        <div>
          <div>
            {isAdmin === true ? (
              <a href={'/admin/users/editer/' + user.id} className='text-secondary-1 hover:text-primary poppins-medium' target='_blank' rel='noreferrer'>
                {user.prenom} {user.nom}
              </a>
            ) : (
              <span className='poppins-medium'>{`${user.prenom} ${user.nom}`}</span>
            )}
          </div>
          <div>
            {user.email && (
              <a href={'mailto:' + user.email} className='hover:text-primary flex flex-row items-center gap-1 text-sm'>
                <EnvelopeIcon className='w-4 h-4 flex-none' strokeWidth={2.5} />
                {user.email}
                {user.email_verified_at && <CheckBadgeIcon className='w-4 h-4 text-light-green-300 flex-none' title='Email vérifié' />}
              </a>
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
};

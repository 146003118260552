import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Filiere } from '../types/visitorRessources';
import { useApi } from '../providers/ApiProvider';
import { GoodApiResponse, ErrorApiResponse } from '../types/api';
import { handleError } from '../utils/ErrorHandler';

/**
 * Ce composant affiche la liste des catégoories.
 */
export const CategoriesGrid: FunctionComponent = () => {
  /**
   * Cet état gère la liste des filières.
   */
  const [filieres, setFilieres] = useState<Filiere[]>([]);

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  /**
   * Effet qui s'exécute à chaque redémarrage de page.
   */
  useEffect(() => {
    handleIndex();
  }, [client]);

  /**
   * Cette méthode appelle la liste des fillière depepuis l'API et les range dans l'état local.
   */
  const handleIndex = () => {
    client
      .get<GoodApiResponse<Filiere[]>>('filieres')
      .then((response) => {
        setFilieres(response.data.data);
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error);
      });
  };

  return (
    <Fragment>
      {/* Product List Section: Categories Grid */}

      <div className='container xl:max-w-7xl mx-auto px-4 pt-8 pb-16 xl:px-8'>
        <h2 className='font text-2xl poppins-bold tracking-tight text-secondary-1 mb-2'>Nos formations courtes par filière</h2>
        <p className='mb-4 text-gray-600 text-sm'>
          Formations techniques, règlementaires ou managériales, les Compagnons du Devoir vous accompagnent dans la réussite de vos projets grâce à une offre de
          formation courte dans une trentaine de métiers.
        </p>
        <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4'>
          {filieres.map((filiere, index) => (
            <Link
              key={index}
              to={`filieres/${filiere.slug}`}
              className=' sm:col-span-2 md:col-span-1 block transition ease-out active:opacity-75 rounded-lg shadow-lg p-4'
              style={{ backgroundColor: filiere.couleur ? filiere.couleur : '#000000' }}>
              <div className='h-[180px] group relative rounded-md overflow-hidden'>
                {filiere.image_couverture ? (
                  <img
                    src={filiere.image_couverture}
                    className='transform transition ease-out group-hover:scale-110 h-full w-full object-cover'
                    loading='lazy'
                  />
                ) : (
                  <div className={`w-full h-full object-cover bg-gray-700 flex items-center justify-center`}></div>
                )}
                <div className='absolute inset-0 bg-black bg-opacity-20 transition ease-out group-hover:bg-opacity-0' />
                <div className='p-4 flex items-end justify-center absolute inset-0'>
                  <div
                    className='px-2 bg-opacity-95 rounded-md montserrat-semibold tracking-wide transition ease-out text-white w-full text-center text-lg'
                    style={{ backgroundColor: filiere.couleur ? filiere.couleur : '#000000' }}>
                    {filiere.nom}
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
      {/* END Product List Section: Categories Grid */}
    </Fragment>
  );
};

import { FunctionComponent } from 'react';

export const Accordion: FunctionComponent<{
  open: boolean;
  icon: JSX.Element;
  titre: string;
  description: (string | React.ReactElement<any, string | React.JSXElementConstructor<any>>)[] | JSX.Element;
  onClick: React.MouseEventHandler<HTMLElement>;
}> = ({ open, icon, titre, description, onClick }) => (
  <div className='block relative w-full bg-white mb-5 rounded-lg shadow-md px-4'>
    <button
      className={`flex justify-between items-center w-full py-4 border-b-blue-gray-100 antialiased text-left poppins-semibold select-none border-b-0 ${
        open ? 'text-primary' : 'text-secondary-1'
      } hover:text-primary poppins-semibold text-xl tracking-wide`}
      onClick={onClick}>
      {titre}
      <span className='ml-4'>{icon}</span>
    </button>
    <div className='overflow-hidden' style={{ height: open ? 'auto' : '0' }}>
      {<div className='block w-full pb-4 text-gray-700 antialiased text-sm leading-normal'>{description}</div>}
    </div>
  </div>
);

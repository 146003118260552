import { useState, useEffect, Fragment } from 'react';
import { Navbar, MobileNav, Button, IconButton, Menu, MenuHandler, MenuList, MenuItem } from '@material-tailwind/react';
import { FunctionComponent } from 'react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom';

/**
 * Tableau de lien pour le menu.
 *
 * @constant
 */
const solutions = [
  {
    name: 'A propos',
    href: 'https://compagnons-du-devoir.com/notre-association/a-propos/',
  },
  {
    name: 'Le collège des métiers',
    href: 'https://compagnons-du-devoir.com/notre-association/le-college-des-metiers/',
  },
  {
    name: "L'accueil et l'accompagnement",
    href: 'https://compagnons-du-devoir.com/laccueil-et-laccompagnement/',
  },
  {
    name: 'Les délégations régionales',
    href: 'https://compagnons-du-devoir.com/notre-association/les-delegations-regionales/',
  },
];

/**
 * Tableau de lien pour le menu.
 *
 * @constant
 */
const noussoutenir = [
  {
    name: 'Pourquoi nous soutenir ?',
    href: 'https://compagnons-du-devoir.com/nous-soutenir/pourquoi-nous-soutenir/',
  },
  {
    name: 'Faire un don',
    href: 'https://compagnons-du-devoir.com/nous-soutenir/faire-un-don/',
  },
  {
    name: 'Mécénat et partenariat',
    href: 'https://compagnons-du-devoir.com/nous-soutenir/mecenat-et-partenariat/',
  },
  {
    name: 'Legs, donation, assurance vie',
    href: 'https://compagnons-du-devoir.com/nous-soutenir/legs-donation-assurance-vie/',
  },
  {
    name: 'Les projets à soutenir',
    href: 'https://compagnons-du-devoir.com/nous-soutenir/',
  },
];

/**
 * Tableau de lien pour le menu.
 *
 * @constant
 */
const espaceentreprises = [
  {
    name: 'Recruter un(e) alternant(e)',
    href: 'https://compagnons-du-devoir.com/espace-entreprises/accueillir-un-jeune/',
  },
  {
    name: 'Former ses salariés',
    href: 'https://compagnons-du-devoir.com/espace-entreprises/former-ses-salaries/',
  },
];

/**
 * Tableau de lien pour le menu.
 *
 * @constant
 */
const espacecompagnons = [
  {
    name: 'Cotiser',
    href: 'https://compagnons-du-devoir.com/espace-compagnons/cotiser/',
  },
  {
    name: 'Entreprendre',
    href: 'https://compagnons-du-devoir.com/espace-compagnons/entreprendre/',
  },
  {
    name: 'Les assises du compagnonnage',
    href: 'https://compagnons-du-devoir.com/espace-compagnons/les-assises/',
  },
];

/**
 * Ce composant affiche le menu du haut de la partie Front.
 */
export const TopBar: FunctionComponent = () => {
  /**
   * Gère l'ouverture du menu.
   */
  const [openNav, setOpenNav] = useState(false);

  /**
   * Affiche le menu navigable.
   */
  const navList = (
    <ul id='MainMenuList' className='mb-4 mt-2 flex flex-col lg:mb-0 lg:mt-0 lg:flex-row lg:items-center gap-2 xl:gap-2 2xl:gap-4'>
      <li>
        <Menu allowHover={true}>
          <MenuHandler>
            <Button
              variant='text'
              ripple={false}
              className='text-white font-normal flex items-center hover:bg-transparent active:bg-transparent focus:outline-none px-0 poppins'>
              L'association
              <ChevronDownIcon className='text-white ml-0 xl:ml-2 h-3 w-3 group-hover:text-primary' aria-hidden='true' />
            </Button>
          </MenuHandler>
          <MenuList className='bg-secondary-2 border-none rounded-t-none p-0 overflow-hidden'>
            {solutions.map((item) => (
              <MenuItem key={item.name} className='!bg-transparent hover:!bg-secondary-1 rounded-none'>
                <a href={item.href} className='-m-3 flex items-start rounded-none p-4 text-white hover:text-primary text-xs poppins'>
                  {item.name}
                </a>
              </MenuItem>
            ))}
            <a
              href='https://compagnons-du-devoir.com/nos-actualites/'
              className='block text-white m-3 bg-primary rounded-lg p-3 hover:bg-secondary-1 text-xs poppins'>
              Nos actualités
            </a>
          </MenuList>
        </Menu>
      </li>
      <li>
        <a href='https://compagnons-du-devoir.com/nos-maisons-et-cfa/' className=' uppercase text-[12px] poppins'>
          Maisons et CFA
        </a>
      </li>
      <li>
        <Menu allowHover={true}>
          <MenuHandler>
            <Button variant='text' ripple={false} className=' text-white font-normal flex items-center hover:bg-transparent active:bg-transparent px-0 poppins'>
              Nous soutenir
              <ChevronDownIcon className='text-white ml-0 xl:ml-2 h-3 w-3 group-hover:text-primary' aria-hidden='true' strokeWidth={7} />
            </Button>
          </MenuHandler>
          <MenuList className='bg-secondary-2 border-none rounded-t-none p-0 overflow-hidden'>
            {noussoutenir.map((item) => (
              <MenuItem key={item.name} className='!bg-transparent hover:!bg-secondary-1 rounded-none'>
                <a href={item.href} className='-m-3 flex items-start rounded-none p-4 text-white hover:text-primary text-xs poppins'>
                  {item.name}
                </a>
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </li>
      <li>
        <Menu allowHover={true}>
          <MenuHandler>
            <Button variant='text' ripple={false} className=' text-white font-normal flex items-center hover:bg-transparent active:bg-transparent px-0 poppins'>
              Espace entreprises
              <ChevronDownIcon className='text-white ml-0 xl:ml-2 h-3 w-3 group-hover:text-primary' aria-hidden='true' />
            </Button>
          </MenuHandler>
          <MenuList className='bg-secondary-2 border-none rounded-t-none p-0 overflow-hidden'>
            {espaceentreprises.map((item) => (
              <MenuItem key={item.name} className='!bg-transparent hover:!bg-secondary-1 rounded-none'>
                <a href={item.href} className='-m-3 flex items-start rounded-none p-4 text-white hover:text-primary text-xs poppins'>
                  {item.name}
                </a>
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </li>
      <li>
        <Menu allowHover={true}>
          <MenuHandler>
            <Button
              variant='text'
              ripple={false}
              className=' text-white font-normal flex flex-row items-center hover:bg-transparent active:bg-transparent px-0 poppins'>
              <span>Espaces Compagnons</span>
              <ChevronDownIcon className='text-white ml-0 xl:ml-2 h-3 w-3 group-hover:text-primary' aria-hidden='true' />
            </Button>
          </MenuHandler>
          <MenuList className='bg-secondary-2 border-none rounded-t-none p-0 overflow-hidden'>
            {espacecompagnons.map((item) => (
              <MenuItem key={item.name} className='!bg-transparent hover:!bg-secondary-1 rounded-none'>
                <a href={item.href} className='-m-3 flex items-start rounded-none p-4 text-white hover:text-primary text-xs poppins'>
                  {item.name}
                </a>
              </MenuItem>
            ))}
            <a href='https://laruche.compagnons-du-devoir.com' className=' block text-white m-3 bg-primary rounded-lg p-3 hover:bg-secondary-1 text-xs poppins'>
              La ruche
            </a>
          </MenuList>
        </Menu>
      </li>
      <li>
        <a href='https://www.compagnons-du-devoir.com/nous-contacter' className=' flex items-center text-xs uppercase poppins'>
          Nous contacter
        </a>
      </li>
    </ul>
  );

  /**
   * Cet effet permet d'ouvrir et fermer le menu en fonction de la largeur d'écran. C'est surtout pour le responsive.
   */
  useEffect(() => {
    window.addEventListener('resize', () => window.innerWidth >= 960 && setOpenNav(false));
  }, []);

  /**
   * Cet état local gère la visiblité du menu.
   */
  const [isVisible, setIsVisible] = useState(false);

  /**
   * Effet qui vérifie le déroulement de la page au chargement du composant.
   */
  useEffect(() => {
    window.addEventListener('scroll', listenToScroll);
    return () => window.removeEventListener('scroll', listenToScroll);
  }, []);

  /**
   * Cette méthode vérifie le déroulement pour afficher ou cacher un lien.
   */
  const listenToScroll = () => {
    const heightToHideFrom = 160;
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;

    if (winScroll > heightToHideFrom) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  return (
    <Fragment>
      <Navbar className='sticky top-0 z-50 px-7 xl:px-5 2xl:px-11 py-2 w-full max-w-full bg-secondary-2 rounded-none backdrop-blur-0 bg-opacity-100 border-none'>
        <div className={isVisible ? 'flex items-center justify-between text-white' : 'flex items-center justify-end text-white'}>
          <Link to='/' className='block lg:hidden'>
            <img className='h-8 mx-auto' src='/assets/logo-aocdtf.webp' alt='Compagnons du devoir et du Tour de France' />
          </Link>
          {isVisible && (
            <Link to='/' className='link hidden lg:block xl:flex flex-row items-center gap-3 max-w-[192px] justify-start'>
              <img className='h-8 mx-auto' src='/assets/logo-aocdtf.webp' alt='Compagnons du devoir et du Tour de France' />
              <span className='lg:hidden xl:block text-white leading-3 text-[10px]'>
                Les Compagnons du Devoir
                <br /> et du Tour de France
              </span>
            </Link>
          )}
          <div className='flex flex-row items-center'>
            <div className='hidden lg:block'>{navList}</div>
            <Button
              variant='outlined'
              size='sm'
              ripple={false}
              className='hidden lg:inline-block normal-case ml-4 rounded-full px-5 md:px-2 xl:px-5 py-1.5 text-sm font-normal leading-6 border-white text-white shadow-sm bg-transparent hover:border-primary hover:bg-primary hover:text-white hover:opacity-100 focus:ring-0 poppins'>
              Je fais un don
            </Button>
          </div>
          <IconButton
            variant='text'
            className='ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden'
            ripple={false}
            onClick={() => setOpenNav(!openNav)}>
            {openNav ? (
              <svg xmlns='http://www.w3.org/2000/svg' fill='none' className='h-6 w-6' viewBox='0 0 24 24' stroke='currentColor' strokeWidth={2}>
                <path strokeLinecap='round' strokeLinejoin='round' d='M6 18L18 6M6 6l12 12' />
              </svg>
            ) : (
              <svg xmlns='http://www.w3.org/2000/svg' className='h-6 w-6' fill='none' stroke='currentColor' strokeWidth={2}>
                <path strokeLinecap='round' strokeLinejoin='round' d='M4 6h16M4 12h16M4 18h16' />
              </svg>
            )}
          </IconButton>
        </div>
        <MobileNav open={openNav}>
          <div className='container mx-auto'>
            {navList}
            <Button variant='outlined' size='sm' fullWidth className='rounded-full normal-case text-sm popins'>
              Je fais un don
            </Button>
          </div>
        </MobileNav>
      </Navbar>
    </Fragment>
  );
};

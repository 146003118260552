import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { Button } from '@material-tailwind/react';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingAbsolute } from '../../../components/LoadingAbsolute';
import { useApi } from '../../../providers/ApiProvider';
import { HeaderForm } from '../../../components/HeaderForm';
import { GoodApiResponse, ErrorApiResponse } from '../../../types/api';
import { Helmet } from 'react-helmet';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { Devis } from '../../../types/AdminRessources';
import { Config } from '../../../config/config';
import { handleError } from '../../../utils/ErrorHandler';
import toast from 'react-hot-toast';
import { ModalAcceptationDevisAdmin } from '../../../components/Modales/Admin/ModalAcceptationDevisAdmin';
import { ModalRefusDevisAdmin } from '../../../components/Modales/Admin/ModalRefusDevisAdmin';
import { ModalRetractationDevisAdmin } from '../../../components/Modales/Admin/ModalRetractationDevisAdmin';
import { ModalTraitementDevisAdmin } from '../../../components/Modales/Admin/ModalTraitementDevisAdmin';
import nl2br from 'react-nl2br';
import { Tab, TabPanel, Tabs, TabsBody, TabsHeader } from '../../../components/Onglets';
import { FormationSessionDisplay } from '../../../components/FormationSessionDisplay';
import { UserWithMailDisplay } from '../../../components/UserWithMailDisplay';
import { DownloadLink } from '../../../components/DownloadLink';

/**
 * Ce composant doit afficher le devis identifié par son id.
 */
export const AdminDeviScreen: FunctionComponent = () => {
  // On initialise les données dont on va avoir besoin sur cette page

  /**
   * On stocke si on est en train d'utiliser l'API ou non.
   */
  const [apiIsLoading, setApiIsLoading] = useState(false);

  /**
   * Permet de stocker les informations relatives au devis.
   */
  const [devi, setDevi] = useState<Devis | null>(null);

  /**
   * Permet d'ouvrir une modale lorsque l'on souhaite accepter le devis.
   */
  const [modalAcceptationDevisEstVisible, setModalAcceptationDevisEstVisible] = useState<boolean>(false);

  /**
   * Permet d'ouvrir une modale lorsque l'on souhaite refuser le devis.
   */
  const [modalRefusDevisEstVisible, setModalRefusDevisEstVisible] = useState<boolean>(false);

  /**
   * Permet d'ouvrir une modale lorsque l'on souhaite retracter le devis.
   */
  const [modalRetractationDevisEstVisible, setModalRetractationDevisEstVisible] = useState<boolean>(false);

  /**
   * Permet d'ouvrir une modale lorsque l'on souhaite traiter le devis.
   */
  const [modalTraitementDevisEstVisible, setModalTraitementDevisEstVisible] = useState<boolean>(false);

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  /**
   * Permet au composant de changer de page.
   */
  const navigate = useNavigate();

  /**
   * On a besoin des paramètres pour avoir l'id du devis.
   */
  const params = useParams();

  /**
   * Permet de recharger les données au chargement de la page.
   */
  useEffect(() => {
    handleShow();
  }, [client]);

  /**
   * Retrouve par le réseau la demande et la met dans un état local.
   */
  const handleShow = () => {
    if (apiIsLoading === true) {
      return;
    }

    setApiIsLoading(true);
    client
      .get<GoodApiResponse<Devis>>(`/admin/devis/${params.id}`)
      .then((response) => {
        setDevi(response.data.data as unknown as Devis);
      })
      .catch((error: ErrorApiResponse<Devis>) => {
        handleError(error, () => {
          navigate('/admin/devis');
        });
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Ouvre une modale pour confirmer la validation du devis.
   */
  const handleModaleAcceptationDevis = () => {
    setModalAcceptationDevisEstVisible(true);
  };

  /**
   * Permet d'accepter le devis en faisant l'appel API dédié.
   */
  const handleAccepte = () => {
    setApiIsLoading(true);

    client
      .patch<GoodApiResponse>(`/admin/devis/${params.id}/accepte`)
      .then(() => {
        handleShow();
        toast.success(`Devis accepté`);
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Ouvre une modale pour confirmer le refus du devis.
   */
  const handleModaleRefusDevis = () => {
    setModalRefusDevisEstVisible(true);
  };

  /**
   * Permet de refuser le devis en faisant l'appel API dédié.
   */
  const handleRefuse = () => {
    setApiIsLoading(true);

    client
      .patch<GoodApiResponse>(`/admin/devis/${params.id}/refuse`)
      .then(() => {
        handleShow();
        toast.success(`Devis refusé`);
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Ouvre une modale pour confirmer la rétractation du devis.
   */
  const handleModaleRetractationDevis = () => {
    setModalRetractationDevisEstVisible(true);
  };

  /**
   * Permet de refuser le devis en faisant l'appel API dédié.
   */
  const handleRetracte = () => {
    setApiIsLoading(true);

    client
      .patch<GoodApiResponse>(`/admin/devis/${params.id}/retracte`)
      .then(() => {
        handleShow();
        toast.success(`La rétractation du devis a bien été prise en compte`);
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Ouvre une modale pour confirmer le traitement du devis.
   */
  const handleModaleTraitementDevis = () => {
    setModalTraitementDevisEstVisible(true);
  };

  /**
   * Permet de traiter le devis en faisant l'appel API dédié.
   */
  const handleTraite = () => {
    setApiIsLoading(true);

    client
      .patch<GoodApiResponse>(`/admin/devis/${params.id}/traite`)
      .then(() => {
        handleShow();
        toast.success(`Le devis a bien été marqué comme traité`);
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Contenu des onglets.
   * @constant
   */
  const data = [
    {
      label: 'Général',
      value: 'general',
      icone: <CheckCircleIcon className='w-5 h-5 flex-none' />,
    },
  ] as const;

  if (devi === null) {
    return <Fragment>Chargement...</Fragment>;
  }

  return (
    <Fragment>
      <Helmet>
        <title>Devis - {Config.app_label}</title>
      </Helmet>
      <HeaderForm text={`Devis ` + devi.numero} returnUrl='/admin/devis' />
      <Tabs value='general'>
        <TabsHeader>
          {data.map(({ label, value, icone }) => (
            <Tab key={value} value={value}>
              {icone}
              <span>{label}</span>
            </Tab>
          ))}
        </TabsHeader>
        <TabsBody>
          <TabPanel value='general'>
            <form className='relative'>
              <div className='bg-white p-5 rounded-b-lg'>
                <div className='container mx-auto bg-white rounded-none'>
                  <fieldset className='flex flex-col md:flex-row gap-3 pb-7 border-b mb-7'>
                    <div className='basis-3/5 md:border-r border-secondary-1/30'>
                      <div className='mb-6'>
                        <legend className='text-secondary-1 poppins-semibold text-lg mb-3'>Informations</legend>
                        <div className='text-secondary-1'>N° {devi.numero}</div>
                        <div className='text-secondary-1'>
                          {`Émis le ${new Intl.DateTimeFormat('default', {
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                            hour12: false,
                          }).format(new Date(devi.created_at as string))}`}
                        </div>
                        {devi.date_limite_acceptation && (
                          <div className='text-secondary-1'>
                            {`Valable jusqu'au : ${new Intl.DateTimeFormat('default', {
                              year: 'numeric',
                              month: 'numeric',
                              day: 'numeric',
                              hour12: false,
                            }).format(new Date(devi.date_limite_acceptation as string))}`}
                          </div>
                        )}
                        {devi.date_acceptation && (
                          <div className='text-secondary-1'>
                            {`Accepté le ${new Intl.DateTimeFormat('default', {
                              year: 'numeric',
                              month: 'numeric',
                              day: 'numeric',
                              hour12: false,
                            }).format(new Date(devi.date_acceptation as string))}`}
                          </div>
                        )}
                        {devi.date_refus && (
                          <div className='text-secondary-1'>
                            {`Refusé le : ${new Intl.DateTimeFormat('default', {
                              year: 'numeric',
                              month: 'numeric',
                              day: 'numeric',
                              hour12: false,
                            }).format(new Date(devi.date_refus as string))}`}
                          </div>
                        )}
                        {devi.date_limite_retractation && (
                          <div className='text-secondary-1'>
                            {`Rétractation possible jusqu'au : ${new Intl.DateTimeFormat('default', {
                              year: 'numeric',
                              month: 'numeric',
                              day: 'numeric',
                              hour12: false,
                            }).format(new Date(devi.date_limite_retractation as string))}`}
                          </div>
                        )}
                        {devi.date_retractation && (
                          <div className='text-secondary-1'>
                            {`Rétractation le ${new Intl.DateTimeFormat('default', {
                              year: 'numeric',
                              month: 'numeric',
                              day: 'numeric',
                              hour: 'numeric',
                              minute: 'numeric',
                              hour12: false,
                            }).format(new Date(devi.date_retractation as string))}`}
                          </div>
                        )}
                        {devi.date_limite_traitement && (
                          <div className='text-secondary-1'>
                            {`Date limite de traitement : ${new Intl.DateTimeFormat('default', {
                              year: 'numeric',
                              month: 'numeric',
                              day: 'numeric',
                              hour12: false,
                            }).format(new Date(devi.date_limite_traitement as string))}`}
                          </div>
                        )}
                        {devi.date_traitement && (
                          <div className='text-secondary-1'>
                            {`Traité le ${new Intl.DateTimeFormat('default', {
                              year: 'numeric',
                              month: 'numeric',
                              day: 'numeric',
                              hour: 'numeric',
                              minute: 'numeric',
                              hour12: false,
                            }).format(new Date(devi.date_acceptation as string))}`}
                          </div>
                        )}
                        <div className='flex flex-row mt-2'>
                          {devi.date_limite_acceptation && devi.date_acceptation === null && devi.date_refus === null && (
                            <Fragment>
                              <Button
                                variant='filled'
                                className='flex flex-row items-center rounded-full mr-2'
                                size='sm'
                                onClick={() => handleModaleAcceptationDevis()}>
                                Accepter
                              </Button>
                              <Button
                                variant='outlined'
                                className='flex flex-row items-center rounded-full mr-2'
                                size='sm'
                                onClick={() => handleModaleRefusDevis()}>
                                Refuser
                              </Button>
                            </Fragment>
                          )}
                          {devi.date_acceptation && devi.date_limite_retractation && devi.date_retractation === null && devi.date_traitement === null && (
                            <Button
                              variant='outlined'
                              className='flex flex-row items-center rounded-full mr-2'
                              size='sm'
                              onClick={() => handleModaleRetractationDevis()}>
                              Rétractation...
                            </Button>
                          )}
                          {devi.date_acceptation && devi.date_limite_traitement && devi.date_retractation === null && devi.date_traitement === null && (
                            <Button
                              variant='outlined'
                              className='flex flex-row items-center rounded-full mr-2'
                              size='sm'
                              onClick={() => handleModaleTraitementDevis()}>
                              Marquer comme traité
                            </Button>
                          )}
                        </div>
                      </div>

                      <legend className='text-secondary-1 poppins-semibold text-lg mb-3'>Formation</legend>
                      <FormationSessionDisplay formation={devi.formation} groupe={devi.groupe} />
                    </div>

                    <div className='text-sm'>
                      <div className='pb-2'>{devi.fichier && <DownloadLink fichier={devi.fichier} titre={devi.titre} />}</div>
                      <div>{nl2br(devi.description)}</div>
                    </div>
                  </fieldset>
                  <fieldset className='pb-2'>
                    <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2'>
                      {devi.user && (
                        <div className='mt-7 lg:mt-0'>
                          <legend className='text-secondary-1 poppins-semibold text-lg mb-3'>Salarié</legend>
                          <UserWithMailDisplay user={devi.user} isAdmin={true} />
                        </div>
                      )}
                    </div>
                  </fieldset>
                </div>
              </div>
            </form>
          </TabPanel>
        </TabsBody>
      </Tabs>
      <ModalAcceptationDevisAdmin visible={modalAcceptationDevisEstVisible} setVisible={setModalAcceptationDevisEstVisible} handleAccepte={handleAccepte} />
      <ModalRefusDevisAdmin visible={modalRefusDevisEstVisible} setVisible={setModalRefusDevisEstVisible} handleRefuse={handleRefuse} />
      <ModalRetractationDevisAdmin
        visible={modalRetractationDevisEstVisible}
        setVisible={setModalRetractationDevisEstVisible}
        handleRetracte={handleRetracte}
      />
      <ModalTraitementDevisAdmin visible={modalTraitementDevisEstVisible} setVisible={setModalTraitementDevisEstVisible} handleTraite={handleTraite} />
      {apiIsLoading ? <LoadingAbsolute /> : ''}
    </Fragment>
  );
};

import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { Button, Menu, MenuHandler, MenuItem, MenuList } from '@material-tailwind/react';
import { useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { LoadingAbsolute } from '../../../components/LoadingAbsolute';
import { useApi } from '../../../providers/ApiProvider';
import { HeaderForm } from '../../../components/HeaderForm';
import { DropdownRessource } from '../../../types';
import { GoodApiResponse, ErrorApiResponse, BaseIndexParams, PaginatedApiResponse } from '../../../types/api';
import { Helmet } from 'react-helmet';
import { ReadOnlyNumber } from '../../../components/Inputs/ReadOnlyNumber';
import { Demande, DemandeDocument, DemandeEtatAvancement, Document } from '../../../types/AdminRessources';
import { ReadOnlyText } from '../../../components/Inputs/ReadOnlyText';
import { ConventionCollective, Convocation, Devis, Entreprise, Facture, FormeJuridique, Octa, TypeEmployeur } from '../../../types/schematics';
import { ReadOnlyDateTime } from '../../../components/Inputs/ReadOnlyDateTime';
import { ReadOnlySelect } from '../../../components/Inputs/ReadOnlySelect';
import { ReadOnlyBoolean } from '../../../components/Inputs/ReadOnlyBoolean';
import { Logo } from '../../../components/Logo';
import { ReadOnlyTrilean } from '../../../components/Inputs/ReadOnlyTrilean';
import {
  BookOpenIcon,
  CheckCircleIcon,
  CheckIcon,
  CurrencyEuroIcon,
  DocumentIcon,
  EllipsisVerticalIcon,
  EnvelopeIcon,
  ExclamationTriangleIcon,
  FolderIcon,
  PlayCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { ModalAdminDemandeDocument } from '../../../components/Modales/Admin/ModalAdminDemandeDocument';
import { ModalAvancementDemande } from '../../../components/Modales/Admin/ModalAvancementDemande';
import nl2br from 'react-nl2br';
import { ModalAjouterDocumentDemande } from '../../../components/Modales/Admin/ModalAjouterDocumentDemande';
import { handleError } from '../../../utils/ErrorHandler';
import { dateEstCompriseDansIntervalle, ajouterJour } from '../../../utils/Temps';
import { ModalAjouterDevisDemande } from '../../../components/Modales/Admin/ModalAjouterDevisDemande';
import { ModalAjouterConvocationDemande } from '../../../components/Modales/Admin/ModalAjouterConvocationDemande';
import { ModalAjouterFactureDemande } from '../../../components/Modales/Admin/ModalAjouterFactureDemande';
import { ModalSuppressionDevis } from '../../../components/Modales/Admin/ModalSuppressionDevis';
import { ModalSuppressionFacture } from '../../../components/Modales/Admin/ModalSuppressionFacture';
import { ModalSuppressionConvocation } from '../../../components/Modales/Admin/ModalSuppressionConvocation';
import { Tab, TabPanel, Tabs, TabsBody, TabsHeader } from '../../../components/Onglets';
import { ModalSuppressionDocument } from '../../../components/Modales/Admin/ModalSuppressionDocument';
import { ModalChangerGroupeDemande } from '../../../components/Modales/Admin/ModalChangerGroupeDemande';
import { FormationSessionDisplay } from '../../../components/FormationSessionDisplay';
import { UserWithMailDisplay } from '../../../components/UserWithMailDisplay';
import { DownloadLink } from '../../../components/DownloadLink';

/**
 * Ce composant doit afficher un formulaire qui à 3 modes de fonctionnement. Les 2 principaux sont la création et l'édition.
 * On a également un mode lecture seule. L'idée c'est de gérer une partie du CRUD.
 * C'est dans ce composant que vont se faire les appels API pour créer, éditer et voir une ressource en particulier.
 *
 * @param modeFormulaire - Mode du formulaire (creer, editer et voir).
 * @returns JSX du composant
 */
export const AdminDemandeScreen: FunctionComponent = () => {
  /**
   * On stocke si on est en train d'utiliser l'API ou non.
   */
  const [apiIsLoading, setApiIsLoading] = useState(false);

  /**
   * On stocke l'état des formes juridiques.
   */
  const [formeJuridiques, setFormeJuridiques] = useState<DropdownRessource[] | null>(null);

  /**
   * On stocke l'état des types d'employeurs.
   */
  const [typeEmployeurs, setTypeEmployeurs] = useState<DropdownRessource[] | null>(null);

  /**
   * On stocke l'état des convention collectives.
   */
  const [conventionCollectives, setConventionCollectives] = useState<DropdownRessource[] | null>(null);

  /**
   * On stocke l'état des octas.
   */
  const [octas, setOctas] = useState<DropdownRessource[] | null>(null);

  /**
   * On stocke l'état des document liés à la demande.
   */
  const [documents, setDocuments] = useState<Document[] | null>(null);

  /**
   * Permet de stocker les informations relatives à la demande.
   */
  const [demande, setDemande] = useState<Demande | null>(null);

  /**
   * Permet d'ouvrir une modale lorsque l'on souhaite ajouter un document à la demande.
   */
  const [modalDocumentsEstVisible, setModalDocumentsEstVisible] = useState<boolean>(false);

  /**
   * Permet d'ouvrir une modale lorsque l'on souhaite ajouter une information à la demande.
   */
  //const [modalInformationsEstVisible, setModalInformationsEstVisible] = useState<boolean>(false);

  /**
   *  Cet état local gere l'état de la demande
   */
  const [etatAvancement, setEtatAvancement] = useState<DemandeEtatAvancement>(DemandeEtatAvancement.adecider);

  /**
   * Cet état local gère la visibilité de la modale pour changer l'état de la demande
   */
  const [modalAvancementEstVisible, setModalAvancementEstVisible] = useState<boolean>(false);

  /**
   * Permet d'ouvrir une modale lorsque l'on souhaite ajouter un document.
   */
  const [modalAjouterDocumentEstVisible, setModalAjouterDocumentEstVisible] = useState<boolean>(false);

  /**
   * Permet d'ouvrir une modale lorsque l'on souhaite ajouter une convocation.
   */
  const [modalAjouterConvocationEstVisible, setModalAjouterConvocationEstVisible] = useState<boolean>(false);

  /**
   * Permet d'ouvrir une modale lorsque l'on souhaite ajouter un devis.
   */
  const [modalAjouterDevisEstVisible, setModalAjouterDevisEstVisible] = useState<boolean>(false);

  /**
   * Permet d'ouvrir une modale lorsque l'on souhaite ajouter une facture.
   */
  const [modalAjouterFactureEstVisible, setModalAjouterFactureEstVisible] = useState<boolean>(false);

  /**
   * Permet de selectionner un devis.
   */
  const [devisIdSelectionne, setDevisIdSelectionne] = useState<Devis['id'] | null>(null);

  /**
   * État local pour gérer le devis selectionné en vue d'ouvrir une modale dessus.
   */
  const [devisSelectionne, setDevisSelectionne] = useState<Devis | null>(null);

  /**
   * Permet d'ouvrir une modale lorsque l'on souhaite supprimer un devis.
   */
  const [modalSuppressionDevisEstVisible, setModalSuppressionDevisEstVisible] = useState<boolean>(false);

  /**
   * État local pour gérer la facture selectionné en vue d'ouvrir une modale dessus.
   */
  const [factureSelectionne, setFactureSelectionne] = useState<Facture | null>(null);

  /**
   * Permet d'ouvrir une modale lorsque l'on souhaite supprimer une facture.
   */
  const [modalSuppressionFactureEstVisible, setModalSuppressionFactureEstVisible] = useState<boolean>(false);

  /**
   * État local pour gérer la facture selectionné en vue d'ouvrir une modale dessus.
   */
  const [convocationSelectionne, setConvocationSelectionne] = useState<Convocation | null>(null);

  /**
   * Permet d'ouvrir une modale lorsque l'on souhaite supprimer une facture.
   */
  const [modalSuppressionConvocationEstVisible, setModalSuppressionConvocationEstVisible] = useState<boolean>(false);

  /**
   * État local pour gérer la facture selectionné en vue d'ouvrir une modale dessus.
   */
  const [documentSelectionne, setDocumentSelectionne] = useState<Document | null>(null);

  /**
   * Permet d'ouvrir une modale lorsque l'on souhaite ajouter un document.
   */
  const [modalSuppressionDocumentEstVisible, setModalSuppressionDocumentEstVisible] = useState<boolean>(false);

  /**
   * Permet d'ouvrir une modale lorsque l'on souhaite changer de groupe pour la formation.
   */
  const [modalChangerGroupeEstVisible, setModalChangerGroupeEstVisible] = useState<boolean>(false);

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  /**
   * Permet au composant de changer de page.
   */
  const navigate = useNavigate();

  const params = useParams();

  /**
   * Date du jour.
   *
   * @constant
   */
  const currentDate = new Date();

  // On a besoin du useEffect lorsque l'on est en mode édition et lecture pour précharger le formulaire avec les données de la ressource.
  useEffect(() => {
    if (demande) {
      if (demande.est_archive) {
        setEtatAvancement(DemandeEtatAvancement.archive);
      } else if (demande.est_finalise) {
        setEtatAvancement(DemandeEtatAvancement.finalise);
      } else if (demande.est_paye) {
        setEtatAvancement(DemandeEtatAvancement.paye);
      } else if (demande.est_complete) {
        setEtatAvancement(DemandeEtatAvancement.complete);
      } else if (demande.date_acceptation !== null || demande.date_refus !== null) {
        if (demande.date_acceptation !== null) {
          setEtatAvancement(DemandeEtatAvancement.accepte);
        }
        if (demande.date_refus !== null) {
          setEtatAvancement(DemandeEtatAvancement.refuse);
        }
      }
    }
  }, [demande]);

  useEffect(() => {
    handleIndex();
    handleShow();
  }, [client]);

  /**
   * Retrouve Les ressources nécessaires pour afficher les dropdown et les stockent dans des .
   *
   * @param indexParams - Paramètres de la recherche.
   */
  const handleIndex = () => {
    setApiIsLoading(true);
    const baseParams: BaseIndexParams = { as_foreign: true, no_pagination: true };

    const promises = [
      client.get<PaginatedApiResponse<FormeJuridique>, BaseIndexParams>('/admin/formes-juridiques', { params: baseParams }),
      client.get<PaginatedApiResponse<TypeEmployeur>, BaseIndexParams>('/admin/types-employeurs', { params: baseParams }),
      client.get<PaginatedApiResponse<ConventionCollective>, BaseIndexParams>('/admin/conventions-collectives', { params: baseParams }),
      client.get<PaginatedApiResponse<Octa>, BaseIndexParams>('/admin/octas', { params: baseParams }),
      client.get<GoodApiResponse<Document[]>, { params: { demande_id: Demande['id'] } }>('/admin/documents', {
        params: {
          demande_id: parseInt(params.id as string),
        },
      }),
    ];

    Promise.all(promises)
      .then((response) => {
        setFormeJuridiques(response[0].data.data as unknown as DropdownRessource[]);
        setTypeEmployeurs(response[1].data.data as unknown as DropdownRessource[]);
        setConventionCollectives(response[2].data.data as unknown as DropdownRessource[]);
        setOctas(response[3].data.data as unknown as DropdownRessource[]);
        setDocuments(response[4].data.data as unknown as Document[]);
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Retrouve par le réseau la ressource liée à l'ID foruni et la met dans un état local.
   * Ne doit être utilisé qu'en mode édition et lecture seule.
   */
  const handleShow = () => {
    if (apiIsLoading === true) {
      return;
    }

    setApiIsLoading(true);
    client
      .get<GoodApiResponse<Demande>>(`/admin/demandes/${params.id}`)
      .then((response) => {
        setDemande(response.data.data as unknown as Demande);
      })
      .catch((error: ErrorApiResponse<Demande & { id: number; entreprise: Entreprise }>) => {
        handleError(error, () => {
          navigate('/admin/entreprises');
        });
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  const handleModalDocuments = () => {
    setModalDocumentsEstVisible(true);
  };

  /*const handleModalInformations = () => {
    setModalInformationsEstVisible(true);
  };*/

  const handleDocumentTraite = (documentId: DemandeDocument['id']) => {
    setApiIsLoading(true);
    client
      .patch<GoodApiResponse<Demande>>(`/admin/demandes/${params.id}/documents/${documentId}/traite`)
      .then(() => {
        setDemande({
          ...(demande as Demande),
          documents: (demande as Demande).documents.map((document) => {
            if (document.id === documentId) {
              return { ...document, est_traite: true };
            }
            return document;
          }),
        });
        toast.success(`Demande de document traitée`);
      })
      .catch((error: ErrorApiResponse<Demande>) => {
        handleError(error, () => {
          navigate('/admin/entreprises');
        });
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /*const handleInformationTraitee = (informationId: DemandeInformation['id']) => {
    setApiIsLoading(true);
    client
      .patch<GoodApiResponse<Demande>>(`/admin/demandes/${params.id}/informations/${informationId}/traite`)
      .then(() => {
        setDemande({
          ...(demande as Demande),
          informations: (demande as Demande).informations.map((information) => {
            if (information.id === informationId) {
              return { ...information, est_traite: true };
            }
            return information;
          }),
        });
        toast.success(`Demande de document traitée`);
      })
      .catch((error: ErrorApiResponse<Demande & { id: number; entreprise: Entreprise }>) => {
        handleError(error, () => {
          navigate('/admin/entreprises');
        });
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };*/

  const handleModalAvancement = (etatAvancement: DemandeEtatAvancement) => {
    setEtatAvancement(etatAvancement);
    setModalAvancementEstVisible(true);
  };

  /**
   * Cette méthode sert à afficher un résumé de l'avancement pour chaque devis.
   *
   * @param devi - Devis dont il est question dans la boucle.
   * @returns Le résumé des étapes de l'avancement du devis.
   */
  const afficheAvancement = (devi: Devis) => {
    if (devi.date_acceptation === null && devi.date_refus === null) {
      if (currentDate < new Date(devi.date_limite_acceptation)) {
        // Devis en attente
        return (
          <div
            className={
              devi.date_limite_acceptation &&
              dateEstCompriseDansIntervalle(new Date(devi.created_at as string), ajouterJour(new Date(devi.date_limite_acceptation), -1), currentDate)
                ? currentDate.getDate() === ajouterJour(new Date(devi.date_limite_acceptation), -1).getDate()
                  ? 'text-orange-200'
                  : ''
                : 'text-red-700'
            }>
            En attente de décision
            {devi.date_limite_acceptation
              ? `, date limite de décision : ${new Intl.DateTimeFormat('default', {
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                  hour12: false,
                }).format(new Date(devi.date_limite_acceptation))}.`
              : '.'}
          </div>
        );
      } else {
        // Délais d'attente dépassé
        return (
          <div className='text-red-700'>
            {devi.date_limite_acceptation
              ? `Date limite de décision : ${new Intl.DateTimeFormat('default', {
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                  hour12: false,
                }).format(new Date(devi.date_limite_acceptation))}.`
              : ''}
          </div>
        );
      }
    }

    if (devi.date_acceptation) {
      /**
       * Une fois le devis accepté on sait que l'on va rendre ce bloc pour tous les cas où le devis est accepté.
       * @constant
       */
      const devisAccepte = (
        <div>{`Accepté le : ${new Intl.DateTimeFormat('default', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour12: false,
        }).format(new Date(devi.date_acceptation as string))}.`}</div>
      );

      if (devi.date_traitement === null && devi.date_limite_traitement) {
        if (currentDate < new Date(devi.date_limite_traitement)) {
          // En attente de traitement
          return (
            <Fragment>
              {devisAccepte}
              <div
                className={
                  devi.date_acceptation &&
                  devi.date_limite_retractation &&
                  dateEstCompriseDansIntervalle(
                    new Date(devi.date_acceptation as string),
                    ajouterJour(new Date(devi.date_limite_retractation as string), -1),
                    currentDate,
                  )
                    ? currentDate.getDate() === ajouterJour(new Date(devi.date_limite_retractation), -1).getDate()
                      ? 'text-orange-200'
                      : ''
                    : 'text-red-700'
                }>
                {devi.date_limite_retractation
                  ? `Date limite de rétractation : ${new Intl.DateTimeFormat('default', {
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                      hour12: false,
                    }).format(new Date(devi.date_limite_retractation))}.`
                  : ''}
              </div>
            </Fragment>
          );
        } else {
          // Délais de traitement dépassé.
          return (
            <Fragment>
              {devisAccepte}
              <div className='text-red-700'>
                {devi.date_limite_traitement
                  ? `Date limite de traitement : ${new Intl.DateTimeFormat('default', {
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                      hour12: false,
                    }).format(new Date(devi.date_limite_traitement))}.`
                  : ''}
              </div>
            </Fragment>
          );
        }
      }

      if (devi.date_retractation) {
        return (
          <Fragment>
            {devisAccepte}
            <div className='text-red-700'>
              {devi.date_retractation
                ? `Rétractation le : ${new Intl.DateTimeFormat('default', {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour12: false,
                  }).format(new Date(devi.date_retractation))}.`
                : ''}
            </div>
          </Fragment>
        );
      }

      if (devi.date_traitement) {
        return (
          <Fragment>
            {devisAccepte}
            <div>
              {devi.date_traitement
                ? `Traité le : ${new Intl.DateTimeFormat('default', {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour12: false,
                  }).format(new Date(devi.date_traitement))}.`
                : ''}
            </div>
          </Fragment>
        );
      }
    }

    if (devi.date_refus) {
      return (
        <div>{`Refusé le : ${new Intl.DateTimeFormat('default', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour12: false,
        }).format(new Date(devi.date_refus as string))}.`}</div>
      );
    }
  };

  /**
   * Ouvre une modale pour informer l'administrateur qu'il s'appreète à supprimer un devis.
   * @param devi - Devis selectionné.
   */
  const handleModaleSuppressionDevis = (devi: Devis) => {
    setDevisSelectionne(devi);
    setModalSuppressionDevisEstVisible(true);
  };

  /**
   * Cette méthode est passé en paramètre de la modale de suppression de devis.
   */
  const handleSupprimeDevis = () => {
    setApiIsLoading(true);

    client
      .delete<GoodApiResponse>(`/admin/devis/${(devisSelectionne as Devis).id}`)
      .then(() => {
        handleShow();
        toast.success(`Devis supprimé`);
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Ouvre une modale pour informer l'administrateur qu'il s'apprète à supprimer une facture.
   * @param facture - Facture selectionnée.
   */
  const handleModaleSuppressionFacture = (facture: Facture) => {
    setFactureSelectionne(facture);
    setModalSuppressionFactureEstVisible(true);
  };

  /**
   * Cette méthode est passé en paramètre de la modale de suppression de facture.
   */
  const handleSupprimeFacture = () => {
    setApiIsLoading(true);

    client
      .delete<GoodApiResponse>(`/admin/factures/${(factureSelectionne as Facture).id}`)
      .then(() => {
        handleShow();
        toast.success(`Facture supprimée`);
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Ouvre une modale pour informer l'administrateur qu'il s'apprète à supprimer une convocation.
   * @param convocation - Convocation selectionnée.
   */
  const handleModaleSuppressionConvocation = (convocation: Convocation) => {
    setConvocationSelectionne(convocation);
    setModalSuppressionConvocationEstVisible(true);
  };

  /**
   * Cette méthode est passé en paramètre de la modale de suppression de convocation.
   */
  const handleSupprimeConvocation = () => {
    setApiIsLoading(true);

    client
      .delete<GoodApiResponse>(`/admin/convocations/${(convocationSelectionne as Convocation).id}`)
      .then(() => {
        handleIndex();
        toast.success(`Convocation supprimée`);
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Ouvre une modale pour informer l'administrateur qu'il s'apprète à supprimer un document.
   * @param document - Document selectionné.
   */
  const handleModaleSuppressionDocument = (document: Document) => {
    setDocumentSelectionne(document);
    setModalSuppressionDocumentEstVisible(true);
  };

  /**
   * Cette méthode est passé en paramètre de la modale de suppression de document.
   */
  const handleSupprimeDocument = () => {
    setApiIsLoading(true);

    client
      .delete<GoodApiResponse>(`/admin/documents/${(documentSelectionne as Document).id}`)
      .then(() => {
        handleIndex();
        toast.success(`Document supprimé`);
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  const handleModalGroupes = () => {
    setModalChangerGroupeEstVisible(true);
  };

  /**
   * Contenu des onglets.
   * @constant
   */
  const data = [
    {
      label: 'Général',
      value: 'general',
      icone: <CheckCircleIcon className='w-5 h-5 flex-none' />,
    },
    {
      label: 'Documents demandés',
      value: 'demandeDocuments',
      icone: <PlayCircleIcon className='w-5 h-5 flex-none' />,
    },
    /*{
      label: "Demandes d'informations",
      value: 'demandeInformations',
      icone: <ClockIcon className='w-5 h-5 flex-none' />,
    },*/
    {
      label: 'Documents salarié',
      value: 'documents',
      icone: <FolderIcon className='w-5 h-5 flex-none' />,
    },
    {
      label: 'Convocations',
      value: 'convocations',
      icone: <EnvelopeIcon className='w-5 h-5 flex-none' />,
    },
    {
      label: 'Devis',
      value: 'devis',
      icone: <DocumentIcon className='w-5 h-5 flex-none' />,
    },
    {
      label: 'Factures',
      value: 'factures',
      icone: <CurrencyEuroIcon className='w-5 h-5 flex-none' />,
    },
  ] as const;

  if (demande === null || documents === null) {
    return <Fragment>Chargement...</Fragment>;
  }

  return (
    <Fragment>
      <Helmet>
        <title>Demande N°{demande.id?.toString().padStart(6, '0')}</title>
      </Helmet>
      <HeaderForm text={`Demande N°${demande.id?.toString().padStart(6, '0')}`} returnUrl='/admin/demandes' />
      <Tabs value='general'>
        <TabsHeader>
          {data.map(({ label, value, icone }) => (
            <Tab key={value} value={value}>
              {icone}
              <span>{label}</span>
            </Tab>
          ))}
        </TabsHeader>
        <TabsBody>
          <TabPanel value='general'>
            <form className='relative'>
              <div className='bg-white rounded-b-lg p-5'>
                <div className='container mx-auto bg-white rounded-none'>
                  <fieldset className='flex flex-col md:flex-row gap-3 pb-7 mb-7'>
                    <div className='basis-3/5 md:border-r border-secondary-1/30'>
                      <legend className='text-secondary-1 poppins-semibold text-lg mb-3'>Formation</legend>

                      <div className='flex flex-row items-end'>
                        <div className='grow'>
                          <FormationSessionDisplay formation={demande.formation} groupe={demande.groupe} />
                        </div>
                        <div className='flex-none'>
                          <Button variant='filled' className='flex flex-row items-center rounded-full mr-4' size='sm' onClick={() => handleModalGroupes()}>
                            Sélectionner une autre session
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div className='basis-2/5'>
                      <legend className='text-secondary-1 poppins-semibold text-lg mb-3'>Dates</legend>
                      <div>
                        {demande.souhait.date_acceptation === null && demande.souhait.date_refus === null
                          ? 'Souhait en attente'
                          : demande.souhait.date_acceptation
                            ? `Souhait accepté le ${new Intl.DateTimeFormat('default', {
                                year: 'numeric',
                                month: 'numeric',
                                day: 'numeric',
                                hour12: false,
                              }).format(new Date(demande.souhait.date_acceptation as string))}`
                            : demande.souhait.date_refus
                              ? `Souhait refusé le ${new Intl.DateTimeFormat('default', {
                                  year: 'numeric',
                                  month: 'numeric',
                                  day: 'numeric',
                                  hour12: false,
                                }).format(new Date(demande.souhait.date_refus as string))}`
                              : ''}
                      </div>
                      {demande.created_at && (
                        <div>{`Demande envoyée le ${new Intl.DateTimeFormat('default', {
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                          hour: 'numeric',
                          minute: 'numeric',
                          hour12: false,
                        }).format(new Date(demande.created_at as string))}`}</div>
                      )}
                      {demande.date_acceptation === null && demande.date_refus === null ? (
                        <span
                          className={
                            demande.created_at &&
                            demande.date_limite_admin_reponse_demande &&
                            dateEstCompriseDansIntervalle(
                              new Date(demande.created_at),
                              ajouterJour(new Date(demande.date_limite_admin_reponse_demande), -1),
                              currentDate,
                            )
                              ? currentDate.getDate() === ajouterJour(new Date(demande.date_limite_admin_reponse_demande), -1).getDate()
                                ? 'text-orange-200'
                                : ''
                              : 'text-red-700'
                          }>
                          En attente
                          {demande.date_limite_admin_reponse_demande
                            ? `, vous avez jusqu'au  ${new Intl.DateTimeFormat('default', {
                                year: 'numeric',
                                month: 'numeric',
                                day: 'numeric',
                                hour12: false,
                              }).format(new Date(demande.date_limite_admin_reponse_demande as string))} pour répondre`
                            : ''}
                        </span>
                      ) : demande.date_acceptation ? (
                        `Acceptée le : ${new Intl.DateTimeFormat('default', {
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                          hour12: false,
                        }).format(new Date(demande.date_acceptation as string))}`
                      ) : demande.date_refus ? (
                        `Refusée le : ${new Intl.DateTimeFormat('default', {
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                          hour12: false,
                        }).format(new Date(demande.date_refus as string))}`
                      ) : (
                        ''
                      )}
                    </div>
                    <ModalAvancementDemande
                      visible={modalAvancementEstVisible}
                      setVisible={setModalAvancementEstVisible}
                      etatAvancement={etatAvancement as DemandeEtatAvancement}
                      handleShow={handleShow}
                      demandeId={parseInt(params.id as string)}
                    />
                  </fieldset>
                  <fieldset className='py-3 mb-7 border-b'>
                    {!demande.est_archive && (
                      <div className='flex flex-wrap gap-2 items-center justify-center'>
                        {demande.date_acceptation === null && demande.date_refus === null && (
                          <Fragment>
                            <Button
                              variant='filled'
                              className='flex flex-row items-center rounded-full'
                              size='sm'
                              onClick={() => handleModalAvancement(DemandeEtatAvancement.accepte)}>
                              Accepter
                            </Button>
                            <Button
                              variant='outlined'
                              className='flex flex-row items-center rounded-full'
                              size='sm'
                              onClick={() => handleModalAvancement(DemandeEtatAvancement.refuse)}>
                              Refuser
                            </Button>
                          </Fragment>
                        )}

                        {(demande.date_acceptation !== null || demande.date_refus !== null) && !demande.est_complete && (
                          <Button
                            variant='text'
                            className='flex flex-row items-center rounded-full'
                            size='sm'
                            onClick={() => handleModalAvancement(DemandeEtatAvancement.complete)}>
                            Marquer comme complétée
                          </Button>
                        )}

                        {demande.est_complete && !demande.est_paye && (
                          <Button
                            variant='text'
                            className='flex flex-row items-center rounded-full'
                            size='sm'
                            onClick={() => handleModalAvancement(DemandeEtatAvancement.paye)}>
                            Marquer comme payée
                          </Button>
                        )}

                        {demande.est_paye && !demande.est_finalise && (
                          <Button
                            variant='text'
                            className='flex flex-row items-center rounded-full'
                            size='sm'
                            onClick={() => handleModalAvancement(DemandeEtatAvancement.finalise)}>
                            Marquer comme finalisée
                          </Button>
                        )}
                        <Button
                          variant='text'
                          className='flex flex-row items-center rounded-full'
                          size='sm'
                          onClick={() => handleModalAvancement(DemandeEtatAvancement.archive)}>
                          Marquer comme archivée
                        </Button>
                      </div>
                    )}
                  </fieldset>
                  <fieldset className='pb-7 mb-7 border-b'>
                    <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-2'>
                      <div>
                        <legend className='text-secondary-1 poppins-semibold text-lg mb-3'>Avancement</legend>
                        <div>
                          <ReadOnlyBoolean label='Est archivé' value={demande.est_archive} />
                        </div>
                        <div>
                          <ReadOnlyDateTime label='Date acceptation' value={demande.date_acceptation} />
                        </div>
                        <div>
                          <div>
                            <ReadOnlyDateTime label='Date refus' value={demande.date_refus} />
                          </div>
                          {demande.motif_refus && <div>Motif de refus : {demande.motif_refus}</div>}
                        </div>
                      </div>
                      <div className='sm:border-l xl:border-r border-secondary-1/30 md:px-3'>
                        <div className='text-secondary-1 poppins-semibold text-lg mb-3'>Statut</div>
                        {/*<div>
                          {demande.est_en_attente_informations ? (
                            <ExclamationTriangleIcon className='w-4 h-4 inline-block mx-auto text-red-300' />
                          ) : (
                            <XMarkIcon className='w-4 h-4 inline-block mx-auto text-gray-900/20' />
                          )}{' '}
                          En attente d'informations
                          </div>*/}
                        <div>
                          {demande.est_en_attente_documents ? (
                            <ExclamationTriangleIcon className='w-4 h-4 inline-block mx-auto text-red-300' />
                          ) : (
                            <XMarkIcon className='w-4 h-4 inline-block mx-auto text-gray-900/20' />
                          )}{' '}
                          Documents en attente
                        </div>
                        <div>
                          {demande.est_complete ? (
                            <CheckIcon className='w-4 h-4 text-white inline-block mx-auto bg-light-green-300 rounded-full p-0.5' />
                          ) : (
                            <XMarkIcon className='w-4 h-4 inline-block mx-auto text-gray-900/20' />
                          )}{' '}
                          Demande complète
                        </div>
                        <div>
                          {demande.est_paye ? (
                            <CheckIcon className='w-4 h-4 text-white inline-block mx-auto bg-light-green-300 rounded-full p-0.5' />
                          ) : (
                            <XMarkIcon className='w-4 h-4 inline-block mx-auto text-gray-900/20' />
                          )}{' '}
                          Paiement effectué
                        </div>
                        {demande.date_paiement && (
                          <div>
                            Date paiement :{' '}
                            {new Intl.DateTimeFormat('default', {
                              year: 'numeric',
                              month: 'numeric',
                              day: 'numeric',
                            }).format(new Date(demande.date_paiement))}
                          </div>
                        )}
                        {demande.informations_paiement && <div>Informations du paiement : {demande.informations_paiement}</div>}
                        <div>
                          {demande.est_finalise ? (
                            <CheckIcon className='w-4 h-4 text-white inline-block mx-auto bg-light-green-300 rounded-full p-0.5' />
                          ) : (
                            <XMarkIcon className='w-4 h-4 inline-block mx-auto text-gray-900/20' />
                          )}{' '}
                          Finalisée
                        </div>
                        <div>
                          {demande.est_archive && (
                            <Fragment>
                              <BookOpenIcon className='w-4 h-4 inline-block mx-auto text-gray-900/20' /> Archivée
                            </Fragment>
                          )}
                        </div>
                      </div>
                      {demande.user && (
                        <div className='md:px-2'>
                          <legend className='text-secondary-1 poppins-semibold text-lg mb-3'>Salarié</legend>
                          <UserWithMailDisplay user={demande.user} isAdmin={true} />
                        </div>
                      )}
                    </div>
                  </fieldset>

                  {demande.entreprise && (
                    <fieldset>
                      <legend className='text-secondary-1 poppins-semibold text-lg mt-7 mb-3'>Entreprise</legend>
                      <h3 className='text-secondary-2 mt-7 mb-3'>Identité</h3>
                      <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-2 items-center'>
                        <a href={'/admin/entreprises/editer/' + demande.entreprise.id} target='_blank' rel='noreferrer'>
                          <Logo className='rounded h-10 w-10 object-cover' src={demande.entreprise.image_logo} />
                        </a>
                        <div>
                          <ReadOnlyText label='Raison sociale' value={demande.entreprise.raison_sociale} />
                        </div>
                        <div>
                          <ReadOnlyText label='Enseigne' value={demande.entreprise.enseigne} />
                        </div>
                        <div>
                          <ReadOnlyText label='Numéro de SIRET' value={demande.entreprise.siret} />
                        </div>
                        <div>
                          <ReadOnlyText label='Code naf' value={demande.entreprise.code_naf} />
                        </div>
                        <div>
                          <ReadOnlySelect
                            label='Forme juridique'
                            value={demande.entreprise.forme_juridique_id as number}
                            options={formeJuridiques as DropdownRessource[]}
                          />
                        </div>
                        <div>
                          <ReadOnlySelect
                            label={"Type d'employeur"}
                            value={demande.entreprise.type_employeur_id as number}
                            options={typeEmployeurs as DropdownRessource[]}
                          />
                        </div>
                        <div>
                          <ReadOnlySelect
                            label={'Convention collective'}
                            value={demande.entreprise.convention_collective_id as number}
                            options={conventionCollectives as DropdownRessource[]}
                          />
                        </div>
                        <div>
                          <ReadOnlySelect label={'Octa'} value={demande.entreprise.octa_id as number} options={octas as DropdownRessource[]} />
                        </div>
                        <div>
                          <ReadOnlyDateTime label='Date création' value={demande.entreprise.date_creation} />
                        </div>
                        <div>
                          <ReadOnlyDateTime label='Date modification' value={demande.entreprise.date_modification} />
                        </div>
                        <div>
                          <ReadOnlyDateTime label='Date radiation' value={demande.entreprise.date_radiation} />
                        </div>
                        <div>
                          <ReadOnlyNumber label='Effectif' value={demande.entreprise.effectif} />
                        </div>
                        <div>
                          <ReadOnlyText label='Numéro repertoire métiers' value={demande.entreprise.num_rep_metier} />
                        </div>
                        <div>
                          <ReadOnlyText label='Numéro de TVA intracommunautaire' value={demande.entreprise.num_tva_intracom} />
                        </div>
                        <div>
                          <ReadOnlyBoolean label='Assujetti taxe' value={demande.entreprise.est_assujetti_taxe as boolean} />
                        </div>
                      </div>

                      <h3 className='text-secondary-2 mt-7 mb-3'>Adresse domiciliation</h3>
                      <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-2'>
                        <div>
                          <ReadOnlyText label='Adresse 1' value={demande.entreprise.adr1} />
                        </div>
                        <div>
                          <ReadOnlyText label='Adresse 2' value={demande.entreprise.adr2} />
                        </div>
                        <div>
                          <ReadOnlyText label='Adresse 3' value={demande.entreprise.adr3} />
                        </div>
                        <div>
                          <ReadOnlyText label='Adresse 4' value={demande.entreprise.adr4} />
                        </div>
                        <div>
                          <ReadOnlyText label='Code postal' value={demande.entreprise.code_postal} />
                        </div>
                        <div>
                          <ReadOnlyText label='Ville' value={demande.entreprise.ville} />
                        </div>
                        <div>
                          <ReadOnlyText label='Pays' value={demande.entreprise.pays} />
                        </div>
                      </div>

                      <h3 className='text-secondary-2 mt-7 mb-3'>Adresse facturation</h3>
                      <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-2'>
                        <div>
                          <ReadOnlyText label='Adresse 1' value={demande.entreprise.fact_adr1} />
                        </div>
                        <div>
                          <ReadOnlyText label='Adresse 2' value={demande.entreprise.fact_adr2} />
                        </div>
                        <div>
                          <ReadOnlyText label='Adresse 3' value={demande.entreprise.fact_adr3} />
                        </div>
                        <div>
                          <ReadOnlyText label='Adresse 4' value={demande.entreprise.fact_adr4} />
                        </div>
                        <div>
                          <ReadOnlyText label='Code postal' value={demande.entreprise.fact_code_postal} />
                        </div>
                        <div>
                          <ReadOnlyText label='Ville' value={demande.entreprise.fact_ville} />
                        </div>
                        <div>
                          <ReadOnlyText label='Pays' value={demande.entreprise.fact_pays} />
                        </div>
                      </div>
                      <h3 className='text-secondary-2 mt-7 mb-3'>Contact</h3>
                      <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-2'>
                        <div>
                          <ReadOnlyText label='Site web' value={demande.entreprise.site_web} />
                        </div>
                      </div>
                      <h3 className='text-secondary-2 mt-7 mb-3'>Administration</h3>
                      <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-2'>
                        <div>
                          <ReadOnlyTrilean label='Approuvée' value={demande.entreprise.est_approuvee} />
                        </div>
                        <div className='w-1/2'>
                          <ReadOnlyNumber label='YParéo CodeEntreprise' value={demande.entreprise.yp_code} />
                        </div>
                      </div>
                    </fieldset>
                  )}
                </div>
              </div>
            </form>
          </TabPanel>
          <TabPanel value='demandeDocuments'>
            <div className='bg-white w-full overflow-x-auto'>
              <ModalAdminDemandeDocument
                visible={modalDocumentsEstVisible}
                setVisible={setModalDocumentsEstVisible}
                handleShow={handleShow}
                handleIndex={handleIndex}
                demandeId={parseInt(params.id as string)}
              />
              {(demande.documents as DemandeDocument[]).length > 0 ? (
                <table className='table-fixed min-w-full bg-white rounded-b-lg'>
                  <thead className='text-sm text-gray-500 text-left border-b'>
                    <tr>
                      <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Document attendu</th>
                      <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Fichier</th>
                      <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Est traité</th>
                      <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Créée le</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(demande.documents as DemandeDocument[]).map((document) => (
                      <tr key={document.id} className=' border-gray-100 border-b hover:bg-gray-100'>
                        <td className='px-4 py-3 text-gray-800 text-sm'>{document.question}</td>
                        <td className='px-4 py-3 text-gray-800 text-sm'>
                          {document.fichier && <DownloadLink fichier={document.fichier} titre={document.titre} />}
                        </td>
                        <td className='px-4 py-3 text-gray-800 text-sm'>
                          {document.est_traite ? (
                            'Oui'
                          ) : document.fichier ? (
                            <Button
                              variant='outlined'
                              className='flex flex-row items-center rounded-full'
                              size='sm'
                              onClick={() => handleDocumentTraite(document.id)}>
                              Marquer comme traité
                            </Button>
                          ) : (
                            'Non'
                          )}
                        </td>
                        <td className='px-4 py-3 text-gray-800 text-sm'>
                          {document.created_at
                            ? new Intl.DateTimeFormat('default', {
                                year: 'numeric',
                                month: 'numeric',
                                day: 'numeric',
                                hour: 'numeric',
                                minute: 'numeric',
                                second: 'numeric',
                                hour12: false,
                              }).format(new Date(document.created_at as string))
                            : ''}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className='text-center px-4 py-7'>Il n'y a pas de document lié à cette demande.</div>
              )}

              <div className='p-4 text-center'>
                <Button
                  variant='filled'
                  color='orange'
                  onClick={() => {
                    handleModalDocuments();
                  }}>
                  Ajouter une demande de document
                </Button>
              </div>
            </div>
          </TabPanel>
          {/*<TabPanel value='demandeInformations' className='p-0 font-normal'>
            <div className='bg-white w-full overflow-x-auto'>
              <div className='flex flex-row justify-end p-4'>
                <Button className='flex flex-row items-center rounded-full' size='sm' onClick={() => handleModalInformations()}>
                  <PlusIcon className='h-6 w-6 inline mr-2' />
                  Ajouter une demande d'information
                </Button>
              </div>
              <ModalAdminDemandeInformation
                visible={modalInformationsEstVisible}
                setVisible={setModalInformationsEstVisible}
                handleShow={handleShow}
                handleIndex={handleIndex}
                demandeId={parseInt(params.id as string)}
              />
              <table className='table-fixed min-w-full bg-white rounded-b-lg'>
                <thead className='text-sm text-gray-500 text-left border-b'>
                  <tr>
                    <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Question</th>
                    <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Réponse</th>
                    <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Est traité</th>
                    <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Créée le</th>
                  </tr>
                </thead>
                <tbody>
                  {(demande.informations as DemandeInformation[]).map((information) => (
                    <tr key={information.id} className=' border-gray-100 border-b hover:bg-gray-100'>
                      <td className='px-4 py-3 text-gray-800 text-sm'>{information.question}</td>
                      <td className='px-4 py-3 text-gray-800 text-sm'>{information.reponse}</td>
                      <td className='px-4 py-3 text-gray-800 text-sm'>
                        {information.est_traite ? (
                          'Oui'
                        ) : information.reponse ? (
                          <Button
                            variant='outlined'
                            className='flex flex-row items-center rounded-full'
                            size='sm'
                            onClick={() => handleInformationTraitee(information.id)}>
                            Marquer comme traitée
                          </Button>
                        ) : (
                          'Non'
                        )}
                      </td>
                      <td className='px-4 py-3 text-gray-800 text-sm'>
                        {information.created_at
                          ? new Intl.DateTimeFormat('default', {
                              year: 'numeric',
                              month: 'numeric',
                              day: 'numeric',
                              hour: 'numeric',
                              minute: 'numeric',
                              second: 'numeric',
                              hour12: false,
                            }).format(new Date(information.created_at as string))
                          : ''}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </TabPanel>*/}
          <TabPanel value='documents'>
            <div className='bg-white w-full overflow-x-auto'>
              {documents && documents.length > 0 ? (
                <table className='table-fixed min-w-full bg-white rounded-b-lg'>
                  <thead className='text-sm text-gray-500 text-left border-b'>
                    <tr>
                      <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Fichier</th>
                      <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Description</th>
                      <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Créée le</th>
                      <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'></th>
                    </tr>
                  </thead>
                  <tbody>
                    {documents.map((document) => (
                      <tr
                        key={document.id}
                        className=' border-gray-100 border-b hover:bg-gray-100'
                        onDoubleClick={() => navigate(`/admin/documents/${document.id}`)}>
                        <td className='px-4 py-3 text-gray-800 text-sm'>
                          {document.fichier && <DownloadLink fichier={document.fichier} titre={document.titre} />}
                        </td>
                        <td className='px-4 py-3 text-gray-800 text-sm'>{document.description ? nl2br(document.description) : ''}</td>
                        <td className='px-4 py-3 text-gray-800 text-sm'>
                          {document.created_at
                            ? new Intl.DateTimeFormat('default', {
                                year: 'numeric',
                                month: 'numeric',
                                day: 'numeric',
                                hour: 'numeric',
                                minute: 'numeric',
                                hour12: false,
                              }).format(new Date(document.created_at as string))
                            : ''}
                        </td>
                        <td className='px-4 py-3 text-gray-800 text-sm'>
                          <Menu placement='right-start'>
                            <MenuHandler>
                              <Button
                                variant='text'
                                className='w-9 h-9 p-1.5 rounded-full text-gray-500 block mx-auto text-center mr-0 hover:bg-white/100 active:bg-transparent'>
                                <EllipsisVerticalIcon className='h-6 w-6' />
                              </Button>
                            </MenuHandler>
                            <MenuList>
                              <MenuItem onClick={() => navigate(`/admin/documents/${document.id}`)}>Voir</MenuItem>
                              <MenuItem
                                onClick={() => {
                                  handleModaleSuppressionDocument(document);
                                }}>
                                Supprimer
                              </MenuItem>
                            </MenuList>
                          </Menu>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className='text-center px-4 py-7'>Il n'y a pas de document salarié lié à cette demande.</div>
              )}

              <div className='p-4 text-center'>
                <Button
                  variant='filled'
                  color='orange'
                  onClick={() => {
                    setModalAjouterDocumentEstVisible(true);
                  }}>
                  Ajouter un document
                </Button>
              </div>
            </div>
          </TabPanel>
          <TabPanel value='convocations'>
            <div className='bg-white w-full overflow-x-auto'>
              {demande.convocations && demande.convocations.length > 0 ? (
                <table className='table-fixed min-w-full bg-white rounded-b-lg'>
                  <thead className='text-sm text-gray-500 text-left border-b'>
                    <tr>
                      <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Fichier</th>
                      <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Description</th>
                      <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Date convocation</th>
                      <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Créée le</th>
                    </tr>
                  </thead>
                  <tbody>
                    {demande.convocations.map((convocation) => (
                      <tr
                        key={convocation.id}
                        className=' border-gray-100 border-b hover:bg-gray-100'
                        onDoubleClick={() => navigate(`/admin/convocations/${convocation.id}`)}>
                        <td className='px-4 py-3 text-gray-800 text-sm'>
                          {convocation.fichier && <DownloadLink fichier={convocation.fichier} titre={convocation.titre} />}
                        </td>
                        <td className='px-4 py-3 text-gray-800 text-sm'>{convocation.description ? nl2br(convocation.description) : ''}</td>
                        <td className='px-4 py-3 text-gray-800 text-sm'>
                          {convocation.date_convocation
                            ? new Intl.DateTimeFormat('default', {
                                year: 'numeric',
                                month: 'numeric',
                                day: 'numeric',
                                hour12: false,
                              }).format(new Date(convocation.date_convocation as string))
                            : ''}
                        </td>
                        <td className='px-4 py-3 text-gray-800 text-sm'>
                          {convocation.created_at
                            ? new Intl.DateTimeFormat('default', {
                                year: 'numeric',
                                month: 'numeric',
                                day: 'numeric',
                                hour: 'numeric',
                                minute: 'numeric',
                                hour12: false,
                              }).format(new Date(convocation.created_at as string))
                            : ''}
                        </td>
                        <td className='px-4 py-3 text-gray-800 text-sm'>
                          <Menu placement='right-start'>
                            <MenuHandler>
                              <Button
                                variant='text'
                                className='w-9 h-9 p-1.5 rounded-full text-gray-500 block mx-auto text-center mr-0 hover:bg-white/100 active:bg-transparent'>
                                <EllipsisVerticalIcon className='h-6 w-6' />
                              </Button>
                            </MenuHandler>
                            <MenuList>
                              <MenuItem onClick={() => navigate(`/admin/convocations/${convocation.id}`)}>Voir</MenuItem>
                              <MenuItem
                                onClick={() => {
                                  handleModaleSuppressionConvocation(convocation);
                                }}>
                                Supprimer
                              </MenuItem>
                            </MenuList>
                          </Menu>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className='text-center px-4 py-7'>Il n'a pas de convocation liée à cette demande.</div>
              )}

              <div className='p-4 text-center'>
                <Button
                  variant='filled'
                  color='orange'
                  onClick={() => {
                    setModalAjouterConvocationEstVisible(true);
                  }}>
                  Ajouter une convocation
                </Button>
              </div>
            </div>
          </TabPanel>
          <TabPanel value='devis'>
            <div className='bg-white w-full overflow-x-auto'>
              {demande.devis && demande.devis.length > 0 ? (
                <table className='table-fixed w-full min-w-[1200px] bg-white rounded-b-lg'>
                  <thead className='text-sm text-gray-500 text-left border-b'>
                    <tr>
                      <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Numéro</th>
                      <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Fichier</th>
                      <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Description</th>
                      <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Avancement</th>
                      <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Créée le</th>
                      <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'></th>
                    </tr>
                  </thead>
                  <tbody>
                    {demande.devis.map((devi) => (
                      <tr key={devi.id} className=' border-gray-100 border-b hover:bg-gray-100' onDoubleClick={() => navigate(`/admin/devis/${devi.id}`)}>
                        <td className='px-4 py-3 text-gray-800 text-sm'>{devi.numero}</td>
                        <td className='px-4 py-3 text-gray-800 text-sm'>{devi.fichier && <DownloadLink fichier={devi.fichier} titre={devi.titre} />}</td>
                        <td
                          className={`px-4 py-3 text-gray-800 text-xs ${devi.id !== devisIdSelectionne ? 'cursor-row-resize' : ''}`}
                          onClick={() => setDevisIdSelectionne(devi.id)}>
                          <div className={`${devi.id !== devisIdSelectionne ? 'line-clamp-6' : ''}`}>{devi.description ? nl2br(devi.description) : ''}</div>
                        </td>
                        <td className='px-4 py-3 text-gray-800 text-xs'>
                          <div>{afficheAvancement(devi)}</div>
                        </td>
                        <td className='px-4 py-3 text-gray-800 text-sm'>
                          {devi.created_at
                            ? new Intl.DateTimeFormat('default', {
                                year: 'numeric',
                                month: 'numeric',
                                day: 'numeric',
                                hour12: false,
                              }).format(new Date(devi.created_at as string))
                            : ''}
                        </td>
                        <td className='px-4 py-3 text-gray-800 text-sm'>
                          <Menu placement='right-start'>
                            <MenuHandler>
                              <Button
                                variant='text'
                                className='w-9 h-9 p-1.5 rounded-full text-gray-500 block mx-auto text-center mr-0 hover:bg-white/100 active:bg-transparent'>
                                <EllipsisVerticalIcon className='h-6 w-6' />
                              </Button>
                            </MenuHandler>
                            <MenuList>
                              <MenuItem onClick={() => navigate(`/admin/devis/${devi.id}`)}>Voir</MenuItem>
                              <MenuItem
                                onClick={() => {
                                  handleModaleSuppressionDevis(devi);
                                }}>
                                Supprimer
                              </MenuItem>
                            </MenuList>
                          </Menu>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className='text-center px-4 py-7'>Il n'y pas de devis lié à cette demande.</div>
              )}
              <div className='p-4 text-center'>
                <Button
                  variant='filled'
                  color='orange'
                  onClick={() => {
                    setModalAjouterDevisEstVisible(true);
                  }}>
                  Ajouter un devis
                </Button>
              </div>
            </div>
          </TabPanel>
          <TabPanel value='factures'>
            <div className='bg-white w-full overflow-x-auto'>
              {demande.factures && demande.factures.length > 0 ? (
                <table className='table-fixed min-w-full bg-white rounded-b-lg'>
                  <thead className='text-sm text-gray-500 text-left border-b'>
                    <tr>
                      <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Numéro</th>
                      <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Fichier</th>
                      <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Description</th>
                      <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Date limite de paiement</th>
                      <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Payée le</th>
                      <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Créée le</th>
                      <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'></th>
                    </tr>
                  </thead>
                  <tbody>
                    {demande.factures.map((facture) => (
                      <tr
                        key={facture.id}
                        className=' border-gray-100 border-b hover:bg-gray-100'
                        onDoubleClick={() => navigate(`/admin/factures/${facture.id}`)}>
                        <td className='px-4 py-3 text-gray-800 text-sm'>{facture.numero}</td>
                        <td className='px-4 py-3 text-gray-800 text-sm'>
                          {facture.fichier && <DownloadLink fichier={facture.fichier} titre={facture.titre} />}
                        </td>
                        <td className='px-4 py-3 text-gray-800 text-sm'>{facture.description ? nl2br(facture.description) : ''}</td>
                        <td className='px-4 py-3 text-gray-800 text-sm'>
                          {facture.date_limite_paiement
                            ? new Intl.DateTimeFormat('default', {
                                year: 'numeric',
                                month: 'numeric',
                                day: 'numeric',
                                hour12: false,
                              }).format(new Date(facture.date_limite_paiement as string))
                            : ''}
                        </td>
                        <td className='px-4 py-3 text-gray-800 text-sm'>
                          {facture.date_paiement
                            ? new Intl.DateTimeFormat('default', {
                                year: 'numeric',
                                month: 'numeric',
                                day: 'numeric',
                                hour12: false,
                              }).format(new Date(facture.date_paiement as string))
                            : ''}
                        </td>
                        <td className='px-4 py-3 text-gray-800 text-sm'>
                          {facture.created_at
                            ? new Intl.DateTimeFormat('default', {
                                year: 'numeric',
                                month: 'numeric',
                                day: 'numeric',
                                hour: 'numeric',
                                minute: 'numeric',
                                hour12: false,
                              }).format(new Date(facture.created_at as string))
                            : ''}
                        </td>
                        <td className='px-4 py-3 text-gray-800 text-sm'>
                          <Menu placement='right-start'>
                            <MenuHandler>
                              <Button
                                variant='text'
                                className='w-9 h-9 p-1.5 rounded-full text-gray-500 block mx-auto text-center mr-0 hover:bg-white/100 active:bg-transparent'>
                                <EllipsisVerticalIcon className='h-6 w-6' />
                              </Button>
                            </MenuHandler>
                            <MenuList>
                              <MenuItem onClick={() => navigate(`/admin/factures/${facture.id}`)}>Voir</MenuItem>
                              <MenuItem
                                onClick={() => {
                                  handleModaleSuppressionFacture(facture);
                                }}>
                                Supprimer
                              </MenuItem>
                            </MenuList>
                          </Menu>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className='text-center px-4 py-7'>Vous n'avez pas de facture liée à cette demande.</div>
              )}

              <div className='p-4 text-center'>
                <Button
                  variant='filled'
                  color='orange'
                  onClick={() => {
                    setModalAjouterFactureEstVisible(true);
                  }}>
                  Ajouter une facture
                </Button>
              </div>
            </div>
          </TabPanel>
        </TabsBody>
      </Tabs>
      {apiIsLoading ? <LoadingAbsolute /> : ''}
      <ModalAjouterDocumentDemande
        visible={modalAjouterDocumentEstVisible}
        setVisible={setModalAjouterDocumentEstVisible}
        demande={demande}
        handleIndex={handleIndex}
      />
      <ModalAjouterConvocationDemande
        visible={modalAjouterConvocationEstVisible}
        setVisible={setModalAjouterConvocationEstVisible}
        demande={demande}
        handleShow={handleShow}
      />
      <ModalAjouterDevisDemande visible={modalAjouterDevisEstVisible} setVisible={setModalAjouterDevisEstVisible} demande={demande} handleShow={handleShow} />
      <ModalAjouterFactureDemande
        visible={modalAjouterFactureEstVisible}
        setVisible={setModalAjouterFactureEstVisible}
        demande={demande}
        handleShow={handleShow}
      />
      <ModalSuppressionDevis visible={modalSuppressionDevisEstVisible} setVisible={setModalSuppressionDevisEstVisible} handleSupprime={handleSupprimeDevis} />
      <ModalSuppressionFacture
        visible={modalSuppressionFactureEstVisible}
        setVisible={setModalSuppressionFactureEstVisible}
        handleSupprime={handleSupprimeFacture}
      />
      <ModalSuppressionConvocation
        visible={modalSuppressionConvocationEstVisible}
        setVisible={setModalSuppressionConvocationEstVisible}
        handleSupprime={handleSupprimeConvocation}
      />
      <ModalSuppressionDocument
        visible={modalSuppressionDocumentEstVisible}
        setVisible={setModalSuppressionDocumentEstVisible}
        handleSupprime={handleSupprimeDocument}
      />
      <ModalChangerGroupeDemande
        visible={modalChangerGroupeEstVisible}
        setVisible={setModalChangerGroupeEstVisible}
        demande={demande}
        handleShow={handleShow}
        handleIndex={handleIndex}
      />
    </Fragment>
  );
};

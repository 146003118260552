import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { AcademicCapIcon, ArrowDownTrayIcon, EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import { Helmet } from 'react-helmet';
import toast from 'react-hot-toast';
import { LoadingAbsolute } from '../../components/LoadingAbsolute';
import { useApi } from '../../providers/ApiProvider';
import { GoodApiResponse, ErrorApiResponse } from '../../types/api';
import { Config } from '../../config/config';
import { Favori } from '../../types/SalariesRessources';
import { Link } from 'react-router-dom';
import { Menu, MenuHandler, Button, MenuList, MenuItem } from '@material-tailwind/react';
import { Formation } from '../../types/visitorRessources';
import { TypeProfilEnum } from '../../types/auth';
import { useUser } from '../../providers/UserProvider';
import { handleError } from '../../utils/ErrorHandler';

/**
 * Ce composant affiche la liste des favoris côté salarié.
 */
export const MesFavorisScreen: FunctionComponent = () => {
  const { profil } = useUser();

  /**
   * On stocke si on est en train d'utiliser l'API ou non.
   */
  const [apiIsLoading, setApiIsLoading] = useState(false);

  /**
   * On stocke les profils possédés par l'entreprise, les salariés.
   */
  const [favoris, setFavoris] = useState<Favori[] | null>(null);

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  /**
   * Permet de recharger les données au chargement de page
   */
  useEffect(() => {
    handleIndex();
  }, [client]);

  /**
   * Retrouve par le réseau les favoris et les met dans un état local.
   */
  const handleIndex = () => {
    setApiIsLoading(true);

    client
      .get<GoodApiResponse<Favori[]>>(
        `/${
          profil !== null && (profil.type_profil === TypeProfilEnum.dirigeant || profil.type_profil === TypeProfilEnum.delegue) ? 'entreprise' : 'salarie'
        }/favoris`,
      )
      .then((response) => {
        setFavoris(response.data.data);
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Cette méthode permet de retirer une formation de la liste des favoris et mettre à jour l'état local de gestion de cette liste.
   *
   * @param formationSlug
   */
  const handleRetraitFavori = (formationSlug: Formation['slug']) => {
    setApiIsLoading(true);

    client
      .delete(
        `/${
          profil !== null && (profil.type_profil === TypeProfilEnum.dirigeant || profil.type_profil === TypeProfilEnum.delegue) ? 'entreprise' : 'salarie'
        }/favoris/${formationSlug}`,
      )
      .then(() => {
        setFavoris((favoris as Favori[]).filter((favori) => favori.formation.slug !== formationSlug));
        toast.success(`Formation retirée des favoris`);
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  if (favoris === null) {
    return <Fragment>Chargement...</Fragment>;
  }

  return (
    <Fragment>
      <Helmet>
        <title>Mes formations favorites - {Config.app_label}</title>
      </Helmet>
      <div className='px-4 py-4 md:py-3 bg-gray-100 rounded-tl-lg rounded-tr-lg'>
        <div className='sm:flex items-center justify-between'>
          <h1 className='text-base sm:text-lg md:text-xl poppins-bold leading-normal text-secondary-1'>Mes formations favorites</h1>
        </div>
      </div>
      <div>
        <div className='bg-white rounded-b-lg w-full overflow-x-auto'>
          {favoris.length > 0 ? (
            <table className='table-auto min-w-[400px] w-full bg-white rounded-b-lg'>
              <thead className='text-sm text-gray-500 text-left border-b'>
                <tr>
                  <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Nom</th>
                  <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'></th>
                  <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'></th>
                </tr>
              </thead>
              <tbody>
                {favoris.map((favori) => (
                  <tr key={favori.formation.nom} className=' border-gray-100 border-b hover:bg-gray-100'>
                    <td className='px-4 py-3 text-gray-800 text-sm'>
                      <div className='flex flex-row gap-2 items-center'>
                        <div className='rounded-md h-10 w-10 object-cover aspect-1'>
                          {favori.formation.image_couverture ? (
                            <img
                              alt={favori.formation.nom as string}
                              src={favori.formation.image_couverture}
                              tabIndex={0}
                              className='rounded-lg shadow-md object-cover w-full h-full'
                              loading='lazy'
                            />
                          ) : (
                            <div className={`rounded-lg shadow-md object-cover w-full h-full bg-gray-700 flex items-center justify-center`}>
                              <AcademicCapIcon className='h-7 w-7 text-white/50' />
                            </div>
                          )}
                        </div>
                        <div>
                          <Link to={'/formations/' + favori.formation.slug} target='blank'>
                            <div className='poppins-bold poppins-medium text-secondary-1'>{favori.formation.nom}</div>
                            <div className='text-xs poppins-medium text-gray-600'>
                              {`Ajoutée le ${new Intl.DateTimeFormat('default', {
                                year: 'numeric',
                                month: 'numeric',
                                day: 'numeric',
                                hour12: false,
                              }).format(new Date(favori.created_at as string))}`}
                            </div>
                          </Link>
                        </div>
                      </div>
                    </td>
                    <td className='px-4 py-3 text-gray-800 text-sm w-12'>
                      <a href={favori.formation.url_pdf} title='Télécharger en version PDF'>
                        <ArrowDownTrayIcon className='h-6 w-6 text-secondary-1' />
                      </a>
                    </td>
                    <td className='px-4 py-3 text-gray-800 text-sm w-12'>
                      <Menu placement='right-start'>
                        <MenuHandler>
                          <Button
                            variant='text'
                            className='w-9 h-9 p-1.5 rounded-full text-gray-500 block mx-auto text-center mr-0 hover:bg-white/100 active:bg-transparent'>
                            <EllipsisVerticalIcon className='h-6 w-6' />
                          </Button>
                        </MenuHandler>
                        <MenuList>
                          <MenuItem onClick={() => window.open(`/formations/${favori.formation.slug}`, '_blank')}>Voir</MenuItem>
                          <MenuItem onClick={() => window.open(`/formations/${favori.formation.slug}#ancre-sessions`, '_blank')}>Voir les sessions</MenuItem>
                          <MenuItem onClick={() => handleRetraitFavori(favori.formation.slug)}>Retirer des favoris</MenuItem>
                        </MenuList>
                      </Menu>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className='text-center px-4 py-7'>Vous n'avez pas encore de formations favorites.</div>
          )}
        </div>
        {apiIsLoading ? <LoadingAbsolute /> : ''}
      </div>
    </Fragment>
  );
};

import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { InputText } from '../../../components/Inputs/InputText';
import { LoadingAbsolute } from '../../../components/LoadingAbsolute';
import { useApi } from '../../../providers/ApiProvider';
import { HeaderForm } from '../../../components/HeaderForm';

import { AxiosResponse } from 'axios';
import XMarkIcon from '@heroicons/react/20/solid/XMarkIcon';
import CheckIcon from '@heroicons/react/20/solid/CheckIcon';
import { ModeFormulaire, DropdownRessource, Ressource } from '../../../types';
import { BaseIndexParams, PaginatedApiResponse, GoodApiResponse, ErrorApiResponse } from '../../../types/api';
import { Personnel, Matiere, Groupe, Periode, Site, Formation } from '../../../types/schematics';
import { Config } from '../../../config/config';
import { Helmet } from 'react-helmet';
import { ReadOnlyNumber } from '../../../components/Inputs/ReadOnlyNumber';
import { Document } from '../../../types/AdminRessources';
import { AcademicCapIcon } from '@heroicons/react/24/outline';
import nl2br from 'react-nl2br';
import { handleError } from '../../../utils/ErrorHandler';
import { Tab, TabPanel, Tabs, TabsBody, TabsHeader } from '../../../components/Onglets';
import { ReadOnlyText } from '../../../components/Inputs/ReadOnlyText';
import { ReadOnlySelect } from '../../../components/Inputs/ReadOnlySelect';
import { ReadOnlyDate } from '../../../components/Inputs/ReadOnlyDate';
import { ReadOnlyBoolean } from '../../../components/Inputs/ReadOnlyBoolean';
import { SubmitSave } from '../../../components/SubmitSave';
import { DownloadLink } from '../../../components/DownloadLink';
import { EntrepriseDisplay } from '../../../components/EntrepriseDisplay';
import { UserDisplay } from '../../../components/UserDisplay';

type Props = { modeFormulaire: ModeFormulaire };

/**
 * Ce composant doit afficher un formulaire qui à 3 modes de fonctionnement. Les 2 principaux sont la création et l'édition.
 * On a également un mode lecture seule. L'idée c'est de gérer une partie du CRUD.
 * C'est dans ce composant que vont se faire les appels API pour créer, éditer et voir une ressource en particulier.
 *
 * @param modeFormulaire - Mode du formulaire (creer, editer et voir).
 * @returns JSX du composant
 */
export const AdminGroupeFormScreen: FunctionComponent<Props> = ({ modeFormulaire }) => {
  // On initialise les données dont on va avoir besoin sur cette page

  /**
   * On stocke si on est en train d'utiliser l'API ou non.
   */
  const [apiIsLoading, setApiIsLoading] = useState(false);

  /**
   * On stocke l'état des formes juridiques.
   */
  const [periodes, setPeriodes] = useState<DropdownRessource[] | null>(null);

  /**
   * On stocke l'état des types d'employeurs.
   */
  const [sites, setSites] = useState<DropdownRessource[] | null>(null);

  /**
   * On stocke l'état des convention collectives.
   */
  const [formations, setFormations] = useState<DropdownRessource[] | null>(null);

  /**
   * On stocke les personnels possédés par le groupe.
   */
  const [ownedPersonnels, setOwnedPersonnels] = useState<Personnel[] | null>(null);

  /**
   * On stocke les matières possédés par la formation.
   */
  const [ownedMatieres, setOwnedMatieres] = useState<Matiere[] | null>(null);

  /**
   * On stocke l'état des document liés à la demande.
   */
  const [documents, setDocuments] = useState<Document[] | null>(null);

  /**
   * On stocke le nom afin de l'afficher en mode édition et voir.
   */
  const [nom, setNom] = useState<Groupe['nom']>('');

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  /**
   * Contient un état général des valeurs des champs du formulaire ainsi que les champs qui ont reçu une erreur lors de l'envoi du formulaire.
   */
  const [formInputs, setFormInputs] = useState<{ [K in keyof Groupe]: { value: Groupe[K]; error: boolean } }>({
    periode_id: { value: null, error: false },
    site_id: { value: null, error: false },
    formation_id: { value: null, error: false },
    nom: { value: '', error: false },
    nom_abrege: { value: null, error: false },
    nom_etendu: { value: null, error: false },
    nom_commercial: { value: null, error: false },
    date_debut: { value: null, error: false },
    date_fin: { value: null, error: false },
    capacite_min: { value: null, error: false },
    capacite_max: { value: null, error: false },
    nb_apprenants_actifs: { value: null, error: false },
    prix_vente: { value: null, error: false },
    unites_facturation: { value: null, error: false },
    nom_unites_facturation: { value: null, error: false },
    nomenclature_type_convention: { value: null, error: false },
    nomenclature_specialite_formation: { value: null, error: false },
    est_formation_longue: { value: false, error: false },
    est_modulaire: { value: false, error: false },
    est_plan_complexe: { value: false, error: false },
    est_bp: { value: false, error: false },
    token: { value: null, error: false },
    yp_code: { value: null, error: false },
    created_at: { value: null, error: false },
  });

  /**
   * Permet au composant de changer de page.
   */
  const navigate = useNavigate();

  const params = useParams();

  // On a besoin du useEffect lorsque l'on est en mode édition et lecture pour précharger le formulaire avec les données de la ressource.

  useEffect(() => {
    handleIndex();
    switch (modeFormulaire) {
      case ModeFormulaire.creer:
        break;
      case ModeFormulaire.voir:
      case ModeFormulaire.editer:
        if (params.id === undefined) {
          navigate('/admin/groupes');
        }
        handleShow(parseInt(params.id as string));
        break;

      default:
        break;
    }
  }, [client]);

  /**
   * Retrouve Les ressources nécessaires pour afficher les dropdown et les stockent dans des .
   *
   * @param indexParams - Paramètres de la recherche.
   */
  const handleIndex = () => {
    setApiIsLoading(true);
    const baseParams: BaseIndexParams = { as_foreign: true, no_pagination: true };

    const promises: Promise<AxiosResponse>[] = [
      client.get<PaginatedApiResponse<Periode>, BaseIndexParams>('/admin/periodes', { params: { ...baseParams, sort: 'nom' } }),
      client.get<PaginatedApiResponse<Site>, BaseIndexParams>('/admin/sites', { params: { ...baseParams, sort: 'nom' } }),
      client.get<PaginatedApiResponse<Formation>, BaseIndexParams>('/admin/formations', { params: { ...baseParams, sort: 'nom' } }),
    ];

    if (modeFormulaire !== ModeFormulaire.creer) {
      promises.push(client.get<GoodApiResponse<Personnel>>(`/admin/groupes/${parseInt(params.id as string)}/personnels`) as Promise<AxiosResponse>);
      promises.push(client.get<GoodApiResponse<Matiere>>(`/admin/groupes/${parseInt(params.id as string)}/matieres`) as Promise<AxiosResponse>);
      promises.push(
        client.get<GoodApiResponse<Document[]>, { params: { groupe_id: Groupe['id'] } }>('/admin/documents', {
          params: {
            groupe_id: parseInt(params.id as string),
          },
        }),
      );
    }

    Promise.all(promises)
      .then((response) => {
        setPeriodes(response[0].data.data as unknown as DropdownRessource[]);
        setSites(response[1].data.data as unknown as DropdownRessource[]);
        setFormations(response[2].data.data as unknown as DropdownRessource[]);
        if (modeFormulaire !== ModeFormulaire.creer) {
          setOwnedPersonnels(response[3].data.data as unknown as Personnel[]);
          setOwnedMatieres(response[4].data.data as unknown as Matiere[]);
          setDocuments(response[5].data.data as unknown as Document[]);
        }
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Retrouve par le réseau la ressource liée à l'ID foruni et la met dans un état local.
   * Ne doit être utilisé qu'en mode édition et lecture seule.
   *
   * @param ressourceId - ID de la ressource dont on veut retrouver les ingroupes.
   */
  const handleShow = (ressourceId: Ressource['id']) => {
    if (apiIsLoading === true) {
      return;
    }

    setApiIsLoading(true);
    client
      .get<GoodApiResponse<Groupe>>(`/admin/groupes/${ressourceId}`)
      .then((reponse) => {
        const updatedInputs = Object.keys(reponse.data.data).reduce((acc, key) => {
          if (key === 'prix_vente' && reponse.data.data.prix_vente !== null) {
            return { ...acc, [key]: { value: parseFloat((reponse.data.data.prix_vente / 100).toFixed(2)), error: false } };
          } else {
            return { ...acc, [key]: { value: reponse.data.data[key as keyof Groupe], error: false } };
          }
        }, formInputs);
        setFormInputs(updatedInputs);
        setNom(updatedInputs.nom.value);
      })
      .catch((error: ErrorApiResponse<Groupe>) => {
        handleError(error, () => {
          navigate('/admin/groupes');
        });
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Poste par le réseau une ressource crée dans le formulaire via un état local.
   * Ne doit être utilisé qu'en mode création.
   */
  const handleStore = () => {
    setApiIsLoading(true);

    /**
     * On transforme un unpeu les données avant de les envoyer, notament pour le prix de vente.
     */
    let ressource: Partial<Groupe> = {};
    Object.keys(formInputs).forEach((key) => {
      if (formInputs[key as keyof Groupe]?.value !== null) {
        if (key === 'prix_vente') {
          ressource = { ...ressource, prix_vente: Math.round(parseFloat((parseFloat(formInputs.prix_vente.value as unknown as string) * 100).toFixed(2))) };
        } else {
          ressource = { ...ressource, [key]: formInputs[key as keyof Groupe]?.value };
        }
      }
    });

    client
      .post<GoodApiResponse<Groupe>>('/admin/groupes', ressource)
      .then(() => {
        toast.success(`Création éffectuée`);
        navigate('/admin/groupes');
      })
      .catch((error: ErrorApiResponse<Groupe>) => {
        handleError(error, () => {
          let object = formInputs;
          Object.keys(error.response.data.errors).forEach((key) => {
            if (error.response.data.errors[key as keyof Groupe]) {
              object = { ...object, [key]: { value: formInputs[key as keyof Groupe]?.value, error: true } };
            }
          }, formInputs);
          setFormInputs(object);
        });
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Met à jour, par le réseau, une ressource à partir du formulaire via un état local.
   * Ne doit être utilisé qu'en mode édition.
   *
   * @param ressourceId - ID de la ressource dont on veut modifier les ingroupes.
   */
  const handleUpdate = (ressourceId: Ressource['id']) => {
    setApiIsLoading(true);

    /**
     * On transforme un unpeu les données avant de les envoyer, notament pour le prix de vente.
     */
    let ressource: Partial<Groupe> = {};
    Object.keys(formInputs).forEach((key) => {
      if (formInputs[key as keyof Groupe]?.value !== null) {
        if (key === 'prix_vente') {
          ressource = { ...ressource, prix_vente: Math.round(parseFloat((parseFloat(formInputs.prix_vente.value as unknown as string) * 100).toFixed(2))) };
        } else {
          ressource = { ...ressource, [key]: formInputs[key as keyof Groupe]?.value };
        }
      }
    });

    client
      .patch<GoodApiResponse<Groupe>>(`/admin/groupes/${ressourceId}`, ressource)
      .then(() => {
        toast.success(`Modification éffectuée`);
        navigate('/admin/groupes');
      })
      .catch((error: ErrorApiResponse<Groupe>) => {
        handleError(error, () => {
          let object = formInputs;
          Object.keys(error.response.data.errors).forEach((key) => {
            if (error.response.data.errors[key as keyof Groupe]) {
              object = { ...object, [key]: { value: formInputs[key as keyof Groupe]?.value, error: true } };
            }
          }, formInputs);
          setFormInputs(object);
        });
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  /**
   * Cette méthode permet de gérer l'envoi du formulaire en fonction du mode dans lequel on se trouve.
   * L'envoi ne fait rien si on est en mode voir.
   *
   * @param event - Évenement de l'envoie.
   */
  const handleSubmit = (event: { preventDefault: () => void }): void => {
    event.preventDefault();

    if (apiIsLoading === true) {
      return;
    }

    if (modeFormulaire === ModeFormulaire.creer) {
      handleStore();
    }

    if (modeFormulaire === ModeFormulaire.editer) {
      if (params.id !== undefined) {
        handleUpdate(parseInt(params.id as string));
      }
    }
  };

  /**
   * Cette méthode permet de gérer les changements à l'interieur des champs texte du formulaire et de gérer les valeurs des champs via un état local.
   *
   * @param field - Champs du formulaire dont on doit stocker la valeur et s'il est en erreur.
   */
  const handleInputChange = (field: keyof Groupe) => {
    return (event: { target: { value: string } }) => {
      setFormInputs((prev) => ({
        ...prev,
        [field]: { value: event.target.value, error: false },
      }));
    };
  };

  /**
   * Permet d'écrire le mot correspondant au mode de fomulaire. Ce mot s'affiche au début du formulaire juste à coté de la ressource.
   *
   * @param modeFormulaire - Mode du formulaire (creer, editer et voir).
   * @returns Mot à afficher correspondant au mode de formulaire.
   * @throws Lance une erreur si aucun mode formulaire n'est fourni.
   */
  const afficheModeFormulaire = (modeFormulaire: ModeFormulaire) => {
    switch (modeFormulaire) {
      case ModeFormulaire.creer:
        return 'Création';
      case ModeFormulaire.editer:
        return 'Édition';
      case ModeFormulaire.voir:
        return 'Voir';

      default:
        throw new Error("Vous n'avez pas choisi un mode de formulaire adéquat.");
    }
  };

  /**
   * Paramètres tabs
   * @constant
   */
  const data = [
    {
      label: 'Personnels',
      value: 'personnels',
    },
    {
      label: 'Matières',
      value: 'matieres',
    },
    {
      label: 'Documents',
      value: 'documents',
    },
  ] as const;

  if (
    periodes === null ||
    sites === null ||
    formations === null ||
    (modeFormulaire !== ModeFormulaire.creer && (ownedPersonnels === null || ownedMatieres === null || documents === null))
  ) {
    return <Fragment>Chargement...</Fragment>;
  }

  return (
    <Fragment>
      <Helmet>
        <title>
          {afficheModeFormulaire(modeFormulaire)} session - {Config.app_label}
        </title>
      </Helmet>
      <HeaderForm text={`${afficheModeFormulaire(modeFormulaire)} session ${modeFormulaire !== ModeFormulaire.creer ? nom : ''}`} returnUrl='/admin/groupes' />
      <Tabs value='general'>
        <TabsHeader>
          <Tab value='general'>Général</Tab>
          {modeFormulaire !== ModeFormulaire.creer &&
            data.map(({ label, value }) => (
              <Tab key={value} value={value}>
                {label}
              </Tab>
            ))}
        </TabsHeader>
        <TabsBody>
          <TabPanel value='general'>
            <form className='relative' onSubmit={handleSubmit}>
              <div className='bg-white p-5 rounded-b-lg'>
                <div className='container mx-auto bg-white rounded'>
                  <fieldset className='pb-7 mb-7 border-b'>
                    <legend className='block text-left mb-3 text-xl text-secondary-1'>Informations</legend>
                    <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-2 items-center'>
                      <div>
                        <InputText
                          label='Nom commercial'
                          value={formInputs.nom_commercial.value as string}
                          onChange={handleInputChange('nom_commercial')}
                          error={formInputs.nom_commercial.error}
                        />
                      </div>
                      <div>
                        <ReadOnlyText label='Nom YParéo' value={formInputs.nom.value as string} error={formInputs.nom.error} />
                      </div>
                      <div>
                        <ReadOnlyText label='Nom abrégé' value={formInputs.nom_abrege.value as string} error={formInputs.nom_abrege.error} />
                      </div>
                      <div>
                        <ReadOnlyText label='Nom étendu' value={formInputs.nom_etendu.value as string} error={formInputs.nom_etendu.error} />
                      </div>
                    </div>
                    <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-2 items-center'>
                      <div>
                        <ReadOnlySelect
                          label={'Site'}
                          value={formInputs.site_id.value !== null && formInputs.site_id.value !== undefined ? formInputs.site_id.value.toString() : ''}
                          error={formInputs.site_id.error}
                          options={sites}
                        />
                      </div>
                      <div>
                        <ReadOnlyDate label='Date début' value={formInputs.date_debut.value as string} error={formInputs.date_debut.error} />
                      </div>
                      <div>
                        <ReadOnlyDate label='Date fin' value={formInputs.date_fin.value as string} error={formInputs.date_fin.error} />
                      </div>
                      <div>
                        <ReadOnlySelect
                          label='Période'
                          value={
                            formInputs.periode_id.value !== null && formInputs.periode_id.value !== undefined ? formInputs.periode_id.value.toString() : ''
                          }
                          error={formInputs.periode_id.error}
                          options={periodes}
                        />
                      </div>
                    </div>
                    <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-2 items-center'>
                      <div>
                        <ReadOnlyNumber label='Capacité minimum' value={formInputs.capacite_min.value as number} error={formInputs.capacite_min.error} />
                      </div>
                      <div>
                        <ReadOnlyNumber label='Capacité maximum' value={formInputs.capacite_max.value as number} error={formInputs.capacite_max.error} />
                      </div>
                      <div>
                        <ReadOnlyNumber
                          label='Nombre apprenants actifs'
                          value={formInputs.nb_apprenants_actifs.value as number}
                          error={formInputs.nb_apprenants_actifs.error}
                        />
                      </div>
                    </div>
                    <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-2 items-center'>
                      <div>
                        <ReadOnlySelect
                          label={"Secteur d'activité"}
                          value={
                            formInputs.formation_id.value !== null && formInputs.formation_id.value !== undefined
                              ? formInputs.formation_id.value.toString()
                              : ''
                          }
                          error={formInputs.formation_id.error}
                          options={formations}
                        />
                      </div>
                      <div>
                        <ReadOnlyNumber label='YParéo CodeGroupe' value={formInputs.yp_code.value} error={formInputs.yp_code.error} />
                      </div>
                    </div>
                  </fieldset>
                  <fieldset className='pb-7 mb-7 border-b'>
                    <legend className='block text-left mb-3 text-xl text-secondary-1'>Tarification</legend>
                    <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-2 items-center'>
                      <div>
                        <ReadOnlyNumber label='Prix de vente' value={formInputs.prix_vente.value as number} error={formInputs.prix_vente.error} />
                      </div>
                    </div>
                  </fieldset>
                  <fieldset className='pb-7 mb-7 border-b'>
                    <legend className='block text-left mb-3 text-xl text-secondary-1'>Nomenclatures</legend>
                    <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-2 items-center'>
                      <div>
                        <ReadOnlyText
                          label='Nomenclature type convention'
                          value={formInputs.nomenclature_type_convention.value as string}
                          error={formInputs.nomenclature_type_convention.error}
                        />
                      </div>
                      <div>
                        <ReadOnlyText
                          label='Nomenclature spécialité formation'
                          value={formInputs.nomenclature_specialite_formation.value as number}
                          error={formInputs.nomenclature_specialite_formation.error}
                        />
                      </div>
                    </div>
                  </fieldset>
                  <fieldset className='pb-7 mb-7'>
                    <legend className='block text-left mb-3 text-xl text-secondary-1'>Paramètres</legend>
                    <div className='grid grid-cols-1 gap-2 items-center'>
                      <div>
                        <ReadOnlyBoolean label='Est formation longue' value={formInputs.est_formation_longue.value} />
                      </div>
                      <div>
                        <ReadOnlyBoolean label='Est modulaire' value={formInputs.est_modulaire.value} />
                      </div>
                      <div>
                        <ReadOnlyBoolean label='Est plan complexe' value={formInputs.est_plan_complexe.value} />
                      </div>
                      <div>
                        <ReadOnlyBoolean label='Est BP' value={formInputs.est_bp.value} />
                      </div>
                    </div>
                  </fieldset>
                </div>

                <div className='container mx-auto w-11/12 xl:w-full'>
                  <div className='w-full py-4 sm:px-0 bg-white flex justify-end'>{modeFormulaire !== ModeFormulaire.voir && <SubmitSave />}</div>
                </div>
              </div>
            </form>
          </TabPanel>
          {modeFormulaire !== ModeFormulaire.creer && (
            <TabPanel value='personnels'>
              <div className='bg-white w-full overflow-x-auto'>
                {ownedPersonnels && ownedPersonnels.length > 0 ? (
                  <table className='table-fixed min-w-full bg-white'>
                    <thead className='text-sm text-gray-500 text-left border-b'>
                      <tr>
                        <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Prénom</th>
                        <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Nom</th>
                        <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Est administratif</th>
                        <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Est conseiller</th>
                        <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Est formateur</th>
                        <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Est permanent</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ownedPersonnels.map((personnel) => (
                        <tr key={personnel.id} className=' border-gray-100 border-b hover:bg-gray-100'>
                          <td className='px-4 py-3 text-gray-800 text-sm'>{personnel.prenom}</td>
                          <td className='px-4 py-3 text-gray-800 text-sm'>{personnel.nom}</td>
                          <td className='px-4 py-3 text-gray-800 text-sm'>
                            {personnel.est_administratif ? (
                              <CheckIcon className='w-4 h-4 text-white block mx-auto bg-light-green-300 rounded-full p-0.5' />
                            ) : (
                              <XMarkIcon className='w-4 h-4 block mx-auto text-gray-900/20' />
                            )}
                          </td>
                          <td className='px-4 py-3 text-gray-800 text-sm'>
                            {personnel.est_conseiller ? (
                              <CheckIcon className='w-4 h-4 text-white block mx-auto bg-light-green-300 rounded-full p-0.5' />
                            ) : (
                              <XMarkIcon className='w-4 h-4 block mx-auto text-gray-900/20' />
                            )}
                          </td>
                          <td className='px-4 py-3 text-gray-800 text-sm'>
                            {personnel.est_formateur ? (
                              <CheckIcon className='w-4 h-4 text-white block mx-auto bg-light-green-300 rounded-full p-0.5' />
                            ) : (
                              <XMarkIcon className='w-4 h-4 block mx-auto text-gray-900/20' />
                            )}
                          </td>
                          <td className='px-4 py-3 text-gray-800 text-sm'>
                            {personnel.est_permanent ? (
                              <CheckIcon className='w-4 h-4 text-white block mx-auto bg-light-green-300 rounded-full p-0.5' />
                            ) : (
                              <XMarkIcon className='w-4 h-4 block mx-auto text-gray-900/20' />
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div className='text-center px-4 py-7'>Il n'y pas de personnel pour cette session.</div>
                )}
              </div>
            </TabPanel>
          )}
          {modeFormulaire !== ModeFormulaire.creer && (
            <TabPanel value='matieres'>
              <div className='bg-white w-full overflow-x-auto'>
                {ownedMatieres && ownedMatieres.length > 0 ? (
                  <table className='table-fixed min-w-full bg-white'>
                    <thead className='text-sm text-gray-500 text-left border-b'>
                      <tr>
                        <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Nom</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ownedMatieres.map((matiere) => (
                        <tr key={matiere.id} className=' border-gray-100 border-b hover:bg-gray-100'>
                          <td className='px-4 py-3 text-gray-800 text-sm'>{matiere.nom}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div className='text-center px-4 py-7'>Il n'y pas de matière pour cette session.</div>
                )}
              </div>
            </TabPanel>
          )}
          {modeFormulaire !== ModeFormulaire.creer && (
            <TabPanel value='documents'>
              <div className='bg-white w-full overflow-x-auto'>
                <div className='flex flex-row justify-end p-4'></div>
                {documents && documents.length > 0 ? (
                  <table className='table-fixed min-w-full bg-white'>
                    <thead className='text-sm text-gray-500 text-left border-b'>
                      <tr>
                        <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Formation</th>
                        <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Entreprise</th>
                        <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Salarié</th>
                        <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Fichier</th>
                        <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Description</th>
                        <th className='py-4 text-secondary-1 px-4 text-left text-sm tracking-normal leading-4'>Créée le</th>
                      </tr>
                    </thead>
                    <tbody>
                      {documents &&
                        documents.map((document) => (
                          <tr key={document.id} className=' border-gray-100 border-b hover:bg-gray-100'>
                            <td className='px-4 py-3 text-gray-800 text-sm'>
                              {document.formation && (
                                <div className='flex flex-row gap-2 items-center'>
                                  {document.formation.image_couverture ? (
                                    <img
                                      alt={document.formation.nom as string}
                                      src={document.formation.image_couverture}
                                      tabIndex={0}
                                      className='rounded-lg shadow-md object-cover w-10 h-10'
                                      loading='lazy'
                                    />
                                  ) : (
                                    <div className={`rounded-lg shadow-md object-cover w-10 h-10 bg-gray-700 flex items-center justify-center`}>
                                      <AcademicCapIcon className='h-7 w-7 text-white/50' />
                                    </div>
                                  )}
                                  <div>
                                    <div className='poppins-bold'>{document.formation.nom}</div>
                                  </div>
                                </div>
                              )}
                            </td>
                            <td className='px-4 py-3 text-gray-800 text-sm'>{document.entreprise && <EntrepriseDisplay entreprise={document.entreprise} />}</td>
                            <td className='px-4 py-3 text-gray-800 text-sm flex flex-row gap-2 items-center'>
                              {document.user && <UserDisplay user={document.user} />}
                            </td>
                            <td className='px-4 py-3 text-gray-800 text-sm'>
                              {document.fichier && <DownloadLink fichier={document.fichier} titre={document.titre} />}
                            </td>
                            <td className='px-4 py-3 text-gray-800 text-sm'>{document.description ? nl2br(document.description) : ''}</td>
                            <td className='px-4 py-3 text-gray-800 text-sm'>
                              {document.created_at
                                ? new Intl.DateTimeFormat('default', {
                                    year: 'numeric',
                                    month: 'numeric',
                                    day: 'numeric',
                                    hour: 'numeric',
                                    minute: 'numeric',
                                    second: 'numeric',
                                    hour12: false,
                                  }).format(new Date(document.created_at as string))
                                : ''}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                ) : (
                  <div className='text-center px-4 py-7'>Il n'y pas de document pour cette session.</div>
                )}
              </div>
            </TabPanel>
          )}
        </TabsBody>
      </Tabs>
      {apiIsLoading ? <LoadingAbsolute /> : ''}
    </Fragment>
  );
};

import { Dialog, DialogHeader, DialogBody, DialogFooter, Button } from '@material-tailwind/react';
import { FunctionComponent, useState } from 'react';
import toast from 'react-hot-toast';
import { useApi } from '../../../providers/ApiProvider';
import { GoodApiResponse, ErrorApiResponse } from '../../../types/api';
import { LoadingAbsolute } from '../../LoadingAbsolute';
import { Avis } from '../../../types/SalariesRessources';
import { InputTextarea } from '../../Inputs/InputTextarea';
import { InputSwitch } from '../../Inputs/InputSwitch';
import { StarEmpty } from '../../Stars/StarEmpty';
import { StarFull } from '../../Stars/StarFull';
import { handleError } from '../../../utils/ErrorHandler';
import { useNavigate } from 'react-router-dom';

/**
 * Ce composant permet d'afficher une Modale pour ajouter un avis.
 * Si l'utilisateur est un dirigeant, On affiche le nom du salarié en plus.
 *
 * @param visible - Précise si la modale et ouverte ou non. Provient du parent.
 * @param setVisible - Accesseur qui modifie la visibilité de la modale. Provient du parent.
 * @param formationToken - Jeton de la formation. Provient du parent.
 */
export const ModalAjoutAvis: FunctionComponent<{
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  formationToken: string | null;
}> = ({ visible, setVisible, formationToken }) => {
  /**
   * Permet au composant de changer de page.
   */
  const navigate = useNavigate();

  /**
   * On stocke si on est en train d'utiliser l'API ou non.
   */
  const [apiIsLoading, setApiIsLoading] = useState(false);

  /**
   * C'est la note que l'utilisateur souhaite donner à l'avis.
   */
  const [note, setNote] = useState<Avis['note'] | null>(null);

  /**
   * C'est le texte de l'avis à proprement parler.
   */
  const [description, setDescription] = useState<Avis['description']>('');

  /**
   * Permet d'afficher son nom complet à l'utilisateur.
   */
  const [afficheNomComplet, setAfficheNomComplet] = useState<Avis['affiche_nom_complet']>(false);

  /**
   * Permet à l'utilisateur d'afficher ou pas le nom de son entreprise.
   */
  const [afficheNomEntreprise, setAfficheNomEntreprise] = useState<Avis['affiche_nom_entreprise']>(false);

  /**
   * Permet à l'utilisateur d'afficher où pas son avatar.
   */
  const [afficheAvatar, setAfficheAvatar] = useState<Avis['affiche_avatar']>(false);

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  /**
   * Cette méthode permet d'ajouter l'avis.
   */
  const handleAjout = () => {
    setApiIsLoading(true);

    client
      .post<
        GoodApiResponse,
        {
          note: Avis['note'];
          description: Avis['description'];
          affiche_avatar: Avis['affiche_avatar'];
          affiche_nom_complet: Avis['affiche_nom_complet'];
          affiche_nom_entreprise: Avis['affiche_nom_entreprise'];
        }
      >(`/salarie/avis/${formationToken}`, {
        note: note as number,
        description: description,
        affiche_avatar: afficheAvatar,
        affiche_nom_complet: afficheNomComplet,
        affiche_nom_entreprise: afficheNomEntreprise,
      })
      .then(() => {
        toast.success('Nous avons bien reçu votre avis, celui-ci sera étudié et publié très prochainement sur notre plateforme.');
        navigate('/avis');
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error);
      })
      .finally(() => {
        setApiIsLoading(false);
      });
  };

  return (
    <Dialog className='min-w-[320px]' open={visible} size='xs' handler={setVisible}>
      <DialogHeader>Ajouter un avis</DialogHeader>
      <DialogBody divider>
        <div className='flex md:flex-row lg:flex-row md:mb-5'>
          <span className='pr-2'>Note&nbsp;:</span>
          <div onClick={() => setNote(1)}>
            {note && note >= 1 ? (
              <StarFull className={`w-5 h-5 cursor-pointer text-primary`} />
            ) : (
              <StarEmpty className={`w-5 h-5 cursor-pointer text-primary`} />
            )}
          </div>
          <div onClick={() => setNote(2)}>
            {note && note >= 2 ? (
              <StarFull className={`w-5 h-5 cursor-pointer text-primary`} />
            ) : (
              <StarEmpty className={`w-5 h-5 cursor-pointer text-primary`} />
            )}
          </div>
          <div onClick={() => setNote(3)}>
            {note && note >= 3 ? (
              <StarFull className={`w-5 h-5 cursor-pointer text-primary`} />
            ) : (
              <StarEmpty className={`w-5 h-5 cursor-pointer text-primary`} />
            )}
          </div>
          <div onClick={() => setNote(4)}>
            {note && note >= 4 ? (
              <StarFull className={`w-5 h-5 cursor-pointer text-primary`} />
            ) : (
              <StarEmpty className={`w-5 h-5 cursor-pointer text-primary`} />
            )}
          </div>
          <div onClick={() => setNote(5)}>
            {note && note >= 5 ? (
              <StarFull className={`w-5 h-5 cursor-pointer text-primary`} />
            ) : (
              <StarEmpty className={`w-5 h-5 cursor-pointer text-primary`} />
            )}
          </div>
        </div>

        <div className='p-3 bg-blue-gray-50 rounded-lg mb-4'>
          <p className='text-xs text-secondary-1'>
            <h6 className='text-base poppins-medium text-primary'>Information&nbsp;:</h6>
            Veuillez éviter les propos&nbsp;: pénalement répréhensibles, incitatifs à commettre une infraction , illicites, menaçants, injurieux,
            blasphématoires, indécent, diffamatoires, racistes, discriminatoires, obscènes, sexistes, portant atteinte à la vie privée d'autrui.
          </p>
        </div>

        <div className='mb-3'>
          <InputTextarea value={description} onChange={(e) => setDescription(e.target.value)} rows={8} />
        </div>
        <div>
          <InputSwitch label='Afficher le nom complet' checked={afficheNomComplet} onChange={() => setAfficheNomComplet(!afficheNomComplet)} />
        </div>
        <div className='my-2'>
          <InputSwitch label="Afficher le nom de l'entreprise" checked={afficheNomEntreprise} onChange={() => setAfficheNomEntreprise(!afficheNomEntreprise)} />
        </div>
        <div>
          <InputSwitch label="Afficher l'avatar" checked={afficheAvatar} onChange={() => setAfficheAvatar(!afficheAvatar)} />
        </div>
      </DialogBody>
      <DialogFooter>
        <Button
          variant='text'
          color='gray'
          onClick={() => {
            setVisible(false);
          }}
          className='mr-1 !rounded-full'
          disabled={apiIsLoading}>
          Annuler
        </Button>
        <Button
          variant='filled'
          color='orange'
          onClick={() => {
            handleAjout();
            setVisible(false);
          }}
          disabled={note === null}>
          Confirmer
        </Button>
      </DialogFooter>
      {apiIsLoading ? <LoadingAbsolute /> : ''}
    </Dialog>
  );
};
